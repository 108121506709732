.student-frame {
  /* background-color: #fff; */
  flex-direction: row;
  width: 100%;
  /* min-width: 1690px; */
  display: flex;
}
.student-frame .wrap {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  z-index: 10;
}
.student-top .student-top-bar-instance {
  z-index: 9999;
}
