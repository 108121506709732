.contact-form {
  background-color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  // overflow-y: scroll;
  overflow-y: auto;
  overflow-x: hidden;

  font-family: sans-serif;

  .Wrapper {
    position: relative;

    .Container {
      width: 940px;
      margin: 0 auto;
      padding: 0 20px;

      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width:767px) {
        width: 100vw;
      }

      header {
        padding: 100px 0 50px 0;
        width: 100%;

        h1 {
          font-size: 38px;
          line-height: 72px;
          text-align: center;
          font-weight: bold;
          color: rgb(68, 68, 68)
        }
      }

      @media screen and (max-width:767px) {
        header {
          padding: 50px 0 25px 0;

          h1 {
            font-size: 24px;
          }
        }
      }

      .Contents {
        width: 100%;
        background: #FFF;
        padding: 60px 100px;
        margin-bottom: 40px;

        @media screen and (max-width:767px) {
          padding: 20px;
        }

        table.FormList {
          margin-bottom: 25px;
          border-collapse: collapse;
          width: 100%;

          th {
            white-space: nowrap;
            padding-right: 20px;
            font-weight: bold;
            width: 264px;
            line-height: 32.4px;
          }

          th,
          td {
            font-size: 18px;
            padding: 15px 0;
            vertical-align: top;
            position: relative;
            text-align: left;

            .required {
              background: #ed5d5d;
              color: #FFF;
              font-size: 12px;
              font-weight: bold;
              line-height: 1;
              vertical-align: middle;
              padding: 3px 10px;
              border-radius: 20px;
              margin-left: 10px;
            }
          }

          input[type="text"],
          input[type="email"],
          input[type="tel"] {
            height: 45px;
            box-sizing: border-box;
            padding: 10px;
            background: #FFF;
            width: 100%;
            border: 1px solid #CCC;
            -webkit-appearance: none;
            border-radius: 5px;
            font-size: 18px;
          }

          textarea {
            background: #FFF;
            border: 1px solid #CCC;
            width: 100%;
            box-sizing: border-box;
            font-size: 18px;
            padding: 10px;
            height: 300px;
            border-radius: 5px;
          }

          @media screen and (max-width:767px) {

            th,
            td {
              font-size: 16px;
              display: block;
            }

            input[type="text"],
            input[type="email"],
            textarea {
              font-size: 16px;
            }
          }

          .select-wrap select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 45px;
            width: 267px;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 0px 10px;
            border: 1px solid #CCC;
            color: #000;
            font-size: 18px;
            background: url(../../../static/img/select.png) 96% 50% no-repeat #FFF;
            background-size: 15px 9px;

            @media screen and (max-width:767px) {
              width: 100%;
            }
          }

          select::-ms-expand {
            display: none;
          }

          .radio_custom {
            margin-bottom: 12px;
          }

          .radio_custom input {
            display: none;
          }

          .radio_custom input+span.radio_custom_field-text {
            padding-left: 25px;
            position: relative;
            display: inline-block;
            margin-right: 40px;
          }

          .radio_custom input+span.radio_custom_field-text::before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 20px;
            height: 20px;
            border: 3px solid #CCC;
            box-sizing: border-box;
            background: #FFF;
            border-radius: 50%;
          }

          .radio_custom input:checked+span.radio_custom_field-text::before {
            border: 3px solid #355cbe;
            background: #FFF;
          }

          .radio_custom input:checked+span.radio_custom_field-text::after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            background: #355cbe;
            border-radius: 50%;
          }



          span.radio_custom_field-text {
            display: inline-block;
          }


          .radio_custom span.radio_custom_field label.active {
            background: #1f215d;
          }
        }

        .btn_wrap {
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;

          @media screen and (max-width:767px) {
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
          }

          .btn {
            margin: 0 20px;

            button {
              font-size: 30px;
              font-weight: bold;
              color: #FFF;
              padding: 15px;
              border-radius: 10px;
              background: #355cbe;
              border: none;
              cursor: pointer;
              transition: 0.3s;
              min-width: 300px;

              @media screen and (max-width:767px) {
                font-size: 20px;
              }

              &:hover {
                background: #1f215d;
                color: #FFF;
              }
            }

            &.btn_back {

              button {
                background: #999;
                border-color: #999;
                color: #FFF;

                @media screen and (max-width:767px) {
                  font-size: 20px;
                }

                &:hover {
                  background: #333;
                  border-color: #333;
                }
              }
            }
          }
        }

        .pagettl {
          font-size: 30px;
          text-align: center;
          font-weight: bold;
          margin-bottom: 30px;
          line-height: 34px;

          @media screen and (max-width:767px) {
            font-size: 20px;

          }
        }

        .form_txt {
          margin-bottom: 20px;
        }

        table.Confirm_list {
          border-top: 1px solid #CCC;
          color: rgb(68, 68, 68);

          th,
          td {
            line-height: 32.4px;
            border-bottom: 1px solid #CCC;
            font-size: 18px;
            padding: 15px 0;
            vertical-align: top;
            position: relative;
            text-align: left;
          }

          th {
            width: 143.66px;
          }

          @media screen and (max-width:767px) {

            th {
              border-bottom: none;
              padding-bottom: 0;
            }

            td {
              padding-top: 0;
            }

            th,
            td {
              font-size: 16px;
              display: block;
              line-height: 1.7;
            }
          }
        }

        .caution_box {
          margin-bottom: 60px;

          .caution {
            font-size: 14px;
            padding-left: 1em;
            margin-bottom: 10px;
            line-height: 25.2px;

            span {
              margin-left: -1em;

            }

            a {
              text-decoration: underline;
            }
          }
        }

        .message {
          text-align: center;
          font-size: 18px;
          margin: 90px 0 40px 0;
          line-height: 32.4px;

          @media screen and (max-width:767px) {
            margin: 40px 0 20px 0;
          }
        }

        .btn_return a {
          display: inline-block;
          font-size: 16px;
          padding: 12px 30px;
          line-height: 1;
          background: #FFF;
          border: 4px solid #355cbe;
          border-radius: 30px;
        }

        // 個人情報保護方針
        .Txt_Box {
          line-height: 1.5;
          font-size: 16px;
          color: rgb(68, 68, 68);

          p {
            margin-bottom: 40px;
          }

          .bold {
            font-weight: bold;
          }

          .right{
            text-align: right;
          }

          p+ol {
            margin-top: -20px;
          }

          ol {
            list-style: auto;
            padding-left: 1.25em;

            li {
              margin-bottom: 10px;
            }

            ol {
              margin-top: 10px;
              list-style: none;
              padding-left: 35px;
              margin-bottom: 40px;

              li {
                span.inner {
                  display: inline-block;
                  width: 35px;
                  margin-left: -35px;
                }
              }
            }
          }
        }

      }

      footer {
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;
        width: 100%;
        margin-top: 48px;

        .foot_nav {
          text-align: center;
          color: #444;

          a {
            color: #444;
          }

          li {
            display: inline-block;
          }

          li+li:before {
            content: '｜';
            margin: 0 20px;
          }
        }

        .btn_wrap {
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;
          position: absolute;
          left: 0;
          top: -48px;

          &.thanks{
            margin: auto;
            top: -163px;
            left: 0;
            right: 0;
            @media screen and (max-width:767px) {
              top:-47px;
            }
          }

          p.btn_return {
            a {
              display: inline-block;
              font-size: 16px;
              padding: 12px 30px;
              line-height: 1;
              background: #FFF;
              border: 4px solid #355cbe;
              border-radius: 30px;
            }
          }
        }

      }


    }

    a {
      text-decoration: none;
      color: #444;

      &:hover {
        opacity: 0.8;
        transition: 0.3s;
      }
    }

    .err{
      color:#ed5d5d;
    }
  }
}