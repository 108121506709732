.MuiButton-root.accent-button {
  background: linear-gradient(
    180deg,
    rgb(255, 228, 153) 10%,
    rgb(255, 196.28, 35.06) 90%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  min-height: 130px;
  width: 680px;
  position: relative;
  color: #333333;
  text-transform: none;
  font-family: var(--main-font-family-m);
  font-size: 40px;
  

  &:hover{
    background: linear-gradient(180deg, rgb(255, 237.39, 189.13) 20.3%, rgb(255, 215.86, 107.31) 83.33%);
    box-shadow: 0px 2px 6px #000000b2;
  }

  &:active{
    background: linear-gradient(180deg, rgb(218.88, 160.25, 0) 20.3%, rgb(204, 136.68, 20.4) 84.9%);
    box-shadow: none;
  }
}
