.group-6 {
  height: 78.77px;
  left: -25px;
  position: absolute;
  top: -7px;
  width: 227.95px;
}

.group-7 {
  height: 80px;
  left: 212px;
  position: absolute;
  top: -9px;
  width: 314px;
}

/* .overlap-group-4 {
  background-image: url(../../../../static/img/vector-1.svg);
  background-size: 100% 100%;
  height: 80px;
  position: relative;
  width: 406px;
} */

/* .text-wrapper-4 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 22px;
  
  left: 0;
  right: 0;
  letter-spacing: 1.1px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 338px;
} */

.element {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 19px;
  
  left: 111px;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 98px;
  white-space: nowrap;
}

.span {
  letter-spacing: 0.75px;
}

.text-wrapper-5 {
  letter-spacing: -1.5px;
}
