@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular001.woff2") format("woff2");
  unicode-range: U+2C, U+2E, U+3A-3B, U+41-5A, U+60-7A, U+C0-D6, U+D8-DD, U+E?, U+F1-F6, U+F8-FD, U+FF-113, U+116-122, U+124-126, U+128-12B, U+12E-13D, U+141-149, U+14B-14D, U+150-17E, U+1CD-1DC, U+1F5, U+1F8-1F9, U+1FD, U+237, U+251, U+254, U+259-25B, U+28C, U+300-301, U+1E3E-1E3F, U+1EBC-1EBD, U+201A, U+3001-3002, U+3042, U+3044, U+3046, U+3048, U+304A-3054, U+3056-305F, U+3061-306A, U+306C-3082, U+3084, U+3086, U+3088-308D, U+308F-3094, U+309A, U+30A1-30A4, U+30A6-30CA, U+30CC-30DE, U+30E0-30E6, U+30E9-30EC, U+30EF, U+30F2-30F4, U+30FC, U+31F7, U+FB00-FB04, U+FE10-FE12, U+FF0C, U+FF0E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular002.woff2") format("woff2");
  unicode-range: U+0-2B, U+2D, U+2F-39, U+3C-40, U+5B-5F, U+7B-7E, U+A0-BF, U+D7, U+DE-DF, U+F0, U+F7, U+FE, U+304, U+327, U+332, U+2011, U+2219, U+223C, U+FF21-FF3A, U+FF41-FF5A, U+FF61-FF9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular003.woff2") format("woff2");
  unicode-range: U+2BB-2BC, U+2003, U+2010, U+2015-2016, U+2018-2019, U+201C-201D, U+2020-2021, U+2025-2026, U+2030, U+2032-2033, U+203B, U+2103, U+212B, U+2190-2193, U+21D2, U+21D4, U+2200, U+2202-2203, U+2207-2208, U+220B, U+2212, U+221A, U+221D-221E, U+2220, U+2227-222C, U+2234-2235, U+223D, U+2252, U+2260-2261, U+2266-2267, U+226A-226B, U+2282-2283, U+2286-2287, U+22A5, U+2312, U+2329-232A, U+2500-2503, U+250C, U+250F-2510, U+2513-2514, U+2517-2518, U+251B-251D, U+2520, U+2523-2525, U+2528, U+252B-252C, U+252F-2530, U+2533-2534, U+2537-2538, U+253B-253C, U+253F, U+2542, U+254B, U+25A0-25A1, U+25B2-25B3, U+25BC-25BD, U+25C6-25C7, U+25CB, U+25CE-25CF, U+25EF, U+2605-2606, U+2640, U+2642, U+266A, U+266D, U+266F, U+3000, U+3003, U+3005-3015, U+301C, U+3041, U+3043, U+3045, U+3047, U+3049, U+3055, U+3060, U+306B, U+3083, U+3085, U+3087, U+308E, U+309B-309E, U+30A5, U+30CB, U+30DF, U+30E7-30E8, U+30ED-30EE, U+30F0-30F1, U+30F5-30F6, U+30FB, U+30FD-30FE, U+4EDD, U+FF01, U+FF03-FF06, U+FF08-FF0B, U+FF0D, U+FF0F-FF20, U+FF3B, U+FF3D-FF40, U+FF5B-FF5E, U+FFE3, U+FFE5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular004.woff2") format("woff2");
  unicode-range: U+391-3A1, U+3A3-3A9, U+3B1-3C1, U+3C3-3C9, U+401, U+410-44F, U+451, U+3303, U+330D, U+3314, U+3318, U+3322-3323, U+3326-3327, U+332B, U+3336, U+333B, U+3349-334A, U+334D, U+3351, U+3357, U+337B-337E, U+338E-338F, U+339C-339E, U+33A1, U+33C4, U+33CB, U+33CD, U+FE45-FE46, U+FF02, U+FF07, U+FF3C, U+FF5F-FF60, U+FFE0-FFE2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular005.woff2") format("woff2");
  unicode-range: U+25B1, U+25B6-25B7, U+25C0-25C1, U+25C9, U+25D0-25D3, U+25E6, U+2600-2603, U+260E, U+2616-2617, U+261E, U+2660-2669, U+266B-266C, U+266E, U+2713, U+2756, U+2776-277F, U+2934-2935, U+29BF, U+29FA-29FB, U+3016-3019, U+301D, U+301F-3020, U+3033-3035, U+303B-303D, U+3095-3096, U+309F-30A0, U+30F7-30FA, U+30FF, U+31F0-31F6, U+31F8-31FF, U+3231-3232, U+3239, U+3251-325F, U+32A4-32A8, U+32B1-32BF, U+32D0-32E3, U+32E5, U+32E9, U+32EC-32ED, U+32FA, U+32FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular006.woff2") format("woff2");
  unicode-range: U+2C6, U+2DC, U+302-303, U+305-306, U+308, U+30B-30C, U+30F, U+318-31A, U+31C-320, U+324-325, U+329-32A, U+32C, U+32F-330, U+334, U+336, U+339-33D, U+361, U+3C2, U+1F70-1F73, U+2012-2014, U+2022, U+203C, U+203E-203F, U+2042, U+2047-2049, U+2051, U+20AC, U+210F, U+2113, U+2116, U+2121, U+2127, U+2135, U+2153-2155, U+2160-216B, U+2170-217B, U+2194, U+2196-2199, U+21C4, U+21E6-21E9, U+2205, U+2209, U+2213, U+221F, U+2225-2226, U+222E, U+2243, U+2245, U+2248, U+2262, U+2276-2277, U+2284-2285, U+228A-228B, U+2295-2297, U+22BF, U+22DA-22DB, U+2305-2306, U+2318, U+23BE-23CC, U+23CE, U+2423, U+2460-2473, U+24D0-24E9, U+24EB-24FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular007.woff2") format("woff2");
  unicode-range: U+127, U+13E, U+193, U+1C2, U+250, U+252-253, U+255-258, U+25C, U+25E-261, U+264-268, U+26C-273, U+275, U+279-27B, U+27D-27E, U+281-284, U+288-28B, U+28D-28E, U+290-292, U+294-295, U+298, U+29D, U+2A1-2A2, U+2C7-2C8, U+2CC, U+2D0-2D1, U+2D8-2D9, U+2DB, U+2DD-2DE, U+2E5-2E9, U+FD6, U+213B, U+2150-2152, U+2156-215B, U+2189, U+2211, U+2474-247F, U+2488-2492, U+24B9, U+24C8, U+26BE, U+26CB, U+27A1, U+2B05-2B07, U+2B95, U+3036, U+322A-3230, U+3233, U+3236-3237, U+3244, U+328B, U+3299, U+3371, U+3390, U+33A0, U+33A2, U+33A4-33A5, U+1F100, U+1F110-1F129, U+1F131, U+1F13D, U+1F13F, U+1F142, U+1F146, U+1F157, U+1F15F, U+1F179, U+1F17B-1F17C, U+1F17F, U+1F202, U+1F237;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular008.woff2") format("woff2");
  unicode-range: U+205A, U+205D, U+3300-3302, U+3304-330C, U+330E-3313, U+3315-3317, U+3319-3321, U+3324-3325, U+3328-332A, U+332D-3335, U+3337-333A, U+333C-3348, U+334B-334C, U+334E-3350, U+3352-3356, U+337F, U+3385-3389, U+338D, U+3396-3398, U+339B, U+339F, U+33A3, U+33A6, U+33B0-33B3, U+33C2, U+33C8, U+33CC, U+33D4, U+33D7-33D8, U+33DA, U+A7B5, U+FE17-FE19, U+FE30-FE33, U+FE35-FE44, U+FE47-FE48, U+FFE4, U+FFE8, U+1F130, U+1F132-1F13C, U+1F13E, U+1F140-1F141, U+1F143-1F145, U+1F147-1F149, U+1F150-1F156, U+1F158-1F15E, U+1F160-1F169, U+1F170-1F178, U+1F17A, U+1F17D-1F17E, U+1F180-1F189;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular009.woff2") format("woff2");
  unicode-range: U+2581-258F, U+2594-2595, U+25A2, U+25AA-25AB, U+25CA, U+25CC, U+25E2-25E5, U+25FB-25FC, U+261C-261D, U+261F, U+2672-267D, U+26A0, U+26AA-26AB, U+26BD, U+2702, U+271A, U+273F-2740, U+27BF, U+2B1A, U+2B25-2B26, U+2B29, U+2B60-2B65, U+2B82-2B83, U+2E40, U+3004, U+301E, U+3030, U+3099, U+319?, U+3220-3229, U+3234-3235, U+3238, U+323A-3243, U+3280-328A, U+328C-3298, U+329A-32A3, U+32A9-32B0, U+32E4, U+32E6-32E8, U+32EA-32EB, U+32EE-32F9, U+32FB-32FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular010.woff2") format("woff2");
  unicode-range: U+2002, U+201E, U+2039-203A, U+2044, U+2070, U+2074-2079, U+207F-2089, U+20DD-20DE, U+2100, U+2105, U+2109-210A, U+2122, U+2126, U+212E, U+215C-215E, U+217F, U+21C5-21C6, U+21CB-21CC, U+21D0, U+21F5, U+2206, U+220A, U+220F, U+222D, U+2264-2265, U+2272-2273, U+2298, U+229E, U+22A0, U+22C4, U+2307, U+239B-23AD, U+23B0-23B1, U+2480-2487, U+249C-24B8, U+24BA-24C7, U+24C9-24CF, U+24EA, U+24FF, U+2504-250B, U+250D-250E, U+2511-2512, U+2515-2516, U+2519-251A, U+251E-251F, U+2521-2522, U+2526-2527, U+2529-252A, U+252D-252E, U+2531-2532, U+2535-2536, U+2539-253A, U+253D-253E, U+2540-2541, U+2543-254A, U+2550, U+255E, U+2561, U+256A, U+256D-2573;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular011.woff2") format("woff2");
  unicode-range: U+13F-140, U+14A, U+192, U+262-263, U+26A, U+274, U+276, U+278, U+280, U+28F, U+299, U+29C, U+29F, U+2B0, U+2B2, U+2B7, U+2C1, U+2DA, U+2E0-2E1, U+307, U+30A, U+328, U+384-386, U+388-38A, U+38C, U+38E-390, U+3AA-3B0, U+3CA-3CE, U+3D0-3D1, U+3D5, U+3DB, U+402-40C, U+40E-40F, U+452-45C, U+45E-45F, U+AB53;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular012.woff2") format("woff2");
  unicode-range: U+2EA0, U+2ED1, U+2F00, U+2F06, U+2F08, U+2F0A-2F0B, U+2F12, U+2F17, U+2F1D, U+2F24-2F26, U+2F29, U+2F2D, U+2F3C, U+2F3F, U+2F42, U+2F45, U+2F47, U+2F49-2F4A, U+2F52, U+2F54, U+2F63-2F65, U+2F6C, U+2F74, U+2F83, U+2F8F, U+2F92, U+2F94, U+2F9D, U+2FA6-2FA7, U+2FAF, U+2FB7, U+2FBC, U+4E00, U+4E09-4E0B, U+4E0D, U+4E16, U+4E2D, U+4E3B, U+4E5D, U+4E8B-4E8C, U+4E94, U+4EBA, U+4ECA, U+4EE3, U+4EE5, U+4F1A, U+4F53, U+4F55, U+4F5C, U+4F7F, U+4FE1, U+5148, U+5165, U+5168, U+516B-516D, U+5175, U+5185, U+51FA, U+5206-5207, U+524D, U+529B, U+52D5, U+5316, U+5341, U+539F, U+53D6, U+53E3, U+5408, U+540C-540D, U+5411, U+5473, U+548C, U+54E1, U+554F, U+56DB, U+56DE, U+56FD, U+5730, U+5831, U+5834, U+58F0, U+5909, U+5916, U+591A, U+5927, U+5929-592A, U+5973, U+5B50, U+5B66, U+5B89, U+5B9A, U+5B9F, U+5BB6, U+5BFE, U+5C0F, U+5C11, U+5C4B, U+5C71, U+5DDD, U+5E73-5E74, U+5EA6, U+5F15, U+5F37, U+5F53, U+5F7C, U+5F8C, U+5FC3, U+601D, U+6027, U+60C5, U+610F, U+611F, U+6210, U+6226, U+6240, U+624B, U+6301, U+653F, U+6559, U+6570, U+6587, U+65B0, U+65B9, U+65E5, U+660E, U+6642, U+66F8, U+6700, U+6708, U+6728, U+672C, U+6765, U+6771, U+696D, U+6A5F, U+6B21, U+6B63, U+6B7B, U+6C0F, U+6C11, U+6C17, U+6C34, U+6C7A, U+6CBB, U+6CD5, U+6D77, U+7121, U+7269, U+73FE, U+7406, U+751F, U+7528, U+7530, U+7537, U+753B, U+754C, U+767A, U+7684, U+76EE, U+76F8, U+771F, U+77E5, U+793E, U+795E, U+79C1, U+7ACB, U+7D4C, U+7D50, U+7F8E, U+8003, U+8005, U+805E, U+81EA, U+884C, U+885B, U+8868, U+8981, U+898B, U+89AA, U+8A00, U+8A18, U+8A71, U+8A9E, U+8EAB, U+8ECD, U+8FD1, U+901A, U+9023, U+9053, U+90CE, U+90E8, U+91CD-91CE, U+91D1, U+9577, U+958B, U+9593, U+95A2, U+96C6, U+9762, U+9854, U+98DF, U+9AD8;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular013.woff2") format("woff2");
  unicode-range: U+2E9F, U+2EC4, U+2F1F-2F20, U+2F3E, U+2F40, U+2F57, U+2F69, U+2F6F, U+2F76, U+2F8A, U+2F9C, U+2F9E, U+2FA8, U+2FB3, U+2FB5, U+2FB8, U+2FBA, U+4E03, U+4E07, U+4E21, U+4E57, U+4E89, U+4E95, U+4EA4, U+4EAC, U+4ED5-4ED6, U+4ED8, U+4EF6, U+4F1D, U+4F4F, U+4F9B, U+4FC2, U+4FDD, U+5143, U+5149, U+515A, U+5171, U+5186, U+521D, U+5224-5225, U+5229, U+5236, U+52A0, U+52A9, U+52D9, U+52DD, U+52E2, U+5317, U+5343, U+534A, U+53CD, U+53D7, U+53E4, U+53F0, U+53F3, U+5409, U+544A, U+547C-547D, U+54C1, U+56E3, U+571F, U+5728, U+57CE, U+58EB, U+58F2, U+591C, U+592B, U+597D, U+59CB, U+5B58, U+5B98, U+5BA4, U+5CF6, U+5E02, U+5E2B, U+5E30, U+5E38, U+5E83, U+5E97, U+5F0F, U+5F35, U+5F62, U+5F79, U+5F85, U+5F97, U+5FA1, U+5FC5, U+5FDC, U+60AA, U+60F3, U+611B, U+6238, U+6253, U+6307, U+652F, U+653E, U+6599, U+65AD, U+65CF, U+65E9, U+6620, U+6709, U+671D, U+671F, U+6751, U+677E, U+679C, U+6821, U+683C, U+697D, U+69D8, U+6A29, U+6B66, U+6B69, U+6B73, U+6B8B, U+6BBA, U+6BCD, U+6C5F, U+6D3B, U+6D41, U+6DF1, U+6E08, U+70B9, U+7136, U+7236, U+7279, U+72B6, U+7523, U+7531, U+753A, U+756A, U+75C5, U+767D-767E, U+76F4, U+7740, U+77F3, U+78BA, U+7A7A, U+7B11, U+7B2C, U+7C73, U+7D04, U+7D42, U+7D44, U+7D9A, U+7DCF, U+7DDA, U+7F6E, U+7FA9, U+80FD, U+8272, U+82E5, U+843D, U+8449, U+85E4, U+8853, U+897F, U+8996, U+89E3, U+8A08, U+8A2D, U+8AAC-8AAD, U+8ABF, U+8AD6, U+8B70, U+8CB7, U+8CC7, U+8CEA, U+8D77, U+8DB3, U+8ECA, U+8FBC, U+8FD4, U+9001, U+9032, U+904B, U+904E, U+9055, U+9078, U+90FD, U+914D, U+9580, U+9662, U+969B, U+96FB, U+97F3, U+982D, U+984C, U+98A8, U+9996, U+99AC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular014.woff2") format("woff2");
  unicode-range: U+2ED8, U+2F2F, U+2F4C, U+2F55, U+2F70, U+2F7C, U+2F8E, U+2F95, U+2F9A-2F9B, U+2FB6, U+2FB9, U+2FCA, U+4E0E, U+4E45, U+4E4B, U+4E88, U+4ECB, U+4EFB, U+4F01, U+4F0A, U+4F4D, U+4F50, U+4F8B, U+4FA1, U+4FFA, U+5074, U+5099, U+50CD, U+50CF, U+50D5, U+512A, U+518D, U+5199, U+5272, U+533B, U+5357-5358, U+53C2, U+53CB, U+53CE, U+53EF, U+53F2, U+53F7, U+541B, U+5546, U+55B6, U+5668, U+56F3, U+578B, U+57FA, U+5883, U+5897, U+5931, U+5965, U+59BB, U+59FF, U+5A5A, U+5B57, U+5B88, U+5B97, U+5BA2, U+5BAE, U+5BB3, U+5BB9, U+5BC4, U+5BDF, U+5BFA, U+5C06, U+5C40, U+5C45, U+5CA1, U+5DDE, U+5DE5-5DE6, U+5DEE, U+5DFB, U+5E9C, U+5EA7, U+5EFA, U+5F71, U+5FF5, U+6025, U+606F, U+614B, U+623B, U+6280, U+6295, U+629C, U+62BC, U+632F, U+6483, U+6539, U+65C5, U+6625, U+666F, U+671B, U+672B, U+6761, U+67FB, U+6839, U+6848, U+69CB, U+6A2A, U+6A4B, U+6B4C, U+6B62, U+6BB5, U+6C42, U+6CC9, U+6CE8, U+6D25, U+6D3E, U+6D6E, U+6D88, U+6E05, U+6E21, U+6E80, U+6E90, U+6F14, U+706B, U+71B1, U+72EC, U+738B, U+7403, U+7533, U+7570, U+7687, U+770C, U+773C, U+793A, U+798F, U+79D1, U+7A2E, U+7A81, U+7B49, U+7B54, U+7B56, U+7B97, U+7D00, U+7D19, U+7D20, U+7D30, U+7D71, U+7D76, U+8001, U+8077, U+80B2, U+80CC, U+8239, U+826F, U+82B1, U+82B8, U+82E6, U+82F1, U+8535, U+8840, U+899A, U+89B3, U+8A3C, U+8A8D, U+8AB0, U+8AC7, U+8B58, U+8B66, U+8B77, U+8C37, U+8C61, U+8CA0, U+8D64, U+8D70, U+8DEF, U+8EE2, U+8FFD, U+9020, U+9054, U+9060, U+9152, U+91CF, U+964D, U+9650, U+968A, U+968E, U+96E2-96E3, U+9752, U+9818, U+983C, U+98DB, U+9928, U+9999, U+9A13, U+9ED2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular015.woff2") format("woff2");
  unicode-range: U+2F50, U+2F5A, U+2F81, U+2F93, U+2FAE, U+2FB0, U+4E26, U+4E38, U+4E71, U+4ECF, U+4EF2, U+4F3C, U+4F4E, U+4F59, U+4FEE, U+500B, U+5012, U+5024, U+5065, U+5144, U+5177, U+51B7, U+51E6, U+52B4, U+52B9, U+533A, U+5354, U+5371, U+53BB, U+53F8, U+5404, U+5468, U+559C, U+56F2, U+5712, U+5742, U+57DF, U+5802, U+590F, U+5922, U+59D4, U+5A18, U+5A66, U+5B85, U+5BBF, U+5BC6, U+5BDD, U+5C0E, U+5C3E, U+5C55, U+5D0E, U+5E03, U+5E2D, U+5E2F, U+5E55, U+5E78, U+5E95, U+5EAD, U+5EB7, U+5F93, U+5FA9, U+5FB3, U+5FD7, U+6050, U+6211, U+623F, U+62B1, U+62C5, U+6319, U+63A2, U+63A5, U+63CF-63D0, U+63F4, U+653B, U+6545, U+6575, U+6577, U+661F, U+662D, U+6697, U+66AE, U+670D, U+672A, U+6750, U+6797, U+682A, U+68EE, U+691C, U+6975, U+6B74, U+6BBF, U+6BCE, U+6BD4, U+6C38, U+6CA2, U+6CB3, U+6CE2, U+6D0B, U+6E1B, U+6E29, U+6E96, U+6FC0, U+7247, U+72AF, U+7387, U+74B0, U+7565, U+7591, U+7642, U+767B, U+76E3, U+7701, U+7814, U+7834, U+79C0, U+79CB, U+79D8, U+79FB, U+7A0B, U+7A0E, U+7A4D, U+7A76, U+7AE0, U+7AEF, U+7AF6, U+7BA1, U+7CBE, U+7CFB, U+7D75, U+7DD2, U+7DE8, U+7E54, U+7FD2, U+8089, U+80F8, U+8170, U+8179, U+8208, U+821E, U+8336, U+8349, U+8457, U+85AC, U+8857, U+88C1, U+88C5, U+88CF, U+88FD, U+898F, U+89D2, U+8A2A, U+8A31, U+8A55, U+8A66, U+8A8C, U+8AF8, U+8CA1, U+8CBB, U+8CDE, U+8D8A, U+8EFD, U+8F09, U+8FBA, U+9000, U+9003, U+901F, U+9031, U+904A, U+907A, U+9244, U+9280, U+9332, U+9632, U+9678, U+967A, U+96A0, U+96D1, U+9759, U+975E, U+9769, U+97FF, U+9803, U+985E, U+98F2, U+9BAE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular016.woff2") format("woff2");
  unicode-range: U+2F11, U+2F23, U+2F51, U+2F5D-2F5F, U+2F75, U+2F7B, U+2F7F, U+2F82, U+2F90, U+2FA5, U+2FAC, U+2FBB, U+2FC3, U+2FC7, U+4E91, U+4EA1, U+4EE4, U+4F11, U+5009, U+50B7, U+5104, U+5150, U+5178, U+5200, U+520A, U+5211, U+5217, U+523A-523B, U+5263, U+5287, U+5348, U+535A, U+5370, U+53B3, U+53CA, U+542B, U+5438, U+5584, U+56E0, U+56F0, U+56FA, U+5727, U+58C1, U+5915, U+592E, U+5947-5948, U+5999, U+5ACC, U+5B87, U+5B8C, U+5BCC, U+5C02, U+5C04, U+5C64, U+5CA9, U+5CB8, U+5DE8, U+5EAB, U+5F01, U+5F1F, U+5F31, U+5F3E, U+5F66, U+5FAE, U+5FD8, U+5FE0, U+6012, U+604B, U+6075, U+60B2, U+6255, U+6297-6298, U+6355, U+6368, U+6388, U+639B, U+63A8, U+6557, U+6563, U+6574, U+65BD, U+6613, U+6628, U+666E, U+6674, U+66B4, U+66F2, U+66FF, U+675F, U+677F, U+67C4, U+67D3, U+690D, U+6A19, U+6A21, U+6A39, U+6B32, U+6BDB, U+6C60, U+6CC1, U+6D45, U+6E2F, U+6E6F, U+70BA, U+713C, U+7167, U+7248, U+72AC, U+7384, U+7389, U+7532, U+7559, U+75C7, U+75DB, U+76DB, U+7720, U+7763, U+77AC, U+77ED, U+793C, U+79F0, U+7A93, U+7AF9, U+7B4B, U+7BC0, U+7D14, U+7D1A, U+7D66, U+7D99, U+7E01, U+7F6A, U+7FBD, U+8033, U+8056, U+80A9, U+8131, U+8155, U+81E3, U+822C, U+83EF, U+8584, U+85E9, U+8846, U+8863, U+88AB, U+88DC, U+89E6, U+8A0A, U+8A0E, U+8A33, U+8AB2, U+8B1B, U+8C4A, U+8CAC, U+8CB4, U+8D85, U+8DE1, U+8E0F, U+8F2A, U+8FB2, U+8FCE, U+8FEB, U+8FF0, U+9006, U+9014, U+91CC, U+9589, U+95D8, U+962A, U+963F, U+9663-9664, U+967D, U+969C, U+96C4, U+96E8, U+96EA, U+9707, U+97D3, U+984D, U+9858, U+990A, U+99C6, U+9A5A, U+9AA8, U+9AEA, U+9CE5, U+9CF4, U+9EBB, U+9ED9, U+9F62;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular017.woff2") format("woff2");
  unicode-range: U+2EE4, U+2EE9, U+2EED, U+2F30, U+2F4B, U+2F62, U+2F6A, U+2F6E, U+2F84, U+2FC1-2FC2, U+2FC5, U+2FD0, U+4E01, U+4E08, U+4E5F, U+4E73, U+4E92, U+4EEE, U+4EF0, U+4F0F, U+4F38, U+4F9D, U+4FBF, U+500D, U+5019, U+501F, U+50BE, U+5100, U+5145, U+51AC, U+5247, U+5275, U+529F, U+52E4, U+5305, U+5360, U+539A, U+53E5, U+53EB, U+5410, U+5426, U+5439, U+543E, U+54F2, U+57F7, U+5800, U+58CA, U+5949, U+59C9, U+5B6B, U+5B9D, U+5BA3, U+5BE9, U+5C0A, U+5C31, U+5C4A, U+5C5E, U+5D29, U+5DF1, U+5E0C, U+5E1D, U+5E79, U+5E7C, U+5E81, U+5E8A, U+5EC3, U+5EF6, U+5F25, U+5F8B, U+5F92, U+5FB4, U+5FEB, U+6016, U+60A3, U+60D1, U+61B2, U+61B6, U+624D, U+6279, U+627F, U+62E0-62E1, U+635C, U+63A1, U+63DB, U+63E1, U+63FA, U+643A, U+64AE, U+64CD, U+6551, U+65E7, U+6614, U+663C, U+667A, U+66DC, U+66F4, U+6749, U+679A, U+679D, U+67F3, U+6804, U+6838, U+6B20, U+6B27, U+6B6F, U+6C88, U+6CB9, U+6CCA, U+6CE3, U+6D17, U+6D5C, U+6D66, U+6DF7, U+6E2C, U+6E7E, U+6EC5, U+6F22, U+6FC3, U+702C, U+7159, U+7206, U+7518, U+76AE, U+76CA, U+76D7, U+76DF, U+77E2, U+7802, U+7956, U+7981, U+7B46, U+7BC9, U+7C21, U+7D05, U+7D0D, U+7D39, U+7D61, U+7DAD, U+7DE0, U+7DF4, U+7E04, U+7E70, U+7FA4, U+7FCC, U+8074, U+8133, U+81F3-81F4, U+822A, U+8302, U+8352, U+8377, U+878D, U+88D5, U+8907, U+8972, U+8A34, U+8A69, U+8A70, U+8A98, U+8C6A, U+8CA9, U+8CC0, U+8F38, U+8F9E, U+8FF7, U+9045, U+9063, U+9069, U+907F, U+90A3, U+90F7, U+9178, U+91E3, U+9283, U+93E1, U+95A3, U+9670, U+96A3, U+96F2, U+9732, U+9806, U+98EF, U+99C5, U+9A12, U+9B3C, U+9B54, U+9B5A, U+9E7F, U+9EC4, U+9F3B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular018.woff2") format("woff2");
  unicode-range: U+2EC1, U+2EEF, U+2EF2, U+2F1C, U+2F32, U+2F5C, U+2F73, U+2F86, U+2F88, U+2F8D, U+2F96, U+2F9F, U+2FD3, U+4E39, U+4E43, U+4E80, U+4E86, U+4E9C, U+4EC1, U+4ED9, U+4F2F, U+4F34, U+4F8D, U+4FB5, U+4FCA, U+505C, U+50AC, U+50B5, U+50DA, U+50E7, U+5230, U+5238, U+526F, U+52C7, U+52D8, U+5352, U+5373, U+53C8, U+53CC, U+53E9, U+5442, U+5507, U+5510, U+5747, U+574A, U+57A3, U+57CB, U+585A, U+5869, U+596A, U+5974, U+5982, U+59B9, U+5A01, U+5AC1, U+5B63, U+5B99, U+5BD2, U+5BFF, U+5C0B, U+5C3B, U+5C3D, U+5C48, U+5DE1, U+5E33, U+5E45, U+5E72, U+5FB9, U+5FCD, U+602A, U+6065, U+609F, U+60A9, U+611A, U+6162-6163, U+616E, U+6176, U+61D0, U+61F8, U+6271, U+6291, U+62DB, U+62DD, U+63B2, U+63EE, U+640D, U+6458, U+656C, U+658E, U+65AC, U+65D7, U+65E6, U+6607, U+6669, U+66F9, U+672D, U+676F, U+6885, U+68D2, U+6BD2, U+6C57, U+6C5A, U+6C96, U+6CBC, U+6CBF, U+6D6A, U+6D74, U+6D99, U+6DB2, U+6E09, U+6E56, U+6F5C, U+6F6E, U+706F, U+707D, U+718A, U+71C3, U+725B, U+72C2, U+72D9, U+72ED, U+732B, U+7372, U+75B2, U+7686, U+76E4, U+770B, U+773A, U+7832, U+7968, U+796D, U+7A32, U+7A3F, U+7A74, U+7ADC, U+7AE5, U+7BB1, U+7BC4, U+7C60, U+7DCA, U+7E2E, U+7E3E, U+7E41, U+7F85, U+808C, U+80DE, U+8107, U+811A, U+819D, U+81D3, U+81ED, U+820C, U+820E, U+821F, U+8266, U+83DC, U+864E, U+866B, U+885D, U+888B, U+88C2, U+8A3A, U+8A73, U+8ACB, U+8B00, U+8B1D, U+8C46, U+8CA7-8CA8, U+8CB8, U+8DDD, U+8E8D, U+8ED2, U+8F1D, U+8F9B, U+9084, U+90F5, U+9154, U+91C8, U+91DD, U+9234, U+92AD, U+95C7, U+9686, U+96C5, U+970A, U+9802, U+9805, U+9830, U+98FE, U+99C4, U+9F8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular019.woff2") format("woff2");
  unicode-range: U+2EEB, U+2F77, U+4E18, U+4E7E, U+4EAD, U+4F73, U+4F75, U+4FC3, U+4FD7, U+507D, U+508D, U+5146, U+514D, U+517C, U+518A, U+524A, U+5264, U+5289, U+52AA, U+52C9, U+52DF, U+5302, U+5320, U+5353, U+5374, U+53D4, U+541E, U+54B2, U+54C0, U+552F, U+5531, U+5642, U+5653, U+5750, U+5857, U+5893, U+594F, U+5951, U+596E, U+59D3, U+59EB, U+5B09, U+5B54, U+5B5D, U+5B64, U+5B8F, U+5BDB, U+5C01, U+5C1A, U+5CF0, U+5D07, U+5E7B, U+5E7E, U+5ECA, U+5EFB, U+5F18, U+5F69, U+5F80-5F81, U+6069, U+6094, U+60A0, U+614E, U+618E, U+6212, U+629E, U+62B5, U+62D2, U+62F6, U+6311, U+6328, U+6392, U+6398, U+63A7, U+63DA, U+6469, U+654F, U+6589, U+65E2, U+660C, U+6696, U+670B, U+6717, U+6790, U+67D4, U+67F1, U+67F4, U+685C, U+68C4, U+6905, U+6982, U+6C37, U+6C99, U+6CA1, U+6CE5, U+6D69, U+6DE1, U+6DFB, U+6E0B, U+6ED1, U+6EDE, U+6F01, U+6FA4, U+6FE1, U+708E, U+70AD, U+70C8, U+719F, U+7235, U+7267, U+732E, U+7344, U+73CD, U+7551, U+7573, U+7709, U+786C, U+795D, U+7A42, U+7B20, U+7C4D, U+7C89, U+7CF8, U+7D1B, U+7D22, U+7DB1-7DB2, U+7DD1, U+7F72, U+8010, U+809D, U+80A1, U+80A5, U+8102, U+8105, U+8108, U+8150, U+81E8, U+829D, U+8358, U+83CA, U+85A9, U+865A, U+88F8, U+8986, U+8997, U+8A13, U+8A17, U+8A87, U+8A95, U+8AA0, U+8AA4, U+8CAB, U+8CBF, U+8CCA, U+8CDB, U+8CFC, U+8DA3, U+8E0A, U+8F03, U+8F29, U+900F, U+902E, U+9047, U+90A6, U+90AA, U+90B8, U+90E1, U+92ED, U+9685, U+96C7, U+9756, U+9808, U+9810, U+9867, U+98FC, U+99D0, U+9A0E, U+9B42, U+9B45, U+9DB4, U+9E97;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular020.woff2") format("woff2");
  unicode-range: U+2EE8, U+2F28, U+2F38, U+2F6B, U+2FA0, U+2FB4, U+4E1E, U+4FF3, U+502B, U+5049, U+5075-5076, U+511F, U+514B, U+5176, U+5192, U+51C4, U+51CD, U+51DD, U+5203, U+5237, U+525B, U+525D, U+52E7, U+5339, U+5375, U+53EC, U+5446, U+5449, U+545F, U+5589, U+558B, U+55AB, U+5606, U+5609, U+5674, U+5699, U+570F, U+5782, U+5805, U+5854, U+585E, U+58C7, U+58EE, U+5A46, U+5B22, U+5BC2, U+5BE7, U+5BF8, U+5C3A, U+5C90, U+5CB3, U+5D8B, U+5E3D, U+5E84, U+5E8F, U+5EB5, U+5EF7, U+5F13, U+5F6B, U+5FD9, U+6068, U+60DC, U+60E8, U+614C, U+6249, U+62CD, U+62ED, U+62FE, U+636E, U+6383, U+638C, U+63AA, U+63C3, U+6442, U+6451, U+64AB, U+659C, U+65E8, U+65EC, U+662F, U+664B, U+6676, U+6687, U+6691, U+6731, U+673A, U+674E, U+6762, U+67A0, U+6842, U+68B0, U+68DA, U+6B53, U+6B8A, U+6B96, U+6C41, U+6CF0, U+6CF3, U+6D29, U+6D44, U+6DAF, U+6DBC, U+6E9C, U+6EB6, U+6F02, U+6F0F, U+6F2B, U+6F70, U+6F84, U+7070, U+7126, U+716E, U+731B, U+7434, U+74F6, U+76BF, U+78E8, U+790E, U+7948, U+7950, U+7985, U+7A3C-7A3D, U+7A4F, U+7B52, U+7C92, U+7CA7, U+7CD6, U+7D0B, U+7D2B, U+7DBF, U+7DE9, U+7E1B, U+7E26, U+7F70, U+7FD4, U+7FFC, U+80C6, U+81A8, U+81B3, U+82B3, U+83CC, U+846C, U+8650, U+8896, U+89A7, U+8A5E, U+8A89, U+8B0E, U+8B72, U+8C8C, U+8C9E, U+8CAF, U+8CC3, U+8CE2, U+8D08, U+8DF3, U+8E74, U+8EB0, U+8FB0, U+9019, U+9038, U+9042, U+906D, U+9177, U+9298, U+934B, U+9375, U+938C, U+9396, U+93AE, U+9451, U+9665, U+968F, U+96F7, U+9700, U+9727, U+9774, U+9801, U+9811, U+9837, U+9855, U+99D2, U+9DF9, U+9EA6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular021.woff2") format("woff2");
  unicode-range: U+2F56, U+2F7A, U+2FCE, U+4E4F, U+4F0D, U+504F, U+5098, U+5132, U+5197, U+51A0, U+51E1, U+51F6, U+52A3, U+52B1, U+5351, U+540E, U+54C9, U+5553, U+55A7, U+5629, U+572D, U+582A, U+584A, U+587E, U+5937, U+5983, U+598A, U+5BB0, U+5BB4, U+5C09, U+5C3F, U+5C65, U+5CE0, U+5D50, U+5DE3, U+5DE7, U+5E06, U+5E61, U+5E7D, U+5EB6, U+5F90, U+6052, U+60A6, U+60DA, U+6109, U+6170, U+6182, U+622F, U+62AB, U+62BD, U+62C9, U+62D3, U+62D9, U+62EC, U+631F, U+633F, U+6414, U+64A4, U+64B2, U+64E6, U+65BC, U+66A6, U+66FE, U+6795, U+67B6, U+6817, U+6843, U+6850, U+68A8, U+68CB, U+68DF, U+69CD, U+6B64, U+6BB4, U+6D1E, U+6D32, U+6D78, U+6DF3, U+6DF5, U+6E67, U+6E7F, U+6EDD, U+6F20, U+6F54, U+6F5F, U+6F64, U+7089, U+722A, U+723A, U+7272, U+72A0, U+72E9, U+72FC, U+732A, U+733F, U+7363, U+73E0, U+73ED, U+751A, U+75BE, U+7656, U+76C6, U+76FE, U+7761, U+7768, U+78C1, U+7949, U+7965, U+7984, U+79D2, U+79E9, U+7A1A, U+7AAE, U+7B48, U+7BC7, U+7BE0, U+7C3F, U+7C8B, U+7C97-7C98, U+7CE7, U+7D17, U+7D5E, U+7DBE, U+7E6B, U+7F8A, U+7FFB, U+8015, U+80C3, U+80E1, U+816B, U+8178, U+819A, U+819C, U+8210, U+8217, U+828B, U+82BD, U+82D7, U+82DB, U+83D3, U+845B, U+84B8, U+84BC, U+84C4, U+84CB, U+8526, U+85DD, U+8607, U+862D, U+86C7, U+86EE, U+8776, U+8870, U+88FE, U+8A93, U+8B19, U+8CA2, U+8CB0, U+8CBC, U+8CED, U+8EC0, U+8EF8, U+8F14, U+8FB1, U+90C1, U+90CA, U+9262, U+9271, U+9285, U+932F, U+9640, U+965B, U+9673, U+9676, U+9694, U+984E, U+99B4, U+99D5, U+99FF, U+9B4F, U+9D28, U+9F13;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular022.woff2") format("woff2");
  unicode-range: U+2F04, U+2F61, U+2F6D, U+2F78, U+2F99, U+4E59, U+4E5E, U+4EAE, U+4F10, U+5091, U+5270, U+52C3, U+537F, U+5384, U+53E1, U+53F1, U+540A, U+541F, U+5448, U+546A, U+5504, U+553E, U+559A, U+55AA, U+55B0, U+564C, U+56C1, U+576A, U+57F9, U+5824, U+583A, U+5840-5841, U+58A8, U+58FA, U+5954, U+5996, U+5A20, U+5B8B, U+5B9B, U+5BEE, U+5C16, U+5CE1, U+5D16, U+5E4C, U+5E63, U+5F26, U+5F70, U+5F84, U+5FAA, U+6020, U+602F, U+606D, U+6070, U+60E3, U+6148, U+61A4, U+61C7, U+61F2, U+6216, U+621A, U+6247, U+628A, U+62D8, U+62F3, U+6349, U+63C9, U+64C1, U+6562, U+6566, U+65CB, U+6602, U+6606, U+6627, U+6643, U+6681, U+6734, U+67AF, U+6851, U+6881, U+68B6, U+68F2, U+690E, U+697C, U+6A3D, U+6B04, U+6B3A, U+6B6A, U+6C70, U+6CE1, U+6D1B, U+6DEB, U+6E26, U+6E9D, U+6EA2, U+6EF4, U+6F2C, U+6F31, U+6FC1, U+6FEF, U+7261-7262, U+731F, U+74E6, U+755C, U+758E, U+75AB, U+75D5, U+75F4, U+7652, U+77B3, U+77DB, U+77E9, U+7815, U+7881, U+7940, U+79E6, U+7ABA, U+7B1B, U+7B26, U+7B87, U+7BB8, U+7D10, U+7D33, U+7D79, U+7DBA, U+7DEF, U+7E2B, U+7E4A, U+7F36, U+7FC1, U+80AA, U+80BA, U+80F4, U+817F, U+8276, U+83C5, U+83E9, U+83F1, U+84B2, U+84EE, U+85CD, U+865C, U+8700, U+8861, U+8881, U+895F, U+8987, U+8A1F, U+8AE6, U+8B21, U+8B5C, U+8C5A, U+8C9D, U+8D99, U+8ECC, U+8EDF, U+8FBB, U+9022, U+904D, U+90ED, U+9175, U+919C, U+920D, U+9326, U+935B, U+9418, U+95A5, U+963B, U+9644, U+9675, U+9699, U+96C0, U+96F0, U+983B, U+98E2, U+98FD, U+9905, U+99B3, U+99C8, U+9AD9, U+9B31, U+9B8E, U+9D8F, U+9EBF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular023.woff2") format("woff2");
  unicode-range: U+2E92, U+2F31, U+2F43, U+2FA9, U+4EAB, U+4EC7, U+4F0E, U+4F3A, U+4F46, U+4FAF, U+4FF5, U+502D, U+50B3, U+5112, U+514E, U+5208, U+52C5, U+52F2, U+53EA, U+547B, U+54B3, U+5538, U+5598, U+559D, U+55C5, U+55E3, U+56DA, U+570B, U+57FC, U+5835, U+5875, U+58B3, U+58CC, U+5968, U+59A5, U+59A8, U+59AC, U+59BE, U+59D1, U+59DC, U+5A92, U+5AC9, U+5B5F, U+5B9C, U+5C3C, U+5CAC, U+5DF3, U+5DFE, U+5E25, U+5E87, U+5F04, U+5FCC, U+6028, U+60F9, U+6115, U+6155, U+61A7, U+6234, U+6276, U+6367, U+642D, U+64AD, U+6590, U+6597, U+66AB, U+66C7, U+66D6, U+66FD, U+6756, U+67A2, U+67CF-67D0, U+67FF, U+683D, U+6876, U+689D, U+695A, U+6BBB, U+6BC5, U+6C72, U+6C7D, U+6CB8, U+6DC0, U+6E07, U+6ECB, U+6EF2, U+6F06, U+6F15, U+708A, U+70CF, U+711A, U+71C8, U+71E5, U+71ED, U+723D, U+72D0, U+74A7, U+75FA, U+7626, U+76BA, U+76C3, U+7891, U+7897, U+78EF, U+79BF, U+7A00, U+7A9F, U+7AAA, U+7AAF, U+7AFF, U+7B39, U+7B51, U+7BE4, U+7C9B, U+7D3A, U+7DB4, U+7DBB, U+7F75, U+8096, U+8098, U+80A2, U+80AF, U+80CE, U+82AF, U+82D1, U+830E, U+83AB, U+8429, U+8463, U+8499, U+8511, U+852D, U+854E, U+85A6, U+85AB, U+8702, U+871C, U+8912, U+8956, U+8A02, U+8A50, U+8A60, U+8A63, U+8A6B, U+8A6E, U+8ACF, U+8AED, U+8AFE, U+8B83, U+8B90, U+8CD1, U+8CE0, U+8D05, U+8D66, U+8D74, U+8DF5, U+8FBF, U+906E, U+9077, U+912D, U+914C, U+916C, U+9192, U+91DC, U+925B, U+92FC, U+9320, U+9591, U+961C, U+9688, U+96B7, U+96BB, U+96F6, U+971E, U+9813, U+990C, U+9A19, U+9A30, U+9AB8, U+9AED, U+9B6F, U+9CE9, U+9D5C, U+9E93, U+20B9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular024.woff2") format("woff2");
  unicode-range: U+2F5B, U+2F60, U+2FCF, U+4E14, U+4E3C, U+4ED4, U+4F3D, U+4F51, U+4FAE, U+4FB6, U+4FE3, U+4FF1, U+50B2, U+50C5, U+5102, U+51B4, U+51FD, U+5243, U+5256, U+52FF, U+533F, U+53A8, U+53AD, U+53D9, U+53DB, U+53F6, U+540F, U+5420, U+543B, U+548E, U+54BD, U+5506, U+5632, U+57A2, U+57C3, U+5815, U+589C, U+5984, U+5993, U+59D0, U+5A3C, U+5A7F, U+5BB5, U+5BC5, U+5CEF, U+5DBA, U+5E16, U+5EE3, U+5F0A, U+5FFD, U+60B6, U+60BC, U+6101, U+6144, U+6168, U+6190-6191, U+61A9, U+62D0, U+62D7, U+632B, U+63C6, U+642C, U+64EC, U+65ED, U+66F3, U+673D, U+674F, U+675C, U+67F5, U+6803, U+685F, U+6897, U+68AF, U+68FA, U+694A, U+6960, U+69CC, U+69FD, U+6A3A, U+6A58, U+6B86, U+6C5D, U+6CCC, U+6D12, U+6D2A, U+6DCB, U+6E13, U+6E15, U+6EBA, U+7027, U+7169, U+717D, U+724C, U+7259, U+727D, U+72FD, U+7336, U+7345, U+73B2, U+73CA, U+7409, U+7435-7436, U+745E, U+7460, U+74DC, U+7525, U+754F, U+7554, U+757F, U+760D, U+764C, U+76F2, U+771E, U+7729, U+7738, U+7766, U+77AD, U+77BC, U+7826, U+786B, U+79B0, U+7A14, U+7A40, U+7A6B, U+7A7F, U+7B25, U+7C1E, U+7C3E, U+7CDE, U+7D2F, U+7D46, U+7F60, U+7FA8, U+8061, U+814E, U+81A3, U+81C6, U+81E5, U+8235, U+8247, U+82A5, U+82D4, U+831C, U+8328, U+832B, U+837B-837C, U+853D, U+8587, U+8594, U+8599, U+85AA, U+85EA, U+85FB, U+868A, U+8766, U+87F9, U+881F, U+88B4, U+88DF, U+88E1, U+88F3, U+8A1D, U+8A3B, U+8A72, U+8ADC, U+8AEB, U+8AEE, U+8CC4, U+8CDC, U+8E2A, U+8E5F, U+8E87, U+8E8A, U+8F3F, U+8F44, U+8F5F, U+8FC5, U+8FE6, U+9010, U+901D, U+9065, U+914E, U+9162, U+91B8, U+91D8, U+92F3, U+932C, U+95B2, U+96DB, U+9798, U+97AD, U+9838, U+9913, U+9AC4, U+9BE8, U+9CF3, U+9DD7, U+9DF2, U+9F20, U+F9EC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular025.woff2") format("woff2");
  unicode-range: U+2F48, U+2F7D, U+2F85, U+4E9B, U+4EA8, U+4F47, U+4FEF, U+5023, U+5118, U+5141, U+51A5, U+51B6, U+51C6, U+51CC, U+51F0, U+5239, U+52FE, U+53C9, U+5484, U+54E2, U+55A9, U+567A, U+5806, U+58D5, U+5957, U+5962, U+597C, U+59E6, U+59FB, U+5A25, U+5A2F, U+5A9A, U+5AE1, U+5BE1, U+5BF5, U+5C4D, U+5C51, U+5C6F, U+5CD9, U+5CE8, U+5D6F, U+5EB8, U+5EC9, U+60E7, U+6284, U+62B9, U+62F7, U+633A, U+6372, U+637B, U+63A0, U+643E, U+647A, U+6492, U+64A5, U+6518, U+6523, U+6591, U+65FA, U+660F, U+6652, U+66F0, U+66FC, U+676D, U+6813, U+6841, U+6893, U+693F, U+698A, U+698E, U+69FB, U+6A0B, U+6A80, U+6A9C, U+6AD3, U+6AFB, U+6B89, U+6DD1, U+6E4A, U+6FE0, U+6FE4, U+704C, U+7092, U+714E, U+71D5, U+723E, U+72D7, U+72F8, U+7325, U+73A9, U+745C, U+7526, U+755D, U+75D9, U+76E7, U+7791, U+7825, U+785D, U+78D0, U+7901, U+7947, U+798D, U+79DF, U+79E4, U+7A92, U+7B95, U+7CA5, U+7CFE, U+7D21, U+7DCB, U+7E1E, U+7E55, U+7E8F, U+7F9E, U+7FE0, U+7FF3, U+800C, U+8036, U+809A, U+80B4, U+8151, U+8154, U+817A, U+81C0, U+81FC, U+8218, U+8236-8237, U+8258, U+82A6, U+82AD, U+8305, U+8389, U+840C, U+840E, U+842C, U+8461, U+8466, U+8475, U+8513, U+8523, U+8549, U+8569, U+85C1, U+8679, U+86CD, U+86D9, U+87BA, U+87EC, U+887F, U+8888, U+895E, U+8B33, U+8B39, U+8CAA, U+8E35, U+8ECB, U+8FC2, U+901E, U+9041, U+9059, U+905C, U+9061, U+918D, U+9190, U+91C7, U+92D2, U+930F, U+93A7, U+9583, U+95A4, U+966A, U+96BC, U+96C1, U+96CC, U+971C, U+9784, U+978D, U+97FB, U+9909, U+9910, U+9945, U+9BC9, U+FA11;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular026.woff2") format("woff2");
  unicode-range: U+2F0F, U+2F14, U+2FA2, U+4E11, U+4E32, U+4E4D, U+4F5B, U+4F86, U+4FC4, U+4FE0, U+4FF8, U+5005, U+5016, U+5036, U+50FB, U+515C, U+51A8, U+51DC, U+51E0, U+51F1, U+51F8-51F9, U+520E, U+52AB, U+5315, U+5347, U+536F, U+5378, U+53E2, U+54A5, U+555C, U+555E, U+55DA, U+55DC, U+55DF, U+55E4, U+5687, U+5703, U+5751, U+5766, U+57D2, U+5830, U+5861, U+589F, U+58F1, U+5B0C, U+5BC7, U+5CFB, U+5D4C, U+5DBD, U+5DCC, U+5DEB, U+5DF4, U+5DF7, U+5E40, U+5ED3, U+5EDF, U+5F14, U+5F1B, U+5F27, U+5F77, U+60DF, U+6167, U+619A, U+622E, U+6309, U+633D, U+634F, U+6377, U+639F, U+63AC, U+63C4, U+63F6, U+646F, U+64B0, U+64E2, U+65AF, U+6666, U+66A2, U+66DD, U+6775, U+67D1, U+6816, U+68A2, U+68F9, U+6900, U+696F, U+6A2B, U+6ABB, U+6ADB, U+6B23, U+6B3D, U+6B4E, U+6BEF, U+6C4E, U+6C50, U+6CAB, U+6E3E, U+6E5B, U+6F38, U+6FEB, U+6FF1, U+7058, U+707C, U+7109, U+7149, U+714C, U+721B, U+725D, U+725F, U+7396, U+745B, U+7483, U+752B, U+7560, U+75BC, U+75E2, U+766A, U+77A0, U+77A5, U+78A7, U+792B, U+7960, U+79B1, U+7A1C, U+7B8B, U+7C95, U+7C9F, U+7CCA, U+7D68, U+7DB8, U+7DFB, U+7E61, U+7E82, U+7F77, U+7F79, U+8017, U+803D, U+805A, U+80DA, U+80E4, U+8106, U+810A, U+8299, U+8338, U+834A, U+8404, U+847A, U+8494, U+86DB, U+8718, U+87FB, U+8882, U+8910, U+8944, U+8A1B, U+8A23, U+8A54, U+8A85, U+8AD2, U+8B01-8B02, U+8B5A, U+8CD3, U+8CE4, U+8CE6, U+8D0B, U+8DE8, U+8E44, U+8F1B, U+8F62, U+8FA3, U+907C-907D, U+9091, U+9127, U+91AC, U+9264, U+9306, U+936C, U+93D1, U+95CA, U+9744, U+978B, U+985A, U+98F4, U+9952, U+9A52, U+9AF7, U+9B41, U+9BAD, U+9BDB, U+9C39, U+9C3B, U+9C57, U+9CF6, U+9D09, U+9D3B, U+9E1E, U+9EB4-9EB5, U+9ECE, U+F993, U+2F8ED;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular027.woff2") format("woff2");
  unicode-range: U+2F18, U+2F8B, U+4E4E, U+4E8E, U+4E98, U+4EA6, U+4EC4, U+4F43, U+5026, U+5039, U+505A, U+50AD, U+50D1, U+5191, U+52D2, U+535C, U+5398, U+53A9, U+5475, U+54A4, U+54AC, U+54E8, U+54ED, U+54FA, U+557C, U+55AC, U+5614, U+5617, U+56CA, U+5821, U+5955, U+5978, U+59EA, U+5A9B, U+5B55, U+5B78, U+5BA5, U+5BD3, U+5C24, U+5C41, U+5C53, U+5C5B, U+5C79, U+5D14, U+5D69, U+5F45, U+5F6C, U+601C, U+604D, U+606B, U+6089, U+60B4, U+60B8, U+60F0, U+61AB, U+61AE, U+61BE, U+61FF, U+620A, U+621F, U+6241, U+626E, U+6289, U+634C, U+65A1, U+65A5, U+65A7, U+6727, U+6853, U+68B5, U+6912, U+6953, U+69B4, U+69D9, U+6A47, U+6A8E, U+6B3E, U+6B7F, U+6BB7, U+6BC0, U+6BD8, U+6C3E, U+6C81, U+6C8C, U+6C93, U+6CC4, U+6CEA, U+6DF9, U+6E1A, U+7015, U+703E, U+7114, U+71BE, U+7280, U+72C4, U+72E1, U+734F, U+745A, U+75B5, U+75B9, U+75D2, U+75E9, U+7624, U+779E, U+77EF, U+78DA, U+798E, U+7A62, U+7A83, U+7AE6, U+7AEA, U+7B19, U+7B75, U+7BDD, U+7C82, U+7CE0, U+7D18, U+7D43, U+7D62, U+7E0B, U+8006, U+805F, U+806F, U+8073, U+808B, U+809B, U+80B1, U+811B, U+814B, U+818F, U+81CD, U+8205, U+821C, U+8233, U+8278, U+8304, U+8309, U+8339, U+8340, U+8431, U+8471, U+84EC, U+8521, U+8543, U+857E, U+8606, U+8654, U+8695, U+86ED, U+8805, U+8823, U+88D4, U+8AA6, U+8AE7, U+8B80, U+8CC2, U+8D14, U+8DA8, U+8DBE, U+8DEA, U+8EB1, U+8EBE, U+8FC4, U+900D, U+9017, U+9075, U+90E4, U+9249, U+932B, U+947F, U+968B, U+96CD, U+9761, U+9870, U+98AF, U+9949, U+99A8, U+9BA8, U+9BAB, U+9D6C, U+9E9F, U+9F4B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular028.woff2") format("woff2");
  unicode-range: U+2F66, U+2FCD, U+4EA5, U+4F98, U+5072, U+5180, U+51DB, U+51EA, U+524C, U+52C1, U+53A0, U+5403, U+5477, U+54B8, U+55C4, U+5631, U+5634, U+56C3, U+5713, U+5718, U+57E0, U+57F4, U+582F, U+58BE, U+58EC, U+5944, U+5A62, U+5B95, U+5C60, U+5CB1, U+5DF2, U+5E37, U+5EFF, U+5F3C, U+5F4C, U+5F57, U+5F59, U+5FA8, U+6063, U+608D, U+60C7, U+60E0, U+61C9, U+6258, U+62F5, U+6369, U+637A, U+638F, U+64F2, U+652A-652B, U+6583, U+6670, U+6688, U+6714, U+6736, U+6753, U+67DA, U+67E9, U+6846, U+6854, U+68CD, U+68D8, U+690B, U+6955, U+6AC3, U+6C40, U+6D8E, U+6DB8, U+6DEE, U+6E20, U+6E25, U+6E58, U+6EF8, U+6FAA, U+6FB1, U+70AC, U+70B8, U+7164, U+7199, U+71D0, U+71E6, U+71FB, U+71FF, U+733E, U+73C2, U+74E2, U+7587, U+758B, U+75E3, U+75F0, U+7647, U+776B, U+777E, U+77E7, U+786F, U+78A9, U+795F, U+7A84, U+7AA9, U+7AC8, U+7B67, U+7B94, U+7C00, U+7C2A, U+7CFA, U+7D72, U+7D9C, U+7E20, U+7F3A, U+8000, U+8058, U+8070, U+8087, U+8129, U+8139, U+81A0, U+81B5, U+81BF, U+81FA, U+8207, U+8292, U+82EB, U+8490, U+84C9, U+84D1, U+8557, U+856A, U+85C9, U+86C6, U+86CB, U+8755, U+87A2, U+880D, U+8822, U+8877, U+88A2, U+88B1, U+890C, U+892A, U+8966, U+8ABC, U+8B10, U+8B17, U+8C79, U+8CB6, U+8CC8, U+8CFD, U+8D73, U+8E72, U+8E99, U+8EEB, U+8F2F, U+8F4D, U+8F9F, U+8FED, U+9021, U+903C, U+9081, U+9087, U+9119, U+929A, U+929C, U+92CF, U+92F8, U+9310, U+9328, U+95BB, U+9716, U+976D, U+986B, U+9957, U+9A55, U+9AEF, U+9B22, U+9B92, U+9C0A, U+9E7C, U+9EBE, U+9F0E, U+9F90, U+FA1F, U+2F877, U+2F8DC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular029.woff2") format("woff2");
  unicode-range: U+2F87, U+4E15, U+4E56, U+4F1C, U+5080, U+5085, U+5121, U+5147, U+51CB, U+51E7, U+51ED, U+524B, U+52BE, U+5301, U+5319, U+5321, U+532A, U+543C, U+5480, U+5556, U+5664, U+5851, U+58DC, U+58FD, U+5925, U+594E, U+59E5, U+5A29, U+5A36, U+5B30, U+5B32, U+5B8D, U+5BE6, U+5C39, U+5D8C, U+5E47, U+5E5F, U+5E96, U+5EE0, U+5F17, U+5F7F, U+5F8A, U+5F98, U+5FB7, U+5FBD, U+6062, U+60A7, U+6147, U+61AC, U+61C3, U+61E3, U+620D-620E, U+622A, U+6292, U+62EE, U+63A9, U+6426, U+6478, U+6703, U+6715, U+6726, U+6883, U+689F, U+6930, U+699B-699C, U+69C7, U+6A05, U+6A84, U+6AAE, U+6B12, U+6BB2, U+6BEB-6BEC, U+6C6A, U+6C83, U+6D1F, U+6D59, U+6DD8, U+6ED4, U+6EFE, U+6F09, U+6F32, U+6F3F, U+7006, U+701F, U+7078, U+7099, U+70D9, U+70F9, U+7184, U+71D7, U+71F5, U+7252, U+73C8, U+7433, U+7455, U+748B, U+7515, U+7576, U+7586, U+75D8, U+7693, U+76E5, U+77B0, U+77DC, U+7887, U+78D4, U+7953, U+79A6, U+79AE, U+79BD, U+7A1F, U+7A46, U+7AD9, U+7AFA, U+7B08, U+7B4F, U+7B72, U+7B92, U+7C50, U+7CAE, U+7D2C, U+7D93, U+7DEC, U+7E23, U+7FF0, U+8180, U+821B, U+826B, U+827E, U+82BB, U+82E7, U+839E, U+83D6, U+840A, U+865E, U+86C9, U+86E4, U+873B, U+87E0, U+8904, U+8977, U+8AB9, U+8AFA, U+8D04, U+8D16, U+8E42, U+8E81, U+8E85, U+8E91, U+9013, U+9089-908A, U+90C3, U+916A, U+91A4, U+91C9, U+91E7, U+9266, U+927E, U+9354, U+937E, U+96EB, U+97A0, U+98C4, U+99C1, U+9B1A, U+9B28, U+9B44, U+9BD6, U+9C2F, U+9C48, U+9C52, U+9DAF, U+9E92, U+9EBA, U+9F3E, U+9F67, U+9F95;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular030.woff2") format("woff2");
  unicode-range: U+2F3D, U+2F44, U+4E19, U+4E58, U+4E99, U+4E9E, U+4EC0, U+4EC6, U+4F36, U+4F69, U+4F83, U+4F91, U+4FCE, U+4FD0, U+4FD8, U+4FDF, U+5043, U+50DE, U+50F9, U+5109, U+514C, U+5152, U+51A4, U+51C9, U+5269, U+527D, U+528D, U+52F3, U+52FA, U+5308, U+5377, U+537D, U+53DF, U+5486, U+54E9, U+54EE, U+5544, U+558A, U+55AE, U+562F, U+565B, U+56A5, U+56B4, U+56BC, U+56EE, U+5700, U+5708, U+5879, U+587C, U+5958, U+5BE8, U+5BEC, U+5C4F, U+5D0B, U+5D17, U+5DAE, U+5E1B, U+5E9A, U+5EEC, U+5F10, U+5F6A, U+5FA0, U+6015, U+6041, U+6043, U+606A, U+6084, U+6194, U+61FA, U+61FC, U+6208, U+620C, U+6248, U+629B, U+62FF, U+6357, U+63BB, U+649E, U+64BC, U+64E1, U+6572, U+6582, U+65A4, U+6635, U+66D9, U+675E, U+6777, U+6787, U+67A1, U+67B7, U+67FE, U+6867, U+68A7, U+68B1, U+68B3, U+6962, U+6977, U+69AE, U+69C3, U+69FF, U+6A59, U+6AC2, U+6CDB, U+6D85, U+6E38, U+6F11, U+6FB9, U+6FFE, U+7119, U+7155, U+722C, U+7232, U+7240, U+731C, U+7370, U+73C0, U+7422, U+7425, U+7432, U+74BD, U+74DA, U+75B1, U+7621, U+7690, U+77EE, U+788D, U+7941, U+79B9, U+79E3, U+7A63, U+7AC4, U+7ADF, U+7AE3, U+7B4D, U+7BAD, U+7C7E, U+7D63, U+7DBD, U+7DDE, U+7E0A, U+7E37, U+7E6D, U+7F8C, U+7FC5, U+80F1, U+8171, U+81C2, U+8201, U+8229, U+822B, U+82B9, U+82FA, U+83A2, U+846D, U+8655, U+86F8, U+86FE, U+87C7, U+8852, U+88C3, U+8A03, U+8A25, U+8ACD, U+8AF7, U+8B04, U+8B16, U+8B6C, U+8D6B, U+8DCB, U+8E93, U+8F61, U+8FF8, U+914B, U+9248, U+929B, U+92E4, U+932E, U+937C, U+9435, U+947D, U+958F, U+95A8, U+97EE, U+9812, U+9824, U+983D, U+9903, U+991E, U+9998, U+99AD-99AE, U+99DD, U+99F1, U+9C13, U+9C3A, U+9DFA, U+9EA9, U+9EF4, U+2F818;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular031.woff2") format("woff2");
  unicode-range: U+2F72, U+2FA3, U+2FC8, U+2FD1, U+57DC, U+5859, U+589E, U+58D8, U+58DE, U+58EF, U+5967, U+596C, U+5A03, U+5B43, U+5B5C, U+5BE2, U+5C07-5C08, U+5C2D, U+5CFD, U+5D1A, U+5DD6, U+5DE2, U+5DFD, U+5E36, U+5EF3, U+5F48, U+5F9E, U+5FB5, U+6046, U+6055, U+608C, U+60E1, U+60FA, U+613C, U+61F7, U+6230, U+6232, U+62C2, U+62D4, U+62DC, U+63ED, U+6416, U+641C, U+64CA, U+651D, U+6536, U+654D, U+660A, U+6634, U+6644, U+664F, U+665A, U+665D, U+665F, U+6668, U+6689, U+66C6, U+66C9, U+67CA, U+67D8, U+681E, U+689B, U+691B, U+6A02, U+6A1F, U+6A23, U+6A6B, U+6AA2, U+6B65, U+6B77, U+6BCF, U+6C23, U+6D35, U+6D38, U+6D6C, U+6D89, U+6DDA, U+6DE8, U+6E34, U+6EAB, U+6EC9, U+6EEF, U+6F23, U+6F81, U+6FD5, U+7028, U+7130, U+71CE, U+71D2, U+722D, U+72C0, U+72F9, U+7378, U+7473, U+7476, U+7562, U+758A, U+758F, U+76DC, U+76E1, U+7827, U+788E, U+7893, U+7955, U+7962, U+7977, U+797F, U+79AA, U+79BE, U+7A3B, U+7A57, U+7A70, U+7A79, U+7CB9, U+7DA0, U+7DD6, U+7DE3, U+7E31, U+7E96, U+7F9A, U+807D, U+81DF, U+838A, U+83EB, U+8420, U+8568, U+8597, U+85B0, U+85CF, U+85E5, U+865B, U+885E, U+88DD, U+89BD, U+8A62, U+8AC4, U+8B20, U+8B93, U+8CE3, U+8CF4, U+8F49, U+8FEA, U+90DE, U+9149, U+9187, U+9189, U+91C0, U+9291, U+9304, U+9318, U+934A, U+93AD, U+9444, U+9677, U+96AA, U+96DC, U+975C, U+980C, U+9817, U+986F, U+98DC, U+9A37, U+9A4D, U+9A57, U+9AEE, U+9DC4, U+9EC3, U+9ED1, U+9ED8, U+9EDB, U+9F4A, U+F91D, U+F928-F929, U+F936, U+F992, U+F9D0, U+FA16, U+FA19-FA1B, U+FA22, U+FA26, U+FA30-FA31, U+FA33;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular032.woff2") format("woff2");
  unicode-range: U+2F89, U+2FA4, U+2FC9, U+4F7C, U+4FA0, U+4FAD, U+5265, U+5283, U+531D, U+5366, U+540B, U+5451, U+548B, U+5516, U+5618, U+5678, U+56A2, U+5764, U+5858, U+586B, U+58F7, U+5919, U+59F6, U+5A41, U+5B2C, U+5C61, U+5CA8, U+5EFC, U+5F4A, U+6108, U+617E, U+63B4, U+63D6, U+649A, U+64B9, U+64FE, U+658C, U+67C1, U+6802, U+6822, U+6834, U+685D, U+68BC, U+68C9, U+6919, U+6934, U+6973, U+6994, U+6A17, U+6A35, U+6A61, U+6A7F, U+6AE8, U+6B1D, U+6D8C, U+6D9B-6D9C, U+6E8C, U+6F45, U+6F97, U+701E, U+7026, U+7194, U+72DB, U+7337, U+73EA, U+7511, U+751C, U+7566, U+7577, U+75D4, U+76C8, U+783A, U+783F, U+7872, U+7895, U+7A17, U+7A4E, U+7A50, U+7AC3, U+7B86, U+7BAA, U+7BED, U+7C38, U+7C81, U+7C8D, U+7CCE, U+7CDF, U+7DAC, U+7E4B, U+7E4D, U+7F6B, U+7FEB, U+807E, U+826E, U+82C5, U+82D3, U+834F, U+83B1, U+83DF, U+83F0, U+844E, U+848B, U+849C, U+8500, U+851A, U+854A, U+85AE-85AF, U+85F7, U+867B, U+86A4, U+86CE, U+8749, U+874B, U+877F, U+88B7, U+8A51, U+8ACC, U+8B2C, U+8CCE, U+8EAF, U+8FE9, U+9197, U+91C6, U+91E6, U+920E, U+9237, U+92EA, U+92F2, U+934D, U+9397, U+939A, U+9419, U+9438, U+9453, U+981A, U+982C, U+9834, U+985B, U+9A28, U+9BAA, U+9BF5, U+9C0D, U+9C10, U+9C2D, U+9D07, U+9D0E, U+9D1B, U+9D2B-9D2C, U+9D60-9D61, U+9E78, U+9EB9, U+9ECD, U+FA34-FA35, U+FA37-FA38, U+FA3A-FA3B, U+FA3D, U+FA3F-FA41, U+FA43-FA48, U+FA4A-FA57, U+FA59-FA5C, U+FA5F, U+FA61-FA65, U+FA67-FA69;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular033.woff2") format("woff2");
  unicode-range: U+2E8E, U+2F02-2F03, U+2F05, U+2F07, U+2F09, U+2F0C-2F0E, U+2F10, U+2F13, U+2F15-2F16, U+2F19-2F1B, U+4E10, U+4E17, U+4E2A, U+4E31, U+4E36, U+4E3F, U+4E42, U+4E55, U+4E62, U+4E82, U+4E85, U+4E8A, U+4E9F-4EA0, U+4EA2, U+4EB0, U+4EB3, U+4EB6, U+4EC2, U+4ECD-4ECE, U+4ED7, U+4EDE-4EDF, U+4EED, U+4EF7, U+4F09, U+4F30, U+4F57, U+4F5A, U+4F5D-4F5E, U+4F6F-4F70, U+4F76, U+4F7B, U+4F88, U+4F8F, U+4F96, U+4FAB, U+4FD1, U+4FD4, U+4FDA-4FDB, U+4FE4-4FE5, U+4FF6, U+4FFE, U+5006, U+500F, U+5011, U+5014, U+501A, U+5021, U+5025, U+5028-502A, U+502C, U+5047-5048, U+5050, U+5055-5056, U+506C, U+5078, U+509A, U+50B4, U+50C2, U+50C9-50CA, U+50D6, U+50E3, U+50E5, U+50ED-50EE, U+50F5, U+5101, U+5114-5116, U+511A, U+5137, U+513A-513C, U+513F-5140, U+5154, U+5162, U+5169-516A, U+516E, U+5182, U+5189, U+518C, U+518F-5190, U+5193, U+5195-5196, U+51A2, U+51A6, U+51A9-51AB, U+51B0-51B3, U+51B5, U+51BD, U+51C5, U+51D6, U+51E9, U+51F5, U+51FE, U+5204, U+520B, U+5214, U+5227, U+522A, U+522E, U+5233, U+5244, U+524F, U+5254, U+525E, U+526A, U+5271, U+5273-5274, U+527F, U+5288, U+5291-5292, U+5294, U+52AC-52AD, U+52B5, U+52BC, U+52CD, U+52D7, U+52DE, U+52E0, U+52E3, U+52E6, U+52F5, U+52F8-52F9, U+5306, U+530D, U+530F-5310, U+531A, U+5323, U+532F, U+5331, U+5333, U+5338, U+5340, U+5345-5346, U+5349, U+534D, U+535E, U+5369, U+536E, U+537B, U+5382, U+5396, U+53A5-53A6, U+53AE, U+53B0, U+53B6, U+53C3, U+53E8, U+53ED-53EE, U+53FA, U+5401, U+541D, U+5429, U+542C-542E, U+5436, U+543D, U+5440, U+544E, U+5470-5471, U+5476;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular034.woff2") format("woff2");
  unicode-range: U+2F1E, U+2F21-2F22, U+548F-5490, U+5492, U+54A2, U+54A8, U+54AB, U+54AF, U+54BC, U+54BE, U+54C2, U+54C4, U+54C7-54C8, U+54D8, U+54E5-54E6, U+54FD, U+550F, U+5514, U+552E, U+5533, U+5539, U+5540, U+5545, U+554C, U+5557, U+555D, U+5563, U+557B, U+557E, U+5580, U+5583, U+5587, U+5599, U+559E-559F, U+55A8, U+55C7, U+55D4, U+55F7, U+55F9, U+55FD-55FE, U+5616, U+561B, U+5636, U+5638, U+564E, U+5650, U+566A-566C, U+5680, U+5686, U+568A, U+568F, U+5694, U+56A0, U+56AE, U+56B6, U+56C0, U+56C2, U+56C8, U+56CE, U+56D1, U+56D3, U+56D7-56D8, U+56F9, U+56FF, U+5704, U+5709, U+570D, U+5716, U+571C, U+5726, U+5737-5738, U+573B, U+5740, U+574E-574F, U+5761, U+5769, U+577F, U+5788-5789, U+5793, U+57A0, U+57A4, U+57AA, U+57B0, U+57B3, U+57C0, U+57C6, U+57D3-57D4, U+57D6, U+57E3, U+580A-580B, U+5819, U+581D, U+583D, U+584B, U+5852, U+5862, U+5870, U+5872, U+5885, U+58AB, U+58AE, U+58B8-58BB, U+58C5, U+58D1, U+58D3, U+58D7, U+58D9, U+58DF, U+58E4-58E5, U+58F9, U+58FB-58FC, U+5902, U+590A, U+5910, U+5918, U+591B, U+592C-592D, U+5932, U+5938, U+593E, U+5950, U+595A, U+5960, U+5969, U+5981, U+598D, U+599B, U+599D, U+59A3, U+59B2, U+59C6, U+59D9-59DA, U+59E8, U+5A09, U+5A11, U+5A1A, U+5A1C, U+5A1F, U+5A35, U+5A40, U+5A49, U+5A6A, U+5A6C, U+5ABC-5ABE, U+5AC2, U+5ACB, U+5AD0, U+5AD6-5AD7, U+5AE3, U+5AE6, U+5AE9, U+5AFA-5AFB, U+5B0B, U+5B16, U+5B2A, U+5B36, U+5B3E, U+5B40, U+5B45, U+5B51, U+5B5A-5B5B, U+5B65, U+5B69, U+5B70-5B71, U+5B73, U+5B75;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular035.woff2") format("woff2");
  unicode-range: U+2E90, U+2E93-2E94, U+2F27, U+2F2A-2F2C, U+2F2E, U+2F33-2F37, U+2F3A-2F3B, U+5B7A, U+5B80, U+5B83, U+5BA6, U+5BB8, U+5BC3, U+5BC9, U+5BD0, U+5BD4, U+5BDE, U+5BE4-5BE5, U+5BEB, U+5BF0, U+5BF3, U+5BF6, U+5C05, U+5C0D, U+5C13, U+5C20, U+5C22, U+5C28, U+5C38, U+5C46, U+5C4E, U+5C50, U+5C6C, U+5C6E, U+5C76, U+5C8C, U+5C91, U+5C94, U+5CAB, U+5CB6-5CB7, U+5CBB-5CBC, U+5CBE, U+5CC5, U+5CC7, U+5CE9-5CEA, U+5CED, U+5CFA, U+5D11, U+5D15, U+5D18-5D19, U+5D1B, U+5D1F, U+5D22, U+5D4B, U+5D4E, U+5D52, U+5D5C, U+5D6C, U+5D73, U+5D76, U+5D82, U+5D84, U+5D87, U+5D90, U+5D9D, U+5DA2, U+5DAC, U+5DB7, U+5DBC, U+5DC9, U+5DCD, U+5DD2-5DD3, U+5DDB, U+5DF5, U+5E0B, U+5E11, U+5E19-5E1A, U+5E43-5E44, U+5E4E, U+5E54, U+5E57, U+5E62, U+5E64, U+5E75-5E76, U+5E7A, U+5E7F, U+5EA0, U+5EC1-5EC2, U+5EC8, U+5ECF-5ED0, U+5ED6, U+5EDA-5EDB, U+5EDD, U+5EE1-5EE2, U+5EE8-5EE9, U+5EF0-5EF1, U+5EF4, U+5EF8, U+5EFE, U+5F03, U+5F09, U+5F0B-5F0D, U+5F11, U+5F16, U+5F29, U+5F2D, U+5F2F, U+5F38, U+5F41, U+5F4E, U+5F51, U+5F56, U+5F5C-5F5D, U+5F61, U+5F6D, U+5F73, U+5F82-5F83, U+5F87-5F88, U+5F91, U+5F99, U+5FAD, U+5FBC, U+5FD6, U+5FDD, U+5FE4, U+5FF0-5FF1, U+5FF8, U+5FFB, U+5FFF, U+600E-6010, U+6019, U+601B, U+6021, U+6026, U+6029, U+602B, U+6031, U+603A, U+6042, U+604A, U+6059-605A, U+605F-6060, U+6064, U+606C, U+6077, U+6081, U+6083, U+608B, U+6092, U+6096-6097, U+609A-609B, U+60B3, U+60B5, U+60BD, U+60C6, U+60D3, U+60D8, U+60F1, U+60F4, U+60F6-60F7, U+60FB, U+6100, U+6103, U+6106, U+610D-610E, U+6121, U+6127-6128, U+612C, U+6134, U+613D-613F, U+6142;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular036.woff2") format("woff2");
  unicode-range: U+2E99, U+2E9B, U+2F41, U+2F46, U+614A, U+614D, U+6153, U+6158-615A, U+615D, U+615F, U+6165, U+616B, U+616F, U+6171, U+6173-6175, U+6177, U+6187, U+618A, U+6196, U+6199, U+61BA, U+61C6, U+61C8, U+61CA-61CD, U+61E6, U+61F4, U+61F6, U+61FD-61FE, U+6200, U+6209, U+6214, U+621B, U+621D-621E, U+6221, U+6233, U+624E, U+625B, U+625E, U+6260, U+6263, U+6268, U+627C, U+627E, U+6282-6283, U+6293-6294, U+6296, U+62AC, U+62BB, U+62C6-62C8, U+62CA, U+62CC, U+62CF, U+62D1, U+62EF, U+62F1, U+6302, U+6308, U+630C, U+6327, U+633E, U+634D, U+6350, U+636B, U+6376, U+6380, U+6389, U+638E, U+6396, U+63A3, U+63AB, U+63B5, U+63BE, U+63C0, U+63D2, U+63E3, U+63E9, U+6406, U+640F, U+6413, U+6417, U+6428, U+6434, U+6436, U+644E, U+6467, U+6476, U+6488, U+6493, U+6495, U+64A9, U+64BB, U+64C2, U+64C5, U+64C7, U+64D2, U+64D4, U+64D8, U+64DA, U+64E0, U+64E3, U+64E7, U+64EF, U+64F1, U+64F4, U+64F6, U+64FA, U+64FD, U+6500, U+6505, U+651C, U+6524, U+652C, U+6534-6535, U+6537-6538, U+6548, U+6555-6556, U+6558, U+655D-655E, U+6578, U+6588, U+659B, U+659F, U+65AB, U+65B7, U+65C1, U+65C3-65C4, U+65C6, U+65CC, U+65D2, U+65D9, U+65DB, U+65E0-65E1, U+65F1, U+65FB, U+6603, U+661C, U+6636, U+663F, U+6641, U+6649, U+665E, U+6662, U+6664, U+6667, U+6683-6684, U+668E, U+6698, U+669D, U+66B8-66B9, U+66BC, U+66BE, U+66C1, U+66C4, U+66DA, U+66E0, U+66E6, U+66E9, U+66F5, U+66F7, U+670F, U+6716, U+671E, U+672E, U+6737-6738, U+673F, U+6741, U+6746, U+6759, U+6760, U+6763-6764, U+676A, U+6770, U+6772-6773, U+677C, U+6785, U+6789, U+678B-678C, U+67A6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular037.woff2") format("woff2");
  unicode-range: U+2F4D-2F4F, U+2F53, U+67A9, U+67B3-67B4, U+67B8-67B9, U+67C6, U+67CE, U+67DD-67DE, U+67E2, U+67E4, U+67E7, U+67EC, U+67EE-67EF, U+6829, U+682B, U+6832, U+6840, U+684D-684E, U+6859, U+6863, U+6874, U+6877, U+687E-687F, U+688D, U+688F, U+6894, U+68A0, U+68A6, U+68AD, U+68B9-68BA, U+68C6, U+68CA, U+68D4-68D5, U+68D7, U+68E0-68E1, U+68E3, U+68E7, U+68EF, U+6901, U+6904, U+6908, U+690C, U+690F, U+691A, U+6921-6923, U+6925-6926, U+6928, U+692A, U+6936, U+6939, U+693D, U+6954, U+6959, U+695C-695E, U+6961, U+696A-696B, U+696E, U+6974, U+6978-6979, U+697E, U+6981, U+6991, U+6995, U+69A0, U+69A7, U+69B1-69B2, U+69BB, U+69BE-69BF, U+69C1, U+69CA, U+69CE, U+69D0, U+69D3, U+69DD-69DE, U+69E7-69E8, U+69EB, U+69ED, U+69F2, U+69F9, U+6A0A, U+6A0C, U+6A12-6A14, U+6A1B, U+6A1E, U+6A22, U+6A2E, U+6A36, U+6A38, U+6A44, U+6A48, U+6A62, U+6A66, U+6A72, U+6A78, U+6A8D, U+6A90, U+6A97, U+6AA0, U+6AA3, U+6AAA, U+6AAC, U+6AB3, U+6AB8, U+6AC1, U+6AD1, U+6ADA, U+6ADE-6ADF, U+6AEA, U+6AFA, U+6B05, U+6B0A, U+6B16, U+6B1F, U+6B37-6B39, U+6B43, U+6B47, U+6B49, U+6B50, U+6B54, U+6B59, U+6B5B, U+6B5F, U+6B61, U+6B78-6B79, U+6B80, U+6B83-6B84, U+6B8D, U+6B95, U+6B98, U+6B9E, U+6BA4, U+6BAA-6BAB, U+6BAF, U+6BB1, U+6BB3, U+6BBC, U+6BC6, U+6BCB, U+6BD3, U+6BDF, U+6BF3, U+6C08, U+6C13-6C14, U+6C1B, U+6C24, U+6C55, U+6C5E, U+6C62, U+6C68, U+6C73, U+6C7E, U+6C82, U+6C8D, U+6C90, U+6C92, U+6C9A-6C9B, U+6CAE, U+6CB1, U+6CBA, U+6CBD-6CBE, U+6CC5, U+6CD3, U+6CD7, U+6CD9;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular038.woff2") format("woff2");
  unicode-range: U+2F58-2F59, U+6CDD, U+6CEF, U+6CF1, U+6D0C, U+6D19, U+6D2B, U+6D33, U+6D36, U+6D3D, U+6D5A, U+6D63-6D64, U+6D79, U+6D93, U+6D95, U+6DB5, U+6DC5-6DC7, U+6DCC, U+6DD2, U+6DD5, U+6DD9, U+6DDE, U+6DE4, U+6DE6, U+6DEA, U+6DEC, U+6DFA, U+6E0A, U+6E19, U+6E1D, U+6E1F, U+6E23-6E24, U+6E2B, U+6E2D-6E2E, U+6E3A, U+6E43, U+6E4D-6E4E, U+6E5F, U+6E6B, U+6E6E, U+6E72, U+6E76, U+6E82, U+6E8F, U+6E98, U+6E9F, U+6EA5, U+6EAA, U+6EAF, U+6EB2, U+6EB7, U+6EBD, U+6EC2, U+6EC4, U+6ECC, U+6ED3, U+6ED5, U+6EEC, U+6EF7, U+6EFF, U+6F13, U+6F3E, U+6F41, U+6F58, U+6F5B, U+6F66, U+6F6D, U+6F6F, U+6F74, U+6F78, U+6F7A, U+6F7C, U+6F80, U+6F82, U+6F86, U+6F8E, U+6F91, U+6FA1, U+6FA3, U+6FB3, U+6FC2, U+6FC6, U+6FD4, U+6FD8, U+6FDB, U+6FDF, U+6FEC, U+6FEE, U+6FF3, U+6FF6, U+6FFA, U+7001, U+7009, U+700B, U+700F, U+7011, U+7018, U+701A-701B, U+701D, U+7030, U+7032, U+7051, U+7063, U+70AE-70AF, U+70B3, U+70CB, U+70DD, U+70DF, U+70F1, U+70FD, U+711C, U+7156, U+7162, U+7165-7166, U+716C, U+7188, U+718F, U+7195, U+71A8, U+71AC, U+71B9, U+71C9, U+71D4, U+71DF-71E0, U+71E7, U+71EC, U+71EE, U+71F9, U+71FC, U+720D, U+7210, U+7228, U+7230, U+723B-723C, U+723F, U+7246, U+724B, U+7258, U+7274, U+727E, U+7281-7282, U+7287, U+7292, U+7296, U+72A2, U+72A7, U+72B2, U+72B9, U+72C3, U+72C6, U+72CE, U+72D2, U+72E0, U+72E2, U+72F7, U+730A, U+7316-7317, U+731D, U+7329, U+732F, U+7334, U+734E, U+7357, U+7368, U+736A, U+7375, U+737A-737B, U+73B3, U+73BB, U+73CE, U+73DE, U+73E5, U+73EE, U+73F1, U+73F8, U+7405;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular039.woff2") format("woff2");
  unicode-range: U+2F68, U+743A, U+743F, U+7441, U+7459, U+745F, U+7463-7464, U+7469-746A, U+746F-7470, U+747E, U+749E, U+74A2, U+74CA, U+74CF, U+74D4, U+74E0, U+74E3, U+74E7, U+74E9, U+74EE, U+74F0-74F2, U+74F7-74F8, U+7503-7505, U+750C-750E, U+7513, U+751E, U+752C, U+7538, U+753C, U+7544, U+7546, U+7549-754B, U+754D, U+755A-755B, U+7564, U+7567, U+7569, U+756B, U+756D, U+7574, U+7578, U+7582, U+7589, U+7594, U+759A, U+759D, U+75A3, U+75A5, U+75B3, U+75B8, U+75BD, U+75C2-75C3, U+75CA, U+75CD, U+75DE, U+75F2-75F3, U+75FC, U+75FE-75FF, U+7601, U+7609, U+760B, U+761F-7620, U+7622, U+7627, U+7630, U+7634, U+763B, U+7646, U+7648, U+7658, U+765C, U+7661-7662, U+7667-7669, U+766C, U+7670, U+7672, U+7676, U+7678, U+767C, U+7680, U+7683, U+7688, U+768B, U+768E, U+7696, U+7699-769A, U+76B0, U+76B4, U+76B7-76B9, U+76C2, U+76CD, U+76D2, U+76D6, U+76DE, U+76EA, U+76FB, U+7704, U+7707-7708, U+771B, U+7724-7726, U+7737, U+7747, U+775A-775B, U+7765, U+7779, U+777F, U+778B, U+778E, U+77B6, U+77B9, U+77BB, U+77BD, U+77BF, U+77C7, U+77CD, U+77D7, U+77DA, U+77E3, U+77FC, U+780C, U+7812, U+7820, U+7845, U+7874, U+787C, U+7886, U+788C, U+789A, U+78A3, U+78AA, U+78AF, U+78B5, U+78BC, U+78BE, U+78C5-78C6, U+78CA-78CB, U+78D1, U+78E7, U+78EC, U+78F4, U+78FD, U+7907, U+7911-7912, U+7919, U+7926, U+792A, U+792C, U+7957, U+795A, U+797A, U+7980, U+798A, U+799D, U+79A7, U+79B3, U+79BA, U+79C9, U+79D5, U+79E1, U+79E7, U+79EC, U+7A08, U+7A0D, U+7A18-7A19, U+7A20, U+7A31, U+7A37, U+7A3E, U+7A43;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular040.woff2") format("woff2");
  unicode-range: U+7A49, U+7A61, U+7A69, U+7A7D, U+7A88, U+7A95-7A98, U+7AB0, U+7AB6, U+7ABF, U+7AC5, U+7AC7, U+7ACA, U+7ACD, U+7ACF, U+7AD2-7AD3, U+7AD5, U+7ADA, U+7ADD, U+7AE1-7AE2, U+7AED, U+7AF0, U+7AF8, U+7B02, U+7B04, U+7B06, U+7B0A-7B0B, U+7B0F, U+7B18, U+7B1E, U+7B28, U+7B33, U+7B35-7B36, U+7B45, U+7B4C, U+7B50, U+7B5D, U+7B65, U+7B6C, U+7B6E, U+7B70-7B71, U+7B74, U+7B7A, U+7B8D, U+7B8F, U+7B98-7B9A, U+7B9C-7B9D, U+7B9F, U+7BB4, U+7BC1, U+7BC6, U+7BCB-7BCC, U+7BCF, U+7BE5-7BE6, U+7BE9, U+7BF3, U+7BF6-7BF7, U+7C07, U+7C0D, U+7C11-7C14, U+7C17, U+7C1F, U+7C23, U+7C27, U+7C2B, U+7C37, U+7C3D, U+7C40, U+7C43, U+7C4C, U+7C4F, U+7C54, U+7C56, U+7C58, U+7C5F, U+7C64-7C65, U+7C6C, U+7C75, U+7C83, U+7C90, U+7CA1-7CA2, U+7CA4, U+7CA8, U+7CAB, U+7CAD, U+7CB1-7CB3, U+7CBD, U+7CC0, U+7CC2, U+7CC5, U+7CD2, U+7CD8, U+7CDC, U+7CE2, U+7CEF, U+7CF2, U+7CF4, U+7CF6, U+7D02, U+7D06, U+7D0A, U+7D15, U+7D1C, U+7D2E, U+7D32, U+7D35, U+7D3F, U+7D45, U+7D4B, U+7D4E-7D4F, U+7D56, U+7D5B, U+7D6E, U+7D73, U+7D7D, U+7D89, U+7D8F, U+7D9B, U+7D9F, U+7DA2-7DA3, U+7DAB, U+7DAE-7DB0, U+7DB5, U+7DC7, U+7DD5, U+7DD8, U+7DDC-7DDD, U+7DE1, U+7DE4, U+7DF2, U+7E05, U+7E09, U+7E12, U+7E1F, U+7E21-7E22, U+7E32, U+7E35, U+7E39-7E3B, U+7E3D, U+7E43, U+7E46, U+7E56, U+7E59-7E5A, U+7E5D-7E5E, U+7E66-7E67, U+7E69-7E6A, U+7E79, U+7E7B-7E7D, U+7E7F, U+7E83, U+7E88-7E89, U+7E8C, U+7E8E, U+7E90, U+7E92-7E94, U+7E9B-7E9C, U+7F38, U+7F45, U+7F4C-7F4E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular041.woff2") format("woff2");
  unicode-range: U+2F79, U+2F7E, U+2F80, U+7F50-7F51, U+7F54-7F55, U+7F58, U+7F5F, U+7F67-7F69, U+7F78, U+7F82-7F83, U+7F86-7F88, U+7F94, U+7F9D, U+7FA3, U+7FAE-7FAF, U+7FB2, U+7FB6, U+7FB8-7FB9, U+7FC6, U+7FCA, U+7FD5, U+7FE1, U+7FE6, U+7FE9, U+7FF9, U+8004, U+800B, U+8012, U+8018-8019, U+801C, U+8021, U+8028, U+803B, U+803F, U+8046, U+804A, U+8052, U+8062, U+8068, U+8072, U+8076, U+8079, U+807F, U+8084-8086, U+8093, U+80AC-80AD, U+80C4, U+80D6, U+80D9, U+80DB, U+80DD, U+80E5, U+80EF, U+80FC, U+8109, U+8123, U+812F, U+813E, U+8146, U+8153, U+815F, U+8165-8166, U+816E, U+8174, U+8182-8183, U+8188, U+818A, U+8193, U+8195, U+81A4, U+81A9, U+81B0, U+81B8, U+81BA, U+81BD-81BE, U+81C8-81C9, U+81D1, U+81D8-81DA, U+81E0, U+81E7, U+81FB, U+81FE, U+8202, U+8209-820A, U+820D, U+8212, U+8216, U+822E, U+8238, U+8240, U+8259-825A, U+825D, U+825F, U+8262, U+8264, U+8268, U+826A, U+8271, U+8277, U+828D, U+829F, U+82AB-82AC, U+82D2, U+82D9, U+82DC, U+82DE-82DF, U+82E1, U+82E3, U+82F3-82F4, U+82F9, U+82FB, U+8303, U+8306, U+8316-8318, U+8323, U+832F, U+8331-8332, U+8334-8335, U+8345, U+8350, U+8373, U+8375, U+8385, U+8387, U+838E, U+8393, U+8396, U+839A, U+839F-83A0, U+83A8, U+83AA, U+83B5, U+83BD, U+83C1, U+83CE, U+83D8, U+83E0, U+83F2, U+83F4, U+83F7, U+83FB, U+83FD, U+8403, U+8407, U+840B, U+840D, U+8413, U+8422, U+842A, U+8435, U+8438, U+843C, U+8446, U+8462, U+8469, U+846B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular042.woff2") format("woff2");
  unicode-range: U+2F8C, U+2F91, U+846E-846F, U+8477, U+8479, U+8482, U+8484, U+849F, U+84A1, U+84AD, U+84B9, U+84BB, U+84BF, U+84C1, U+84C6, U+84CA, U+84CD, U+84D0, U+84D6, U+84D9-84DA, U+84F4, U+84FC, U+84FF, U+8506, U+8514-8515, U+8517-8518, U+851F, U+852C, U+8540-8541, U+8548, U+854B, U+8555, U+8558, U+855A, U+8563, U+856D, U+8577, U+8580, U+8588, U+858A, U+8590-8591, U+859B-859C, U+85A4, U+85A8, U+85B9-85BA, U+85D0, U+85D5, U+85DC, U+85F9-85FA, U+85FE, U+8602, U+860A-860B, U+8613, U+8616-8617, U+861A, U+8622, U+862F-8630, U+863F, U+864D, U+865F, U+8667, U+8671, U+868B-868C, U+8693, U+86A3, U+86A9-86AB, U+86AF-86B0, U+86B6, U+86C4, U+86D4, U+86DE-86DF, U+86E9, U+86EC, U+86EF, U+86F9, U+86FB, U+8703, U+8706, U+8708-870A, U+870D, U+8711-8712, U+871A, U+8725, U+8729, U+8734, U+8737, U+873F, U+874C, U+874E, U+8753, U+8757, U+8759, U+875F-8760, U+8763, U+8768, U+876A, U+876E, U+8774, U+8778, U+8782, U+879F, U+87AB, U+87AF, U+87B3, U+87BB, U+87BD, U+87C0, U+87C4, U+87C6, U+87CB, U+87D0, U+87D2, U+87EF, U+87F2, U+87F6-87F7, U+87FE, U+880E-880F, U+8811, U+8815-8816, U+8821, U+8827, U+8831, U+8836, U+8839, U+883B, U+8842, U+8844, U+884D, U+8859, U+8862, U+886B, U+8872, U+8875, U+887D-887E, U+888D, U+8892, U+8897, U+8899, U+889E, U+88A4, U+88AE, U+88B0, U+88B5, U+88BF, U+88C4, U+88D8-88D9, U+88E8, U+88F2, U+88F4, U+88F9, U+88FC, U+8902, U+890A, U+8913, U+891D-891E, U+8925, U+892B, U+8936, U+8938, U+893B, U+8941, U+8943, U+894C-894D, U+8960, U+8964, U+896A, U+896D, U+896F, U+8974, U+897E, U+8983, U+8988, U+898A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular043.woff2") format("woff2");
  unicode-range: U+2F97-2F98, U+8993, U+8998, U+89A1, U+89A6, U+89A9, U+89AC, U+89AF, U+89B2, U+89BA, U+89BF-89C0, U+89DA, U+89DC-89DD, U+89E7, U+89F4, U+89F8, U+8A0C, U+8A10, U+8A16, U+8A36, U+8A41, U+8A46, U+8A48, U+8A52, U+8A5B, U+8A6C-8A6D, U+8A7C, U+8A82, U+8A84, U+8A91, U+8A9A, U+8AA1, U+8AA3, U+8AA5, U+8AA8, U+8AC2, U+8ADA-8ADB, U+8ADE, U+8AE0-8AE2, U+8AE4, U+8AF1, U+8AF3, U+8B07, U+8B0C, U+8B14, U+8B1A, U+8B26, U+8B28, U+8B2B, U+8B3E, U+8B41, U+8B49, U+8B4C, U+8B4E-8B4F, U+8B56, U+8B5B, U+8B5F, U+8B6B, U+8B6F, U+8B71, U+8B74, U+8B7D, U+8B8A, U+8B8C, U+8B8E, U+8B92, U+8B96, U+8B99-8B9A, U+8C3A, U+8C3F, U+8C41, U+8C48, U+8C4C, U+8C4E, U+8C50, U+8C55, U+8C62, U+8C6B-8C6C, U+8C78, U+8C7A, U+8C7C, U+8C82, U+8C85, U+8C89-8C8A, U+8C8D-8C8E, U+8C94, U+8C98, U+8CAD-8CAE, U+8CB2-8CB3, U+8CBD, U+8CC1, U+8CCD, U+8CDA, U+8CFA-8CFB, U+8D07, U+8D0A, U+8D0D, U+8D0F-8D10, U+8D13, U+8D67, U+8D6D, U+8D71, U+8D81, U+8DBA, U+8DC2, U+8DCC, U+8DCF, U+8DD6, U+8DDA-8DDB, U+8DDF, U+8DE3, U+8DEB, U+8DFC, U+8DFF, U+8E08-8E09, U+8E10, U+8E1D-8E1F, U+8E30, U+8E34, U+8E47-8E4A, U+8E4C, U+8E50, U+8E55, U+8E59, U+8E60, U+8E63-8E64, U+8E76, U+8E7C, U+8E84, U+8E8B, U+8E94, U+8EA1, U+8EAA, U+8EAC, U+8EC5-8EC6, U+8EC8, U+8EDB, U+8EE3, U+8EFB-8EFC, U+8EFE, U+8F05, U+8F0A, U+8F0C, U+8F12-8F13, U+8F15, U+8F19, U+8F1C, U+8F1F, U+8F26, U+8F33, U+8F39, U+8F3B, U+8F3E, U+8F42, U+8F45-8F46, U+8F4C, U+8F4E, U+8F57, U+8F5C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular044.woff2") format("woff2");
  unicode-range: U+8F63-8F64, U+8F9C, U+8FA7-8FA8, U+8FAD-8FAF, U+8FB7, U+8FDA, U+8FE2, U+8FE5, U+8FEF, U+8FF4, U+8FF9-8FFA, U+9005, U+900B, U+900E, U+9011, U+9015-9016, U+9027, U+9035-9036, U+9039, U+903E, U+9049, U+904F-9052, U+9056, U+9058, U+905E, U+9068, U+906F, U+9072, U+9076, U+9080, U+9082-9083, U+908F, U+90A8, U+90AF, U+90B1, U+90B5, U+90DB, U+90E2, U+9102, U+9112, U+9130, U+9132, U+914A, U+9156, U+9158, U+9163, U+9165, U+9169, U+9172-9173, U+9182, U+918B, U+91A2, U+91AA-91AB, U+91AF, U+91B4-91B5, U+91BA, U+91C1, U+91CB, U+91D0, U+91D6, U+91DB, U+91DF, U+91E1, U+91F5-91F6, U+91FC, U+91FF, U+9211, U+9214-9215, U+921E, U+9229, U+922C, U+923F, U+9245, U+924B, U+9250, U+9257, U+925A, U+925E, U+9293, U+9295-9296, U+92B7, U+92B9, U+92E9, U+92FA, U+9319-931A, U+9322-9323, U+9335, U+933A-933B, U+9344, U+9356, U+935C, U+9360, U+936E, U+9394, U+93AC, U+93B0, U+93B9, U+93C3, U+93C8, U+93D0, U+93D6-93D8, U+93DD, U+93E4-93E5, U+93E8, U+9403, U+9407, U+9410, U+9413-9414, U+941A, U+9421, U+942B, U+9436, U+943A, U+9441, U+9452, U+945A-945B, U+945E, U+9460, U+9462, U+946A, U+9470, U+9475, U+9477, U+947C, U+947E, U+9481, U+9582, U+9587, U+958A, U+9594, U+9596, U+9598-9599, U+95A0, U+95A7, U+95AD, U+95B9, U+95BC, U+95BE, U+95C3, U+95CC-95CD, U+95D4-95D6, U+95DC, U+95E1-95E2, U+95E5, U+9621, U+9628, U+962E-962F, U+9642, U+964B-964C, U+964F, U+965C-965D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular045.woff2") format("woff2");
  unicode-range: U+2FAA-2FAB, U+2FB1-2FB2, U+2FBD-2FC0, U+965E-965F, U+9666, U+966C, U+9672, U+968D, U+9695, U+9697-9698, U+96A7-96A8, U+96B0-96B2, U+96B4, U+96B6, U+96B8-96B9, U+96C9, U+96CB, U+96CE, U+96D5-96D6, U+96D9, U+96F9, U+9704, U+9706, U+9708, U+970D-970F, U+9711, U+9713, U+9719, U+9724, U+972A, U+9730, U+9738-9739, U+973D-973E, U+9742, U+9746, U+9748-9749, U+9760, U+9764, U+9766, U+9768, U+976B, U+9771, U+9779-977A, U+977C, U+9781, U+9785-9786, U+978F-9790, U+979C, U+97A3, U+97A6, U+97A8, U+97AB, U+97B3-97B4, U+97C3, U+97C6, U+97C8, U+97CB, U+97DC, U+97ED, U+97F2, U+97F5-97F6, U+980F, U+9821, U+9846, U+984B, U+984F, U+9871, U+9873-9874, U+98AA, U+98B1, U+98B6, U+98C3, U+98C6, U+98E9, U+98EB, U+98ED-98EE, U+9912, U+9914, U+9918, U+991D, U+9920-9921, U+9924, U+992C, U+992E, U+993D-993E, U+9942, U+994B-994C, U+9950-9951, U+9955, U+9997, U+99A5, U+99BC, U+99D1, U+99D8, U+99DB, U+99DF, U+99E2, U+99ED-99EE, U+99F2, U+99F8, U+99FB, U+9A01, U+9A05, U+9A0F, U+9A2B, U+9A3E, U+9A40, U+9A42-9A43, U+9A45, U+9A5B, U+9A5F, U+9A62, U+9A64-9A65, U+9A69-9A6B, U+9AAD, U+9AB0, U+9ABC, U+9AC0, U+9ACF, U+9AD1, U+9AD3-9AD4, U+9ADE-9ADF, U+9AE2-9AE3, U+9AE6, U+9AEB, U+9AF1, U+9AF4, U+9AFB, U+9B06, U+9B18, U+9B1F, U+9B23, U+9B25, U+9B27, U+9B29-9B2A, U+9B2E-9B2F, U+9B32, U+9B3B, U+9B43, U+9B4D-9B4E, U+9B51, U+9B58, U+9B74, U+9B83, U+9B91, U+9B93, U+9B96-9B97, U+9B9F-9BA0, U+9BB4, U+9BB9, U+9BC0, U+9BC6, U+9BCA, U+9BCF, U+9BD1-9BD2, U+9BD4, U+9BE1-9BE3;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular046.woff2") format("woff2");
  unicode-range: U+2F01, U+2FC4, U+2FC6, U+2FCB-2FCC, U+2FD2, U+2FD4-2FD5, U+3402, U+34B5, U+34DB, U+378D, U+37E2, U+3B22, U+3BB6, U+3BC3, U+3C0F, U+3E3F, U+3F72, U+4264, U+4453, U+445B, U+459D, U+45EA, U+4844, U+49B0, U+4C17, U+4E28, U+4E2F-4E30, U+4E8D, U+4EE1, U+4EFD, U+4EFF, U+4F03, U+4F0B, U+4F48-4F49, U+4F56, U+4F5F-4F60, U+4F6A, U+4F6C, U+4F7E, U+4F8A, U+4F94, U+4F97, U+4FC9, U+5001-5002, U+500E, U+5018, U+5027, U+502E, U+503B, U+5040-5041, U+5094, U+50CC, U+50D0, U+50E6, U+50F2, U+5103, U+5106, U+510B, U+511E, U+9BE4, U+9BF0-9BF2, U+9C04, U+9C06, U+9C08-9C09, U+9C0C, U+9C12, U+9C14-9C15, U+9C1B, U+9C21, U+9C24-9C25, U+9C2E, U+9C30, U+9C32, U+9C3E, U+9C46-9C47, U+9C5A, U+9C60, U+9C67, U+9C76, U+9C78, U+9CE7, U+9CEB-9CEC, U+9CF0, U+9D03, U+9D06, U+9D08, U+9D12, U+9D15, U+9D1F, U+9D23, U+9D26, U+9D2A, U+9D3E-9D3F, U+9D41, U+9D44, U+9D46, U+9D48, U+9D50-9D51, U+9D59, U+9D5D-9D5E, U+9D64, U+9D6F, U+9D72, U+9D7A, U+9D87, U+9D89, U+9D9A, U+9DA4, U+9DA9, U+9DAB, U+9DB2, U+9DB8, U+9DBA-9DBB, U+9DC1-9DC2, U+9DC6, U+9DCF, U+9DD3, U+9DD9, U+9DE6, U+9DED, U+9DEF, U+9DF8, U+9DFD, U+9E1A-9E1B, U+9E75, U+9E79, U+9E7D, U+9E81, U+9E88, U+9E8B-9E8C, U+9E91, U+9E95, U+9E9D, U+9EA5, U+9EAA, U+9EAD, U+9EB8, U+9EBC, U+9ECC, U+9ECF-9ED0, U+9ED4, U+9EDC-9EDE, U+9EE0, U+9EE5, U+9EE8, U+9EEF, U+9EF6-9EF7, U+9EF9, U+9EFB-9EFD, U+9F07-9F08, U+9F15, U+9F21, U+9F2C, U+9F4E-9F4F, U+9F52, U+9F54, U+9F5F-9F61, U+9F63, U+9F66, U+9F6A, U+9F6C, U+9F72, U+9F76-9F77, U+9F9C-9F9D, U+9FA0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular047.woff2") format("woff2");
  unicode-range: U+2E8F, U+5135, U+514A, U+5155, U+5157, U+519D, U+51C3, U+51CA, U+51DE, U+51E2, U+51EE, U+5201, U+5213, U+5215, U+5249, U+5257, U+5261, U+5293, U+52C8, U+52CC, U+52D0, U+52D6, U+52DB, U+52F0, U+52FB, U+5300, U+5307, U+531C, U+5361, U+5363, U+5393, U+539D, U+53B2, U+5412, U+5427, U+544D, U+546B, U+5474, U+547F, U+5488, U+5496, U+549C, U+54A1, U+54A9, U+54C6, U+54FF, U+550E, U+552B, U+5535, U+5550, U+5581, U+5586, U+558E, U+55AD, U+55CE, U+5608, U+560E, U+563B, U+5649, U+5666, U+566F, U+5671-5672, U+5676, U+569E, U+56A9, U+56AC, U+56B3, U+56C9, U+570A, U+5721, U+572F, U+5733-5734, U+5770, U+5777, U+577C, U+579C, U+57B8, U+57C7-57C8, U+57CF, U+57E4, U+57ED, U+57F5-57F6, U+57FF, U+5809, U+5864, U+5889, U+58A9, U+58CE, U+58D2, U+58D4, U+58DA, U+58E0, U+58E9, U+590C, U+595D, U+596D, U+598B, U+5992, U+59A4, U+59C3, U+59D2, U+59DD, U+59F8, U+5A13, U+5A23, U+5A67, U+5A6D, U+5A77, U+5A7E, U+5A84, U+5A9E, U+5AA7, U+5AC4, U+5B19, U+5B25, U+5B41, U+5B56, U+5B7D, U+5B93, U+5BD8, U+5C12, U+5C1E, U+5C23, U+5C2B, U+5C62, U+5C7A, U+5C8F, U+5C9F, U+5CA3, U+5CAA, U+5CBA, U+5CCB, U+5CD0, U+5CD2, U+5CF4, U+5D0D, U+5D27, U+5D46-5D47, U+5D4A, U+5D53, U+5D6D, U+5D81, U+5DA0, U+5DA4, U+5DA7, U+5DB8, U+5DCB, U+5E14, U+5E18, U+5E58, U+5E5E, U+5E77, U+5EBE, U+5ECB, U+5EF9, U+5F00, U+5F02, U+5F07, U+5F1D, U+5F23, U+5F34, U+5F36, U+5F3D, U+5F40, U+5F54, U+5F58, U+5F64, U+5F67, U+5F7D, U+5F89, U+5F9C, U+5FA7, U+5FAF, U+5FC9, U+5FDE, U+5FE1, U+5FE9, U+600D, U+6014, U+6018;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular048.woff2") format("woff2");
  unicode-range: U+6033, U+6035, U+6047, U+609D-609E, U+60CB, U+60D4-60D5, U+60DD, U+60F8, U+611C, U+612B, U+6130, U+6137, U+618D, U+61B9, U+61BC, U+6222, U+623E, U+6243, U+6256, U+625A, U+626F, U+6285, U+62C4, U+62D6, U+62FC, U+630A, U+6318, U+6339, U+6343, U+6365, U+637C, U+63E5, U+63F5, U+6410, U+6422, U+6460, U+646D, U+6479, U+64BE-64BF, U+64C4, U+64CE, U+64D0, U+64F7, U+64FB, U+6522, U+6529, U+6567, U+659D, U+6600, U+6609, U+6615, U+661E, U+6622, U+6624, U+662B, U+6630-6631, U+6633, U+663A, U+6648, U+664C, U+6659, U+6661, U+6665, U+6673, U+6677-6678, U+668D, U+66A0, U+66B2, U+66BB, U+66C8, U+66DB, U+66E8, U+66FA-66FB, U+6713, U+6733, U+6747-6748, U+6766, U+677B, U+6781, U+6793, U+6798, U+679B, U+67BB, U+67C0, U+67D7, U+67F9, U+67FC, U+6801, U+681D, U+682C, U+6831, U+6852, U+685B, U+6872, U+6875, U+68A3, U+68A5, U+68B2, U+68C8, U+68D0, U+68E8, U+68ED, U+68F0-68F1, U+68FC, U+690A, U+6935, U+6942, U+6949, U+6957, U+6963-6964, U+6968, U+6980, U+69A5, U+69AD, U+69CF, U+69E2, U+69E9-69EA, U+69F5-69F6, U+6A0F, U+6A15, U+6A3B, U+6A3E, U+6A45, U+6A50, U+6A56, U+6A5B, U+6A73, U+6A89, U+6A94, U+6A9D-6A9E, U+6AA5, U+6AE4, U+6AE7, U+6B1B, U+6B1E, U+6B2C, U+6B35, U+6B46, U+6B56, U+6B60, U+6B67, U+6B82, U+6BA9, U+6BAD, U+6BD6-6BD7, U+6BFF, U+6C05, U+6C10, U+6C33, U+6C59, U+6C5C, U+6C74, U+6C76, U+6C85-6C86, U+6C98, U+6C9C, U+6CAA, U+6CC6, U+6CD4, U+6CE0, U+6CEB, U+6CEE, U+6CFB, U+6D04, U+6D0E, U+6D2E, U+6D31, U+6D39, U+6D3F, U+6D58, U+6D65, U+6D82, U+6D87, U+6D94, U+6DAA;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular049.woff2") format("woff2");
  unicode-range: U+2F67, U+6DAC, U+6DBF, U+6DC4, U+6DD6, U+6DDB, U+6DDD, U+6DFC, U+6E44, U+6E5C, U+6E5E, U+6EB1, U+6EC1, U+6EC7, U+6ECE, U+6F10, U+6F1A, U+6F2A, U+6F2F, U+6F33, U+6F51, U+6F59, U+6F5E, U+6F61-6F62, U+6F7E, U+6F88, U+6F8C-6F8D, U+6F94, U+6FA0, U+6FA7, U+6FB6, U+6FBC, U+6FC7, U+6FCA, U+6FF0, U+6FF5, U+6FF9, U+7005, U+704A, U+704E, U+705D-705E, U+7064, U+7075, U+7085, U+70A4, U+70AB, U+70B7, U+70D4, U+70D8, U+70E4, U+710F, U+711E, U+7120, U+712B, U+712E, U+7146-7147, U+7151-7152, U+715C, U+7160, U+7168, U+7185, U+7187, U+7192, U+71BA, U+71C1, U+71C4, U+71FE, U+7200, U+7215, U+7255-7256, U+728D, U+729B, U+72BE, U+72FB, U+7327-7328, U+7350, U+7366, U+737C, U+7395, U+739F-73A0, U+73A2, U+73A6, U+73AB, U+73C9, U+73CF, U+73D6, U+73D9, U+73E3, U+73E9, U+7407, U+740A, U+741A-741B, U+7426, U+7428, U+742A-742C, U+742E-7430, U+7444, U+7446-7447, U+744B, U+7457, U+7462, U+746B, U+746D, U+7486-7487, U+7489, U+7490, U+7498, U+749C, U+749F, U+74A3, U+74A6, U+74A8-74A9, U+74B5, U+74BF, U+74C8-74C9, U+74FF, U+7501, U+7517, U+752F, U+756F, U+7579, U+7592, U+75CE, U+75E4, U+7600, U+7602, U+7608, U+7615-7616, U+7619, U+761E, U+762D, U+7635, U+7643, U+764B, U+7664-7665, U+766D, U+766F, U+7671, U+7681, U+769B, U+769D-769E, U+76A6, U+76AA, U+76B6, U+76C5, U+76CC, U+76CE, U+76D4, U+76E6, U+76F1, U+76FC, U+770A, U+7719, U+7734, U+7736, U+7746, U+774D-774E, U+775C, U+775F, U+7762, U+777A, U+7780, U+7794, U+77AA, U+77E0, U+782D, U+7843, U+784E-784F, U+7851, U+7868;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular050.woff2") format("woff2");
  unicode-range: U+2F71, U+786E, U+78AD, U+78B0, U+78E4, U+78F2, U+78F7, U+7900, U+791C, U+792E, U+7931, U+7934, U+7945-7946, U+795C, U+7979, U+7998, U+79B8, U+79C8, U+79CA, U+79D4, U+79DE, U+79EB, U+79ED, U+7A03, U+7A39, U+7A5D, U+7A6D, U+7A85, U+7AA0, U+7AB3, U+7ABB, U+7ACE, U+7AEB, U+7AFD, U+7B12, U+7B2D, U+7B3B, U+7B47, U+7B4E, U+7B60, U+7B6D, U+7B6F, U+7B9E, U+7BD7, U+7BD9, U+7C01, U+7C20, U+7C31, U+7C33, U+7C36, U+7C59, U+7C6D, U+7C79, U+7C8F, U+7C94, U+7CA0, U+7CBC, U+7CD5, U+7CD9, U+7CDD, U+7D07-7D08, U+7D13, U+7D1D, U+7D23, U+7D31, U+7D41, U+7D48, U+7D53, U+7D5C, U+7D7A, U+7D83, U+7D8B, U+7DA6, U+7DC2, U+7DCC, U+7E08, U+7E11, U+7E15, U+7E28, U+7E47, U+7E52, U+7E8A, U+7E8D, U+7F47, U+7F91, U+7F97, U+7FBF, U+7FCE, U+7FDB, U+7FDF, U+7FEC, U+7FEE, U+7FFA, U+8014, U+8026, U+8035, U+8037, U+803C, U+80CA, U+80D7, U+80E0, U+80F3, U+8118, U+814A, U+8160, U+8167-8168, U+816D, U+81BB, U+81CA, U+81CF, U+81D7, U+8260, U+8274, U+828E, U+82A1, U+82A3-82A4, U+82A9, U+82AE, U+82B7, U+82BE-82BF, U+82C6, U+82D5, U+82FD-82FE, U+8300-8301, U+8322, U+832D, U+833A, U+8343, U+8347, U+8351, U+8355, U+8362, U+837D, U+8386, U+8392, U+8398, U+83A7, U+83A9, U+83BF-83C0, U+83C7, U+83CF, U+83D1, U+83E1, U+83EA, U+8401, U+8406, U+8448, U+845F, U+8470, U+8473, U+8485, U+849E, U+84AF, U+84B4, U+84BA, U+84C0, U+84C2, U+851E, U+852F, U+8532, U+8559, U+8564, U+857A, U+858C, U+858F, U+85A2, U+85AD, U+85CB, U+85CE, U+85ED, U+85FF, U+8604-8605, U+8610, U+8612, U+8618, U+8629, U+8638, U+8641, U+8657, U+8662;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular051.woff2") format("woff2");
  unicode-range: U+2FA1, U+866C, U+8675, U+8698, U+86B8, U+86FA, U+86FC-86FD, U+870B, U+8771, U+8787-8788, U+87AC-87AD, U+87B5, U+87D6, U+8806, U+880A, U+8810, U+8814, U+8898, U+88AA, U+88CA, U+88CE, U+88F5, U+8918-891A, U+891C, U+8927, U+8930, U+8932, U+8939, U+8940, U+8994, U+89D4, U+89E5, U+89F6, U+8A12, U+8A15, U+8A22, U+8A37, U+8A47, U+8A4E, U+8A5D, U+8A61, U+8A75, U+8A79, U+8AA7, U+8AD0, U+8ADF, U+8AF4, U+8AF6, U+8B46, U+8B54, U+8B59, U+8B69, U+8B9D, U+8C49, U+8C68, U+8CE1, U+8CF8, U+8CFE, U+8D12, U+8D1B, U+8DAF, U+8DCE, U+8DD1, U+8DD7, U+8E20, U+8E23, U+8E3D, U+8E70, U+8E7B, U+8EFA, U+8F1E, U+8F2D, U+8F36, U+8F54, U+8FA6, U+8FB5, U+8FE4, U+8FE8, U+8FEE, U+9008, U+902D, U+9088, U+9095, U+9097, U+9099, U+909B, U+90A2, U+90B3, U+90BE, U+90C4-90C5, U+90C7, U+90D7, U+90DD, U+90EF, U+90F4, U+9114-9116, U+9122-9123, U+912F, U+9131, U+9134, U+913D, U+9148, U+915B, U+9183, U+919E, U+91B1, U+91BC, U+91D7, U+91E4-91E5, U+91ED, U+91F1, U+91FB, U+9207, U+9210, U+9238-923A, U+923C, U+9240, U+9243, U+924F, U+9278, U+9288, U+92C2, U+92CB-92CC, U+92D3, U+92E0, U+92FF, U+931F, U+9321, U+9325, U+9348-9349, U+9364-9365, U+936A, U+9370, U+939B, U+93A3, U+93BA, U+93C6, U+93DE-93DF, U+93FD, U+9404, U+9433, U+944A, U+9463, U+946B, U+9471-9472, U+958E, U+959F, U+95A6, U+95A9, U+95AC, U+95B6, U+95BD, U+95CB, U+95D0, U+95D3, U+95DA, U+95DE, U+9658, U+9684, U+969D, U+96A4-96A5, U+96D2, U+96DE, U+96E9, U+96EF, U+9733, U+973B, U+974D-974F, U+975A, U+976E, U+9773, U+9795;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular052.woff2") format("woff2");
  unicode-range: U+2E97, U+3406, U+342C, U+342E, U+3468, U+346A, U+3492, U+34BC, U+34C1, U+34C7, U+351F, U+355D-355E, U+3563, U+356E, U+35A6, U+35A8, U+35C5, U+35DA, U+35F4, U+3605, U+364A, U+3691, U+3696, U+3699, U+36CF, U+3761-3762, U+376B-376C, U+3775, U+37C1, U+37E8, U+37F4, U+37FD, U+3800, U+382F, U+3836, U+3840, U+385C, U+3861, U+38FA, U+3917, U+391A, U+396F, U+3A6E, U+3A73, U+3AD6-3AD7, U+3AEA, U+3B0E, U+3B1A, U+3B1C, U+3B6D, U+3B77, U+3B87-3B88, U+3B8D, U+3BA4, U+3BCD, U+3BF0, U+97AE, U+97BA, U+97C1, U+97C9, U+97DB, U+97DE, U+97F4, U+980A, U+981E, U+982B, U+9852-9853, U+9856-9857, U+9859, U+9865, U+986C, U+98BA, U+98C8, U+98E7, U+9958, U+999E, U+9A02-9A03, U+9A24, U+9A2D-9A2E, U+9A38, U+9A4A, U+9A4E, U+9AB6, U+9AC1, U+9AC3, U+9ACE, U+9AD6, U+9AF9, U+9B02, U+9B08, U+9B20, U+9B2D, U+9B5E, U+9B66, U+9B72, U+9B75, U+9B79, U+9B84, U+9B8A, U+9B8F, U+9B9E, U+9BA7, U+9BC1, U+9BCE, U+9BE5, U+9BF8, U+9BFD, U+9C00, U+9C23, U+9C41, U+9C4F-9C50, U+9C53, U+9C63, U+9C65, U+9C77, U+9D1D-9D1E, U+9D43, U+9D47, U+9D52, U+9D63, U+9D70, U+9D7C, U+9D8A, U+9D96, U+9DAC, U+9DBC, U+9DC0, U+9DE7, U+9E07, U+9E15, U+9E9E, U+9EA4, U+9EAC, U+9EAF, U+9F10, U+9F39, U+9F57, U+9F94, U+9F97, U+9FA2, U+F970, U+F9DC, U+FA0F-FA10, U+FA14-FA15, U+FA32, U+FA36, U+FA39, U+FA3C, U+FA3E, U+FA42, U+FA60, U+FA6A, U+2000B, U+2123D, U+2131B, U+2146E, U+216B4, U+218BD, U+21E34, U+231C4, U+235C4, U+2373F, U+23763, U+23CFE, U+247F1, U+2548E, U+2550E, U+25771, U+259C4, U+25DA1, U+26AFF, U+26E40, U+270F4, U+27684, U+28277, U+283CD, U+2A190;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular053.woff2") format("woff2");
  unicode-range: U+2E83, U+2E85, U+2EA6, U+3C26, U+3CC3, U+3CD2, U+3D11, U+3D1E, U+3D64, U+3D9A, U+3DC0, U+3DD4, U+3E05, U+3E60, U+3E66, U+3E68, U+3E83, U+3E94, U+3F57, U+3F75, U+3F77, U+3FAE, U+3FC9, U+3FD7, U+4039, U+4058, U+4093, U+4105, U+4148, U+414F, U+4163, U+41B4, U+41BF, U+41E6, U+41EE, U+41F3, U+4207, U+420E, U+42C6, U+42D6, U+42DD, U+4302, U+432B, U+4343, U+43EE, U+43F0, U+4408, U+4417, U+441C, U+4422, U+4476, U+447A, U+4491, U+44B3, U+44BE, U+44D4, U+4508, U+450D, U+4525, U+4543, U+45B8, U+45E5, U+460F, U+4641, U+4665, U+46A1, U+46AF, U+470C, U+4764, U+47FD, U+4816, U+484E, U+48B5, U+49E7, U+49FA, U+4A04, U+4A29, U+4ABC, U+4B3B, U+4BC2, U+4BCA, U+4BD2, U+4BE8, U+4C20, U+4CC4, U+4CD1, U+4D07, U+4D77, U+4E02, U+4E0F, U+4E12, U+4E29, U+4E2B-4E2C, U+4E2E, U+4E40, U+4E47-4E48, U+4E51, U+4E5A, U+4E69, U+4E9D, U+4EB9, U+4EBB-4EBC, U+4EC3, U+4EC8, U+4ED0, U+4EDA, U+4EEB, U+4EF1, U+4EF5, U+4F00, U+4F16, U+4F37, U+4F3E, U+4F54, U+4F58, U+4F64, U+4F77-4F78, U+4F7A, U+4F7D, U+4F82, U+4F85, U+4F92, U+4F9A, U+4FB2, U+4FBE, U+4FC5, U+4FCB, U+4FCF, U+4FD2, U+4FE6, U+4FF2, U+5000, U+5010, U+5013, U+501C, U+501E, U+5022, U+5042, U+5046, U+504E, U+5053, U+5057, U+5063, U+5066, U+506A, U+5070, U+5088, U+5092-5093, U+5095-5096, U+509C, U+50A3, U+50AA, U+50B1, U+50BA-50BB, U+50C4, U+50C7, U+50CE, U+50D4, U+50D9, U+50E1, U+50E9, U+50F3, U+5108, U+5117, U+511B, U+5160, U+5173, U+517B, U+5183, U+518B, U+5198, U+51A3, U+51AD, U+51BC, U+51F3-51F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular054.woff2") format("woff2");
  unicode-range: U+2E89, U+5202, U+5212, U+5216, U+5255, U+525C, U+526C, U+5277, U+5282, U+5284, U+5298, U+52A4, U+52A6, U+52AF, U+52BA-52BB, U+52CA, U+52D1, U+52F7, U+530A-530B, U+5324, U+5335, U+533E, U+5342, U+5367, U+536C, U+537A, U+53A4, U+53B4, U+53B7, U+53C0, U+53D5, U+53DA, U+53F4-53F5, U+5424, U+5428, U+5443, U+5455, U+5462, U+5466, U+546C, U+548A, U+548D, U+5495, U+54A0, U+54A6, U+54AD-54AE, U+54B7, U+54BA, U+54BF, U+54C3, U+54EC, U+54EF, U+54F1, U+54F3, U+5500-5501, U+5509, U+553C, U+5541, U+5547, U+554A, U+5560-5561, U+5564, U+557D, U+5582, U+5588, U+5591, U+55BF, U+55C9, U+55CC, U+55D1-55D2, U+55DD, U+55E2, U+55E9, U+5607, U+5610, U+5628, U+5630, U+5637, U+563D, U+563F-5640, U+5647, U+565E, U+5660, U+566D, U+5688, U+568C, U+5695, U+569A, U+569D, U+56A8, U+56AD, U+56B2, U+56C5, U+56CD, U+56DF, U+56E8, U+56F6-56F7, U+5715, U+5723, U+5729, U+5745-5746, U+574C-574D, U+5768, U+576F, U+5773-5775, U+577B, U+579A, U+579D-579E, U+57A8, U+57AC, U+57CC, U+57D7, U+57DE, U+57E6, U+57F0, U+57F8, U+57FB, U+57FD, U+5804, U+581E, U+5820, U+5827, U+5832, U+5839, U+5849, U+584C, U+5867, U+588A-588B, U+588D, U+588F-5890, U+5894, U+589D, U+58AA, U+58B1, U+58C3, U+58CD, U+58E2, U+58F3-58F4, U+5905-5906, U+590B, U+590D, U+5914, U+5924, U+593D, U+5946, U+595B, U+595F, U+5975-5976, U+599F, U+59AE, U+59BC, U+59C8, U+59CD, U+59DE, U+59E3-59E4, U+59E7, U+59EE, U+5A0C-5A0D, U+5A17, U+5A27, U+5A2D, U+5A55, U+5A65, U+5A7A, U+5A8B, U+5A9C, U+5A9F-5AA0, U+5AA2, U+5AB1, U+5AB3, U+5AB5, U+5ABA, U+5ABF, U+5ADA, U+5ADC, U+5AE0, U+5AE5, U+5AEE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular055.woff2") format("woff2");
  unicode-range: U+2E96, U+2E98, U+5AF0, U+5AF5, U+5B00, U+5B08, U+5B17, U+5B2D, U+5B34, U+5B4C, U+5B52, U+5B68, U+5B6F, U+5B7C, U+5B7F, U+5B81, U+5B84, U+5B96, U+5BAC, U+5BC0, U+5BCE, U+5BD6, U+5BF1, U+5BFD, U+5C03, U+5C29, U+5C30, U+5C5F, U+5C63, U+5C67-5C69, U+5C70, U+5C7C, U+5C88, U+5C8A, U+5CA0, U+5CA2, U+5CA6-5CA7, U+5CAD, U+5CB5, U+5CC9, U+5D06, U+5D10, U+5D1D, U+5D20, U+5D24, U+5D26, U+5D2B, U+5D31, U+5D39, U+5D42, U+5D61, U+5D6A, U+5D70, U+5D88, U+5D92, U+5D94, U+5D97, U+5D99, U+5DB0, U+5DB2, U+5DB4, U+5DB9, U+5DD1, U+5DD7-5DD8, U+5DE0, U+5DE4, U+5DE9, U+5E00, U+5E12, U+5E15, U+5E1F, U+5E2E, U+5E3E, U+5E49, U+5E56, U+5E6B-5E6E, U+5EA5, U+5EAA, U+5EAC, U+5EB9, U+5EBF, U+5EC6, U+5ED2, U+5ED9, U+5EFD, U+5F08, U+5F0E, U+5F1C, U+5F1E, U+5F47, U+5F63, U+5F72, U+5F7E, U+5F8F, U+5FA2, U+5FA4, U+5FB8, U+5FC4, U+5FC7, U+5FCB, U+5FD2-5FD4, U+5FE2, U+5FEE-5FEF, U+5FF3, U+5FFC, U+6017, U+6022, U+6024, U+604C, U+607F, U+608A, U+6095, U+60A8, U+60B0-60B1, U+60BE, U+60C8, U+60D9, U+60DB, U+60EE, U+60F2, U+60F5, U+6110, U+6112-6113, U+6119, U+611E, U+613A, U+6141, U+6146, U+6160, U+617C, U+6192-6193, U+6197-6198, U+61A5, U+61A8, U+61AD, U+61D5, U+61DD, U+61DF, U+61F5, U+6215, U+6223, U+6229, U+6246, U+624C, U+6251-6252, U+6261, U+6264, U+626D, U+6273, U+627B, U+6299, U+62A6, U+62D5, U+62FD, U+6303, U+630D, U+6310, U+6332, U+6335, U+633B-633C, U+6341, U+6344, U+634E, U+6359, U+636C, U+6384;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular056.woff2") format("woff2");
  unicode-range: U+6394, U+6399, U+63BD, U+63D4-63D5, U+63DC, U+63E0, U+63EB-63EC, U+63F2, U+63F7, U+6409, U+641E, U+6425, U+6429, U+642F, U+645A-645B, U+645D, U+6473, U+647D, U+6487, U+6491, U+649D, U+649F, U+64CB-64CC, U+64D5, U+64D7, U+64E4-64E5, U+64FF, U+6504, U+650F, U+6514, U+6516, U+651E, U+6532, U+6544, U+6554, U+656B, U+657A, U+6581, U+6584-6585, U+658A, U+65B2, U+65B5, U+65B8, U+65BF, U+65C2, U+65C9, U+65D4, U+65F2, U+65F9, U+65FC, U+6604, U+6608, U+6621, U+662A, U+6645, U+664E, U+6651, U+6657, U+665B, U+6663, U+666A-666D, U+667B, U+6680, U+6690, U+6692, U+6699, U+66AD, U+66B1, U+66B5, U+66BF, U+66EC, U+6701, U+6705, U+6712, U+6719, U+674C-674D, U+6754, U+675D, U+6774, U+6776, U+6792, U+67B0, U+67B2, U+67C3, U+67C8, U+67D2, U+67D9, U+67DB, U+67F0, U+67F7, U+6810, U+6818, U+681F, U+682D, U+6833, U+683B, U+683E, U+6844-6845, U+6849, U+684C, U+6855, U+6857, U+686B, U+686E, U+687A, U+687C, U+6882, U+6890, U+6896, U+6898-689A, U+689C, U+68AA-68AB, U+68B4, U+68BB, U+68C3, U+68C5, U+68CC, U+68CF, U+68D6, U+68D9, U+68E4-68E5, U+68EC, U+68F7, U+68FB, U+6903, U+6907, U+693B, U+6946, U+6969, U+696C, U+6972, U+697A, U+697F, U+6992, U+6996, U+6998, U+69A6, U+69B0, U+69B7, U+69BA, U+69BC, U+69C0, U+69D1, U+69D6, U+69E3, U+69EE-69EF, U+69F3-69F4, U+69FE, U+6A11, U+6A1A, U+6A1D, U+6A30, U+6A32-6A34, U+6A3F, U+6A46, U+6A49, U+6A4E, U+6A52, U+6A64, U+6A7A, U+6A7E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular057.woff2") format("woff2");
  unicode-range: U+2E9E, U+2EA1, U+2EA3, U+6A83, U+6A8B, U+6A91, U+6A9F, U+6AA1, U+6AAB, U+6ABD, U+6AC6, U+6AD0, U+6AD4, U+6ADC-6ADD, U+6AEC, U+6AF1-6AF3, U+6AFD, U+6B0B, U+6B0F-6B11, U+6B17, U+6B2F, U+6B4A, U+6B58, U+6B6C, U+6B75, U+6B7A, U+6B81, U+6B9B, U+6BAE, U+6BBD-6BBE, U+6BC7-6BC9, U+6BDA, U+6BE6-6BE7, U+6BEE, U+6BF1, U+6C02, U+6C0A, U+6C0E, U+6C35-6C36, U+6C3A, U+6C3F, U+6C4D, U+6C5B, U+6C67, U+6C6D, U+6C84, U+6C89, U+6C94-6C95, U+6C97, U+6CAD, U+6CC2, U+6CD0, U+6CD6, U+6CDA, U+6CDC, U+6CE9, U+6CEC-6CED, U+6D00, U+6D0A, U+6D24, U+6D26-6D27, U+6D2F, U+6D34, U+6D3C, U+6D5B, U+6D5E, U+6D60, U+6D70, U+6D80-6D81, U+6D8A, U+6D8D, U+6D91, U+6D98, U+6DAB, U+6DAE, U+6DB4, U+6DC2, U+6DC8, U+6DCE-6DD0, U+6DDF, U+6DE9, U+6DF6, U+6E17, U+6E1E, U+6E22, U+6E27, U+6E32, U+6E36, U+6E3C, U+6E48-6E49, U+6E4B-6E4C, U+6E4F, U+6E51, U+6E53-6E54, U+6E57, U+6E63, U+6E93, U+6EA7, U+6EB4, U+6EBF, U+6EC3, U+6ECA, U+6ED9, U+6EEB, U+6EF9, U+6EFB, U+6F0A, U+6F0C, U+6F18, U+6F25, U+6F35-6F36, U+6F3C, U+6F52, U+6F57, U+6F5A, U+6F60, U+6F68, U+6F7D, U+6F90, U+6F96, U+6F98, U+6F9F, U+6FA5, U+6FAF, U+6FB5, U+6FBE, U+6FC8-6FC9, U+6FDA, U+6FDE, U+6FE9, U+6FFC, U+7000, U+7007, U+700A, U+7023, U+7039-703A, U+703C, U+7043, U+7047, U+704B, U+7054, U+7065, U+7069, U+706C, U+706E, U+7076, U+707E, U+7081, U+7086, U+7095, U+7097, U+709F, U+70B1, U+70BB, U+70CA, U+70D1, U+70D3, U+70DC, U+70EC, U+7103-7104;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular058.woff2") format("woff2");
  unicode-range: U+2EA8, U+7106-7108, U+710C, U+712F, U+7131, U+714A, U+7150, U+7153, U+715E, U+7180, U+7196, U+719B, U+71A0, U+71A2, U+71AE-71AF, U+71B3, U+71CB, U+71D3, U+71D9, U+71DC, U+7207, U+722B, U+7234, U+7238-7239, U+7242, U+7253, U+7257, U+7263, U+726E-726F, U+7278, U+727F, U+728E, U+72AD-72AE, U+72B0-72B1, U+72C1, U+72CC, U+72F3, U+72FA, U+7307, U+7312, U+7318-7319, U+732C, U+7331, U+7333, U+7339, U+733D, U+7352, U+736B-736C, U+736E-736F, U+7371, U+7377, U+7381, U+7385, U+738A, U+7394, U+7398, U+739C, U+739E, U+73A5, U+73A8, U+73B5, U+73B7, U+73B9, U+73BC, U+73BF, U+73C5, U+73CB, U+73E1, U+73E7, U+73F9-73FA, U+7401, U+7413, U+7424, U+7431, U+7439, U+7440, U+7443, U+744D, U+7452-7453, U+745D, U+7471, U+7481, U+7485, U+7488, U+7492, U+7497, U+7499, U+74A0-74A1, U+74A5, U+74AA-74AB, U+74B9-74BB, U+74D6, U+74D8, U+74DE, U+74EB, U+74EF, U+74FA, U+7520, U+7524, U+752A, U+753D-753E, U+7540, U+7548, U+754E, U+7550, U+7552, U+756C, U+7571-7572, U+757A, U+757D-757E, U+7581, U+758C, U+75A2, U+75B0, U+75B7, U+75BF-75C0, U+75C6, U+75CF, U+75D3, U+75DD, U+75DF-75E0, U+75E7, U+75EC, U+75EE, U+75F1, U+75F9, U+7603, U+7607, U+760F, U+7613, U+7618, U+761B-761C, U+7625, U+7628, U+7633, U+763C, U+7641, U+7649, U+7655, U+766E, U+7695, U+769C, U+76A0-76A1, U+76A7-76A8, U+76AF, U+76C9, U+76E8, U+76EC, U+7717, U+771A, U+772D, U+7735, U+7758, U+7760, U+776A, U+7772, U+777C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular059.woff2") format("woff2");
  unicode-range: U+2EAD, U+777D, U+779A, U+779F, U+77A2, U+77A4, U+77A9, U+77DE-77DF, U+77E4, U+77E6, U+77EA, U+77EC, U+77F0, U+77F4, U+77FB, U+7805-7806, U+7809, U+780D, U+7819, U+7821, U+782C, U+7847, U+7864, U+786A, U+788A, U+7894, U+789D-789F, U+78A4, U+78BB, U+78C8, U+78CC, U+78CE, U+78D5, U+78E0-78E1, U+78E6, U+78F9-78FB, U+78FE, U+7910, U+791B, U+7925, U+7930, U+793B, U+794A, U+7958, U+795B, U+7967, U+7972, U+7994-7996, U+799B, U+79A1, U+79A9, U+79B4, U+79BB, U+79C2, U+79C7, U+79CC-79CD, U+79D6, U+7A0A, U+7A11, U+7A15, U+7A1B, U+7A1E, U+7A2D, U+7A38, U+7A47, U+7A4C, U+7A56, U+7A59, U+7A5C, U+7A5F-7A60, U+7A67, U+7A6A, U+7A75, U+7A78, U+7A82, U+7A8A, U+7A90, U+7AA3, U+7AAC, U+7AB9, U+7ABC, U+7ABE, U+7ACC, U+7AD1, U+7AE7-7AE8, U+7AF4, U+7B07, U+7B27, U+7B2A, U+7B2E-7B2F, U+7B31, U+7B3D, U+7B41, U+7B55, U+7B64, U+7B66, U+7B69, U+7B73, U+7B79, U+7B7F, U+7B90-7B91, U+7B9B, U+7BAF, U+7BB5, U+7BBC, U+7BC5, U+7BCA, U+7BD4, U+7BD6, U+7BDA, U+7BEA, U+7BF0, U+7C03, U+7C0B, U+7C0E-7C0F, U+7C26, U+7C45, U+7C4A, U+7C51, U+7C57, U+7C5E, U+7C61, U+7C69, U+7C6E-7C70, U+7CA6, U+7CB6-7CB7, U+7CBF, U+7CC4, U+7CC8, U+7CCD, U+7CD7, U+7CE6, U+7CEB, U+7CF5, U+7D03, U+7D09, U+7D12, U+7D1E, U+7D3D-7D3E, U+7D40, U+7D47, U+7D59-7D5A, U+7D6A, U+7D70, U+7D7F, U+7D86, U+7D88, U+7D8C, U+7D97, U+7D9D, U+7DA7, U+7DAA, U+7DB6-7DB7, U+7DC0, U+7DD7, U+7DD9, U+7DE6, U+7DF1, U+7DF9, U+7E10, U+7E17, U+7E1D, U+7E27, U+7E2C, U+7E45, U+7E73, U+7E75, U+7E7E, U+7E86-7E87, U+7E91, U+7E98, U+7E9A, U+7F3B-7F3C, U+7F3E, U+7F43-7F44, U+7F4F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular060.woff2") format("woff2");
  unicode-range: U+2EAB, U+2EB2, U+2EB9, U+2EBE, U+7F52, U+7F61, U+7F63-7F64, U+7F6D, U+7F7D-7F7E, U+7F90, U+7F96, U+7F9C, U+7FAD, U+7FC3, U+7FCF, U+7FE3, U+7FE5, U+7FEF, U+7FF2, U+8002, U+8008, U+800A, U+800E, U+8011, U+8016, U+8024, U+802C, U+8030, U+8043, U+8066, U+8071, U+8075, U+807B, U+8099, U+809C, U+80A4, U+80A7, U+80B8, U+80C5, U+80D5, U+80D8, U+80E6, U+80F5, U+80FB, U+810D, U+8116, U+811E, U+8124, U+8127, U+812C, U+8135, U+813D, U+8169, U+8181, U+8184-8185, U+8198, U+81B2, U+81C1, U+81C3, U+81D6, U+81DB, U+81E4, U+81EC, U+81FD, U+81FF, U+8204, U+8219, U+8221-8222, U+8232, U+8234, U+823C, U+8245-8246, U+8249, U+824B, U+824F, U+8257, U+825C, U+8263, U+8279, U+827D, U+827F, U+8283, U+828A, U+8293, U+82A7-82A8, U+82B2, U+82B4, U+82BA, U+82BC, U+82E2, U+82E8, U+82F7, U+8307-8308, U+830C, U+831B, U+831D, U+8330, U+833C, U+8344, U+8354, U+8357, U+8363, U+837F, U+838D, U+8394-8395, U+839B, U+839D, U+83C9, U+83D0, U+83D4, U+83DD, U+83E5, U+83F9, U+840F, U+8411, U+8415, U+8417, U+8439, U+844A, U+844F, U+8451-8452, U+8459-845A, U+845C, U+8465, U+8476, U+8478, U+847C, U+8481, U+8497, U+84A6, U+84BE, U+84CE-84CF, U+84D3, U+84DC, U+84E7, U+84EA, U+84EF-84F1, U+84FA, U+84FD, U+850C, U+851B, U+8524-8525, U+852B, U+8534, U+853E, U+854F, U+8551, U+8553, U+855E, U+8561-8562, U+856F, U+857B, U+857D, U+857F, U+8581, U+8586, U+8593, U+859D, U+859F, U+85B7, U+85BC, U+85C7, U+85CA, U+85D8-85D9, U+85DF, U+85E1, U+85E6, U+85F6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular061.woff2") format("woff2");
  unicode-range: U+2EC2-2EC3, U+8600, U+8611, U+861E, U+8621, U+8624, U+8627, U+8639, U+863C, U+8640, U+8653, U+8656, U+866F, U+8677, U+867A, U+8687, U+8689, U+868D, U+8691, U+869C-869D, U+86A8, U+86B1, U+86B3, U+86C1, U+86C3, U+86D1, U+86D5, U+86D7, U+86E3, U+86E6, U+8705, U+8707, U+870E, U+8710, U+8713, U+8719, U+871F, U+8721, U+8723, U+8731, U+873A, U+873E, U+8740, U+8743, U+8751, U+8758, U+8764-8765, U+8772, U+877C, U+8789, U+878B, U+8793, U+87A0, U+87A7, U+87BE, U+87C1, U+87CE, U+87DF, U+87E3, U+87E5-87E6, U+87EA-87EB, U+87ED, U+87F5, U+8801, U+8803, U+880B, U+8813, U+8828, U+882E, U+8832, U+883C, U+884A, U+8858, U+885F, U+8864, U+8869, U+886F, U+88A0, U+88BC-88BE, U+88C0, U+88D1-88D3, U+88DB, U+88F0-88F1, U+8901, U+8937, U+8942, U+8945, U+8949, U+8962, U+8980, U+8989, U+8990, U+899F, U+89B0, U+89B7, U+89D6, U+89D8, U+89EB, U+89F1, U+89F3, U+89FD, U+89FF, U+8A11, U+8A14, U+8A21, U+8A35, U+8A3E, U+8A45, U+8A4D, U+8A58, U+8A90, U+8AAE, U+8AB7, U+8ABE, U+8AD7, U+8AFC, U+8B05, U+8B0A, U+8B0D, U+8B1C, U+8B1F, U+8B2D, U+8B43, U+8B51, U+8B5E, U+8B76, U+8B7F, U+8B81, U+8B8B, U+8B94-8B95, U+8B9C, U+8B9E, U+8C39, U+8C3D, U+8C45, U+8C47, U+8C4F, U+8C54, U+8C57, U+8C69, U+8C6D, U+8C73, U+8C92-8C93, U+8C99, U+8C9B, U+8CA4, U+8CD5-8CD6, U+8CD9, U+8CF0-8CF1, U+8D09, U+8D0E, U+8D6C, U+8D84, U+8D95, U+8DA6, U+8DC6, U+8DC8, U+8DD9, U+8DEC, U+8DFD, U+8E06, U+8E0C, U+8E14, U+8E16, U+8E21-8E22, U+8E27, U+8E36;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular062.woff2") format("woff2");
  unicode-range: U+2ECD, U+2ECF, U+2ED2, U+2ED6, U+8E39, U+8E4B, U+8E54, U+8E62, U+8E6C-8E6D, U+8E6F, U+8E98, U+8E9E, U+8EAE, U+8EB3, U+8EB5-8EB6, U+8EBB, U+8ED1, U+8ED4, U+8EF9, U+8F00, U+8F08, U+8F17, U+8F2B, U+8F40, U+8F4A, U+8F58, U+8FA4, U+8FB4, U+8FB6, U+8FC1, U+8FC6, U+8FCA, U+8FCD, U+8FD3, U+8FD5, U+8FE0, U+8FF1, U+8FF5, U+8FFB, U+9002, U+900C, U+9037, U+9043-9044, U+905D, U+9085, U+908C, U+9090, U+90A1, U+90B0, U+90B6, U+90C8, U+90DC, U+90DF, U+90EB, U+90F2, U+90F6, U+90FE-9100, U+9104, U+9106, U+9118, U+911C, U+911E, U+9137, U+9139-913A, U+9146-9147, U+9157, U+9159, U+9161, U+9164, U+9174, U+9179, U+9185, U+918E, U+91A8, U+91AE, U+91B3, U+91B6, U+91C3-91C4, U+91DA, U+91EC, U+91EE, U+9201, U+920A, U+9216-9217, U+9233, U+9242, U+9247, U+924A, U+924E, U+9251, U+9256, U+9259, U+9260-9261, U+9265, U+9267-9268, U+927C-927D, U+927F, U+9289, U+928D, U+9297, U+9299, U+929F, U+92A7, U+92AB, U+92B2, U+92BF-92C0, U+92C6, U+92CE, U+92D0, U+92D7, U+92D9, U+92E5, U+92E7, U+92F7, U+92F9, U+92FB, U+9302, U+930D, U+9311, U+9315, U+931D-931E, U+9327, U+9329, U+9347, U+9351, U+9357, U+935A, U+936B, U+9371, U+9373, U+9388, U+938B, U+938F, U+939E, U+93A1, U+93C1, U+93C7, U+93DC, U+93E2, U+93E7, U+93F1, U+93F5, U+93FB, U+9409, U+940F, U+9416-9417, U+9432, U+9434, U+943B, U+9445, U+946D, U+946F, U+9578-9579, U+9586, U+958C-958D, U+95AB, U+95B4, U+95C8, U+961D, U+962C, U+9633-9634, U+963C, U+9641, U+9661, U+9682, U+969A, U+96A9, U+96AF, U+96B3, U+96BA, U+96BD, U+96D8, U+96DA, U+96DD, U+9714, U+9723, U+9736, U+9741, U+9747, U+9755, U+9757, U+975B, U+976A, U+9796, U+979A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular063.woff2") format("woff2");
  unicode-range: U+2EDF, U+979E, U+97A2, U+97B1-97B2, U+97BE, U+97CC, U+97D1, U+97D4, U+97D8-97D9, U+97E1, U+97F1, U+9804, U+980D-980E, U+9814, U+9816, U+9823, U+9825, U+9832-9833, U+9847, U+9866, U+98AB, U+98AD, U+98B0, U+98B7-98B8, U+98BB-98BC, U+98BF, U+98C2, U+98C7, U+98CB, U+98E0-98E1, U+98E3, U+98E5, U+98EA, U+98F0-98F1, U+98F3, U+9908, U+9916-9917, U+991A-991C, U+9931-9933, U+993A-993C, U+9940-9941, U+9946, U+994D-994E, U+995C, U+995F-9960, U+99A3, U+99A6, U+99B9, U+99BD, U+99BF, U+99C3, U+99C9, U+99D4, U+99D9, U+99DE, U+99F0, U+99F9, U+99FC, U+9A0A, U+9A11, U+9A16, U+9A1A, U+9A20, U+9A31, U+9A36, U+9A44, U+9A4C, U+9A58, U+9AAF, U+9AB7, U+9AB9, U+9AC6, U+9AD0, U+9AD2, U+9AD5, U+9ADC, U+9AE0, U+9AE5, U+9AE9, U+9B03, U+9B0C, U+9B10, U+9B12, U+9B16, U+9B1C, U+9B2B, U+9B33, U+9B3D, U+9B4B, U+9B63, U+9B65, U+9B6B-9B6C, U+9B73, U+9B76-9B77, U+9BA6, U+9BAC, U+9BB1-9BB2, U+9BB8, U+9BBE, U+9BC7, U+9BD8, U+9BDD, U+9BE7, U+9BEA-9BEB, U+9BEE-9BEF, U+9BF3, U+9BF7, U+9BFA, U+9C16, U+9C18-9C1A, U+9C1D, U+9C22, U+9C27, U+9C29-9C2A, U+9C31, U+9C36-9C37, U+9C45, U+9C49-9C4A, U+9C54, U+9C58, U+9C5B-9C5D, U+9C5F, U+9C69-9C6B, U+9C6D-9C6E, U+9C70, U+9C72, U+9C75, U+9C7A, U+9CE6, U+9CF2, U+9D02, U+9D0B, U+9D11, U+9D17-9D18, U+9D32, U+9D42, U+9D4A, U+9D5F, U+9D62, U+9D69, U+9D6B, U+9D73, U+9D76-9D77, U+9D7E, U+9D84, U+9D8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular064.woff2") format("woff2");
  unicode-range: U+2E87, U+2EA4, U+2EBF-2EC0, U+2ECC, U+9D99, U+9DA1, U+9DB5, U+9DB9, U+9DBD, U+9DBF, U+9DC3, U+9DC7, U+9DC9, U+9DD6, U+9DDA, U+9DDF-9DE0, U+9DE3, U+9DF4, U+9E02, U+9E0A, U+9E0D, U+9E19, U+9E1C-9E1D, U+9E7B, U+9E80, U+9E85, U+9E9B, U+9EA8, U+9EBD, U+9EDF, U+9EE7, U+9EEE, U+9EFF, U+9F02-9F03, U+9F17, U+9F19, U+9F2F, U+9F37, U+9F3A, U+9F3D, U+9F41, U+9F45-9F46, U+9F53, U+9F55, U+9F58, U+9F5D, U+9F69, U+9F6D, U+9F70, U+9F75, U+FA13, U+FA20-FA21, U+FA24, U+FA49, U+FA58, U+FA5D-FA5E, U+FA66, U+20089, U+200A2, U+200A4, U+201A2, U+20213, U+2032B, U+20371, U+20381, U+203F9, U+2044A, U+20509, U+205D6, U+20628, U+2074F, U+20807, U+2083A, U+208B9, U+2097C, U+2099D, U+20AD3, U+20B1D, U+20D45, U+20DE1, U+20E64, U+20E6D, U+20E95, U+20F5F, U+21201, U+21255, U+21274, U+2127B, U+212D7, U+212E4, U+212FD, U+21336, U+21344, U+213C4, U+2146D, U+215D7, U+21647, U+21706, U+21742, U+219C3, U+21C56, U+21D2D, U+21D45, U+21D62, U+21D78, U+21D92, U+21D9C, U+21DA1, U+21DB7, U+21DE0, U+21E33, U+21F1E, U+21F76, U+21FFA, U+2217B, U+22218, U+2231E, U+223AD, U+226F3, U+2285B, U+228AB, U+2298F, U+22AB8, U+22B46, U+22B4F-22B50, U+22BA6, U+22C1D, U+22C24, U+22DE1, U+231B6, U+231C3, U+231F5, U+23372, U+233D0, U+233D2-233D3, U+233D5, U+233DA, U+233DF, U+233E4, U+2344A-2344B, U+23451, U+23465, U+234E4, U+2355A, U+23594, U+23638-2363A, U+23647, U+2370C, U+2371C, U+23764, U+237E7, U+237FF, U+23824, U+2383D, U+23A98, U+23C7F, U+23D00, U+23D0E, U+23D40, U+23DD3, U+23DF9-23DFA, U+23F7E, U+24096, U+24103, U+241C6, U+241FE, U+243BC, U+24629, U+246A5, U+24896, U+24A4D, U+24B56;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular065.woff2") format("woff2");
  unicode-range: U+2EAA, U+2EBD, U+2ECA, U+2EDE, U+4EFC, U+4F39, U+4FCD, U+4FFF, U+50D8, U+50F4, U+5164, U+51BE, U+51EC, U+529C, U+52C0, U+5372, U+53DD, U+5759, U+5765, U+58B2, U+5953, U+5963, U+59BA, U+5CF5, U+5DD0, U+5F21, U+605D, U+6085, U+60DE, U+6111, U+6120, U+6213, U+654E, U+662E, U+663B, U+670E, U+6AE2, U+6C6F, U+6D6F, U+6D96, U+6DF2, U+6DF8, U+6E39, U+24B6F, U+24C16, U+24D14, U+24E0E, U+24E37, U+24E6A, U+24E8B, U+2504A, U+25055, U+25122, U+251A9, U+251CD, U+251E5, U+2521E, U+2524C, U+2542E, U+254D9, U+255A7, U+257A9, U+257B4, U+259D4, U+25AE3-25AE4, U+25AF1, U+25BB2, U+25C4B, U+25C64, U+25E2E, U+25E56, U+25E62, U+25E65, U+25EC2, U+25ED8, U+25EE8, U+25F23, U+25F5C, U+25FD4, U+25FE0, U+25FFB, U+2600C, U+26017, U+26060, U+260ED, U+26270, U+26286, U+2634C, U+26402, U+2667E, U+266B0, U+2671D, U+268DD, U+268EA, U+26951, U+2696F, U+269DD, U+26A1E, U+26A58, U+26A8C, U+26AB7, U+26C29, U+26C73, U+26CDD, U+26E65, U+26F94, U+26FF6-26FF8, U+2710D, U+27139, U+273DA-273DB, U+273FE, U+27410, U+27449, U+27614-27615, U+27631, U+27693, U+2770E, U+27723, U+27752, U+27985, U+27A84, U+27BB3, U+27BBE, U+27BC7, U+27CB8, U+27DA0, U+27E10, U+27FB7, U+2808A, U+280BB, U+28282, U+282F3, U+2840C, U+28455, U+2856B, U+285C8-285C9, U+286D7, U+286FA, U+28946, U+28949, U+2896B, U+28987-28988, U+289BA-289BB, U+28A1E, U+28A29, U+28A43, U+28A71, U+28A99, U+28ACD, U+28ADD, U+28AE4, U+28BC1, U+28BEF, U+28D10, U+28D71, U+28DFB, U+28E1F, U+28E36, U+28E89, U+28EEB, U+28F32, U+28FF8, U+292A0, U+292B1, U+29490, U+295CF, U+2967F, U+296F0, U+29719, U+29750, U+298C6, U+29A72, U+29DDB, U+29E15, U+29E3D, U+29E49, U+29E8A, U+29EC4, U+29EDB, U+29EE9, U+29FCE, U+29FD7, U+2A01A, U+2A02F, U+2A082, U+2A0F9, U+2A38C, U+2A437, U+2A5F1, U+2A602, U+2A61A, U+2A6B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular066.woff2") format("woff2");
  unicode-range: U+2FAD, U+3EDA, U+4103, U+4E04-4E05, U+4E0C, U+4E1F, U+4E23-4E24, U+4E35, U+4E41, U+4E44, U+4E5C, U+4E63, U+4E68, U+4E74-4E75, U+4E79, U+4E7F, U+4E96-4E97, U+4EAF, U+4EDB, U+4EE0, U+4EE2, U+4EE8, U+4EEF, U+4EF3, U+4EFE, U+4F02, U+4F08, U+4F0C, U+4F12, U+4F15, U+4F17, U+4F19, U+4F2E, U+4F31, U+4F33, U+4F35, U+4F3B, U+4F40, U+4F42, U+4F4B-4F4C, U+4F52, U+4F63, U+4F6E, U+4F71, U+4F79, U+4F81, U+4F84, U+4F89, U+4F8C, U+4F8E, U+4F90, U+4F93, U+4F99, U+4F9E-4F9F, U+4FB7, U+4FB9, U+4FBB-4FBD, U+4FC0-4FC1, U+4FC6, U+4FC8, U+4FCC, U+4FDC, U+4FE2, U+4FF0, U+4FFC-4FFD, U+5004, U+5007, U+500A, U+500C, U+5017, U+501B, U+501D, U+5030, U+5032-5033, U+5035, U+5045, U+504A, U+504C, U+5051-5052, U+5059, U+505F-5060, U+5062, U+5067, U+506D, U+5071, U+5081, U+5083-5084, U+5086, U+508A, U+508E-5090, U+509B, U+509E-50A2, U+50AF-50B0, U+50B9, U+50BD, U+50C0, U+50C3, U+50D3, U+50DC-50DD, U+50DF, U+50E2, U+50E4, U+50E8, U+50EF, U+50F1, U+50F6, U+50FA, U+50FE, U+5107, U+510C, U+56E4, U+5880, U+6911, U+6DCA, U+7324, U+73BD, U+73D2, U+73F5, U+7421, U+7429, U+7575, U+7682, U+787A, U+79DA, U+7FA1, U+813A, U+83F6, U+856B, U+8807, U+8B53, U+8D76, U+8ECF, U+8FF6, U+9067, U+91B2, U+91DE, U+9206, U+9277, U+92D5, U+93A4, U+93F8, U+9431, U+9448, U+9592, U+9743, U+9751, U+9927, U+9BBB, U+9FC4, U+9FC6, U+FA0E, U+FA12, U+FA17-FA18, U+FA1C-FA1E, U+FA23, U+FA25, U+FA27-FA2D, U+FA6B-FA6D, U+20158, U+20BB7, U+233CC, U+233FE, U+242EE, U+2F8DB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular067.woff2") format("woff2");
  unicode-range: U+510D-510E, U+5110, U+5119, U+511C-511D, U+5123, U+5127-5128, U+512C-512D, U+512F, U+5131, U+5133-5134, U+5138-5139, U+5142, U+514F, U+5153, U+5158, U+515F, U+5166, U+517E, U+5184, U+518E, U+51A1, U+51B8, U+51BA, U+51BF, U+51C2, U+51C8, U+51CF, U+51D1-51D3, U+51D5, U+51D8, U+51E5, U+51F2, U+51F7, U+5205, U+5218, U+5222, U+5228, U+5231-5232, U+5235, U+523C, U+5245, U+5258, U+525A, U+525F-5260, U+5266, U+526E, U+5278-5279, U+5280, U+5285, U+528A, U+528C, U+5295-5297, U+529A, U+52A5, U+52A7, U+52B0, U+52B6-52B8, U+52BD, U+52C4, U+52C6, U+52CF, U+52D4, U+52DC, U+52E1, U+52E5, U+52E8-52EA, U+52EC, U+52F1, U+52F4, U+52F6, U+5303, U+530C, U+5311, U+5313, U+5318, U+531B, U+531E-531F, U+5325, U+5327-5329, U+532B-532D, U+5330, U+5332, U+533C-533D, U+534B-534C, U+5359, U+535B, U+5365, U+536D, U+5379, U+537E, U+5383, U+5387-5388, U+538E, U+5394, U+5399, U+53A1, U+53AA-53AB, U+53AF, U+53B5, U+53B8, U+53BA, U+53BD, U+53C5, U+53CF, U+53D2-53D3, U+53DE, U+53E0, U+53E6-53E7, U+5402, U+5413, U+541A, U+5421, U+542A, U+542F, U+5431, U+5434-5435, U+5444, U+5447, U+544F, U+545E, U+5464, U+5467, U+5469, U+546D-546E, U+5481, U+5483, U+5485, U+5489, U+5491, U+549F, U+54A7, U+54AA, U+54B1, U+54B9, U+54BB, U+54CA, U+54CD-54CE, U+54E0, U+54EA, U+54F6, U+54FC, U+54FE, U+5505, U+5508, U+550C-550D, U+5515, U+552A, U+5532, U+5536, U+553B, U+553D, U+5549, U+554D, U+5551, U+5558, U+555A-555B, U+5566, U+557F, U+558F, U+5592-5594, U+5597, U+55A3-55A4, U+55B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular068.woff2") format("woff2");
  unicode-range: U+55C1, U+55C3, U+55C6, U+55CB, U+55D3, U+55D7-55D8, U+55DB, U+55DE, U+55F6, U+55FF, U+5605, U+560A, U+560D, U+560F, U+5611-5612, U+5619, U+562C, U+5633, U+5635, U+5639, U+563C, U+5641, U+5643-5644, U+5646, U+564B, U+564D, U+564F, U+5654, U+5661-5663, U+5669, U+5675, U+5684-5685, U+568B, U+569F, U+56A6-56A7, U+56AB, U+56B1, U+56B7, U+56BE, U+56CB-56CC, U+56CF-56D0, U+56D9, U+56DC-56DD, U+56E1, U+56E5-56E7, U+56EB, U+56ED, U+56F1, U+5701-5702, U+5707, U+570C, U+5711, U+571A-571B, U+571D, U+5720, U+5722, U+5724-5725, U+572A, U+572C, U+572E, U+573D-573F, U+5752, U+5762, U+5767, U+576B, U+576D-576E, U+5771, U+5779-577A, U+577E, U+5781, U+5783, U+578C, U+5794-5795, U+5797, U+5799, U+579F, U+57A1, U+57A7, U+57A9, U+57BD, U+57D5, U+57DD, U+57E1, U+57E7, U+57E9, U+57FE, U+5803, U+5808, U+580C-580D, U+581B, U+581F, U+5826, U+582D, U+583F, U+584D, U+584F-5850, U+5855, U+585F, U+5868, U+5878, U+587F, U+5881, U+5887-5888, U+588C, U+5896, U+58A0-58A2, U+58A6, U+58BC, U+58C2, U+58C4, U+58C8, U+58D0, U+58D6, U+58DD, U+58E1, U+5912-5913, U+591D, U+5921, U+5923, U+5928, U+592F-5930, U+5933, U+5935-5936, U+593F, U+5943, U+5952, U+5959, U+595E, U+5961, U+596B, U+596F, U+5972, U+5979, U+597B, U+598C, U+598E, U+5995, U+5997, U+59A7, U+59AD, U+59AF-59B0, U+59B3, U+59B7, U+59C1, U+59C4, U+59CA, U+59DF, U+59EF, U+59F1-59F2, U+59F4, U+59F7, U+5A00, U+5A04, U+5A0E, U+5A12, U+5A1E, U+5A24, U+5A28, U+5A2A, U+5A30, U+5A44-5A45, U+5A47-5A48, U+5A4C, U+5A50, U+5A5E, U+5A63;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular069.woff2") format("woff2");
  unicode-range: U+2F39, U+5A7B, U+5A90, U+5A93, U+5A96, U+5A99, U+5AAC, U+5AB2, U+5AB8, U+5ABB, U+5AC6, U+5AC8, U+5ACF, U+5AEA, U+5AF6, U+5AFD, U+5B01, U+5B1B, U+5B1D, U+5B21, U+5B38, U+5B4B, U+5B5E, U+5B6E, U+5B7E, U+5B86, U+5B8A, U+5B8E, U+5B90-5B91, U+5B94, U+5BA8-5BA9, U+5BAD, U+5BAF, U+5BB1-5BB2, U+5BB7, U+5BBA, U+5BBC, U+5BC1, U+5BCD, U+5BCF, U+5BD7, U+5BD9-5BDA, U+5BE0, U+5BEF, U+5BF4, U+5C0C, U+5C17, U+5C1F, U+5C26, U+5C2C, U+5C2E, U+5C32, U+5C35-5C36, U+5C59-5C5A, U+5C5C, U+5C6D, U+5C74-5C75, U+5C7B, U+5C7D, U+5C87, U+5C92, U+5C9D, U+5CB2, U+5CB4, U+5CD7, U+5CDD, U+5CEE, U+5CF1-5CF2, U+5D01, U+5D12, U+5D23, U+5D34, U+5D3D, U+5D3F, U+5D43, U+5D48, U+5D51, U+5D55, U+5D59, U+5D5F-5D60, U+5D62, U+5D64, U+5D79-5D7A, U+5D7E-5D7F, U+5D83, U+5D8A, U+5D93, U+5D95, U+5D9B, U+5D9F, U+5DAB, U+5DC3, U+5DC7, U+5DCE, U+5DD9, U+5DF8-5DF9, U+5E07, U+5E0D, U+5E20, U+5E28, U+5E32, U+5E35, U+5E4B, U+5E50-5E51, U+5E5B-5E5C, U+5E68, U+5E6A, U+5E70, U+5E80, U+5E8B, U+5E8E, U+5EA2, U+5EA4, U+5EA8, U+5EB1, U+5EB3, U+5EBD, U+5ECC, U+5ECE, U+5ED1, U+5ED4-5ED5, U+5EDC, U+5EDE, U+5EE5, U+5EEB, U+5F06, U+5F19, U+5F22, U+5F24, U+5F28, U+5F2B-5F2C, U+5F2E, U+5F30, U+5F3B, U+5F3F, U+5F44, U+5F4D, U+5F50, U+5F5B, U+5F60, U+5F6F, U+5F74-5F75, U+5F78, U+5F7A, U+5F8D, U+5F96, U+5F9D, U+5FAB-5FAC, U+5FB0-5FB1, U+5FC8, U+5FD0-5FD1, U+5FE8, U+5FEA, U+5FEC-5FED, U+5FF2, U+5FF6, U+5FFA, U+6007, U+600A, U+6013, U+601A, U+601F, U+602D, U+6040, U+6048-6049, U+6051, U+6054, U+6056-6057, U+6061, U+6067;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular070.woff2") format("woff2");
  unicode-range: U+6071, U+607E, U+6082, U+6086, U+6088, U+608E, U+6091, U+6093, U+6098, U+60A2, U+60A4-60A5, U+60B7, U+60BB, U+60C2, U+60C4, U+60C9-60CA, U+60CE-60CF, U+60E2, U+60E5, U+60FC-60FD, U+6102, U+6107, U+610A, U+610C, U+6114, U+6116-6117, U+6122, U+612A, U+6131, U+6135-6136, U+6139, U+6145, U+6149, U+615E, U+616C, U+6172, U+6178, U+617B, U+617F-6181, U+6183-6184, U+618B, U+619C-619D, U+619F-61A0, U+61AA, U+61B8, U+61C0-61C2, U+61CE-61CF, U+61DC, U+61DE, U+61E1-61E2, U+61E5, U+61E7, U+61E9, U+61EC-61ED, U+61EF, U+6201, U+6203-6204, U+6207, U+621C, U+6220, U+6227, U+622B, U+6239, U+623D, U+6242, U+6244, U+6250, U+6254, U+625C, U+627A, U+627D, U+628D-6290, U+62A8, U+62B3, U+62B6-62B7, U+62BA, U+62BE-62BF, U+62CE, U+62DA, U+62EA, U+62F2, U+62F4, U+6304, U+630B, U+6313, U+6316, U+6329-632A, U+632D, U+6336, U+6342, U+6346, U+634A-634B, U+6352-6354, U+6358, U+635B, U+6366, U+636D, U+6371, U+6374-6375, U+6378, U+637D, U+637F, U+6382, U+6387, U+638A, U+6390, U+6395, U+639A, U+639E, U+63A4, U+63A6, U+63AD-63AF, U+63C1, U+63C5, U+63C8, U+63CE, U+63D1, U+63D3, U+63EA, U+63F3, U+63F8-63F9, U+640A, U+6412, U+6418, U+6420, U+6424, U+642A, U+6430, U+6435, U+643D, U+643F, U+644B, U+644F, U+6452-6454, U+645C, U+645F, U+6461, U+6463, U+6474, U+647B, U+6485, U+648F-6490, U+6498-6499, U+649B, U+64A1, U+64A3, U+64A6, U+64A8, U+64AC, U+64B3, U+64BD, U+64C9, U+64D1, U+64E9-64EA, U+64ED, U+64F0, U+64F5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular071.woff2") format("woff2");
  unicode-range: U+6501, U+6508-650A, U+6513, U+6519, U+651B, U+651F, U+6526, U+652E, U+6531, U+653A, U+653C-653D, U+6543, U+6547, U+6549, U+6550, U+6552, U+655F-6560, U+657D, U+6592, U+6595, U+6598, U+65A0, U+65A3, U+65A6, U+65AE, U+65B3-65B4, U+65C8, U+65CE, U+65D0, U+65D6, U+65D8, U+65DF, U+65F0, U+65F4-65F5, U+65FE-65FF, U+660D, U+6611-6612, U+6616, U+661D, U+6623, U+6626, U+6629, U+662C, U+6637, U+6639, U+6640, U+6646, U+664A, U+6658, U+665C, U+6660, U+6675, U+6679, U+667C, U+667E-667F, U+668B-668C, U+669A-669C, U+669F, U+66A4, U+66C0, U+66C2-66C3, U+66CC, U+66CE-66CF, U+66D4, U+66DF, U+66EB, U+66EE, U+6707, U+671C, U+6720, U+6722, U+673E, U+6745, U+6755, U+676C, U+676E, U+6784, U+678E-678F, U+6791, U+6796, U+6799, U+67B1, U+67B5, U+67BC-67BD, U+67C2, U+67C5, U+67C9, U+67DC, U+67E1, U+67E6, U+67F2, U+67F6, U+6814, U+6819, U+6827-6828, U+682F-6830, U+683F, U+684A, U+6858, U+686F-6871, U+6879, U+687B, U+6884, U+6886, U+6888, U+68A1, U+68A9, U+68AE, U+68D1, U+68D3, U+68DC-68DD, U+68EA-68EB, U+68F5-68F6, U+68FD, U+6906, U+6909, U+6910, U+6913, U+6916-6917, U+6931, U+6933, U+6938, U+6945, U+694E, U+695B, U+6965-6966, U+6970-6971, U+697B, U+698D, U+69A1, U+69A8, U+69AB, U+69AF, U+69B8, U+69C5, U+69C8, U+69D7, U+69E5, U+69F1, U+6A00-6A01, U+6A03, U+6A20, U+6A24, U+6A28, U+6A37, U+6A4A, U+6A51, U+6A55, U+6A67, U+6A6A, U+6A71, U+6A81, U+6A86-6A87, U+6A9B, U+6AAF-6AB1, U+6AB4, U+6ABE-6ABF, U+6AC8-6AC9, U+6ACC, U+6AD5-6AD6, U+6AF0, U+6AFC, U+6B02;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular072.woff2") format("woff2");
  unicode-range: U+6B03, U+6B06-6B07, U+6B09, U+6B24, U+6B28, U+6B2B, U+6B36, U+6B3B, U+6B3F, U+6B4D, U+6B52, U+6B5D, U+6B6B, U+6B6E, U+6B70, U+6B7D-6B7E, U+6B85, U+6B97, U+6B9F-6BA0, U+6BA2-6BA3, U+6BA8, U+6BAC, U+6BB0, U+6BB8-6BB9, U+6BC3-6BC4, U+6BCC, U+6BE1, U+6BE3, U+6BF7, U+6BF9, U+6C04, U+6C09, U+6C0D, U+6C12, U+6C19, U+6C1F, U+6C26-6C28, U+6C2C, U+6C2E, U+6C3B, U+6C4A-6C4B, U+6C4F, U+6C52, U+6C54, U+6C6B, U+6C78-6C79, U+6C7B, U+6C87, U+6C9F, U+6CB0, U+6CB2, U+6CB4, U+6CCD, U+6CCF, U+6CD1-6CD2, U+6CE7, U+6CF2, U+6CF4, U+6D07, U+6D0F, U+6D11, U+6D13, U+6D1A, U+6D28, U+6D57, U+6D5F, U+6D61, U+6D67, U+6D7C, U+6D92, U+6D97, U+6DB7, U+6DB9, U+6DBD, U+6DE0, U+6DE2, U+6DE5, U+6DEF-6DF0, U+6DF4, U+6E00, U+6E04, U+6E3B, U+6E45, U+6E52, U+6E5D, U+6E62, U+6E68, U+6E73, U+6E7B, U+6E7D, U+6E8D, U+6E99, U+6EA0, U+6EAD-6EAE, U+6EB3, U+6EBB, U+6EC0, U+6EC8, U+6ECD, U+6ECF, U+6EED-6EEE, U+6EFD, U+6F04, U+6F08, U+6F0D, U+6F16, U+6F1B, U+6F26, U+6F29, U+6F2D, U+6F30, U+6F3B, U+6F4F, U+6F53, U+6F5D, U+6F6C, U+6F83, U+6F87, U+6F8B, U+6F92-6F93, U+6F9A, U+6FA6, U+6FA8, U+6FAE, U+6FB0, U+6FC5, U+6FE8, U+6FFD, U+700D, U+7017, U+7020, U+702F, U+7034, U+7037, U+7044, U+7048-7049, U+7055, U+7094, U+7096, U+7098, U+709B, U+70B0, U+70B4, U+70D5-70D6, U+70FA, U+7105, U+710B, U+712D, U+7138, U+7141, U+7145, U+714B, U+7157, U+715A, U+7179, U+718C, U+719A, U+71B0, U+71B2, U+71BF-71C0, U+71CC, U+71D6, U+71DA, U+71F8, U+7208-7209, U+7213, U+7217, U+721A, U+721D, U+721F, U+7224, U+722F, U+7241, U+7243, U+7245, U+724E-7250;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular073.woff2") format("woff2");
  unicode-range: U+725A, U+725C, U+725E, U+7260, U+7268, U+726B, U+7271, U+7277, U+727B-727C, U+7284, U+7289, U+7293, U+72A8, U+72B4, U+72C7, U+72C9, U+72D5-72D6, U+72D8, U+72DF, U+72E5, U+72F4, U+72FE, U+7302, U+7304-7305, U+730B, U+730D, U+7313, U+731E, U+7322, U+7332, U+7335, U+733A-733B, U+7343, U+734D, U+7356, U+7358, U+735D-7360, U+7367, U+7369, U+7379, U+7380, U+7383, U+7386, U+738E, U+7390, U+7393, U+7397, U+73AA, U+73AD, U+73C6, U+73CC, U+73D3, U+73DD, U+73E6, U+73F4, U+73F7, U+73FB, U+73FD, U+73FF-7400, U+7404, U+7411, U+742D, U+7451, U+7466-7468, U+746E, U+7472, U+7480, U+748F, U+7491, U+749A, U+74AE-74AF, U+74B1-74B2, U+74CC, U+74D0, U+74D3, U+74DB, U+74DF, U+74E4, U+74E8, U+74EA, U+74F4, U+74FB-74FC, U+7506, U+7512, U+7516, U+7521, U+7527, U+7529, U+7536, U+7539, U+753F, U+7543, U+7547, U+7557, U+755E-755F, U+7561, U+757B-757C, U+7585, U+7590, U+7593, U+7595, U+7599, U+759C, U+75A4, U+75B4, U+75BA, U+75C1, U+75C4, U+75CC, U+75D7, U+75DC, U+75E1, U+75EF, U+7604, U+760A, U+760C, U+7612, U+761D, U+7623, U+7629, U+7632, U+7638-763A, U+7640, U+7644-7645, U+764A, U+7659, U+765F, U+7674, U+7685, U+768C-768D, U+769F, U+76A2-76A5, U+76AD, U+76BD, U+76C1, U+76CB, U+76D9, U+76E0, U+76F0, U+76F6, U+76F9, U+7700, U+7706, U+770E, U+7712, U+7714-7715, U+771C, U+7722, U+7728, U+772E-772F, U+7739, U+773D-773E, U+7742, U+7745, U+774A, U+774F, U+7752, U+7756-7757, U+775E, U+7764, U+7767, U+776C, U+7770, U+7773-7774, U+7784, U+778C-778D, U+7795-7796, U+77A7;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular074.woff2") format("woff2");
  unicode-range: U+77AE-77AF, U+77B1, U+77B5, U+77BE, U+77C3, U+77C9, U+77D1-77D2, U+77D5, U+77D9, U+77F1, U+77F8, U+780E, U+7811, U+781D, U+7822-7823, U+782E, U+7830, U+7835, U+7837, U+7844, U+7848, U+784C, U+7852, U+785C, U+785E, U+7860-7861, U+7863, U+787E, U+788F, U+7898, U+78A1, U+78A8, U+78AC, U+78B1-78B3, U+78BD, U+78BF, U+78C7, U+78C9, U+78D2-78D3, U+78D6, U+78DB, U+78DF, U+78EA, U+78F3, U+78F6, U+78FF, U+7906, U+790C, U+791A, U+791E-7920, U+7927, U+7929, U+792D, U+7935, U+793D, U+793F, U+7944, U+794B, U+794F, U+7951, U+7954, U+7969, U+796B, U+797B-797C, U+797E, U+798B-798C, U+7991, U+7993, U+799C, U+79A8, U+79AB, U+79AF, U+79C4, U+79CF, U+79DD, U+79E0, U+79E2, U+79E5, U+79EA, U+79F1, U+79F8, U+79FC, U+7A02, U+7A07, U+7A09, U+7A0C, U+7A21, U+7A27, U+7A2B, U+7A2F-7A30, U+7A34-7A35, U+7A3A, U+7A44-7A45, U+7A48, U+7A55, U+7A65, U+7A7E, U+7A80, U+7A86, U+7A8B, U+7A91, U+7A94, U+7A9E, U+7AB5, U+7AC6, U+7AC9, U+7ADB, U+7AE9, U+7AEC, U+7AF1, U+7AFB, U+7AFE, U+7B14, U+7B1F, U+7B23, U+7B29, U+7B2B, U+7B30, U+7B34, U+7B3F-7B40, U+7B6A, U+7B77, U+7B84, U+7B89, U+7B8E, U+7B96, U+7BA0, U+7BA5, U+7BAC, U+7BB0, U+7BB2, U+7BB6, U+7BBA-7BBB, U+7BBD, U+7BC2, U+7BC8, U+7BDB, U+7BE8, U+7BF2, U+7BF4-7BF5, U+7BF8-7BFA, U+7BFC, U+7BFE, U+7C02, U+7C04, U+7C06, U+7C09, U+7C0C, U+7C19, U+7C1B, U+7C25, U+7C28, U+7C2C, U+7C34, U+7C39-7C3A, U+7C46, U+7C52-7C53, U+7C55, U+7C5A-7C5D, U+7C63, U+7C67, U+7C72, U+7C7C-7C7D, U+7C86-7C87, U+7C9E, U+7CB0, U+7CBA-7CBB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular075.woff2") format("woff2");
  unicode-range: U+2EB1, U+7CC7, U+7CC9, U+7CCF, U+7CD3-7CD4, U+7CDA, U+7CE9, U+7D0F, U+7D11, U+7D16, U+7D26, U+7D2A, U+7D2D, U+7D3C, U+7D4D, U+7D51, U+7D57, U+7D5D, U+7D65, U+7D67, U+7D78, U+7D7B, U+7D81-7D82, U+7D85, U+7D8D, U+7D91, U+7D96, U+7D9E, U+7DB3, U+7DB9, U+7DC3-7DC6, U+7DCD-7DCE, U+7DE2, U+7DE5, U+7DEA-7DEB, U+7DED, U+7DF5-7DF6, U+7DFA, U+7E00, U+7E1C, U+7E2D, U+7E2F, U+7E33, U+7E36, U+7E3F, U+7E44, U+7E4E, U+7E50, U+7E58, U+7E5F, U+7E62, U+7E65, U+7E6E-7E6F, U+7E78, U+7E81, U+7E95, U+7E9D-7E9E, U+7F3D, U+7F3F, U+7F53, U+7F5B-7F5D, U+7F65-7F66, U+7F71, U+7F7F-7F80, U+7F8B, U+7F8D, U+7F8F, U+7FA2, U+7FA6, U+7FAA, U+7FB4, U+7FBC, U+7FC0, U+7FC8, U+7FE8, U+7FFD-7FFF, U+8007, U+800D, U+800F, U+8013, U+801D-8020, U+802E, U+8034, U+8039-803A, U+803E, U+8040, U+8044, U+8060, U+8064, U+806D, U+8081, U+8088, U+808E, U+809E, U+80A6, U+80AB, U+80B9, U+80C8, U+80CD, U+80CF, U+80D2, U+80D4, U+80ED-80EE, U+80F0, U+80F2, U+80F6, U+80F9-80FA, U+80FE, U+8103, U+810B, U+8117, U+811C, U+8120, U+8130, U+813C, U+8145, U+8147, U+814C, U+8152, U+8157, U+8161, U+816F, U+8177, U+8186, U+818B, U+818E, U+8190, U+8196, U+819B, U+819E, U+81A2, U+81AE, U+81B4, U+81C5, U+81CB, U+81CE, U+81D5, U+81DD-81DE, U+81E1, U+81EB, U+81F0-81F2, U+81F5-81F6, U+81F8-81F9, U+8200, U+8203, U+820F, U+8213-8214, U+821A, U+821D, U+8228, U+823A, U+8243-8244, U+824E, U+8251, U+8256, U+8267, U+826D, U+827B, U+8280-8281, U+8284, U+8287, U+8289, U+8291, U+8294, U+8296, U+8298, U+829A-829B, U+82A0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular076.woff2") format("woff2");
  unicode-range: U+82AA, U+82B0, U+82D0, U+82DA, U+82E0, U+82E4, U+82EA, U+82ED, U+82EF, U+82F6, U+830A-830B, U+831E-831F, U+8321, U+832C, U+832E, U+8333, U+8337, U+833D, U+8342, U+834D-834E, U+8353, U+8356, U+8370, U+8378, U+8380, U+8382, U+8384, U+8399, U+839C, U+83A6, U+83AC-83AD, U+83BE, U+83E8, U+83F8, U+83FC, U+8419, U+842F, U+8445, U+8447, U+844D, U+8456, U+8458, U+8460, U+8464, U+8467, U+846A, U+8474, U+847D, U+8492-8493, U+8495, U+84A8-84AA, U+84B1, U+84BD, U+84C7-84C8, U+84CC, U+84F2, U+84F7, U+84FB, U+8502-8503, U+8507, U+850E, U+8510, U+851C, U+8522, U+8527, U+852A, U+8533, U+8536, U+853F, U+8546, U+8550, U+8552, U+8556, U+855C-855D, U+855F-8560, U+8579, U+8585, U+8589, U+858B, U+8598, U+85A0, U+85A5, U+85A7, U+85B4, U+85B6, U+85B8, U+85BD-85BF, U+85C2, U+85DA, U+85E0, U+85E8, U+85F3, U+85FC, U+860D-860E, U+8619, U+861B, U+8636, U+863A, U+863D, U+8642, U+8646, U+8652, U+8658-8659, U+865D, U+8660-8661, U+8663-8664, U+8669, U+8676, U+8688, U+8696, U+869A, U+86A1, U+86A6-86A7, U+86AD, U+86B4-86B5, U+86B7, U+86B9, U+86BF-86C0, U+86C5, U+86D2, U+86DA, U+86DC, U+86E0, U+86E5, U+86E7, U+8704, U+870F, U+8714, U+871E, U+8728, U+872E-872F, U+8732, U+8739, U+873C-873D, U+8745, U+874D, U+875D, U+8761, U+876F, U+877B, U+8783-8786, U+878C, U+8790, U+8795, U+8797-8799, U+879E, U+87A3, U+87AE, U+87B1, U+87BF, U+87C8-87CA, U+87D5, U+87D9-87DA, U+87DC, U+87E2, U+87E4, U+87F1, U+87F3, U+87F8, U+87FA, U+87FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular077.woff2") format("woff2");
  unicode-range: U+8809, U+8812, U+8818-881C, U+881E, U+882D, U+8830, U+8835, U+883A, U+8841, U+8843, U+8845, U+8848-8849, U+884B, U+884E, U+8851, U+8855-8856, U+885A, U+885C, U+8860, U+8871, U+8879, U+887B, U+8880, U+889A-889C, U+889F, U+88A8, U+88BA, U+88CB-88CD, U+88DE, U+88E7, U+88EF, U+88F7, U+8906, U+890D-890F, U+8915-8916, U+8920, U+8926, U+8928, U+8931, U+8935, U+893A, U+893E, U+8946, U+894F, U+8952, U+8957, U+895A-895C, U+8961, U+8963, U+896B, U+896E, U+8970, U+8973, U+8975, U+897A-897D, U+898D, U+8995, U+899B-899C, U+89A0, U+89A5, U+89B4-89B6, U+89BC, U+89D5, U+89D7, U+89E9, U+89ED, U+89F9, U+8A04-8A05, U+8A07, U+8A0F, U+8A1E, U+8A20, U+8A24, U+8A26, U+8A2B-8A2C, U+8A2F, U+8A3D, U+8A40, U+8A43, U+8A49, U+8A53, U+8A56-8A57, U+8A5C, U+8A65, U+8A67, U+8A76-8A77, U+8A7A-8A7B, U+8A7E-8A80, U+8A83, U+8A86, U+8A8B, U+8A8F, U+8A92, U+8A96-8A97, U+8A99, U+8A9F, U+8AA9, U+8AAF, U+8AB3, U+8AB6, U+8ABB, U+8AC3, U+8AC6, U+8AC8-8ACA, U+8AD1, U+8AD3-8AD5, U+8ADD, U+8AEC, U+8AF0, U+8AF5, U+8AFF, U+8B06, U+8B0B, U+8B11, U+8B1E, U+8B30, U+8B37, U+8B3C, U+8B42, U+8B44-8B45, U+8B48, U+8B4D, U+8B52, U+8B63, U+8B6D, U+8B78-8B79, U+8B7C, U+8B7E, U+8B84-8B85, U+8B8D, U+8B8F, U+8B9F, U+8C38, U+8C3E, U+8C4B, U+8C51, U+8C53, U+8C58-8C59, U+8C5B, U+8C5D, U+8C63-8C64, U+8C66, U+8C75-8C76, U+8C7B, U+8C7E, U+8C86-8C87, U+8C8B, U+8C90, U+8C9C, U+8CB9-8CBA, U+8CC5-8CC6, U+8CC9, U+8CCB, U+8CCF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular078.woff2") format("woff2");
  unicode-range: U+8CDD, U+8CE8, U+8CEC, U+8CEF, U+8CF2, U+8CF5, U+8CF7, U+8CFF, U+8D01, U+8D03, U+8D17, U+8D65, U+8D69, U+8D6E, U+8D7F, U+8D82, U+8D88, U+8D8D, U+8D90-8D91, U+8D9E-8DA0, U+8DAB-8DAC, U+8DB2, U+8DB5, U+8DB7, U+8DB9, U+8DBB-8DBC, U+8DC0, U+8DC5, U+8DC7, U+8DCA, U+8DD4-8DD5, U+8DE4-8DE5, U+8DE7, U+8DF0-8DF2, U+8DF4, U+8E01, U+8E04-8E05, U+8E0B, U+8E11, U+8E26, U+8E31, U+8E33, U+8E37-8E38, U+8E40-8E41, U+8E4D-8E4F, U+8E5B-8E5E, U+8E61, U+8E69, U+8E71, U+8E79-8E7A, U+8E82-8E83, U+8E89, U+8E90, U+8E92, U+8E95, U+8E9A-8E9B, U+8E9D, U+8EA2, U+8EA7, U+8EA9, U+8EAD, U+8EBA, U+8EC1, U+8EC3-8EC4, U+8EC7, U+8EDC, U+8EE8, U+8EED-8EEE, U+8EF0-8EF1, U+8EF7, U+8F02, U+8F07, U+8F0F-8F10, U+8F16, U+8F18, U+8F20-8F21, U+8F23, U+8F25, U+8F27-8F28, U+8F2C, U+8F2E, U+8F34-8F35, U+8F37, U+8F3A, U+8F41, U+8F43, U+8F47, U+8F4F, U+8F51-8F53, U+8F55, U+8F5D-8F5E, U+8F65, U+8F9D, U+8FA0-8FA1, U+8FA5, U+8FB8, U+8FBE, U+8FC0, U+8FCB, U+8FD0, U+8FD2, U+8FE3, U+8FFE, U+9004, U+9018, U+901B, U+9028-902A, U+902C, U+902F, U+9033-9034, U+903F, U+904C, U+905B, U+9062, U+9066, U+906C, U+9070, U+9074, U+9079, U+908B, U+908E, U+9098, U+90A0, U+90A5, U+90B2, U+90B4, U+90BD, U+90CC, U+90D2, U+90D5, U+90D8-90D9, U+90E5, U+90F0, U+9105, U+9108, U+910D, U+9110, U+9117, U+911A, U+9120, U+9125, U+9129, U+912E, U+9136, U+913C, U+9143, U+914F, U+9153, U+915A, U+9167, U+916D, U+917A-917B, U+9181, U+9186, U+918A, U+9191;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular079.woff2") format("woff2");
  unicode-range: U+9193-9195, U+9198, U+91A1, U+91A6, U+91AD, U+91B0, U+91BB, U+91BD, U+91BF, U+91C2, U+91C5, U+91D3-91D4, U+91D9, U+91E9-91EA, U+91EF-91F0, U+91F7, U+91F9, U+91FD, U+9200, U+9204-9205, U+9209, U+920C, U+9212-9213, U+9218, U+921C-921D, U+9223-9226, U+9228, U+922E-9230, U+9235-9236, U+923E, U+9246, U+924D, U+9258, U+925C-925D, U+9269, U+926E-9270, U+9275-9276, U+9279, U+927B, U+928A, U+928E, U+9292, U+92A0, U+92A4-92A5, U+92A8, U+92AF, U+92B6, U+92B8, U+92BA-92BD, U+92C1, U+92C3, U+92C5, U+92C7-92C8, U+92CD, U+92D8, U+92DC-92DD, U+92DF, U+92E1, U+92E3, U+92E8, U+92EC, U+92EE, U+92F0, U+9300, U+9308, U+9314, U+931C, U+9324, U+932A, U+9333-9334, U+9336-9337, U+9350, U+9352, U+9355, U+9358, U+935E, U+9367, U+9369, U+936D, U+936F, U+9374, U+9376, U+937A, U+937D, U+937F-9382, U+938A, U+938D, U+9392, U+9395, U+9398, U+93A6, U+93A8-93A9, U+93AB, U+93B4-93B6, U+93C4-93C5, U+93C9-93CD, U+93D3, U+93D9, U+93E6, U+93F7, U+93F9-93FA, U+9401-9402, U+9408, U+940D-940E, U+9415, U+941F, U+942E-942F, U+943D, U+943F, U+9443, U+944C, U+9455, U+9459, U+945C, U+945F, U+9461, U+9468, U+946E, U+9483-9484, U+957E, U+9584, U+9588, U+959D-959E, U+95A1, U+95BA, U+95BF, U+95C6, U+95C9, U+95D1-95D2, U+95D9, U+95DD, U+95DF-95E0, U+95E4, U+95E6, U+961E, U+9622, U+9624-9626, U+9631, U+9637-963A, U+963D, U+9652, U+9654, U+9656-9657, U+966E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular080.woff2") format("woff2");
  unicode-range: U+9674, U+967B-967C, U+967E-967F, U+9681, U+9683, U+9689, U+9691, U+9696, U+969F, U+96A6, U+96AE, U+96CA, U+96DF, U+96F1, U+96FA, U+9702-9703, U+9705, U+9709, U+971A-971B, U+971D, U+9721-9722, U+9728, U+9731, U+974A, U+9758, U+9763, U+9767, U+9776-9778, U+977B, U+977D, U+977F-9780, U+9789, U+9797, U+9799, U+979F, U+97AC, U+97B5-97B6, U+97B8-97B9, U+97BC, U+97BF, U+97C4-97C5, U+97C7, U+97CA, U+97CD-97CE, U+97D0, U+97D7, U+97DD, U+97E0, U+97E4, U+97EF, U+97F7-97F8, U+97FA, U+9807, U+9819, U+981C, U+9820, U+9826, U+982E-982F, U+9835, U+983E, U+9844, U+984A, U+9851, U+9862-9863, U+986A, U+98AE, U+98B4, U+98C5, U+98CC, U+98E6, U+98F6, U+9902, U+9907, U+9911, U+9915, U+991F, U+9922, U+9926, U+992B, U+9934-9935, U+9939, U+9947-9948, U+9954, U+9959, U+995B, U+995E, U+999B, U+999D, U+999F, U+99B0-99B2, U+99B5, U+99BA, U+99D3, U+99DA, U+99DC, U+99E7, U+99EA-99EC, U+99F4-99F5, U+99FD-99FE, U+9A04, U+9A0B-9A0C, U+9A10, U+9A1E, U+9A22-9A23, U+9A27, U+9A33, U+9A35, U+9A41, U+9A47, U+9A4B, U+9A51, U+9A54, U+9A56, U+9A5D, U+9AAA, U+9AAC, U+9AAE, U+9AB2, U+9AB4-9AB5, U+9ABB, U+9ABE-9ABF, U+9AC8, U+9AD7, U+9ADB, U+9AE4, U+9AE7, U+9AEC, U+9AF2-9AF3, U+9AF5, U+9AFA, U+9AFD, U+9AFF-9B01, U+9B04-9B05, U+9B09, U+9B0B, U+9B0D-9B0E, U+9B19, U+9B1B, U+9B26, U+9B34-9B35, U+9B37, U+9B39-9B3A, U+9B48, U+9B4C, U+9B55-9B57, U+9B5B, U+9B61, U+9B68, U+9B6A, U+9B6D-9B6E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular081.woff2") format("woff2");
  unicode-range: U+2E8B-2E8D, U+2E95, U+2EA2, U+2EA9, U+2EAC, U+2EAE, U+2EB3, U+2EB7, U+2EBC, U+2EC6, U+2ED7, U+2EDD, U+3405, U+3427, U+3488, U+353E, U+35DE, U+3614, U+38A1, U+38AD, U+39A4, U+39B8, U+3A5C, U+3A85, U+3AC4, U+3ACB, U+3AF3, U+3BF3, U+3D31, U+3D4E, U+3DCC, U+3E40, U+3E8A, U+3FB1, U+3FDC, U+4293, U+440C, U+457A, U+45BE, U+4610, U+46AE, U+471F, U+47E6, U+481E, U+4B38, U+4B7E, U+4C38, U+4CE1, U+4E37, U+4F2B, U+4FD3, U+9B78, U+9B7F-9B80, U+9B85-9B87, U+9B89, U+9B8B, U+9B8D, U+9B90, U+9B94, U+9B9A, U+9B9D, U+9BA9, U+9BB0, U+9BB7, U+9BBC, U+9BBF, U+9BC8, U+9BD0, U+9BD7, U+9BDF, U+9BF9, U+9BFF, U+9C02, U+9C0B, U+9C0F, U+9C11, U+9C1C, U+9C1E, U+9C26, U+9C28, U+9C35, U+9C3D, U+9C43-9C44, U+9C4E, U+9C56, U+9C5E, U+9C68, U+9C7B, U+9CF7, U+9CF9, U+9D1C, U+9D2F-9D30, U+9D33-9D34, U+9D3A, U+9D3C-9D3D, U+9D45, U+9D53-9D54, U+9D65, U+9D6A, U+9D7B, U+9D83, U+9D86, U+9D8E, U+9D92-9D93, U+9D95, U+9D97-9D98, U+9DAA, U+9DAE, U+9DB1, U+9DCA, U+9DD4-9DD5, U+9DDE, U+9DE5, U+9DE9, U+9DEB, U+9DEE, U+9DF0, U+9DF3, U+9DFE, U+9E0E, U+9E10-9E12, U+9E16, U+9E7A, U+9E82-9E84, U+9E87, U+9E8E-9E8F, U+9E96, U+9E98, U+9EAE, U+9EB0, U+9EB3, U+9EC6, U+9EC8, U+9ECB, U+9ED5, U+9EE4, U+9EEC-9EED, U+9EF0-9EF2, U+9EF5, U+9EF8, U+9F09, U+9F0F, U+9F11-9F12, U+9F14, U+9F16, U+9F1A-9F1B, U+9F1F, U+9F22, U+9F26, U+9F2A-9F2B, U+9F31-9F32, U+9F34, U+9F3C, U+9F3F, U+9F43-9F44, U+9F47, U+9F56, U+9F5A, U+9F5E, U+9F68, U+9F6E-9F6F, U+9F71, U+9F73, U+9F7A, U+9F7D, U+9F8F, U+9F91-9F92, U+9F96, U+9F9E, U+9FA1, U+9FA3, U+9FA5, U+25AD7, U+2626A, U+2D544;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular082.woff2") format("woff2");
  unicode-range: U+5077, U+50F0, U+5167, U+5174, U+5179, U+51D4, U+51DF, U+5226, U+522B, U+5389, U+53C1, U+53C4, U+5433, U+543F, U+544C, U+5527, U+55EC, U+55EE, U+55F1, U+55F8, U+5620, U+575F, U+57AE, U+586D, U+5898, U+591F, U+5939, U+59EC, U+5A1B, U+5B76, U+5B82, U+5C14, U+5C19, U+5C2A, U+5CE6, U+5DFF, U+5E99, U+5F3A, U+5F65, U+6164, U+617D, U+61E8, U+6231, U+6236, U+631B, U+65BE, U+65E3, U+66B3, U+6725, U+6735, U+6743, U+6780, U+67A4, U+67BE, U+67FA, U+6805, U+688E, U+6986, U+6A54, U+6AF8, U+6B72, U+6CAC, U+6D01, U+6E42, U+6E89, U+6EBC, U+6EDA-6EDB, U+6EE6, U+6F9D, U+6FB7, U+71F4, U+73BA, U+73E4, U+7522, U+764E, U+76EB, U+77B7, U+7928, U+7942, U+7A05, U+7ABD, U+7B53, U+7C2F, U+7C42, U+7C7B, U+7D55, U+7DD0, U+7E48, U+80F7, U+812B, U+8141, U+8173, U+81EF, U+820B, U+8346, U+835A, U+85F4, U+867D, U+8722, U+878E, U+886E, U+88C6, U+88E0, U+8A01, U+8AAA, U+8B0F, U+8C9F, U+8CD2, U+8D1C, U+8E75, U+8E77, U+8FA2, U+8FC8, U+90C9, U+92B3, U+93BB, U+95B1, U+95E8, U+9839, U+99E1, U+9A08, U+9ABD, U+9B1D, U+9B2C, U+9C61, U+9FB4, U+9FBC-9FC2, U+9FCC, U+F909, U+F91F, U+F95F, U+F983, U+F999-F99A, U+F9A2, U+F9C3, U+FA03, U+2008A, U+200B0, U+200F5, U+2053F, U+205B1, U+20611, U+206EC, U+207C8, U+2090E, U+20984, U+20A64, U+20D58, U+21A1A, U+22609, U+22E42, U+22FEB, U+237F1, U+23CBE, U+2404B, U+243D0, U+24E04, U+24FF2, U+2567F, U+25874, U+259CC, U+26222, U+26999, U+26C9E, U+279B4, U+27C3C, U+27D73, U+28CDD, U+28E17, U+28EF6, U+29D4B, U+2A2B2, U+2A9E6, U+2B746, U+2B751, U+2B753, U+2B75A, U+2B75C, U+2B765, U+2B776-2B777, U+2B77C, U+2B782, U+2B789, U+2B78B, U+2B78E, U+2B794, U+2B7AC, U+2F82C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular083.woff2") format("woff2");
  unicode-range: U+2B7AF, U+2B7BD, U+2B7C9, U+2B7CF, U+2B7D2, U+2B7D8, U+2B7F0, U+2B80D, U+2B817, U+2B81A, U+2E278, U+2E569, U+2E6EA, U+2F804, U+2F80F, U+2F815, U+2F81A, U+2F822, U+2F828, U+2F833, U+2F83F, U+2F846, U+2F852, U+2F862, U+2F86D, U+2F873, U+2F884, U+2F899-2F89A, U+2F8A6, U+2F8AC, U+2F8B2, U+2F8B6, U+2F8D3, U+2F8E1, U+2F8E5, U+2F8EA, U+2F8FC, U+2F903, U+2F90B, U+2F90F, U+2F91A, U+2F920-2F921, U+2F945, U+2F947, U+2F96C, U+2F995, U+2F9D0, U+2F9DE-2F9DF, U+2F9F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Regular;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Regular084.woff2") format("woff2");
  unicode-range: U+F860-F862, U+F87A, U+F87F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium001.woff2") format("woff2");
  unicode-range: U+2C, U+2E, U+3A-3B, U+41-5A, U+60-7A, U+C0-D6, U+D8-DD, U+E?, U+F1-F6, U+F8-FD, U+FF-113, U+116-122, U+124-126, U+128-12B, U+12E-13D, U+141-149, U+14B-14D, U+150-17E, U+1CD-1DC, U+1F5, U+1F8-1F9, U+1FD, U+237, U+251, U+254, U+259-25B, U+28C, U+300-301, U+1E3E-1E3F, U+1EBC-1EBD, U+201A, U+3001-3002, U+3042, U+3044, U+3046, U+3048, U+304A-3082, U+3084, U+3086, U+3088-308D, U+308F-3094, U+309A, U+30A1-30A4, U+30A6-30E6, U+30E8-30EC, U+30EF, U+30F2-30F4, U+30FC, U+31F7, U+FB00-FB04, U+FE10-FE12, U+FF0C, U+FF0E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium002.woff2") format("woff2");
  unicode-range: U+0-2B, U+2D, U+2F-39, U+3C-40, U+5B-5F, U+7B-7E, U+A0-BF, U+D7, U+DE-DF, U+F0, U+F7, U+FE, U+304, U+327, U+332, U+2011, U+2219, U+223C, U+FF21-FF3A, U+FF41-FF5A, U+FF61-FF9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium003.woff2") format("woff2");
  unicode-range: U+2BB-2BC, U+2003, U+2010, U+2015-2016, U+2018-2019, U+201C-201D, U+2020-2021, U+2025-2026, U+2030, U+2032-2033, U+203B, U+2103, U+212B, U+2190-2193, U+21D2, U+21D4, U+2200, U+2202-2203, U+2207-2208, U+220B, U+2212, U+221A, U+221D-221E, U+2220, U+2227-222C, U+2234-2235, U+223D, U+2252, U+2260-2261, U+2266-2267, U+226A-226B, U+2282-2283, U+2286-2287, U+22A5, U+2312, U+2329-232A, U+2500-2503, U+250C, U+250F-2510, U+2513-2514, U+2517-2518, U+251B-251D, U+2520, U+2523-2525, U+2528, U+252B-252C, U+252F-2530, U+2533-2534, U+2537-2538, U+253B-253C, U+253F, U+2542, U+254B, U+25A0-25A1, U+25B2-25B3, U+25BC-25BD, U+25C6-25C7, U+25CB, U+25CE-25CF, U+25EF, U+2605-2606, U+2640, U+2642, U+266A, U+266D, U+266F, U+3000, U+3003, U+3005-3015, U+301C, U+3041, U+3043, U+3045, U+3047, U+3049, U+3083, U+3085, U+3087, U+308E, U+309B-309E, U+30A5, U+30E7, U+30ED-30EE, U+30F0-30F1, U+30F5-30F6, U+30FB, U+30FD-30FE, U+4EDD, U+FF01, U+FF03-FF06, U+FF08-FF0B, U+FF0D, U+FF0F-FF20, U+FF3B, U+FF3D-FF40, U+FF5B-FF5E, U+FFE3, U+FFE5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium004.woff2") format("woff2");
  unicode-range: U+391-3A1, U+3A3-3A9, U+3B1-3C1, U+3C3-3C9, U+401, U+410-44F, U+451, U+3303, U+330D, U+3314, U+3318, U+3322-3323, U+3326-3327, U+332B, U+3336, U+333B, U+3349-334A, U+334D, U+3351, U+3357, U+337B-337E, U+338E-338F, U+339C-339E, U+33A1, U+33C4, U+33CB, U+33CD, U+FE45-FE46, U+FF02, U+FF07, U+FF3C, U+FF5F-FF60, U+FFE0-FFE2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium005.woff2") format("woff2");
  unicode-range: U+25B1, U+25B6-25B7, U+25C0-25C1, U+25C9, U+25D0-25D3, U+25E6, U+2600-2603, U+260E, U+2616-2617, U+261E, U+2660-2669, U+266B-266C, U+266E, U+2713, U+2756, U+2776-277F, U+2934-2935, U+29BF, U+29FA-29FB, U+3016-3019, U+301D, U+301F-3020, U+3033-3035, U+303B-303D, U+3095-3096, U+309F-30A0, U+30F7-30FA, U+30FF, U+31F0-31F6, U+31F8-31FF, U+3231-3232, U+3239, U+3251-325F, U+32A4-32A8, U+32B1-32BF, U+32D0-32E3, U+32E5, U+32E9, U+32EC-32ED, U+32FA, U+32FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium006.woff2") format("woff2");
  unicode-range: U+2C6, U+2DC, U+302-303, U+305-306, U+308, U+30B-30C, U+30F, U+318-31A, U+31C-320, U+324-325, U+329-32A, U+32C, U+32F-330, U+334, U+336, U+339-33D, U+361, U+3C2, U+1F70-1F73, U+2012-2014, U+2022, U+203C, U+203E-203F, U+2042, U+2047-2049, U+2051, U+20AC, U+210F, U+2113, U+2116, U+2121, U+2127, U+2135, U+2153-2155, U+2160-216B, U+2170-217B, U+2194, U+2196-2199, U+21C4, U+21E6-21E9, U+2205, U+2209, U+2213, U+221F, U+2225-2226, U+222E, U+2243, U+2245, U+2248, U+2262, U+2276-2277, U+2284-2285, U+228A-228B, U+2295-2297, U+22BF, U+22DA-22DB, U+2305-2306, U+2318, U+23BE-23CC, U+23CE, U+2423, U+2460-2473, U+24D0-24E9, U+24EB-24FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium007.woff2") format("woff2");
  unicode-range: U+127, U+13E, U+193, U+1C2, U+250, U+252-253, U+255-258, U+25C, U+25E-261, U+264-268, U+26C-273, U+275, U+279-27B, U+27D-27E, U+281-284, U+288-28B, U+28D-28E, U+290-292, U+294-295, U+298, U+29D, U+2A1-2A2, U+2C7-2C8, U+2CC, U+2D0-2D1, U+2D8-2D9, U+2DB, U+2DD-2DE, U+2E5-2E9, U+FD6, U+213B, U+2150-2152, U+2156-215B, U+2189, U+2211, U+2474-247F, U+2488-2492, U+24B9, U+24C8, U+26BE, U+26CB, U+27A1, U+2B05-2B07, U+2B95, U+3036, U+322A-3230, U+3233, U+3236-3237, U+3244, U+328B, U+3299, U+3371, U+3390, U+33A0, U+33A2, U+33A4-33A5, U+1F100, U+1F110-1F129, U+1F131, U+1F13D, U+1F13F, U+1F142, U+1F146, U+1F157, U+1F15F, U+1F179, U+1F17B-1F17C, U+1F17F, U+1F202, U+1F237;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium008.woff2") format("woff2");
  unicode-range: U+205A, U+205D, U+3300-3302, U+3304-330C, U+330E-3313, U+3315-3317, U+3319-3321, U+3324-3325, U+3328-332A, U+332D-3335, U+3337-333A, U+333C-3348, U+334B-334C, U+334E-3350, U+3352-3356, U+337F, U+3385-3389, U+338D, U+3396-3398, U+339B, U+339F, U+33A3, U+33A6, U+33B0-33B3, U+33C2, U+33C8, U+33CC, U+33D4, U+33D7-33D8, U+33DA, U+A7B5, U+FE17-FE19, U+FE30-FE33, U+FE35-FE44, U+FE47-FE48, U+FFE4, U+FFE8, U+1F130, U+1F132-1F13C, U+1F13E, U+1F140-1F141, U+1F143-1F145, U+1F147-1F149, U+1F150-1F156, U+1F158-1F15E, U+1F160-1F169, U+1F170-1F178, U+1F17A, U+1F17D-1F17E, U+1F180-1F189;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium009.woff2") format("woff2");
  unicode-range: U+2581-258F, U+2594-2595, U+25A2, U+25AA-25AB, U+25CA, U+25CC, U+25E2-25E5, U+25FB-25FC, U+261C-261D, U+261F, U+2672-267D, U+26A0, U+26AA-26AB, U+26BD, U+2702, U+271A, U+273F-2740, U+27BF, U+2B1A, U+2B25-2B26, U+2B29, U+2B60-2B65, U+2B82-2B83, U+2E40, U+3004, U+301E, U+3030, U+3099, U+319?, U+3220-3229, U+3234-3235, U+3238, U+323A-3243, U+3280-328A, U+328C-3298, U+329A-32A3, U+32A9-32B0, U+32E4, U+32E6-32E8, U+32EA-32EB, U+32EE-32F9, U+32FB-32FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium010.woff2") format("woff2");
  unicode-range: U+2002, U+201E, U+2039-203A, U+2044, U+2070, U+2074-2079, U+207F-2089, U+20DD-20DE, U+2100, U+2105, U+2109-210A, U+2122, U+2126, U+212E, U+215C-215E, U+217F, U+21C5-21C6, U+21CB-21CC, U+21D0, U+21F5, U+2206, U+220A, U+220F, U+222D, U+2264-2265, U+2272-2273, U+2298, U+229E, U+22A0, U+22C4, U+2307, U+239B-23AD, U+23B0-23B1, U+2480-2487, U+249C-24B8, U+24BA-24C7, U+24C9-24CF, U+24EA, U+24FF, U+2504-250B, U+250D-250E, U+2511-2512, U+2515-2516, U+2519-251A, U+251E-251F, U+2521-2522, U+2526-2527, U+2529-252A, U+252D-252E, U+2531-2532, U+2535-2536, U+2539-253A, U+253D-253E, U+2540-2541, U+2543-254A, U+2550, U+255E, U+2561, U+256A, U+256D-2573;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium011.woff2") format("woff2");
  unicode-range: U+13F-140, U+14A, U+192, U+262-263, U+26A, U+274, U+276, U+278, U+280, U+28F, U+299, U+29C, U+29F, U+2B0, U+2B2, U+2B7, U+2C1, U+2DA, U+2E0-2E1, U+307, U+30A, U+328, U+384-386, U+388-38A, U+38C, U+38E-390, U+3AA-3B0, U+3CA-3CE, U+3D0-3D1, U+3D5, U+3DB, U+402-40C, U+40E-40F, U+452-45C, U+45E-45F, U+AB53;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium012.woff2") format("woff2");
  unicode-range: U+2EA0, U+2ED1, U+2F00, U+2F06, U+2F08, U+2F0A-2F0B, U+2F12, U+2F17, U+2F1D, U+2F24-2F26, U+2F29, U+2F2D, U+2F3C, U+2F3F, U+2F42, U+2F45, U+2F47, U+2F49-2F4A, U+2F52, U+2F54, U+2F63-2F65, U+2F6C, U+2F74, U+2F83, U+2F8F, U+2F92, U+2F94, U+2F9D, U+2FA6-2FA7, U+2FAF, U+2FB7, U+2FBC, U+4E00, U+4E09-4E0B, U+4E0D, U+4E16, U+4E2D, U+4E3B, U+4E5D, U+4E8B-4E8C, U+4E94, U+4EBA, U+4ECA, U+4EE3, U+4EE5, U+4F1A, U+4F53, U+4F55, U+4F5C, U+4F7F, U+4FE1, U+5148, U+5165, U+5168, U+516B-516D, U+5175, U+5185, U+51FA, U+5206-5207, U+524D, U+529B, U+52D5, U+5316, U+5341, U+539F, U+53D6, U+53E3, U+5408, U+540C-540D, U+5411, U+5473, U+548C, U+54E1, U+554F, U+56DB, U+56DE, U+56FD, U+5730, U+5831, U+5834, U+58F0, U+5909, U+5916, U+591A, U+5927, U+5929-592A, U+5973, U+5B50, U+5B66, U+5B89, U+5B9A, U+5B9F, U+5BB6, U+5BFE, U+5C0F, U+5C11, U+5C4B, U+5C71, U+5DDD, U+5E73-5E74, U+5EA6, U+5F15, U+5F37, U+5F53, U+5F7C, U+5F8C, U+5FC3, U+601D, U+6027, U+60C5, U+610F, U+611F, U+6210, U+6226, U+6240, U+624B, U+6301, U+653F, U+6559, U+6570, U+6587, U+65B0, U+65B9, U+65E5, U+660E, U+6642, U+66F8, U+6700, U+6708, U+6728, U+672C, U+6765, U+6771, U+696D, U+6A5F, U+6B21, U+6B63, U+6B7B, U+6C0F, U+6C11, U+6C17, U+6C34, U+6C7A, U+6CBB, U+6CD5, U+6D77, U+7121, U+7269, U+73FE, U+7406, U+751F, U+7528, U+7530, U+7537, U+753B, U+754C, U+767A, U+7684, U+76EE, U+76F8, U+771F, U+77E5, U+793E, U+795E, U+79C1, U+7ACB, U+7D4C, U+7D50, U+7F8E, U+8003, U+8005, U+805E, U+81EA, U+884C, U+885B, U+8868, U+8981, U+898B, U+89AA, U+8A00, U+8A18, U+8A71, U+8A9E, U+8EAB, U+8ECD, U+8FD1, U+901A, U+9023, U+9053, U+90CE, U+90E8, U+91CD-91CE, U+91D1, U+9577, U+958B, U+9593, U+95A2, U+96C6, U+9762, U+9854, U+98DF, U+9AD8;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium013.woff2") format("woff2");
  unicode-range: U+2E9F, U+2EC4, U+2F1F-2F20, U+2F3E, U+2F40, U+2F57, U+2F69, U+2F6F, U+2F76, U+2F8A, U+2F9C, U+2F9E, U+2FA8, U+2FB3, U+2FB5, U+2FB8, U+2FBA, U+4E03, U+4E07, U+4E21, U+4E57, U+4E89, U+4E95, U+4EA4, U+4EAC, U+4ED5-4ED6, U+4ED8, U+4EF6, U+4F1D, U+4F4F, U+4F9B, U+4FC2, U+4FDD, U+5143, U+5149, U+515A, U+5171, U+5186, U+521D, U+5224-5225, U+5229, U+5236, U+52A0, U+52A9, U+52D9, U+52DD, U+52E2, U+5317, U+5343, U+534A, U+53CD, U+53D7, U+53E4, U+53F0, U+53F3, U+5409, U+544A, U+547C-547D, U+54C1, U+56E3, U+571F, U+5728, U+57CE, U+58EB, U+58F2, U+591C, U+592B, U+597D, U+59CB, U+5B58, U+5B98, U+5BA4, U+5CF6, U+5E02, U+5E2B, U+5E30, U+5E38, U+5E83, U+5E97, U+5F0F, U+5F35, U+5F62, U+5F79, U+5F85, U+5F97, U+5FA1, U+5FC5, U+5FDC, U+60AA, U+60F3, U+611B, U+6238, U+6253, U+6307, U+652F, U+653E, U+6599, U+65AD, U+65CF, U+65E9, U+6620, U+6709, U+671D, U+671F, U+6751, U+677E, U+679C, U+6821, U+683C, U+697D, U+69D8, U+6A29, U+6B66, U+6B69, U+6B73, U+6B8B, U+6BBA, U+6BCD, U+6C5F, U+6D3B, U+6D41, U+6DF1, U+6E08, U+70B9, U+7136, U+7236, U+7279, U+72B6, U+7523, U+7531, U+753A, U+756A, U+75C5, U+767D-767E, U+76F4, U+7740, U+77F3, U+78BA, U+7A7A, U+7B11, U+7B2C, U+7C73, U+7D04, U+7D42, U+7D44, U+7D9A, U+7DCF, U+7DDA, U+7F6E, U+7FA9, U+80FD, U+8272, U+82E5, U+843D, U+8449, U+85E4, U+8853, U+897F, U+8996, U+89E3, U+8A08, U+8A2D, U+8AAC-8AAD, U+8ABF, U+8AD6, U+8B70, U+8CB7, U+8CC7, U+8CEA, U+8D77, U+8DB3, U+8ECA, U+8FBC, U+8FD4, U+9001, U+9032, U+904B, U+904E, U+9055, U+9078, U+90FD, U+914D, U+9580, U+9662, U+969B, U+96FB, U+97F3, U+982D, U+984C, U+98A8, U+9996, U+99AC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium014.woff2") format("woff2");
  unicode-range: U+2ED8, U+2F2F, U+2F4C, U+2F55, U+2F70, U+2F7C, U+2F8E, U+2F95, U+2F9A-2F9B, U+2FB6, U+2FB9, U+2FCA, U+4E0E, U+4E45, U+4E4B, U+4E88, U+4ECB, U+4EFB, U+4F01, U+4F0A, U+4F4D, U+4F50, U+4F8B, U+4FA1, U+4FFA, U+5074, U+5099, U+50CD, U+50CF, U+50D5, U+512A, U+518D, U+5199, U+5272, U+533B, U+5357-5358, U+53C2, U+53CB, U+53CE, U+53EF, U+53F2, U+53F7, U+541B, U+5546, U+55B6, U+5668, U+56F3, U+578B, U+57FA, U+5883, U+5897, U+5931, U+5965, U+59BB, U+59FF, U+5A5A, U+5B57, U+5B88, U+5B97, U+5BA2, U+5BAE, U+5BB3, U+5BB9, U+5BC4, U+5BDF, U+5BFA, U+5C06, U+5C40, U+5C45, U+5CA1, U+5DDE, U+5DE5-5DE6, U+5DEE, U+5DFB, U+5E9C, U+5EA7, U+5EFA, U+5F71, U+5FF5, U+6025, U+606F, U+614B, U+623B, U+6280, U+6295, U+629C, U+62BC, U+632F, U+6483, U+6539, U+65C5, U+6625, U+666F, U+671B, U+672B, U+6761, U+67FB, U+6839, U+6848, U+69CB, U+6A2A, U+6A4B, U+6B4C, U+6B62, U+6BB5, U+6C42, U+6CC9, U+6CE8, U+6D25, U+6D3E, U+6D6E, U+6D88, U+6E05, U+6E21, U+6E80, U+6E90, U+6F14, U+706B, U+71B1, U+72EC, U+738B, U+7403, U+7533, U+7570, U+7687, U+770C, U+773C, U+793A, U+798F, U+79D1, U+7A2E, U+7A81, U+7B49, U+7B54, U+7B56, U+7B97, U+7D00, U+7D19, U+7D20, U+7D30, U+7D71, U+7D76, U+8001, U+8077, U+80B2, U+80CC, U+8239, U+826F, U+82B1, U+82B8, U+82E6, U+82F1, U+8535, U+8840, U+899A, U+89B3, U+8A3C, U+8A8D, U+8AB0, U+8AC7, U+8B58, U+8B66, U+8B77, U+8C37, U+8C61, U+8CA0, U+8D64, U+8D70, U+8DEF, U+8EE2, U+8FFD, U+9020, U+9054, U+9060, U+9152, U+91CF, U+964D, U+9650, U+968A, U+968E, U+96E2-96E3, U+9752, U+9818, U+983C, U+98DB, U+9928, U+9999, U+9A13, U+9ED2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium015.woff2") format("woff2");
  unicode-range: U+2F50, U+2F5A, U+2F81, U+2F93, U+2FAE, U+2FB0, U+4E26, U+4E38, U+4E71, U+4ECF, U+4EF2, U+4F3C, U+4F4E, U+4F59, U+4FEE, U+500B, U+5012, U+5024, U+5065, U+5144, U+5177, U+51B7, U+51E6, U+52B4, U+52B9, U+533A, U+5354, U+5371, U+53BB, U+53F8, U+5404, U+5468, U+559C, U+56F2, U+5712, U+5742, U+57DF, U+5802, U+590F, U+5922, U+59D4, U+5A18, U+5A66, U+5B85, U+5BBF, U+5BC6, U+5BDD, U+5C0E, U+5C3E, U+5C55, U+5D0E, U+5E03, U+5E2D, U+5E2F, U+5E55, U+5E78, U+5E95, U+5EAD, U+5EB7, U+5F93, U+5FA9, U+5FB3, U+5FD7, U+6050, U+6211, U+623F, U+62B1, U+62C5, U+6319, U+63A2, U+63A5, U+63CF-63D0, U+63F4, U+653B, U+6545, U+6575, U+6577, U+661F, U+662D, U+6697, U+66AE, U+670D, U+672A, U+6750, U+6797, U+682A, U+68EE, U+691C, U+6975, U+6B74, U+6BBF, U+6BCE, U+6BD4, U+6C38, U+6CA2, U+6CB3, U+6CE2, U+6D0B, U+6E1B, U+6E29, U+6E96, U+6FC0, U+7247, U+72AF, U+7387, U+74B0, U+7565, U+7591, U+7642, U+767B, U+76E3, U+7701, U+7814, U+7834, U+79C0, U+79CB, U+79D8, U+79FB, U+7A0B, U+7A0E, U+7A4D, U+7A76, U+7AE0, U+7AEF, U+7AF6, U+7BA1, U+7CBE, U+7CFB, U+7D75, U+7DD2, U+7DE8, U+7E54, U+7FD2, U+8089, U+80F8, U+8170, U+8179, U+8208, U+821E, U+8336, U+8349, U+8457, U+85AC, U+8857, U+88C1, U+88C5, U+88CF, U+88FD, U+898F, U+89D2, U+8A2A, U+8A31, U+8A55, U+8A66, U+8A8C, U+8AF8, U+8CA1, U+8CBB, U+8CDE, U+8D8A, U+8EFD, U+8F09, U+8FBA, U+9000, U+9003, U+901F, U+9031, U+904A, U+907A, U+9244, U+9280, U+9332, U+9632, U+9678, U+967A, U+96A0, U+96D1, U+9759, U+975E, U+9769, U+97FF, U+9803, U+985E, U+98F2, U+9BAE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium016.woff2") format("woff2");
  unicode-range: U+2F11, U+2F23, U+2F51, U+2F5D-2F5F, U+2F75, U+2F7B, U+2F7F, U+2F82, U+2F90, U+2FA5, U+2FAC, U+2FBB, U+2FC3, U+2FC7, U+4E91, U+4EA1, U+4EE4, U+4F11, U+5009, U+50B7, U+5104, U+5150, U+5178, U+5200, U+520A, U+5211, U+5217, U+523A-523B, U+5263, U+5287, U+5348, U+535A, U+5370, U+53B3, U+53CA, U+542B, U+5438, U+5584, U+56E0, U+56F0, U+56FA, U+5727, U+58C1, U+5915, U+592E, U+5947-5948, U+5999, U+5ACC, U+5B87, U+5B8C, U+5BCC, U+5C02, U+5C04, U+5C64, U+5CA9, U+5CB8, U+5DE8, U+5EAB, U+5F01, U+5F1F, U+5F31, U+5F3E, U+5F66, U+5FAE, U+5FD8, U+5FE0, U+6012, U+604B, U+6075, U+60B2, U+6255, U+6297-6298, U+6355, U+6368, U+6388, U+639B, U+63A8, U+6557, U+6563, U+6574, U+65BD, U+6613, U+6628, U+666E, U+6674, U+66B4, U+66F2, U+66FF, U+675F, U+677F, U+67C4, U+67D3, U+690D, U+6A19, U+6A21, U+6A39, U+6B32, U+6BDB, U+6C60, U+6CC1, U+6D45, U+6E2F, U+6E6F, U+70BA, U+713C, U+7167, U+7248, U+72AC, U+7384, U+7389, U+7532, U+7559, U+75C7, U+75DB, U+76DB, U+7720, U+7763, U+77AC, U+77ED, U+793C, U+79F0, U+7A93, U+7AF9, U+7B4B, U+7BC0, U+7D14, U+7D1A, U+7D66, U+7D99, U+7E01, U+7F6A, U+7FBD, U+8033, U+8056, U+80A9, U+8131, U+8155, U+81E3, U+822C, U+83EF, U+8584, U+85E9, U+8846, U+8863, U+88AB, U+88DC, U+89E6, U+8A0A, U+8A0E, U+8A33, U+8AB2, U+8B1B, U+8C4A, U+8CAC, U+8CB4, U+8D85, U+8DE1, U+8E0F, U+8F2A, U+8FB2, U+8FCE, U+8FEB, U+8FF0, U+9006, U+9014, U+91CC, U+9589, U+95D8, U+962A, U+963F, U+9663-9664, U+967D, U+969C, U+96C4, U+96E8, U+96EA, U+9707, U+97D3, U+984D, U+9858, U+990A, U+99C6, U+9A5A, U+9AA8, U+9AEA, U+9CE5, U+9CF4, U+9EBB, U+9ED9, U+9F62;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium017.woff2") format("woff2");
  unicode-range: U+2EE4, U+2EE9, U+2EED, U+2F30, U+2F4B, U+2F62, U+2F6A, U+2F6E, U+2F84, U+2FC1-2FC2, U+2FC5, U+2FD0, U+4E01, U+4E08, U+4E5F, U+4E73, U+4E92, U+4EEE, U+4EF0, U+4F0F, U+4F38, U+4F9D, U+4FBF, U+500D, U+5019, U+501F, U+50BE, U+5100, U+5145, U+51AC, U+5247, U+5275, U+529F, U+52E4, U+5305, U+5360, U+539A, U+53E5, U+53EB, U+5410, U+5426, U+5439, U+543E, U+54F2, U+57F7, U+5800, U+58CA, U+5949, U+59C9, U+5B6B, U+5B9D, U+5BA3, U+5BE9, U+5C0A, U+5C31, U+5C4A, U+5C5E, U+5D29, U+5DF1, U+5E0C, U+5E1D, U+5E79, U+5E7C, U+5E81, U+5E8A, U+5EC3, U+5EF6, U+5F25, U+5F8B, U+5F92, U+5FB4, U+5FEB, U+6016, U+60A3, U+60D1, U+61B2, U+61B6, U+624D, U+6279, U+627F, U+62E0-62E1, U+635C, U+63A1, U+63DB, U+63E1, U+63FA, U+643A, U+64AE, U+64CD, U+6551, U+65E7, U+6614, U+663C, U+667A, U+66DC, U+66F4, U+6749, U+679A, U+679D, U+67F3, U+6804, U+6838, U+6B20, U+6B27, U+6B6F, U+6C88, U+6CB9, U+6CCA, U+6CE3, U+6D17, U+6D5C, U+6D66, U+6DF7, U+6E2C, U+6E7E, U+6EC5, U+6F22, U+6FC3, U+702C, U+7159, U+7206, U+7518, U+76AE, U+76CA, U+76D7, U+76DF, U+77E2, U+7802, U+7956, U+7981, U+7B46, U+7BC9, U+7C21, U+7D05, U+7D0D, U+7D39, U+7D61, U+7DAD, U+7DE0, U+7DF4, U+7E04, U+7E70, U+7FA4, U+7FCC, U+8074, U+8133, U+81F3-81F4, U+822A, U+8302, U+8352, U+8377, U+878D, U+88D5, U+8907, U+8972, U+8A34, U+8A69, U+8A70, U+8A98, U+8C6A, U+8CA9, U+8CC0, U+8F38, U+8F9E, U+8FF7, U+9045, U+9063, U+9069, U+907F, U+90A3, U+90F7, U+9178, U+91E3, U+9283, U+93E1, U+95A3, U+9670, U+96A3, U+96F2, U+9732, U+9806, U+98EF, U+99C5, U+9A12, U+9B3C, U+9B54, U+9B5A, U+9E7F, U+9EC4, U+9F3B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium018.woff2") format("woff2");
  unicode-range: U+2EC1, U+2EEF, U+2EF2, U+2F1C, U+2F32, U+2F5C, U+2F73, U+2F86, U+2F88, U+2F8D, U+2F96, U+2F9F, U+2FD3, U+4E39, U+4E43, U+4E80, U+4E86, U+4E9C, U+4EC1, U+4ED9, U+4F2F, U+4F34, U+4F8D, U+4FB5, U+4FCA, U+505C, U+50AC, U+50B5, U+50DA, U+50E7, U+5230, U+5238, U+526F, U+52C7, U+52D8, U+5352, U+5373, U+53C8, U+53CC, U+53E9, U+5442, U+5507, U+5510, U+5747, U+574A, U+57A3, U+57CB, U+585A, U+5869, U+596A, U+5974, U+5982, U+59B9, U+5A01, U+5AC1, U+5B63, U+5B99, U+5BD2, U+5BFF, U+5C0B, U+5C3B, U+5C3D, U+5C48, U+5DE1, U+5E33, U+5E45, U+5E72, U+5FB9, U+5FCD, U+602A, U+6065, U+609F, U+60A9, U+611A, U+6162-6163, U+616E, U+6176, U+61D0, U+61F8, U+6271, U+6291, U+62DB, U+62DD, U+63B2, U+63EE, U+640D, U+6458, U+656C, U+658E, U+65AC, U+65D7, U+65E6, U+6607, U+6669, U+66F9, U+672D, U+676F, U+6885, U+68D2, U+6BD2, U+6C57, U+6C5A, U+6C96, U+6CBC, U+6CBF, U+6D6A, U+6D74, U+6D99, U+6DB2, U+6E09, U+6E56, U+6F5C, U+6F6E, U+706F, U+707D, U+718A, U+71C3, U+725B, U+72C2, U+72D9, U+72ED, U+732B, U+7372, U+75B2, U+7686, U+76E4, U+770B, U+773A, U+7832, U+7968, U+796D, U+7A32, U+7A3F, U+7A74, U+7ADC, U+7AE5, U+7BB1, U+7BC4, U+7C60, U+7DCA, U+7E2E, U+7E3E, U+7E41, U+7F85, U+808C, U+80DE, U+8107, U+811A, U+819D, U+81D3, U+81ED, U+820C, U+820E, U+821F, U+8266, U+83DC, U+864E, U+866B, U+885D, U+888B, U+88C2, U+8A3A, U+8A73, U+8ACB, U+8B00, U+8B1D, U+8C46, U+8CA7-8CA8, U+8CB8, U+8DDD, U+8E8D, U+8ED2, U+8F1D, U+8F9B, U+9084, U+90F5, U+9154, U+91C8, U+91DD, U+9234, U+92AD, U+95C7, U+9686, U+96C5, U+970A, U+9802, U+9805, U+9830, U+98FE, U+99C4, U+9F8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium019.woff2") format("woff2");
  unicode-range: U+2EEB, U+2F77, U+4E18, U+4E7E, U+4EAD, U+4F73, U+4F75, U+4FC3, U+4FD7, U+507D, U+508D, U+5146, U+514D, U+517C, U+518A, U+524A, U+5264, U+5289, U+52AA, U+52C9, U+52DF, U+5302, U+5320, U+5353, U+5374, U+53D4, U+541E, U+54B2, U+54C0, U+552F, U+5531, U+5642, U+5653, U+5750, U+5857, U+5893, U+594F, U+5951, U+596E, U+59D3, U+59EB, U+5B09, U+5B54, U+5B5D, U+5B64, U+5B8F, U+5BDB, U+5C01, U+5C1A, U+5CF0, U+5D07, U+5E7B, U+5E7E, U+5ECA, U+5EFB, U+5F18, U+5F69, U+5F80-5F81, U+6069, U+6094, U+60A0, U+614E, U+618E, U+6212, U+629E, U+62B5, U+62D2, U+62F6, U+6311, U+6328, U+6392, U+6398, U+63A7, U+63DA, U+6469, U+654F, U+6589, U+65E2, U+660C, U+6696, U+670B, U+6717, U+6790, U+67D4, U+67F1, U+67F4, U+685C, U+68C4, U+6905, U+6982, U+6C37, U+6C99, U+6CA1, U+6CE5, U+6D69, U+6DE1, U+6DFB, U+6E0B, U+6ED1, U+6EDE, U+6F01, U+6FA4, U+6FE1, U+708E, U+70AD, U+70C8, U+719F, U+7235, U+7267, U+732E, U+7344, U+73CD, U+7551, U+7573, U+7709, U+786C, U+795D, U+7A42, U+7B20, U+7C4D, U+7C89, U+7CF8, U+7D1B, U+7D22, U+7DB1-7DB2, U+7DD1, U+7F72, U+8010, U+809D, U+80A1, U+80A5, U+8102, U+8105, U+8108, U+8150, U+81E8, U+829D, U+8358, U+83CA, U+85A9, U+865A, U+88F8, U+8986, U+8997, U+8A13, U+8A17, U+8A87, U+8A95, U+8AA0, U+8AA4, U+8CAB, U+8CBF, U+8CCA, U+8CDB, U+8CFC, U+8DA3, U+8E0A, U+8F03, U+8F29, U+900F, U+902E, U+9047, U+90A6, U+90AA, U+90B8, U+90E1, U+92ED, U+9685, U+96C7, U+9756, U+9808, U+9810, U+9867, U+98FC, U+99D0, U+9A0E, U+9B42, U+9B45, U+9DB4, U+9E97;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium020.woff2") format("woff2");
  unicode-range: U+2EE8, U+2F28, U+2F38, U+2F6B, U+2FA0, U+2FB4, U+4E1E, U+4FF3, U+502B, U+5049, U+5075-5076, U+511F, U+514B, U+5176, U+5192, U+51C4, U+51CD, U+51DD, U+5203, U+5237, U+525B, U+525D, U+52E7, U+5339, U+5375, U+53EC, U+5446, U+5449, U+545F, U+5589, U+558B, U+55AB, U+5606, U+5609, U+5674, U+5699, U+570F, U+5782, U+5805, U+5854, U+585E, U+58C7, U+58EE, U+5A46, U+5B22, U+5BC2, U+5BE7, U+5BF8, U+5C3A, U+5C90, U+5CB3, U+5D8B, U+5E3D, U+5E84, U+5E8F, U+5EB5, U+5EF7, U+5F13, U+5F6B, U+5FD9, U+6068, U+60DC, U+60E8, U+614C, U+6249, U+62CD, U+62ED, U+62FE, U+636E, U+6383, U+638C, U+63AA, U+63C3, U+6442, U+6451, U+64AB, U+659C, U+65E8, U+65EC, U+662F, U+664B, U+6676, U+6687, U+6691, U+6731, U+673A, U+674E, U+6762, U+67A0, U+6842, U+68B0, U+68DA, U+6B53, U+6B8A, U+6B96, U+6C41, U+6CF0, U+6CF3, U+6D29, U+6D44, U+6DAF, U+6DBC, U+6E9C, U+6EB6, U+6F02, U+6F0F, U+6F2B, U+6F70, U+6F84, U+7070, U+7126, U+716E, U+731B, U+7434, U+74F6, U+76BF, U+78E8, U+790E, U+7948, U+7950, U+7985, U+7A3C-7A3D, U+7A4F, U+7B52, U+7C92, U+7CA7, U+7CD6, U+7D0B, U+7D2B, U+7DBF, U+7DE9, U+7E1B, U+7E26, U+7F70, U+7FD4, U+7FFC, U+80C6, U+81A8, U+81B3, U+82B3, U+83CC, U+846C, U+8650, U+8896, U+89A7, U+8A5E, U+8A89, U+8B0E, U+8B72, U+8C8C, U+8C9E, U+8CAF, U+8CC3, U+8CE2, U+8D08, U+8DF3, U+8E74, U+8EB0, U+8FB0, U+9019, U+9038, U+9042, U+906D, U+9177, U+9298, U+934B, U+9375, U+938C, U+9396, U+93AE, U+9451, U+9665, U+968F, U+96F7, U+9700, U+9727, U+9774, U+9801, U+9811, U+9837, U+9855, U+99D2, U+9DF9, U+9EA6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium021.woff2") format("woff2");
  unicode-range: U+2F56, U+2F7A, U+2FCE, U+4E4F, U+4F0D, U+504F, U+5098, U+5132, U+5197, U+51A0, U+51E1, U+51F6, U+52A3, U+52B1, U+5351, U+540E, U+54C9, U+5553, U+55A7, U+5629, U+572D, U+582A, U+584A, U+587E, U+5937, U+5983, U+598A, U+5BB0, U+5BB4, U+5C09, U+5C3F, U+5C65, U+5CE0, U+5D50, U+5DE3, U+5DE7, U+5E06, U+5E61, U+5E7D, U+5EB6, U+5F90, U+6052, U+60A6, U+60DA, U+6109, U+6170, U+6182, U+622F, U+62AB, U+62BD, U+62C9, U+62D3, U+62D9, U+62EC, U+631F, U+633F, U+6414, U+64A4, U+64B2, U+64E6, U+65BC, U+66A6, U+66FE, U+6795, U+67B6, U+6817, U+6843, U+6850, U+68A8, U+68CB, U+68DF, U+69CD, U+6B64, U+6BB4, U+6D1E, U+6D32, U+6D78, U+6DF3, U+6DF5, U+6E67, U+6E7F, U+6EDD, U+6F20, U+6F54, U+6F5F, U+6F64, U+7089, U+722A, U+723A, U+7272, U+72A0, U+72E9, U+72FC, U+732A, U+733F, U+7363, U+73E0, U+73ED, U+751A, U+75BE, U+7656, U+76C6, U+76FE, U+7761, U+7768, U+78C1, U+7949, U+7965, U+7984, U+79D2, U+79E9, U+7A1A, U+7AAE, U+7B48, U+7BC7, U+7BE0, U+7C3F, U+7C8B, U+7C97-7C98, U+7CE7, U+7D17, U+7D5E, U+7DBE, U+7E6B, U+7F8A, U+7FFB, U+8015, U+80C3, U+80E1, U+816B, U+8178, U+819A, U+819C, U+8210, U+8217, U+828B, U+82BD, U+82D7, U+82DB, U+83D3, U+845B, U+84B8, U+84BC, U+84C4, U+84CB, U+8526, U+85DD, U+8607, U+862D, U+86C7, U+86EE, U+8776, U+8870, U+88FE, U+8A93, U+8B19, U+8CA2, U+8CB0, U+8CBC, U+8CED, U+8EC0, U+8EF8, U+8F14, U+8FB1, U+90C1, U+90CA, U+9262, U+9271, U+9285, U+932F, U+9640, U+965B, U+9673, U+9676, U+9694, U+984E, U+99B4, U+99D5, U+99FF, U+9B4F, U+9D28, U+9F13;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium022.woff2") format("woff2");
  unicode-range: U+2F04, U+2F61, U+2F6D, U+2F78, U+2F99, U+4E59, U+4E5E, U+4EAE, U+4F10, U+5091, U+5270, U+52C3, U+537F, U+5384, U+53E1, U+53F1, U+540A, U+541F, U+5448, U+546A, U+5504, U+553E, U+559A, U+55AA, U+55B0, U+564C, U+56C1, U+576A, U+57F9, U+5824, U+583A, U+5840-5841, U+58A8, U+58FA, U+5954, U+5996, U+5A20, U+5B8B, U+5B9B, U+5BEE, U+5C16, U+5CE1, U+5D16, U+5E4C, U+5E63, U+5F26, U+5F70, U+5F84, U+5FAA, U+6020, U+602F, U+606D, U+6070, U+60E3, U+6148, U+61A4, U+61C7, U+61F2, U+6216, U+621A, U+6247, U+628A, U+62D8, U+62F3, U+6349, U+63C9, U+64C1, U+6562, U+6566, U+65CB, U+6602, U+6606, U+6627, U+6643, U+6681, U+6734, U+67AF, U+6851, U+6881, U+68B6, U+68F2, U+690E, U+697C, U+6A3D, U+6B04, U+6B3A, U+6B6A, U+6C70, U+6CE1, U+6D1B, U+6DEB, U+6E26, U+6E9D, U+6EA2, U+6EF4, U+6F2C, U+6F31, U+6FC1, U+6FEF, U+7261-7262, U+731F, U+74E6, U+755C, U+758E, U+75AB, U+75D5, U+75F4, U+7652, U+77B3, U+77DB, U+77E9, U+7815, U+7881, U+7940, U+79E6, U+7ABA, U+7B1B, U+7B26, U+7B87, U+7BB8, U+7D10, U+7D33, U+7D79, U+7DBA, U+7DEF, U+7E2B, U+7E4A, U+7F36, U+7FC1, U+80AA, U+80BA, U+80F4, U+817F, U+8276, U+83C5, U+83E9, U+83F1, U+84B2, U+84EE, U+85CD, U+865C, U+8700, U+8861, U+8881, U+895F, U+8987, U+8A1F, U+8AE6, U+8B21, U+8B5C, U+8C5A, U+8C9D, U+8D99, U+8ECC, U+8EDF, U+8FBB, U+9022, U+904D, U+90ED, U+9175, U+919C, U+920D, U+9326, U+935B, U+9418, U+95A5, U+963B, U+9644, U+9675, U+9699, U+96C0, U+96F0, U+983B, U+98E2, U+98FD, U+9905, U+99B3, U+99C8, U+9AD9, U+9B31, U+9B8E, U+9D8F, U+9EBF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium023.woff2") format("woff2");
  unicode-range: U+2E92, U+2F31, U+2F43, U+2FA9, U+4EAB, U+4EC7, U+4F0E, U+4F3A, U+4F46, U+4FAF, U+4FF5, U+502D, U+50B3, U+5112, U+514E, U+5208, U+52C5, U+52F2, U+53EA, U+547B, U+54B3, U+5538, U+5598, U+559D, U+55C5, U+55E3, U+56DA, U+570B, U+57FC, U+5835, U+5875, U+58B3, U+58CC, U+5968, U+59A5, U+59A8, U+59AC, U+59BE, U+59D1, U+59DC, U+5A92, U+5AC9, U+5B5F, U+5B9C, U+5C3C, U+5CAC, U+5DF3, U+5DFE, U+5E25, U+5E87, U+5F04, U+5FCC, U+6028, U+60F9, U+6115, U+6155, U+61A7, U+6234, U+6276, U+6367, U+642D, U+64AD, U+6590, U+6597, U+66AB, U+66C7, U+66D6, U+66FD, U+6756, U+67A2, U+67CF-67D0, U+67FF, U+683D, U+6876, U+689D, U+695A, U+6BBB, U+6BC5, U+6C72, U+6C7D, U+6CB8, U+6DC0, U+6E07, U+6ECB, U+6EF2, U+6F06, U+6F15, U+708A, U+70CF, U+711A, U+71C8, U+71E5, U+71ED, U+723D, U+72D0, U+74A7, U+75FA, U+7626, U+76BA, U+76C3, U+7891, U+7897, U+78EF, U+79BF, U+7A00, U+7A9F, U+7AAA, U+7AAF, U+7AFF, U+7B39, U+7B51, U+7BE4, U+7C9B, U+7D3A, U+7DB4, U+7DBB, U+7F75, U+8096, U+8098, U+80A2, U+80AF, U+80CE, U+82AF, U+82D1, U+830E, U+83AB, U+8429, U+8463, U+8499, U+8511, U+852D, U+854E, U+85A6, U+85AB, U+8702, U+871C, U+8912, U+8956, U+8A02, U+8A50, U+8A60, U+8A63, U+8A6B, U+8A6E, U+8ACF, U+8AED, U+8AFE, U+8B83, U+8B90, U+8CD1, U+8CE0, U+8D05, U+8D66, U+8D74, U+8DF5, U+8FBF, U+906E, U+9077, U+912D, U+914C, U+916C, U+9192, U+91DC, U+925B, U+92FC, U+9320, U+9591, U+961C, U+9688, U+96B7, U+96BB, U+96F6, U+971E, U+9813, U+990C, U+9A19, U+9A30, U+9AB8, U+9AED, U+9B6F, U+9CE9, U+9D5C, U+9E93, U+20B9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium024.woff2") format("woff2");
  unicode-range: U+2F5B, U+2F60, U+2FCF, U+4E14, U+4E3C, U+4ED4, U+4F3D, U+4F51, U+4FAE, U+4FB6, U+4FE3, U+4FF1, U+50B2, U+50C5, U+5102, U+51B4, U+51FD, U+5243, U+5256, U+52FF, U+533F, U+53A8, U+53AD, U+53D9, U+53DB, U+53F6, U+540F, U+5420, U+543B, U+548E, U+54BD, U+5506, U+5632, U+57A2, U+57C3, U+5815, U+589C, U+5984, U+5993, U+59D0, U+5A3C, U+5A7F, U+5BB5, U+5BC5, U+5CEF, U+5DBA, U+5E16, U+5EE3, U+5F0A, U+5FFD, U+60B6, U+60BC, U+6101, U+6144, U+6168, U+6190-6191, U+61A9, U+62D0, U+62D7, U+632B, U+63C6, U+642C, U+64EC, U+65ED, U+66F3, U+673D, U+674F, U+675C, U+67F5, U+6803, U+685F, U+6897, U+68AF, U+68FA, U+694A, U+6960, U+69CC, U+69FD, U+6A3A, U+6A58, U+6B86, U+6C5D, U+6CCC, U+6D12, U+6D2A, U+6DCB, U+6E13, U+6E15, U+6EBA, U+7027, U+7169, U+717D, U+724C, U+7259, U+727D, U+72FD, U+7336, U+7345, U+73B2, U+73CA, U+7409, U+7435-7436, U+745E, U+7460, U+74DC, U+7525, U+754F, U+7554, U+757F, U+760D, U+764C, U+76F2, U+771E, U+7729, U+7738, U+7766, U+77AD, U+77BC, U+7826, U+786B, U+79B0, U+7A14, U+7A40, U+7A6B, U+7A7F, U+7B25, U+7C1E, U+7C3E, U+7CDE, U+7D2F, U+7D46, U+7F60, U+7FA8, U+8061, U+814E, U+81A3, U+81C6, U+81E5, U+8235, U+8247, U+82A5, U+82D4, U+831C, U+8328, U+832B, U+837B-837C, U+853D, U+8587, U+8594, U+8599, U+85AA, U+85EA, U+85FB, U+868A, U+8766, U+87F9, U+881F, U+88B4, U+88DF, U+88E1, U+88F3, U+8A1D, U+8A3B, U+8A72, U+8ADC, U+8AEB, U+8AEE, U+8CC4, U+8CDC, U+8E2A, U+8E5F, U+8E87, U+8E8A, U+8F3F, U+8F44, U+8F5F, U+8FC5, U+8FE6, U+9010, U+901D, U+9065, U+914E, U+9162, U+91B8, U+91D8, U+92F3, U+932C, U+95B2, U+96DB, U+9798, U+97AD, U+9838, U+9913, U+9AC4, U+9BE8, U+9CF3, U+9DD7, U+9DF2, U+9F20, U+F9EC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium025.woff2") format("woff2");
  unicode-range: U+2F48, U+2F7D, U+2F85, U+4E9B, U+4EA8, U+4F47, U+4FEF, U+5023, U+5118, U+5141, U+51A5, U+51B6, U+51C6, U+51CC, U+51F0, U+5239, U+52FE, U+53C9, U+5484, U+54E2, U+55A9, U+567A, U+5806, U+58D5, U+5957, U+5962, U+597C, U+59E6, U+59FB, U+5A25, U+5A2F, U+5A9A, U+5AE1, U+5BE1, U+5BF5, U+5C4D, U+5C51, U+5C6F, U+5CD9, U+5CE8, U+5D6F, U+5EB8, U+5EC9, U+60E7, U+6284, U+62B9, U+62F7, U+633A, U+6372, U+637B, U+63A0, U+643E, U+647A, U+6492, U+64A5, U+6518, U+6523, U+6591, U+65FA, U+660F, U+6652, U+66F0, U+66FC, U+676D, U+6813, U+6841, U+6893, U+693F, U+698A, U+698E, U+69FB, U+6A0B, U+6A80, U+6A9C, U+6AD3, U+6AFB, U+6B89, U+6DD1, U+6E4A, U+6FE0, U+6FE4, U+704C, U+7092, U+714E, U+71D5, U+723E, U+72D7, U+72F8, U+7325, U+73A9, U+745C, U+7526, U+755D, U+75D9, U+76E7, U+7791, U+7825, U+785D, U+78D0, U+7901, U+7947, U+798D, U+79DF, U+79E4, U+7A92, U+7B95, U+7CA5, U+7CFE, U+7D21, U+7DCB, U+7E1E, U+7E55, U+7E8F, U+7F9E, U+7FE0, U+7FF3, U+800C, U+8036, U+809A, U+80B4, U+8151, U+8154, U+817A, U+81C0, U+81FC, U+8218, U+8236-8237, U+8258, U+82A6, U+82AD, U+8305, U+8389, U+840C, U+840E, U+842C, U+8461, U+8466, U+8475, U+8513, U+8523, U+8549, U+8569, U+85C1, U+8679, U+86CD, U+86D9, U+87BA, U+87EC, U+887F, U+8888, U+895E, U+8B33, U+8B39, U+8CAA, U+8E35, U+8ECB, U+8FC2, U+901E, U+9041, U+9059, U+905C, U+9061, U+918D, U+9190, U+91C7, U+92D2, U+930F, U+93A7, U+9583, U+95A4, U+966A, U+96BC, U+96C1, U+96CC, U+971C, U+9784, U+978D, U+97FB, U+9909, U+9910, U+9945, U+9BC9, U+FA11;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium026.woff2") format("woff2");
  unicode-range: U+2F0F, U+2F14, U+2FA2, U+4E11, U+4E32, U+4E4D, U+4F5B, U+4F86, U+4FC4, U+4FE0, U+4FF8, U+5005, U+5016, U+5036, U+50FB, U+515C, U+51A8, U+51DC, U+51E0, U+51F1, U+51F8-51F9, U+520E, U+52AB, U+5315, U+5347, U+536F, U+5378, U+53E2, U+54A5, U+555C, U+555E, U+55DA, U+55DC, U+55DF, U+55E4, U+5687, U+5703, U+5751, U+5766, U+57D2, U+5830, U+5861, U+589F, U+58F1, U+5B0C, U+5BC7, U+5CFB, U+5D4C, U+5DBD, U+5DCC, U+5DEB, U+5DF4, U+5DF7, U+5E40, U+5ED3, U+5EDF, U+5F14, U+5F1B, U+5F27, U+5F77, U+60DF, U+6167, U+619A, U+622E, U+6309, U+633D, U+634F, U+6377, U+639F, U+63AC, U+63C4, U+63F6, U+646F, U+64B0, U+64E2, U+65AF, U+6666, U+66A2, U+66DD, U+6775, U+67D1, U+6816, U+68A2, U+68F9, U+6900, U+696F, U+6A2B, U+6ABB, U+6ADB, U+6B23, U+6B3D, U+6B4E, U+6BEF, U+6C4E, U+6C50, U+6CAB, U+6E3E, U+6E5B, U+6F38, U+6FEB, U+6FF1, U+7058, U+707C, U+7109, U+7149, U+714C, U+721B, U+725D, U+725F, U+7396, U+745B, U+7483, U+752B, U+7560, U+75BC, U+75E2, U+766A, U+77A0, U+77A5, U+78A7, U+792B, U+7960, U+79B1, U+7A1C, U+7B8B, U+7C95, U+7C9F, U+7CCA, U+7D68, U+7DB8, U+7DFB, U+7E61, U+7E82, U+7F77, U+7F79, U+8017, U+803D, U+805A, U+80DA, U+80E4, U+8106, U+810A, U+8299, U+8338, U+834A, U+8404, U+847A, U+8494, U+86DB, U+8718, U+87FB, U+8882, U+8910, U+8944, U+8A1B, U+8A23, U+8A54, U+8A85, U+8AD2, U+8B01-8B02, U+8B5A, U+8CD3, U+8CE4, U+8CE6, U+8D0B, U+8DE8, U+8E44, U+8F1B, U+8F62, U+8FA3, U+907C-907D, U+9091, U+9127, U+91AC, U+9264, U+9306, U+936C, U+93D1, U+95CA, U+9744, U+978B, U+985A, U+98F4, U+9952, U+9A52, U+9AF7, U+9B41, U+9BAD, U+9BDB, U+9C39, U+9C3B, U+9C57, U+9CF6, U+9D09, U+9D3B, U+9E1E, U+9EB4-9EB5, U+9ECE, U+F993, U+2F8ED;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium027.woff2") format("woff2");
  unicode-range: U+2F18, U+2F8B, U+4E4E, U+4E8E, U+4E98, U+4EA6, U+4EC4, U+4F43, U+5026, U+5039, U+505A, U+50AD, U+50D1, U+5191, U+52D2, U+535C, U+5398, U+53A9, U+5475, U+54A4, U+54AC, U+54E8, U+54ED, U+54FA, U+557C, U+55AC, U+5614, U+5617, U+56CA, U+5821, U+5955, U+5978, U+59EA, U+5A9B, U+5B55, U+5B78, U+5BA5, U+5BD3, U+5C24, U+5C41, U+5C53, U+5C5B, U+5C79, U+5D14, U+5D69, U+5F45, U+5F6C, U+601C, U+604D, U+606B, U+6089, U+60B4, U+60B8, U+60F0, U+61AB, U+61AE, U+61BE, U+61FF, U+620A, U+621F, U+6241, U+626E, U+6289, U+634C, U+65A1, U+65A5, U+65A7, U+6727, U+6853, U+68B5, U+6912, U+6953, U+69B4, U+69D9, U+6A47, U+6A8E, U+6B3E, U+6B7F, U+6BB7, U+6BC0, U+6BD8, U+6C3E, U+6C81, U+6C8C, U+6C93, U+6CC4, U+6CEA, U+6DF9, U+6E1A, U+7015, U+703E, U+7114, U+71BE, U+7280, U+72C4, U+72E1, U+734F, U+745A, U+75B5, U+75B9, U+75D2, U+75E9, U+7624, U+779E, U+77EF, U+78DA, U+798E, U+7A62, U+7A83, U+7AE6, U+7AEA, U+7B19, U+7B75, U+7BDD, U+7C82, U+7CE0, U+7D18, U+7D43, U+7D62, U+7E0B, U+8006, U+805F, U+806F, U+8073, U+808B, U+809B, U+80B1, U+811B, U+814B, U+818F, U+81CD, U+8205, U+821C, U+8233, U+8278, U+8304, U+8309, U+8339, U+8340, U+8431, U+8471, U+84EC, U+8521, U+8543, U+857E, U+8606, U+8654, U+8695, U+86ED, U+8805, U+8823, U+88D4, U+8AA6, U+8AE7, U+8B80, U+8CC2, U+8D14, U+8DA8, U+8DBE, U+8DEA, U+8EB1, U+8EBE, U+8FC4, U+900D, U+9017, U+9075, U+90E4, U+9249, U+932B, U+947F, U+968B, U+96CD, U+9761, U+9870, U+98AF, U+9949, U+99A8, U+9BA8, U+9BAB, U+9D6C, U+9E9F, U+9F4B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium028.woff2") format("woff2");
  unicode-range: U+2F66, U+2FCD, U+4EA5, U+4F98, U+5072, U+5180, U+51DB, U+51EA, U+524C, U+52C1, U+53A0, U+5403, U+5477, U+54B8, U+55C4, U+5631, U+5634, U+56C3, U+5713, U+5718, U+57E0, U+57F4, U+582F, U+58BE, U+58EC, U+5944, U+5A62, U+5B95, U+5C60, U+5CB1, U+5DF2, U+5E37, U+5EFF, U+5F3C, U+5F4C, U+5F57, U+5F59, U+5FA8, U+6063, U+608D, U+60C7, U+60E0, U+61C9, U+6258, U+62F5, U+6369, U+637A, U+638F, U+64F2, U+652A-652B, U+6583, U+6670, U+6688, U+6714, U+6736, U+6753, U+67DA, U+67E9, U+6846, U+6854, U+68CD, U+68D8, U+690B, U+6955, U+6AC3, U+6C40, U+6D8E, U+6DB8, U+6DEE, U+6E20, U+6E25, U+6E58, U+6EF8, U+6FAA, U+6FB1, U+70AC, U+70B8, U+7164, U+7199, U+71D0, U+71E6, U+71FB, U+71FF, U+733E, U+73C2, U+74E2, U+7587, U+758B, U+75E3, U+75F0, U+7647, U+776B, U+777E, U+77E7, U+786F, U+78A9, U+795F, U+7A84, U+7AA9, U+7AC8, U+7B67, U+7B94, U+7C00, U+7C2A, U+7CFA, U+7D72, U+7D9C, U+7E20, U+7F3A, U+8000, U+8058, U+8070, U+8087, U+8129, U+8139, U+81A0, U+81B5, U+81BF, U+81FA, U+8207, U+8292, U+82EB, U+8490, U+84C9, U+84D1, U+8557, U+856A, U+85C9, U+86C6, U+86CB, U+8755, U+87A2, U+880D, U+8822, U+8877, U+88A2, U+88B1, U+890C, U+892A, U+8966, U+8ABC, U+8B10, U+8B17, U+8C79, U+8CB6, U+8CC8, U+8CFD, U+8D73, U+8E72, U+8E99, U+8EEB, U+8F2F, U+8F4D, U+8F9F, U+8FED, U+9021, U+903C, U+9081, U+9087, U+9119, U+929A, U+929C, U+92CF, U+92F8, U+9310, U+9328, U+95BB, U+9716, U+976D, U+986B, U+9957, U+9A55, U+9AEF, U+9B22, U+9B92, U+9C0A, U+9E7C, U+9EBE, U+9F0E, U+9F90, U+FA1F, U+2F877, U+2F8DC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium029.woff2") format("woff2");
  unicode-range: U+2F87, U+4E15, U+4E56, U+4F1C, U+5080, U+5085, U+5121, U+5147, U+51CB, U+51E7, U+51ED, U+524B, U+52BE, U+5301, U+5319, U+5321, U+532A, U+543C, U+5480, U+5556, U+5664, U+5851, U+58DC, U+58FD, U+5925, U+594E, U+59E5, U+5A29, U+5A36, U+5B30, U+5B32, U+5B8D, U+5BE6, U+5C39, U+5D8C, U+5E47, U+5E5F, U+5E96, U+5EE0, U+5F17, U+5F7F, U+5F8A, U+5F98, U+5FB7, U+5FBD, U+6062, U+60A7, U+6147, U+61AC, U+61C3, U+61E3, U+620D-620E, U+622A, U+6292, U+62EE, U+63A9, U+6426, U+6478, U+6703, U+6715, U+6726, U+6883, U+689F, U+6930, U+699B-699C, U+69C7, U+6A05, U+6A84, U+6AAE, U+6B12, U+6BB2, U+6BEB-6BEC, U+6C6A, U+6C83, U+6D1F, U+6D59, U+6DD8, U+6ED4, U+6EFE, U+6F09, U+6F32, U+6F3F, U+7006, U+701F, U+7078, U+7099, U+70D9, U+70F9, U+7184, U+71D7, U+71F5, U+7252, U+73C8, U+7433, U+7455, U+748B, U+7515, U+7576, U+7586, U+75D8, U+7693, U+76E5, U+77B0, U+77DC, U+7887, U+78D4, U+7953, U+79A6, U+79AE, U+79BD, U+7A1F, U+7A46, U+7AD9, U+7AFA, U+7B08, U+7B4F, U+7B72, U+7B92, U+7C50, U+7CAE, U+7D2C, U+7D93, U+7DEC, U+7E23, U+7FF0, U+8180, U+821B, U+826B, U+827E, U+82BB, U+82E7, U+839E, U+83D6, U+840A, U+865E, U+86C9, U+86E4, U+873B, U+87E0, U+8904, U+8977, U+8AB9, U+8AFA, U+8D04, U+8D16, U+8E42, U+8E81, U+8E85, U+8E91, U+9013, U+9089-908A, U+90C3, U+916A, U+91A4, U+91C9, U+91E7, U+9266, U+927E, U+9354, U+937E, U+96EB, U+97A0, U+98C4, U+99C1, U+9B1A, U+9B28, U+9B44, U+9BD6, U+9C2F, U+9C48, U+9C52, U+9DAF, U+9E92, U+9EBA, U+9F3E, U+9F67, U+9F95;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium030.woff2") format("woff2");
  unicode-range: U+2F3D, U+2F44, U+4E19, U+4E58, U+4E99, U+4E9E, U+4EC0, U+4EC6, U+4F36, U+4F69, U+4F83, U+4F91, U+4FCE, U+4FD0, U+4FD8, U+4FDF, U+5043, U+50DE, U+50F9, U+5109, U+514C, U+5152, U+51A4, U+51C9, U+5269, U+527D, U+528D, U+52F3, U+52FA, U+5308, U+5377, U+537D, U+53DF, U+5486, U+54E9, U+54EE, U+5544, U+558A, U+55AE, U+562F, U+565B, U+56A5, U+56B4, U+56BC, U+56EE, U+5700, U+5708, U+5879, U+587C, U+5958, U+5BE8, U+5BEC, U+5C4F, U+5D0B, U+5D17, U+5DAE, U+5E1B, U+5E9A, U+5EEC, U+5F10, U+5F6A, U+5FA0, U+6015, U+6041, U+6043, U+606A, U+6084, U+6194, U+61FA, U+61FC, U+6208, U+620C, U+6248, U+629B, U+62FF, U+6357, U+63BB, U+649E, U+64BC, U+64E1, U+6572, U+6582, U+65A4, U+6635, U+66D9, U+675E, U+6777, U+6787, U+67A1, U+67B7, U+67FE, U+6867, U+68A7, U+68B1, U+68B3, U+6962, U+6977, U+69AE, U+69C3, U+69FF, U+6A59, U+6AC2, U+6CDB, U+6D85, U+6E38, U+6F11, U+6FB9, U+6FFE, U+7119, U+7155, U+722C, U+7232, U+7240, U+731C, U+7370, U+73C0, U+7422, U+7425, U+7432, U+74BD, U+74DA, U+75B1, U+7621, U+7690, U+77EE, U+788D, U+7941, U+79B9, U+79E3, U+7A63, U+7AC4, U+7ADF, U+7AE3, U+7B4D, U+7BAD, U+7C7E, U+7D63, U+7DBD, U+7DDE, U+7E0A, U+7E37, U+7E6D, U+7F8C, U+7FC5, U+80F1, U+8171, U+81C2, U+8201, U+8229, U+822B, U+82B9, U+82FA, U+83A2, U+846D, U+8655, U+86F8, U+86FE, U+87C7, U+8852, U+88C3, U+8A03, U+8A25, U+8ACD, U+8AF7, U+8B04, U+8B16, U+8B6C, U+8D6B, U+8DCB, U+8E93, U+8F61, U+8FF8, U+914B, U+9248, U+929B, U+92E4, U+932E, U+937C, U+9435, U+947D, U+958F, U+95A8, U+97EE, U+9812, U+9824, U+983D, U+9903, U+991E, U+9998, U+99AD-99AE, U+99DD, U+99F1, U+9C13, U+9C3A, U+9DFA, U+9EA9, U+9EF4, U+2F818;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium031.woff2") format("woff2");
  unicode-range: U+2F72, U+2FA3, U+2FC8, U+2FD1, U+57DC, U+5859, U+589E, U+58D8, U+58DE, U+58EF, U+5967, U+596C, U+5A03, U+5B43, U+5B5C, U+5BE2, U+5C07-5C08, U+5C2D, U+5CFD, U+5D1A, U+5DD6, U+5DE2, U+5DFD, U+5E36, U+5EF3, U+5F48, U+5F9E, U+5FB5, U+6046, U+6055, U+608C, U+60E1, U+60FA, U+613C, U+61F7, U+6230, U+6232, U+62C2, U+62D4, U+62DC, U+63ED, U+6416, U+641C, U+64CA, U+651D, U+6536, U+654D, U+660A, U+6634, U+6644, U+664F, U+665A, U+665D, U+665F, U+6668, U+6689, U+66C6, U+66C9, U+67CA, U+67D8, U+681E, U+689B, U+691B, U+6A02, U+6A1F, U+6A23, U+6A6B, U+6AA2, U+6B65, U+6B77, U+6BCF, U+6C23, U+6D35, U+6D38, U+6D6C, U+6D89, U+6DDA, U+6DE8, U+6E34, U+6EAB, U+6EC9, U+6EEF, U+6F23, U+6F81, U+6FD5, U+7028, U+7130, U+71CE, U+71D2, U+722D, U+72C0, U+72F9, U+7378, U+7473, U+7476, U+7562, U+758A, U+758F, U+76DC, U+76E1, U+7827, U+788E, U+7893, U+7955, U+7962, U+7977, U+797F, U+79AA, U+79BE, U+7A3B, U+7A57, U+7A70, U+7A79, U+7CB9, U+7DA0, U+7DD6, U+7DE3, U+7E31, U+7E96, U+7F9A, U+807D, U+81DF, U+838A, U+83EB, U+8420, U+8568, U+8597, U+85B0, U+85CF, U+85E5, U+865B, U+885E, U+88DD, U+89BD, U+8A62, U+8AC4, U+8B20, U+8B93, U+8CE3, U+8CF4, U+8F49, U+8FEA, U+90DE, U+9149, U+9187, U+9189, U+91C0, U+9291, U+9304, U+9318, U+934A, U+93AD, U+9444, U+9677, U+96AA, U+96DC, U+975C, U+980C, U+9817, U+986F, U+98DC, U+9A37, U+9A4D, U+9A57, U+9AEE, U+9DC4, U+9EC3, U+9ED1, U+9ED8, U+9EDB, U+9F4A, U+F91D, U+F928-F929, U+F936, U+F992, U+F9D0, U+FA16, U+FA19-FA1B, U+FA22, U+FA26, U+FA30-FA31, U+FA33;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium032.woff2") format("woff2");
  unicode-range: U+2F89, U+2FA4, U+2FC9, U+4F7C, U+4FA0, U+4FAD, U+5265, U+5283, U+531D, U+5366, U+540B, U+5451, U+548B, U+5516, U+5618, U+5678, U+56A2, U+5764, U+5858, U+586B, U+58F7, U+5919, U+59F6, U+5A41, U+5B2C, U+5C61, U+5CA8, U+5EFC, U+5F4A, U+6108, U+617E, U+63B4, U+63D6, U+649A, U+64B9, U+64FE, U+658C, U+67C1, U+6802, U+6822, U+6834, U+685D, U+68BC, U+68C9, U+6919, U+6934, U+6973, U+6994, U+6A17, U+6A35, U+6A61, U+6A7F, U+6AE8, U+6B1D, U+6D8C, U+6D9B-6D9C, U+6E8C, U+6F45, U+6F97, U+701E, U+7026, U+7194, U+72DB, U+7337, U+73EA, U+7511, U+751C, U+7566, U+7577, U+75D4, U+76C8, U+783A, U+783F, U+7872, U+7895, U+7A17, U+7A4E, U+7A50, U+7AC3, U+7B86, U+7BAA, U+7BED, U+7C38, U+7C81, U+7C8D, U+7CCE, U+7CDF, U+7DAC, U+7E4B, U+7E4D, U+7F6B, U+7FEB, U+807E, U+826E, U+82C5, U+82D3, U+834F, U+83B1, U+83DF, U+83F0, U+844E, U+848B, U+849C, U+8500, U+851A, U+854A, U+85AE-85AF, U+85F7, U+867B, U+86A4, U+86CE, U+8749, U+874B, U+877F, U+88B7, U+8A51, U+8ACC, U+8B2C, U+8CCE, U+8EAF, U+8FE9, U+9197, U+91C6, U+91E6, U+920E, U+9237, U+92EA, U+92F2, U+934D, U+9397, U+939A, U+9419, U+9438, U+9453, U+981A, U+982C, U+9834, U+985B, U+9A28, U+9BAA, U+9BF5, U+9C0D, U+9C10, U+9C2D, U+9D07, U+9D0E, U+9D1B, U+9D2B-9D2C, U+9D60-9D61, U+9E78, U+9EB9, U+9ECD, U+FA34-FA35, U+FA37-FA38, U+FA3A-FA3B, U+FA3D, U+FA3F-FA41, U+FA43-FA48, U+FA4A-FA57, U+FA59-FA5C, U+FA5F, U+FA61-FA65, U+FA67-FA69;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium033.woff2") format("woff2");
  unicode-range: U+2E8E, U+2F02-2F03, U+2F05, U+2F07, U+2F09, U+2F0C-2F0E, U+2F10, U+2F13, U+2F15-2F16, U+2F19-2F1B, U+4E10, U+4E17, U+4E2A, U+4E31, U+4E36, U+4E3F, U+4E42, U+4E55, U+4E62, U+4E82, U+4E85, U+4E8A, U+4E9F-4EA0, U+4EA2, U+4EB0, U+4EB3, U+4EB6, U+4EC2, U+4ECD-4ECE, U+4ED7, U+4EDE-4EDF, U+4EED, U+4EF7, U+4F09, U+4F30, U+4F57, U+4F5A, U+4F5D-4F5E, U+4F6F-4F70, U+4F76, U+4F7B, U+4F88, U+4F8F, U+4F96, U+4FAB, U+4FD1, U+4FD4, U+4FDA-4FDB, U+4FE4-4FE5, U+4FF6, U+4FFE, U+5006, U+500F, U+5011, U+5014, U+501A, U+5021, U+5025, U+5028-502A, U+502C, U+5047-5048, U+5050, U+5055-5056, U+506C, U+5078, U+509A, U+50B4, U+50C2, U+50C9-50CA, U+50D6, U+50E3, U+50E5, U+50ED-50EE, U+50F5, U+5101, U+5114-5116, U+511A, U+5137, U+513A-513C, U+513F-5140, U+5154, U+5162, U+5169-516A, U+516E, U+5182, U+5189, U+518C, U+518F-5190, U+5193, U+5195-5196, U+51A2, U+51A6, U+51A9-51AB, U+51B0-51B3, U+51B5, U+51BD, U+51C5, U+51D6, U+51E9, U+51F5, U+51FE, U+5204, U+520B, U+5214, U+5227, U+522A, U+522E, U+5233, U+5244, U+524F, U+5254, U+525E, U+526A, U+5271, U+5273-5274, U+527F, U+5288, U+5291-5292, U+5294, U+52AC-52AD, U+52B5, U+52BC, U+52CD, U+52D7, U+52DE, U+52E0, U+52E3, U+52E6, U+52F5, U+52F8-52F9, U+5306, U+530D, U+530F-5310, U+531A, U+5323, U+532F, U+5331, U+5333, U+5338, U+5340, U+5345-5346, U+5349, U+534D, U+535E, U+5369, U+536E, U+537B, U+5382, U+5396, U+53A5-53A6, U+53AE, U+53B0, U+53B6, U+53C3, U+53E8, U+53ED-53EE, U+53FA, U+5401, U+541D, U+5429, U+542C-542E, U+5436, U+543D, U+5440, U+544E, U+5470-5471, U+5476;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium034.woff2") format("woff2");
  unicode-range: U+2F1E, U+2F21-2F22, U+548F-5490, U+5492, U+54A2, U+54A8, U+54AB, U+54AF, U+54BC, U+54BE, U+54C2, U+54C4, U+54C7-54C8, U+54D8, U+54E5-54E6, U+54FD, U+550F, U+5514, U+552E, U+5533, U+5539, U+5540, U+5545, U+554C, U+5557, U+555D, U+5563, U+557B, U+557E, U+5580, U+5583, U+5587, U+5599, U+559E-559F, U+55A8, U+55C7, U+55D4, U+55F7, U+55F9, U+55FD-55FE, U+5616, U+561B, U+5636, U+5638, U+564E, U+5650, U+566A-566C, U+5680, U+5686, U+568A, U+568F, U+5694, U+56A0, U+56AE, U+56B6, U+56C0, U+56C2, U+56C8, U+56CE, U+56D1, U+56D3, U+56D7-56D8, U+56F9, U+56FF, U+5704, U+5709, U+570D, U+5716, U+571C, U+5726, U+5737-5738, U+573B, U+5740, U+574E-574F, U+5761, U+5769, U+577F, U+5788-5789, U+5793, U+57A0, U+57A4, U+57AA, U+57B0, U+57B3, U+57C0, U+57C6, U+57D3-57D4, U+57D6, U+57E3, U+580A-580B, U+5819, U+581D, U+583D, U+584B, U+5852, U+5862, U+5870, U+5872, U+5885, U+58AB, U+58AE, U+58B8-58BB, U+58C5, U+58D1, U+58D3, U+58D7, U+58D9, U+58DF, U+58E4-58E5, U+58F9, U+58FB-58FC, U+5902, U+590A, U+5910, U+5918, U+591B, U+592C-592D, U+5932, U+5938, U+593E, U+5950, U+595A, U+5960, U+5969, U+5981, U+598D, U+599B, U+599D, U+59A3, U+59B2, U+59C6, U+59D9-59DA, U+59E8, U+5A09, U+5A11, U+5A1A, U+5A1C, U+5A1F, U+5A35, U+5A40, U+5A49, U+5A6A, U+5A6C, U+5ABC-5ABE, U+5AC2, U+5ACB, U+5AD0, U+5AD6-5AD7, U+5AE3, U+5AE6, U+5AE9, U+5AFA-5AFB, U+5B0B, U+5B16, U+5B2A, U+5B36, U+5B3E, U+5B40, U+5B45, U+5B51, U+5B5A-5B5B, U+5B65, U+5B69, U+5B70-5B71, U+5B73, U+5B75;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium035.woff2") format("woff2");
  unicode-range: U+2E90, U+2E93-2E94, U+2F27, U+2F2A-2F2C, U+2F2E, U+2F33-2F37, U+2F3A-2F3B, U+5B7A, U+5B80, U+5B83, U+5BA6, U+5BB8, U+5BC3, U+5BC9, U+5BD0, U+5BD4, U+5BDE, U+5BE4-5BE5, U+5BEB, U+5BF0, U+5BF3, U+5BF6, U+5C05, U+5C0D, U+5C13, U+5C20, U+5C22, U+5C28, U+5C38, U+5C46, U+5C4E, U+5C50, U+5C6C, U+5C6E, U+5C76, U+5C8C, U+5C91, U+5C94, U+5CAB, U+5CB6-5CB7, U+5CBB-5CBC, U+5CBE, U+5CC5, U+5CC7, U+5CE9-5CEA, U+5CED, U+5CFA, U+5D11, U+5D15, U+5D18-5D19, U+5D1B, U+5D1F, U+5D22, U+5D4B, U+5D4E, U+5D52, U+5D5C, U+5D6C, U+5D73, U+5D76, U+5D82, U+5D84, U+5D87, U+5D90, U+5D9D, U+5DA2, U+5DAC, U+5DB7, U+5DBC, U+5DC9, U+5DCD, U+5DD2-5DD3, U+5DDB, U+5DF5, U+5E0B, U+5E11, U+5E19-5E1A, U+5E43-5E44, U+5E4E, U+5E54, U+5E57, U+5E62, U+5E64, U+5E75-5E76, U+5E7A, U+5E7F, U+5EA0, U+5EC1-5EC2, U+5EC8, U+5ECF-5ED0, U+5ED6, U+5EDA-5EDB, U+5EDD, U+5EE1-5EE2, U+5EE8-5EE9, U+5EF0-5EF1, U+5EF4, U+5EF8, U+5EFE, U+5F03, U+5F09, U+5F0B-5F0D, U+5F11, U+5F16, U+5F29, U+5F2D, U+5F2F, U+5F38, U+5F41, U+5F4E, U+5F51, U+5F56, U+5F5C-5F5D, U+5F61, U+5F6D, U+5F73, U+5F82-5F83, U+5F87-5F88, U+5F91, U+5F99, U+5FAD, U+5FBC, U+5FD6, U+5FDD, U+5FE4, U+5FF0-5FF1, U+5FF8, U+5FFB, U+5FFF, U+600E-6010, U+6019, U+601B, U+6021, U+6026, U+6029, U+602B, U+6031, U+603A, U+6042, U+604A, U+6059-605A, U+605F-6060, U+6064, U+606C, U+6077, U+6081, U+6083, U+608B, U+6092, U+6096-6097, U+609A-609B, U+60B3, U+60B5, U+60BD, U+60C6, U+60D3, U+60D8, U+60F1, U+60F4, U+60F6-60F7, U+60FB, U+6100, U+6103, U+6106, U+610D-610E, U+6121, U+6127-6128, U+612C, U+6134, U+613D-613F, U+6142;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium036.woff2") format("woff2");
  unicode-range: U+2E99, U+2E9B, U+2F41, U+2F46, U+614A, U+614D, U+6153, U+6158-615A, U+615D, U+615F, U+6165, U+616B, U+616F, U+6171, U+6173-6175, U+6177, U+6187, U+618A, U+6196, U+6199, U+61BA, U+61C6, U+61C8, U+61CA-61CD, U+61E6, U+61F4, U+61F6, U+61FD-61FE, U+6200, U+6209, U+6214, U+621B, U+621D-621E, U+6221, U+6233, U+624E, U+625B, U+625E, U+6260, U+6263, U+6268, U+627C, U+627E, U+6282-6283, U+6293-6294, U+6296, U+62AC, U+62BB, U+62C6-62C8, U+62CA, U+62CC, U+62CF, U+62D1, U+62EF, U+62F1, U+6302, U+6308, U+630C, U+6327, U+633E, U+634D, U+6350, U+636B, U+6376, U+6380, U+6389, U+638E, U+6396, U+63A3, U+63AB, U+63B5, U+63BE, U+63C0, U+63D2, U+63E3, U+63E9, U+6406, U+640F, U+6413, U+6417, U+6428, U+6434, U+6436, U+644E, U+6467, U+6476, U+6488, U+6493, U+6495, U+64A9, U+64BB, U+64C2, U+64C5, U+64C7, U+64D2, U+64D4, U+64D8, U+64DA, U+64E0, U+64E3, U+64E7, U+64EF, U+64F1, U+64F4, U+64F6, U+64FA, U+64FD, U+6500, U+6505, U+651C, U+6524, U+652C, U+6534-6535, U+6537-6538, U+6548, U+6555-6556, U+6558, U+655D-655E, U+6578, U+6588, U+659B, U+659F, U+65AB, U+65B7, U+65C1, U+65C3-65C4, U+65C6, U+65CC, U+65D2, U+65D9, U+65DB, U+65E0-65E1, U+65F1, U+65FB, U+6603, U+661C, U+6636, U+663F, U+6641, U+6649, U+665E, U+6662, U+6664, U+6667, U+6683-6684, U+668E, U+6698, U+669D, U+66B8-66B9, U+66BC, U+66BE, U+66C1, U+66C4, U+66DA, U+66E0, U+66E6, U+66E9, U+66F5, U+66F7, U+670F, U+6716, U+671E, U+672E, U+6737-6738, U+673F, U+6741, U+6746, U+6759, U+6760, U+6763-6764, U+676A, U+6770, U+6772-6773, U+677C, U+6785, U+6789, U+678B-678C, U+67A6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium037.woff2") format("woff2");
  unicode-range: U+2F4D-2F4F, U+2F53, U+67A9, U+67B3-67B4, U+67B8-67B9, U+67C6, U+67CE, U+67DD-67DE, U+67E2, U+67E4, U+67E7, U+67EC, U+67EE-67EF, U+6829, U+682B, U+6832, U+6840, U+684D-684E, U+6859, U+6863, U+6874, U+6877, U+687E-687F, U+688D, U+688F, U+6894, U+68A0, U+68A6, U+68AD, U+68B9-68BA, U+68C6, U+68CA, U+68D4-68D5, U+68D7, U+68E0-68E1, U+68E3, U+68E7, U+68EF, U+6901, U+6904, U+6908, U+690C, U+690F, U+691A, U+6921-6923, U+6925-6926, U+6928, U+692A, U+6936, U+6939, U+693D, U+6954, U+6959, U+695C-695E, U+6961, U+696A-696B, U+696E, U+6974, U+6978-6979, U+697E, U+6981, U+6991, U+6995, U+69A0, U+69A7, U+69B1-69B2, U+69BB, U+69BE-69BF, U+69C1, U+69CA, U+69CE, U+69D0, U+69D3, U+69DD-69DE, U+69E7-69E8, U+69EB, U+69ED, U+69F2, U+69F9, U+6A0A, U+6A0C, U+6A12-6A14, U+6A1B, U+6A1E, U+6A22, U+6A2E, U+6A36, U+6A38, U+6A44, U+6A48, U+6A62, U+6A66, U+6A72, U+6A78, U+6A8D, U+6A90, U+6A97, U+6AA0, U+6AA3, U+6AAA, U+6AAC, U+6AB3, U+6AB8, U+6AC1, U+6AD1, U+6ADA, U+6ADE-6ADF, U+6AEA, U+6AFA, U+6B05, U+6B0A, U+6B16, U+6B1F, U+6B37-6B39, U+6B43, U+6B47, U+6B49, U+6B50, U+6B54, U+6B59, U+6B5B, U+6B5F, U+6B61, U+6B78-6B79, U+6B80, U+6B83-6B84, U+6B8D, U+6B95, U+6B98, U+6B9E, U+6BA4, U+6BAA-6BAB, U+6BAF, U+6BB1, U+6BB3, U+6BBC, U+6BC6, U+6BCB, U+6BD3, U+6BDF, U+6BF3, U+6C08, U+6C13-6C14, U+6C1B, U+6C24, U+6C55, U+6C5E, U+6C62, U+6C68, U+6C73, U+6C7E, U+6C82, U+6C8D, U+6C90, U+6C92, U+6C9A-6C9B, U+6CAE, U+6CB1, U+6CBA, U+6CBD-6CBE, U+6CC5, U+6CD3, U+6CD7, U+6CD9;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium038.woff2") format("woff2");
  unicode-range: U+2F58-2F59, U+6CDD, U+6CEF, U+6CF1, U+6D0C, U+6D19, U+6D2B, U+6D33, U+6D36, U+6D3D, U+6D5A, U+6D63-6D64, U+6D79, U+6D93, U+6D95, U+6DB5, U+6DC5-6DC7, U+6DCC, U+6DD2, U+6DD5, U+6DD9, U+6DDE, U+6DE4, U+6DE6, U+6DEA, U+6DEC, U+6DFA, U+6E0A, U+6E19, U+6E1D, U+6E1F, U+6E23-6E24, U+6E2B, U+6E2D-6E2E, U+6E3A, U+6E43, U+6E4D-6E4E, U+6E5F, U+6E6B, U+6E6E, U+6E72, U+6E76, U+6E82, U+6E8F, U+6E98, U+6E9F, U+6EA5, U+6EAA, U+6EAF, U+6EB2, U+6EB7, U+6EBD, U+6EC2, U+6EC4, U+6ECC, U+6ED3, U+6ED5, U+6EEC, U+6EF7, U+6EFF, U+6F13, U+6F3E, U+6F41, U+6F58, U+6F5B, U+6F66, U+6F6D, U+6F6F, U+6F74, U+6F78, U+6F7A, U+6F7C, U+6F80, U+6F82, U+6F86, U+6F8E, U+6F91, U+6FA1, U+6FA3, U+6FB3, U+6FC2, U+6FC6, U+6FD4, U+6FD8, U+6FDB, U+6FDF, U+6FEC, U+6FEE, U+6FF3, U+6FF6, U+6FFA, U+7001, U+7009, U+700B, U+700F, U+7011, U+7018, U+701A-701B, U+701D, U+7030, U+7032, U+7051, U+7063, U+70AE-70AF, U+70B3, U+70CB, U+70DD, U+70DF, U+70F1, U+70FD, U+711C, U+7156, U+7162, U+7165-7166, U+716C, U+7188, U+718F, U+7195, U+71A8, U+71AC, U+71B9, U+71C9, U+71D4, U+71DF-71E0, U+71E7, U+71EC, U+71EE, U+71F9, U+71FC, U+720D, U+7210, U+7228, U+7230, U+723B-723C, U+723F, U+7246, U+724B, U+7258, U+7274, U+727E, U+7281-7282, U+7287, U+7292, U+7296, U+72A2, U+72A7, U+72B2, U+72B9, U+72C3, U+72C6, U+72CE, U+72D2, U+72E0, U+72E2, U+72F7, U+730A, U+7316-7317, U+731D, U+7329, U+732F, U+7334, U+734E, U+7357, U+7368, U+736A, U+7375, U+737A-737B, U+73B3, U+73BB, U+73CE, U+73DE, U+73E5, U+73EE, U+73F1, U+73F8, U+7405;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium039.woff2") format("woff2");
  unicode-range: U+2F68, U+743A, U+743F, U+7441, U+7459, U+745F, U+7463-7464, U+7469-746A, U+746F-7470, U+747E, U+749E, U+74A2, U+74CA, U+74CF, U+74D4, U+74E0, U+74E3, U+74E7, U+74E9, U+74EE, U+74F0-74F2, U+74F7-74F8, U+7503-7505, U+750C-750E, U+7513, U+751E, U+752C, U+7538, U+753C, U+7544, U+7546, U+7549-754B, U+754D, U+755A-755B, U+7564, U+7567, U+7569, U+756B, U+756D, U+7574, U+7578, U+7582, U+7589, U+7594, U+759A, U+759D, U+75A3, U+75A5, U+75B3, U+75B8, U+75BD, U+75C2-75C3, U+75CA, U+75CD, U+75DE, U+75F2-75F3, U+75FC, U+75FE-75FF, U+7601, U+7609, U+760B, U+761F-7620, U+7622, U+7627, U+7630, U+7634, U+763B, U+7646, U+7648, U+7658, U+765C, U+7661-7662, U+7667-7669, U+766C, U+7670, U+7672, U+7676, U+7678, U+767C, U+7680, U+7683, U+7688, U+768B, U+768E, U+7696, U+7699-769A, U+76B0, U+76B4, U+76B7-76B9, U+76C2, U+76CD, U+76D2, U+76D6, U+76DE, U+76EA, U+76FB, U+7704, U+7707-7708, U+771B, U+7724-7726, U+7737, U+7747, U+775A-775B, U+7765, U+7779, U+777F, U+778B, U+778E, U+77B6, U+77B9, U+77BB, U+77BD, U+77BF, U+77C7, U+77CD, U+77D7, U+77DA, U+77E3, U+77FC, U+780C, U+7812, U+7820, U+7845, U+7874, U+787C, U+7886, U+788C, U+789A, U+78A3, U+78AA, U+78AF, U+78B5, U+78BC, U+78BE, U+78C5-78C6, U+78CA-78CB, U+78D1, U+78E7, U+78EC, U+78F4, U+78FD, U+7907, U+7911-7912, U+7919, U+7926, U+792A, U+792C, U+7957, U+795A, U+797A, U+7980, U+798A, U+799D, U+79A7, U+79B3, U+79BA, U+79C9, U+79D5, U+79E1, U+79E7, U+79EC, U+7A08, U+7A0D, U+7A18-7A19, U+7A20, U+7A31, U+7A37, U+7A3E, U+7A43;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium040.woff2") format("woff2");
  unicode-range: U+7A49, U+7A61, U+7A69, U+7A7D, U+7A88, U+7A95-7A98, U+7AB0, U+7AB6, U+7ABF, U+7AC5, U+7AC7, U+7ACA, U+7ACD, U+7ACF, U+7AD2-7AD3, U+7AD5, U+7ADA, U+7ADD, U+7AE1-7AE2, U+7AED, U+7AF0, U+7AF8, U+7B02, U+7B04, U+7B06, U+7B0A-7B0B, U+7B0F, U+7B18, U+7B1E, U+7B28, U+7B33, U+7B35-7B36, U+7B45, U+7B4C, U+7B50, U+7B5D, U+7B65, U+7B6C, U+7B6E, U+7B70-7B71, U+7B74, U+7B7A, U+7B8D, U+7B8F, U+7B98-7B9A, U+7B9C-7B9D, U+7B9F, U+7BB4, U+7BC1, U+7BC6, U+7BCB-7BCC, U+7BCF, U+7BE5-7BE6, U+7BE9, U+7BF3, U+7BF6-7BF7, U+7C07, U+7C0D, U+7C11-7C14, U+7C17, U+7C1F, U+7C23, U+7C27, U+7C2B, U+7C37, U+7C3D, U+7C40, U+7C43, U+7C4C, U+7C4F, U+7C54, U+7C56, U+7C58, U+7C5F, U+7C64-7C65, U+7C6C, U+7C75, U+7C83, U+7C90, U+7CA1-7CA2, U+7CA4, U+7CA8, U+7CAB, U+7CAD, U+7CB1-7CB3, U+7CBD, U+7CC0, U+7CC2, U+7CC5, U+7CD2, U+7CD8, U+7CDC, U+7CE2, U+7CEF, U+7CF2, U+7CF4, U+7CF6, U+7D02, U+7D06, U+7D0A, U+7D15, U+7D1C, U+7D2E, U+7D32, U+7D35, U+7D3F, U+7D45, U+7D4B, U+7D4E-7D4F, U+7D56, U+7D5B, U+7D6E, U+7D73, U+7D7D, U+7D89, U+7D8F, U+7D9B, U+7D9F, U+7DA2-7DA3, U+7DAB, U+7DAE-7DB0, U+7DB5, U+7DC7, U+7DD5, U+7DD8, U+7DDC-7DDD, U+7DE1, U+7DE4, U+7DF2, U+7E05, U+7E09, U+7E12, U+7E1F, U+7E21-7E22, U+7E32, U+7E35, U+7E39-7E3B, U+7E3D, U+7E43, U+7E46, U+7E56, U+7E59-7E5A, U+7E5D-7E5E, U+7E66-7E67, U+7E69-7E6A, U+7E79, U+7E7B-7E7D, U+7E7F, U+7E83, U+7E88-7E89, U+7E8C, U+7E8E, U+7E90, U+7E92-7E94, U+7E9B-7E9C, U+7F38, U+7F45, U+7F4C-7F4E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium041.woff2") format("woff2");
  unicode-range: U+2F79, U+2F7E, U+2F80, U+7F50-7F51, U+7F54-7F55, U+7F58, U+7F5F, U+7F67-7F69, U+7F78, U+7F82-7F83, U+7F86-7F88, U+7F94, U+7F9D, U+7FA3, U+7FAE-7FAF, U+7FB2, U+7FB6, U+7FB8-7FB9, U+7FC6, U+7FCA, U+7FD5, U+7FE1, U+7FE6, U+7FE9, U+7FF9, U+8004, U+800B, U+8012, U+8018-8019, U+801C, U+8021, U+8028, U+803B, U+803F, U+8046, U+804A, U+8052, U+8062, U+8068, U+8072, U+8076, U+8079, U+807F, U+8084-8086, U+8093, U+80AC-80AD, U+80C4, U+80D6, U+80D9, U+80DB, U+80DD, U+80E5, U+80EF, U+80FC, U+8109, U+8123, U+812F, U+813E, U+8146, U+8153, U+815F, U+8165-8166, U+816E, U+8174, U+8182-8183, U+8188, U+818A, U+8193, U+8195, U+81A4, U+81A9, U+81B0, U+81B8, U+81BA, U+81BD-81BE, U+81C8-81C9, U+81D1, U+81D8-81DA, U+81E0, U+81E7, U+81FB, U+81FE, U+8202, U+8209-820A, U+820D, U+8212, U+8216, U+822E, U+8238, U+8240, U+8259-825A, U+825D, U+825F, U+8262, U+8264, U+8268, U+826A, U+8271, U+8277, U+828D, U+829F, U+82AB-82AC, U+82D2, U+82D9, U+82DC, U+82DE-82DF, U+82E1, U+82E3, U+82F3-82F4, U+82F9, U+82FB, U+8303, U+8306, U+8316-8318, U+8323, U+832F, U+8331-8332, U+8334-8335, U+8345, U+8350, U+8373, U+8375, U+8385, U+8387, U+838E, U+8393, U+8396, U+839A, U+839F-83A0, U+83A8, U+83AA, U+83B5, U+83BD, U+83C1, U+83CE, U+83D8, U+83E0, U+83F2, U+83F4, U+83F7, U+83FB, U+83FD, U+8403, U+8407, U+840B, U+840D, U+8413, U+8422, U+842A, U+8435, U+8438, U+843C, U+8446, U+8462, U+8469, U+846B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium042.woff2") format("woff2");
  unicode-range: U+2F8C, U+2F91, U+846E-846F, U+8477, U+8479, U+8482, U+8484, U+849F, U+84A1, U+84AD, U+84B9, U+84BB, U+84BF, U+84C1, U+84C6, U+84CA, U+84CD, U+84D0, U+84D6, U+84D9-84DA, U+84F4, U+84FC, U+84FF, U+8506, U+8514-8515, U+8517-8518, U+851F, U+852C, U+8540-8541, U+8548, U+854B, U+8555, U+8558, U+855A, U+8563, U+856D, U+8577, U+8580, U+8588, U+858A, U+8590-8591, U+859B-859C, U+85A4, U+85A8, U+85B9-85BA, U+85D0, U+85D5, U+85DC, U+85F9-85FA, U+85FE, U+8602, U+860A-860B, U+8613, U+8616-8617, U+861A, U+8622, U+862F-8630, U+863F, U+864D, U+865F, U+8667, U+8671, U+868B-868C, U+8693, U+86A3, U+86A9-86AB, U+86AF-86B0, U+86B6, U+86C4, U+86D4, U+86DE-86DF, U+86E9, U+86EC, U+86EF, U+86F9, U+86FB, U+8703, U+8706, U+8708-870A, U+870D, U+8711-8712, U+871A, U+8725, U+8729, U+8734, U+8737, U+873F, U+874C, U+874E, U+8753, U+8757, U+8759, U+875F-8760, U+8763, U+8768, U+876A, U+876E, U+8774, U+8778, U+8782, U+879F, U+87AB, U+87AF, U+87B3, U+87BB, U+87BD, U+87C0, U+87C4, U+87C6, U+87CB, U+87D0, U+87D2, U+87EF, U+87F2, U+87F6-87F7, U+87FE, U+880E-880F, U+8811, U+8815-8816, U+8821, U+8827, U+8831, U+8836, U+8839, U+883B, U+8842, U+8844, U+884D, U+8859, U+8862, U+886B, U+8872, U+8875, U+887D-887E, U+888D, U+8892, U+8897, U+8899, U+889E, U+88A4, U+88AE, U+88B0, U+88B5, U+88BF, U+88C4, U+88D8-88D9, U+88E8, U+88F2, U+88F4, U+88F9, U+88FC, U+8902, U+890A, U+8913, U+891D-891E, U+8925, U+892B, U+8936, U+8938, U+893B, U+8941, U+8943, U+894C-894D, U+8960, U+8964, U+896A, U+896D, U+896F, U+8974, U+897E, U+8983, U+8988, U+898A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium043.woff2") format("woff2");
  unicode-range: U+2F97-2F98, U+8993, U+8998, U+89A1, U+89A6, U+89A9, U+89AC, U+89AF, U+89B2, U+89BA, U+89BF-89C0, U+89DA, U+89DC-89DD, U+89E7, U+89F4, U+89F8, U+8A0C, U+8A10, U+8A16, U+8A36, U+8A41, U+8A46, U+8A48, U+8A52, U+8A5B, U+8A6C-8A6D, U+8A7C, U+8A82, U+8A84, U+8A91, U+8A9A, U+8AA1, U+8AA3, U+8AA5, U+8AA8, U+8AC2, U+8ADA-8ADB, U+8ADE, U+8AE0-8AE2, U+8AE4, U+8AF1, U+8AF3, U+8B07, U+8B0C, U+8B14, U+8B1A, U+8B26, U+8B28, U+8B2B, U+8B3E, U+8B41, U+8B49, U+8B4C, U+8B4E-8B4F, U+8B56, U+8B5B, U+8B5F, U+8B6B, U+8B6F, U+8B71, U+8B74, U+8B7D, U+8B8A, U+8B8C, U+8B8E, U+8B92, U+8B96, U+8B99-8B9A, U+8C3A, U+8C3F, U+8C41, U+8C48, U+8C4C, U+8C4E, U+8C50, U+8C55, U+8C62, U+8C6B-8C6C, U+8C78, U+8C7A, U+8C7C, U+8C82, U+8C85, U+8C89-8C8A, U+8C8D-8C8E, U+8C94, U+8C98, U+8CAD-8CAE, U+8CB2-8CB3, U+8CBD, U+8CC1, U+8CCD, U+8CDA, U+8CFA-8CFB, U+8D07, U+8D0A, U+8D0D, U+8D0F-8D10, U+8D13, U+8D67, U+8D6D, U+8D71, U+8D81, U+8DBA, U+8DC2, U+8DCC, U+8DCF, U+8DD6, U+8DDA-8DDB, U+8DDF, U+8DE3, U+8DEB, U+8DFC, U+8DFF, U+8E08-8E09, U+8E10, U+8E1D-8E1F, U+8E30, U+8E34, U+8E47-8E4A, U+8E4C, U+8E50, U+8E55, U+8E59, U+8E60, U+8E63-8E64, U+8E76, U+8E7C, U+8E84, U+8E8B, U+8E94, U+8EA1, U+8EAA, U+8EAC, U+8EC5-8EC6, U+8EC8, U+8EDB, U+8EE3, U+8EFB-8EFC, U+8EFE, U+8F05, U+8F0A, U+8F0C, U+8F12-8F13, U+8F15, U+8F19, U+8F1C, U+8F1F, U+8F26, U+8F33, U+8F39, U+8F3B, U+8F3E, U+8F42, U+8F45-8F46, U+8F4C, U+8F4E, U+8F57, U+8F5C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium044.woff2") format("woff2");
  unicode-range: U+8F63-8F64, U+8F9C, U+8FA7-8FA8, U+8FAD-8FAF, U+8FB7, U+8FDA, U+8FE2, U+8FE5, U+8FEF, U+8FF4, U+8FF9-8FFA, U+9005, U+900B, U+900E, U+9011, U+9015-9016, U+9027, U+9035-9036, U+9039, U+903E, U+9049, U+904F-9052, U+9056, U+9058, U+905E, U+9068, U+906F, U+9072, U+9076, U+9080, U+9082-9083, U+908F, U+90A8, U+90AF, U+90B1, U+90B5, U+90DB, U+90E2, U+9102, U+9112, U+9130, U+9132, U+914A, U+9156, U+9158, U+9163, U+9165, U+9169, U+9172-9173, U+9182, U+918B, U+91A2, U+91AA-91AB, U+91AF, U+91B4-91B5, U+91BA, U+91C1, U+91CB, U+91D0, U+91D6, U+91DB, U+91DF, U+91E1, U+91F5-91F6, U+91FC, U+91FF, U+9211, U+9214-9215, U+921E, U+9229, U+922C, U+923F, U+9245, U+924B, U+9250, U+9257, U+925A, U+925E, U+9293, U+9295-9296, U+92B7, U+92B9, U+92E9, U+92FA, U+9319-931A, U+9322-9323, U+9335, U+933A-933B, U+9344, U+9356, U+935C, U+9360, U+936E, U+9394, U+93AC, U+93B0, U+93B9, U+93C3, U+93C8, U+93D0, U+93D6-93D8, U+93DD, U+93E4-93E5, U+93E8, U+9403, U+9407, U+9410, U+9413-9414, U+941A, U+9421, U+942B, U+9436, U+943A, U+9441, U+9452, U+945A-945B, U+945E, U+9460, U+9462, U+946A, U+9470, U+9475, U+9477, U+947C, U+947E, U+9481, U+9582, U+9587, U+958A, U+9594, U+9596, U+9598-9599, U+95A0, U+95A7, U+95AD, U+95B9, U+95BC, U+95BE, U+95C3, U+95CC-95CD, U+95D4-95D6, U+95DC, U+95E1-95E2, U+95E5, U+9621, U+9628, U+962E-962F, U+9642, U+964B-964C, U+964F, U+965C-965D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium045.woff2") format("woff2");
  unicode-range: U+2FAA-2FAB, U+2FB1-2FB2, U+2FBD-2FC0, U+965E-965F, U+9666, U+966C, U+9672, U+968D, U+9695, U+9697-9698, U+96A7-96A8, U+96B0-96B2, U+96B4, U+96B6, U+96B8-96B9, U+96C9, U+96CB, U+96CE, U+96D5-96D6, U+96D9, U+96F9, U+9704, U+9706, U+9708, U+970D-970F, U+9711, U+9713, U+9719, U+9724, U+972A, U+9730, U+9738-9739, U+973D-973E, U+9742, U+9746, U+9748-9749, U+9760, U+9764, U+9766, U+9768, U+976B, U+9771, U+9779-977A, U+977C, U+9781, U+9785-9786, U+978F-9790, U+979C, U+97A3, U+97A6, U+97A8, U+97AB, U+97B3-97B4, U+97C3, U+97C6, U+97C8, U+97CB, U+97DC, U+97ED, U+97F2, U+97F5-97F6, U+980F, U+9821, U+9846, U+984B, U+984F, U+9871, U+9873-9874, U+98AA, U+98B1, U+98B6, U+98C3, U+98C6, U+98E9, U+98EB, U+98ED-98EE, U+9912, U+9914, U+9918, U+991D, U+9920-9921, U+9924, U+992C, U+992E, U+993D-993E, U+9942, U+994B-994C, U+9950-9951, U+9955, U+9997, U+99A5, U+99BC, U+99D1, U+99D8, U+99DB, U+99DF, U+99E2, U+99ED-99EE, U+99F2, U+99F8, U+99FB, U+9A01, U+9A05, U+9A0F, U+9A2B, U+9A3E, U+9A40, U+9A42-9A43, U+9A45, U+9A5B, U+9A5F, U+9A62, U+9A64-9A65, U+9A69-9A6B, U+9AAD, U+9AB0, U+9ABC, U+9AC0, U+9ACF, U+9AD1, U+9AD3-9AD4, U+9ADE-9ADF, U+9AE2-9AE3, U+9AE6, U+9AEB, U+9AF1, U+9AF4, U+9AFB, U+9B06, U+9B18, U+9B1F, U+9B23, U+9B25, U+9B27, U+9B29-9B2A, U+9B2E-9B2F, U+9B32, U+9B3B, U+9B43, U+9B4D-9B4E, U+9B51, U+9B58, U+9B74, U+9B83, U+9B91, U+9B93, U+9B96-9B97, U+9B9F-9BA0, U+9BB4, U+9BB9, U+9BC0, U+9BC6, U+9BCA, U+9BCF, U+9BD1-9BD2, U+9BD4, U+9BE1-9BE3;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium046.woff2") format("woff2");
  unicode-range: U+2F01, U+2FC4, U+2FC6, U+2FCB-2FCC, U+2FD2, U+2FD4-2FD5, U+3402, U+34B5, U+34DB, U+378D, U+37E2, U+3B22, U+3BB6, U+3BC3, U+3C0F, U+3E3F, U+3F72, U+4264, U+4453, U+445B, U+459D, U+45EA, U+4844, U+49B0, U+4C17, U+4E28, U+4E2F-4E30, U+4E8D, U+4EE1, U+4EFD, U+4EFF, U+4F03, U+4F0B, U+4F48-4F49, U+4F56, U+4F5F-4F60, U+4F6A, U+4F6C, U+4F7E, U+4F8A, U+4F94, U+4F97, U+4FC9, U+5001-5002, U+500E, U+5018, U+5027, U+502E, U+503B, U+5040-5041, U+5094, U+50CC, U+50D0, U+50E6, U+50F2, U+5103, U+5106, U+510B, U+511E, U+9BE4, U+9BF0-9BF2, U+9C04, U+9C06, U+9C08-9C09, U+9C0C, U+9C12, U+9C14-9C15, U+9C1B, U+9C21, U+9C24-9C25, U+9C2E, U+9C30, U+9C32, U+9C3E, U+9C46-9C47, U+9C5A, U+9C60, U+9C67, U+9C76, U+9C78, U+9CE7, U+9CEB-9CEC, U+9CF0, U+9D03, U+9D06, U+9D08, U+9D12, U+9D15, U+9D1F, U+9D23, U+9D26, U+9D2A, U+9D3E-9D3F, U+9D41, U+9D44, U+9D46, U+9D48, U+9D50-9D51, U+9D59, U+9D5D-9D5E, U+9D64, U+9D6F, U+9D72, U+9D7A, U+9D87, U+9D89, U+9D9A, U+9DA4, U+9DA9, U+9DAB, U+9DB2, U+9DB8, U+9DBA-9DBB, U+9DC1-9DC2, U+9DC6, U+9DCF, U+9DD3, U+9DD9, U+9DE6, U+9DED, U+9DEF, U+9DF8, U+9DFD, U+9E1A-9E1B, U+9E75, U+9E79, U+9E7D, U+9E81, U+9E88, U+9E8B-9E8C, U+9E91, U+9E95, U+9E9D, U+9EA5, U+9EAA, U+9EAD, U+9EB8, U+9EBC, U+9ECC, U+9ECF-9ED0, U+9ED4, U+9EDC-9EDE, U+9EE0, U+9EE5, U+9EE8, U+9EEF, U+9EF6-9EF7, U+9EF9, U+9EFB-9EFD, U+9F07-9F08, U+9F15, U+9F21, U+9F2C, U+9F4E-9F4F, U+9F52, U+9F54, U+9F5F-9F61, U+9F63, U+9F66, U+9F6A, U+9F6C, U+9F72, U+9F76-9F77, U+9F9C-9F9D, U+9FA0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium047.woff2") format("woff2");
  unicode-range: U+2E8F, U+5135, U+514A, U+5155, U+5157, U+519D, U+51C3, U+51CA, U+51DE, U+51E2, U+51EE, U+5201, U+5213, U+5215, U+5249, U+5257, U+5261, U+5293, U+52C8, U+52CC, U+52D0, U+52D6, U+52DB, U+52F0, U+52FB, U+5300, U+5307, U+531C, U+5361, U+5363, U+5393, U+539D, U+53B2, U+5412, U+5427, U+544D, U+546B, U+5474, U+547F, U+5488, U+5496, U+549C, U+54A1, U+54A9, U+54C6, U+54FF, U+550E, U+552B, U+5535, U+5550, U+5581, U+5586, U+558E, U+55AD, U+55CE, U+5608, U+560E, U+563B, U+5649, U+5666, U+566F, U+5671-5672, U+5676, U+569E, U+56A9, U+56AC, U+56B3, U+56C9, U+570A, U+5721, U+572F, U+5733-5734, U+5770, U+5777, U+577C, U+579C, U+57B8, U+57C7-57C8, U+57CF, U+57E4, U+57ED, U+57F5-57F6, U+57FF, U+5809, U+5864, U+5889, U+58A9, U+58CE, U+58D2, U+58D4, U+58DA, U+58E0, U+58E9, U+590C, U+595D, U+596D, U+598B, U+5992, U+59A4, U+59C3, U+59D2, U+59DD, U+59F8, U+5A13, U+5A23, U+5A67, U+5A6D, U+5A77, U+5A7E, U+5A84, U+5A9E, U+5AA7, U+5AC4, U+5B19, U+5B25, U+5B41, U+5B56, U+5B7D, U+5B93, U+5BD8, U+5C12, U+5C1E, U+5C23, U+5C2B, U+5C62, U+5C7A, U+5C8F, U+5C9F, U+5CA3, U+5CAA, U+5CBA, U+5CCB, U+5CD0, U+5CD2, U+5CF4, U+5D0D, U+5D27, U+5D46-5D47, U+5D4A, U+5D53, U+5D6D, U+5D81, U+5DA0, U+5DA4, U+5DA7, U+5DB8, U+5DCB, U+5E14, U+5E18, U+5E58, U+5E5E, U+5E77, U+5EBE, U+5ECB, U+5EF9, U+5F00, U+5F02, U+5F07, U+5F1D, U+5F23, U+5F34, U+5F36, U+5F3D, U+5F40, U+5F54, U+5F58, U+5F64, U+5F67, U+5F7D, U+5F89, U+5F9C, U+5FA7, U+5FAF, U+5FC9, U+5FDE, U+5FE1, U+5FE9, U+600D, U+6014, U+6018;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium048.woff2") format("woff2");
  unicode-range: U+6033, U+6035, U+6047, U+609D-609E, U+60CB, U+60D4-60D5, U+60DD, U+60F8, U+611C, U+612B, U+6130, U+6137, U+618D, U+61B9, U+61BC, U+6222, U+623E, U+6243, U+6256, U+625A, U+626F, U+6285, U+62C4, U+62D6, U+62FC, U+630A, U+6318, U+6339, U+6343, U+6365, U+637C, U+63E5, U+63F5, U+6410, U+6422, U+6460, U+646D, U+6479, U+64BE-64BF, U+64C4, U+64CE, U+64D0, U+64F7, U+64FB, U+6522, U+6529, U+6567, U+659D, U+6600, U+6609, U+6615, U+661E, U+6622, U+6624, U+662B, U+6630-6631, U+6633, U+663A, U+6648, U+664C, U+6659, U+6661, U+6665, U+6673, U+6677-6678, U+668D, U+66A0, U+66B2, U+66BB, U+66C8, U+66DB, U+66E8, U+66FA-66FB, U+6713, U+6733, U+6747-6748, U+6766, U+677B, U+6781, U+6793, U+6798, U+679B, U+67BB, U+67C0, U+67D7, U+67F9, U+67FC, U+6801, U+681D, U+682C, U+6831, U+6852, U+685B, U+6872, U+6875, U+68A3, U+68A5, U+68B2, U+68C8, U+68D0, U+68E8, U+68ED, U+68F0-68F1, U+68FC, U+690A, U+6935, U+6942, U+6949, U+6957, U+6963-6964, U+6968, U+6980, U+69A5, U+69AD, U+69CF, U+69E2, U+69E9-69EA, U+69F5-69F6, U+6A0F, U+6A15, U+6A3B, U+6A3E, U+6A45, U+6A50, U+6A56, U+6A5B, U+6A73, U+6A89, U+6A94, U+6A9D-6A9E, U+6AA5, U+6AE4, U+6AE7, U+6B1B, U+6B1E, U+6B2C, U+6B35, U+6B46, U+6B56, U+6B60, U+6B67, U+6B82, U+6BA9, U+6BAD, U+6BD6-6BD7, U+6BFF, U+6C05, U+6C10, U+6C33, U+6C59, U+6C5C, U+6C74, U+6C76, U+6C85-6C86, U+6C98, U+6C9C, U+6CAA, U+6CC6, U+6CD4, U+6CE0, U+6CEB, U+6CEE, U+6CFB, U+6D04, U+6D0E, U+6D2E, U+6D31, U+6D39, U+6D3F, U+6D58, U+6D65, U+6D82, U+6D87, U+6D94, U+6DAA;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium049.woff2") format("woff2");
  unicode-range: U+2F67, U+6DAC, U+6DBF, U+6DC4, U+6DD6, U+6DDB, U+6DDD, U+6DFC, U+6E44, U+6E5C, U+6E5E, U+6EB1, U+6EC1, U+6EC7, U+6ECE, U+6F10, U+6F1A, U+6F2A, U+6F2F, U+6F33, U+6F51, U+6F59, U+6F5E, U+6F61-6F62, U+6F7E, U+6F88, U+6F8C-6F8D, U+6F94, U+6FA0, U+6FA7, U+6FB6, U+6FBC, U+6FC7, U+6FCA, U+6FF0, U+6FF5, U+6FF9, U+7005, U+704A, U+704E, U+705D-705E, U+7064, U+7075, U+7085, U+70A4, U+70AB, U+70B7, U+70D4, U+70D8, U+70E4, U+710F, U+711E, U+7120, U+712B, U+712E, U+7146-7147, U+7151-7152, U+715C, U+7160, U+7168, U+7185, U+7187, U+7192, U+71BA, U+71C1, U+71C4, U+71FE, U+7200, U+7215, U+7255-7256, U+728D, U+729B, U+72BE, U+72FB, U+7327-7328, U+7350, U+7366, U+737C, U+7395, U+739F-73A0, U+73A2, U+73A6, U+73AB, U+73C9, U+73CF, U+73D6, U+73D9, U+73E3, U+73E9, U+7407, U+740A, U+741A-741B, U+7426, U+7428, U+742A-742C, U+742E-7430, U+7444, U+7446-7447, U+744B, U+7457, U+7462, U+746B, U+746D, U+7486-7487, U+7489, U+7490, U+7498, U+749C, U+749F, U+74A3, U+74A6, U+74A8-74A9, U+74B5, U+74BF, U+74C8-74C9, U+74FF, U+7501, U+7517, U+752F, U+756F, U+7579, U+7592, U+75CE, U+75E4, U+7600, U+7602, U+7608, U+7615-7616, U+7619, U+761E, U+762D, U+7635, U+7643, U+764B, U+7664-7665, U+766D, U+766F, U+7671, U+7681, U+769B, U+769D-769E, U+76A6, U+76AA, U+76B6, U+76C5, U+76CC, U+76CE, U+76D4, U+76E6, U+76F1, U+76FC, U+770A, U+7719, U+7734, U+7736, U+7746, U+774D-774E, U+775C, U+775F, U+7762, U+777A, U+7780, U+7794, U+77AA, U+77E0, U+782D, U+7843, U+784E-784F, U+7851, U+7868;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium050.woff2") format("woff2");
  unicode-range: U+2F71, U+786E, U+78AD, U+78B0, U+78E4, U+78F2, U+78F7, U+7900, U+791C, U+792E, U+7931, U+7934, U+7945-7946, U+795C, U+7979, U+7998, U+79B8, U+79C8, U+79CA, U+79D4, U+79DE, U+79EB, U+79ED, U+7A03, U+7A39, U+7A5D, U+7A6D, U+7A85, U+7AA0, U+7AB3, U+7ABB, U+7ACE, U+7AEB, U+7AFD, U+7B12, U+7B2D, U+7B3B, U+7B47, U+7B4E, U+7B60, U+7B6D, U+7B6F, U+7B9E, U+7BD7, U+7BD9, U+7C01, U+7C20, U+7C31, U+7C33, U+7C36, U+7C59, U+7C6D, U+7C79, U+7C8F, U+7C94, U+7CA0, U+7CBC, U+7CD5, U+7CD9, U+7CDD, U+7D07-7D08, U+7D13, U+7D1D, U+7D23, U+7D31, U+7D41, U+7D48, U+7D53, U+7D5C, U+7D7A, U+7D83, U+7D8B, U+7DA6, U+7DC2, U+7DCC, U+7E08, U+7E11, U+7E15, U+7E28, U+7E47, U+7E52, U+7E8A, U+7E8D, U+7F47, U+7F91, U+7F97, U+7FBF, U+7FCE, U+7FDB, U+7FDF, U+7FEC, U+7FEE, U+7FFA, U+8014, U+8026, U+8035, U+8037, U+803C, U+80CA, U+80D7, U+80E0, U+80F3, U+8118, U+814A, U+8160, U+8167-8168, U+816D, U+81BB, U+81CA, U+81CF, U+81D7, U+8260, U+8274, U+828E, U+82A1, U+82A3-82A4, U+82A9, U+82AE, U+82B7, U+82BE-82BF, U+82C6, U+82D5, U+82FD-82FE, U+8300-8301, U+8322, U+832D, U+833A, U+8343, U+8347, U+8351, U+8355, U+8362, U+837D, U+8386, U+8392, U+8398, U+83A7, U+83A9, U+83BF-83C0, U+83C7, U+83CF, U+83D1, U+83E1, U+83EA, U+8401, U+8406, U+8448, U+845F, U+8470, U+8473, U+8485, U+849E, U+84AF, U+84B4, U+84BA, U+84C0, U+84C2, U+851E, U+852F, U+8532, U+8559, U+8564, U+857A, U+858C, U+858F, U+85A2, U+85AD, U+85CB, U+85CE, U+85ED, U+85FF, U+8604-8605, U+8610, U+8612, U+8618, U+8629, U+8638, U+8641, U+8657, U+8662;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium051.woff2") format("woff2");
  unicode-range: U+2FA1, U+866C, U+8675, U+8698, U+86B8, U+86FA, U+86FC-86FD, U+870B, U+8771, U+8787-8788, U+87AC-87AD, U+87B5, U+87D6, U+8806, U+880A, U+8810, U+8814, U+8898, U+88AA, U+88CA, U+88CE, U+88F5, U+8918-891A, U+891C, U+8927, U+8930, U+8932, U+8939, U+8940, U+8994, U+89D4, U+89E5, U+89F6, U+8A12, U+8A15, U+8A22, U+8A37, U+8A47, U+8A4E, U+8A5D, U+8A61, U+8A75, U+8A79, U+8AA7, U+8AD0, U+8ADF, U+8AF4, U+8AF6, U+8B46, U+8B54, U+8B59, U+8B69, U+8B9D, U+8C49, U+8C68, U+8CE1, U+8CF8, U+8CFE, U+8D12, U+8D1B, U+8DAF, U+8DCE, U+8DD1, U+8DD7, U+8E20, U+8E23, U+8E3D, U+8E70, U+8E7B, U+8EFA, U+8F1E, U+8F2D, U+8F36, U+8F54, U+8FA6, U+8FB5, U+8FE4, U+8FE8, U+8FEE, U+9008, U+902D, U+9088, U+9095, U+9097, U+9099, U+909B, U+90A2, U+90B3, U+90BE, U+90C4-90C5, U+90C7, U+90D7, U+90DD, U+90EF, U+90F4, U+9114-9116, U+9122-9123, U+912F, U+9131, U+9134, U+913D, U+9148, U+915B, U+9183, U+919E, U+91B1, U+91BC, U+91D7, U+91E4-91E5, U+91ED, U+91F1, U+91FB, U+9207, U+9210, U+9238-923A, U+923C, U+9240, U+9243, U+924F, U+9278, U+9288, U+92C2, U+92CB-92CC, U+92D3, U+92E0, U+92FF, U+931F, U+9321, U+9325, U+9348-9349, U+9364-9365, U+936A, U+9370, U+939B, U+93A3, U+93BA, U+93C6, U+93DE-93DF, U+93FD, U+9404, U+9433, U+944A, U+9463, U+946B, U+9471-9472, U+958E, U+959F, U+95A6, U+95A9, U+95AC, U+95B6, U+95BD, U+95CB, U+95D0, U+95D3, U+95DA, U+95DE, U+9658, U+9684, U+969D, U+96A4-96A5, U+96D2, U+96DE, U+96E9, U+96EF, U+9733, U+973B, U+974D-974F, U+975A, U+976E, U+9773, U+9795;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium052.woff2") format("woff2");
  unicode-range: U+2E97, U+3406, U+342C, U+342E, U+3468, U+346A, U+3492, U+34BC, U+34C1, U+34C7, U+351F, U+355D-355E, U+3563, U+356E, U+35A6, U+35A8, U+35C5, U+35DA, U+35F4, U+3605, U+364A, U+3691, U+3696, U+3699, U+36CF, U+3761-3762, U+376B-376C, U+3775, U+37C1, U+37E8, U+37F4, U+37FD, U+3800, U+382F, U+3836, U+3840, U+385C, U+3861, U+38FA, U+3917, U+391A, U+396F, U+3A6E, U+3A73, U+3AD6-3AD7, U+3AEA, U+3B0E, U+3B1A, U+3B1C, U+3B6D, U+3B77, U+3B87-3B88, U+3B8D, U+3BA4, U+3BCD, U+3BF0, U+97AE, U+97BA, U+97C1, U+97C9, U+97DB, U+97DE, U+97F4, U+980A, U+981E, U+982B, U+9852-9853, U+9856-9857, U+9859, U+9865, U+986C, U+98BA, U+98C8, U+98E7, U+9958, U+999E, U+9A02-9A03, U+9A24, U+9A2D-9A2E, U+9A38, U+9A4A, U+9A4E, U+9AB6, U+9AC1, U+9AC3, U+9ACE, U+9AD6, U+9AF9, U+9B02, U+9B08, U+9B20, U+9B2D, U+9B5E, U+9B66, U+9B72, U+9B75, U+9B79, U+9B84, U+9B8A, U+9B8F, U+9B9E, U+9BA7, U+9BC1, U+9BCE, U+9BE5, U+9BF8, U+9BFD, U+9C00, U+9C23, U+9C41, U+9C4F-9C50, U+9C53, U+9C63, U+9C65, U+9C77, U+9D1D-9D1E, U+9D43, U+9D47, U+9D52, U+9D63, U+9D70, U+9D7C, U+9D8A, U+9D96, U+9DAC, U+9DBC, U+9DC0, U+9DE7, U+9E07, U+9E15, U+9E9E, U+9EA4, U+9EAC, U+9EAF, U+9F10, U+9F39, U+9F57, U+9F94, U+9F97, U+9FA2, U+F970, U+F9DC, U+FA0F-FA10, U+FA14-FA15, U+FA32, U+FA36, U+FA39, U+FA3C, U+FA3E, U+FA42, U+FA60, U+FA6A, U+2000B, U+2123D, U+2131B, U+2146E, U+216B4, U+218BD, U+21E34, U+231C4, U+235C4, U+2373F, U+23763, U+23CFE, U+247F1, U+2548E, U+2550E, U+25771, U+259C4, U+25DA1, U+26AFF, U+26E40, U+270F4, U+27684, U+28277, U+283CD, U+2A190;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium053.woff2") format("woff2");
  unicode-range: U+2E83, U+2E85, U+2EA6, U+3C26, U+3CC3, U+3CD2, U+3D11, U+3D1E, U+3D64, U+3D9A, U+3DC0, U+3DD4, U+3E05, U+3E60, U+3E66, U+3E68, U+3E83, U+3E94, U+3F57, U+3F75, U+3F77, U+3FAE, U+3FC9, U+3FD7, U+4039, U+4058, U+4093, U+4105, U+4148, U+414F, U+4163, U+41B4, U+41BF, U+41E6, U+41EE, U+41F3, U+4207, U+420E, U+42C6, U+42D6, U+42DD, U+4302, U+432B, U+4343, U+43EE, U+43F0, U+4408, U+4417, U+441C, U+4422, U+4476, U+447A, U+4491, U+44B3, U+44BE, U+44D4, U+4508, U+450D, U+4525, U+4543, U+45B8, U+45E5, U+460F, U+4641, U+4665, U+46A1, U+46AF, U+470C, U+4764, U+47FD, U+4816, U+484E, U+48B5, U+49E7, U+49FA, U+4A04, U+4A29, U+4ABC, U+4B3B, U+4BC2, U+4BCA, U+4BD2, U+4BE8, U+4C20, U+4CC4, U+4CD1, U+4D07, U+4D77, U+4E02, U+4E0F, U+4E12, U+4E29, U+4E2B-4E2C, U+4E2E, U+4E40, U+4E47-4E48, U+4E51, U+4E5A, U+4E69, U+4E9D, U+4EB9, U+4EBB-4EBC, U+4EC3, U+4EC8, U+4ED0, U+4EDA, U+4EEB, U+4EF1, U+4EF5, U+4F00, U+4F16, U+4F37, U+4F3E, U+4F54, U+4F58, U+4F64, U+4F77-4F78, U+4F7A, U+4F7D, U+4F82, U+4F85, U+4F92, U+4F9A, U+4FB2, U+4FBE, U+4FC5, U+4FCB, U+4FCF, U+4FD2, U+4FE6, U+4FF2, U+5000, U+5010, U+5013, U+501C, U+501E, U+5022, U+5042, U+5046, U+504E, U+5053, U+5057, U+5063, U+5066, U+506A, U+5070, U+5088, U+5092-5093, U+5095-5096, U+509C, U+50A3, U+50AA, U+50B1, U+50BA-50BB, U+50C4, U+50C7, U+50CE, U+50D4, U+50D9, U+50E1, U+50E9, U+50F3, U+5108, U+5117, U+511B, U+5160, U+5173, U+517B, U+5183, U+518B, U+5198, U+51A3, U+51AD, U+51BC, U+51F3-51F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium054.woff2") format("woff2");
  unicode-range: U+2E89, U+5202, U+5212, U+5216, U+5255, U+525C, U+526C, U+5277, U+5282, U+5284, U+5298, U+52A4, U+52A6, U+52AF, U+52BA-52BB, U+52CA, U+52D1, U+52F7, U+530A-530B, U+5324, U+5335, U+533E, U+5342, U+5367, U+536C, U+537A, U+53A4, U+53B4, U+53B7, U+53C0, U+53D5, U+53DA, U+53F4-53F5, U+5424, U+5428, U+5443, U+5455, U+5462, U+5466, U+546C, U+548A, U+548D, U+5495, U+54A0, U+54A6, U+54AD-54AE, U+54B7, U+54BA, U+54BF, U+54C3, U+54EC, U+54EF, U+54F1, U+54F3, U+5500-5501, U+5509, U+553C, U+5541, U+5547, U+554A, U+5560-5561, U+5564, U+557D, U+5582, U+5588, U+5591, U+55BF, U+55C9, U+55CC, U+55D1-55D2, U+55DD, U+55E2, U+55E9, U+5607, U+5610, U+5628, U+5630, U+5637, U+563D, U+563F-5640, U+5647, U+565E, U+5660, U+566D, U+5688, U+568C, U+5695, U+569A, U+569D, U+56A8, U+56AD, U+56B2, U+56C5, U+56CD, U+56DF, U+56E8, U+56F6-56F7, U+5715, U+5723, U+5729, U+5745-5746, U+574C-574D, U+5768, U+576F, U+5773-5775, U+577B, U+579A, U+579D-579E, U+57A8, U+57AC, U+57CC, U+57D7, U+57DE, U+57E6, U+57F0, U+57F8, U+57FB, U+57FD, U+5804, U+581E, U+5820, U+5827, U+5832, U+5839, U+5849, U+584C, U+5867, U+588A-588B, U+588D, U+588F-5890, U+5894, U+589D, U+58AA, U+58B1, U+58C3, U+58CD, U+58E2, U+58F3-58F4, U+5905-5906, U+590B, U+590D, U+5914, U+5924, U+593D, U+5946, U+595B, U+595F, U+5975-5976, U+599F, U+59AE, U+59BC, U+59C8, U+59CD, U+59DE, U+59E3-59E4, U+59E7, U+59EE, U+5A0C-5A0D, U+5A17, U+5A27, U+5A2D, U+5A55, U+5A65, U+5A7A, U+5A8B, U+5A9C, U+5A9F-5AA0, U+5AA2, U+5AB1, U+5AB3, U+5AB5, U+5ABA, U+5ABF, U+5ADA, U+5ADC, U+5AE0, U+5AE5, U+5AEE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium055.woff2") format("woff2");
  unicode-range: U+2E96, U+2E98, U+5AF0, U+5AF5, U+5B00, U+5B08, U+5B17, U+5B2D, U+5B34, U+5B4C, U+5B52, U+5B68, U+5B6F, U+5B7C, U+5B7F, U+5B81, U+5B84, U+5B96, U+5BAC, U+5BC0, U+5BCE, U+5BD6, U+5BF1, U+5BFD, U+5C03, U+5C29, U+5C30, U+5C5F, U+5C63, U+5C67-5C69, U+5C70, U+5C7C, U+5C88, U+5C8A, U+5CA0, U+5CA2, U+5CA6-5CA7, U+5CAD, U+5CB5, U+5CC9, U+5D06, U+5D10, U+5D1D, U+5D20, U+5D24, U+5D26, U+5D2B, U+5D31, U+5D39, U+5D42, U+5D61, U+5D6A, U+5D70, U+5D88, U+5D92, U+5D94, U+5D97, U+5D99, U+5DB0, U+5DB2, U+5DB4, U+5DB9, U+5DD1, U+5DD7-5DD8, U+5DE0, U+5DE4, U+5DE9, U+5E00, U+5E12, U+5E15, U+5E1F, U+5E2E, U+5E3E, U+5E49, U+5E56, U+5E6B-5E6E, U+5EA5, U+5EAA, U+5EAC, U+5EB9, U+5EBF, U+5EC6, U+5ED2, U+5ED9, U+5EFD, U+5F08, U+5F0E, U+5F1C, U+5F1E, U+5F47, U+5F63, U+5F72, U+5F7E, U+5F8F, U+5FA2, U+5FA4, U+5FB8, U+5FC4, U+5FC7, U+5FCB, U+5FD2-5FD4, U+5FE2, U+5FEE-5FEF, U+5FF3, U+5FFC, U+6017, U+6022, U+6024, U+604C, U+607F, U+608A, U+6095, U+60A8, U+60B0-60B1, U+60BE, U+60C8, U+60D9, U+60DB, U+60EE, U+60F2, U+60F5, U+6110, U+6112-6113, U+6119, U+611E, U+613A, U+6141, U+6146, U+6160, U+617C, U+6192-6193, U+6197-6198, U+61A5, U+61A8, U+61AD, U+61D5, U+61DD, U+61DF, U+61F5, U+6215, U+6223, U+6229, U+6246, U+624C, U+6251-6252, U+6261, U+6264, U+626D, U+6273, U+627B, U+6299, U+62A6, U+62D5, U+62FD, U+6303, U+630D, U+6310, U+6332, U+6335, U+633B-633C, U+6341, U+6344, U+634E, U+6359, U+636C, U+6384;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium056.woff2") format("woff2");
  unicode-range: U+6394, U+6399, U+63BD, U+63D4-63D5, U+63DC, U+63E0, U+63EB-63EC, U+63F2, U+63F7, U+6409, U+641E, U+6425, U+6429, U+642F, U+645A-645B, U+645D, U+6473, U+647D, U+6487, U+6491, U+649D, U+649F, U+64CB-64CC, U+64D5, U+64D7, U+64E4-64E5, U+64FF, U+6504, U+650F, U+6514, U+6516, U+651E, U+6532, U+6544, U+6554, U+656B, U+657A, U+6581, U+6584-6585, U+658A, U+65B2, U+65B5, U+65B8, U+65BF, U+65C2, U+65C9, U+65D4, U+65F2, U+65F9, U+65FC, U+6604, U+6608, U+6621, U+662A, U+6645, U+664E, U+6651, U+6657, U+665B, U+6663, U+666A-666D, U+667B, U+6680, U+6690, U+6692, U+6699, U+66AD, U+66B1, U+66B5, U+66BF, U+66EC, U+6701, U+6705, U+6712, U+6719, U+674C-674D, U+6754, U+675D, U+6774, U+6776, U+6792, U+67B0, U+67B2, U+67C3, U+67C8, U+67D2, U+67D9, U+67DB, U+67F0, U+67F7, U+6810, U+6818, U+681F, U+682D, U+6833, U+683B, U+683E, U+6844-6845, U+6849, U+684C, U+6855, U+6857, U+686B, U+686E, U+687A, U+687C, U+6882, U+6890, U+6896, U+6898-689A, U+689C, U+68AA-68AB, U+68B4, U+68BB, U+68C3, U+68C5, U+68CC, U+68CF, U+68D6, U+68D9, U+68E4-68E5, U+68EC, U+68F7, U+68FB, U+6903, U+6907, U+693B, U+6946, U+6969, U+696C, U+6972, U+697A, U+697F, U+6992, U+6996, U+6998, U+69A6, U+69B0, U+69B7, U+69BA, U+69BC, U+69C0, U+69D1, U+69D6, U+69E3, U+69EE-69EF, U+69F3-69F4, U+69FE, U+6A11, U+6A1A, U+6A1D, U+6A30, U+6A32-6A34, U+6A3F, U+6A46, U+6A49, U+6A4E, U+6A52, U+6A64, U+6A7A, U+6A7E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium057.woff2") format("woff2");
  unicode-range: U+2E9E, U+2EA1, U+2EA3, U+6A83, U+6A8B, U+6A91, U+6A9F, U+6AA1, U+6AAB, U+6ABD, U+6AC6, U+6AD0, U+6AD4, U+6ADC-6ADD, U+6AEC, U+6AF1-6AF3, U+6AFD, U+6B0B, U+6B0F-6B11, U+6B17, U+6B2F, U+6B4A, U+6B58, U+6B6C, U+6B75, U+6B7A, U+6B81, U+6B9B, U+6BAE, U+6BBD-6BBE, U+6BC7-6BC9, U+6BDA, U+6BE6-6BE7, U+6BEE, U+6BF1, U+6C02, U+6C0A, U+6C0E, U+6C35-6C36, U+6C3A, U+6C3F, U+6C4D, U+6C5B, U+6C67, U+6C6D, U+6C84, U+6C89, U+6C94-6C95, U+6C97, U+6CAD, U+6CC2, U+6CD0, U+6CD6, U+6CDA, U+6CDC, U+6CE9, U+6CEC-6CED, U+6D00, U+6D0A, U+6D24, U+6D26-6D27, U+6D2F, U+6D34, U+6D3C, U+6D5B, U+6D5E, U+6D60, U+6D70, U+6D80-6D81, U+6D8A, U+6D8D, U+6D91, U+6D98, U+6DAB, U+6DAE, U+6DB4, U+6DC2, U+6DC8, U+6DCE-6DD0, U+6DDF, U+6DE9, U+6DF6, U+6E17, U+6E1E, U+6E22, U+6E27, U+6E32, U+6E36, U+6E3C, U+6E48-6E49, U+6E4B-6E4C, U+6E4F, U+6E51, U+6E53-6E54, U+6E57, U+6E63, U+6E93, U+6EA7, U+6EB4, U+6EBF, U+6EC3, U+6ECA, U+6ED9, U+6EEB, U+6EF9, U+6EFB, U+6F0A, U+6F0C, U+6F18, U+6F25, U+6F35-6F36, U+6F3C, U+6F52, U+6F57, U+6F5A, U+6F60, U+6F68, U+6F7D, U+6F90, U+6F96, U+6F98, U+6F9F, U+6FA5, U+6FAF, U+6FB5, U+6FBE, U+6FC8-6FC9, U+6FDA, U+6FDE, U+6FE9, U+6FFC, U+7000, U+7007, U+700A, U+7023, U+7039-703A, U+703C, U+7043, U+7047, U+704B, U+7054, U+7065, U+7069, U+706C, U+706E, U+7076, U+707E, U+7081, U+7086, U+7095, U+7097, U+709F, U+70B1, U+70BB, U+70CA, U+70D1, U+70D3, U+70DC, U+70EC, U+7103-7104;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium058.woff2") format("woff2");
  unicode-range: U+2EA8, U+7106-7108, U+710C, U+712F, U+7131, U+714A, U+7150, U+7153, U+715E, U+7180, U+7196, U+719B, U+71A0, U+71A2, U+71AE-71AF, U+71B3, U+71CB, U+71D3, U+71D9, U+71DC, U+7207, U+722B, U+7234, U+7238-7239, U+7242, U+7253, U+7257, U+7263, U+726E-726F, U+7278, U+727F, U+728E, U+72AD-72AE, U+72B0-72B1, U+72C1, U+72CC, U+72F3, U+72FA, U+7307, U+7312, U+7318-7319, U+732C, U+7331, U+7333, U+7339, U+733D, U+7352, U+736B-736C, U+736E-736F, U+7371, U+7377, U+7381, U+7385, U+738A, U+7394, U+7398, U+739C, U+739E, U+73A5, U+73A8, U+73B5, U+73B7, U+73B9, U+73BC, U+73BF, U+73C5, U+73CB, U+73E1, U+73E7, U+73F9-73FA, U+7401, U+7413, U+7424, U+7431, U+7439, U+7440, U+7443, U+744D, U+7452-7453, U+745D, U+7471, U+7481, U+7485, U+7488, U+7492, U+7497, U+7499, U+74A0-74A1, U+74A5, U+74AA-74AB, U+74B9-74BB, U+74D6, U+74D8, U+74DE, U+74EB, U+74EF, U+74FA, U+7520, U+7524, U+752A, U+753D-753E, U+7540, U+7548, U+754E, U+7550, U+7552, U+756C, U+7571-7572, U+757A, U+757D-757E, U+7581, U+758C, U+75A2, U+75B0, U+75B7, U+75BF-75C0, U+75C6, U+75CF, U+75D3, U+75DD, U+75DF-75E0, U+75E7, U+75EC, U+75EE, U+75F1, U+75F9, U+7603, U+7607, U+760F, U+7613, U+7618, U+761B-761C, U+7625, U+7628, U+7633, U+763C, U+7641, U+7649, U+7655, U+766E, U+7695, U+769C, U+76A0-76A1, U+76A7-76A8, U+76AF, U+76C9, U+76E8, U+76EC, U+7717, U+771A, U+772D, U+7735, U+7758, U+7760, U+776A, U+7772, U+777C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium059.woff2") format("woff2");
  unicode-range: U+2EAD, U+777D, U+779A, U+779F, U+77A2, U+77A4, U+77A9, U+77DE-77DF, U+77E4, U+77E6, U+77EA, U+77EC, U+77F0, U+77F4, U+77FB, U+7805-7806, U+7809, U+780D, U+7819, U+7821, U+782C, U+7847, U+7864, U+786A, U+788A, U+7894, U+789D-789F, U+78A4, U+78BB, U+78C8, U+78CC, U+78CE, U+78D5, U+78E0-78E1, U+78E6, U+78F9-78FB, U+78FE, U+7910, U+791B, U+7925, U+7930, U+793B, U+794A, U+7958, U+795B, U+7967, U+7972, U+7994-7996, U+799B, U+79A1, U+79A9, U+79B4, U+79BB, U+79C2, U+79C7, U+79CC-79CD, U+79D6, U+7A0A, U+7A11, U+7A15, U+7A1B, U+7A1E, U+7A2D, U+7A38, U+7A47, U+7A4C, U+7A56, U+7A59, U+7A5C, U+7A5F-7A60, U+7A67, U+7A6A, U+7A75, U+7A78, U+7A82, U+7A8A, U+7A90, U+7AA3, U+7AAC, U+7AB9, U+7ABC, U+7ABE, U+7ACC, U+7AD1, U+7AE7-7AE8, U+7AF4, U+7B07, U+7B27, U+7B2A, U+7B2E-7B2F, U+7B31, U+7B3D, U+7B41, U+7B55, U+7B64, U+7B66, U+7B69, U+7B73, U+7B79, U+7B7F, U+7B90-7B91, U+7B9B, U+7BAF, U+7BB5, U+7BBC, U+7BC5, U+7BCA, U+7BD4, U+7BD6, U+7BDA, U+7BEA, U+7BF0, U+7C03, U+7C0B, U+7C0E-7C0F, U+7C26, U+7C45, U+7C4A, U+7C51, U+7C57, U+7C5E, U+7C61, U+7C69, U+7C6E-7C70, U+7CA6, U+7CB6-7CB7, U+7CBF, U+7CC4, U+7CC8, U+7CCD, U+7CD7, U+7CE6, U+7CEB, U+7CF5, U+7D03, U+7D09, U+7D12, U+7D1E, U+7D3D-7D3E, U+7D40, U+7D47, U+7D59-7D5A, U+7D6A, U+7D70, U+7D7F, U+7D86, U+7D88, U+7D8C, U+7D97, U+7D9D, U+7DA7, U+7DAA, U+7DB6-7DB7, U+7DC0, U+7DD7, U+7DD9, U+7DE6, U+7DF1, U+7DF9, U+7E10, U+7E17, U+7E1D, U+7E27, U+7E2C, U+7E45, U+7E73, U+7E75, U+7E7E, U+7E86-7E87, U+7E91, U+7E98, U+7E9A, U+7F3B-7F3C, U+7F3E, U+7F43-7F44, U+7F4F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium060.woff2") format("woff2");
  unicode-range: U+2EAB, U+2EB2, U+2EB9, U+2EBE, U+7F52, U+7F61, U+7F63-7F64, U+7F6D, U+7F7D-7F7E, U+7F90, U+7F96, U+7F9C, U+7FAD, U+7FC3, U+7FCF, U+7FE3, U+7FE5, U+7FEF, U+7FF2, U+8002, U+8008, U+800A, U+800E, U+8011, U+8016, U+8024, U+802C, U+8030, U+8043, U+8066, U+8071, U+8075, U+807B, U+8099, U+809C, U+80A4, U+80A7, U+80B8, U+80C5, U+80D5, U+80D8, U+80E6, U+80F5, U+80FB, U+810D, U+8116, U+811E, U+8124, U+8127, U+812C, U+8135, U+813D, U+8169, U+8181, U+8184-8185, U+8198, U+81B2, U+81C1, U+81C3, U+81D6, U+81DB, U+81E4, U+81EC, U+81FD, U+81FF, U+8204, U+8219, U+8221-8222, U+8232, U+8234, U+823C, U+8245-8246, U+8249, U+824B, U+824F, U+8257, U+825C, U+8263, U+8279, U+827D, U+827F, U+8283, U+828A, U+8293, U+82A7-82A8, U+82B2, U+82B4, U+82BA, U+82BC, U+82E2, U+82E8, U+82F7, U+8307-8308, U+830C, U+831B, U+831D, U+8330, U+833C, U+8344, U+8354, U+8357, U+8363, U+837F, U+838D, U+8394-8395, U+839B, U+839D, U+83C9, U+83D0, U+83D4, U+83DD, U+83E5, U+83F9, U+840F, U+8411, U+8415, U+8417, U+8439, U+844A, U+844F, U+8451-8452, U+8459-845A, U+845C, U+8465, U+8476, U+8478, U+847C, U+8481, U+8497, U+84A6, U+84BE, U+84CE-84CF, U+84D3, U+84DC, U+84E7, U+84EA, U+84EF-84F1, U+84FA, U+84FD, U+850C, U+851B, U+8524-8525, U+852B, U+8534, U+853E, U+854F, U+8551, U+8553, U+855E, U+8561-8562, U+856F, U+857B, U+857D, U+857F, U+8581, U+8586, U+8593, U+859D, U+859F, U+85B7, U+85BC, U+85C7, U+85CA, U+85D8-85D9, U+85DF, U+85E1, U+85E6, U+85F6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium061.woff2") format("woff2");
  unicode-range: U+2EC2-2EC3, U+8600, U+8611, U+861E, U+8621, U+8624, U+8627, U+8639, U+863C, U+8640, U+8653, U+8656, U+866F, U+8677, U+867A, U+8687, U+8689, U+868D, U+8691, U+869C-869D, U+86A8, U+86B1, U+86B3, U+86C1, U+86C3, U+86D1, U+86D5, U+86D7, U+86E3, U+86E6, U+8705, U+8707, U+870E, U+8710, U+8713, U+8719, U+871F, U+8721, U+8723, U+8731, U+873A, U+873E, U+8740, U+8743, U+8751, U+8758, U+8764-8765, U+8772, U+877C, U+8789, U+878B, U+8793, U+87A0, U+87A7, U+87BE, U+87C1, U+87CE, U+87DF, U+87E3, U+87E5-87E6, U+87EA-87EB, U+87ED, U+87F5, U+8801, U+8803, U+880B, U+8813, U+8828, U+882E, U+8832, U+883C, U+884A, U+8858, U+885F, U+8864, U+8869, U+886F, U+88A0, U+88BC-88BE, U+88C0, U+88D1-88D3, U+88DB, U+88F0-88F1, U+8901, U+8937, U+8942, U+8945, U+8949, U+8962, U+8980, U+8989, U+8990, U+899F, U+89B0, U+89B7, U+89D6, U+89D8, U+89EB, U+89F1, U+89F3, U+89FD, U+89FF, U+8A11, U+8A14, U+8A21, U+8A35, U+8A3E, U+8A45, U+8A4D, U+8A58, U+8A90, U+8AAE, U+8AB7, U+8ABE, U+8AD7, U+8AFC, U+8B05, U+8B0A, U+8B0D, U+8B1C, U+8B1F, U+8B2D, U+8B43, U+8B51, U+8B5E, U+8B76, U+8B7F, U+8B81, U+8B8B, U+8B94-8B95, U+8B9C, U+8B9E, U+8C39, U+8C3D, U+8C45, U+8C47, U+8C4F, U+8C54, U+8C57, U+8C69, U+8C6D, U+8C73, U+8C92-8C93, U+8C99, U+8C9B, U+8CA4, U+8CD5-8CD6, U+8CD9, U+8CF0-8CF1, U+8D09, U+8D0E, U+8D6C, U+8D84, U+8D95, U+8DA6, U+8DC6, U+8DC8, U+8DD9, U+8DEC, U+8DFD, U+8E06, U+8E0C, U+8E14, U+8E16, U+8E21-8E22, U+8E27, U+8E36;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium062.woff2") format("woff2");
  unicode-range: U+2ECD, U+2ECF, U+2ED2, U+2ED6, U+8E39, U+8E4B, U+8E54, U+8E62, U+8E6C-8E6D, U+8E6F, U+8E98, U+8E9E, U+8EAE, U+8EB3, U+8EB5-8EB6, U+8EBB, U+8ED1, U+8ED4, U+8EF9, U+8F00, U+8F08, U+8F17, U+8F2B, U+8F40, U+8F4A, U+8F58, U+8FA4, U+8FB4, U+8FB6, U+8FC1, U+8FC6, U+8FCA, U+8FCD, U+8FD3, U+8FD5, U+8FE0, U+8FF1, U+8FF5, U+8FFB, U+9002, U+900C, U+9037, U+9043-9044, U+905D, U+9085, U+908C, U+9090, U+90A1, U+90B0, U+90B6, U+90C8, U+90DC, U+90DF, U+90EB, U+90F2, U+90F6, U+90FE-9100, U+9104, U+9106, U+9118, U+911C, U+911E, U+9137, U+9139-913A, U+9146-9147, U+9157, U+9159, U+9161, U+9164, U+9174, U+9179, U+9185, U+918E, U+91A8, U+91AE, U+91B3, U+91B6, U+91C3-91C4, U+91DA, U+91EC, U+91EE, U+9201, U+920A, U+9216-9217, U+9233, U+9242, U+9247, U+924A, U+924E, U+9251, U+9256, U+9259, U+9260-9261, U+9265, U+9267-9268, U+927C-927D, U+927F, U+9289, U+928D, U+9297, U+9299, U+929F, U+92A7, U+92AB, U+92B2, U+92BF-92C0, U+92C6, U+92CE, U+92D0, U+92D7, U+92D9, U+92E5, U+92E7, U+92F7, U+92F9, U+92FB, U+9302, U+930D, U+9311, U+9315, U+931D-931E, U+9327, U+9329, U+9347, U+9351, U+9357, U+935A, U+936B, U+9371, U+9373, U+9388, U+938B, U+938F, U+939E, U+93A1, U+93C1, U+93C7, U+93DC, U+93E2, U+93E7, U+93F1, U+93F5, U+93FB, U+9409, U+940F, U+9416-9417, U+9432, U+9434, U+943B, U+9445, U+946D, U+946F, U+9578-9579, U+9586, U+958C-958D, U+95AB, U+95B4, U+95C8, U+961D, U+962C, U+9633-9634, U+963C, U+9641, U+9661, U+9682, U+969A, U+96A9, U+96AF, U+96B3, U+96BA, U+96BD, U+96D8, U+96DA, U+96DD, U+9714, U+9723, U+9736, U+9741, U+9747, U+9755, U+9757, U+975B, U+976A, U+9796, U+979A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium063.woff2") format("woff2");
  unicode-range: U+2EDF, U+979E, U+97A2, U+97B1-97B2, U+97BE, U+97CC, U+97D1, U+97D4, U+97D8-97D9, U+97E1, U+97F1, U+9804, U+980D-980E, U+9814, U+9816, U+9823, U+9825, U+9832-9833, U+9847, U+9866, U+98AB, U+98AD, U+98B0, U+98B7-98B8, U+98BB-98BC, U+98BF, U+98C2, U+98C7, U+98CB, U+98E0-98E1, U+98E3, U+98E5, U+98EA, U+98F0-98F1, U+98F3, U+9908, U+9916-9917, U+991A-991C, U+9931-9933, U+993A-993C, U+9940-9941, U+9946, U+994D-994E, U+995C, U+995F-9960, U+99A3, U+99A6, U+99B9, U+99BD, U+99BF, U+99C3, U+99C9, U+99D4, U+99D9, U+99DE, U+99F0, U+99F9, U+99FC, U+9A0A, U+9A11, U+9A16, U+9A1A, U+9A20, U+9A31, U+9A36, U+9A44, U+9A4C, U+9A58, U+9AAF, U+9AB7, U+9AB9, U+9AC6, U+9AD0, U+9AD2, U+9AD5, U+9ADC, U+9AE0, U+9AE5, U+9AE9, U+9B03, U+9B0C, U+9B10, U+9B12, U+9B16, U+9B1C, U+9B2B, U+9B33, U+9B3D, U+9B4B, U+9B63, U+9B65, U+9B6B-9B6C, U+9B73, U+9B76-9B77, U+9BA6, U+9BAC, U+9BB1-9BB2, U+9BB8, U+9BBE, U+9BC7, U+9BD8, U+9BDD, U+9BE7, U+9BEA-9BEB, U+9BEE-9BEF, U+9BF3, U+9BF7, U+9BFA, U+9C16, U+9C18-9C1A, U+9C1D, U+9C22, U+9C27, U+9C29-9C2A, U+9C31, U+9C36-9C37, U+9C45, U+9C49-9C4A, U+9C54, U+9C58, U+9C5B-9C5D, U+9C5F, U+9C69-9C6B, U+9C6D-9C6E, U+9C70, U+9C72, U+9C75, U+9C7A, U+9CE6, U+9CF2, U+9D02, U+9D0B, U+9D11, U+9D17-9D18, U+9D32, U+9D42, U+9D4A, U+9D5F, U+9D62, U+9D69, U+9D6B, U+9D73, U+9D76-9D77, U+9D7E, U+9D84, U+9D8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium064.woff2") format("woff2");
  unicode-range: U+2E87, U+2EA4, U+2EBF-2EC0, U+2ECC, U+9D99, U+9DA1, U+9DB5, U+9DB9, U+9DBD, U+9DBF, U+9DC3, U+9DC7, U+9DC9, U+9DD6, U+9DDA, U+9DDF-9DE0, U+9DE3, U+9DF4, U+9E02, U+9E0A, U+9E0D, U+9E19, U+9E1C-9E1D, U+9E7B, U+9E80, U+9E85, U+9E9B, U+9EA8, U+9EBD, U+9EDF, U+9EE7, U+9EEE, U+9EFF, U+9F02-9F03, U+9F17, U+9F19, U+9F2F, U+9F37, U+9F3A, U+9F3D, U+9F41, U+9F45-9F46, U+9F53, U+9F55, U+9F58, U+9F5D, U+9F69, U+9F6D, U+9F70, U+9F75, U+FA13, U+FA20-FA21, U+FA24, U+FA49, U+FA58, U+FA5D-FA5E, U+FA66, U+20089, U+200A2, U+200A4, U+201A2, U+20213, U+2032B, U+20371, U+20381, U+203F9, U+2044A, U+20509, U+205D6, U+20628, U+2074F, U+20807, U+2083A, U+208B9, U+2097C, U+2099D, U+20AD3, U+20B1D, U+20D45, U+20DE1, U+20E64, U+20E6D, U+20E95, U+20F5F, U+21201, U+21255, U+21274, U+2127B, U+212D7, U+212E4, U+212FD, U+21336, U+21344, U+213C4, U+2146D, U+215D7, U+21647, U+21706, U+21742, U+219C3, U+21C56, U+21D2D, U+21D45, U+21D62, U+21D78, U+21D92, U+21D9C, U+21DA1, U+21DB7, U+21DE0, U+21E33, U+21F1E, U+21F76, U+21FFA, U+2217B, U+22218, U+2231E, U+223AD, U+226F3, U+2285B, U+228AB, U+2298F, U+22AB8, U+22B46, U+22B4F-22B50, U+22BA6, U+22C1D, U+22C24, U+22DE1, U+231B6, U+231C3, U+231F5, U+23372, U+233D0, U+233D2-233D3, U+233D5, U+233DA, U+233DF, U+233E4, U+2344A-2344B, U+23451, U+23465, U+234E4, U+2355A, U+23594, U+23638-2363A, U+23647, U+2370C, U+2371C, U+23764, U+237E7, U+237FF, U+23824, U+2383D, U+23A98, U+23C7F, U+23D00, U+23D0E, U+23D40, U+23DD3, U+23DF9-23DFA, U+23F7E, U+24096, U+24103, U+241C6, U+241FE, U+243BC, U+24629, U+246A5, U+24896, U+24A4D, U+24B56;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium065.woff2") format("woff2");
  unicode-range: U+2EAA, U+2EBD, U+2ECA, U+2EDE, U+4EFC, U+4F39, U+4FCD, U+4FFF, U+50D8, U+50F4, U+5164, U+51BE, U+51EC, U+529C, U+52C0, U+5372, U+53DD, U+5759, U+5765, U+58B2, U+5953, U+5963, U+59BA, U+5CF5, U+5DD0, U+5F21, U+605D, U+6085, U+60DE, U+6111, U+6120, U+6213, U+654E, U+662E, U+663B, U+670E, U+6AE2, U+6C6F, U+6D6F, U+6D96, U+6DF2, U+6DF8, U+6E39, U+24B6F, U+24C16, U+24D14, U+24E0E, U+24E37, U+24E6A, U+24E8B, U+2504A, U+25055, U+25122, U+251A9, U+251CD, U+251E5, U+2521E, U+2524C, U+2542E, U+254D9, U+255A7, U+257A9, U+257B4, U+259D4, U+25AE3-25AE4, U+25AF1, U+25BB2, U+25C4B, U+25C64, U+25E2E, U+25E56, U+25E62, U+25E65, U+25EC2, U+25ED8, U+25EE8, U+25F23, U+25F5C, U+25FD4, U+25FE0, U+25FFB, U+2600C, U+26017, U+26060, U+260ED, U+26270, U+26286, U+2634C, U+26402, U+2667E, U+266B0, U+2671D, U+268DD, U+268EA, U+26951, U+2696F, U+269DD, U+26A1E, U+26A58, U+26A8C, U+26AB7, U+26C29, U+26C73, U+26CDD, U+26E65, U+26F94, U+26FF6-26FF8, U+2710D, U+27139, U+273DA-273DB, U+273FE, U+27410, U+27449, U+27614-27615, U+27631, U+27693, U+2770E, U+27723, U+27752, U+27985, U+27A84, U+27BB3, U+27BBE, U+27BC7, U+27CB8, U+27DA0, U+27E10, U+27FB7, U+2808A, U+280BB, U+28282, U+282F3, U+2840C, U+28455, U+2856B, U+285C8-285C9, U+286D7, U+286FA, U+28946, U+28949, U+2896B, U+28987-28988, U+289BA-289BB, U+28A1E, U+28A29, U+28A43, U+28A71, U+28A99, U+28ACD, U+28ADD, U+28AE4, U+28BC1, U+28BEF, U+28D10, U+28D71, U+28DFB, U+28E1F, U+28E36, U+28E89, U+28EEB, U+28F32, U+28FF8, U+292A0, U+292B1, U+29490, U+295CF, U+2967F, U+296F0, U+29719, U+29750, U+298C6, U+29A72, U+29DDB, U+29E15, U+29E3D, U+29E49, U+29E8A, U+29EC4, U+29EDB, U+29EE9, U+29FCE, U+29FD7, U+2A01A, U+2A02F, U+2A082, U+2A0F9, U+2A38C, U+2A437, U+2A5F1, U+2A602, U+2A61A, U+2A6B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium066.woff2") format("woff2");
  unicode-range: U+2FAD, U+3EDA, U+4103, U+4E04-4E05, U+4E0C, U+4E1F, U+4E23-4E24, U+4E35, U+4E41, U+4E44, U+4E5C, U+4E63, U+4E68, U+4E74-4E75, U+4E79, U+4E7F, U+4E96-4E97, U+4EAF, U+4EDB, U+4EE0, U+4EE2, U+4EE8, U+4EEF, U+4EF3, U+4EFE, U+4F02, U+4F08, U+4F0C, U+4F12, U+4F15, U+4F17, U+4F19, U+4F2E, U+4F31, U+4F33, U+4F35, U+4F3B, U+4F40, U+4F42, U+4F4B-4F4C, U+4F52, U+4F63, U+4F6E, U+4F71, U+4F79, U+4F81, U+4F84, U+4F89, U+4F8C, U+4F8E, U+4F90, U+4F93, U+4F99, U+4F9E-4F9F, U+4FB7, U+4FB9, U+4FBB-4FBD, U+4FC0-4FC1, U+4FC6, U+4FC8, U+4FCC, U+4FDC, U+4FE2, U+4FF0, U+4FFC-4FFD, U+5004, U+5007, U+500A, U+500C, U+5017, U+501B, U+501D, U+5030, U+5032-5033, U+5035, U+5045, U+504A, U+504C, U+5051-5052, U+5059, U+505F-5060, U+5062, U+5067, U+506D, U+5071, U+5081, U+5083-5084, U+5086, U+508A, U+508E-5090, U+509B, U+509E-50A2, U+50AF-50B0, U+50B9, U+50BD, U+50C0, U+50C3, U+50D3, U+50DC-50DD, U+50DF, U+50E2, U+50E4, U+50E8, U+50EF, U+50F1, U+50F6, U+50FA, U+50FE, U+5107, U+510C, U+56E4, U+5880, U+6911, U+6DCA, U+7324, U+73BD, U+73D2, U+73F5, U+7421, U+7429, U+7575, U+7682, U+787A, U+79DA, U+7FA1, U+813A, U+83F6, U+856B, U+8807, U+8B53, U+8D76, U+8ECF, U+8FF6, U+9067, U+91B2, U+91DE, U+9206, U+9277, U+92D5, U+93A4, U+93F8, U+9431, U+9448, U+9592, U+9743, U+9751, U+9927, U+9BBB, U+9FC4, U+9FC6, U+FA0E, U+FA12, U+FA17-FA18, U+FA1C-FA1E, U+FA23, U+FA25, U+FA27-FA2D, U+FA6B-FA6D, U+20158, U+20BB7, U+233CC, U+233FE, U+242EE, U+2F8DB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium067.woff2") format("woff2");
  unicode-range: U+510D-510E, U+5110, U+5119, U+511C-511D, U+5123, U+5127-5128, U+512C-512D, U+512F, U+5131, U+5133-5134, U+5138-5139, U+5142, U+514F, U+5153, U+5158, U+515F, U+5166, U+517E, U+5184, U+518E, U+51A1, U+51B8, U+51BA, U+51BF, U+51C2, U+51C8, U+51CF, U+51D1-51D3, U+51D5, U+51D8, U+51E5, U+51F2, U+51F7, U+5205, U+5218, U+5222, U+5228, U+5231-5232, U+5235, U+523C, U+5245, U+5258, U+525A, U+525F-5260, U+5266, U+526E, U+5278-5279, U+5280, U+5285, U+528A, U+528C, U+5295-5297, U+529A, U+52A5, U+52A7, U+52B0, U+52B6-52B8, U+52BD, U+52C4, U+52C6, U+52CF, U+52D4, U+52DC, U+52E1, U+52E5, U+52E8-52EA, U+52EC, U+52F1, U+52F4, U+52F6, U+5303, U+530C, U+5311, U+5313, U+5318, U+531B, U+531E-531F, U+5325, U+5327-5329, U+532B-532D, U+5330, U+5332, U+533C-533D, U+534B-534C, U+5359, U+535B, U+5365, U+536D, U+5379, U+537E, U+5383, U+5387-5388, U+538E, U+5394, U+5399, U+53A1, U+53AA-53AB, U+53AF, U+53B5, U+53B8, U+53BA, U+53BD, U+53C5, U+53CF, U+53D2-53D3, U+53DE, U+53E0, U+53E6-53E7, U+5402, U+5413, U+541A, U+5421, U+542A, U+542F, U+5431, U+5434-5435, U+5444, U+5447, U+544F, U+545E, U+5464, U+5467, U+5469, U+546D-546E, U+5481, U+5483, U+5485, U+5489, U+5491, U+549F, U+54A7, U+54AA, U+54B1, U+54B9, U+54BB, U+54CA, U+54CD-54CE, U+54E0, U+54EA, U+54F6, U+54FC, U+54FE, U+5505, U+5508, U+550C-550D, U+5515, U+552A, U+5532, U+5536, U+553B, U+553D, U+5549, U+554D, U+5551, U+5558, U+555A-555B, U+5566, U+557F, U+558F, U+5592-5594, U+5597, U+55A3-55A4, U+55B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium068.woff2") format("woff2");
  unicode-range: U+55C1, U+55C3, U+55C6, U+55CB, U+55D3, U+55D7-55D8, U+55DB, U+55DE, U+55F6, U+55FF, U+5605, U+560A, U+560D, U+560F, U+5611-5612, U+5619, U+562C, U+5633, U+5635, U+5639, U+563C, U+5641, U+5643-5644, U+5646, U+564B, U+564D, U+564F, U+5654, U+5661-5663, U+5669, U+5675, U+5684-5685, U+568B, U+569F, U+56A6-56A7, U+56AB, U+56B1, U+56B7, U+56BE, U+56CB-56CC, U+56CF-56D0, U+56D9, U+56DC-56DD, U+56E1, U+56E5-56E7, U+56EB, U+56ED, U+56F1, U+5701-5702, U+5707, U+570C, U+5711, U+571A-571B, U+571D, U+5720, U+5722, U+5724-5725, U+572A, U+572C, U+572E, U+573D-573F, U+5752, U+5762, U+5767, U+576B, U+576D-576E, U+5771, U+5779-577A, U+577E, U+5781, U+5783, U+578C, U+5794-5795, U+5797, U+5799, U+579F, U+57A1, U+57A7, U+57A9, U+57BD, U+57D5, U+57DD, U+57E1, U+57E7, U+57E9, U+57FE, U+5803, U+5808, U+580C-580D, U+581B, U+581F, U+5826, U+582D, U+583F, U+584D, U+584F-5850, U+5855, U+585F, U+5868, U+5878, U+587F, U+5881, U+5887-5888, U+588C, U+5896, U+58A0-58A2, U+58A6, U+58BC, U+58C2, U+58C4, U+58C8, U+58D0, U+58D6, U+58DD, U+58E1, U+5912-5913, U+591D, U+5921, U+5923, U+5928, U+592F-5930, U+5933, U+5935-5936, U+593F, U+5943, U+5952, U+5959, U+595E, U+5961, U+596B, U+596F, U+5972, U+5979, U+597B, U+598C, U+598E, U+5995, U+5997, U+59A7, U+59AD, U+59AF-59B0, U+59B3, U+59B7, U+59C1, U+59C4, U+59CA, U+59DF, U+59EF, U+59F1-59F2, U+59F4, U+59F7, U+5A00, U+5A04, U+5A0E, U+5A12, U+5A1E, U+5A24, U+5A28, U+5A2A, U+5A30, U+5A44-5A45, U+5A47-5A48, U+5A4C, U+5A50, U+5A5E, U+5A63;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium069.woff2") format("woff2");
  unicode-range: U+2F39, U+5A7B, U+5A90, U+5A93, U+5A96, U+5A99, U+5AAC, U+5AB2, U+5AB8, U+5ABB, U+5AC6, U+5AC8, U+5ACF, U+5AEA, U+5AF6, U+5AFD, U+5B01, U+5B1B, U+5B1D, U+5B21, U+5B38, U+5B4B, U+5B5E, U+5B6E, U+5B7E, U+5B86, U+5B8A, U+5B8E, U+5B90-5B91, U+5B94, U+5BA8-5BA9, U+5BAD, U+5BAF, U+5BB1-5BB2, U+5BB7, U+5BBA, U+5BBC, U+5BC1, U+5BCD, U+5BCF, U+5BD7, U+5BD9-5BDA, U+5BE0, U+5BEF, U+5BF4, U+5C0C, U+5C17, U+5C1F, U+5C26, U+5C2C, U+5C2E, U+5C32, U+5C35-5C36, U+5C59-5C5A, U+5C5C, U+5C6D, U+5C74-5C75, U+5C7B, U+5C7D, U+5C87, U+5C92, U+5C9D, U+5CB2, U+5CB4, U+5CD7, U+5CDD, U+5CEE, U+5CF1-5CF2, U+5D01, U+5D12, U+5D23, U+5D34, U+5D3D, U+5D3F, U+5D43, U+5D48, U+5D51, U+5D55, U+5D59, U+5D5F-5D60, U+5D62, U+5D64, U+5D79-5D7A, U+5D7E-5D7F, U+5D83, U+5D8A, U+5D93, U+5D95, U+5D9B, U+5D9F, U+5DAB, U+5DC3, U+5DC7, U+5DCE, U+5DD9, U+5DF8-5DF9, U+5E07, U+5E0D, U+5E20, U+5E28, U+5E32, U+5E35, U+5E4B, U+5E50-5E51, U+5E5B-5E5C, U+5E68, U+5E6A, U+5E70, U+5E80, U+5E8B, U+5E8E, U+5EA2, U+5EA4, U+5EA8, U+5EB1, U+5EB3, U+5EBD, U+5ECC, U+5ECE, U+5ED1, U+5ED4-5ED5, U+5EDC, U+5EDE, U+5EE5, U+5EEB, U+5F06, U+5F19, U+5F22, U+5F24, U+5F28, U+5F2B-5F2C, U+5F2E, U+5F30, U+5F3B, U+5F3F, U+5F44, U+5F4D, U+5F50, U+5F5B, U+5F60, U+5F6F, U+5F74-5F75, U+5F78, U+5F7A, U+5F8D, U+5F96, U+5F9D, U+5FAB-5FAC, U+5FB0-5FB1, U+5FC8, U+5FD0-5FD1, U+5FE8, U+5FEA, U+5FEC-5FED, U+5FF2, U+5FF6, U+5FFA, U+6007, U+600A, U+6013, U+601A, U+601F, U+602D, U+6040, U+6048-6049, U+6051, U+6054, U+6056-6057, U+6061, U+6067;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium070.woff2") format("woff2");
  unicode-range: U+6071, U+607E, U+6082, U+6086, U+6088, U+608E, U+6091, U+6093, U+6098, U+60A2, U+60A4-60A5, U+60B7, U+60BB, U+60C2, U+60C4, U+60C9-60CA, U+60CE-60CF, U+60E2, U+60E5, U+60FC-60FD, U+6102, U+6107, U+610A, U+610C, U+6114, U+6116-6117, U+6122, U+612A, U+6131, U+6135-6136, U+6139, U+6145, U+6149, U+615E, U+616C, U+6172, U+6178, U+617B, U+617F-6181, U+6183-6184, U+618B, U+619C-619D, U+619F-61A0, U+61AA, U+61B8, U+61C0-61C2, U+61CE-61CF, U+61DC, U+61DE, U+61E1-61E2, U+61E5, U+61E7, U+61E9, U+61EC-61ED, U+61EF, U+6201, U+6203-6204, U+6207, U+621C, U+6220, U+6227, U+622B, U+6239, U+623D, U+6242, U+6244, U+6250, U+6254, U+625C, U+627A, U+627D, U+628D-6290, U+62A8, U+62B3, U+62B6-62B7, U+62BA, U+62BE-62BF, U+62CE, U+62DA, U+62EA, U+62F2, U+62F4, U+6304, U+630B, U+6313, U+6316, U+6329-632A, U+632D, U+6336, U+6342, U+6346, U+634A-634B, U+6352-6354, U+6358, U+635B, U+6366, U+636D, U+6371, U+6374-6375, U+6378, U+637D, U+637F, U+6382, U+6387, U+638A, U+6390, U+6395, U+639A, U+639E, U+63A4, U+63A6, U+63AD-63AF, U+63C1, U+63C5, U+63C8, U+63CE, U+63D1, U+63D3, U+63EA, U+63F3, U+63F8-63F9, U+640A, U+6412, U+6418, U+6420, U+6424, U+642A, U+6430, U+6435, U+643D, U+643F, U+644B, U+644F, U+6452-6454, U+645C, U+645F, U+6461, U+6463, U+6474, U+647B, U+6485, U+648F-6490, U+6498-6499, U+649B, U+64A1, U+64A3, U+64A6, U+64A8, U+64AC, U+64B3, U+64BD, U+64C9, U+64D1, U+64E9-64EA, U+64ED, U+64F0, U+64F5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium071.woff2") format("woff2");
  unicode-range: U+6501, U+6508-650A, U+6513, U+6519, U+651B, U+651F, U+6526, U+652E, U+6531, U+653A, U+653C-653D, U+6543, U+6547, U+6549, U+6550, U+6552, U+655F-6560, U+657D, U+6592, U+6595, U+6598, U+65A0, U+65A3, U+65A6, U+65AE, U+65B3-65B4, U+65C8, U+65CE, U+65D0, U+65D6, U+65D8, U+65DF, U+65F0, U+65F4-65F5, U+65FE-65FF, U+660D, U+6611-6612, U+6616, U+661D, U+6623, U+6626, U+6629, U+662C, U+6637, U+6639, U+6640, U+6646, U+664A, U+6658, U+665C, U+6660, U+6675, U+6679, U+667C, U+667E-667F, U+668B-668C, U+669A-669C, U+669F, U+66A4, U+66C0, U+66C2-66C3, U+66CC, U+66CE-66CF, U+66D4, U+66DF, U+66EB, U+66EE, U+6707, U+671C, U+6720, U+6722, U+673E, U+6745, U+6755, U+676C, U+676E, U+6784, U+678E-678F, U+6791, U+6796, U+6799, U+67B1, U+67B5, U+67BC-67BD, U+67C2, U+67C5, U+67C9, U+67DC, U+67E1, U+67E6, U+67F2, U+67F6, U+6814, U+6819, U+6827-6828, U+682F-6830, U+683F, U+684A, U+6858, U+686F-6871, U+6879, U+687B, U+6884, U+6886, U+6888, U+68A1, U+68A9, U+68AE, U+68D1, U+68D3, U+68DC-68DD, U+68EA-68EB, U+68F5-68F6, U+68FD, U+6906, U+6909, U+6910, U+6913, U+6916-6917, U+6931, U+6933, U+6938, U+6945, U+694E, U+695B, U+6965-6966, U+6970-6971, U+697B, U+698D, U+69A1, U+69A8, U+69AB, U+69AF, U+69B8, U+69C5, U+69C8, U+69D7, U+69E5, U+69F1, U+6A00-6A01, U+6A03, U+6A20, U+6A24, U+6A28, U+6A37, U+6A4A, U+6A51, U+6A55, U+6A67, U+6A6A, U+6A71, U+6A81, U+6A86-6A87, U+6A9B, U+6AAF-6AB1, U+6AB4, U+6ABE-6ABF, U+6AC8-6AC9, U+6ACC, U+6AD5-6AD6, U+6AF0, U+6AFC, U+6B02;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium072.woff2") format("woff2");
  unicode-range: U+6B03, U+6B06-6B07, U+6B09, U+6B24, U+6B28, U+6B2B, U+6B36, U+6B3B, U+6B3F, U+6B4D, U+6B52, U+6B5D, U+6B6B, U+6B6E, U+6B70, U+6B7D-6B7E, U+6B85, U+6B97, U+6B9F-6BA0, U+6BA2-6BA3, U+6BA8, U+6BAC, U+6BB0, U+6BB8-6BB9, U+6BC3-6BC4, U+6BCC, U+6BE1, U+6BE3, U+6BF7, U+6BF9, U+6C04, U+6C09, U+6C0D, U+6C12, U+6C19, U+6C1F, U+6C26-6C28, U+6C2C, U+6C2E, U+6C3B, U+6C4A-6C4B, U+6C4F, U+6C52, U+6C54, U+6C6B, U+6C78-6C79, U+6C7B, U+6C87, U+6C9F, U+6CB0, U+6CB2, U+6CB4, U+6CCD, U+6CCF, U+6CD1-6CD2, U+6CE7, U+6CF2, U+6CF4, U+6D07, U+6D0F, U+6D11, U+6D13, U+6D1A, U+6D28, U+6D57, U+6D5F, U+6D61, U+6D67, U+6D7C, U+6D92, U+6D97, U+6DB7, U+6DB9, U+6DBD, U+6DE0, U+6DE2, U+6DE5, U+6DEF-6DF0, U+6DF4, U+6E00, U+6E04, U+6E3B, U+6E45, U+6E52, U+6E5D, U+6E62, U+6E68, U+6E73, U+6E7B, U+6E7D, U+6E8D, U+6E99, U+6EA0, U+6EAD-6EAE, U+6EB3, U+6EBB, U+6EC0, U+6EC8, U+6ECD, U+6ECF, U+6EED-6EEE, U+6EFD, U+6F04, U+6F08, U+6F0D, U+6F16, U+6F1B, U+6F26, U+6F29, U+6F2D, U+6F30, U+6F3B, U+6F4F, U+6F53, U+6F5D, U+6F6C, U+6F83, U+6F87, U+6F8B, U+6F92-6F93, U+6F9A, U+6FA6, U+6FA8, U+6FAE, U+6FB0, U+6FC5, U+6FE8, U+6FFD, U+700D, U+7017, U+7020, U+702F, U+7034, U+7037, U+7044, U+7048-7049, U+7055, U+7094, U+7096, U+7098, U+709B, U+70B0, U+70B4, U+70D5-70D6, U+70FA, U+7105, U+710B, U+712D, U+7138, U+7141, U+7145, U+714B, U+7157, U+715A, U+7179, U+718C, U+719A, U+71B0, U+71B2, U+71BF-71C0, U+71CC, U+71D6, U+71DA, U+71F8, U+7208-7209, U+7213, U+7217, U+721A, U+721D, U+721F, U+7224, U+722F, U+7241, U+7243, U+7245, U+724E-7250;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium073.woff2") format("woff2");
  unicode-range: U+725A, U+725C, U+725E, U+7260, U+7268, U+726B, U+7271, U+7277, U+727B-727C, U+7284, U+7289, U+7293, U+72A8, U+72B4, U+72C7, U+72C9, U+72D5-72D6, U+72D8, U+72DF, U+72E5, U+72F4, U+72FE, U+7302, U+7304-7305, U+730B, U+730D, U+7313, U+731E, U+7322, U+7332, U+7335, U+733A-733B, U+7343, U+734D, U+7356, U+7358, U+735D-7360, U+7367, U+7369, U+7379, U+7380, U+7383, U+7386, U+738E, U+7390, U+7393, U+7397, U+73AA, U+73AD, U+73C6, U+73CC, U+73D3, U+73DD, U+73E6, U+73F4, U+73F7, U+73FB, U+73FD, U+73FF-7400, U+7404, U+7411, U+742D, U+7451, U+7466-7468, U+746E, U+7472, U+7480, U+748F, U+7491, U+749A, U+74AE-74AF, U+74B1-74B2, U+74CC, U+74D0, U+74D3, U+74DB, U+74DF, U+74E4, U+74E8, U+74EA, U+74F4, U+74FB-74FC, U+7506, U+7512, U+7516, U+7521, U+7527, U+7529, U+7536, U+7539, U+753F, U+7543, U+7547, U+7557, U+755E-755F, U+7561, U+757B-757C, U+7585, U+7590, U+7593, U+7595, U+7599, U+759C, U+75A4, U+75B4, U+75BA, U+75C1, U+75C4, U+75CC, U+75D7, U+75DC, U+75E1, U+75EF, U+7604, U+760A, U+760C, U+7612, U+761D, U+7623, U+7629, U+7632, U+7638-763A, U+7640, U+7644-7645, U+764A, U+7659, U+765F, U+7674, U+7685, U+768C-768D, U+769F, U+76A2-76A5, U+76AD, U+76BD, U+76C1, U+76CB, U+76D9, U+76E0, U+76F0, U+76F6, U+76F9, U+7700, U+7706, U+770E, U+7712, U+7714-7715, U+771C, U+7722, U+7728, U+772E-772F, U+7739, U+773D-773E, U+7742, U+7745, U+774A, U+774F, U+7752, U+7756-7757, U+775E, U+7764, U+7767, U+776C, U+7770, U+7773-7774, U+7784, U+778C-778D, U+7795-7796, U+77A7;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium074.woff2") format("woff2");
  unicode-range: U+77AE-77AF, U+77B1, U+77B5, U+77BE, U+77C3, U+77C9, U+77D1-77D2, U+77D5, U+77D9, U+77F1, U+77F8, U+780E, U+7811, U+781D, U+7822-7823, U+782E, U+7830, U+7835, U+7837, U+7844, U+7848, U+784C, U+7852, U+785C, U+785E, U+7860-7861, U+7863, U+787E, U+788F, U+7898, U+78A1, U+78A8, U+78AC, U+78B1-78B3, U+78BD, U+78BF, U+78C7, U+78C9, U+78D2-78D3, U+78D6, U+78DB, U+78DF, U+78EA, U+78F3, U+78F6, U+78FF, U+7906, U+790C, U+791A, U+791E-7920, U+7927, U+7929, U+792D, U+7935, U+793D, U+793F, U+7944, U+794B, U+794F, U+7951, U+7954, U+7969, U+796B, U+797B-797C, U+797E, U+798B-798C, U+7991, U+7993, U+799C, U+79A8, U+79AB, U+79AF, U+79C4, U+79CF, U+79DD, U+79E0, U+79E2, U+79E5, U+79EA, U+79F1, U+79F8, U+79FC, U+7A02, U+7A07, U+7A09, U+7A0C, U+7A21, U+7A27, U+7A2B, U+7A2F-7A30, U+7A34-7A35, U+7A3A, U+7A44-7A45, U+7A48, U+7A55, U+7A65, U+7A7E, U+7A80, U+7A86, U+7A8B, U+7A91, U+7A94, U+7A9E, U+7AB5, U+7AC6, U+7AC9, U+7ADB, U+7AE9, U+7AEC, U+7AF1, U+7AFB, U+7AFE, U+7B14, U+7B1F, U+7B23, U+7B29, U+7B2B, U+7B30, U+7B34, U+7B3F-7B40, U+7B6A, U+7B77, U+7B84, U+7B89, U+7B8E, U+7B96, U+7BA0, U+7BA5, U+7BAC, U+7BB0, U+7BB2, U+7BB6, U+7BBA-7BBB, U+7BBD, U+7BC2, U+7BC8, U+7BDB, U+7BE8, U+7BF2, U+7BF4-7BF5, U+7BF8-7BFA, U+7BFC, U+7BFE, U+7C02, U+7C04, U+7C06, U+7C09, U+7C0C, U+7C19, U+7C1B, U+7C25, U+7C28, U+7C2C, U+7C34, U+7C39-7C3A, U+7C46, U+7C52-7C53, U+7C55, U+7C5A-7C5D, U+7C63, U+7C67, U+7C72, U+7C7C-7C7D, U+7C86-7C87, U+7C9E, U+7CB0, U+7CBA-7CBB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium075.woff2") format("woff2");
  unicode-range: U+2EB1, U+7CC7, U+7CC9, U+7CCF, U+7CD3-7CD4, U+7CDA, U+7CE9, U+7D0F, U+7D11, U+7D16, U+7D26, U+7D2A, U+7D2D, U+7D3C, U+7D4D, U+7D51, U+7D57, U+7D5D, U+7D65, U+7D67, U+7D78, U+7D7B, U+7D81-7D82, U+7D85, U+7D8D, U+7D91, U+7D96, U+7D9E, U+7DB3, U+7DB9, U+7DC3-7DC6, U+7DCD-7DCE, U+7DE2, U+7DE5, U+7DEA-7DEB, U+7DED, U+7DF5-7DF6, U+7DFA, U+7E00, U+7E1C, U+7E2D, U+7E2F, U+7E33, U+7E36, U+7E3F, U+7E44, U+7E4E, U+7E50, U+7E58, U+7E5F, U+7E62, U+7E65, U+7E6E-7E6F, U+7E78, U+7E81, U+7E95, U+7E9D-7E9E, U+7F3D, U+7F3F, U+7F53, U+7F5B-7F5D, U+7F65-7F66, U+7F71, U+7F7F-7F80, U+7F8B, U+7F8D, U+7F8F, U+7FA2, U+7FA6, U+7FAA, U+7FB4, U+7FBC, U+7FC0, U+7FC8, U+7FE8, U+7FFD-7FFF, U+8007, U+800D, U+800F, U+8013, U+801D-8020, U+802E, U+8034, U+8039-803A, U+803E, U+8040, U+8044, U+8060, U+8064, U+806D, U+8081, U+8088, U+808E, U+809E, U+80A6, U+80AB, U+80B9, U+80C8, U+80CD, U+80CF, U+80D2, U+80D4, U+80ED-80EE, U+80F0, U+80F2, U+80F6, U+80F9-80FA, U+80FE, U+8103, U+810B, U+8117, U+811C, U+8120, U+8130, U+813C, U+8145, U+8147, U+814C, U+8152, U+8157, U+8161, U+816F, U+8177, U+8186, U+818B, U+818E, U+8190, U+8196, U+819B, U+819E, U+81A2, U+81AE, U+81B4, U+81C5, U+81CB, U+81CE, U+81D5, U+81DD-81DE, U+81E1, U+81EB, U+81F0-81F2, U+81F5-81F6, U+81F8-81F9, U+8200, U+8203, U+820F, U+8213-8214, U+821A, U+821D, U+8228, U+823A, U+8243-8244, U+824E, U+8251, U+8256, U+8267, U+826D, U+827B, U+8280-8281, U+8284, U+8287, U+8289, U+8291, U+8294, U+8296, U+8298, U+829A-829B, U+82A0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium076.woff2") format("woff2");
  unicode-range: U+82AA, U+82B0, U+82D0, U+82DA, U+82E0, U+82E4, U+82EA, U+82ED, U+82EF, U+82F6, U+830A-830B, U+831E-831F, U+8321, U+832C, U+832E, U+8333, U+8337, U+833D, U+8342, U+834D-834E, U+8353, U+8356, U+8370, U+8378, U+8380, U+8382, U+8384, U+8399, U+839C, U+83A6, U+83AC-83AD, U+83BE, U+83E8, U+83F8, U+83FC, U+8419, U+842F, U+8445, U+8447, U+844D, U+8456, U+8458, U+8460, U+8464, U+8467, U+846A, U+8474, U+847D, U+8492-8493, U+8495, U+84A8-84AA, U+84B1, U+84BD, U+84C7-84C8, U+84CC, U+84F2, U+84F7, U+84FB, U+8502-8503, U+8507, U+850E, U+8510, U+851C, U+8522, U+8527, U+852A, U+8533, U+8536, U+853F, U+8546, U+8550, U+8552, U+8556, U+855C-855D, U+855F-8560, U+8579, U+8585, U+8589, U+858B, U+8598, U+85A0, U+85A5, U+85A7, U+85B4, U+85B6, U+85B8, U+85BD-85BF, U+85C2, U+85DA, U+85E0, U+85E8, U+85F3, U+85FC, U+860D-860E, U+8619, U+861B, U+8636, U+863A, U+863D, U+8642, U+8646, U+8652, U+8658-8659, U+865D, U+8660-8661, U+8663-8664, U+8669, U+8676, U+8688, U+8696, U+869A, U+86A1, U+86A6-86A7, U+86AD, U+86B4-86B5, U+86B7, U+86B9, U+86BF-86C0, U+86C5, U+86D2, U+86DA, U+86DC, U+86E0, U+86E5, U+86E7, U+8704, U+870F, U+8714, U+871E, U+8728, U+872E-872F, U+8732, U+8739, U+873C-873D, U+8745, U+874D, U+875D, U+8761, U+876F, U+877B, U+8783-8786, U+878C, U+8790, U+8795, U+8797-8799, U+879E, U+87A3, U+87AE, U+87B1, U+87BF, U+87C8-87CA, U+87D5, U+87D9-87DA, U+87DC, U+87E2, U+87E4, U+87F1, U+87F3, U+87F8, U+87FA, U+87FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium077.woff2") format("woff2");
  unicode-range: U+8809, U+8812, U+8818-881C, U+881E, U+882D, U+8830, U+8835, U+883A, U+8841, U+8843, U+8845, U+8848-8849, U+884B, U+884E, U+8851, U+8855-8856, U+885A, U+885C, U+8860, U+8871, U+8879, U+887B, U+8880, U+889A-889C, U+889F, U+88A8, U+88BA, U+88CB-88CD, U+88DE, U+88E7, U+88EF, U+88F7, U+8906, U+890D-890F, U+8915-8916, U+8920, U+8926, U+8928, U+8931, U+8935, U+893A, U+893E, U+8946, U+894F, U+8952, U+8957, U+895A-895C, U+8961, U+8963, U+896B, U+896E, U+8970, U+8973, U+8975, U+897A-897D, U+898D, U+8995, U+899B-899C, U+89A0, U+89A5, U+89B4-89B6, U+89BC, U+89D5, U+89D7, U+89E9, U+89ED, U+89F9, U+8A04-8A05, U+8A07, U+8A0F, U+8A1E, U+8A20, U+8A24, U+8A26, U+8A2B-8A2C, U+8A2F, U+8A3D, U+8A40, U+8A43, U+8A49, U+8A53, U+8A56-8A57, U+8A5C, U+8A65, U+8A67, U+8A76-8A77, U+8A7A-8A7B, U+8A7E-8A80, U+8A83, U+8A86, U+8A8B, U+8A8F, U+8A92, U+8A96-8A97, U+8A99, U+8A9F, U+8AA9, U+8AAF, U+8AB3, U+8AB6, U+8ABB, U+8AC3, U+8AC6, U+8AC8-8ACA, U+8AD1, U+8AD3-8AD5, U+8ADD, U+8AEC, U+8AF0, U+8AF5, U+8AFF, U+8B06, U+8B0B, U+8B11, U+8B1E, U+8B30, U+8B37, U+8B3C, U+8B42, U+8B44-8B45, U+8B48, U+8B4D, U+8B52, U+8B63, U+8B6D, U+8B78-8B79, U+8B7C, U+8B7E, U+8B84-8B85, U+8B8D, U+8B8F, U+8B9F, U+8C38, U+8C3E, U+8C4B, U+8C51, U+8C53, U+8C58-8C59, U+8C5B, U+8C5D, U+8C63-8C64, U+8C66, U+8C75-8C76, U+8C7B, U+8C7E, U+8C86-8C87, U+8C8B, U+8C90, U+8C9C, U+8CB9-8CBA, U+8CC5-8CC6, U+8CC9, U+8CCB, U+8CCF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium078.woff2") format("woff2");
  unicode-range: U+8CDD, U+8CE8, U+8CEC, U+8CEF, U+8CF2, U+8CF5, U+8CF7, U+8CFF, U+8D01, U+8D03, U+8D17, U+8D65, U+8D69, U+8D6E, U+8D7F, U+8D82, U+8D88, U+8D8D, U+8D90-8D91, U+8D9E-8DA0, U+8DAB-8DAC, U+8DB2, U+8DB5, U+8DB7, U+8DB9, U+8DBB-8DBC, U+8DC0, U+8DC5, U+8DC7, U+8DCA, U+8DD4-8DD5, U+8DE4-8DE5, U+8DE7, U+8DF0-8DF2, U+8DF4, U+8E01, U+8E04-8E05, U+8E0B, U+8E11, U+8E26, U+8E31, U+8E33, U+8E37-8E38, U+8E40-8E41, U+8E4D-8E4F, U+8E5B-8E5E, U+8E61, U+8E69, U+8E71, U+8E79-8E7A, U+8E82-8E83, U+8E89, U+8E90, U+8E92, U+8E95, U+8E9A-8E9B, U+8E9D, U+8EA2, U+8EA7, U+8EA9, U+8EAD, U+8EBA, U+8EC1, U+8EC3-8EC4, U+8EC7, U+8EDC, U+8EE8, U+8EED-8EEE, U+8EF0-8EF1, U+8EF7, U+8F02, U+8F07, U+8F0F-8F10, U+8F16, U+8F18, U+8F20-8F21, U+8F23, U+8F25, U+8F27-8F28, U+8F2C, U+8F2E, U+8F34-8F35, U+8F37, U+8F3A, U+8F41, U+8F43, U+8F47, U+8F4F, U+8F51-8F53, U+8F55, U+8F5D-8F5E, U+8F65, U+8F9D, U+8FA0-8FA1, U+8FA5, U+8FB8, U+8FBE, U+8FC0, U+8FCB, U+8FD0, U+8FD2, U+8FE3, U+8FFE, U+9004, U+9018, U+901B, U+9028-902A, U+902C, U+902F, U+9033-9034, U+903F, U+904C, U+905B, U+9062, U+9066, U+906C, U+9070, U+9074, U+9079, U+908B, U+908E, U+9098, U+90A0, U+90A5, U+90B2, U+90B4, U+90BD, U+90CC, U+90D2, U+90D5, U+90D8-90D9, U+90E5, U+90F0, U+9105, U+9108, U+910D, U+9110, U+9117, U+911A, U+9120, U+9125, U+9129, U+912E, U+9136, U+913C, U+9143, U+914F, U+9153, U+915A, U+9167, U+916D, U+917A-917B, U+9181, U+9186, U+918A, U+9191;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium079.woff2") format("woff2");
  unicode-range: U+9193-9195, U+9198, U+91A1, U+91A6, U+91AD, U+91B0, U+91BB, U+91BD, U+91BF, U+91C2, U+91C5, U+91D3-91D4, U+91D9, U+91E9-91EA, U+91EF-91F0, U+91F7, U+91F9, U+91FD, U+9200, U+9204-9205, U+9209, U+920C, U+9212-9213, U+9218, U+921C-921D, U+9223-9226, U+9228, U+922E-9230, U+9235-9236, U+923E, U+9246, U+924D, U+9258, U+925C-925D, U+9269, U+926E-9270, U+9275-9276, U+9279, U+927B, U+928A, U+928E, U+9292, U+92A0, U+92A4-92A5, U+92A8, U+92AF, U+92B6, U+92B8, U+92BA-92BD, U+92C1, U+92C3, U+92C5, U+92C7-92C8, U+92CD, U+92D8, U+92DC-92DD, U+92DF, U+92E1, U+92E3, U+92E8, U+92EC, U+92EE, U+92F0, U+9300, U+9308, U+9314, U+931C, U+9324, U+932A, U+9333-9334, U+9336-9337, U+9350, U+9352, U+9355, U+9358, U+935E, U+9367, U+9369, U+936D, U+936F, U+9374, U+9376, U+937A, U+937D, U+937F-9382, U+938A, U+938D, U+9392, U+9395, U+9398, U+93A6, U+93A8-93A9, U+93AB, U+93B4-93B6, U+93C4-93C5, U+93C9-93CD, U+93D3, U+93D9, U+93E6, U+93F7, U+93F9-93FA, U+9401-9402, U+9408, U+940D-940E, U+9415, U+941F, U+942E-942F, U+943D, U+943F, U+9443, U+944C, U+9455, U+9459, U+945C, U+945F, U+9461, U+9468, U+946E, U+9483-9484, U+957E, U+9584, U+9588, U+959D-959E, U+95A1, U+95BA, U+95BF, U+95C6, U+95C9, U+95D1-95D2, U+95D9, U+95DD, U+95DF-95E0, U+95E4, U+95E6, U+961E, U+9622, U+9624-9626, U+9631, U+9637-963A, U+963D, U+9652, U+9654, U+9656-9657, U+966E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium080.woff2") format("woff2");
  unicode-range: U+9674, U+967B-967C, U+967E-967F, U+9681, U+9683, U+9689, U+9691, U+9696, U+969F, U+96A6, U+96AE, U+96CA, U+96DF, U+96F1, U+96FA, U+9702-9703, U+9705, U+9709, U+971A-971B, U+971D, U+9721-9722, U+9728, U+9731, U+974A, U+9758, U+9763, U+9767, U+9776-9778, U+977B, U+977D, U+977F-9780, U+9789, U+9797, U+9799, U+979F, U+97AC, U+97B5-97B6, U+97B8-97B9, U+97BC, U+97BF, U+97C4-97C5, U+97C7, U+97CA, U+97CD-97CE, U+97D0, U+97D7, U+97DD, U+97E0, U+97E4, U+97EF, U+97F7-97F8, U+97FA, U+9807, U+9819, U+981C, U+9820, U+9826, U+982E-982F, U+9835, U+983E, U+9844, U+984A, U+9851, U+9862-9863, U+986A, U+98AE, U+98B4, U+98C5, U+98CC, U+98E6, U+98F6, U+9902, U+9907, U+9911, U+9915, U+991F, U+9922, U+9926, U+992B, U+9934-9935, U+9939, U+9947-9948, U+9954, U+9959, U+995B, U+995E, U+999B, U+999D, U+999F, U+99B0-99B2, U+99B5, U+99BA, U+99D3, U+99DA, U+99DC, U+99E7, U+99EA-99EC, U+99F4-99F5, U+99FD-99FE, U+9A04, U+9A0B-9A0C, U+9A10, U+9A1E, U+9A22-9A23, U+9A27, U+9A33, U+9A35, U+9A41, U+9A47, U+9A4B, U+9A51, U+9A54, U+9A56, U+9A5D, U+9AAA, U+9AAC, U+9AAE, U+9AB2, U+9AB4-9AB5, U+9ABB, U+9ABE-9ABF, U+9AC8, U+9AD7, U+9ADB, U+9AE4, U+9AE7, U+9AEC, U+9AF2-9AF3, U+9AF5, U+9AFA, U+9AFD, U+9AFF-9B01, U+9B04-9B05, U+9B09, U+9B0B, U+9B0D-9B0E, U+9B19, U+9B1B, U+9B26, U+9B34-9B35, U+9B37, U+9B39-9B3A, U+9B48, U+9B4C, U+9B55-9B57, U+9B5B, U+9B61, U+9B68, U+9B6A, U+9B6D-9B6E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium081.woff2") format("woff2");
  unicode-range: U+2E8B-2E8D, U+2E95, U+2EA2, U+2EA9, U+2EAC, U+2EAE, U+2EB3, U+2EB7, U+2EBC, U+2EC6, U+2ED7, U+2EDD, U+3405, U+3427, U+3488, U+353E, U+35DE, U+3614, U+38A1, U+38AD, U+39A4, U+39B8, U+3A5C, U+3A85, U+3AC4, U+3ACB, U+3AF3, U+3BF3, U+3D31, U+3D4E, U+3DCC, U+3E40, U+3E8A, U+3FB1, U+3FDC, U+4293, U+440C, U+457A, U+45BE, U+4610, U+46AE, U+471F, U+47E6, U+481E, U+4B38, U+4B7E, U+4C38, U+4CE1, U+4E37, U+4F2B, U+4FD3, U+9B78, U+9B7F-9B80, U+9B85-9B87, U+9B89, U+9B8B, U+9B8D, U+9B90, U+9B94, U+9B9A, U+9B9D, U+9BA9, U+9BB0, U+9BB7, U+9BBC, U+9BBF, U+9BC8, U+9BD0, U+9BD7, U+9BDF, U+9BF9, U+9BFF, U+9C02, U+9C0B, U+9C0F, U+9C11, U+9C1C, U+9C1E, U+9C26, U+9C28, U+9C35, U+9C3D, U+9C43-9C44, U+9C4E, U+9C56, U+9C5E, U+9C68, U+9C7B, U+9CF7, U+9CF9, U+9D1C, U+9D2F-9D30, U+9D33-9D34, U+9D3A, U+9D3C-9D3D, U+9D45, U+9D53-9D54, U+9D65, U+9D6A, U+9D7B, U+9D83, U+9D86, U+9D8E, U+9D92-9D93, U+9D95, U+9D97-9D98, U+9DAA, U+9DAE, U+9DB1, U+9DCA, U+9DD4-9DD5, U+9DDE, U+9DE5, U+9DE9, U+9DEB, U+9DEE, U+9DF0, U+9DF3, U+9DFE, U+9E0E, U+9E10-9E12, U+9E16, U+9E7A, U+9E82-9E84, U+9E87, U+9E8E-9E8F, U+9E96, U+9E98, U+9EAE, U+9EB0, U+9EB3, U+9EC6, U+9EC8, U+9ECB, U+9ED5, U+9EE4, U+9EEC-9EED, U+9EF0-9EF2, U+9EF5, U+9EF8, U+9F09, U+9F0F, U+9F11-9F12, U+9F14, U+9F16, U+9F1A-9F1B, U+9F1F, U+9F22, U+9F26, U+9F2A-9F2B, U+9F31-9F32, U+9F34, U+9F3C, U+9F3F, U+9F43-9F44, U+9F47, U+9F56, U+9F5A, U+9F5E, U+9F68, U+9F6E-9F6F, U+9F71, U+9F73, U+9F7A, U+9F7D, U+9F8F, U+9F91-9F92, U+9F96, U+9F9E, U+9FA1, U+9FA3, U+9FA5, U+25AD7, U+2626A, U+2D544;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium082.woff2") format("woff2");
  unicode-range: U+5077, U+50F0, U+5167, U+5174, U+5179, U+51D4, U+51DF, U+5226, U+522B, U+5389, U+53C1, U+53C4, U+5433, U+543F, U+544C, U+5527, U+55EC, U+55EE, U+55F1, U+55F8, U+5620, U+575F, U+57AE, U+586D, U+5898, U+591F, U+5939, U+59EC, U+5A1B, U+5B76, U+5B82, U+5C14, U+5C19, U+5C2A, U+5CE6, U+5DFF, U+5E99, U+5F3A, U+5F65, U+6164, U+617D, U+61E8, U+6231, U+6236, U+631B, U+65BE, U+65E3, U+66B3, U+6725, U+6735, U+6743, U+6780, U+67A4, U+67BE, U+67FA, U+6805, U+688E, U+6986, U+6A54, U+6AF8, U+6B72, U+6CAC, U+6D01, U+6E42, U+6E89, U+6EBC, U+6EDA-6EDB, U+6EE6, U+6F9D, U+6FB7, U+71F4, U+73BA, U+73E4, U+7522, U+764E, U+76EB, U+77B7, U+7928, U+7942, U+7A05, U+7ABD, U+7B53, U+7C2F, U+7C42, U+7C7B, U+7D55, U+7DD0, U+7E48, U+80F7, U+812B, U+8141, U+8173, U+81EF, U+820B, U+8346, U+835A, U+85F4, U+867D, U+8722, U+878E, U+886E, U+88C6, U+88E0, U+8A01, U+8AAA, U+8B0F, U+8C9F, U+8CD2, U+8D1C, U+8E75, U+8E77, U+8FA2, U+8FC8, U+90C9, U+92B3, U+93BB, U+95B1, U+95E8, U+9839, U+99E1, U+9A08, U+9ABD, U+9B1D, U+9B2C, U+9C61, U+9FB4, U+9FBC-9FC2, U+9FCC, U+F909, U+F91F, U+F95F, U+F983, U+F999-F99A, U+F9A2, U+F9C3, U+FA03, U+2008A, U+200B0, U+200F5, U+2053F, U+205B1, U+20611, U+206EC, U+207C8, U+2090E, U+20984, U+20A64, U+20D58, U+21A1A, U+22609, U+22E42, U+22FEB, U+237F1, U+23CBE, U+2404B, U+243D0, U+24E04, U+24FF2, U+2567F, U+25874, U+259CC, U+26222, U+26999, U+26C9E, U+279B4, U+27C3C, U+27D73, U+28CDD, U+28E17, U+28EF6, U+29D4B, U+2A2B2, U+2A9E6, U+2B746, U+2B751, U+2B753, U+2B75A, U+2B75C, U+2B765, U+2B776-2B777, U+2B77C, U+2B782, U+2B789, U+2B78B, U+2B78E, U+2B794, U+2B7AC, U+2F82C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium083.woff2") format("woff2");
  unicode-range: U+2B7AF, U+2B7BD, U+2B7C9, U+2B7CF, U+2B7D2, U+2B7D8, U+2B7F0, U+2B80D, U+2B817, U+2B81A, U+2E278, U+2E569, U+2E6EA, U+2F804, U+2F80F, U+2F815, U+2F81A, U+2F822, U+2F828, U+2F833, U+2F83F, U+2F846, U+2F852, U+2F862, U+2F86D, U+2F873, U+2F884, U+2F899-2F89A, U+2F8A6, U+2F8AC, U+2F8B2, U+2F8B6, U+2F8D3, U+2F8E1, U+2F8E5, U+2F8EA, U+2F8FC, U+2F903, U+2F90B, U+2F90F, U+2F91A, U+2F920-2F921, U+2F945, U+2F947, U+2F96C, U+2F995, U+2F9D0, U+2F9DE-2F9DF, U+2F9F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Medium;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Medium084.woff2") format("woff2");
  unicode-range: U+F860-F862, U+F87A, U+F87F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold001.woff2") format("woff2");
  unicode-range: U+2C, U+2E, U+3A-3B, U+41-5A, U+60-7A, U+C0-D6, U+D8-DD, U+E?, U+F1-F6, U+F8-FD, U+FF-113, U+116-122, U+124-126, U+128-12B, U+12E-13D, U+141-149, U+14B-14D, U+150-17E, U+1CD-1DC, U+1F5, U+1F8-1F9, U+1FD, U+237, U+251, U+254, U+259-25B, U+28C, U+300-301, U+1E3E-1E3F, U+1EBC-1EBD, U+201A, U+3001-3002, U+3042, U+3044, U+3046, U+3048, U+304A-3082, U+3084, U+3086, U+3088-308D, U+308F-3094, U+309A, U+30A1-30A4, U+30A6-30E6, U+30E8-30EC, U+30EF, U+30F2-30F4, U+30FC, U+31F7, U+FB00-FB04, U+FE10-FE12, U+FF0C, U+FF0E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold002.woff2") format("woff2");
  unicode-range: U+0-2B, U+2D, U+2F-39, U+3C-40, U+5B-5F, U+7B-7E, U+A0-BF, U+D7, U+DE-DF, U+F0, U+F7, U+FE, U+304, U+327, U+332, U+2011, U+2219, U+223C, U+FF21-FF3A, U+FF41-FF5A, U+FF61-FF9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold003.woff2") format("woff2");
  unicode-range: U+2BB-2BC, U+2003, U+2010, U+2015-2016, U+2018-2019, U+201C-201D, U+2020-2021, U+2025-2026, U+2030, U+2032-2033, U+203B, U+2103, U+212B, U+2190-2193, U+21D2, U+21D4, U+2200, U+2202-2203, U+2207-2208, U+220B, U+2212, U+221A, U+221D-221E, U+2220, U+2227-222C, U+2234-2235, U+223D, U+2252, U+2260-2261, U+2266-2267, U+226A-226B, U+2282-2283, U+2286-2287, U+22A5, U+2312, U+2329-232A, U+2500-2503, U+250C, U+250F-2510, U+2513-2514, U+2517-2518, U+251B-251D, U+2520, U+2523-2525, U+2528, U+252B-252C, U+252F-2530, U+2533-2534, U+2537-2538, U+253B-253C, U+253F, U+2542, U+254B, U+25A0-25A1, U+25B2-25B3, U+25BC-25BD, U+25C6-25C7, U+25CB, U+25CE-25CF, U+25EF, U+2605-2606, U+2640, U+2642, U+266A, U+266D, U+266F, U+3000, U+3003, U+3005-3015, U+301C, U+3041, U+3043, U+3045, U+3047, U+3049, U+3083, U+3085, U+3087, U+308E, U+309B-309E, U+30A5, U+30E7, U+30ED-30EE, U+30F0-30F1, U+30F5-30F6, U+30FB, U+30FD-30FE, U+4EDD, U+FF01, U+FF03-FF06, U+FF08-FF0B, U+FF0D, U+FF0F-FF20, U+FF3B, U+FF3D-FF40, U+FF5B-FF5E, U+FFE3, U+FFE5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold004.woff2") format("woff2");
  unicode-range: U+391-3A1, U+3A3-3A9, U+3B1-3C1, U+3C3-3C9, U+401, U+410-44F, U+451, U+3303, U+330D, U+3314, U+3318, U+3322-3323, U+3326-3327, U+332B, U+3336, U+333B, U+3349-334A, U+334D, U+3351, U+3357, U+337B-337E, U+338E-338F, U+339C-339E, U+33A1, U+33C4, U+33CB, U+33CD, U+FE45-FE46, U+FF02, U+FF07, U+FF3C, U+FF5F-FF60, U+FFE0-FFE2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold005.woff2") format("woff2");
  unicode-range: U+25B1, U+25B6-25B7, U+25C0-25C1, U+25C9, U+25D0-25D3, U+25E6, U+2600-2603, U+260E, U+2616-2617, U+261E, U+2660-2669, U+266B-266C, U+266E, U+2713, U+2756, U+2776-277F, U+2934-2935, U+29BF, U+29FA-29FB, U+3016-3019, U+301D, U+301F-3020, U+3033-3035, U+303B-303D, U+3095-3096, U+309F-30A0, U+30F7-30FA, U+30FF, U+31F0-31F6, U+31F8-31FF, U+3231-3232, U+3239, U+3251-325F, U+32A4-32A8, U+32B1-32BF, U+32D0-32E3, U+32E5, U+32E9, U+32EC-32ED, U+32FA, U+32FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold006.woff2") format("woff2");
  unicode-range: U+2C6, U+2DC, U+302-303, U+305-306, U+308, U+30B-30C, U+30F, U+318-31A, U+31C-320, U+324-325, U+329-32A, U+32C, U+32F-330, U+334, U+336, U+339-33D, U+361, U+3C2, U+1F70-1F73, U+2012-2014, U+2022, U+203C, U+203E-203F, U+2042, U+2047-2049, U+2051, U+20AC, U+210F, U+2113, U+2116, U+2121, U+2127, U+2135, U+2153-2155, U+2160-216B, U+2170-217B, U+2194, U+2196-2199, U+21C4, U+21E6-21E9, U+2205, U+2209, U+2213, U+221F, U+2225-2226, U+222E, U+2243, U+2245, U+2248, U+2262, U+2276-2277, U+2284-2285, U+228A-228B, U+2295-2297, U+22BF, U+22DA-22DB, U+2305-2306, U+2318, U+23BE-23CC, U+23CE, U+2423, U+2460-2473, U+24D0-24E9, U+24EB-24FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold007.woff2") format("woff2");
  unicode-range: U+127, U+13E, U+193, U+1C2, U+250, U+252-253, U+255-258, U+25C, U+25E-261, U+264-268, U+26C-273, U+275, U+279-27B, U+27D-27E, U+281-284, U+288-28B, U+28D-28E, U+290-292, U+294-295, U+298, U+29D, U+2A1-2A2, U+2C7-2C8, U+2CC, U+2D0-2D1, U+2D8-2D9, U+2DB, U+2DD-2DE, U+2E5-2E9, U+FD6, U+213B, U+2150-2152, U+2156-215B, U+2189, U+2211, U+2474-247F, U+2488-2492, U+24B9, U+24C8, U+26BE, U+26CB, U+27A1, U+2B05-2B07, U+2B95, U+3036, U+322A-3230, U+3233, U+3236-3237, U+3244, U+328B, U+3299, U+3371, U+3390, U+33A0, U+33A2, U+33A4-33A5, U+1F100, U+1F110-1F129, U+1F131, U+1F13D, U+1F13F, U+1F142, U+1F146, U+1F157, U+1F15F, U+1F179, U+1F17B-1F17C, U+1F17F, U+1F202, U+1F237;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold008.woff2") format("woff2");
  unicode-range: U+205A, U+205D, U+3300-3302, U+3304-330C, U+330E-3313, U+3315-3317, U+3319-3321, U+3324-3325, U+3328-332A, U+332D-3335, U+3337-333A, U+333C-3348, U+334B-334C, U+334E-3350, U+3352-3356, U+337F, U+3385-3389, U+338D, U+3396-3398, U+339B, U+339F, U+33A3, U+33A6, U+33B0-33B3, U+33C2, U+33C8, U+33CC, U+33D4, U+33D7-33D8, U+33DA, U+A7B5, U+FE17-FE19, U+FE30-FE33, U+FE35-FE44, U+FE47-FE48, U+FFE4, U+FFE8, U+1F130, U+1F132-1F13C, U+1F13E, U+1F140-1F141, U+1F143-1F145, U+1F147-1F149, U+1F150-1F156, U+1F158-1F15E, U+1F160-1F169, U+1F170-1F178, U+1F17A, U+1F17D-1F17E, U+1F180-1F189;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold009.woff2") format("woff2");
  unicode-range: U+2581-258F, U+2594-2595, U+25A2, U+25AA-25AB, U+25CA, U+25CC, U+25E2-25E5, U+25FB-25FC, U+261C-261D, U+261F, U+2672-267D, U+26A0, U+26AA-26AB, U+26BD, U+2702, U+271A, U+273F-2740, U+27BF, U+2B1A, U+2B25-2B26, U+2B29, U+2B60-2B65, U+2B82-2B83, U+2E40, U+3004, U+301E, U+3030, U+3099, U+319?, U+3220-3229, U+3234-3235, U+3238, U+323A-3243, U+3280-328A, U+328C-3298, U+329A-32A3, U+32A9-32B0, U+32E4, U+32E6-32E8, U+32EA-32EB, U+32EE-32F9, U+32FB-32FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold010.woff2") format("woff2");
  unicode-range: U+2002, U+201E, U+2039-203A, U+2044, U+2070, U+2074-2079, U+207F-2089, U+20DD-20DE, U+2100, U+2105, U+2109-210A, U+2122, U+2126, U+212E, U+215C-215E, U+217F, U+21C5-21C6, U+21CB-21CC, U+21D0, U+21F5, U+2206, U+220A, U+220F, U+222D, U+2264-2265, U+2272-2273, U+2298, U+229E, U+22A0, U+22C4, U+2307, U+239B-23AD, U+23B0-23B1, U+2480-2487, U+249C-24B8, U+24BA-24C7, U+24C9-24CF, U+24EA, U+24FF, U+2504-250B, U+250D-250E, U+2511-2512, U+2515-2516, U+2519-251A, U+251E-251F, U+2521-2522, U+2526-2527, U+2529-252A, U+252D-252E, U+2531-2532, U+2535-2536, U+2539-253A, U+253D-253E, U+2540-2541, U+2543-254A, U+2550, U+255E, U+2561, U+256A, U+256D-2573;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold011.woff2") format("woff2");
  unicode-range: U+13F-140, U+14A, U+192, U+262-263, U+26A, U+274, U+276, U+278, U+280, U+28F, U+299, U+29C, U+29F, U+2B0, U+2B2, U+2B7, U+2C1, U+2DA, U+2E0-2E1, U+307, U+30A, U+328, U+384-386, U+388-38A, U+38C, U+38E-390, U+3AA-3B0, U+3CA-3CE, U+3D0-3D1, U+3D5, U+3DB, U+402-40C, U+40E-40F, U+452-45C, U+45E-45F, U+AB53;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold012.woff2") format("woff2");
  unicode-range: U+2EA0, U+2ED1, U+2F00, U+2F06, U+2F08, U+2F0A-2F0B, U+2F12, U+2F17, U+2F1D, U+2F24-2F26, U+2F29, U+2F2D, U+2F3C, U+2F3F, U+2F42, U+2F45, U+2F47, U+2F49-2F4A, U+2F52, U+2F54, U+2F63-2F65, U+2F6C, U+2F74, U+2F83, U+2F8F, U+2F92, U+2F94, U+2F9D, U+2FA6-2FA7, U+2FAF, U+2FB7, U+2FBC, U+4E00, U+4E09-4E0B, U+4E0D, U+4E16, U+4E2D, U+4E3B, U+4E5D, U+4E8B-4E8C, U+4E94, U+4EBA, U+4ECA, U+4EE3, U+4EE5, U+4F1A, U+4F53, U+4F55, U+4F5C, U+4F7F, U+4FE1, U+5148, U+5165, U+5168, U+516B-516D, U+5175, U+5185, U+51FA, U+5206-5207, U+524D, U+529B, U+52D5, U+5316, U+5341, U+539F, U+53D6, U+53E3, U+5408, U+540C-540D, U+5411, U+5473, U+548C, U+54E1, U+554F, U+56DB, U+56DE, U+56FD, U+5730, U+5831, U+5834, U+58F0, U+5909, U+5916, U+591A, U+5927, U+5929-592A, U+5973, U+5B50, U+5B66, U+5B89, U+5B9A, U+5B9F, U+5BB6, U+5BFE, U+5C0F, U+5C11, U+5C4B, U+5C71, U+5DDD, U+5E73-5E74, U+5EA6, U+5F15, U+5F37, U+5F53, U+5F7C, U+5F8C, U+5FC3, U+601D, U+6027, U+60C5, U+610F, U+611F, U+6210, U+6226, U+6240, U+624B, U+6301, U+653F, U+6559, U+6570, U+6587, U+65B0, U+65B9, U+65E5, U+660E, U+6642, U+66F8, U+6700, U+6708, U+6728, U+672C, U+6765, U+6771, U+696D, U+6A5F, U+6B21, U+6B63, U+6B7B, U+6C0F, U+6C11, U+6C17, U+6C34, U+6C7A, U+6CBB, U+6CD5, U+6D77, U+7121, U+7269, U+73FE, U+7406, U+751F, U+7528, U+7530, U+7537, U+753B, U+754C, U+767A, U+7684, U+76EE, U+76F8, U+771F, U+77E5, U+793E, U+795E, U+79C1, U+7ACB, U+7D4C, U+7D50, U+7F8E, U+8003, U+8005, U+805E, U+81EA, U+884C, U+885B, U+8868, U+8981, U+898B, U+89AA, U+8A00, U+8A18, U+8A71, U+8A9E, U+8EAB, U+8ECD, U+8FD1, U+901A, U+9023, U+9053, U+90CE, U+90E8, U+91CD-91CE, U+91D1, U+9577, U+958B, U+9593, U+95A2, U+96C6, U+9762, U+9854, U+98DF, U+9AD8;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold013.woff2") format("woff2");
  unicode-range: U+2E9F, U+2EC4, U+2F1F-2F20, U+2F3E, U+2F40, U+2F57, U+2F69, U+2F6F, U+2F76, U+2F8A, U+2F9C, U+2F9E, U+2FA8, U+2FB3, U+2FB5, U+2FB8, U+2FBA, U+4E03, U+4E07, U+4E21, U+4E57, U+4E89, U+4E95, U+4EA4, U+4EAC, U+4ED5-4ED6, U+4ED8, U+4EF6, U+4F1D, U+4F4F, U+4F9B, U+4FC2, U+4FDD, U+5143, U+5149, U+515A, U+5171, U+5186, U+521D, U+5224-5225, U+5229, U+5236, U+52A0, U+52A9, U+52D9, U+52DD, U+52E2, U+5317, U+5343, U+534A, U+53CD, U+53D7, U+53E4, U+53F0, U+53F3, U+5409, U+544A, U+547C-547D, U+54C1, U+56E3, U+571F, U+5728, U+57CE, U+58EB, U+58F2, U+591C, U+592B, U+597D, U+59CB, U+5B58, U+5B98, U+5BA4, U+5CF6, U+5E02, U+5E2B, U+5E30, U+5E38, U+5E83, U+5E97, U+5F0F, U+5F35, U+5F62, U+5F79, U+5F85, U+5F97, U+5FA1, U+5FC5, U+5FDC, U+60AA, U+60F3, U+611B, U+6238, U+6253, U+6307, U+652F, U+653E, U+6599, U+65AD, U+65CF, U+65E9, U+6620, U+6709, U+671D, U+671F, U+6751, U+677E, U+679C, U+6821, U+683C, U+697D, U+69D8, U+6A29, U+6B66, U+6B69, U+6B73, U+6B8B, U+6BBA, U+6BCD, U+6C5F, U+6D3B, U+6D41, U+6DF1, U+6E08, U+70B9, U+7136, U+7236, U+7279, U+72B6, U+7523, U+7531, U+753A, U+756A, U+75C5, U+767D-767E, U+76F4, U+7740, U+77F3, U+78BA, U+7A7A, U+7B11, U+7B2C, U+7C73, U+7D04, U+7D42, U+7D44, U+7D9A, U+7DCF, U+7DDA, U+7F6E, U+7FA9, U+80FD, U+8272, U+82E5, U+843D, U+8449, U+85E4, U+8853, U+897F, U+8996, U+89E3, U+8A08, U+8A2D, U+8AAC-8AAD, U+8ABF, U+8AD6, U+8B70, U+8CB7, U+8CC7, U+8CEA, U+8D77, U+8DB3, U+8ECA, U+8FBC, U+8FD4, U+9001, U+9032, U+904B, U+904E, U+9055, U+9078, U+90FD, U+914D, U+9580, U+9662, U+969B, U+96FB, U+97F3, U+982D, U+984C, U+98A8, U+9996, U+99AC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold014.woff2") format("woff2");
  unicode-range: U+2ED8, U+2F2F, U+2F4C, U+2F55, U+2F70, U+2F7C, U+2F8E, U+2F95, U+2F9A-2F9B, U+2FB6, U+2FB9, U+2FCA, U+4E0E, U+4E45, U+4E4B, U+4E88, U+4ECB, U+4EFB, U+4F01, U+4F0A, U+4F4D, U+4F50, U+4F8B, U+4FA1, U+4FFA, U+5074, U+5099, U+50CD, U+50CF, U+50D5, U+512A, U+518D, U+5199, U+5272, U+533B, U+5357-5358, U+53C2, U+53CB, U+53CE, U+53EF, U+53F2, U+53F7, U+541B, U+5546, U+55B6, U+5668, U+56F3, U+578B, U+57FA, U+5883, U+5897, U+5931, U+5965, U+59BB, U+59FF, U+5A5A, U+5B57, U+5B88, U+5B97, U+5BA2, U+5BAE, U+5BB3, U+5BB9, U+5BC4, U+5BDF, U+5BFA, U+5C06, U+5C40, U+5C45, U+5CA1, U+5DDE, U+5DE5-5DE6, U+5DEE, U+5DFB, U+5E9C, U+5EA7, U+5EFA, U+5F71, U+5FF5, U+6025, U+606F, U+614B, U+623B, U+6280, U+6295, U+629C, U+62BC, U+632F, U+6483, U+6539, U+65C5, U+6625, U+666F, U+671B, U+672B, U+6761, U+67FB, U+6839, U+6848, U+69CB, U+6A2A, U+6A4B, U+6B4C, U+6B62, U+6BB5, U+6C42, U+6CC9, U+6CE8, U+6D25, U+6D3E, U+6D6E, U+6D88, U+6E05, U+6E21, U+6E80, U+6E90, U+6F14, U+706B, U+71B1, U+72EC, U+738B, U+7403, U+7533, U+7570, U+7687, U+770C, U+773C, U+793A, U+798F, U+79D1, U+7A2E, U+7A81, U+7B49, U+7B54, U+7B56, U+7B97, U+7D00, U+7D19, U+7D20, U+7D30, U+7D71, U+7D76, U+8001, U+8077, U+80B2, U+80CC, U+8239, U+826F, U+82B1, U+82B8, U+82E6, U+82F1, U+8535, U+8840, U+899A, U+89B3, U+8A3C, U+8A8D, U+8AB0, U+8AC7, U+8B58, U+8B66, U+8B77, U+8C37, U+8C61, U+8CA0, U+8D64, U+8D70, U+8DEF, U+8EE2, U+8FFD, U+9020, U+9054, U+9060, U+9152, U+91CF, U+964D, U+9650, U+968A, U+968E, U+96E2-96E3, U+9752, U+9818, U+983C, U+98DB, U+9928, U+9999, U+9A13, U+9ED2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold015.woff2") format("woff2");
  unicode-range: U+2F50, U+2F5A, U+2F81, U+2F93, U+2FAE, U+2FB0, U+4E26, U+4E38, U+4E71, U+4ECF, U+4EF2, U+4F3C, U+4F4E, U+4F59, U+4FEE, U+500B, U+5012, U+5024, U+5065, U+5144, U+5177, U+51B7, U+51E6, U+52B4, U+52B9, U+533A, U+5354, U+5371, U+53BB, U+53F8, U+5404, U+5468, U+559C, U+56F2, U+5712, U+5742, U+57DF, U+5802, U+590F, U+5922, U+59D4, U+5A18, U+5A66, U+5B85, U+5BBF, U+5BC6, U+5BDD, U+5C0E, U+5C3E, U+5C55, U+5D0E, U+5E03, U+5E2D, U+5E2F, U+5E55, U+5E78, U+5E95, U+5EAD, U+5EB7, U+5F93, U+5FA9, U+5FB3, U+5FD7, U+6050, U+6211, U+623F, U+62B1, U+62C5, U+6319, U+63A2, U+63A5, U+63CF-63D0, U+63F4, U+653B, U+6545, U+6575, U+6577, U+661F, U+662D, U+6697, U+66AE, U+670D, U+672A, U+6750, U+6797, U+682A, U+68EE, U+691C, U+6975, U+6B74, U+6BBF, U+6BCE, U+6BD4, U+6C38, U+6CA2, U+6CB3, U+6CE2, U+6D0B, U+6E1B, U+6E29, U+6E96, U+6FC0, U+7247, U+72AF, U+7387, U+74B0, U+7565, U+7591, U+7642, U+767B, U+76E3, U+7701, U+7814, U+7834, U+79C0, U+79CB, U+79D8, U+79FB, U+7A0B, U+7A0E, U+7A4D, U+7A76, U+7AE0, U+7AEF, U+7AF6, U+7BA1, U+7CBE, U+7CFB, U+7D75, U+7DD2, U+7DE8, U+7E54, U+7FD2, U+8089, U+80F8, U+8170, U+8179, U+8208, U+821E, U+8336, U+8349, U+8457, U+85AC, U+8857, U+88C1, U+88C5, U+88CF, U+88FD, U+898F, U+89D2, U+8A2A, U+8A31, U+8A55, U+8A66, U+8A8C, U+8AF8, U+8CA1, U+8CBB, U+8CDE, U+8D8A, U+8EFD, U+8F09, U+8FBA, U+9000, U+9003, U+901F, U+9031, U+904A, U+907A, U+9244, U+9280, U+9332, U+9632, U+9678, U+967A, U+96A0, U+96D1, U+9759, U+975E, U+9769, U+97FF, U+9803, U+985E, U+98F2, U+9BAE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold016.woff2") format("woff2");
  unicode-range: U+2F11, U+2F23, U+2F51, U+2F5D-2F5F, U+2F75, U+2F7B, U+2F7F, U+2F82, U+2F90, U+2FA5, U+2FAC, U+2FBB, U+2FC3, U+2FC7, U+4E91, U+4EA1, U+4EE4, U+4F11, U+5009, U+50B7, U+5104, U+5150, U+5178, U+5200, U+520A, U+5211, U+5217, U+523A-523B, U+5263, U+5287, U+5348, U+535A, U+5370, U+53B3, U+53CA, U+542B, U+5438, U+5584, U+56E0, U+56F0, U+56FA, U+5727, U+58C1, U+5915, U+592E, U+5947-5948, U+5999, U+5ACC, U+5B87, U+5B8C, U+5BCC, U+5C02, U+5C04, U+5C64, U+5CA9, U+5CB8, U+5DE8, U+5EAB, U+5F01, U+5F1F, U+5F31, U+5F3E, U+5F66, U+5FAE, U+5FD8, U+5FE0, U+6012, U+604B, U+6075, U+60B2, U+6255, U+6297-6298, U+6355, U+6368, U+6388, U+639B, U+63A8, U+6557, U+6563, U+6574, U+65BD, U+6613, U+6628, U+666E, U+6674, U+66B4, U+66F2, U+66FF, U+675F, U+677F, U+67C4, U+67D3, U+690D, U+6A19, U+6A21, U+6A39, U+6B32, U+6BDB, U+6C60, U+6CC1, U+6D45, U+6E2F, U+6E6F, U+70BA, U+713C, U+7167, U+7248, U+72AC, U+7384, U+7389, U+7532, U+7559, U+75C7, U+75DB, U+76DB, U+7720, U+7763, U+77AC, U+77ED, U+793C, U+79F0, U+7A93, U+7AF9, U+7B4B, U+7BC0, U+7D14, U+7D1A, U+7D66, U+7D99, U+7E01, U+7F6A, U+7FBD, U+8033, U+8056, U+80A9, U+8131, U+8155, U+81E3, U+822C, U+83EF, U+8584, U+85E9, U+8846, U+8863, U+88AB, U+88DC, U+89E6, U+8A0A, U+8A0E, U+8A33, U+8AB2, U+8B1B, U+8C4A, U+8CAC, U+8CB4, U+8D85, U+8DE1, U+8E0F, U+8F2A, U+8FB2, U+8FCE, U+8FEB, U+8FF0, U+9006, U+9014, U+91CC, U+9589, U+95D8, U+962A, U+963F, U+9663-9664, U+967D, U+969C, U+96C4, U+96E8, U+96EA, U+9707, U+97D3, U+984D, U+9858, U+990A, U+99C6, U+9A5A, U+9AA8, U+9AEA, U+9CE5, U+9CF4, U+9EBB, U+9ED9, U+9F62;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold017.woff2") format("woff2");
  unicode-range: U+2EE4, U+2EE9, U+2EED, U+2F30, U+2F4B, U+2F62, U+2F6A, U+2F6E, U+2F84, U+2FC1-2FC2, U+2FC5, U+2FD0, U+4E01, U+4E08, U+4E5F, U+4E73, U+4E92, U+4EEE, U+4EF0, U+4F0F, U+4F38, U+4F9D, U+4FBF, U+500D, U+5019, U+501F, U+50BE, U+5100, U+5145, U+51AC, U+5247, U+5275, U+529F, U+52E4, U+5305, U+5360, U+539A, U+53E5, U+53EB, U+5410, U+5426, U+5439, U+543E, U+54F2, U+57F7, U+5800, U+58CA, U+5949, U+59C9, U+5B6B, U+5B9D, U+5BA3, U+5BE9, U+5C0A, U+5C31, U+5C4A, U+5C5E, U+5D29, U+5DF1, U+5E0C, U+5E1D, U+5E79, U+5E7C, U+5E81, U+5E8A, U+5EC3, U+5EF6, U+5F25, U+5F8B, U+5F92, U+5FB4, U+5FEB, U+6016, U+60A3, U+60D1, U+61B2, U+61B6, U+624D, U+6279, U+627F, U+62E0-62E1, U+635C, U+63A1, U+63DB, U+63E1, U+63FA, U+643A, U+64AE, U+64CD, U+6551, U+65E7, U+6614, U+663C, U+667A, U+66DC, U+66F4, U+6749, U+679A, U+679D, U+67F3, U+6804, U+6838, U+6B20, U+6B27, U+6B6F, U+6C88, U+6CB9, U+6CCA, U+6CE3, U+6D17, U+6D5C, U+6D66, U+6DF7, U+6E2C, U+6E7E, U+6EC5, U+6F22, U+6FC3, U+702C, U+7159, U+7206, U+7518, U+76AE, U+76CA, U+76D7, U+76DF, U+77E2, U+7802, U+7956, U+7981, U+7B46, U+7BC9, U+7C21, U+7D05, U+7D0D, U+7D39, U+7D61, U+7DAD, U+7DE0, U+7DF4, U+7E04, U+7E70, U+7FA4, U+7FCC, U+8074, U+8133, U+81F3-81F4, U+822A, U+8302, U+8352, U+8377, U+878D, U+88D5, U+8907, U+8972, U+8A34, U+8A69, U+8A70, U+8A98, U+8C6A, U+8CA9, U+8CC0, U+8F38, U+8F9E, U+8FF7, U+9045, U+9063, U+9069, U+907F, U+90A3, U+90F7, U+9178, U+91E3, U+9283, U+93E1, U+95A3, U+9670, U+96A3, U+96F2, U+9732, U+9806, U+98EF, U+99C5, U+9A12, U+9B3C, U+9B54, U+9B5A, U+9E7F, U+9EC4, U+9F3B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold018.woff2") format("woff2");
  unicode-range: U+2EC1, U+2EEF, U+2EF2, U+2F1C, U+2F32, U+2F5C, U+2F73, U+2F86, U+2F88, U+2F8D, U+2F96, U+2F9F, U+2FD3, U+4E39, U+4E43, U+4E80, U+4E86, U+4E9C, U+4EC1, U+4ED9, U+4F2F, U+4F34, U+4F8D, U+4FB5, U+4FCA, U+505C, U+50AC, U+50B5, U+50DA, U+50E7, U+5230, U+5238, U+526F, U+52C7, U+52D8, U+5352, U+5373, U+53C8, U+53CC, U+53E9, U+5442, U+5507, U+5510, U+5747, U+574A, U+57A3, U+57CB, U+585A, U+5869, U+596A, U+5974, U+5982, U+59B9, U+5A01, U+5AC1, U+5B63, U+5B99, U+5BD2, U+5BFF, U+5C0B, U+5C3B, U+5C3D, U+5C48, U+5DE1, U+5E33, U+5E45, U+5E72, U+5FB9, U+5FCD, U+602A, U+6065, U+609F, U+60A9, U+611A, U+6162-6163, U+616E, U+6176, U+61D0, U+61F8, U+6271, U+6291, U+62DB, U+62DD, U+63B2, U+63EE, U+640D, U+6458, U+656C, U+658E, U+65AC, U+65D7, U+65E6, U+6607, U+6669, U+66F9, U+672D, U+676F, U+6885, U+68D2, U+6BD2, U+6C57, U+6C5A, U+6C96, U+6CBC, U+6CBF, U+6D6A, U+6D74, U+6D99, U+6DB2, U+6E09, U+6E56, U+6F5C, U+6F6E, U+706F, U+707D, U+718A, U+71C3, U+725B, U+72C2, U+72D9, U+72ED, U+732B, U+7372, U+75B2, U+7686, U+76E4, U+770B, U+773A, U+7832, U+7968, U+796D, U+7A32, U+7A3F, U+7A74, U+7ADC, U+7AE5, U+7BB1, U+7BC4, U+7C60, U+7DCA, U+7E2E, U+7E3E, U+7E41, U+7F85, U+808C, U+80DE, U+8107, U+811A, U+819D, U+81D3, U+81ED, U+820C, U+820E, U+821F, U+8266, U+83DC, U+864E, U+866B, U+885D, U+888B, U+88C2, U+8A3A, U+8A73, U+8ACB, U+8B00, U+8B1D, U+8C46, U+8CA7-8CA8, U+8CB8, U+8DDD, U+8E8D, U+8ED2, U+8F1D, U+8F9B, U+9084, U+90F5, U+9154, U+91C8, U+91DD, U+9234, U+92AD, U+95C7, U+9686, U+96C5, U+970A, U+9802, U+9805, U+9830, U+98FE, U+99C4, U+9F8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold019.woff2") format("woff2");
  unicode-range: U+2EEB, U+2F77, U+4E18, U+4E7E, U+4EAD, U+4F73, U+4F75, U+4FC3, U+4FD7, U+507D, U+508D, U+5146, U+514D, U+517C, U+518A, U+524A, U+5264, U+5289, U+52AA, U+52C9, U+52DF, U+5302, U+5320, U+5353, U+5374, U+53D4, U+541E, U+54B2, U+54C0, U+552F, U+5531, U+5642, U+5653, U+5750, U+5857, U+5893, U+594F, U+5951, U+596E, U+59D3, U+59EB, U+5B09, U+5B54, U+5B5D, U+5B64, U+5B8F, U+5BDB, U+5C01, U+5C1A, U+5CF0, U+5D07, U+5E7B, U+5E7E, U+5ECA, U+5EFB, U+5F18, U+5F69, U+5F80-5F81, U+6069, U+6094, U+60A0, U+614E, U+618E, U+6212, U+629E, U+62B5, U+62D2, U+62F6, U+6311, U+6328, U+6392, U+6398, U+63A7, U+63DA, U+6469, U+654F, U+6589, U+65E2, U+660C, U+6696, U+670B, U+6717, U+6790, U+67D4, U+67F1, U+67F4, U+685C, U+68C4, U+6905, U+6982, U+6C37, U+6C99, U+6CA1, U+6CE5, U+6D69, U+6DE1, U+6DFB, U+6E0B, U+6ED1, U+6EDE, U+6F01, U+6FA4, U+6FE1, U+708E, U+70AD, U+70C8, U+719F, U+7235, U+7267, U+732E, U+7344, U+73CD, U+7551, U+7573, U+7709, U+786C, U+795D, U+7A42, U+7B20, U+7C4D, U+7C89, U+7CF8, U+7D1B, U+7D22, U+7DB1-7DB2, U+7DD1, U+7F72, U+8010, U+809D, U+80A1, U+80A5, U+8102, U+8105, U+8108, U+8150, U+81E8, U+829D, U+8358, U+83CA, U+85A9, U+865A, U+88F8, U+8986, U+8997, U+8A13, U+8A17, U+8A87, U+8A95, U+8AA0, U+8AA4, U+8CAB, U+8CBF, U+8CCA, U+8CDB, U+8CFC, U+8DA3, U+8E0A, U+8F03, U+8F29, U+900F, U+902E, U+9047, U+90A6, U+90AA, U+90B8, U+90E1, U+92ED, U+9685, U+96C7, U+9756, U+9808, U+9810, U+9867, U+98FC, U+99D0, U+9A0E, U+9B42, U+9B45, U+9DB4, U+9E97;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold020.woff2") format("woff2");
  unicode-range: U+2EE8, U+2F28, U+2F38, U+2F6B, U+2FA0, U+2FB4, U+4E1E, U+4FF3, U+502B, U+5049, U+5075-5076, U+511F, U+514B, U+5176, U+5192, U+51C4, U+51CD, U+51DD, U+5203, U+5237, U+525B, U+525D, U+52E7, U+5339, U+5375, U+53EC, U+5446, U+5449, U+545F, U+5589, U+558B, U+55AB, U+5606, U+5609, U+5674, U+5699, U+570F, U+5782, U+5805, U+5854, U+585E, U+58C7, U+58EE, U+5A46, U+5B22, U+5BC2, U+5BE7, U+5BF8, U+5C3A, U+5C90, U+5CB3, U+5D8B, U+5E3D, U+5E84, U+5E8F, U+5EB5, U+5EF7, U+5F13, U+5F6B, U+5FD9, U+6068, U+60DC, U+60E8, U+614C, U+6249, U+62CD, U+62ED, U+62FE, U+636E, U+6383, U+638C, U+63AA, U+63C3, U+6442, U+6451, U+64AB, U+659C, U+65E8, U+65EC, U+662F, U+664B, U+6676, U+6687, U+6691, U+6731, U+673A, U+674E, U+6762, U+67A0, U+6842, U+68B0, U+68DA, U+6B53, U+6B8A, U+6B96, U+6C41, U+6CF0, U+6CF3, U+6D29, U+6D44, U+6DAF, U+6DBC, U+6E9C, U+6EB6, U+6F02, U+6F0F, U+6F2B, U+6F70, U+6F84, U+7070, U+7126, U+716E, U+731B, U+7434, U+74F6, U+76BF, U+78E8, U+790E, U+7948, U+7950, U+7985, U+7A3C-7A3D, U+7A4F, U+7B52, U+7C92, U+7CA7, U+7CD6, U+7D0B, U+7D2B, U+7DBF, U+7DE9, U+7E1B, U+7E26, U+7F70, U+7FD4, U+7FFC, U+80C6, U+81A8, U+81B3, U+82B3, U+83CC, U+846C, U+8650, U+8896, U+89A7, U+8A5E, U+8A89, U+8B0E, U+8B72, U+8C8C, U+8C9E, U+8CAF, U+8CC3, U+8CE2, U+8D08, U+8DF3, U+8E74, U+8EB0, U+8FB0, U+9019, U+9038, U+9042, U+906D, U+9177, U+9298, U+934B, U+9375, U+938C, U+9396, U+93AE, U+9451, U+9665, U+968F, U+96F7, U+9700, U+9727, U+9774, U+9801, U+9811, U+9837, U+9855, U+99D2, U+9DF9, U+9EA6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold021.woff2") format("woff2");
  unicode-range: U+2F56, U+2F7A, U+2FCE, U+4E4F, U+4F0D, U+504F, U+5098, U+5132, U+5197, U+51A0, U+51E1, U+51F6, U+52A3, U+52B1, U+5351, U+540E, U+54C9, U+5553, U+55A7, U+5629, U+572D, U+582A, U+584A, U+587E, U+5937, U+5983, U+598A, U+5BB0, U+5BB4, U+5C09, U+5C3F, U+5C65, U+5CE0, U+5D50, U+5DE3, U+5DE7, U+5E06, U+5E61, U+5E7D, U+5EB6, U+5F90, U+6052, U+60A6, U+60DA, U+6109, U+6170, U+6182, U+622F, U+62AB, U+62BD, U+62C9, U+62D3, U+62D9, U+62EC, U+631F, U+633F, U+6414, U+64A4, U+64B2, U+64E6, U+65BC, U+66A6, U+66FE, U+6795, U+67B6, U+6817, U+6843, U+6850, U+68A8, U+68CB, U+68DF, U+69CD, U+6B64, U+6BB4, U+6D1E, U+6D32, U+6D78, U+6DF3, U+6DF5, U+6E67, U+6E7F, U+6EDD, U+6F20, U+6F54, U+6F5F, U+6F64, U+7089, U+722A, U+723A, U+7272, U+72A0, U+72E9, U+72FC, U+732A, U+733F, U+7363, U+73E0, U+73ED, U+751A, U+75BE, U+7656, U+76C6, U+76FE, U+7761, U+7768, U+78C1, U+7949, U+7965, U+7984, U+79D2, U+79E9, U+7A1A, U+7AAE, U+7B48, U+7BC7, U+7BE0, U+7C3F, U+7C8B, U+7C97-7C98, U+7CE7, U+7D17, U+7D5E, U+7DBE, U+7E6B, U+7F8A, U+7FFB, U+8015, U+80C3, U+80E1, U+816B, U+8178, U+819A, U+819C, U+8210, U+8217, U+828B, U+82BD, U+82D7, U+82DB, U+83D3, U+845B, U+84B8, U+84BC, U+84C4, U+84CB, U+8526, U+85DD, U+8607, U+862D, U+86C7, U+86EE, U+8776, U+8870, U+88FE, U+8A93, U+8B19, U+8CA2, U+8CB0, U+8CBC, U+8CED, U+8EC0, U+8EF8, U+8F14, U+8FB1, U+90C1, U+90CA, U+9262, U+9271, U+9285, U+932F, U+9640, U+965B, U+9673, U+9676, U+9694, U+984E, U+99B4, U+99D5, U+99FF, U+9B4F, U+9D28, U+9F13;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold022.woff2") format("woff2");
  unicode-range: U+2F04, U+2F61, U+2F6D, U+2F78, U+2F99, U+4E59, U+4E5E, U+4EAE, U+4F10, U+5091, U+5270, U+52C3, U+537F, U+5384, U+53E1, U+53F1, U+540A, U+541F, U+5448, U+546A, U+5504, U+553E, U+559A, U+55AA, U+55B0, U+564C, U+56C1, U+576A, U+57F9, U+5824, U+583A, U+5840-5841, U+58A8, U+58FA, U+5954, U+5996, U+5A20, U+5B8B, U+5B9B, U+5BEE, U+5C16, U+5CE1, U+5D16, U+5E4C, U+5E63, U+5F26, U+5F70, U+5F84, U+5FAA, U+6020, U+602F, U+606D, U+6070, U+60E3, U+6148, U+61A4, U+61C7, U+61F2, U+6216, U+621A, U+6247, U+628A, U+62D8, U+62F3, U+6349, U+63C9, U+64C1, U+6562, U+6566, U+65CB, U+6602, U+6606, U+6627, U+6643, U+6681, U+6734, U+67AF, U+6851, U+6881, U+68B6, U+68F2, U+690E, U+697C, U+6A3D, U+6B04, U+6B3A, U+6B6A, U+6C70, U+6CE1, U+6D1B, U+6DEB, U+6E26, U+6E9D, U+6EA2, U+6EF4, U+6F2C, U+6F31, U+6FC1, U+6FEF, U+7261-7262, U+731F, U+74E6, U+755C, U+758E, U+75AB, U+75D5, U+75F4, U+7652, U+77B3, U+77DB, U+77E9, U+7815, U+7881, U+7940, U+79E6, U+7ABA, U+7B1B, U+7B26, U+7B87, U+7BB8, U+7D10, U+7D33, U+7D79, U+7DBA, U+7DEF, U+7E2B, U+7E4A, U+7F36, U+7FC1, U+80AA, U+80BA, U+80F4, U+817F, U+8276, U+83C5, U+83E9, U+83F1, U+84B2, U+84EE, U+85CD, U+865C, U+8700, U+8861, U+8881, U+895F, U+8987, U+8A1F, U+8AE6, U+8B21, U+8B5C, U+8C5A, U+8C9D, U+8D99, U+8ECC, U+8EDF, U+8FBB, U+9022, U+904D, U+90ED, U+9175, U+919C, U+920D, U+9326, U+935B, U+9418, U+95A5, U+963B, U+9644, U+9675, U+9699, U+96C0, U+96F0, U+983B, U+98E2, U+98FD, U+9905, U+99B3, U+99C8, U+9AD9, U+9B31, U+9B8E, U+9D8F, U+9EBF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold023.woff2") format("woff2");
  unicode-range: U+2E92, U+2F31, U+2F43, U+2FA9, U+4EAB, U+4EC7, U+4F0E, U+4F3A, U+4F46, U+4FAF, U+4FF5, U+502D, U+50B3, U+5112, U+514E, U+5208, U+52C5, U+52F2, U+53EA, U+547B, U+54B3, U+5538, U+5598, U+559D, U+55C5, U+55E3, U+56DA, U+570B, U+57FC, U+5835, U+5875, U+58B3, U+58CC, U+5968, U+59A5, U+59A8, U+59AC, U+59BE, U+59D1, U+59DC, U+5A92, U+5AC9, U+5B5F, U+5B9C, U+5C3C, U+5CAC, U+5DF3, U+5DFE, U+5E25, U+5E87, U+5F04, U+5FCC, U+6028, U+60F9, U+6115, U+6155, U+61A7, U+6234, U+6276, U+6367, U+642D, U+64AD, U+6590, U+6597, U+66AB, U+66C7, U+66D6, U+66FD, U+6756, U+67A2, U+67CF-67D0, U+67FF, U+683D, U+6876, U+689D, U+695A, U+6BBB, U+6BC5, U+6C72, U+6C7D, U+6CB8, U+6DC0, U+6E07, U+6ECB, U+6EF2, U+6F06, U+6F15, U+708A, U+70CF, U+711A, U+71C8, U+71E5, U+71ED, U+723D, U+72D0, U+74A7, U+75FA, U+7626, U+76BA, U+76C3, U+7891, U+7897, U+78EF, U+79BF, U+7A00, U+7A9F, U+7AAA, U+7AAF, U+7AFF, U+7B39, U+7B51, U+7BE4, U+7C9B, U+7D3A, U+7DB4, U+7DBB, U+7F75, U+8096, U+8098, U+80A2, U+80AF, U+80CE, U+82AF, U+82D1, U+830E, U+83AB, U+8429, U+8463, U+8499, U+8511, U+852D, U+854E, U+85A6, U+85AB, U+8702, U+871C, U+8912, U+8956, U+8A02, U+8A50, U+8A60, U+8A63, U+8A6B, U+8A6E, U+8ACF, U+8AED, U+8AFE, U+8B83, U+8B90, U+8CD1, U+8CE0, U+8D05, U+8D66, U+8D74, U+8DF5, U+8FBF, U+906E, U+9077, U+912D, U+914C, U+916C, U+9192, U+91DC, U+925B, U+92FC, U+9320, U+9591, U+961C, U+9688, U+96B7, U+96BB, U+96F6, U+971E, U+9813, U+990C, U+9A19, U+9A30, U+9AB8, U+9AED, U+9B6F, U+9CE9, U+9D5C, U+9E93, U+20B9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold024.woff2") format("woff2");
  unicode-range: U+2F5B, U+2F60, U+2FCF, U+4E14, U+4E3C, U+4ED4, U+4F3D, U+4F51, U+4FAE, U+4FB6, U+4FE3, U+4FF1, U+50B2, U+50C5, U+5102, U+51B4, U+51FD, U+5243, U+5256, U+52FF, U+533F, U+53A8, U+53AD, U+53D9, U+53DB, U+53F6, U+540F, U+5420, U+543B, U+548E, U+54BD, U+5506, U+5632, U+57A2, U+57C3, U+5815, U+589C, U+5984, U+5993, U+59D0, U+5A3C, U+5A7F, U+5BB5, U+5BC5, U+5CEF, U+5DBA, U+5E16, U+5EE3, U+5F0A, U+5FFD, U+60B6, U+60BC, U+6101, U+6144, U+6168, U+6190-6191, U+61A9, U+62D0, U+62D7, U+632B, U+63C6, U+642C, U+64EC, U+65ED, U+66F3, U+673D, U+674F, U+675C, U+67F5, U+6803, U+685F, U+6897, U+68AF, U+68FA, U+694A, U+6960, U+69CC, U+69FD, U+6A3A, U+6A58, U+6B86, U+6C5D, U+6CCC, U+6D12, U+6D2A, U+6DCB, U+6E13, U+6E15, U+6EBA, U+7027, U+7169, U+717D, U+724C, U+7259, U+727D, U+72FD, U+7336, U+7345, U+73B2, U+73CA, U+7409, U+7435-7436, U+745E, U+7460, U+74DC, U+7525, U+754F, U+7554, U+757F, U+760D, U+764C, U+76F2, U+771E, U+7729, U+7738, U+7766, U+77AD, U+77BC, U+7826, U+786B, U+79B0, U+7A14, U+7A40, U+7A6B, U+7A7F, U+7B25, U+7C1E, U+7C3E, U+7CDE, U+7D2F, U+7D46, U+7F60, U+7FA8, U+8061, U+814E, U+81A3, U+81C6, U+81E5, U+8235, U+8247, U+82A5, U+82D4, U+831C, U+8328, U+832B, U+837B-837C, U+853D, U+8587, U+8594, U+8599, U+85AA, U+85EA, U+85FB, U+868A, U+8766, U+87F9, U+881F, U+88B4, U+88DF, U+88E1, U+88F3, U+8A1D, U+8A3B, U+8A72, U+8ADC, U+8AEB, U+8AEE, U+8CC4, U+8CDC, U+8E2A, U+8E5F, U+8E87, U+8E8A, U+8F3F, U+8F44, U+8F5F, U+8FC5, U+8FE6, U+9010, U+901D, U+9065, U+914E, U+9162, U+91B8, U+91D8, U+92F3, U+932C, U+95B2, U+96DB, U+9798, U+97AD, U+9838, U+9913, U+9AC4, U+9BE8, U+9CF3, U+9DD7, U+9DF2, U+9F20, U+F9EC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold025.woff2") format("woff2");
  unicode-range: U+2F48, U+2F7D, U+2F85, U+4E9B, U+4EA8, U+4F47, U+4FEF, U+5023, U+5118, U+5141, U+51A5, U+51B6, U+51C6, U+51CC, U+51F0, U+5239, U+52FE, U+53C9, U+5484, U+54E2, U+55A9, U+567A, U+5806, U+58D5, U+5957, U+5962, U+597C, U+59E6, U+59FB, U+5A25, U+5A2F, U+5A9A, U+5AE1, U+5BE1, U+5BF5, U+5C4D, U+5C51, U+5C6F, U+5CD9, U+5CE8, U+5D6F, U+5EB8, U+5EC9, U+60E7, U+6284, U+62B9, U+62F7, U+633A, U+6372, U+637B, U+63A0, U+643E, U+647A, U+6492, U+64A5, U+6518, U+6523, U+6591, U+65FA, U+660F, U+6652, U+66F0, U+66FC, U+676D, U+6813, U+6841, U+6893, U+693F, U+698A, U+698E, U+69FB, U+6A0B, U+6A80, U+6A9C, U+6AD3, U+6AFB, U+6B89, U+6DD1, U+6E4A, U+6FE0, U+6FE4, U+704C, U+7092, U+714E, U+71D5, U+723E, U+72D7, U+72F8, U+7325, U+73A9, U+745C, U+7526, U+755D, U+75D9, U+76E7, U+7791, U+7825, U+785D, U+78D0, U+7901, U+7947, U+798D, U+79DF, U+79E4, U+7A92, U+7B95, U+7CA5, U+7CFE, U+7D21, U+7DCB, U+7E1E, U+7E55, U+7E8F, U+7F9E, U+7FE0, U+7FF3, U+800C, U+8036, U+809A, U+80B4, U+8151, U+8154, U+817A, U+81C0, U+81FC, U+8218, U+8236-8237, U+8258, U+82A6, U+82AD, U+8305, U+8389, U+840C, U+840E, U+842C, U+8461, U+8466, U+8475, U+8513, U+8523, U+8549, U+8569, U+85C1, U+8679, U+86CD, U+86D9, U+87BA, U+87EC, U+887F, U+8888, U+895E, U+8B33, U+8B39, U+8CAA, U+8E35, U+8ECB, U+8FC2, U+901E, U+9041, U+9059, U+905C, U+9061, U+918D, U+9190, U+91C7, U+92D2, U+930F, U+93A7, U+9583, U+95A4, U+966A, U+96BC, U+96C1, U+96CC, U+971C, U+9784, U+978D, U+97FB, U+9909, U+9910, U+9945, U+9BC9, U+FA11;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold026.woff2") format("woff2");
  unicode-range: U+2F0F, U+2F14, U+2FA2, U+4E11, U+4E32, U+4E4D, U+4F5B, U+4F86, U+4FC4, U+4FE0, U+4FF8, U+5005, U+5016, U+5036, U+50FB, U+515C, U+51A8, U+51DC, U+51E0, U+51F1, U+51F8-51F9, U+520E, U+52AB, U+5315, U+5347, U+536F, U+5378, U+53E2, U+54A5, U+555C, U+555E, U+55DA, U+55DC, U+55DF, U+55E4, U+5687, U+5703, U+5751, U+5766, U+57D2, U+5830, U+5861, U+589F, U+58F1, U+5B0C, U+5BC7, U+5CFB, U+5D4C, U+5DBD, U+5DCC, U+5DEB, U+5DF4, U+5DF7, U+5E40, U+5ED3, U+5EDF, U+5F14, U+5F1B, U+5F27, U+5F77, U+60DF, U+6167, U+619A, U+622E, U+6309, U+633D, U+634F, U+6377, U+639F, U+63AC, U+63C4, U+63F6, U+646F, U+64B0, U+64E2, U+65AF, U+6666, U+66A2, U+66DD, U+6775, U+67D1, U+6816, U+68A2, U+68F9, U+6900, U+696F, U+6A2B, U+6ABB, U+6ADB, U+6B23, U+6B3D, U+6B4E, U+6BEF, U+6C4E, U+6C50, U+6CAB, U+6E3E, U+6E5B, U+6F38, U+6FEB, U+6FF1, U+7058, U+707C, U+7109, U+7149, U+714C, U+721B, U+725D, U+725F, U+7396, U+745B, U+7483, U+752B, U+7560, U+75BC, U+75E2, U+766A, U+77A0, U+77A5, U+78A7, U+792B, U+7960, U+79B1, U+7A1C, U+7B8B, U+7C95, U+7C9F, U+7CCA, U+7D68, U+7DB8, U+7DFB, U+7E61, U+7E82, U+7F77, U+7F79, U+8017, U+803D, U+805A, U+80DA, U+80E4, U+8106, U+810A, U+8299, U+8338, U+834A, U+8404, U+847A, U+8494, U+86DB, U+8718, U+87FB, U+8882, U+8910, U+8944, U+8A1B, U+8A23, U+8A54, U+8A85, U+8AD2, U+8B01-8B02, U+8B5A, U+8CD3, U+8CE4, U+8CE6, U+8D0B, U+8DE8, U+8E44, U+8F1B, U+8F62, U+8FA3, U+907C-907D, U+9091, U+9127, U+91AC, U+9264, U+9306, U+936C, U+93D1, U+95CA, U+9744, U+978B, U+985A, U+98F4, U+9952, U+9A52, U+9AF7, U+9B41, U+9BAD, U+9BDB, U+9C39, U+9C3B, U+9C57, U+9CF6, U+9D09, U+9D3B, U+9E1E, U+9EB4-9EB5, U+9ECE, U+F993, U+2F8ED;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold027.woff2") format("woff2");
  unicode-range: U+2F18, U+2F8B, U+4E4E, U+4E8E, U+4E98, U+4EA6, U+4EC4, U+4F43, U+5026, U+5039, U+505A, U+50AD, U+50D1, U+5191, U+52D2, U+535C, U+5398, U+53A9, U+5475, U+54A4, U+54AC, U+54E8, U+54ED, U+54FA, U+557C, U+55AC, U+5614, U+5617, U+56CA, U+5821, U+5955, U+5978, U+59EA, U+5A9B, U+5B55, U+5B78, U+5BA5, U+5BD3, U+5C24, U+5C41, U+5C53, U+5C5B, U+5C79, U+5D14, U+5D69, U+5F45, U+5F6C, U+601C, U+604D, U+606B, U+6089, U+60B4, U+60B8, U+60F0, U+61AB, U+61AE, U+61BE, U+61FF, U+620A, U+621F, U+6241, U+626E, U+6289, U+634C, U+65A1, U+65A5, U+65A7, U+6727, U+6853, U+68B5, U+6912, U+6953, U+69B4, U+69D9, U+6A47, U+6A8E, U+6B3E, U+6B7F, U+6BB7, U+6BC0, U+6BD8, U+6C3E, U+6C81, U+6C8C, U+6C93, U+6CC4, U+6CEA, U+6DF9, U+6E1A, U+7015, U+703E, U+7114, U+71BE, U+7280, U+72C4, U+72E1, U+734F, U+745A, U+75B5, U+75B9, U+75D2, U+75E9, U+7624, U+779E, U+77EF, U+78DA, U+798E, U+7A62, U+7A83, U+7AE6, U+7AEA, U+7B19, U+7B75, U+7BDD, U+7C82, U+7CE0, U+7D18, U+7D43, U+7D62, U+7E0B, U+8006, U+805F, U+806F, U+8073, U+808B, U+809B, U+80B1, U+811B, U+814B, U+818F, U+81CD, U+8205, U+821C, U+8233, U+8278, U+8304, U+8309, U+8339, U+8340, U+8431, U+8471, U+84EC, U+8521, U+8543, U+857E, U+8606, U+8654, U+8695, U+86ED, U+8805, U+8823, U+88D4, U+8AA6, U+8AE7, U+8B80, U+8CC2, U+8D14, U+8DA8, U+8DBE, U+8DEA, U+8EB1, U+8EBE, U+8FC4, U+900D, U+9017, U+9075, U+90E4, U+9249, U+932B, U+947F, U+968B, U+96CD, U+9761, U+9870, U+98AF, U+9949, U+99A8, U+9BA8, U+9BAB, U+9D6C, U+9E9F, U+9F4B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold028.woff2") format("woff2");
  unicode-range: U+2F66, U+2FCD, U+4EA5, U+4F98, U+5072, U+5180, U+51DB, U+51EA, U+524C, U+52C1, U+53A0, U+5403, U+5477, U+54B8, U+55C4, U+5631, U+5634, U+56C3, U+5713, U+5718, U+57E0, U+57F4, U+582F, U+58BE, U+58EC, U+5944, U+5A62, U+5B95, U+5C60, U+5CB1, U+5DF2, U+5E37, U+5EFF, U+5F3C, U+5F4C, U+5F57, U+5F59, U+5FA8, U+6063, U+608D, U+60C7, U+60E0, U+61C9, U+6258, U+62F5, U+6369, U+637A, U+638F, U+64F2, U+652A-652B, U+6583, U+6670, U+6688, U+6714, U+6736, U+6753, U+67DA, U+67E9, U+6846, U+6854, U+68CD, U+68D8, U+690B, U+6955, U+6AC3, U+6C40, U+6D8E, U+6DB8, U+6DEE, U+6E20, U+6E25, U+6E58, U+6EF8, U+6FAA, U+6FB1, U+70AC, U+70B8, U+7164, U+7199, U+71D0, U+71E6, U+71FB, U+71FF, U+733E, U+73C2, U+74E2, U+7587, U+758B, U+75E3, U+75F0, U+7647, U+776B, U+777E, U+77E7, U+786F, U+78A9, U+795F, U+7A84, U+7AA9, U+7AC8, U+7B67, U+7B94, U+7C00, U+7C2A, U+7CFA, U+7D72, U+7D9C, U+7E20, U+7F3A, U+8000, U+8058, U+8070, U+8087, U+8129, U+8139, U+81A0, U+81B5, U+81BF, U+81FA, U+8207, U+8292, U+82EB, U+8490, U+84C9, U+84D1, U+8557, U+856A, U+85C9, U+86C6, U+86CB, U+8755, U+87A2, U+880D, U+8822, U+8877, U+88A2, U+88B1, U+890C, U+892A, U+8966, U+8ABC, U+8B10, U+8B17, U+8C79, U+8CB6, U+8CC8, U+8CFD, U+8D73, U+8E72, U+8E99, U+8EEB, U+8F2F, U+8F4D, U+8F9F, U+8FED, U+9021, U+903C, U+9081, U+9087, U+9119, U+929A, U+929C, U+92CF, U+92F8, U+9310, U+9328, U+95BB, U+9716, U+976D, U+986B, U+9957, U+9A55, U+9AEF, U+9B22, U+9B92, U+9C0A, U+9E7C, U+9EBE, U+9F0E, U+9F90, U+FA1F, U+2F877, U+2F8DC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold029.woff2") format("woff2");
  unicode-range: U+2F87, U+4E15, U+4E56, U+4F1C, U+5080, U+5085, U+5121, U+5147, U+51CB, U+51E7, U+51ED, U+524B, U+52BE, U+5301, U+5319, U+5321, U+532A, U+543C, U+5480, U+5556, U+5664, U+5851, U+58DC, U+58FD, U+5925, U+594E, U+59E5, U+5A29, U+5A36, U+5B30, U+5B32, U+5B8D, U+5BE6, U+5C39, U+5D8C, U+5E47, U+5E5F, U+5E96, U+5EE0, U+5F17, U+5F7F, U+5F8A, U+5F98, U+5FB7, U+5FBD, U+6062, U+60A7, U+6147, U+61AC, U+61C3, U+61E3, U+620D-620E, U+622A, U+6292, U+62EE, U+63A9, U+6426, U+6478, U+6703, U+6715, U+6726, U+6883, U+689F, U+6930, U+699B-699C, U+69C7, U+6A05, U+6A84, U+6AAE, U+6B12, U+6BB2, U+6BEB-6BEC, U+6C6A, U+6C83, U+6D1F, U+6D59, U+6DD8, U+6ED4, U+6EFE, U+6F09, U+6F32, U+6F3F, U+7006, U+701F, U+7078, U+7099, U+70D9, U+70F9, U+7184, U+71D7, U+71F5, U+7252, U+73C8, U+7433, U+7455, U+748B, U+7515, U+7576, U+7586, U+75D8, U+7693, U+76E5, U+77B0, U+77DC, U+7887, U+78D4, U+7953, U+79A6, U+79AE, U+79BD, U+7A1F, U+7A46, U+7AD9, U+7AFA, U+7B08, U+7B4F, U+7B72, U+7B92, U+7C50, U+7CAE, U+7D2C, U+7D93, U+7DEC, U+7E23, U+7FF0, U+8180, U+821B, U+826B, U+827E, U+82BB, U+82E7, U+839E, U+83D6, U+840A, U+865E, U+86C9, U+86E4, U+873B, U+87E0, U+8904, U+8977, U+8AB9, U+8AFA, U+8D04, U+8D16, U+8E42, U+8E81, U+8E85, U+8E91, U+9013, U+9089-908A, U+90C3, U+916A, U+91A4, U+91C9, U+91E7, U+9266, U+927E, U+9354, U+937E, U+96EB, U+97A0, U+98C4, U+99C1, U+9B1A, U+9B28, U+9B44, U+9BD6, U+9C2F, U+9C48, U+9C52, U+9DAF, U+9E92, U+9EBA, U+9F3E, U+9F67, U+9F95;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold030.woff2") format("woff2");
  unicode-range: U+2F3D, U+2F44, U+4E19, U+4E58, U+4E99, U+4E9E, U+4EC0, U+4EC6, U+4F36, U+4F69, U+4F83, U+4F91, U+4FCE, U+4FD0, U+4FD8, U+4FDF, U+5043, U+50DE, U+50F9, U+5109, U+514C, U+5152, U+51A4, U+51C9, U+5269, U+527D, U+528D, U+52F3, U+52FA, U+5308, U+5377, U+537D, U+53DF, U+5486, U+54E9, U+54EE, U+5544, U+558A, U+55AE, U+562F, U+565B, U+56A5, U+56B4, U+56BC, U+56EE, U+5700, U+5708, U+5879, U+587C, U+5958, U+5BE8, U+5BEC, U+5C4F, U+5D0B, U+5D17, U+5DAE, U+5E1B, U+5E9A, U+5EEC, U+5F10, U+5F6A, U+5FA0, U+6015, U+6041, U+6043, U+606A, U+6084, U+6194, U+61FA, U+61FC, U+6208, U+620C, U+6248, U+629B, U+62FF, U+6357, U+63BB, U+649E, U+64BC, U+64E1, U+6572, U+6582, U+65A4, U+6635, U+66D9, U+675E, U+6777, U+6787, U+67A1, U+67B7, U+67FE, U+6867, U+68A7, U+68B1, U+68B3, U+6962, U+6977, U+69AE, U+69C3, U+69FF, U+6A59, U+6AC2, U+6CDB, U+6D85, U+6E38, U+6F11, U+6FB9, U+6FFE, U+7119, U+7155, U+722C, U+7232, U+7240, U+731C, U+7370, U+73C0, U+7422, U+7425, U+7432, U+74BD, U+74DA, U+75B1, U+7621, U+7690, U+77EE, U+788D, U+7941, U+79B9, U+79E3, U+7A63, U+7AC4, U+7ADF, U+7AE3, U+7B4D, U+7BAD, U+7C7E, U+7D63, U+7DBD, U+7DDE, U+7E0A, U+7E37, U+7E6D, U+7F8C, U+7FC5, U+80F1, U+8171, U+81C2, U+8201, U+8229, U+822B, U+82B9, U+82FA, U+83A2, U+846D, U+8655, U+86F8, U+86FE, U+87C7, U+8852, U+88C3, U+8A03, U+8A25, U+8ACD, U+8AF7, U+8B04, U+8B16, U+8B6C, U+8D6B, U+8DCB, U+8E93, U+8F61, U+8FF8, U+914B, U+9248, U+929B, U+92E4, U+932E, U+937C, U+9435, U+947D, U+958F, U+95A8, U+97EE, U+9812, U+9824, U+983D, U+9903, U+991E, U+9998, U+99AD-99AE, U+99DD, U+99F1, U+9C13, U+9C3A, U+9DFA, U+9EA9, U+9EF4, U+2F818;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold031.woff2") format("woff2");
  unicode-range: U+2F72, U+2FA3, U+2FC8, U+2FD1, U+57DC, U+5859, U+589E, U+58D8, U+58DE, U+58EF, U+5967, U+596C, U+5A03, U+5B43, U+5B5C, U+5BE2, U+5C07-5C08, U+5C2D, U+5CFD, U+5D1A, U+5DD6, U+5DE2, U+5DFD, U+5E36, U+5EF3, U+5F48, U+5F9E, U+5FB5, U+6046, U+6055, U+608C, U+60E1, U+60FA, U+613C, U+61F7, U+6230, U+6232, U+62C2, U+62D4, U+62DC, U+63ED, U+6416, U+641C, U+64CA, U+651D, U+6536, U+654D, U+660A, U+6634, U+6644, U+664F, U+665A, U+665D, U+665F, U+6668, U+6689, U+66C6, U+66C9, U+67CA, U+67D8, U+681E, U+689B, U+691B, U+6A02, U+6A1F, U+6A23, U+6A6B, U+6AA2, U+6B65, U+6B77, U+6BCF, U+6C23, U+6D35, U+6D38, U+6D6C, U+6D89, U+6DDA, U+6DE8, U+6E34, U+6EAB, U+6EC9, U+6EEF, U+6F23, U+6F81, U+6FD5, U+7028, U+7130, U+71CE, U+71D2, U+722D, U+72C0, U+72F9, U+7378, U+7473, U+7476, U+7562, U+758A, U+758F, U+76DC, U+76E1, U+7827, U+788E, U+7893, U+7955, U+7962, U+7977, U+797F, U+79AA, U+79BE, U+7A3B, U+7A57, U+7A70, U+7A79, U+7CB9, U+7DA0, U+7DD6, U+7DE3, U+7E31, U+7E96, U+7F9A, U+807D, U+81DF, U+838A, U+83EB, U+8420, U+8568, U+8597, U+85B0, U+85CF, U+85E5, U+865B, U+885E, U+88DD, U+89BD, U+8A62, U+8AC4, U+8B20, U+8B93, U+8CE3, U+8CF4, U+8F49, U+8FEA, U+90DE, U+9149, U+9187, U+9189, U+91C0, U+9291, U+9304, U+9318, U+934A, U+93AD, U+9444, U+9677, U+96AA, U+96DC, U+975C, U+980C, U+9817, U+986F, U+98DC, U+9A37, U+9A4D, U+9A57, U+9AEE, U+9DC4, U+9EC3, U+9ED1, U+9ED8, U+9EDB, U+9F4A, U+F91D, U+F928-F929, U+F936, U+F992, U+F9D0, U+FA16, U+FA19-FA1B, U+FA22, U+FA26, U+FA30-FA31, U+FA33;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold032.woff2") format("woff2");
  unicode-range: U+2F89, U+2FA4, U+2FC9, U+4F7C, U+4FA0, U+4FAD, U+5265, U+5283, U+531D, U+5366, U+540B, U+5451, U+548B, U+5516, U+5618, U+5678, U+56A2, U+5764, U+5858, U+586B, U+58F7, U+5919, U+59F6, U+5A41, U+5B2C, U+5C61, U+5CA8, U+5EFC, U+5F4A, U+6108, U+617E, U+63B4, U+63D6, U+649A, U+64B9, U+64FE, U+658C, U+67C1, U+6802, U+6822, U+6834, U+685D, U+68BC, U+68C9, U+6919, U+6934, U+6973, U+6994, U+6A17, U+6A35, U+6A61, U+6A7F, U+6AE8, U+6B1D, U+6D8C, U+6D9B-6D9C, U+6E8C, U+6F45, U+6F97, U+701E, U+7026, U+7194, U+72DB, U+7337, U+73EA, U+7511, U+751C, U+7566, U+7577, U+75D4, U+76C8, U+783A, U+783F, U+7872, U+7895, U+7A17, U+7A4E, U+7A50, U+7AC3, U+7B86, U+7BAA, U+7BED, U+7C38, U+7C81, U+7C8D, U+7CCE, U+7CDF, U+7DAC, U+7E4B, U+7E4D, U+7F6B, U+7FEB, U+807E, U+826E, U+82C5, U+82D3, U+834F, U+83B1, U+83DF, U+83F0, U+844E, U+848B, U+849C, U+8500, U+851A, U+854A, U+85AE-85AF, U+85F7, U+867B, U+86A4, U+86CE, U+8749, U+874B, U+877F, U+88B7, U+8A51, U+8ACC, U+8B2C, U+8CCE, U+8EAF, U+8FE9, U+9197, U+91C6, U+91E6, U+920E, U+9237, U+92EA, U+92F2, U+934D, U+9397, U+939A, U+9419, U+9438, U+9453, U+981A, U+982C, U+9834, U+985B, U+9A28, U+9BAA, U+9BF5, U+9C0D, U+9C10, U+9C2D, U+9D07, U+9D0E, U+9D1B, U+9D2B-9D2C, U+9D60-9D61, U+9E78, U+9EB9, U+9ECD, U+FA34-FA35, U+FA37-FA38, U+FA3A-FA3B, U+FA3D, U+FA3F-FA41, U+FA43-FA48, U+FA4A-FA57, U+FA59-FA5C, U+FA5F, U+FA61-FA65, U+FA67-FA69;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold033.woff2") format("woff2");
  unicode-range: U+2E8E, U+2F02-2F03, U+2F05, U+2F07, U+2F09, U+2F0C-2F0E, U+2F10, U+2F13, U+2F15-2F16, U+2F19-2F1B, U+4E10, U+4E17, U+4E2A, U+4E31, U+4E36, U+4E3F, U+4E42, U+4E55, U+4E62, U+4E82, U+4E85, U+4E8A, U+4E9F-4EA0, U+4EA2, U+4EB0, U+4EB3, U+4EB6, U+4EC2, U+4ECD-4ECE, U+4ED7, U+4EDE-4EDF, U+4EED, U+4EF7, U+4F09, U+4F30, U+4F57, U+4F5A, U+4F5D-4F5E, U+4F6F-4F70, U+4F76, U+4F7B, U+4F88, U+4F8F, U+4F96, U+4FAB, U+4FD1, U+4FD4, U+4FDA-4FDB, U+4FE4-4FE5, U+4FF6, U+4FFE, U+5006, U+500F, U+5011, U+5014, U+501A, U+5021, U+5025, U+5028-502A, U+502C, U+5047-5048, U+5050, U+5055-5056, U+506C, U+5078, U+509A, U+50B4, U+50C2, U+50C9-50CA, U+50D6, U+50E3, U+50E5, U+50ED-50EE, U+50F5, U+5101, U+5114-5116, U+511A, U+5137, U+513A-513C, U+513F-5140, U+5154, U+5162, U+5169-516A, U+516E, U+5182, U+5189, U+518C, U+518F-5190, U+5193, U+5195-5196, U+51A2, U+51A6, U+51A9-51AB, U+51B0-51B3, U+51B5, U+51BD, U+51C5, U+51D6, U+51E9, U+51F5, U+51FE, U+5204, U+520B, U+5214, U+5227, U+522A, U+522E, U+5233, U+5244, U+524F, U+5254, U+525E, U+526A, U+5271, U+5273-5274, U+527F, U+5288, U+5291-5292, U+5294, U+52AC-52AD, U+52B5, U+52BC, U+52CD, U+52D7, U+52DE, U+52E0, U+52E3, U+52E6, U+52F5, U+52F8-52F9, U+5306, U+530D, U+530F-5310, U+531A, U+5323, U+532F, U+5331, U+5333, U+5338, U+5340, U+5345-5346, U+5349, U+534D, U+535E, U+5369, U+536E, U+537B, U+5382, U+5396, U+53A5-53A6, U+53AE, U+53B0, U+53B6, U+53C3, U+53E8, U+53ED-53EE, U+53FA, U+5401, U+541D, U+5429, U+542C-542E, U+5436, U+543D, U+5440, U+544E, U+5470-5471, U+5476;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold034.woff2") format("woff2");
  unicode-range: U+2F1E, U+2F21-2F22, U+548F-5490, U+5492, U+54A2, U+54A8, U+54AB, U+54AF, U+54BC, U+54BE, U+54C2, U+54C4, U+54C7-54C8, U+54D8, U+54E5-54E6, U+54FD, U+550F, U+5514, U+552E, U+5533, U+5539, U+5540, U+5545, U+554C, U+5557, U+555D, U+5563, U+557B, U+557E, U+5580, U+5583, U+5587, U+5599, U+559E-559F, U+55A8, U+55C7, U+55D4, U+55F7, U+55F9, U+55FD-55FE, U+5616, U+561B, U+5636, U+5638, U+564E, U+5650, U+566A-566C, U+5680, U+5686, U+568A, U+568F, U+5694, U+56A0, U+56AE, U+56B6, U+56C0, U+56C2, U+56C8, U+56CE, U+56D1, U+56D3, U+56D7-56D8, U+56F9, U+56FF, U+5704, U+5709, U+570D, U+5716, U+571C, U+5726, U+5737-5738, U+573B, U+5740, U+574E-574F, U+5761, U+5769, U+577F, U+5788-5789, U+5793, U+57A0, U+57A4, U+57AA, U+57B0, U+57B3, U+57C0, U+57C6, U+57D3-57D4, U+57D6, U+57E3, U+580A-580B, U+5819, U+581D, U+583D, U+584B, U+5852, U+5862, U+5870, U+5872, U+5885, U+58AB, U+58AE, U+58B8-58BB, U+58C5, U+58D1, U+58D3, U+58D7, U+58D9, U+58DF, U+58E4-58E5, U+58F9, U+58FB-58FC, U+5902, U+590A, U+5910, U+5918, U+591B, U+592C-592D, U+5932, U+5938, U+593E, U+5950, U+595A, U+5960, U+5969, U+5981, U+598D, U+599B, U+599D, U+59A3, U+59B2, U+59C6, U+59D9-59DA, U+59E8, U+5A09, U+5A11, U+5A1A, U+5A1C, U+5A1F, U+5A35, U+5A40, U+5A49, U+5A6A, U+5A6C, U+5ABC-5ABE, U+5AC2, U+5ACB, U+5AD0, U+5AD6-5AD7, U+5AE3, U+5AE6, U+5AE9, U+5AFA-5AFB, U+5B0B, U+5B16, U+5B2A, U+5B36, U+5B3E, U+5B40, U+5B45, U+5B51, U+5B5A-5B5B, U+5B65, U+5B69, U+5B70-5B71, U+5B73, U+5B75;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold035.woff2") format("woff2");
  unicode-range: U+2E90, U+2E93-2E94, U+2F27, U+2F2A-2F2C, U+2F2E, U+2F33-2F37, U+2F3A-2F3B, U+5B7A, U+5B80, U+5B83, U+5BA6, U+5BB8, U+5BC3, U+5BC9, U+5BD0, U+5BD4, U+5BDE, U+5BE4-5BE5, U+5BEB, U+5BF0, U+5BF3, U+5BF6, U+5C05, U+5C0D, U+5C13, U+5C20, U+5C22, U+5C28, U+5C38, U+5C46, U+5C4E, U+5C50, U+5C6C, U+5C6E, U+5C76, U+5C8C, U+5C91, U+5C94, U+5CAB, U+5CB6-5CB7, U+5CBB-5CBC, U+5CBE, U+5CC5, U+5CC7, U+5CE9-5CEA, U+5CED, U+5CFA, U+5D11, U+5D15, U+5D18-5D19, U+5D1B, U+5D1F, U+5D22, U+5D4B, U+5D4E, U+5D52, U+5D5C, U+5D6C, U+5D73, U+5D76, U+5D82, U+5D84, U+5D87, U+5D90, U+5D9D, U+5DA2, U+5DAC, U+5DB7, U+5DBC, U+5DC9, U+5DCD, U+5DD2-5DD3, U+5DDB, U+5DF5, U+5E0B, U+5E11, U+5E19-5E1A, U+5E43-5E44, U+5E4E, U+5E54, U+5E57, U+5E62, U+5E64, U+5E75-5E76, U+5E7A, U+5E7F, U+5EA0, U+5EC1-5EC2, U+5EC8, U+5ECF-5ED0, U+5ED6, U+5EDA-5EDB, U+5EDD, U+5EE1-5EE2, U+5EE8-5EE9, U+5EF0-5EF1, U+5EF4, U+5EF8, U+5EFE, U+5F03, U+5F09, U+5F0B-5F0D, U+5F11, U+5F16, U+5F29, U+5F2D, U+5F2F, U+5F38, U+5F41, U+5F4E, U+5F51, U+5F56, U+5F5C-5F5D, U+5F61, U+5F6D, U+5F73, U+5F82-5F83, U+5F87-5F88, U+5F91, U+5F99, U+5FAD, U+5FBC, U+5FD6, U+5FDD, U+5FE4, U+5FF0-5FF1, U+5FF8, U+5FFB, U+5FFF, U+600E-6010, U+6019, U+601B, U+6021, U+6026, U+6029, U+602B, U+6031, U+603A, U+6042, U+604A, U+6059-605A, U+605F-6060, U+6064, U+606C, U+6077, U+6081, U+6083, U+608B, U+6092, U+6096-6097, U+609A-609B, U+60B3, U+60B5, U+60BD, U+60C6, U+60D3, U+60D8, U+60F1, U+60F4, U+60F6-60F7, U+60FB, U+6100, U+6103, U+6106, U+610D-610E, U+6121, U+6127-6128, U+612C, U+6134, U+613D-613F, U+6142;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold036.woff2") format("woff2");
  unicode-range: U+2E99, U+2E9B, U+2F41, U+2F46, U+614A, U+614D, U+6153, U+6158-615A, U+615D, U+615F, U+6165, U+616B, U+616F, U+6171, U+6173-6175, U+6177, U+6187, U+618A, U+6196, U+6199, U+61BA, U+61C6, U+61C8, U+61CA-61CD, U+61E6, U+61F4, U+61F6, U+61FD-61FE, U+6200, U+6209, U+6214, U+621B, U+621D-621E, U+6221, U+6233, U+624E, U+625B, U+625E, U+6260, U+6263, U+6268, U+627C, U+627E, U+6282-6283, U+6293-6294, U+6296, U+62AC, U+62BB, U+62C6-62C8, U+62CA, U+62CC, U+62CF, U+62D1, U+62EF, U+62F1, U+6302, U+6308, U+630C, U+6327, U+633E, U+634D, U+6350, U+636B, U+6376, U+6380, U+6389, U+638E, U+6396, U+63A3, U+63AB, U+63B5, U+63BE, U+63C0, U+63D2, U+63E3, U+63E9, U+6406, U+640F, U+6413, U+6417, U+6428, U+6434, U+6436, U+644E, U+6467, U+6476, U+6488, U+6493, U+6495, U+64A9, U+64BB, U+64C2, U+64C5, U+64C7, U+64D2, U+64D4, U+64D8, U+64DA, U+64E0, U+64E3, U+64E7, U+64EF, U+64F1, U+64F4, U+64F6, U+64FA, U+64FD, U+6500, U+6505, U+651C, U+6524, U+652C, U+6534-6535, U+6537-6538, U+6548, U+6555-6556, U+6558, U+655D-655E, U+6578, U+6588, U+659B, U+659F, U+65AB, U+65B7, U+65C1, U+65C3-65C4, U+65C6, U+65CC, U+65D2, U+65D9, U+65DB, U+65E0-65E1, U+65F1, U+65FB, U+6603, U+661C, U+6636, U+663F, U+6641, U+6649, U+665E, U+6662, U+6664, U+6667, U+6683-6684, U+668E, U+6698, U+669D, U+66B8-66B9, U+66BC, U+66BE, U+66C1, U+66C4, U+66DA, U+66E0, U+66E6, U+66E9, U+66F5, U+66F7, U+670F, U+6716, U+671E, U+672E, U+6737-6738, U+673F, U+6741, U+6746, U+6759, U+6760, U+6763-6764, U+676A, U+6770, U+6772-6773, U+677C, U+6785, U+6789, U+678B-678C, U+67A6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold037.woff2") format("woff2");
  unicode-range: U+2F4D-2F4F, U+2F53, U+67A9, U+67B3-67B4, U+67B8-67B9, U+67C6, U+67CE, U+67DD-67DE, U+67E2, U+67E4, U+67E7, U+67EC, U+67EE-67EF, U+6829, U+682B, U+6832, U+6840, U+684D-684E, U+6859, U+6863, U+6874, U+6877, U+687E-687F, U+688D, U+688F, U+6894, U+68A0, U+68A6, U+68AD, U+68B9-68BA, U+68C6, U+68CA, U+68D4-68D5, U+68D7, U+68E0-68E1, U+68E3, U+68E7, U+68EF, U+6901, U+6904, U+6908, U+690C, U+690F, U+691A, U+6921-6923, U+6925-6926, U+6928, U+692A, U+6936, U+6939, U+693D, U+6954, U+6959, U+695C-695E, U+6961, U+696A-696B, U+696E, U+6974, U+6978-6979, U+697E, U+6981, U+6991, U+6995, U+69A0, U+69A7, U+69B1-69B2, U+69BB, U+69BE-69BF, U+69C1, U+69CA, U+69CE, U+69D0, U+69D3, U+69DD-69DE, U+69E7-69E8, U+69EB, U+69ED, U+69F2, U+69F9, U+6A0A, U+6A0C, U+6A12-6A14, U+6A1B, U+6A1E, U+6A22, U+6A2E, U+6A36, U+6A38, U+6A44, U+6A48, U+6A62, U+6A66, U+6A72, U+6A78, U+6A8D, U+6A90, U+6A97, U+6AA0, U+6AA3, U+6AAA, U+6AAC, U+6AB3, U+6AB8, U+6AC1, U+6AD1, U+6ADA, U+6ADE-6ADF, U+6AEA, U+6AFA, U+6B05, U+6B0A, U+6B16, U+6B1F, U+6B37-6B39, U+6B43, U+6B47, U+6B49, U+6B50, U+6B54, U+6B59, U+6B5B, U+6B5F, U+6B61, U+6B78-6B79, U+6B80, U+6B83-6B84, U+6B8D, U+6B95, U+6B98, U+6B9E, U+6BA4, U+6BAA-6BAB, U+6BAF, U+6BB1, U+6BB3, U+6BBC, U+6BC6, U+6BCB, U+6BD3, U+6BDF, U+6BF3, U+6C08, U+6C13-6C14, U+6C1B, U+6C24, U+6C55, U+6C5E, U+6C62, U+6C68, U+6C73, U+6C7E, U+6C82, U+6C8D, U+6C90, U+6C92, U+6C9A-6C9B, U+6CAE, U+6CB1, U+6CBA, U+6CBD-6CBE, U+6CC5, U+6CD3, U+6CD7, U+6CD9;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold038.woff2") format("woff2");
  unicode-range: U+2F58-2F59, U+6CDD, U+6CEF, U+6CF1, U+6D0C, U+6D19, U+6D2B, U+6D33, U+6D36, U+6D3D, U+6D5A, U+6D63-6D64, U+6D79, U+6D93, U+6D95, U+6DB5, U+6DC5-6DC7, U+6DCC, U+6DD2, U+6DD5, U+6DD9, U+6DDE, U+6DE4, U+6DE6, U+6DEA, U+6DEC, U+6DFA, U+6E0A, U+6E19, U+6E1D, U+6E1F, U+6E23-6E24, U+6E2B, U+6E2D-6E2E, U+6E3A, U+6E43, U+6E4D-6E4E, U+6E5F, U+6E6B, U+6E6E, U+6E72, U+6E76, U+6E82, U+6E8F, U+6E98, U+6E9F, U+6EA5, U+6EAA, U+6EAF, U+6EB2, U+6EB7, U+6EBD, U+6EC2, U+6EC4, U+6ECC, U+6ED3, U+6ED5, U+6EEC, U+6EF7, U+6EFF, U+6F13, U+6F3E, U+6F41, U+6F58, U+6F5B, U+6F66, U+6F6D, U+6F6F, U+6F74, U+6F78, U+6F7A, U+6F7C, U+6F80, U+6F82, U+6F86, U+6F8E, U+6F91, U+6FA1, U+6FA3, U+6FB3, U+6FC2, U+6FC6, U+6FD4, U+6FD8, U+6FDB, U+6FDF, U+6FEC, U+6FEE, U+6FF3, U+6FF6, U+6FFA, U+7001, U+7009, U+700B, U+700F, U+7011, U+7018, U+701A-701B, U+701D, U+7030, U+7032, U+7051, U+7063, U+70AE-70AF, U+70B3, U+70CB, U+70DD, U+70DF, U+70F1, U+70FD, U+711C, U+7156, U+7162, U+7165-7166, U+716C, U+7188, U+718F, U+7195, U+71A8, U+71AC, U+71B9, U+71C9, U+71D4, U+71DF-71E0, U+71E7, U+71EC, U+71EE, U+71F9, U+71FC, U+720D, U+7210, U+7228, U+7230, U+723B-723C, U+723F, U+7246, U+724B, U+7258, U+7274, U+727E, U+7281-7282, U+7287, U+7292, U+7296, U+72A2, U+72A7, U+72B2, U+72B9, U+72C3, U+72C6, U+72CE, U+72D2, U+72E0, U+72E2, U+72F7, U+730A, U+7316-7317, U+731D, U+7329, U+732F, U+7334, U+734E, U+7357, U+7368, U+736A, U+7375, U+737A-737B, U+73B3, U+73BB, U+73CE, U+73DE, U+73E5, U+73EE, U+73F1, U+73F8, U+7405;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold039.woff2") format("woff2");
  unicode-range: U+2F68, U+743A, U+743F, U+7441, U+7459, U+745F, U+7463-7464, U+7469-746A, U+746F-7470, U+747E, U+749E, U+74A2, U+74CA, U+74CF, U+74D4, U+74E0, U+74E3, U+74E7, U+74E9, U+74EE, U+74F0-74F2, U+74F7-74F8, U+7503-7505, U+750C-750E, U+7513, U+751E, U+752C, U+7538, U+753C, U+7544, U+7546, U+7549-754B, U+754D, U+755A-755B, U+7564, U+7567, U+7569, U+756B, U+756D, U+7574, U+7578, U+7582, U+7589, U+7594, U+759A, U+759D, U+75A3, U+75A5, U+75B3, U+75B8, U+75BD, U+75C2-75C3, U+75CA, U+75CD, U+75DE, U+75F2-75F3, U+75FC, U+75FE-75FF, U+7601, U+7609, U+760B, U+761F-7620, U+7622, U+7627, U+7630, U+7634, U+763B, U+7646, U+7648, U+7658, U+765C, U+7661-7662, U+7667-7669, U+766C, U+7670, U+7672, U+7676, U+7678, U+767C, U+7680, U+7683, U+7688, U+768B, U+768E, U+7696, U+7699-769A, U+76B0, U+76B4, U+76B7-76B9, U+76C2, U+76CD, U+76D2, U+76D6, U+76DE, U+76EA, U+76FB, U+7704, U+7707-7708, U+771B, U+7724-7726, U+7737, U+7747, U+775A-775B, U+7765, U+7779, U+777F, U+778B, U+778E, U+77B6, U+77B9, U+77BB, U+77BD, U+77BF, U+77C7, U+77CD, U+77D7, U+77DA, U+77E3, U+77FC, U+780C, U+7812, U+7820, U+7845, U+7874, U+787C, U+7886, U+788C, U+789A, U+78A3, U+78AA, U+78AF, U+78B5, U+78BC, U+78BE, U+78C5-78C6, U+78CA-78CB, U+78D1, U+78E7, U+78EC, U+78F4, U+78FD, U+7907, U+7911-7912, U+7919, U+7926, U+792A, U+792C, U+7957, U+795A, U+797A, U+7980, U+798A, U+799D, U+79A7, U+79B3, U+79BA, U+79C9, U+79D5, U+79E1, U+79E7, U+79EC, U+7A08, U+7A0D, U+7A18-7A19, U+7A20, U+7A31, U+7A37, U+7A3E, U+7A43;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold040.woff2") format("woff2");
  unicode-range: U+7A49, U+7A61, U+7A69, U+7A7D, U+7A88, U+7A95-7A98, U+7AB0, U+7AB6, U+7ABF, U+7AC5, U+7AC7, U+7ACA, U+7ACD, U+7ACF, U+7AD2-7AD3, U+7AD5, U+7ADA, U+7ADD, U+7AE1-7AE2, U+7AED, U+7AF0, U+7AF8, U+7B02, U+7B04, U+7B06, U+7B0A-7B0B, U+7B0F, U+7B18, U+7B1E, U+7B28, U+7B33, U+7B35-7B36, U+7B45, U+7B4C, U+7B50, U+7B5D, U+7B65, U+7B6C, U+7B6E, U+7B70-7B71, U+7B74, U+7B7A, U+7B8D, U+7B8F, U+7B98-7B9A, U+7B9C-7B9D, U+7B9F, U+7BB4, U+7BC1, U+7BC6, U+7BCB-7BCC, U+7BCF, U+7BE5-7BE6, U+7BE9, U+7BF3, U+7BF6-7BF7, U+7C07, U+7C0D, U+7C11-7C14, U+7C17, U+7C1F, U+7C23, U+7C27, U+7C2B, U+7C37, U+7C3D, U+7C40, U+7C43, U+7C4C, U+7C4F, U+7C54, U+7C56, U+7C58, U+7C5F, U+7C64-7C65, U+7C6C, U+7C75, U+7C83, U+7C90, U+7CA1-7CA2, U+7CA4, U+7CA8, U+7CAB, U+7CAD, U+7CB1-7CB3, U+7CBD, U+7CC0, U+7CC2, U+7CC5, U+7CD2, U+7CD8, U+7CDC, U+7CE2, U+7CEF, U+7CF2, U+7CF4, U+7CF6, U+7D02, U+7D06, U+7D0A, U+7D15, U+7D1C, U+7D2E, U+7D32, U+7D35, U+7D3F, U+7D45, U+7D4B, U+7D4E-7D4F, U+7D56, U+7D5B, U+7D6E, U+7D73, U+7D7D, U+7D89, U+7D8F, U+7D9B, U+7D9F, U+7DA2-7DA3, U+7DAB, U+7DAE-7DB0, U+7DB5, U+7DC7, U+7DD5, U+7DD8, U+7DDC-7DDD, U+7DE1, U+7DE4, U+7DF2, U+7E05, U+7E09, U+7E12, U+7E1F, U+7E21-7E22, U+7E32, U+7E35, U+7E39-7E3B, U+7E3D, U+7E43, U+7E46, U+7E56, U+7E59-7E5A, U+7E5D-7E5E, U+7E66-7E67, U+7E69-7E6A, U+7E79, U+7E7B-7E7D, U+7E7F, U+7E83, U+7E88-7E89, U+7E8C, U+7E8E, U+7E90, U+7E92-7E94, U+7E9B-7E9C, U+7F38, U+7F45, U+7F4C-7F4E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold041.woff2") format("woff2");
  unicode-range: U+2F79, U+2F7E, U+2F80, U+7F50-7F51, U+7F54-7F55, U+7F58, U+7F5F, U+7F67-7F69, U+7F78, U+7F82-7F83, U+7F86-7F88, U+7F94, U+7F9D, U+7FA3, U+7FAE-7FAF, U+7FB2, U+7FB6, U+7FB8-7FB9, U+7FC6, U+7FCA, U+7FD5, U+7FE1, U+7FE6, U+7FE9, U+7FF9, U+8004, U+800B, U+8012, U+8018-8019, U+801C, U+8021, U+8028, U+803B, U+803F, U+8046, U+804A, U+8052, U+8062, U+8068, U+8072, U+8076, U+8079, U+807F, U+8084-8086, U+8093, U+80AC-80AD, U+80C4, U+80D6, U+80D9, U+80DB, U+80DD, U+80E5, U+80EF, U+80FC, U+8109, U+8123, U+812F, U+813E, U+8146, U+8153, U+815F, U+8165-8166, U+816E, U+8174, U+8182-8183, U+8188, U+818A, U+8193, U+8195, U+81A4, U+81A9, U+81B0, U+81B8, U+81BA, U+81BD-81BE, U+81C8-81C9, U+81D1, U+81D8-81DA, U+81E0, U+81E7, U+81FB, U+81FE, U+8202, U+8209-820A, U+820D, U+8212, U+8216, U+822E, U+8238, U+8240, U+8259-825A, U+825D, U+825F, U+8262, U+8264, U+8268, U+826A, U+8271, U+8277, U+828D, U+829F, U+82AB-82AC, U+82D2, U+82D9, U+82DC, U+82DE-82DF, U+82E1, U+82E3, U+82F3-82F4, U+82F9, U+82FB, U+8303, U+8306, U+8316-8318, U+8323, U+832F, U+8331-8332, U+8334-8335, U+8345, U+8350, U+8373, U+8375, U+8385, U+8387, U+838E, U+8393, U+8396, U+839A, U+839F-83A0, U+83A8, U+83AA, U+83B5, U+83BD, U+83C1, U+83CE, U+83D8, U+83E0, U+83F2, U+83F4, U+83F7, U+83FB, U+83FD, U+8403, U+8407, U+840B, U+840D, U+8413, U+8422, U+842A, U+8435, U+8438, U+843C, U+8446, U+8462, U+8469, U+846B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold042.woff2") format("woff2");
  unicode-range: U+2F8C, U+2F91, U+846E-846F, U+8477, U+8479, U+8482, U+8484, U+849F, U+84A1, U+84AD, U+84B9, U+84BB, U+84BF, U+84C1, U+84C6, U+84CA, U+84CD, U+84D0, U+84D6, U+84D9-84DA, U+84F4, U+84FC, U+84FF, U+8506, U+8514-8515, U+8517-8518, U+851F, U+852C, U+8540-8541, U+8548, U+854B, U+8555, U+8558, U+855A, U+8563, U+856D, U+8577, U+8580, U+8588, U+858A, U+8590-8591, U+859B-859C, U+85A4, U+85A8, U+85B9-85BA, U+85D0, U+85D5, U+85DC, U+85F9-85FA, U+85FE, U+8602, U+860A-860B, U+8613, U+8616-8617, U+861A, U+8622, U+862F-8630, U+863F, U+864D, U+865F, U+8667, U+8671, U+868B-868C, U+8693, U+86A3, U+86A9-86AB, U+86AF-86B0, U+86B6, U+86C4, U+86D4, U+86DE-86DF, U+86E9, U+86EC, U+86EF, U+86F9, U+86FB, U+8703, U+8706, U+8708-870A, U+870D, U+8711-8712, U+871A, U+8725, U+8729, U+8734, U+8737, U+873F, U+874C, U+874E, U+8753, U+8757, U+8759, U+875F-8760, U+8763, U+8768, U+876A, U+876E, U+8774, U+8778, U+8782, U+879F, U+87AB, U+87AF, U+87B3, U+87BB, U+87BD, U+87C0, U+87C4, U+87C6, U+87CB, U+87D0, U+87D2, U+87EF, U+87F2, U+87F6-87F7, U+87FE, U+880E-880F, U+8811, U+8815-8816, U+8821, U+8827, U+8831, U+8836, U+8839, U+883B, U+8842, U+8844, U+884D, U+8859, U+8862, U+886B, U+8872, U+8875, U+887D-887E, U+888D, U+8892, U+8897, U+8899, U+889E, U+88A4, U+88AE, U+88B0, U+88B5, U+88BF, U+88C4, U+88D8-88D9, U+88E8, U+88F2, U+88F4, U+88F9, U+88FC, U+8902, U+890A, U+8913, U+891D-891E, U+8925, U+892B, U+8936, U+8938, U+893B, U+8941, U+8943, U+894C-894D, U+8960, U+8964, U+896A, U+896D, U+896F, U+8974, U+897E, U+8983, U+8988, U+898A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold043.woff2") format("woff2");
  unicode-range: U+2F97-2F98, U+8993, U+8998, U+89A1, U+89A6, U+89A9, U+89AC, U+89AF, U+89B2, U+89BA, U+89BF-89C0, U+89DA, U+89DC-89DD, U+89E7, U+89F4, U+89F8, U+8A0C, U+8A10, U+8A16, U+8A36, U+8A41, U+8A46, U+8A48, U+8A52, U+8A5B, U+8A6C-8A6D, U+8A7C, U+8A82, U+8A84, U+8A91, U+8A9A, U+8AA1, U+8AA3, U+8AA5, U+8AA8, U+8AC2, U+8ADA-8ADB, U+8ADE, U+8AE0-8AE2, U+8AE4, U+8AF1, U+8AF3, U+8B07, U+8B0C, U+8B14, U+8B1A, U+8B26, U+8B28, U+8B2B, U+8B3E, U+8B41, U+8B49, U+8B4C, U+8B4E-8B4F, U+8B56, U+8B5B, U+8B5F, U+8B6B, U+8B6F, U+8B71, U+8B74, U+8B7D, U+8B8A, U+8B8C, U+8B8E, U+8B92, U+8B96, U+8B99-8B9A, U+8C3A, U+8C3F, U+8C41, U+8C48, U+8C4C, U+8C4E, U+8C50, U+8C55, U+8C62, U+8C6B-8C6C, U+8C78, U+8C7A, U+8C7C, U+8C82, U+8C85, U+8C89-8C8A, U+8C8D-8C8E, U+8C94, U+8C98, U+8CAD-8CAE, U+8CB2-8CB3, U+8CBD, U+8CC1, U+8CCD, U+8CDA, U+8CFA-8CFB, U+8D07, U+8D0A, U+8D0D, U+8D0F-8D10, U+8D13, U+8D67, U+8D6D, U+8D71, U+8D81, U+8DBA, U+8DC2, U+8DCC, U+8DCF, U+8DD6, U+8DDA-8DDB, U+8DDF, U+8DE3, U+8DEB, U+8DFC, U+8DFF, U+8E08-8E09, U+8E10, U+8E1D-8E1F, U+8E30, U+8E34, U+8E47-8E4A, U+8E4C, U+8E50, U+8E55, U+8E59, U+8E60, U+8E63-8E64, U+8E76, U+8E7C, U+8E84, U+8E8B, U+8E94, U+8EA1, U+8EAA, U+8EAC, U+8EC5-8EC6, U+8EC8, U+8EDB, U+8EE3, U+8EFB-8EFC, U+8EFE, U+8F05, U+8F0A, U+8F0C, U+8F12-8F13, U+8F15, U+8F19, U+8F1C, U+8F1F, U+8F26, U+8F33, U+8F39, U+8F3B, U+8F3E, U+8F42, U+8F45-8F46, U+8F4C, U+8F4E, U+8F57, U+8F5C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold044.woff2") format("woff2");
  unicode-range: U+8F63-8F64, U+8F9C, U+8FA7-8FA8, U+8FAD-8FAF, U+8FB7, U+8FDA, U+8FE2, U+8FE5, U+8FEF, U+8FF4, U+8FF9-8FFA, U+9005, U+900B, U+900E, U+9011, U+9015-9016, U+9027, U+9035-9036, U+9039, U+903E, U+9049, U+904F-9052, U+9056, U+9058, U+905E, U+9068, U+906F, U+9072, U+9076, U+9080, U+9082-9083, U+908F, U+90A8, U+90AF, U+90B1, U+90B5, U+90DB, U+90E2, U+9102, U+9112, U+9130, U+9132, U+914A, U+9156, U+9158, U+9163, U+9165, U+9169, U+9172-9173, U+9182, U+918B, U+91A2, U+91AA-91AB, U+91AF, U+91B4-91B5, U+91BA, U+91C1, U+91CB, U+91D0, U+91D6, U+91DB, U+91DF, U+91E1, U+91F5-91F6, U+91FC, U+91FF, U+9211, U+9214-9215, U+921E, U+9229, U+922C, U+923F, U+9245, U+924B, U+9250, U+9257, U+925A, U+925E, U+9293, U+9295-9296, U+92B7, U+92B9, U+92E9, U+92FA, U+9319-931A, U+9322-9323, U+9335, U+933A-933B, U+9344, U+9356, U+935C, U+9360, U+936E, U+9394, U+93AC, U+93B0, U+93B9, U+93C3, U+93C8, U+93D0, U+93D6-93D8, U+93DD, U+93E4-93E5, U+93E8, U+9403, U+9407, U+9410, U+9413-9414, U+941A, U+9421, U+942B, U+9436, U+943A, U+9441, U+9452, U+945A-945B, U+945E, U+9460, U+9462, U+946A, U+9470, U+9475, U+9477, U+947C, U+947E, U+9481, U+9582, U+9587, U+958A, U+9594, U+9596, U+9598-9599, U+95A0, U+95A7, U+95AD, U+95B9, U+95BC, U+95BE, U+95C3, U+95CC-95CD, U+95D4-95D6, U+95DC, U+95E1-95E2, U+95E5, U+9621, U+9628, U+962E-962F, U+9642, U+964B-964C, U+964F, U+965C-965D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold045.woff2") format("woff2");
  unicode-range: U+2FAA-2FAB, U+2FB1-2FB2, U+2FBD-2FC0, U+965E-965F, U+9666, U+966C, U+9672, U+968D, U+9695, U+9697-9698, U+96A7-96A8, U+96B0-96B2, U+96B4, U+96B6, U+96B8-96B9, U+96C9, U+96CB, U+96CE, U+96D5-96D6, U+96D9, U+96F9, U+9704, U+9706, U+9708, U+970D-970F, U+9711, U+9713, U+9719, U+9724, U+972A, U+9730, U+9738-9739, U+973D-973E, U+9742, U+9746, U+9748-9749, U+9760, U+9764, U+9766, U+9768, U+976B, U+9771, U+9779-977A, U+977C, U+9781, U+9785-9786, U+978F-9790, U+979C, U+97A3, U+97A6, U+97A8, U+97AB, U+97B3-97B4, U+97C3, U+97C6, U+97C8, U+97CB, U+97DC, U+97ED, U+97F2, U+97F5-97F6, U+980F, U+9821, U+9846, U+984B, U+984F, U+9871, U+9873-9874, U+98AA, U+98B1, U+98B6, U+98C3, U+98C6, U+98E9, U+98EB, U+98ED-98EE, U+9912, U+9914, U+9918, U+991D, U+9920-9921, U+9924, U+992C, U+992E, U+993D-993E, U+9942, U+994B-994C, U+9950-9951, U+9955, U+9997, U+99A5, U+99BC, U+99D1, U+99D8, U+99DB, U+99DF, U+99E2, U+99ED-99EE, U+99F2, U+99F8, U+99FB, U+9A01, U+9A05, U+9A0F, U+9A2B, U+9A3E, U+9A40, U+9A42-9A43, U+9A45, U+9A5B, U+9A5F, U+9A62, U+9A64-9A65, U+9A69-9A6B, U+9AAD, U+9AB0, U+9ABC, U+9AC0, U+9ACF, U+9AD1, U+9AD3-9AD4, U+9ADE-9ADF, U+9AE2-9AE3, U+9AE6, U+9AEB, U+9AF1, U+9AF4, U+9AFB, U+9B06, U+9B18, U+9B1F, U+9B23, U+9B25, U+9B27, U+9B29-9B2A, U+9B2E-9B2F, U+9B32, U+9B3B, U+9B43, U+9B4D-9B4E, U+9B51, U+9B58, U+9B74, U+9B83, U+9B91, U+9B93, U+9B96-9B97, U+9B9F-9BA0, U+9BB4, U+9BB9, U+9BC0, U+9BC6, U+9BCA, U+9BCF, U+9BD1-9BD2, U+9BD4, U+9BE1-9BE3;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold046.woff2") format("woff2");
  unicode-range: U+2F01, U+2FC4, U+2FC6, U+2FCB-2FCC, U+2FD2, U+2FD4-2FD5, U+3402, U+34B5, U+34DB, U+378D, U+37E2, U+3B22, U+3BB6, U+3BC3, U+3C0F, U+3E3F, U+3F72, U+4264, U+4453, U+445B, U+459D, U+45EA, U+4844, U+49B0, U+4C17, U+4E28, U+4E2F-4E30, U+4E8D, U+4EE1, U+4EFD, U+4EFF, U+4F03, U+4F0B, U+4F48-4F49, U+4F56, U+4F5F-4F60, U+4F6A, U+4F6C, U+4F7E, U+4F8A, U+4F94, U+4F97, U+4FC9, U+5001-5002, U+500E, U+5018, U+5027, U+502E, U+503B, U+5040-5041, U+5094, U+50CC, U+50D0, U+50E6, U+50F2, U+5103, U+5106, U+510B, U+511E, U+9BE4, U+9BF0-9BF2, U+9C04, U+9C06, U+9C08-9C09, U+9C0C, U+9C12, U+9C14-9C15, U+9C1B, U+9C21, U+9C24-9C25, U+9C2E, U+9C30, U+9C32, U+9C3E, U+9C46-9C47, U+9C5A, U+9C60, U+9C67, U+9C76, U+9C78, U+9CE7, U+9CEB-9CEC, U+9CF0, U+9D03, U+9D06, U+9D08, U+9D12, U+9D15, U+9D1F, U+9D23, U+9D26, U+9D2A, U+9D3E-9D3F, U+9D41, U+9D44, U+9D46, U+9D48, U+9D50-9D51, U+9D59, U+9D5D-9D5E, U+9D64, U+9D6F, U+9D72, U+9D7A, U+9D87, U+9D89, U+9D9A, U+9DA4, U+9DA9, U+9DAB, U+9DB2, U+9DB8, U+9DBA-9DBB, U+9DC1-9DC2, U+9DC6, U+9DCF, U+9DD3, U+9DD9, U+9DE6, U+9DED, U+9DEF, U+9DF8, U+9DFD, U+9E1A-9E1B, U+9E75, U+9E79, U+9E7D, U+9E81, U+9E88, U+9E8B-9E8C, U+9E91, U+9E95, U+9E9D, U+9EA5, U+9EAA, U+9EAD, U+9EB8, U+9EBC, U+9ECC, U+9ECF-9ED0, U+9ED4, U+9EDC-9EDE, U+9EE0, U+9EE5, U+9EE8, U+9EEF, U+9EF6-9EF7, U+9EF9, U+9EFB-9EFD, U+9F07-9F08, U+9F15, U+9F21, U+9F2C, U+9F4E-9F4F, U+9F52, U+9F54, U+9F5F-9F61, U+9F63, U+9F66, U+9F6A, U+9F6C, U+9F72, U+9F76-9F77, U+9F9C-9F9D, U+9FA0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold047.woff2") format("woff2");
  unicode-range: U+2E8F, U+5135, U+514A, U+5155, U+5157, U+519D, U+51C3, U+51CA, U+51DE, U+51E2, U+51EE, U+5201, U+5213, U+5215, U+5249, U+5257, U+5261, U+5293, U+52C8, U+52CC, U+52D0, U+52D6, U+52DB, U+52F0, U+52FB, U+5300, U+5307, U+531C, U+5361, U+5363, U+5393, U+539D, U+53B2, U+5412, U+5427, U+544D, U+546B, U+5474, U+547F, U+5488, U+5496, U+549C, U+54A1, U+54A9, U+54C6, U+54FF, U+550E, U+552B, U+5535, U+5550, U+5581, U+5586, U+558E, U+55AD, U+55CE, U+5608, U+560E, U+563B, U+5649, U+5666, U+566F, U+5671-5672, U+5676, U+569E, U+56A9, U+56AC, U+56B3, U+56C9, U+570A, U+5721, U+572F, U+5733-5734, U+5770, U+5777, U+577C, U+579C, U+57B8, U+57C7-57C8, U+57CF, U+57E4, U+57ED, U+57F5-57F6, U+57FF, U+5809, U+5864, U+5889, U+58A9, U+58CE, U+58D2, U+58D4, U+58DA, U+58E0, U+58E9, U+590C, U+595D, U+596D, U+598B, U+5992, U+59A4, U+59C3, U+59D2, U+59DD, U+59F8, U+5A13, U+5A23, U+5A67, U+5A6D, U+5A77, U+5A7E, U+5A84, U+5A9E, U+5AA7, U+5AC4, U+5B19, U+5B25, U+5B41, U+5B56, U+5B7D, U+5B93, U+5BD8, U+5C12, U+5C1E, U+5C23, U+5C2B, U+5C62, U+5C7A, U+5C8F, U+5C9F, U+5CA3, U+5CAA, U+5CBA, U+5CCB, U+5CD0, U+5CD2, U+5CF4, U+5D0D, U+5D27, U+5D46-5D47, U+5D4A, U+5D53, U+5D6D, U+5D81, U+5DA0, U+5DA4, U+5DA7, U+5DB8, U+5DCB, U+5E14, U+5E18, U+5E58, U+5E5E, U+5E77, U+5EBE, U+5ECB, U+5EF9, U+5F00, U+5F02, U+5F07, U+5F1D, U+5F23, U+5F34, U+5F36, U+5F3D, U+5F40, U+5F54, U+5F58, U+5F64, U+5F67, U+5F7D, U+5F89, U+5F9C, U+5FA7, U+5FAF, U+5FC9, U+5FDE, U+5FE1, U+5FE9, U+600D, U+6014, U+6018;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold048.woff2") format("woff2");
  unicode-range: U+6033, U+6035, U+6047, U+609D-609E, U+60CB, U+60D4-60D5, U+60DD, U+60F8, U+611C, U+612B, U+6130, U+6137, U+618D, U+61B9, U+61BC, U+6222, U+623E, U+6243, U+6256, U+625A, U+626F, U+6285, U+62C4, U+62D6, U+62FC, U+630A, U+6318, U+6339, U+6343, U+6365, U+637C, U+63E5, U+63F5, U+6410, U+6422, U+6460, U+646D, U+6479, U+64BE-64BF, U+64C4, U+64CE, U+64D0, U+64F7, U+64FB, U+6522, U+6529, U+6567, U+659D, U+6600, U+6609, U+6615, U+661E, U+6622, U+6624, U+662B, U+6630-6631, U+6633, U+663A, U+6648, U+664C, U+6659, U+6661, U+6665, U+6673, U+6677-6678, U+668D, U+66A0, U+66B2, U+66BB, U+66C8, U+66DB, U+66E8, U+66FA-66FB, U+6713, U+6733, U+6747-6748, U+6766, U+677B, U+6781, U+6793, U+6798, U+679B, U+67BB, U+67C0, U+67D7, U+67F9, U+67FC, U+6801, U+681D, U+682C, U+6831, U+6852, U+685B, U+6872, U+6875, U+68A3, U+68A5, U+68B2, U+68C8, U+68D0, U+68E8, U+68ED, U+68F0-68F1, U+68FC, U+690A, U+6935, U+6942, U+6949, U+6957, U+6963-6964, U+6968, U+6980, U+69A5, U+69AD, U+69CF, U+69E2, U+69E9-69EA, U+69F5-69F6, U+6A0F, U+6A15, U+6A3B, U+6A3E, U+6A45, U+6A50, U+6A56, U+6A5B, U+6A73, U+6A89, U+6A94, U+6A9D-6A9E, U+6AA5, U+6AE4, U+6AE7, U+6B1B, U+6B1E, U+6B2C, U+6B35, U+6B46, U+6B56, U+6B60, U+6B67, U+6B82, U+6BA9, U+6BAD, U+6BD6-6BD7, U+6BFF, U+6C05, U+6C10, U+6C33, U+6C59, U+6C5C, U+6C74, U+6C76, U+6C85-6C86, U+6C98, U+6C9C, U+6CAA, U+6CC6, U+6CD4, U+6CE0, U+6CEB, U+6CEE, U+6CFB, U+6D04, U+6D0E, U+6D2E, U+6D31, U+6D39, U+6D3F, U+6D58, U+6D65, U+6D82, U+6D87, U+6D94, U+6DAA;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold049.woff2") format("woff2");
  unicode-range: U+2F67, U+6DAC, U+6DBF, U+6DC4, U+6DD6, U+6DDB, U+6DDD, U+6DFC, U+6E44, U+6E5C, U+6E5E, U+6EB1, U+6EC1, U+6EC7, U+6ECE, U+6F10, U+6F1A, U+6F2A, U+6F2F, U+6F33, U+6F51, U+6F59, U+6F5E, U+6F61-6F62, U+6F7E, U+6F88, U+6F8C-6F8D, U+6F94, U+6FA0, U+6FA7, U+6FB6, U+6FBC, U+6FC7, U+6FCA, U+6FF0, U+6FF5, U+6FF9, U+7005, U+704A, U+704E, U+705D-705E, U+7064, U+7075, U+7085, U+70A4, U+70AB, U+70B7, U+70D4, U+70D8, U+70E4, U+710F, U+711E, U+7120, U+712B, U+712E, U+7146-7147, U+7151-7152, U+715C, U+7160, U+7168, U+7185, U+7187, U+7192, U+71BA, U+71C1, U+71C4, U+71FE, U+7200, U+7215, U+7255-7256, U+728D, U+729B, U+72BE, U+72FB, U+7327-7328, U+7350, U+7366, U+737C, U+7395, U+739F-73A0, U+73A2, U+73A6, U+73AB, U+73C9, U+73CF, U+73D6, U+73D9, U+73E3, U+73E9, U+7407, U+740A, U+741A-741B, U+7426, U+7428, U+742A-742C, U+742E-7430, U+7444, U+7446-7447, U+744B, U+7457, U+7462, U+746B, U+746D, U+7486-7487, U+7489, U+7490, U+7498, U+749C, U+749F, U+74A3, U+74A6, U+74A8-74A9, U+74B5, U+74BF, U+74C8-74C9, U+74FF, U+7501, U+7517, U+752F, U+756F, U+7579, U+7592, U+75CE, U+75E4, U+7600, U+7602, U+7608, U+7615-7616, U+7619, U+761E, U+762D, U+7635, U+7643, U+764B, U+7664-7665, U+766D, U+766F, U+7671, U+7681, U+769B, U+769D-769E, U+76A6, U+76AA, U+76B6, U+76C5, U+76CC, U+76CE, U+76D4, U+76E6, U+76F1, U+76FC, U+770A, U+7719, U+7734, U+7736, U+7746, U+774D-774E, U+775C, U+775F, U+7762, U+777A, U+7780, U+7794, U+77AA, U+77E0, U+782D, U+7843, U+784E-784F, U+7851, U+7868;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold050.woff2") format("woff2");
  unicode-range: U+2F71, U+786E, U+78AD, U+78B0, U+78E4, U+78F2, U+78F7, U+7900, U+791C, U+792E, U+7931, U+7934, U+7945-7946, U+795C, U+7979, U+7998, U+79B8, U+79C8, U+79CA, U+79D4, U+79DE, U+79EB, U+79ED, U+7A03, U+7A39, U+7A5D, U+7A6D, U+7A85, U+7AA0, U+7AB3, U+7ABB, U+7ACE, U+7AEB, U+7AFD, U+7B12, U+7B2D, U+7B3B, U+7B47, U+7B4E, U+7B60, U+7B6D, U+7B6F, U+7B9E, U+7BD7, U+7BD9, U+7C01, U+7C20, U+7C31, U+7C33, U+7C36, U+7C59, U+7C6D, U+7C79, U+7C8F, U+7C94, U+7CA0, U+7CBC, U+7CD5, U+7CD9, U+7CDD, U+7D07-7D08, U+7D13, U+7D1D, U+7D23, U+7D31, U+7D41, U+7D48, U+7D53, U+7D5C, U+7D7A, U+7D83, U+7D8B, U+7DA6, U+7DC2, U+7DCC, U+7E08, U+7E11, U+7E15, U+7E28, U+7E47, U+7E52, U+7E8A, U+7E8D, U+7F47, U+7F91, U+7F97, U+7FBF, U+7FCE, U+7FDB, U+7FDF, U+7FEC, U+7FEE, U+7FFA, U+8014, U+8026, U+8035, U+8037, U+803C, U+80CA, U+80D7, U+80E0, U+80F3, U+8118, U+814A, U+8160, U+8167-8168, U+816D, U+81BB, U+81CA, U+81CF, U+81D7, U+8260, U+8274, U+828E, U+82A1, U+82A3-82A4, U+82A9, U+82AE, U+82B7, U+82BE-82BF, U+82C6, U+82D5, U+82FD-82FE, U+8300-8301, U+8322, U+832D, U+833A, U+8343, U+8347, U+8351, U+8355, U+8362, U+837D, U+8386, U+8392, U+8398, U+83A7, U+83A9, U+83BF-83C0, U+83C7, U+83CF, U+83D1, U+83E1, U+83EA, U+8401, U+8406, U+8448, U+845F, U+8470, U+8473, U+8485, U+849E, U+84AF, U+84B4, U+84BA, U+84C0, U+84C2, U+851E, U+852F, U+8532, U+8559, U+8564, U+857A, U+858C, U+858F, U+85A2, U+85AD, U+85CB, U+85CE, U+85ED, U+85FF, U+8604-8605, U+8610, U+8612, U+8618, U+8629, U+8638, U+8641, U+8657, U+8662;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold051.woff2") format("woff2");
  unicode-range: U+2FA1, U+866C, U+8675, U+8698, U+86B8, U+86FA, U+86FC-86FD, U+870B, U+8771, U+8787-8788, U+87AC-87AD, U+87B5, U+87D6, U+8806, U+880A, U+8810, U+8814, U+8898, U+88AA, U+88CA, U+88CE, U+88F5, U+8918-891A, U+891C, U+8927, U+8930, U+8932, U+8939, U+8940, U+8994, U+89D4, U+89E5, U+89F6, U+8A12, U+8A15, U+8A22, U+8A37, U+8A47, U+8A4E, U+8A5D, U+8A61, U+8A75, U+8A79, U+8AA7, U+8AD0, U+8ADF, U+8AF4, U+8AF6, U+8B46, U+8B54, U+8B59, U+8B69, U+8B9D, U+8C49, U+8C68, U+8CE1, U+8CF8, U+8CFE, U+8D12, U+8D1B, U+8DAF, U+8DCE, U+8DD1, U+8DD7, U+8E20, U+8E23, U+8E3D, U+8E70, U+8E7B, U+8EFA, U+8F1E, U+8F2D, U+8F36, U+8F54, U+8FA6, U+8FB5, U+8FE4, U+8FE8, U+8FEE, U+9008, U+902D, U+9088, U+9095, U+9097, U+9099, U+909B, U+90A2, U+90B3, U+90BE, U+90C4-90C5, U+90C7, U+90D7, U+90DD, U+90EF, U+90F4, U+9114-9116, U+9122-9123, U+912F, U+9131, U+9134, U+913D, U+9148, U+915B, U+9183, U+919E, U+91B1, U+91BC, U+91D7, U+91E4-91E5, U+91ED, U+91F1, U+91FB, U+9207, U+9210, U+9238-923A, U+923C, U+9240, U+9243, U+924F, U+9278, U+9288, U+92C2, U+92CB-92CC, U+92D3, U+92E0, U+92FF, U+931F, U+9321, U+9325, U+9348-9349, U+9364-9365, U+936A, U+9370, U+939B, U+93A3, U+93BA, U+93C6, U+93DE-93DF, U+93FD, U+9404, U+9433, U+944A, U+9463, U+946B, U+9471-9472, U+958E, U+959F, U+95A6, U+95A9, U+95AC, U+95B6, U+95BD, U+95CB, U+95D0, U+95D3, U+95DA, U+95DE, U+9658, U+9684, U+969D, U+96A4-96A5, U+96D2, U+96DE, U+96E9, U+96EF, U+9733, U+973B, U+974D-974F, U+975A, U+976E, U+9773, U+9795;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold052.woff2") format("woff2");
  unicode-range: U+2E97, U+3406, U+342C, U+342E, U+3468, U+346A, U+3492, U+34BC, U+34C1, U+34C7, U+351F, U+355D-355E, U+3563, U+356E, U+35A6, U+35A8, U+35C5, U+35DA, U+35F4, U+3605, U+364A, U+3691, U+3696, U+3699, U+36CF, U+3761-3762, U+376B-376C, U+3775, U+37C1, U+37E8, U+37F4, U+37FD, U+3800, U+382F, U+3836, U+3840, U+385C, U+3861, U+38FA, U+3917, U+391A, U+396F, U+3A6E, U+3A73, U+3AD6-3AD7, U+3AEA, U+3B0E, U+3B1A, U+3B1C, U+3B6D, U+3B77, U+3B87-3B88, U+3B8D, U+3BA4, U+3BCD, U+3BF0, U+97AE, U+97BA, U+97C1, U+97C9, U+97DB, U+97DE, U+97F4, U+980A, U+981E, U+982B, U+9852-9853, U+9856-9857, U+9859, U+9865, U+986C, U+98BA, U+98C8, U+98E7, U+9958, U+999E, U+9A02-9A03, U+9A24, U+9A2D-9A2E, U+9A38, U+9A4A, U+9A4E, U+9AB6, U+9AC1, U+9AC3, U+9ACE, U+9AD6, U+9AF9, U+9B02, U+9B08, U+9B20, U+9B2D, U+9B5E, U+9B66, U+9B72, U+9B75, U+9B79, U+9B84, U+9B8A, U+9B8F, U+9B9E, U+9BA7, U+9BC1, U+9BCE, U+9BE5, U+9BF8, U+9BFD, U+9C00, U+9C23, U+9C41, U+9C4F-9C50, U+9C53, U+9C63, U+9C65, U+9C77, U+9D1D-9D1E, U+9D43, U+9D47, U+9D52, U+9D63, U+9D70, U+9D7C, U+9D8A, U+9D96, U+9DAC, U+9DBC, U+9DC0, U+9DE7, U+9E07, U+9E15, U+9E9E, U+9EA4, U+9EAC, U+9EAF, U+9F10, U+9F39, U+9F57, U+9F94, U+9F97, U+9FA2, U+F970, U+F9DC, U+FA0F-FA10, U+FA14-FA15, U+FA32, U+FA36, U+FA39, U+FA3C, U+FA3E, U+FA42, U+FA60, U+FA6A, U+2000B, U+2123D, U+2131B, U+2146E, U+216B4, U+218BD, U+21E34, U+231C4, U+235C4, U+2373F, U+23763, U+23CFE, U+247F1, U+2548E, U+2550E, U+25771, U+259C4, U+25DA1, U+26AFF, U+26E40, U+270F4, U+27684, U+28277, U+283CD, U+2A190;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold053.woff2") format("woff2");
  unicode-range: U+2E83, U+2E85, U+2EA6, U+3C26, U+3CC3, U+3CD2, U+3D11, U+3D1E, U+3D64, U+3D9A, U+3DC0, U+3DD4, U+3E05, U+3E60, U+3E66, U+3E68, U+3E83, U+3E94, U+3F57, U+3F75, U+3F77, U+3FAE, U+3FC9, U+3FD7, U+4039, U+4058, U+4093, U+4105, U+4148, U+414F, U+4163, U+41B4, U+41BF, U+41E6, U+41EE, U+41F3, U+4207, U+420E, U+42C6, U+42D6, U+42DD, U+4302, U+432B, U+4343, U+43EE, U+43F0, U+4408, U+4417, U+441C, U+4422, U+4476, U+447A, U+4491, U+44B3, U+44BE, U+44D4, U+4508, U+450D, U+4525, U+4543, U+45B8, U+45E5, U+460F, U+4641, U+4665, U+46A1, U+46AF, U+470C, U+4764, U+47FD, U+4816, U+484E, U+48B5, U+49E7, U+49FA, U+4A04, U+4A29, U+4ABC, U+4B3B, U+4BC2, U+4BCA, U+4BD2, U+4BE8, U+4C20, U+4CC4, U+4CD1, U+4D07, U+4D77, U+4E02, U+4E0F, U+4E12, U+4E29, U+4E2B-4E2C, U+4E2E, U+4E40, U+4E47-4E48, U+4E51, U+4E5A, U+4E69, U+4E9D, U+4EB9, U+4EBB-4EBC, U+4EC3, U+4EC8, U+4ED0, U+4EDA, U+4EEB, U+4EF1, U+4EF5, U+4F00, U+4F16, U+4F37, U+4F3E, U+4F54, U+4F58, U+4F64, U+4F77-4F78, U+4F7A, U+4F7D, U+4F82, U+4F85, U+4F92, U+4F9A, U+4FB2, U+4FBE, U+4FC5, U+4FCB, U+4FCF, U+4FD2, U+4FE6, U+4FF2, U+5000, U+5010, U+5013, U+501C, U+501E, U+5022, U+5042, U+5046, U+504E, U+5053, U+5057, U+5063, U+5066, U+506A, U+5070, U+5088, U+5092-5093, U+5095-5096, U+509C, U+50A3, U+50AA, U+50B1, U+50BA-50BB, U+50C4, U+50C7, U+50CE, U+50D4, U+50D9, U+50E1, U+50E9, U+50F3, U+5108, U+5117, U+511B, U+5160, U+5173, U+517B, U+5183, U+518B, U+5198, U+51A3, U+51AD, U+51BC, U+51F3-51F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold054.woff2") format("woff2");
  unicode-range: U+2E89, U+5202, U+5212, U+5216, U+5255, U+525C, U+526C, U+5277, U+5282, U+5284, U+5298, U+52A4, U+52A6, U+52AF, U+52BA-52BB, U+52CA, U+52D1, U+52F7, U+530A-530B, U+5324, U+5335, U+533E, U+5342, U+5367, U+536C, U+537A, U+53A4, U+53B4, U+53B7, U+53C0, U+53D5, U+53DA, U+53F4-53F5, U+5424, U+5428, U+5443, U+5455, U+5462, U+5466, U+546C, U+548A, U+548D, U+5495, U+54A0, U+54A6, U+54AD-54AE, U+54B7, U+54BA, U+54BF, U+54C3, U+54EC, U+54EF, U+54F1, U+54F3, U+5500-5501, U+5509, U+553C, U+5541, U+5547, U+554A, U+5560-5561, U+5564, U+557D, U+5582, U+5588, U+5591, U+55BF, U+55C9, U+55CC, U+55D1-55D2, U+55DD, U+55E2, U+55E9, U+5607, U+5610, U+5628, U+5630, U+5637, U+563D, U+563F-5640, U+5647, U+565E, U+5660, U+566D, U+5688, U+568C, U+5695, U+569A, U+569D, U+56A8, U+56AD, U+56B2, U+56C5, U+56CD, U+56DF, U+56E8, U+56F6-56F7, U+5715, U+5723, U+5729, U+5745-5746, U+574C-574D, U+5768, U+576F, U+5773-5775, U+577B, U+579A, U+579D-579E, U+57A8, U+57AC, U+57CC, U+57D7, U+57DE, U+57E6, U+57F0, U+57F8, U+57FB, U+57FD, U+5804, U+581E, U+5820, U+5827, U+5832, U+5839, U+5849, U+584C, U+5867, U+588A-588B, U+588D, U+588F-5890, U+5894, U+589D, U+58AA, U+58B1, U+58C3, U+58CD, U+58E2, U+58F3-58F4, U+5905-5906, U+590B, U+590D, U+5914, U+5924, U+593D, U+5946, U+595B, U+595F, U+5975-5976, U+599F, U+59AE, U+59BC, U+59C8, U+59CD, U+59DE, U+59E3-59E4, U+59E7, U+59EE, U+5A0C-5A0D, U+5A17, U+5A27, U+5A2D, U+5A55, U+5A65, U+5A7A, U+5A8B, U+5A9C, U+5A9F-5AA0, U+5AA2, U+5AB1, U+5AB3, U+5AB5, U+5ABA, U+5ABF, U+5ADA, U+5ADC, U+5AE0, U+5AE5, U+5AEE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold055.woff2") format("woff2");
  unicode-range: U+2E96, U+2E98, U+5AF0, U+5AF5, U+5B00, U+5B08, U+5B17, U+5B2D, U+5B34, U+5B4C, U+5B52, U+5B68, U+5B6F, U+5B7C, U+5B7F, U+5B81, U+5B84, U+5B96, U+5BAC, U+5BC0, U+5BCE, U+5BD6, U+5BF1, U+5BFD, U+5C03, U+5C29, U+5C30, U+5C5F, U+5C63, U+5C67-5C69, U+5C70, U+5C7C, U+5C88, U+5C8A, U+5CA0, U+5CA2, U+5CA6-5CA7, U+5CAD, U+5CB5, U+5CC9, U+5D06, U+5D10, U+5D1D, U+5D20, U+5D24, U+5D26, U+5D2B, U+5D31, U+5D39, U+5D42, U+5D61, U+5D6A, U+5D70, U+5D88, U+5D92, U+5D94, U+5D97, U+5D99, U+5DB0, U+5DB2, U+5DB4, U+5DB9, U+5DD1, U+5DD7-5DD8, U+5DE0, U+5DE4, U+5DE9, U+5E00, U+5E12, U+5E15, U+5E1F, U+5E2E, U+5E3E, U+5E49, U+5E56, U+5E6B-5E6E, U+5EA5, U+5EAA, U+5EAC, U+5EB9, U+5EBF, U+5EC6, U+5ED2, U+5ED9, U+5EFD, U+5F08, U+5F0E, U+5F1C, U+5F1E, U+5F47, U+5F63, U+5F72, U+5F7E, U+5F8F, U+5FA2, U+5FA4, U+5FB8, U+5FC4, U+5FC7, U+5FCB, U+5FD2-5FD4, U+5FE2, U+5FEE-5FEF, U+5FF3, U+5FFC, U+6017, U+6022, U+6024, U+604C, U+607F, U+608A, U+6095, U+60A8, U+60B0-60B1, U+60BE, U+60C8, U+60D9, U+60DB, U+60EE, U+60F2, U+60F5, U+6110, U+6112-6113, U+6119, U+611E, U+613A, U+6141, U+6146, U+6160, U+617C, U+6192-6193, U+6197-6198, U+61A5, U+61A8, U+61AD, U+61D5, U+61DD, U+61DF, U+61F5, U+6215, U+6223, U+6229, U+6246, U+624C, U+6251-6252, U+6261, U+6264, U+626D, U+6273, U+627B, U+6299, U+62A6, U+62D5, U+62FD, U+6303, U+630D, U+6310, U+6332, U+6335, U+633B-633C, U+6341, U+6344, U+634E, U+6359, U+636C, U+6384;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold056.woff2") format("woff2");
  unicode-range: U+6394, U+6399, U+63BD, U+63D4-63D5, U+63DC, U+63E0, U+63EB-63EC, U+63F2, U+63F7, U+6409, U+641E, U+6425, U+6429, U+642F, U+645A-645B, U+645D, U+6473, U+647D, U+6487, U+6491, U+649D, U+649F, U+64CB-64CC, U+64D5, U+64D7, U+64E4-64E5, U+64FF, U+6504, U+650F, U+6514, U+6516, U+651E, U+6532, U+6544, U+6554, U+656B, U+657A, U+6581, U+6584-6585, U+658A, U+65B2, U+65B5, U+65B8, U+65BF, U+65C2, U+65C9, U+65D4, U+65F2, U+65F9, U+65FC, U+6604, U+6608, U+6621, U+662A, U+6645, U+664E, U+6651, U+6657, U+665B, U+6663, U+666A-666D, U+667B, U+6680, U+6690, U+6692, U+6699, U+66AD, U+66B1, U+66B5, U+66BF, U+66EC, U+6701, U+6705, U+6712, U+6719, U+674C-674D, U+6754, U+675D, U+6774, U+6776, U+6792, U+67B0, U+67B2, U+67C3, U+67C8, U+67D2, U+67D9, U+67DB, U+67F0, U+67F7, U+6810, U+6818, U+681F, U+682D, U+6833, U+683B, U+683E, U+6844-6845, U+6849, U+684C, U+6855, U+6857, U+686B, U+686E, U+687A, U+687C, U+6882, U+6890, U+6896, U+6898-689A, U+689C, U+68AA-68AB, U+68B4, U+68BB, U+68C3, U+68C5, U+68CC, U+68CF, U+68D6, U+68D9, U+68E4-68E5, U+68EC, U+68F7, U+68FB, U+6903, U+6907, U+693B, U+6946, U+6969, U+696C, U+6972, U+697A, U+697F, U+6992, U+6996, U+6998, U+69A6, U+69B0, U+69B7, U+69BA, U+69BC, U+69C0, U+69D1, U+69D6, U+69E3, U+69EE-69EF, U+69F3-69F4, U+69FE, U+6A11, U+6A1A, U+6A1D, U+6A30, U+6A32-6A34, U+6A3F, U+6A46, U+6A49, U+6A4E, U+6A52, U+6A64, U+6A7A, U+6A7E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold057.woff2") format("woff2");
  unicode-range: U+2E9E, U+2EA1, U+2EA3, U+6A83, U+6A8B, U+6A91, U+6A9F, U+6AA1, U+6AAB, U+6ABD, U+6AC6, U+6AD0, U+6AD4, U+6ADC-6ADD, U+6AEC, U+6AF1-6AF3, U+6AFD, U+6B0B, U+6B0F-6B11, U+6B17, U+6B2F, U+6B4A, U+6B58, U+6B6C, U+6B75, U+6B7A, U+6B81, U+6B9B, U+6BAE, U+6BBD-6BBE, U+6BC7-6BC9, U+6BDA, U+6BE6-6BE7, U+6BEE, U+6BF1, U+6C02, U+6C0A, U+6C0E, U+6C35-6C36, U+6C3A, U+6C3F, U+6C4D, U+6C5B, U+6C67, U+6C6D, U+6C84, U+6C89, U+6C94-6C95, U+6C97, U+6CAD, U+6CC2, U+6CD0, U+6CD6, U+6CDA, U+6CDC, U+6CE9, U+6CEC-6CED, U+6D00, U+6D0A, U+6D24, U+6D26-6D27, U+6D2F, U+6D34, U+6D3C, U+6D5B, U+6D5E, U+6D60, U+6D70, U+6D80-6D81, U+6D8A, U+6D8D, U+6D91, U+6D98, U+6DAB, U+6DAE, U+6DB4, U+6DC2, U+6DC8, U+6DCE-6DD0, U+6DDF, U+6DE9, U+6DF6, U+6E17, U+6E1E, U+6E22, U+6E27, U+6E32, U+6E36, U+6E3C, U+6E48-6E49, U+6E4B-6E4C, U+6E4F, U+6E51, U+6E53-6E54, U+6E57, U+6E63, U+6E93, U+6EA7, U+6EB4, U+6EBF, U+6EC3, U+6ECA, U+6ED9, U+6EEB, U+6EF9, U+6EFB, U+6F0A, U+6F0C, U+6F18, U+6F25, U+6F35-6F36, U+6F3C, U+6F52, U+6F57, U+6F5A, U+6F60, U+6F68, U+6F7D, U+6F90, U+6F96, U+6F98, U+6F9F, U+6FA5, U+6FAF, U+6FB5, U+6FBE, U+6FC8-6FC9, U+6FDA, U+6FDE, U+6FE9, U+6FFC, U+7000, U+7007, U+700A, U+7023, U+7039-703A, U+703C, U+7043, U+7047, U+704B, U+7054, U+7065, U+7069, U+706C, U+706E, U+7076, U+707E, U+7081, U+7086, U+7095, U+7097, U+709F, U+70B1, U+70BB, U+70CA, U+70D1, U+70D3, U+70DC, U+70EC, U+7103-7104;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold058.woff2") format("woff2");
  unicode-range: U+2EA8, U+7106-7108, U+710C, U+712F, U+7131, U+714A, U+7150, U+7153, U+715E, U+7180, U+7196, U+719B, U+71A0, U+71A2, U+71AE-71AF, U+71B3, U+71CB, U+71D3, U+71D9, U+71DC, U+7207, U+722B, U+7234, U+7238-7239, U+7242, U+7253, U+7257, U+7263, U+726E-726F, U+7278, U+727F, U+728E, U+72AD-72AE, U+72B0-72B1, U+72C1, U+72CC, U+72F3, U+72FA, U+7307, U+7312, U+7318-7319, U+732C, U+7331, U+7333, U+7339, U+733D, U+7352, U+736B-736C, U+736E-736F, U+7371, U+7377, U+7381, U+7385, U+738A, U+7394, U+7398, U+739C, U+739E, U+73A5, U+73A8, U+73B5, U+73B7, U+73B9, U+73BC, U+73BF, U+73C5, U+73CB, U+73E1, U+73E7, U+73F9-73FA, U+7401, U+7413, U+7424, U+7431, U+7439, U+7440, U+7443, U+744D, U+7452-7453, U+745D, U+7471, U+7481, U+7485, U+7488, U+7492, U+7497, U+7499, U+74A0-74A1, U+74A5, U+74AA-74AB, U+74B9-74BB, U+74D6, U+74D8, U+74DE, U+74EB, U+74EF, U+74FA, U+7520, U+7524, U+752A, U+753D-753E, U+7540, U+7548, U+754E, U+7550, U+7552, U+756C, U+7571-7572, U+757A, U+757D-757E, U+7581, U+758C, U+75A2, U+75B0, U+75B7, U+75BF-75C0, U+75C6, U+75CF, U+75D3, U+75DD, U+75DF-75E0, U+75E7, U+75EC, U+75EE, U+75F1, U+75F9, U+7603, U+7607, U+760F, U+7613, U+7618, U+761B-761C, U+7625, U+7628, U+7633, U+763C, U+7641, U+7649, U+7655, U+766E, U+7695, U+769C, U+76A0-76A1, U+76A7-76A8, U+76AF, U+76C9, U+76E8, U+76EC, U+7717, U+771A, U+772D, U+7735, U+7758, U+7760, U+776A, U+7772, U+777C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold059.woff2") format("woff2");
  unicode-range: U+2EAD, U+777D, U+779A, U+779F, U+77A2, U+77A4, U+77A9, U+77DE-77DF, U+77E4, U+77E6, U+77EA, U+77EC, U+77F0, U+77F4, U+77FB, U+7805-7806, U+7809, U+780D, U+7819, U+7821, U+782C, U+7847, U+7864, U+786A, U+788A, U+7894, U+789D-789F, U+78A4, U+78BB, U+78C8, U+78CC, U+78CE, U+78D5, U+78E0-78E1, U+78E6, U+78F9-78FB, U+78FE, U+7910, U+791B, U+7925, U+7930, U+793B, U+794A, U+7958, U+795B, U+7967, U+7972, U+7994-7996, U+799B, U+79A1, U+79A9, U+79B4, U+79BB, U+79C2, U+79C7, U+79CC-79CD, U+79D6, U+7A0A, U+7A11, U+7A15, U+7A1B, U+7A1E, U+7A2D, U+7A38, U+7A47, U+7A4C, U+7A56, U+7A59, U+7A5C, U+7A5F-7A60, U+7A67, U+7A6A, U+7A75, U+7A78, U+7A82, U+7A8A, U+7A90, U+7AA3, U+7AAC, U+7AB9, U+7ABC, U+7ABE, U+7ACC, U+7AD1, U+7AE7-7AE8, U+7AF4, U+7B07, U+7B27, U+7B2A, U+7B2E-7B2F, U+7B31, U+7B3D, U+7B41, U+7B55, U+7B64, U+7B66, U+7B69, U+7B73, U+7B79, U+7B7F, U+7B90-7B91, U+7B9B, U+7BAF, U+7BB5, U+7BBC, U+7BC5, U+7BCA, U+7BD4, U+7BD6, U+7BDA, U+7BEA, U+7BF0, U+7C03, U+7C0B, U+7C0E-7C0F, U+7C26, U+7C45, U+7C4A, U+7C51, U+7C57, U+7C5E, U+7C61, U+7C69, U+7C6E-7C70, U+7CA6, U+7CB6-7CB7, U+7CBF, U+7CC4, U+7CC8, U+7CCD, U+7CD7, U+7CE6, U+7CEB, U+7CF5, U+7D03, U+7D09, U+7D12, U+7D1E, U+7D3D-7D3E, U+7D40, U+7D47, U+7D59-7D5A, U+7D6A, U+7D70, U+7D7F, U+7D86, U+7D88, U+7D8C, U+7D97, U+7D9D, U+7DA7, U+7DAA, U+7DB6-7DB7, U+7DC0, U+7DD7, U+7DD9, U+7DE6, U+7DF1, U+7DF9, U+7E10, U+7E17, U+7E1D, U+7E27, U+7E2C, U+7E45, U+7E73, U+7E75, U+7E7E, U+7E86-7E87, U+7E91, U+7E98, U+7E9A, U+7F3B-7F3C, U+7F3E, U+7F43-7F44, U+7F4F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold060.woff2") format("woff2");
  unicode-range: U+2EAB, U+2EB2, U+2EB9, U+2EBE, U+7F52, U+7F61, U+7F63-7F64, U+7F6D, U+7F7D-7F7E, U+7F90, U+7F96, U+7F9C, U+7FAD, U+7FC3, U+7FCF, U+7FE3, U+7FE5, U+7FEF, U+7FF2, U+8002, U+8008, U+800A, U+800E, U+8011, U+8016, U+8024, U+802C, U+8030, U+8043, U+8066, U+8071, U+8075, U+807B, U+8099, U+809C, U+80A4, U+80A7, U+80B8, U+80C5, U+80D5, U+80D8, U+80E6, U+80F5, U+80FB, U+810D, U+8116, U+811E, U+8124, U+8127, U+812C, U+8135, U+813D, U+8169, U+8181, U+8184-8185, U+8198, U+81B2, U+81C1, U+81C3, U+81D6, U+81DB, U+81E4, U+81EC, U+81FD, U+81FF, U+8204, U+8219, U+8221-8222, U+8232, U+8234, U+823C, U+8245-8246, U+8249, U+824B, U+824F, U+8257, U+825C, U+8263, U+8279, U+827D, U+827F, U+8283, U+828A, U+8293, U+82A7-82A8, U+82B2, U+82B4, U+82BA, U+82BC, U+82E2, U+82E8, U+82F7, U+8307-8308, U+830C, U+831B, U+831D, U+8330, U+833C, U+8344, U+8354, U+8357, U+8363, U+837F, U+838D, U+8394-8395, U+839B, U+839D, U+83C9, U+83D0, U+83D4, U+83DD, U+83E5, U+83F9, U+840F, U+8411, U+8415, U+8417, U+8439, U+844A, U+844F, U+8451-8452, U+8459-845A, U+845C, U+8465, U+8476, U+8478, U+847C, U+8481, U+8497, U+84A6, U+84BE, U+84CE-84CF, U+84D3, U+84DC, U+84E7, U+84EA, U+84EF-84F1, U+84FA, U+84FD, U+850C, U+851B, U+8524-8525, U+852B, U+8534, U+853E, U+854F, U+8551, U+8553, U+855E, U+8561-8562, U+856F, U+857B, U+857D, U+857F, U+8581, U+8586, U+8593, U+859D, U+859F, U+85B7, U+85BC, U+85C7, U+85CA, U+85D8-85D9, U+85DF, U+85E1, U+85E6, U+85F6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold061.woff2") format("woff2");
  unicode-range: U+2EC2-2EC3, U+8600, U+8611, U+861E, U+8621, U+8624, U+8627, U+8639, U+863C, U+8640, U+8653, U+8656, U+866F, U+8677, U+867A, U+8687, U+8689, U+868D, U+8691, U+869C-869D, U+86A8, U+86B1, U+86B3, U+86C1, U+86C3, U+86D1, U+86D5, U+86D7, U+86E3, U+86E6, U+8705, U+8707, U+870E, U+8710, U+8713, U+8719, U+871F, U+8721, U+8723, U+8731, U+873A, U+873E, U+8740, U+8743, U+8751, U+8758, U+8764-8765, U+8772, U+877C, U+8789, U+878B, U+8793, U+87A0, U+87A7, U+87BE, U+87C1, U+87CE, U+87DF, U+87E3, U+87E5-87E6, U+87EA-87EB, U+87ED, U+87F5, U+8801, U+8803, U+880B, U+8813, U+8828, U+882E, U+8832, U+883C, U+884A, U+8858, U+885F, U+8864, U+8869, U+886F, U+88A0, U+88BC-88BE, U+88C0, U+88D1-88D3, U+88DB, U+88F0-88F1, U+8901, U+8937, U+8942, U+8945, U+8949, U+8962, U+8980, U+8989, U+8990, U+899F, U+89B0, U+89B7, U+89D6, U+89D8, U+89EB, U+89F1, U+89F3, U+89FD, U+89FF, U+8A11, U+8A14, U+8A21, U+8A35, U+8A3E, U+8A45, U+8A4D, U+8A58, U+8A90, U+8AAE, U+8AB7, U+8ABE, U+8AD7, U+8AFC, U+8B05, U+8B0A, U+8B0D, U+8B1C, U+8B1F, U+8B2D, U+8B43, U+8B51, U+8B5E, U+8B76, U+8B7F, U+8B81, U+8B8B, U+8B94-8B95, U+8B9C, U+8B9E, U+8C39, U+8C3D, U+8C45, U+8C47, U+8C4F, U+8C54, U+8C57, U+8C69, U+8C6D, U+8C73, U+8C92-8C93, U+8C99, U+8C9B, U+8CA4, U+8CD5-8CD6, U+8CD9, U+8CF0-8CF1, U+8D09, U+8D0E, U+8D6C, U+8D84, U+8D95, U+8DA6, U+8DC6, U+8DC8, U+8DD9, U+8DEC, U+8DFD, U+8E06, U+8E0C, U+8E14, U+8E16, U+8E21-8E22, U+8E27, U+8E36;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold062.woff2") format("woff2");
  unicode-range: U+2ECD, U+2ECF, U+2ED2, U+2ED6, U+8E39, U+8E4B, U+8E54, U+8E62, U+8E6C-8E6D, U+8E6F, U+8E98, U+8E9E, U+8EAE, U+8EB3, U+8EB5-8EB6, U+8EBB, U+8ED1, U+8ED4, U+8EF9, U+8F00, U+8F08, U+8F17, U+8F2B, U+8F40, U+8F4A, U+8F58, U+8FA4, U+8FB4, U+8FB6, U+8FC1, U+8FC6, U+8FCA, U+8FCD, U+8FD3, U+8FD5, U+8FE0, U+8FF1, U+8FF5, U+8FFB, U+9002, U+900C, U+9037, U+9043-9044, U+905D, U+9085, U+908C, U+9090, U+90A1, U+90B0, U+90B6, U+90C8, U+90DC, U+90DF, U+90EB, U+90F2, U+90F6, U+90FE-9100, U+9104, U+9106, U+9118, U+911C, U+911E, U+9137, U+9139-913A, U+9146-9147, U+9157, U+9159, U+9161, U+9164, U+9174, U+9179, U+9185, U+918E, U+91A8, U+91AE, U+91B3, U+91B6, U+91C3-91C4, U+91DA, U+91EC, U+91EE, U+9201, U+920A, U+9216-9217, U+9233, U+9242, U+9247, U+924A, U+924E, U+9251, U+9256, U+9259, U+9260-9261, U+9265, U+9267-9268, U+927C-927D, U+927F, U+9289, U+928D, U+9297, U+9299, U+929F, U+92A7, U+92AB, U+92B2, U+92BF-92C0, U+92C6, U+92CE, U+92D0, U+92D7, U+92D9, U+92E5, U+92E7, U+92F7, U+92F9, U+92FB, U+9302, U+930D, U+9311, U+9315, U+931D-931E, U+9327, U+9329, U+9347, U+9351, U+9357, U+935A, U+936B, U+9371, U+9373, U+9388, U+938B, U+938F, U+939E, U+93A1, U+93C1, U+93C7, U+93DC, U+93E2, U+93E7, U+93F1, U+93F5, U+93FB, U+9409, U+940F, U+9416-9417, U+9432, U+9434, U+943B, U+9445, U+946D, U+946F, U+9578-9579, U+9586, U+958C-958D, U+95AB, U+95B4, U+95C8, U+961D, U+962C, U+9633-9634, U+963C, U+9641, U+9661, U+9682, U+969A, U+96A9, U+96AF, U+96B3, U+96BA, U+96BD, U+96D8, U+96DA, U+96DD, U+9714, U+9723, U+9736, U+9741, U+9747, U+9755, U+9757, U+975B, U+976A, U+9796, U+979A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold063.woff2") format("woff2");
  unicode-range: U+2EDF, U+979E, U+97A2, U+97B1-97B2, U+97BE, U+97CC, U+97D1, U+97D4, U+97D8-97D9, U+97E1, U+97F1, U+9804, U+980D-980E, U+9814, U+9816, U+9823, U+9825, U+9832-9833, U+9847, U+9866, U+98AB, U+98AD, U+98B0, U+98B7-98B8, U+98BB-98BC, U+98BF, U+98C2, U+98C7, U+98CB, U+98E0-98E1, U+98E3, U+98E5, U+98EA, U+98F0-98F1, U+98F3, U+9908, U+9916-9917, U+991A-991C, U+9931-9933, U+993A-993C, U+9940-9941, U+9946, U+994D-994E, U+995C, U+995F-9960, U+99A3, U+99A6, U+99B9, U+99BD, U+99BF, U+99C3, U+99C9, U+99D4, U+99D9, U+99DE, U+99F0, U+99F9, U+99FC, U+9A0A, U+9A11, U+9A16, U+9A1A, U+9A20, U+9A31, U+9A36, U+9A44, U+9A4C, U+9A58, U+9AAF, U+9AB7, U+9AB9, U+9AC6, U+9AD0, U+9AD2, U+9AD5, U+9ADC, U+9AE0, U+9AE5, U+9AE9, U+9B03, U+9B0C, U+9B10, U+9B12, U+9B16, U+9B1C, U+9B2B, U+9B33, U+9B3D, U+9B4B, U+9B63, U+9B65, U+9B6B-9B6C, U+9B73, U+9B76-9B77, U+9BA6, U+9BAC, U+9BB1-9BB2, U+9BB8, U+9BBE, U+9BC7, U+9BD8, U+9BDD, U+9BE7, U+9BEA-9BEB, U+9BEE-9BEF, U+9BF3, U+9BF7, U+9BFA, U+9C16, U+9C18-9C1A, U+9C1D, U+9C22, U+9C27, U+9C29-9C2A, U+9C31, U+9C36-9C37, U+9C45, U+9C49-9C4A, U+9C54, U+9C58, U+9C5B-9C5D, U+9C5F, U+9C69-9C6B, U+9C6D-9C6E, U+9C70, U+9C72, U+9C75, U+9C7A, U+9CE6, U+9CF2, U+9D02, U+9D0B, U+9D11, U+9D17-9D18, U+9D32, U+9D42, U+9D4A, U+9D5F, U+9D62, U+9D69, U+9D6B, U+9D73, U+9D76-9D77, U+9D7E, U+9D84, U+9D8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold064.woff2") format("woff2");
  unicode-range: U+2E87, U+2EA4, U+2EBF-2EC0, U+2ECC, U+9D99, U+9DA1, U+9DB5, U+9DB9, U+9DBD, U+9DBF, U+9DC3, U+9DC7, U+9DC9, U+9DD6, U+9DDA, U+9DDF-9DE0, U+9DE3, U+9DF4, U+9E02, U+9E0A, U+9E0D, U+9E19, U+9E1C-9E1D, U+9E7B, U+9E80, U+9E85, U+9E9B, U+9EA8, U+9EBD, U+9EDF, U+9EE7, U+9EEE, U+9EFF, U+9F02-9F03, U+9F17, U+9F19, U+9F2F, U+9F37, U+9F3A, U+9F3D, U+9F41, U+9F45-9F46, U+9F53, U+9F55, U+9F58, U+9F5D, U+9F69, U+9F6D, U+9F70, U+9F75, U+FA13, U+FA20-FA21, U+FA24, U+FA49, U+FA58, U+FA5D-FA5E, U+FA66, U+20089, U+200A2, U+200A4, U+201A2, U+20213, U+2032B, U+20371, U+20381, U+203F9, U+2044A, U+20509, U+205D6, U+20628, U+2074F, U+20807, U+2083A, U+208B9, U+2097C, U+2099D, U+20AD3, U+20B1D, U+20D45, U+20DE1, U+20E64, U+20E6D, U+20E95, U+20F5F, U+21201, U+21255, U+21274, U+2127B, U+212D7, U+212E4, U+212FD, U+21336, U+21344, U+213C4, U+2146D, U+215D7, U+21647, U+21706, U+21742, U+219C3, U+21C56, U+21D2D, U+21D45, U+21D62, U+21D78, U+21D92, U+21D9C, U+21DA1, U+21DB7, U+21DE0, U+21E33, U+21F1E, U+21F76, U+21FFA, U+2217B, U+22218, U+2231E, U+223AD, U+226F3, U+2285B, U+228AB, U+2298F, U+22AB8, U+22B46, U+22B4F-22B50, U+22BA6, U+22C1D, U+22C24, U+22DE1, U+231B6, U+231C3, U+231F5, U+23372, U+233D0, U+233D2-233D3, U+233D5, U+233DA, U+233DF, U+233E4, U+2344A-2344B, U+23451, U+23465, U+234E4, U+2355A, U+23594, U+23638-2363A, U+23647, U+2370C, U+2371C, U+23764, U+237E7, U+237FF, U+23824, U+2383D, U+23A98, U+23C7F, U+23D00, U+23D0E, U+23D40, U+23DD3, U+23DF9-23DFA, U+23F7E, U+24096, U+24103, U+241C6, U+241FE, U+243BC, U+24629, U+246A5, U+24896, U+24A4D, U+24B56;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold065.woff2") format("woff2");
  unicode-range: U+2EAA, U+2EBD, U+2ECA, U+2EDE, U+4EFC, U+4F39, U+4FCD, U+4FFF, U+50D8, U+50F4, U+5164, U+51BE, U+51EC, U+529C, U+52C0, U+5372, U+53DD, U+5759, U+5765, U+58B2, U+5953, U+5963, U+59BA, U+5CF5, U+5DD0, U+5F21, U+605D, U+6085, U+60DE, U+6111, U+6120, U+6213, U+654E, U+662E, U+663B, U+670E, U+6AE2, U+6C6F, U+6D6F, U+6D96, U+6DF2, U+6DF8, U+6E39, U+24B6F, U+24C16, U+24D14, U+24E0E, U+24E37, U+24E6A, U+24E8B, U+2504A, U+25055, U+25122, U+251A9, U+251CD, U+251E5, U+2521E, U+2524C, U+2542E, U+254D9, U+255A7, U+257A9, U+257B4, U+259D4, U+25AE3-25AE4, U+25AF1, U+25BB2, U+25C4B, U+25C64, U+25E2E, U+25E56, U+25E62, U+25E65, U+25EC2, U+25ED8, U+25EE8, U+25F23, U+25F5C, U+25FD4, U+25FE0, U+25FFB, U+2600C, U+26017, U+26060, U+260ED, U+26270, U+26286, U+2634C, U+26402, U+2667E, U+266B0, U+2671D, U+268DD, U+268EA, U+26951, U+2696F, U+269DD, U+26A1E, U+26A58, U+26A8C, U+26AB7, U+26C29, U+26C73, U+26CDD, U+26E65, U+26F94, U+26FF6-26FF8, U+2710D, U+27139, U+273DA-273DB, U+273FE, U+27410, U+27449, U+27614-27615, U+27631, U+27693, U+2770E, U+27723, U+27752, U+27985, U+27A84, U+27BB3, U+27BBE, U+27BC7, U+27CB8, U+27DA0, U+27E10, U+27FB7, U+2808A, U+280BB, U+28282, U+282F3, U+2840C, U+28455, U+2856B, U+285C8-285C9, U+286D7, U+286FA, U+28946, U+28949, U+2896B, U+28987-28988, U+289BA-289BB, U+28A1E, U+28A29, U+28A43, U+28A71, U+28A99, U+28ACD, U+28ADD, U+28AE4, U+28BC1, U+28BEF, U+28D10, U+28D71, U+28DFB, U+28E1F, U+28E36, U+28E89, U+28EEB, U+28F32, U+28FF8, U+292A0, U+292B1, U+29490, U+295CF, U+2967F, U+296F0, U+29719, U+29750, U+298C6, U+29A72, U+29DDB, U+29E15, U+29E3D, U+29E49, U+29E8A, U+29EC4, U+29EDB, U+29EE9, U+29FCE, U+29FD7, U+2A01A, U+2A02F, U+2A082, U+2A0F9, U+2A38C, U+2A437, U+2A5F1, U+2A602, U+2A61A, U+2A6B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold066.woff2") format("woff2");
  unicode-range: U+2FAD, U+3EDA, U+4103, U+4E04-4E05, U+4E0C, U+4E1F, U+4E23-4E24, U+4E35, U+4E41, U+4E44, U+4E5C, U+4E63, U+4E68, U+4E74-4E75, U+4E79, U+4E7F, U+4E96-4E97, U+4EAF, U+4EDB, U+4EE0, U+4EE2, U+4EE8, U+4EEF, U+4EF3, U+4EFE, U+4F02, U+4F08, U+4F0C, U+4F12, U+4F15, U+4F17, U+4F19, U+4F2E, U+4F31, U+4F33, U+4F35, U+4F3B, U+4F40, U+4F42, U+4F4B-4F4C, U+4F52, U+4F63, U+4F6E, U+4F71, U+4F79, U+4F81, U+4F84, U+4F89, U+4F8C, U+4F8E, U+4F90, U+4F93, U+4F99, U+4F9E-4F9F, U+4FB7, U+4FB9, U+4FBB-4FBD, U+4FC0-4FC1, U+4FC6, U+4FC8, U+4FCC, U+4FDC, U+4FE2, U+4FF0, U+4FFC-4FFD, U+5004, U+5007, U+500A, U+500C, U+5017, U+501B, U+501D, U+5030, U+5032-5033, U+5035, U+5045, U+504A, U+504C, U+5051-5052, U+5059, U+505F-5060, U+5062, U+5067, U+506D, U+5071, U+5081, U+5083-5084, U+5086, U+508A, U+508E-5090, U+509B, U+509E-50A2, U+50AF-50B0, U+50B9, U+50BD, U+50C0, U+50C3, U+50D3, U+50DC-50DD, U+50DF, U+50E2, U+50E4, U+50E8, U+50EF, U+50F1, U+50F6, U+50FA, U+50FE, U+5107, U+510C, U+56E4, U+5880, U+6911, U+6DCA, U+7324, U+73BD, U+73D2, U+73F5, U+7421, U+7429, U+7575, U+7682, U+787A, U+79DA, U+7FA1, U+813A, U+83F6, U+856B, U+8807, U+8B53, U+8D76, U+8ECF, U+8FF6, U+9067, U+91B2, U+91DE, U+9206, U+9277, U+92D5, U+93A4, U+93F8, U+9431, U+9448, U+9592, U+9743, U+9751, U+9927, U+9BBB, U+9FC4, U+9FC6, U+FA0E, U+FA12, U+FA17-FA18, U+FA1C-FA1E, U+FA23, U+FA25, U+FA27-FA2D, U+FA6B-FA6D, U+20158, U+20BB7, U+233CC, U+233FE, U+242EE, U+2F8DB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold067.woff2") format("woff2");
  unicode-range: U+510D-510E, U+5110, U+5119, U+511C-511D, U+5123, U+5127-5128, U+512C-512D, U+512F, U+5131, U+5133-5134, U+5138-5139, U+5142, U+514F, U+5153, U+5158, U+515F, U+5166, U+517E, U+5184, U+518E, U+51A1, U+51B8, U+51BA, U+51BF, U+51C2, U+51C8, U+51CF, U+51D1-51D3, U+51D5, U+51D8, U+51E5, U+51F2, U+51F7, U+5205, U+5218, U+5222, U+5228, U+5231-5232, U+5235, U+523C, U+5245, U+5258, U+525A, U+525F-5260, U+5266, U+526E, U+5278-5279, U+5280, U+5285, U+528A, U+528C, U+5295-5297, U+529A, U+52A5, U+52A7, U+52B0, U+52B6-52B8, U+52BD, U+52C4, U+52C6, U+52CF, U+52D4, U+52DC, U+52E1, U+52E5, U+52E8-52EA, U+52EC, U+52F1, U+52F4, U+52F6, U+5303, U+530C, U+5311, U+5313, U+5318, U+531B, U+531E-531F, U+5325, U+5327-5329, U+532B-532D, U+5330, U+5332, U+533C-533D, U+534B-534C, U+5359, U+535B, U+5365, U+536D, U+5379, U+537E, U+5383, U+5387-5388, U+538E, U+5394, U+5399, U+53A1, U+53AA-53AB, U+53AF, U+53B5, U+53B8, U+53BA, U+53BD, U+53C5, U+53CF, U+53D2-53D3, U+53DE, U+53E0, U+53E6-53E7, U+5402, U+5413, U+541A, U+5421, U+542A, U+542F, U+5431, U+5434-5435, U+5444, U+5447, U+544F, U+545E, U+5464, U+5467, U+5469, U+546D-546E, U+5481, U+5483, U+5485, U+5489, U+5491, U+549F, U+54A7, U+54AA, U+54B1, U+54B9, U+54BB, U+54CA, U+54CD-54CE, U+54E0, U+54EA, U+54F6, U+54FC, U+54FE, U+5505, U+5508, U+550C-550D, U+5515, U+552A, U+5532, U+5536, U+553B, U+553D, U+5549, U+554D, U+5551, U+5558, U+555A-555B, U+5566, U+557F, U+558F, U+5592-5594, U+5597, U+55A3-55A4, U+55B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold068.woff2") format("woff2");
  unicode-range: U+55C1, U+55C3, U+55C6, U+55CB, U+55D3, U+55D7-55D8, U+55DB, U+55DE, U+55F6, U+55FF, U+5605, U+560A, U+560D, U+560F, U+5611-5612, U+5619, U+562C, U+5633, U+5635, U+5639, U+563C, U+5641, U+5643-5644, U+5646, U+564B, U+564D, U+564F, U+5654, U+5661-5663, U+5669, U+5675, U+5684-5685, U+568B, U+569F, U+56A6-56A7, U+56AB, U+56B1, U+56B7, U+56BE, U+56CB-56CC, U+56CF-56D0, U+56D9, U+56DC-56DD, U+56E1, U+56E5-56E7, U+56EB, U+56ED, U+56F1, U+5701-5702, U+5707, U+570C, U+5711, U+571A-571B, U+571D, U+5720, U+5722, U+5724-5725, U+572A, U+572C, U+572E, U+573D-573F, U+5752, U+5762, U+5767, U+576B, U+576D-576E, U+5771, U+5779-577A, U+577E, U+5781, U+5783, U+578C, U+5794-5795, U+5797, U+5799, U+579F, U+57A1, U+57A7, U+57A9, U+57BD, U+57D5, U+57DD, U+57E1, U+57E7, U+57E9, U+57FE, U+5803, U+5808, U+580C-580D, U+581B, U+581F, U+5826, U+582D, U+583F, U+584D, U+584F-5850, U+5855, U+585F, U+5868, U+5878, U+587F, U+5881, U+5887-5888, U+588C, U+5896, U+58A0-58A2, U+58A6, U+58BC, U+58C2, U+58C4, U+58C8, U+58D0, U+58D6, U+58DD, U+58E1, U+5912-5913, U+591D, U+5921, U+5923, U+5928, U+592F-5930, U+5933, U+5935-5936, U+593F, U+5943, U+5952, U+5959, U+595E, U+5961, U+596B, U+596F, U+5972, U+5979, U+597B, U+598C, U+598E, U+5995, U+5997, U+59A7, U+59AD, U+59AF-59B0, U+59B3, U+59B7, U+59C1, U+59C4, U+59CA, U+59DF, U+59EF, U+59F1-59F2, U+59F4, U+59F7, U+5A00, U+5A04, U+5A0E, U+5A12, U+5A1E, U+5A24, U+5A28, U+5A2A, U+5A30, U+5A44-5A45, U+5A47-5A48, U+5A4C, U+5A50, U+5A5E, U+5A63;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold069.woff2") format("woff2");
  unicode-range: U+2F39, U+5A7B, U+5A90, U+5A93, U+5A96, U+5A99, U+5AAC, U+5AB2, U+5AB8, U+5ABB, U+5AC6, U+5AC8, U+5ACF, U+5AEA, U+5AF6, U+5AFD, U+5B01, U+5B1B, U+5B1D, U+5B21, U+5B38, U+5B4B, U+5B5E, U+5B6E, U+5B7E, U+5B86, U+5B8A, U+5B8E, U+5B90-5B91, U+5B94, U+5BA8-5BA9, U+5BAD, U+5BAF, U+5BB1-5BB2, U+5BB7, U+5BBA, U+5BBC, U+5BC1, U+5BCD, U+5BCF, U+5BD7, U+5BD9-5BDA, U+5BE0, U+5BEF, U+5BF4, U+5C0C, U+5C17, U+5C1F, U+5C26, U+5C2C, U+5C2E, U+5C32, U+5C35-5C36, U+5C59-5C5A, U+5C5C, U+5C6D, U+5C74-5C75, U+5C7B, U+5C7D, U+5C87, U+5C92, U+5C9D, U+5CB2, U+5CB4, U+5CD7, U+5CDD, U+5CEE, U+5CF1-5CF2, U+5D01, U+5D12, U+5D23, U+5D34, U+5D3D, U+5D3F, U+5D43, U+5D48, U+5D51, U+5D55, U+5D59, U+5D5F-5D60, U+5D62, U+5D64, U+5D79-5D7A, U+5D7E-5D7F, U+5D83, U+5D8A, U+5D93, U+5D95, U+5D9B, U+5D9F, U+5DAB, U+5DC3, U+5DC7, U+5DCE, U+5DD9, U+5DF8-5DF9, U+5E07, U+5E0D, U+5E20, U+5E28, U+5E32, U+5E35, U+5E4B, U+5E50-5E51, U+5E5B-5E5C, U+5E68, U+5E6A, U+5E70, U+5E80, U+5E8B, U+5E8E, U+5EA2, U+5EA4, U+5EA8, U+5EB1, U+5EB3, U+5EBD, U+5ECC, U+5ECE, U+5ED1, U+5ED4-5ED5, U+5EDC, U+5EDE, U+5EE5, U+5EEB, U+5F06, U+5F19, U+5F22, U+5F24, U+5F28, U+5F2B-5F2C, U+5F2E, U+5F30, U+5F3B, U+5F3F, U+5F44, U+5F4D, U+5F50, U+5F5B, U+5F60, U+5F6F, U+5F74-5F75, U+5F78, U+5F7A, U+5F8D, U+5F96, U+5F9D, U+5FAB-5FAC, U+5FB0-5FB1, U+5FC8, U+5FD0-5FD1, U+5FE8, U+5FEA, U+5FEC-5FED, U+5FF2, U+5FF6, U+5FFA, U+6007, U+600A, U+6013, U+601A, U+601F, U+602D, U+6040, U+6048-6049, U+6051, U+6054, U+6056-6057, U+6061, U+6067;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold070.woff2") format("woff2");
  unicode-range: U+6071, U+607E, U+6082, U+6086, U+6088, U+608E, U+6091, U+6093, U+6098, U+60A2, U+60A4-60A5, U+60B7, U+60BB, U+60C2, U+60C4, U+60C9-60CA, U+60CE-60CF, U+60E2, U+60E5, U+60FC-60FD, U+6102, U+6107, U+610A, U+610C, U+6114, U+6116-6117, U+6122, U+612A, U+6131, U+6135-6136, U+6139, U+6145, U+6149, U+615E, U+616C, U+6172, U+6178, U+617B, U+617F-6181, U+6183-6184, U+618B, U+619C-619D, U+619F-61A0, U+61AA, U+61B8, U+61C0-61C2, U+61CE-61CF, U+61DC, U+61DE, U+61E1-61E2, U+61E5, U+61E7, U+61E9, U+61EC-61ED, U+61EF, U+6201, U+6203-6204, U+6207, U+621C, U+6220, U+6227, U+622B, U+6239, U+623D, U+6242, U+6244, U+6250, U+6254, U+625C, U+627A, U+627D, U+628D-6290, U+62A8, U+62B3, U+62B6-62B7, U+62BA, U+62BE-62BF, U+62CE, U+62DA, U+62EA, U+62F2, U+62F4, U+6304, U+630B, U+6313, U+6316, U+6329-632A, U+632D, U+6336, U+6342, U+6346, U+634A-634B, U+6352-6354, U+6358, U+635B, U+6366, U+636D, U+6371, U+6374-6375, U+6378, U+637D, U+637F, U+6382, U+6387, U+638A, U+6390, U+6395, U+639A, U+639E, U+63A4, U+63A6, U+63AD-63AF, U+63C1, U+63C5, U+63C8, U+63CE, U+63D1, U+63D3, U+63EA, U+63F3, U+63F8-63F9, U+640A, U+6412, U+6418, U+6420, U+6424, U+642A, U+6430, U+6435, U+643D, U+643F, U+644B, U+644F, U+6452-6454, U+645C, U+645F, U+6461, U+6463, U+6474, U+647B, U+6485, U+648F-6490, U+6498-6499, U+649B, U+64A1, U+64A3, U+64A6, U+64A8, U+64AC, U+64B3, U+64BD, U+64C9, U+64D1, U+64E9-64EA, U+64ED, U+64F0, U+64F5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold071.woff2") format("woff2");
  unicode-range: U+6501, U+6508-650A, U+6513, U+6519, U+651B, U+651F, U+6526, U+652E, U+6531, U+653A, U+653C-653D, U+6543, U+6547, U+6549, U+6550, U+6552, U+655F-6560, U+657D, U+6592, U+6595, U+6598, U+65A0, U+65A3, U+65A6, U+65AE, U+65B3-65B4, U+65C8, U+65CE, U+65D0, U+65D6, U+65D8, U+65DF, U+65F0, U+65F4-65F5, U+65FE-65FF, U+660D, U+6611-6612, U+6616, U+661D, U+6623, U+6626, U+6629, U+662C, U+6637, U+6639, U+6640, U+6646, U+664A, U+6658, U+665C, U+6660, U+6675, U+6679, U+667C, U+667E-667F, U+668B-668C, U+669A-669C, U+669F, U+66A4, U+66C0, U+66C2-66C3, U+66CC, U+66CE-66CF, U+66D4, U+66DF, U+66EB, U+66EE, U+6707, U+671C, U+6720, U+6722, U+673E, U+6745, U+6755, U+676C, U+676E, U+6784, U+678E-678F, U+6791, U+6796, U+6799, U+67B1, U+67B5, U+67BC-67BD, U+67C2, U+67C5, U+67C9, U+67DC, U+67E1, U+67E6, U+67F2, U+67F6, U+6814, U+6819, U+6827-6828, U+682F-6830, U+683F, U+684A, U+6858, U+686F-6871, U+6879, U+687B, U+6884, U+6886, U+6888, U+68A1, U+68A9, U+68AE, U+68D1, U+68D3, U+68DC-68DD, U+68EA-68EB, U+68F5-68F6, U+68FD, U+6906, U+6909, U+6910, U+6913, U+6916-6917, U+6931, U+6933, U+6938, U+6945, U+694E, U+695B, U+6965-6966, U+6970-6971, U+697B, U+698D, U+69A1, U+69A8, U+69AB, U+69AF, U+69B8, U+69C5, U+69C8, U+69D7, U+69E5, U+69F1, U+6A00-6A01, U+6A03, U+6A20, U+6A24, U+6A28, U+6A37, U+6A4A, U+6A51, U+6A55, U+6A67, U+6A6A, U+6A71, U+6A81, U+6A86-6A87, U+6A9B, U+6AAF-6AB1, U+6AB4, U+6ABE-6ABF, U+6AC8-6AC9, U+6ACC, U+6AD5-6AD6, U+6AF0, U+6AFC, U+6B02;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold072.woff2") format("woff2");
  unicode-range: U+6B03, U+6B06-6B07, U+6B09, U+6B24, U+6B28, U+6B2B, U+6B36, U+6B3B, U+6B3F, U+6B4D, U+6B52, U+6B5D, U+6B6B, U+6B6E, U+6B70, U+6B7D-6B7E, U+6B85, U+6B97, U+6B9F-6BA0, U+6BA2-6BA3, U+6BA8, U+6BAC, U+6BB0, U+6BB8-6BB9, U+6BC3-6BC4, U+6BCC, U+6BE1, U+6BE3, U+6BF7, U+6BF9, U+6C04, U+6C09, U+6C0D, U+6C12, U+6C19, U+6C1F, U+6C26-6C28, U+6C2C, U+6C2E, U+6C3B, U+6C4A-6C4B, U+6C4F, U+6C52, U+6C54, U+6C6B, U+6C78-6C79, U+6C7B, U+6C87, U+6C9F, U+6CB0, U+6CB2, U+6CB4, U+6CCD, U+6CCF, U+6CD1-6CD2, U+6CE7, U+6CF2, U+6CF4, U+6D07, U+6D0F, U+6D11, U+6D13, U+6D1A, U+6D28, U+6D57, U+6D5F, U+6D61, U+6D67, U+6D7C, U+6D92, U+6D97, U+6DB7, U+6DB9, U+6DBD, U+6DE0, U+6DE2, U+6DE5, U+6DEF-6DF0, U+6DF4, U+6E00, U+6E04, U+6E3B, U+6E45, U+6E52, U+6E5D, U+6E62, U+6E68, U+6E73, U+6E7B, U+6E7D, U+6E8D, U+6E99, U+6EA0, U+6EAD-6EAE, U+6EB3, U+6EBB, U+6EC0, U+6EC8, U+6ECD, U+6ECF, U+6EED-6EEE, U+6EFD, U+6F04, U+6F08, U+6F0D, U+6F16, U+6F1B, U+6F26, U+6F29, U+6F2D, U+6F30, U+6F3B, U+6F4F, U+6F53, U+6F5D, U+6F6C, U+6F83, U+6F87, U+6F8B, U+6F92-6F93, U+6F9A, U+6FA6, U+6FA8, U+6FAE, U+6FB0, U+6FC5, U+6FE8, U+6FFD, U+700D, U+7017, U+7020, U+702F, U+7034, U+7037, U+7044, U+7048-7049, U+7055, U+7094, U+7096, U+7098, U+709B, U+70B0, U+70B4, U+70D5-70D6, U+70FA, U+7105, U+710B, U+712D, U+7138, U+7141, U+7145, U+714B, U+7157, U+715A, U+7179, U+718C, U+719A, U+71B0, U+71B2, U+71BF-71C0, U+71CC, U+71D6, U+71DA, U+71F8, U+7208-7209, U+7213, U+7217, U+721A, U+721D, U+721F, U+7224, U+722F, U+7241, U+7243, U+7245, U+724E-7250;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold073.woff2") format("woff2");
  unicode-range: U+725A, U+725C, U+725E, U+7260, U+7268, U+726B, U+7271, U+7277, U+727B-727C, U+7284, U+7289, U+7293, U+72A8, U+72B4, U+72C7, U+72C9, U+72D5-72D6, U+72D8, U+72DF, U+72E5, U+72F4, U+72FE, U+7302, U+7304-7305, U+730B, U+730D, U+7313, U+731E, U+7322, U+7332, U+7335, U+733A-733B, U+7343, U+734D, U+7356, U+7358, U+735D-7360, U+7367, U+7369, U+7379, U+7380, U+7383, U+7386, U+738E, U+7390, U+7393, U+7397, U+73AA, U+73AD, U+73C6, U+73CC, U+73D3, U+73DD, U+73E6, U+73F4, U+73F7, U+73FB, U+73FD, U+73FF-7400, U+7404, U+7411, U+742D, U+7451, U+7466-7468, U+746E, U+7472, U+7480, U+748F, U+7491, U+749A, U+74AE-74AF, U+74B1-74B2, U+74CC, U+74D0, U+74D3, U+74DB, U+74DF, U+74E4, U+74E8, U+74EA, U+74F4, U+74FB-74FC, U+7506, U+7512, U+7516, U+7521, U+7527, U+7529, U+7536, U+7539, U+753F, U+7543, U+7547, U+7557, U+755E-755F, U+7561, U+757B-757C, U+7585, U+7590, U+7593, U+7595, U+7599, U+759C, U+75A4, U+75B4, U+75BA, U+75C1, U+75C4, U+75CC, U+75D7, U+75DC, U+75E1, U+75EF, U+7604, U+760A, U+760C, U+7612, U+761D, U+7623, U+7629, U+7632, U+7638-763A, U+7640, U+7644-7645, U+764A, U+7659, U+765F, U+7674, U+7685, U+768C-768D, U+769F, U+76A2-76A5, U+76AD, U+76BD, U+76C1, U+76CB, U+76D9, U+76E0, U+76F0, U+76F6, U+76F9, U+7700, U+7706, U+770E, U+7712, U+7714-7715, U+771C, U+7722, U+7728, U+772E-772F, U+7739, U+773D-773E, U+7742, U+7745, U+774A, U+774F, U+7752, U+7756-7757, U+775E, U+7764, U+7767, U+776C, U+7770, U+7773-7774, U+7784, U+778C-778D, U+7795-7796, U+77A7;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold074.woff2") format("woff2");
  unicode-range: U+77AE-77AF, U+77B1, U+77B5, U+77BE, U+77C3, U+77C9, U+77D1-77D2, U+77D5, U+77D9, U+77F1, U+77F8, U+780E, U+7811, U+781D, U+7822-7823, U+782E, U+7830, U+7835, U+7837, U+7844, U+7848, U+784C, U+7852, U+785C, U+785E, U+7860-7861, U+7863, U+787E, U+788F, U+7898, U+78A1, U+78A8, U+78AC, U+78B1-78B3, U+78BD, U+78BF, U+78C7, U+78C9, U+78D2-78D3, U+78D6, U+78DB, U+78DF, U+78EA, U+78F3, U+78F6, U+78FF, U+7906, U+790C, U+791A, U+791E-7920, U+7927, U+7929, U+792D, U+7935, U+793D, U+793F, U+7944, U+794B, U+794F, U+7951, U+7954, U+7969, U+796B, U+797B-797C, U+797E, U+798B-798C, U+7991, U+7993, U+799C, U+79A8, U+79AB, U+79AF, U+79C4, U+79CF, U+79DD, U+79E0, U+79E2, U+79E5, U+79EA, U+79F1, U+79F8, U+79FC, U+7A02, U+7A07, U+7A09, U+7A0C, U+7A21, U+7A27, U+7A2B, U+7A2F-7A30, U+7A34-7A35, U+7A3A, U+7A44-7A45, U+7A48, U+7A55, U+7A65, U+7A7E, U+7A80, U+7A86, U+7A8B, U+7A91, U+7A94, U+7A9E, U+7AB5, U+7AC6, U+7AC9, U+7ADB, U+7AE9, U+7AEC, U+7AF1, U+7AFB, U+7AFE, U+7B14, U+7B1F, U+7B23, U+7B29, U+7B2B, U+7B30, U+7B34, U+7B3F-7B40, U+7B6A, U+7B77, U+7B84, U+7B89, U+7B8E, U+7B96, U+7BA0, U+7BA5, U+7BAC, U+7BB0, U+7BB2, U+7BB6, U+7BBA-7BBB, U+7BBD, U+7BC2, U+7BC8, U+7BDB, U+7BE8, U+7BF2, U+7BF4-7BF5, U+7BF8-7BFA, U+7BFC, U+7BFE, U+7C02, U+7C04, U+7C06, U+7C09, U+7C0C, U+7C19, U+7C1B, U+7C25, U+7C28, U+7C2C, U+7C34, U+7C39-7C3A, U+7C46, U+7C52-7C53, U+7C55, U+7C5A-7C5D, U+7C63, U+7C67, U+7C72, U+7C7C-7C7D, U+7C86-7C87, U+7C9E, U+7CB0, U+7CBA-7CBB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold075.woff2") format("woff2");
  unicode-range: U+2EB1, U+7CC7, U+7CC9, U+7CCF, U+7CD3-7CD4, U+7CDA, U+7CE9, U+7D0F, U+7D11, U+7D16, U+7D26, U+7D2A, U+7D2D, U+7D3C, U+7D4D, U+7D51, U+7D57, U+7D5D, U+7D65, U+7D67, U+7D78, U+7D7B, U+7D81-7D82, U+7D85, U+7D8D, U+7D91, U+7D96, U+7D9E, U+7DB3, U+7DB9, U+7DC3-7DC6, U+7DCD-7DCE, U+7DE2, U+7DE5, U+7DEA-7DEB, U+7DED, U+7DF5-7DF6, U+7DFA, U+7E00, U+7E1C, U+7E2D, U+7E2F, U+7E33, U+7E36, U+7E3F, U+7E44, U+7E4E, U+7E50, U+7E58, U+7E5F, U+7E62, U+7E65, U+7E6E-7E6F, U+7E78, U+7E81, U+7E95, U+7E9D-7E9E, U+7F3D, U+7F3F, U+7F53, U+7F5B-7F5D, U+7F65-7F66, U+7F71, U+7F7F-7F80, U+7F8B, U+7F8D, U+7F8F, U+7FA2, U+7FA6, U+7FAA, U+7FB4, U+7FBC, U+7FC0, U+7FC8, U+7FE8, U+7FFD-7FFF, U+8007, U+800D, U+800F, U+8013, U+801D-8020, U+802E, U+8034, U+8039-803A, U+803E, U+8040, U+8044, U+8060, U+8064, U+806D, U+8081, U+8088, U+808E, U+809E, U+80A6, U+80AB, U+80B9, U+80C8, U+80CD, U+80CF, U+80D2, U+80D4, U+80ED-80EE, U+80F0, U+80F2, U+80F6, U+80F9-80FA, U+80FE, U+8103, U+810B, U+8117, U+811C, U+8120, U+8130, U+813C, U+8145, U+8147, U+814C, U+8152, U+8157, U+8161, U+816F, U+8177, U+8186, U+818B, U+818E, U+8190, U+8196, U+819B, U+819E, U+81A2, U+81AE, U+81B4, U+81C5, U+81CB, U+81CE, U+81D5, U+81DD-81DE, U+81E1, U+81EB, U+81F0-81F2, U+81F5-81F6, U+81F8-81F9, U+8200, U+8203, U+820F, U+8213-8214, U+821A, U+821D, U+8228, U+823A, U+8243-8244, U+824E, U+8251, U+8256, U+8267, U+826D, U+827B, U+8280-8281, U+8284, U+8287, U+8289, U+8291, U+8294, U+8296, U+8298, U+829A-829B, U+82A0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold076.woff2") format("woff2");
  unicode-range: U+82AA, U+82B0, U+82D0, U+82DA, U+82E0, U+82E4, U+82EA, U+82ED, U+82EF, U+82F6, U+830A-830B, U+831E-831F, U+8321, U+832C, U+832E, U+8333, U+8337, U+833D, U+8342, U+834D-834E, U+8353, U+8356, U+8370, U+8378, U+8380, U+8382, U+8384, U+8399, U+839C, U+83A6, U+83AC-83AD, U+83BE, U+83E8, U+83F8, U+83FC, U+8419, U+842F, U+8445, U+8447, U+844D, U+8456, U+8458, U+8460, U+8464, U+8467, U+846A, U+8474, U+847D, U+8492-8493, U+8495, U+84A8-84AA, U+84B1, U+84BD, U+84C7-84C8, U+84CC, U+84F2, U+84F7, U+84FB, U+8502-8503, U+8507, U+850E, U+8510, U+851C, U+8522, U+8527, U+852A, U+8533, U+8536, U+853F, U+8546, U+8550, U+8552, U+8556, U+855C-855D, U+855F-8560, U+8579, U+8585, U+8589, U+858B, U+8598, U+85A0, U+85A5, U+85A7, U+85B4, U+85B6, U+85B8, U+85BD-85BF, U+85C2, U+85DA, U+85E0, U+85E8, U+85F3, U+85FC, U+860D-860E, U+8619, U+861B, U+8636, U+863A, U+863D, U+8642, U+8646, U+8652, U+8658-8659, U+865D, U+8660-8661, U+8663-8664, U+8669, U+8676, U+8688, U+8696, U+869A, U+86A1, U+86A6-86A7, U+86AD, U+86B4-86B5, U+86B7, U+86B9, U+86BF-86C0, U+86C5, U+86D2, U+86DA, U+86DC, U+86E0, U+86E5, U+86E7, U+8704, U+870F, U+8714, U+871E, U+8728, U+872E-872F, U+8732, U+8739, U+873C-873D, U+8745, U+874D, U+875D, U+8761, U+876F, U+877B, U+8783-8786, U+878C, U+8790, U+8795, U+8797-8799, U+879E, U+87A3, U+87AE, U+87B1, U+87BF, U+87C8-87CA, U+87D5, U+87D9-87DA, U+87DC, U+87E2, U+87E4, U+87F1, U+87F3, U+87F8, U+87FA, U+87FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold077.woff2") format("woff2");
  unicode-range: U+8809, U+8812, U+8818-881C, U+881E, U+882D, U+8830, U+8835, U+883A, U+8841, U+8843, U+8845, U+8848-8849, U+884B, U+884E, U+8851, U+8855-8856, U+885A, U+885C, U+8860, U+8871, U+8879, U+887B, U+8880, U+889A-889C, U+889F, U+88A8, U+88BA, U+88CB-88CD, U+88DE, U+88E7, U+88EF, U+88F7, U+8906, U+890D-890F, U+8915-8916, U+8920, U+8926, U+8928, U+8931, U+8935, U+893A, U+893E, U+8946, U+894F, U+8952, U+8957, U+895A-895C, U+8961, U+8963, U+896B, U+896E, U+8970, U+8973, U+8975, U+897A-897D, U+898D, U+8995, U+899B-899C, U+89A0, U+89A5, U+89B4-89B6, U+89BC, U+89D5, U+89D7, U+89E9, U+89ED, U+89F9, U+8A04-8A05, U+8A07, U+8A0F, U+8A1E, U+8A20, U+8A24, U+8A26, U+8A2B-8A2C, U+8A2F, U+8A3D, U+8A40, U+8A43, U+8A49, U+8A53, U+8A56-8A57, U+8A5C, U+8A65, U+8A67, U+8A76-8A77, U+8A7A-8A7B, U+8A7E-8A80, U+8A83, U+8A86, U+8A8B, U+8A8F, U+8A92, U+8A96-8A97, U+8A99, U+8A9F, U+8AA9, U+8AAF, U+8AB3, U+8AB6, U+8ABB, U+8AC3, U+8AC6, U+8AC8-8ACA, U+8AD1, U+8AD3-8AD5, U+8ADD, U+8AEC, U+8AF0, U+8AF5, U+8AFF, U+8B06, U+8B0B, U+8B11, U+8B1E, U+8B30, U+8B37, U+8B3C, U+8B42, U+8B44-8B45, U+8B48, U+8B4D, U+8B52, U+8B63, U+8B6D, U+8B78-8B79, U+8B7C, U+8B7E, U+8B84-8B85, U+8B8D, U+8B8F, U+8B9F, U+8C38, U+8C3E, U+8C4B, U+8C51, U+8C53, U+8C58-8C59, U+8C5B, U+8C5D, U+8C63-8C64, U+8C66, U+8C75-8C76, U+8C7B, U+8C7E, U+8C86-8C87, U+8C8B, U+8C90, U+8C9C, U+8CB9-8CBA, U+8CC5-8CC6, U+8CC9, U+8CCB, U+8CCF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold078.woff2") format("woff2");
  unicode-range: U+8CDD, U+8CE8, U+8CEC, U+8CEF, U+8CF2, U+8CF5, U+8CF7, U+8CFF, U+8D01, U+8D03, U+8D17, U+8D65, U+8D69, U+8D6E, U+8D7F, U+8D82, U+8D88, U+8D8D, U+8D90-8D91, U+8D9E-8DA0, U+8DAB-8DAC, U+8DB2, U+8DB5, U+8DB7, U+8DB9, U+8DBB-8DBC, U+8DC0, U+8DC5, U+8DC7, U+8DCA, U+8DD4-8DD5, U+8DE4-8DE5, U+8DE7, U+8DF0-8DF2, U+8DF4, U+8E01, U+8E04-8E05, U+8E0B, U+8E11, U+8E26, U+8E31, U+8E33, U+8E37-8E38, U+8E40-8E41, U+8E4D-8E4F, U+8E5B-8E5E, U+8E61, U+8E69, U+8E71, U+8E79-8E7A, U+8E82-8E83, U+8E89, U+8E90, U+8E92, U+8E95, U+8E9A-8E9B, U+8E9D, U+8EA2, U+8EA7, U+8EA9, U+8EAD, U+8EBA, U+8EC1, U+8EC3-8EC4, U+8EC7, U+8EDC, U+8EE8, U+8EED-8EEE, U+8EF0-8EF1, U+8EF7, U+8F02, U+8F07, U+8F0F-8F10, U+8F16, U+8F18, U+8F20-8F21, U+8F23, U+8F25, U+8F27-8F28, U+8F2C, U+8F2E, U+8F34-8F35, U+8F37, U+8F3A, U+8F41, U+8F43, U+8F47, U+8F4F, U+8F51-8F53, U+8F55, U+8F5D-8F5E, U+8F65, U+8F9D, U+8FA0-8FA1, U+8FA5, U+8FB8, U+8FBE, U+8FC0, U+8FCB, U+8FD0, U+8FD2, U+8FE3, U+8FFE, U+9004, U+9018, U+901B, U+9028-902A, U+902C, U+902F, U+9033-9034, U+903F, U+904C, U+905B, U+9062, U+9066, U+906C, U+9070, U+9074, U+9079, U+908B, U+908E, U+9098, U+90A0, U+90A5, U+90B2, U+90B4, U+90BD, U+90CC, U+90D2, U+90D5, U+90D8-90D9, U+90E5, U+90F0, U+9105, U+9108, U+910D, U+9110, U+9117, U+911A, U+9120, U+9125, U+9129, U+912E, U+9136, U+913C, U+9143, U+914F, U+9153, U+915A, U+9167, U+916D, U+917A-917B, U+9181, U+9186, U+918A, U+9191;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold079.woff2") format("woff2");
  unicode-range: U+9193-9195, U+9198, U+91A1, U+91A6, U+91AD, U+91B0, U+91BB, U+91BD, U+91BF, U+91C2, U+91C5, U+91D3-91D4, U+91D9, U+91E9-91EA, U+91EF-91F0, U+91F7, U+91F9, U+91FD, U+9200, U+9204-9205, U+9209, U+920C, U+9212-9213, U+9218, U+921C-921D, U+9223-9226, U+9228, U+922E-9230, U+9235-9236, U+923E, U+9246, U+924D, U+9258, U+925C-925D, U+9269, U+926E-9270, U+9275-9276, U+9279, U+927B, U+928A, U+928E, U+9292, U+92A0, U+92A4-92A5, U+92A8, U+92AF, U+92B6, U+92B8, U+92BA-92BD, U+92C1, U+92C3, U+92C5, U+92C7-92C8, U+92CD, U+92D8, U+92DC-92DD, U+92DF, U+92E1, U+92E3, U+92E8, U+92EC, U+92EE, U+92F0, U+9300, U+9308, U+9314, U+931C, U+9324, U+932A, U+9333-9334, U+9336-9337, U+9350, U+9352, U+9355, U+9358, U+935E, U+9367, U+9369, U+936D, U+936F, U+9374, U+9376, U+937A, U+937D, U+937F-9382, U+938A, U+938D, U+9392, U+9395, U+9398, U+93A6, U+93A8-93A9, U+93AB, U+93B4-93B6, U+93C4-93C5, U+93C9-93CD, U+93D3, U+93D9, U+93E6, U+93F7, U+93F9-93FA, U+9401-9402, U+9408, U+940D-940E, U+9415, U+941F, U+942E-942F, U+943D, U+943F, U+9443, U+944C, U+9455, U+9459, U+945C, U+945F, U+9461, U+9468, U+946E, U+9483-9484, U+957E, U+9584, U+9588, U+959D-959E, U+95A1, U+95BA, U+95BF, U+95C6, U+95C9, U+95D1-95D2, U+95D9, U+95DD, U+95DF-95E0, U+95E4, U+95E6, U+961E, U+9622, U+9624-9626, U+9631, U+9637-963A, U+963D, U+9652, U+9654, U+9656-9657, U+966E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold080.woff2") format("woff2");
  unicode-range: U+9674, U+967B-967C, U+967E-967F, U+9681, U+9683, U+9689, U+9691, U+9696, U+969F, U+96A6, U+96AE, U+96CA, U+96DF, U+96F1, U+96FA, U+9702-9703, U+9705, U+9709, U+971A-971B, U+971D, U+9721-9722, U+9728, U+9731, U+974A, U+9758, U+9763, U+9767, U+9776-9778, U+977B, U+977D, U+977F-9780, U+9789, U+9797, U+9799, U+979F, U+97AC, U+97B5-97B6, U+97B8-97B9, U+97BC, U+97BF, U+97C4-97C5, U+97C7, U+97CA, U+97CD-97CE, U+97D0, U+97D7, U+97DD, U+97E0, U+97E4, U+97EF, U+97F7-97F8, U+97FA, U+9807, U+9819, U+981C, U+9820, U+9826, U+982E-982F, U+9835, U+983E, U+9844, U+984A, U+9851, U+9862-9863, U+986A, U+98AE, U+98B4, U+98C5, U+98CC, U+98E6, U+98F6, U+9902, U+9907, U+9911, U+9915, U+991F, U+9922, U+9926, U+992B, U+9934-9935, U+9939, U+9947-9948, U+9954, U+9959, U+995B, U+995E, U+999B, U+999D, U+999F, U+99B0-99B2, U+99B5, U+99BA, U+99D3, U+99DA, U+99DC, U+99E7, U+99EA-99EC, U+99F4-99F5, U+99FD-99FE, U+9A04, U+9A0B-9A0C, U+9A10, U+9A1E, U+9A22-9A23, U+9A27, U+9A33, U+9A35, U+9A41, U+9A47, U+9A4B, U+9A51, U+9A54, U+9A56, U+9A5D, U+9AAA, U+9AAC, U+9AAE, U+9AB2, U+9AB4-9AB5, U+9ABB, U+9ABE-9ABF, U+9AC8, U+9AD7, U+9ADB, U+9AE4, U+9AE7, U+9AEC, U+9AF2-9AF3, U+9AF5, U+9AFA, U+9AFD, U+9AFF-9B01, U+9B04-9B05, U+9B09, U+9B0B, U+9B0D-9B0E, U+9B19, U+9B1B, U+9B26, U+9B34-9B35, U+9B37, U+9B39-9B3A, U+9B48, U+9B4C, U+9B55-9B57, U+9B5B, U+9B61, U+9B68, U+9B6A, U+9B6D-9B6E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold081.woff2") format("woff2");
  unicode-range: U+2E8B-2E8D, U+2E95, U+2EA2, U+2EA9, U+2EAC, U+2EAE, U+2EB3, U+2EB7, U+2EBC, U+2EC6, U+2ED7, U+2EDD, U+3405, U+3427, U+3488, U+353E, U+35DE, U+3614, U+38A1, U+38AD, U+39A4, U+39B8, U+3A5C, U+3A85, U+3AC4, U+3ACB, U+3AF3, U+3BF3, U+3D31, U+3D4E, U+3DCC, U+3E40, U+3E8A, U+3FB1, U+3FDC, U+4293, U+440C, U+457A, U+45BE, U+4610, U+46AE, U+471F, U+47E6, U+481E, U+4B38, U+4B7E, U+4C38, U+4CE1, U+4E37, U+4F2B, U+4FD3, U+9B78, U+9B7F-9B80, U+9B85-9B87, U+9B89, U+9B8B, U+9B8D, U+9B90, U+9B94, U+9B9A, U+9B9D, U+9BA9, U+9BB0, U+9BB7, U+9BBC, U+9BBF, U+9BC8, U+9BD0, U+9BD7, U+9BDF, U+9BF9, U+9BFF, U+9C02, U+9C0B, U+9C0F, U+9C11, U+9C1C, U+9C1E, U+9C26, U+9C28, U+9C35, U+9C3D, U+9C43-9C44, U+9C4E, U+9C56, U+9C5E, U+9C68, U+9C7B, U+9CF7, U+9CF9, U+9D1C, U+9D2F-9D30, U+9D33-9D34, U+9D3A, U+9D3C-9D3D, U+9D45, U+9D53-9D54, U+9D65, U+9D6A, U+9D7B, U+9D83, U+9D86, U+9D8E, U+9D92-9D93, U+9D95, U+9D97-9D98, U+9DAA, U+9DAE, U+9DB1, U+9DCA, U+9DD4-9DD5, U+9DDE, U+9DE5, U+9DE9, U+9DEB, U+9DEE, U+9DF0, U+9DF3, U+9DFE, U+9E0E, U+9E10-9E12, U+9E16, U+9E7A, U+9E82-9E84, U+9E87, U+9E8E-9E8F, U+9E96, U+9E98, U+9EAE, U+9EB0, U+9EB3, U+9EC6, U+9EC8, U+9ECB, U+9ED5, U+9EE4, U+9EEC-9EED, U+9EF0-9EF2, U+9EF5, U+9EF8, U+9F09, U+9F0F, U+9F11-9F12, U+9F14, U+9F16, U+9F1A-9F1B, U+9F1F, U+9F22, U+9F26, U+9F2A-9F2B, U+9F31-9F32, U+9F34, U+9F3C, U+9F3F, U+9F43-9F44, U+9F47, U+9F56, U+9F5A, U+9F5E, U+9F68, U+9F6E-9F6F, U+9F71, U+9F73, U+9F7A, U+9F7D, U+9F8F, U+9F91-9F92, U+9F96, U+9F9E, U+9FA1, U+9FA3, U+9FA5, U+25AD7, U+2626A, U+2D544;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold082.woff2") format("woff2");
  unicode-range: U+5077, U+50F0, U+5167, U+5174, U+5179, U+51D4, U+51DF, U+5226, U+522B, U+5389, U+53C1, U+53C4, U+5433, U+543F, U+544C, U+5527, U+55EC, U+55EE, U+55F1, U+55F8, U+5620, U+575F, U+57AE, U+586D, U+5898, U+591F, U+5939, U+59EC, U+5A1B, U+5B76, U+5B82, U+5C14, U+5C19, U+5C2A, U+5CE6, U+5DFF, U+5E99, U+5F3A, U+5F65, U+6164, U+617D, U+61E8, U+6231, U+6236, U+631B, U+65BE, U+65E3, U+66B3, U+6725, U+6735, U+6743, U+6780, U+67A4, U+67BE, U+67FA, U+6805, U+688E, U+6986, U+6A54, U+6AF8, U+6B72, U+6CAC, U+6D01, U+6E42, U+6E89, U+6EBC, U+6EDA-6EDB, U+6EE6, U+6F9D, U+6FB7, U+71F4, U+73BA, U+73E4, U+7522, U+764E, U+76EB, U+77B7, U+7928, U+7942, U+7A05, U+7ABD, U+7B53, U+7C2F, U+7C42, U+7C7B, U+7D55, U+7DD0, U+7E48, U+80F7, U+812B, U+8141, U+8173, U+81EF, U+820B, U+8346, U+835A, U+85F4, U+867D, U+8722, U+878E, U+886E, U+88C6, U+88E0, U+8A01, U+8AAA, U+8B0F, U+8C9F, U+8CD2, U+8D1C, U+8E75, U+8E77, U+8FA2, U+8FC8, U+90C9, U+92B3, U+93BB, U+95B1, U+95E8, U+9839, U+99E1, U+9A08, U+9ABD, U+9B1D, U+9B2C, U+9C61, U+9FB4, U+9FBC-9FC2, U+9FCC, U+F909, U+F91F, U+F95F, U+F983, U+F999-F99A, U+F9A2, U+F9C3, U+FA03, U+2008A, U+200B0, U+200F5, U+2053F, U+205B1, U+20611, U+206EC, U+207C8, U+2090E, U+20984, U+20A64, U+20D58, U+21A1A, U+22609, U+22E42, U+22FEB, U+237F1, U+23CBE, U+2404B, U+243D0, U+24E04, U+24FF2, U+2567F, U+25874, U+259CC, U+26222, U+26999, U+26C9E, U+279B4, U+27C3C, U+27D73, U+28CDD, U+28E17, U+28EF6, U+29D4B, U+2A2B2, U+2A9E6, U+2B746, U+2B751, U+2B753, U+2B75A, U+2B75C, U+2B765, U+2B776-2B777, U+2B77C, U+2B782, U+2B789, U+2B78B, U+2B78E, U+2B794, U+2B7AC, U+2F82C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold083.woff2") format("woff2");
  unicode-range: U+2B7AF, U+2B7BD, U+2B7C9, U+2B7CF, U+2B7D2, U+2B7D8, U+2B7F0, U+2B80D, U+2B817, U+2B81A, U+2E278, U+2E569, U+2E6EA, U+2F804, U+2F80F, U+2F815, U+2F81A, U+2F822, U+2F828, U+2F833, U+2F83F, U+2F846, U+2F852, U+2F862, U+2F86D, U+2F873, U+2F884, U+2F899-2F89A, U+2F8A6, U+2F8AC, U+2F8B2, U+2F8B6, U+2F8D3, U+2F8E1, U+2F8E5, U+2F8EA, U+2F8FC, U+2F903, U+2F90B, U+2F90F, U+2F91A, U+2F920-2F921, U+2F945, U+2F947, U+2F96C, U+2F995, U+2F9D0, U+2F9DE-2F9DF, U+2F9F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-DeBold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-DeBold084.woff2") format("woff2");
  unicode-range: U+F860-F862, U+F87A, U+F87F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold001.woff2") format("woff2");
  unicode-range: U+2C, U+2E, U+3A-3B, U+41-5A, U+60-7A, U+C0-D6, U+D8-DD, U+E?, U+F1-F6, U+F8-FD, U+FF-113, U+116-122, U+124-126, U+128-12B, U+12E-13D, U+141-149, U+14B-14D, U+150-17E, U+1CD-1DC, U+1F5, U+1F8-1F9, U+1FD, U+237, U+251, U+254, U+259-25B, U+28C, U+300-301, U+1E3E-1E3F, U+1EBC-1EBD, U+201A, U+3001-3002, U+3042, U+3044, U+3046, U+3048, U+304A-3054, U+3056-305F, U+3061-306A, U+306C-3082, U+3084, U+3086, U+3088-308D, U+308F-3094, U+309A, U+30A1-30A4, U+30A6-30CA, U+30CC-30DE, U+30E0-30E6, U+30E9-30EC, U+30EF, U+30F2-30F4, U+30FC, U+31F7, U+FB00-FB04, U+FE10-FE12, U+FF0C, U+FF0E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold002.woff2") format("woff2");
  unicode-range: U+0-2B, U+2D, U+2F-39, U+3C-40, U+5B-5F, U+7B-7E, U+A0-BF, U+D7, U+DE-DF, U+F0, U+F7, U+FE, U+304, U+327, U+332, U+2011, U+2219, U+223C, U+FF21-FF3A, U+FF41-FF5A, U+FF61-FF9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold003.woff2") format("woff2");
  unicode-range: U+2BB-2BC, U+2003, U+2010, U+2015-2016, U+2018-2019, U+201C-201D, U+2020-2021, U+2025-2026, U+2030, U+2032-2033, U+203B, U+2103, U+212B, U+2190-2193, U+21D2, U+21D4, U+2200, U+2202-2203, U+2207-2208, U+220B, U+2212, U+221A, U+221D-221E, U+2220, U+2227-222C, U+2234-2235, U+223D, U+2252, U+2260-2261, U+2266-2267, U+226A-226B, U+2282-2283, U+2286-2287, U+22A5, U+2312, U+2329-232A, U+2500-2503, U+250C, U+250F-2510, U+2513-2514, U+2517-2518, U+251B-251D, U+2520, U+2523-2525, U+2528, U+252B-252C, U+252F-2530, U+2533-2534, U+2537-2538, U+253B-253C, U+253F, U+2542, U+254B, U+25A0-25A1, U+25B2-25B3, U+25BC-25BD, U+25C6-25C7, U+25CB, U+25CE-25CF, U+25EF, U+2605-2606, U+2640, U+2642, U+266A, U+266D, U+266F, U+3000, U+3003, U+3005-3015, U+301C, U+3041, U+3043, U+3045, U+3047, U+3049, U+3055, U+3060, U+306B, U+3083, U+3085, U+3087, U+308E, U+309B-309E, U+30A5, U+30CB, U+30DF, U+30E7-30E8, U+30ED-30EE, U+30F0-30F1, U+30F5-30F6, U+30FB, U+30FD-30FE, U+4EDD, U+FF01, U+FF03-FF06, U+FF08-FF0B, U+FF0D, U+FF0F-FF20, U+FF3B, U+FF3D-FF40, U+FF5B-FF5E, U+FFE3, U+FFE5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold004.woff2") format("woff2");
  unicode-range: U+391-3A1, U+3A3-3A9, U+3B1-3C1, U+3C3-3C9, U+401, U+410-44F, U+451, U+3303, U+330D, U+3314, U+3318, U+3322-3323, U+3326-3327, U+332B, U+3336, U+333B, U+3349-334A, U+334D, U+3351, U+3357, U+337B-337E, U+338E-338F, U+339C-339E, U+33A1, U+33C4, U+33CB, U+33CD, U+FE45-FE46, U+FF02, U+FF07, U+FF3C, U+FF5F-FF60, U+FFE0-FFE2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold005.woff2") format("woff2");
  unicode-range: U+25B1, U+25B6-25B7, U+25C0-25C1, U+25C9, U+25D0-25D3, U+25E6, U+2600-2603, U+260E, U+2616-2617, U+261E, U+2660-2669, U+266B-266C, U+266E, U+2713, U+2756, U+2776-277F, U+2934-2935, U+29BF, U+29FA-29FB, U+3016-3019, U+301D, U+301F-3020, U+3033-3035, U+303B-303D, U+3095-3096, U+309F-30A0, U+30F7-30FA, U+30FF, U+31F0-31F6, U+31F8-31FF, U+3231-3232, U+3239, U+3251-325F, U+32A4-32A8, U+32B1-32BF, U+32D0-32E3, U+32E5, U+32E9, U+32EC-32ED, U+32FA, U+32FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold006.woff2") format("woff2");
  unicode-range: U+2C6, U+2DC, U+302-303, U+305-306, U+308, U+30B-30C, U+30F, U+318-31A, U+31C-320, U+324-325, U+329-32A, U+32C, U+32F-330, U+334, U+336, U+339-33D, U+361, U+3C2, U+1F70-1F73, U+2012-2014, U+2022, U+203C, U+203E-203F, U+2042, U+2047-2049, U+2051, U+20AC, U+210F, U+2113, U+2116, U+2121, U+2127, U+2135, U+2153-2155, U+2160-216B, U+2170-217B, U+2194, U+2196-2199, U+21C4, U+21E6-21E9, U+2205, U+2209, U+2213, U+221F, U+2225-2226, U+222E, U+2243, U+2245, U+2248, U+2262, U+2276-2277, U+2284-2285, U+228A-228B, U+2295-2297, U+22BF, U+22DA-22DB, U+2305-2306, U+2318, U+23BE-23CC, U+23CE, U+2423, U+2460-2473, U+24D0-24E9, U+24EB-24FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold007.woff2") format("woff2");
  unicode-range: U+127, U+13E, U+193, U+1C2, U+250, U+252-253, U+255-258, U+25C, U+25E-261, U+264-268, U+26C-273, U+275, U+279-27B, U+27D-27E, U+281-284, U+288-28B, U+28D-28E, U+290-292, U+294-295, U+298, U+29D, U+2A1-2A2, U+2C7-2C8, U+2CC, U+2D0-2D1, U+2D8-2D9, U+2DB, U+2DD-2DE, U+2E5-2E9, U+FD6, U+213B, U+2150-2152, U+2156-215B, U+2189, U+2211, U+2474-247F, U+2488-2492, U+24B9, U+24C8, U+26BE, U+26CB, U+27A1, U+2B05-2B07, U+2B95, U+3036, U+322A-3230, U+3233, U+3236-3237, U+3244, U+328B, U+3299, U+3371, U+3390, U+33A0, U+33A2, U+33A4-33A5, U+1F100, U+1F110-1F129, U+1F131, U+1F13D, U+1F13F, U+1F142, U+1F146, U+1F157, U+1F15F, U+1F179, U+1F17B-1F17C, U+1F17F, U+1F202, U+1F237;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold008.woff2") format("woff2");
  unicode-range: U+205A, U+205D, U+3300-3302, U+3304-330C, U+330E-3313, U+3315-3317, U+3319-3321, U+3324-3325, U+3328-332A, U+332D-3335, U+3337-333A, U+333C-3348, U+334B-334C, U+334E-3350, U+3352-3356, U+337F, U+3385-3389, U+338D, U+3396-3398, U+339B, U+339F, U+33A3, U+33A6, U+33B0-33B3, U+33C2, U+33C8, U+33CC, U+33D4, U+33D7-33D8, U+33DA, U+A7B5, U+FE17-FE19, U+FE30-FE33, U+FE35-FE44, U+FE47-FE48, U+FFE4, U+FFE8, U+1F130, U+1F132-1F13C, U+1F13E, U+1F140-1F141, U+1F143-1F145, U+1F147-1F149, U+1F150-1F156, U+1F158-1F15E, U+1F160-1F169, U+1F170-1F178, U+1F17A, U+1F17D-1F17E, U+1F180-1F189;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold009.woff2") format("woff2");
  unicode-range: U+2581-258F, U+2594-2595, U+25A2, U+25AA-25AB, U+25CA, U+25CC, U+25E2-25E5, U+25FB-25FC, U+261C-261D, U+261F, U+2672-267D, U+26A0, U+26AA-26AB, U+26BD, U+2702, U+271A, U+273F-2740, U+27BF, U+2B1A, U+2B25-2B26, U+2B29, U+2B60-2B65, U+2B82-2B83, U+2E40, U+3004, U+301E, U+3030, U+3099, U+319?, U+3220-3229, U+3234-3235, U+3238, U+323A-3243, U+3280-328A, U+328C-3298, U+329A-32A3, U+32A9-32B0, U+32E4, U+32E6-32E8, U+32EA-32EB, U+32EE-32F9, U+32FB-32FE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold010.woff2") format("woff2");
  unicode-range: U+2002, U+201E, U+2039-203A, U+2044, U+2070, U+2074-2079, U+207F-2089, U+20DD-20DE, U+2100, U+2105, U+2109-210A, U+2122, U+2126, U+212E, U+215C-215E, U+217F, U+21C5-21C6, U+21CB-21CC, U+21D0, U+21F5, U+2206, U+220A, U+220F, U+222D, U+2264-2265, U+2272-2273, U+2298, U+229E, U+22A0, U+22C4, U+2307, U+239B-23AD, U+23B0-23B1, U+2480-2487, U+249C-24B8, U+24BA-24C7, U+24C9-24CF, U+24EA, U+24FF, U+2504-250B, U+250D-250E, U+2511-2512, U+2515-2516, U+2519-251A, U+251E-251F, U+2521-2522, U+2526-2527, U+2529-252A, U+252D-252E, U+2531-2532, U+2535-2536, U+2539-253A, U+253D-253E, U+2540-2541, U+2543-254A, U+2550, U+255E, U+2561, U+256A, U+256D-2573;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold011.woff2") format("woff2");
  unicode-range: U+13F-140, U+14A, U+192, U+262-263, U+26A, U+274, U+276, U+278, U+280, U+28F, U+299, U+29C, U+29F, U+2B0, U+2B2, U+2B7, U+2C1, U+2DA, U+2E0-2E1, U+307, U+30A, U+328, U+384-386, U+388-38A, U+38C, U+38E-390, U+3AA-3B0, U+3CA-3CE, U+3D0-3D1, U+3D5, U+3DB, U+402-40C, U+40E-40F, U+452-45C, U+45E-45F, U+AB53;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold012.woff2") format("woff2");
  unicode-range: U+2EA0, U+2ED1, U+2F00, U+2F06, U+2F08, U+2F0A-2F0B, U+2F12, U+2F17, U+2F1D, U+2F24-2F26, U+2F29, U+2F2D, U+2F3C, U+2F3F, U+2F42, U+2F45, U+2F47, U+2F49-2F4A, U+2F52, U+2F54, U+2F63-2F65, U+2F6C, U+2F74, U+2F83, U+2F8F, U+2F92, U+2F94, U+2F9D, U+2FA6-2FA7, U+2FAF, U+2FB7, U+2FBC, U+4E00, U+4E09-4E0B, U+4E0D, U+4E16, U+4E2D, U+4E3B, U+4E5D, U+4E8B-4E8C, U+4E94, U+4EBA, U+4ECA, U+4EE3, U+4EE5, U+4F1A, U+4F53, U+4F55, U+4F5C, U+4F7F, U+4FE1, U+5148, U+5165, U+5168, U+516B-516D, U+5175, U+5185, U+51FA, U+5206-5207, U+524D, U+529B, U+52D5, U+5316, U+5341, U+539F, U+53D6, U+53E3, U+5408, U+540C-540D, U+5411, U+5473, U+548C, U+54E1, U+554F, U+56DB, U+56DE, U+56FD, U+5730, U+5831, U+5834, U+58F0, U+5909, U+5916, U+591A, U+5927, U+5929-592A, U+5973, U+5B50, U+5B66, U+5B89, U+5B9A, U+5B9F, U+5BB6, U+5BFE, U+5C0F, U+5C11, U+5C4B, U+5C71, U+5DDD, U+5E73-5E74, U+5EA6, U+5F15, U+5F37, U+5F53, U+5F7C, U+5F8C, U+5FC3, U+601D, U+6027, U+60C5, U+610F, U+611F, U+6210, U+6226, U+6240, U+624B, U+6301, U+653F, U+6559, U+6570, U+6587, U+65B0, U+65B9, U+65E5, U+660E, U+6642, U+66F8, U+6700, U+6708, U+6728, U+672C, U+6765, U+6771, U+696D, U+6A5F, U+6B21, U+6B63, U+6B7B, U+6C0F, U+6C11, U+6C17, U+6C34, U+6C7A, U+6CBB, U+6CD5, U+6D77, U+7121, U+7269, U+73FE, U+7406, U+751F, U+7528, U+7530, U+7537, U+753B, U+754C, U+767A, U+7684, U+76EE, U+76F8, U+771F, U+77E5, U+793E, U+795E, U+79C1, U+7ACB, U+7D4C, U+7D50, U+7F8E, U+8003, U+8005, U+805E, U+81EA, U+884C, U+885B, U+8868, U+8981, U+898B, U+89AA, U+8A00, U+8A18, U+8A71, U+8A9E, U+8EAB, U+8ECD, U+8FD1, U+901A, U+9023, U+9053, U+90CE, U+90E8, U+91CD-91CE, U+91D1, U+9577, U+958B, U+9593, U+95A2, U+96C6, U+9762, U+9854, U+98DF, U+9AD8;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold013.woff2") format("woff2");
  unicode-range: U+2E9F, U+2EC4, U+2F1F-2F20, U+2F3E, U+2F40, U+2F57, U+2F69, U+2F6F, U+2F76, U+2F8A, U+2F9C, U+2F9E, U+2FA8, U+2FB3, U+2FB5, U+2FB8, U+2FBA, U+4E03, U+4E07, U+4E21, U+4E57, U+4E89, U+4E95, U+4EA4, U+4EAC, U+4ED5-4ED6, U+4ED8, U+4EF6, U+4F1D, U+4F4F, U+4F9B, U+4FC2, U+4FDD, U+5143, U+5149, U+515A, U+5171, U+5186, U+521D, U+5224-5225, U+5229, U+5236, U+52A0, U+52A9, U+52D9, U+52DD, U+52E2, U+5317, U+5343, U+534A, U+53CD, U+53D7, U+53E4, U+53F0, U+53F3, U+5409, U+544A, U+547C-547D, U+54C1, U+56E3, U+571F, U+5728, U+57CE, U+58EB, U+58F2, U+591C, U+592B, U+597D, U+59CB, U+5B58, U+5B98, U+5BA4, U+5CF6, U+5E02, U+5E2B, U+5E30, U+5E38, U+5E83, U+5E97, U+5F0F, U+5F35, U+5F62, U+5F79, U+5F85, U+5F97, U+5FA1, U+5FC5, U+5FDC, U+60AA, U+60F3, U+611B, U+6238, U+6253, U+6307, U+652F, U+653E, U+6599, U+65AD, U+65CF, U+65E9, U+6620, U+6709, U+671D, U+671F, U+6751, U+677E, U+679C, U+6821, U+683C, U+697D, U+69D8, U+6A29, U+6B66, U+6B69, U+6B73, U+6B8B, U+6BBA, U+6BCD, U+6C5F, U+6D3B, U+6D41, U+6DF1, U+6E08, U+70B9, U+7136, U+7236, U+7279, U+72B6, U+7523, U+7531, U+753A, U+756A, U+75C5, U+767D-767E, U+76F4, U+7740, U+77F3, U+78BA, U+7A7A, U+7B11, U+7B2C, U+7C73, U+7D04, U+7D42, U+7D44, U+7D9A, U+7DCF, U+7DDA, U+7F6E, U+7FA9, U+80FD, U+8272, U+82E5, U+843D, U+8449, U+85E4, U+8853, U+897F, U+8996, U+89E3, U+8A08, U+8A2D, U+8AAC-8AAD, U+8ABF, U+8AD6, U+8B70, U+8CB7, U+8CC7, U+8CEA, U+8D77, U+8DB3, U+8ECA, U+8FBC, U+8FD4, U+9001, U+9032, U+904B, U+904E, U+9055, U+9078, U+90FD, U+914D, U+9580, U+9662, U+969B, U+96FB, U+97F3, U+982D, U+984C, U+98A8, U+9996, U+99AC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold014.woff2") format("woff2");
  unicode-range: U+2ED8, U+2F2F, U+2F4C, U+2F55, U+2F70, U+2F7C, U+2F8E, U+2F95, U+2F9A-2F9B, U+2FB6, U+2FB9, U+2FCA, U+4E0E, U+4E45, U+4E4B, U+4E88, U+4ECB, U+4EFB, U+4F01, U+4F0A, U+4F4D, U+4F50, U+4F8B, U+4FA1, U+4FFA, U+5074, U+5099, U+50CD, U+50CF, U+50D5, U+512A, U+518D, U+5199, U+5272, U+533B, U+5357-5358, U+53C2, U+53CB, U+53CE, U+53EF, U+53F2, U+53F7, U+541B, U+5546, U+55B6, U+5668, U+56F3, U+578B, U+57FA, U+5883, U+5897, U+5931, U+5965, U+59BB, U+59FF, U+5A5A, U+5B57, U+5B88, U+5B97, U+5BA2, U+5BAE, U+5BB3, U+5BB9, U+5BC4, U+5BDF, U+5BFA, U+5C06, U+5C40, U+5C45, U+5CA1, U+5DDE, U+5DE5-5DE6, U+5DEE, U+5DFB, U+5E9C, U+5EA7, U+5EFA, U+5F71, U+5FF5, U+6025, U+606F, U+614B, U+623B, U+6280, U+6295, U+629C, U+62BC, U+632F, U+6483, U+6539, U+65C5, U+6625, U+666F, U+671B, U+672B, U+6761, U+67FB, U+6839, U+6848, U+69CB, U+6A2A, U+6A4B, U+6B4C, U+6B62, U+6BB5, U+6C42, U+6CC9, U+6CE8, U+6D25, U+6D3E, U+6D6E, U+6D88, U+6E05, U+6E21, U+6E80, U+6E90, U+6F14, U+706B, U+71B1, U+72EC, U+738B, U+7403, U+7533, U+7570, U+7687, U+770C, U+773C, U+793A, U+798F, U+79D1, U+7A2E, U+7A81, U+7B49, U+7B54, U+7B56, U+7B97, U+7D00, U+7D19, U+7D20, U+7D30, U+7D71, U+7D76, U+8001, U+8077, U+80B2, U+80CC, U+8239, U+826F, U+82B1, U+82B8, U+82E6, U+82F1, U+8535, U+8840, U+899A, U+89B3, U+8A3C, U+8A8D, U+8AB0, U+8AC7, U+8B58, U+8B66, U+8B77, U+8C37, U+8C61, U+8CA0, U+8D64, U+8D70, U+8DEF, U+8EE2, U+8FFD, U+9020, U+9054, U+9060, U+9152, U+91CF, U+964D, U+9650, U+968A, U+968E, U+96E2-96E3, U+9752, U+9818, U+983C, U+98DB, U+9928, U+9999, U+9A13, U+9ED2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold015.woff2") format("woff2");
  unicode-range: U+2F50, U+2F5A, U+2F81, U+2F93, U+2FAE, U+2FB0, U+4E26, U+4E38, U+4E71, U+4ECF, U+4EF2, U+4F3C, U+4F4E, U+4F59, U+4FEE, U+500B, U+5012, U+5024, U+5065, U+5144, U+5177, U+51B7, U+51E6, U+52B4, U+52B9, U+533A, U+5354, U+5371, U+53BB, U+53F8, U+5404, U+5468, U+559C, U+56F2, U+5712, U+5742, U+57DF, U+5802, U+590F, U+5922, U+59D4, U+5A18, U+5A66, U+5B85, U+5BBF, U+5BC6, U+5BDD, U+5C0E, U+5C3E, U+5C55, U+5D0E, U+5E03, U+5E2D, U+5E2F, U+5E55, U+5E78, U+5E95, U+5EAD, U+5EB7, U+5F93, U+5FA9, U+5FB3, U+5FD7, U+6050, U+6211, U+623F, U+62B1, U+62C5, U+6319, U+63A2, U+63A5, U+63CF-63D0, U+63F4, U+653B, U+6545, U+6575, U+6577, U+661F, U+662D, U+6697, U+66AE, U+670D, U+672A, U+6750, U+6797, U+682A, U+68EE, U+691C, U+6975, U+6B74, U+6BBF, U+6BCE, U+6BD4, U+6C38, U+6CA2, U+6CB3, U+6CE2, U+6D0B, U+6E1B, U+6E29, U+6E96, U+6FC0, U+7247, U+72AF, U+7387, U+74B0, U+7565, U+7591, U+7642, U+767B, U+76E3, U+7701, U+7814, U+7834, U+79C0, U+79CB, U+79D8, U+79FB, U+7A0B, U+7A0E, U+7A4D, U+7A76, U+7AE0, U+7AEF, U+7AF6, U+7BA1, U+7CBE, U+7CFB, U+7D75, U+7DD2, U+7DE8, U+7E54, U+7FD2, U+8089, U+80F8, U+8170, U+8179, U+8208, U+821E, U+8336, U+8349, U+8457, U+85AC, U+8857, U+88C1, U+88C5, U+88CF, U+88FD, U+898F, U+89D2, U+8A2A, U+8A31, U+8A55, U+8A66, U+8A8C, U+8AF8, U+8CA1, U+8CBB, U+8CDE, U+8D8A, U+8EFD, U+8F09, U+8FBA, U+9000, U+9003, U+901F, U+9031, U+904A, U+907A, U+9244, U+9280, U+9332, U+9632, U+9678, U+967A, U+96A0, U+96D1, U+9759, U+975E, U+9769, U+97FF, U+9803, U+985E, U+98F2, U+9BAE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold016.woff2") format("woff2");
  unicode-range: U+2F11, U+2F23, U+2F51, U+2F5D-2F5F, U+2F75, U+2F7B, U+2F7F, U+2F82, U+2F90, U+2FA5, U+2FAC, U+2FBB, U+2FC3, U+2FC7, U+4E91, U+4EA1, U+4EE4, U+4F11, U+5009, U+50B7, U+5104, U+5150, U+5178, U+5200, U+520A, U+5211, U+5217, U+523A-523B, U+5263, U+5287, U+5348, U+535A, U+5370, U+53B3, U+53CA, U+542B, U+5438, U+5584, U+56E0, U+56F0, U+56FA, U+5727, U+58C1, U+5915, U+592E, U+5947-5948, U+5999, U+5ACC, U+5B87, U+5B8C, U+5BCC, U+5C02, U+5C04, U+5C64, U+5CA9, U+5CB8, U+5DE8, U+5EAB, U+5F01, U+5F1F, U+5F31, U+5F3E, U+5F66, U+5FAE, U+5FD8, U+5FE0, U+6012, U+604B, U+6075, U+60B2, U+6255, U+6297-6298, U+6355, U+6368, U+6388, U+639B, U+63A8, U+6557, U+6563, U+6574, U+65BD, U+6613, U+6628, U+666E, U+6674, U+66B4, U+66F2, U+66FF, U+675F, U+677F, U+67C4, U+67D3, U+690D, U+6A19, U+6A21, U+6A39, U+6B32, U+6BDB, U+6C60, U+6CC1, U+6D45, U+6E2F, U+6E6F, U+70BA, U+713C, U+7167, U+7248, U+72AC, U+7384, U+7389, U+7532, U+7559, U+75C7, U+75DB, U+76DB, U+7720, U+7763, U+77AC, U+77ED, U+793C, U+79F0, U+7A93, U+7AF9, U+7B4B, U+7BC0, U+7D14, U+7D1A, U+7D66, U+7D99, U+7E01, U+7F6A, U+7FBD, U+8033, U+8056, U+80A9, U+8131, U+8155, U+81E3, U+822C, U+83EF, U+8584, U+85E9, U+8846, U+8863, U+88AB, U+88DC, U+89E6, U+8A0A, U+8A0E, U+8A33, U+8AB2, U+8B1B, U+8C4A, U+8CAC, U+8CB4, U+8D85, U+8DE1, U+8E0F, U+8F2A, U+8FB2, U+8FCE, U+8FEB, U+8FF0, U+9006, U+9014, U+91CC, U+9589, U+95D8, U+962A, U+963F, U+9663-9664, U+967D, U+969C, U+96C4, U+96E8, U+96EA, U+9707, U+97D3, U+984D, U+9858, U+990A, U+99C6, U+9A5A, U+9AA8, U+9AEA, U+9CE5, U+9CF4, U+9EBB, U+9ED9, U+9F62;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold017.woff2") format("woff2");
  unicode-range: U+2EE4, U+2EE9, U+2EED, U+2F30, U+2F4B, U+2F62, U+2F6A, U+2F6E, U+2F84, U+2FC1-2FC2, U+2FC5, U+2FD0, U+4E01, U+4E08, U+4E5F, U+4E73, U+4E92, U+4EEE, U+4EF0, U+4F0F, U+4F38, U+4F9D, U+4FBF, U+500D, U+5019, U+501F, U+50BE, U+5100, U+5145, U+51AC, U+5247, U+5275, U+529F, U+52E4, U+5305, U+5360, U+539A, U+53E5, U+53EB, U+5410, U+5426, U+5439, U+543E, U+54F2, U+57F7, U+5800, U+58CA, U+5949, U+59C9, U+5B6B, U+5B9D, U+5BA3, U+5BE9, U+5C0A, U+5C31, U+5C4A, U+5C5E, U+5D29, U+5DF1, U+5E0C, U+5E1D, U+5E79, U+5E7C, U+5E81, U+5E8A, U+5EC3, U+5EF6, U+5F25, U+5F8B, U+5F92, U+5FB4, U+5FEB, U+6016, U+60A3, U+60D1, U+61B2, U+61B6, U+624D, U+6279, U+627F, U+62E0-62E1, U+635C, U+63A1, U+63DB, U+63E1, U+63FA, U+643A, U+64AE, U+64CD, U+6551, U+65E7, U+6614, U+663C, U+667A, U+66DC, U+66F4, U+6749, U+679A, U+679D, U+67F3, U+6804, U+6838, U+6B20, U+6B27, U+6B6F, U+6C88, U+6CB9, U+6CCA, U+6CE3, U+6D17, U+6D5C, U+6D66, U+6DF7, U+6E2C, U+6E7E, U+6EC5, U+6F22, U+6FC3, U+702C, U+7159, U+7206, U+7518, U+76AE, U+76CA, U+76D7, U+76DF, U+77E2, U+7802, U+7956, U+7981, U+7B46, U+7BC9, U+7C21, U+7D05, U+7D0D, U+7D39, U+7D61, U+7DAD, U+7DE0, U+7DF4, U+7E04, U+7E70, U+7FA4, U+7FCC, U+8074, U+8133, U+81F3-81F4, U+822A, U+8302, U+8352, U+8377, U+878D, U+88D5, U+8907, U+8972, U+8A34, U+8A69, U+8A70, U+8A98, U+8C6A, U+8CA9, U+8CC0, U+8F38, U+8F9E, U+8FF7, U+9045, U+9063, U+9069, U+907F, U+90A3, U+90F7, U+9178, U+91E3, U+9283, U+93E1, U+95A3, U+9670, U+96A3, U+96F2, U+9732, U+9806, U+98EF, U+99C5, U+9A12, U+9B3C, U+9B54, U+9B5A, U+9E7F, U+9EC4, U+9F3B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold018.woff2") format("woff2");
  unicode-range: U+2EC1, U+2EEF, U+2EF2, U+2F1C, U+2F32, U+2F5C, U+2F73, U+2F86, U+2F88, U+2F8D, U+2F96, U+2F9F, U+2FD3, U+4E39, U+4E43, U+4E80, U+4E86, U+4E9C, U+4EC1, U+4ED9, U+4F2F, U+4F34, U+4F8D, U+4FB5, U+4FCA, U+505C, U+50AC, U+50B5, U+50DA, U+50E7, U+5230, U+5238, U+526F, U+52C7, U+52D8, U+5352, U+5373, U+53C8, U+53CC, U+53E9, U+5442, U+5507, U+5510, U+5747, U+574A, U+57A3, U+57CB, U+585A, U+5869, U+596A, U+5974, U+5982, U+59B9, U+5A01, U+5AC1, U+5B63, U+5B99, U+5BD2, U+5BFF, U+5C0B, U+5C3B, U+5C3D, U+5C48, U+5DE1, U+5E33, U+5E45, U+5E72, U+5FB9, U+5FCD, U+602A, U+6065, U+609F, U+60A9, U+611A, U+6162-6163, U+616E, U+6176, U+61D0, U+61F8, U+6271, U+6291, U+62DB, U+62DD, U+63B2, U+63EE, U+640D, U+6458, U+656C, U+658E, U+65AC, U+65D7, U+65E6, U+6607, U+6669, U+66F9, U+672D, U+676F, U+6885, U+68D2, U+6BD2, U+6C57, U+6C5A, U+6C96, U+6CBC, U+6CBF, U+6D6A, U+6D74, U+6D99, U+6DB2, U+6E09, U+6E56, U+6F5C, U+6F6E, U+706F, U+707D, U+718A, U+71C3, U+725B, U+72C2, U+72D9, U+72ED, U+732B, U+7372, U+75B2, U+7686, U+76E4, U+770B, U+773A, U+7832, U+7968, U+796D, U+7A32, U+7A3F, U+7A74, U+7ADC, U+7AE5, U+7BB1, U+7BC4, U+7C60, U+7DCA, U+7E2E, U+7E3E, U+7E41, U+7F85, U+808C, U+80DE, U+8107, U+811A, U+819D, U+81D3, U+81ED, U+820C, U+820E, U+821F, U+8266, U+83DC, U+864E, U+866B, U+885D, U+888B, U+88C2, U+8A3A, U+8A73, U+8ACB, U+8B00, U+8B1D, U+8C46, U+8CA7-8CA8, U+8CB8, U+8DDD, U+8E8D, U+8ED2, U+8F1D, U+8F9B, U+9084, U+90F5, U+9154, U+91C8, U+91DD, U+9234, U+92AD, U+95C7, U+9686, U+96C5, U+970A, U+9802, U+9805, U+9830, U+98FE, U+99C4, U+9F8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold019.woff2") format("woff2");
  unicode-range: U+2EEB, U+2F77, U+4E18, U+4E7E, U+4EAD, U+4F73, U+4F75, U+4FC3, U+4FD7, U+507D, U+508D, U+5146, U+514D, U+517C, U+518A, U+524A, U+5264, U+5289, U+52AA, U+52C9, U+52DF, U+5302, U+5320, U+5353, U+5374, U+53D4, U+541E, U+54B2, U+54C0, U+552F, U+5531, U+5642, U+5653, U+5750, U+5857, U+5893, U+594F, U+5951, U+596E, U+59D3, U+59EB, U+5B09, U+5B54, U+5B5D, U+5B64, U+5B8F, U+5BDB, U+5C01, U+5C1A, U+5CF0, U+5D07, U+5E7B, U+5E7E, U+5ECA, U+5EFB, U+5F18, U+5F69, U+5F80-5F81, U+6069, U+6094, U+60A0, U+614E, U+618E, U+6212, U+629E, U+62B5, U+62D2, U+62F6, U+6311, U+6328, U+6392, U+6398, U+63A7, U+63DA, U+6469, U+654F, U+6589, U+65E2, U+660C, U+6696, U+670B, U+6717, U+6790, U+67D4, U+67F1, U+67F4, U+685C, U+68C4, U+6905, U+6982, U+6C37, U+6C99, U+6CA1, U+6CE5, U+6D69, U+6DE1, U+6DFB, U+6E0B, U+6ED1, U+6EDE, U+6F01, U+6FA4, U+6FE1, U+708E, U+70AD, U+70C8, U+719F, U+7235, U+7267, U+732E, U+7344, U+73CD, U+7551, U+7573, U+7709, U+786C, U+795D, U+7A42, U+7B20, U+7C4D, U+7C89, U+7CF8, U+7D1B, U+7D22, U+7DB1-7DB2, U+7DD1, U+7F72, U+8010, U+809D, U+80A1, U+80A5, U+8102, U+8105, U+8108, U+8150, U+81E8, U+829D, U+8358, U+83CA, U+85A9, U+865A, U+88F8, U+8986, U+8997, U+8A13, U+8A17, U+8A87, U+8A95, U+8AA0, U+8AA4, U+8CAB, U+8CBF, U+8CCA, U+8CDB, U+8CFC, U+8DA3, U+8E0A, U+8F03, U+8F29, U+900F, U+902E, U+9047, U+90A6, U+90AA, U+90B8, U+90E1, U+92ED, U+9685, U+96C7, U+9756, U+9808, U+9810, U+9867, U+98FC, U+99D0, U+9A0E, U+9B42, U+9B45, U+9DB4, U+9E97;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold020.woff2") format("woff2");
  unicode-range: U+2EE8, U+2F28, U+2F38, U+2F6B, U+2FA0, U+2FB4, U+4E1E, U+4FF3, U+502B, U+5049, U+5075-5076, U+511F, U+514B, U+5176, U+5192, U+51C4, U+51CD, U+51DD, U+5203, U+5237, U+525B, U+525D, U+52E7, U+5339, U+5375, U+53EC, U+5446, U+5449, U+545F, U+5589, U+558B, U+55AB, U+5606, U+5609, U+5674, U+5699, U+570F, U+5782, U+5805, U+5854, U+585E, U+58C7, U+58EE, U+5A46, U+5B22, U+5BC2, U+5BE7, U+5BF8, U+5C3A, U+5C90, U+5CB3, U+5D8B, U+5E3D, U+5E84, U+5E8F, U+5EB5, U+5EF7, U+5F13, U+5F6B, U+5FD9, U+6068, U+60DC, U+60E8, U+614C, U+6249, U+62CD, U+62ED, U+62FE, U+636E, U+6383, U+638C, U+63AA, U+63C3, U+6442, U+6451, U+64AB, U+659C, U+65E8, U+65EC, U+662F, U+664B, U+6676, U+6687, U+6691, U+6731, U+673A, U+674E, U+6762, U+67A0, U+6842, U+68B0, U+68DA, U+6B53, U+6B8A, U+6B96, U+6C41, U+6CF0, U+6CF3, U+6D29, U+6D44, U+6DAF, U+6DBC, U+6E9C, U+6EB6, U+6F02, U+6F0F, U+6F2B, U+6F70, U+6F84, U+7070, U+7126, U+716E, U+731B, U+7434, U+74F6, U+76BF, U+78E8, U+790E, U+7948, U+7950, U+7985, U+7A3C-7A3D, U+7A4F, U+7B52, U+7C92, U+7CA7, U+7CD6, U+7D0B, U+7D2B, U+7DBF, U+7DE9, U+7E1B, U+7E26, U+7F70, U+7FD4, U+7FFC, U+80C6, U+81A8, U+81B3, U+82B3, U+83CC, U+846C, U+8650, U+8896, U+89A7, U+8A5E, U+8A89, U+8B0E, U+8B72, U+8C8C, U+8C9E, U+8CAF, U+8CC3, U+8CE2, U+8D08, U+8DF3, U+8E74, U+8EB0, U+8FB0, U+9019, U+9038, U+9042, U+906D, U+9177, U+9298, U+934B, U+9375, U+938C, U+9396, U+93AE, U+9451, U+9665, U+968F, U+96F7, U+9700, U+9727, U+9774, U+9801, U+9811, U+9837, U+9855, U+99D2, U+9DF9, U+9EA6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold021.woff2") format("woff2");
  unicode-range: U+2F56, U+2F7A, U+2FCE, U+4E4F, U+4F0D, U+504F, U+5098, U+5132, U+5197, U+51A0, U+51E1, U+51F6, U+52A3, U+52B1, U+5351, U+540E, U+54C9, U+5553, U+55A7, U+5629, U+572D, U+582A, U+584A, U+587E, U+5937, U+5983, U+598A, U+5BB0, U+5BB4, U+5C09, U+5C3F, U+5C65, U+5CE0, U+5D50, U+5DE3, U+5DE7, U+5E06, U+5E61, U+5E7D, U+5EB6, U+5F90, U+6052, U+60A6, U+60DA, U+6109, U+6170, U+6182, U+622F, U+62AB, U+62BD, U+62C9, U+62D3, U+62D9, U+62EC, U+631F, U+633F, U+6414, U+64A4, U+64B2, U+64E6, U+65BC, U+66A6, U+66FE, U+6795, U+67B6, U+6817, U+6843, U+6850, U+68A8, U+68CB, U+68DF, U+69CD, U+6B64, U+6BB4, U+6D1E, U+6D32, U+6D78, U+6DF3, U+6DF5, U+6E67, U+6E7F, U+6EDD, U+6F20, U+6F54, U+6F5F, U+6F64, U+7089, U+722A, U+723A, U+7272, U+72A0, U+72E9, U+72FC, U+732A, U+733F, U+7363, U+73E0, U+73ED, U+751A, U+75BE, U+7656, U+76C6, U+76FE, U+7761, U+7768, U+78C1, U+7949, U+7965, U+7984, U+79D2, U+79E9, U+7A1A, U+7AAE, U+7B48, U+7BC7, U+7BE0, U+7C3F, U+7C8B, U+7C97-7C98, U+7CE7, U+7D17, U+7D5E, U+7DBE, U+7E6B, U+7F8A, U+7FFB, U+8015, U+80C3, U+80E1, U+816B, U+8178, U+819A, U+819C, U+8210, U+8217, U+828B, U+82BD, U+82D7, U+82DB, U+83D3, U+845B, U+84B8, U+84BC, U+84C4, U+84CB, U+8526, U+85DD, U+8607, U+862D, U+86C7, U+86EE, U+8776, U+8870, U+88FE, U+8A93, U+8B19, U+8CA2, U+8CB0, U+8CBC, U+8CED, U+8EC0, U+8EF8, U+8F14, U+8FB1, U+90C1, U+90CA, U+9262, U+9271, U+9285, U+932F, U+9640, U+965B, U+9673, U+9676, U+9694, U+984E, U+99B4, U+99D5, U+99FF, U+9B4F, U+9D28, U+9F13;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold022.woff2") format("woff2");
  unicode-range: U+2F04, U+2F61, U+2F6D, U+2F78, U+2F99, U+4E59, U+4E5E, U+4EAE, U+4F10, U+5091, U+5270, U+52C3, U+537F, U+5384, U+53E1, U+53F1, U+540A, U+541F, U+5448, U+546A, U+5504, U+553E, U+559A, U+55AA, U+55B0, U+564C, U+56C1, U+576A, U+57F9, U+5824, U+583A, U+5840-5841, U+58A8, U+58FA, U+5954, U+5996, U+5A20, U+5B8B, U+5B9B, U+5BEE, U+5C16, U+5CE1, U+5D16, U+5E4C, U+5E63, U+5F26, U+5F70, U+5F84, U+5FAA, U+6020, U+602F, U+606D, U+6070, U+60E3, U+6148, U+61A4, U+61C7, U+61F2, U+6216, U+621A, U+6247, U+628A, U+62D8, U+62F3, U+6349, U+63C9, U+64C1, U+6562, U+6566, U+65CB, U+6602, U+6606, U+6627, U+6643, U+6681, U+6734, U+67AF, U+6851, U+6881, U+68B6, U+68F2, U+690E, U+697C, U+6A3D, U+6B04, U+6B3A, U+6B6A, U+6C70, U+6CE1, U+6D1B, U+6DEB, U+6E26, U+6E9D, U+6EA2, U+6EF4, U+6F2C, U+6F31, U+6FC1, U+6FEF, U+7261-7262, U+731F, U+74E6, U+755C, U+758E, U+75AB, U+75D5, U+75F4, U+7652, U+77B3, U+77DB, U+77E9, U+7815, U+7881, U+7940, U+79E6, U+7ABA, U+7B1B, U+7B26, U+7B87, U+7BB8, U+7D10, U+7D33, U+7D79, U+7DBA, U+7DEF, U+7E2B, U+7E4A, U+7F36, U+7FC1, U+80AA, U+80BA, U+80F4, U+817F, U+8276, U+83C5, U+83E9, U+83F1, U+84B2, U+84EE, U+85CD, U+865C, U+8700, U+8861, U+8881, U+895F, U+8987, U+8A1F, U+8AE6, U+8B21, U+8B5C, U+8C5A, U+8C9D, U+8D99, U+8ECC, U+8EDF, U+8FBB, U+9022, U+904D, U+90ED, U+9175, U+919C, U+920D, U+9326, U+935B, U+9418, U+95A5, U+963B, U+9644, U+9675, U+9699, U+96C0, U+96F0, U+983B, U+98E2, U+98FD, U+9905, U+99B3, U+99C8, U+9AD9, U+9B31, U+9B8E, U+9D8F, U+9EBF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold023.woff2") format("woff2");
  unicode-range: U+2E92, U+2F31, U+2F43, U+2FA9, U+4EAB, U+4EC7, U+4F0E, U+4F3A, U+4F46, U+4FAF, U+4FF5, U+502D, U+50B3, U+5112, U+514E, U+5208, U+52C5, U+52F2, U+53EA, U+547B, U+54B3, U+5538, U+5598, U+559D, U+55C5, U+55E3, U+56DA, U+570B, U+57FC, U+5835, U+5875, U+58B3, U+58CC, U+5968, U+59A5, U+59A8, U+59AC, U+59BE, U+59D1, U+59DC, U+5A92, U+5AC9, U+5B5F, U+5B9C, U+5C3C, U+5CAC, U+5DF3, U+5DFE, U+5E25, U+5E87, U+5F04, U+5FCC, U+6028, U+60F9, U+6115, U+6155, U+61A7, U+6234, U+6276, U+6367, U+642D, U+64AD, U+6590, U+6597, U+66AB, U+66C7, U+66D6, U+66FD, U+6756, U+67A2, U+67CF-67D0, U+67FF, U+683D, U+6876, U+689D, U+695A, U+6BBB, U+6BC5, U+6C72, U+6C7D, U+6CB8, U+6DC0, U+6E07, U+6ECB, U+6EF2, U+6F06, U+6F15, U+708A, U+70CF, U+711A, U+71C8, U+71E5, U+71ED, U+723D, U+72D0, U+74A7, U+75FA, U+7626, U+76BA, U+76C3, U+7891, U+7897, U+78EF, U+79BF, U+7A00, U+7A9F, U+7AAA, U+7AAF, U+7AFF, U+7B39, U+7B51, U+7BE4, U+7C9B, U+7D3A, U+7DB4, U+7DBB, U+7F75, U+8096, U+8098, U+80A2, U+80AF, U+80CE, U+82AF, U+82D1, U+830E, U+83AB, U+8429, U+8463, U+8499, U+8511, U+852D, U+854E, U+85A6, U+85AB, U+8702, U+871C, U+8912, U+8956, U+8A02, U+8A50, U+8A60, U+8A63, U+8A6B, U+8A6E, U+8ACF, U+8AED, U+8AFE, U+8B83, U+8B90, U+8CD1, U+8CE0, U+8D05, U+8D66, U+8D74, U+8DF5, U+8FBF, U+906E, U+9077, U+912D, U+914C, U+916C, U+9192, U+91DC, U+925B, U+92FC, U+9320, U+9591, U+961C, U+9688, U+96B7, U+96BB, U+96F6, U+971E, U+9813, U+990C, U+9A19, U+9A30, U+9AB8, U+9AED, U+9B6F, U+9CE9, U+9D5C, U+9E93, U+20B9F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold024.woff2") format("woff2");
  unicode-range: U+2F5B, U+2F60, U+2FCF, U+4E14, U+4E3C, U+4ED4, U+4F3D, U+4F51, U+4FAE, U+4FB6, U+4FE3, U+4FF1, U+50B2, U+50C5, U+5102, U+51B4, U+51FD, U+5243, U+5256, U+52FF, U+533F, U+53A8, U+53AD, U+53D9, U+53DB, U+53F6, U+540F, U+5420, U+543B, U+548E, U+54BD, U+5506, U+5632, U+57A2, U+57C3, U+5815, U+589C, U+5984, U+5993, U+59D0, U+5A3C, U+5A7F, U+5BB5, U+5BC5, U+5CEF, U+5DBA, U+5E16, U+5EE3, U+5F0A, U+5FFD, U+60B6, U+60BC, U+6101, U+6144, U+6168, U+6190-6191, U+61A9, U+62D0, U+62D7, U+632B, U+63C6, U+642C, U+64EC, U+65ED, U+66F3, U+673D, U+674F, U+675C, U+67F5, U+6803, U+685F, U+6897, U+68AF, U+68FA, U+694A, U+6960, U+69CC, U+69FD, U+6A3A, U+6A58, U+6B86, U+6C5D, U+6CCC, U+6D12, U+6D2A, U+6DCB, U+6E13, U+6E15, U+6EBA, U+7027, U+7169, U+717D, U+724C, U+7259, U+727D, U+72FD, U+7336, U+7345, U+73B2, U+73CA, U+7409, U+7435-7436, U+745E, U+7460, U+74DC, U+7525, U+754F, U+7554, U+757F, U+760D, U+764C, U+76F2, U+771E, U+7729, U+7738, U+7766, U+77AD, U+77BC, U+7826, U+786B, U+79B0, U+7A14, U+7A40, U+7A6B, U+7A7F, U+7B25, U+7C1E, U+7C3E, U+7CDE, U+7D2F, U+7D46, U+7F60, U+7FA8, U+8061, U+814E, U+81A3, U+81C6, U+81E5, U+8235, U+8247, U+82A5, U+82D4, U+831C, U+8328, U+832B, U+837B-837C, U+853D, U+8587, U+8594, U+8599, U+85AA, U+85EA, U+85FB, U+868A, U+8766, U+87F9, U+881F, U+88B4, U+88DF, U+88E1, U+88F3, U+8A1D, U+8A3B, U+8A72, U+8ADC, U+8AEB, U+8AEE, U+8CC4, U+8CDC, U+8E2A, U+8E5F, U+8E87, U+8E8A, U+8F3F, U+8F44, U+8F5F, U+8FC5, U+8FE6, U+9010, U+901D, U+9065, U+914E, U+9162, U+91B8, U+91D8, U+92F3, U+932C, U+95B2, U+96DB, U+9798, U+97AD, U+9838, U+9913, U+9AC4, U+9BE8, U+9CF3, U+9DD7, U+9DF2, U+9F20, U+F9EC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold025.woff2") format("woff2");
  unicode-range: U+2F48, U+2F7D, U+2F85, U+4E9B, U+4EA8, U+4F47, U+4FEF, U+5023, U+5118, U+5141, U+51A5, U+51B6, U+51C6, U+51CC, U+51F0, U+5239, U+52FE, U+53C9, U+5484, U+54E2, U+55A9, U+567A, U+5806, U+58D5, U+5957, U+5962, U+597C, U+59E6, U+59FB, U+5A25, U+5A2F, U+5A9A, U+5AE1, U+5BE1, U+5BF5, U+5C4D, U+5C51, U+5C6F, U+5CD9, U+5CE8, U+5D6F, U+5EB8, U+5EC9, U+60E7, U+6284, U+62B9, U+62F7, U+633A, U+6372, U+637B, U+63A0, U+643E, U+647A, U+6492, U+64A5, U+6518, U+6523, U+6591, U+65FA, U+660F, U+6652, U+66F0, U+66FC, U+676D, U+6813, U+6841, U+6893, U+693F, U+698A, U+698E, U+69FB, U+6A0B, U+6A80, U+6A9C, U+6AD3, U+6AFB, U+6B89, U+6DD1, U+6E4A, U+6FE0, U+6FE4, U+704C, U+7092, U+714E, U+71D5, U+723E, U+72D7, U+72F8, U+7325, U+73A9, U+745C, U+7526, U+755D, U+75D9, U+76E7, U+7791, U+7825, U+785D, U+78D0, U+7901, U+7947, U+798D, U+79DF, U+79E4, U+7A92, U+7B95, U+7CA5, U+7CFE, U+7D21, U+7DCB, U+7E1E, U+7E55, U+7E8F, U+7F9E, U+7FE0, U+7FF3, U+800C, U+8036, U+809A, U+80B4, U+8151, U+8154, U+817A, U+81C0, U+81FC, U+8218, U+8236-8237, U+8258, U+82A6, U+82AD, U+8305, U+8389, U+840C, U+840E, U+842C, U+8461, U+8466, U+8475, U+8513, U+8523, U+8549, U+8569, U+85C1, U+8679, U+86CD, U+86D9, U+87BA, U+87EC, U+887F, U+8888, U+895E, U+8B33, U+8B39, U+8CAA, U+8E35, U+8ECB, U+8FC2, U+901E, U+9041, U+9059, U+905C, U+9061, U+918D, U+9190, U+91C7, U+92D2, U+930F, U+93A7, U+9583, U+95A4, U+966A, U+96BC, U+96C1, U+96CC, U+971C, U+9784, U+978D, U+97FB, U+9909, U+9910, U+9945, U+9BC9, U+FA11;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold026.woff2") format("woff2");
  unicode-range: U+2F0F, U+2F14, U+2FA2, U+4E11, U+4E32, U+4E4D, U+4F5B, U+4F86, U+4FC4, U+4FE0, U+4FF8, U+5005, U+5016, U+5036, U+50FB, U+515C, U+51A8, U+51DC, U+51E0, U+51F1, U+51F8-51F9, U+520E, U+52AB, U+5315, U+5347, U+536F, U+5378, U+53E2, U+54A5, U+555C, U+555E, U+55DA, U+55DC, U+55DF, U+55E4, U+5687, U+5703, U+5751, U+5766, U+57D2, U+5830, U+5861, U+589F, U+58F1, U+5B0C, U+5BC7, U+5CFB, U+5D4C, U+5DBD, U+5DCC, U+5DEB, U+5DF4, U+5DF7, U+5E40, U+5ED3, U+5EDF, U+5F14, U+5F1B, U+5F27, U+5F77, U+60DF, U+6167, U+619A, U+622E, U+6309, U+633D, U+634F, U+6377, U+639F, U+63AC, U+63C4, U+63F6, U+646F, U+64B0, U+64E2, U+65AF, U+6666, U+66A2, U+66DD, U+6775, U+67D1, U+6816, U+68A2, U+68F9, U+6900, U+696F, U+6A2B, U+6ABB, U+6ADB, U+6B23, U+6B3D, U+6B4E, U+6BEF, U+6C4E, U+6C50, U+6CAB, U+6E3E, U+6E5B, U+6F38, U+6FEB, U+6FF1, U+7058, U+707C, U+7109, U+7149, U+714C, U+721B, U+725D, U+725F, U+7396, U+745B, U+7483, U+752B, U+7560, U+75BC, U+75E2, U+766A, U+77A0, U+77A5, U+78A7, U+792B, U+7960, U+79B1, U+7A1C, U+7B8B, U+7C95, U+7C9F, U+7CCA, U+7D68, U+7DB8, U+7DFB, U+7E61, U+7E82, U+7F77, U+7F79, U+8017, U+803D, U+805A, U+80DA, U+80E4, U+8106, U+810A, U+8299, U+8338, U+834A, U+8404, U+847A, U+8494, U+86DB, U+8718, U+87FB, U+8882, U+8910, U+8944, U+8A1B, U+8A23, U+8A54, U+8A85, U+8AD2, U+8B01-8B02, U+8B5A, U+8CD3, U+8CE4, U+8CE6, U+8D0B, U+8DE8, U+8E44, U+8F1B, U+8F62, U+8FA3, U+907C-907D, U+9091, U+9127, U+91AC, U+9264, U+9306, U+936C, U+93D1, U+95CA, U+9744, U+978B, U+985A, U+98F4, U+9952, U+9A52, U+9AF7, U+9B41, U+9BAD, U+9BDB, U+9C39, U+9C3B, U+9C57, U+9CF6, U+9D09, U+9D3B, U+9E1E, U+9EB4-9EB5, U+9ECE, U+F993, U+2F8ED;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold027.woff2") format("woff2");
  unicode-range: U+2F18, U+2F8B, U+4E4E, U+4E8E, U+4E98, U+4EA6, U+4EC4, U+4F43, U+5026, U+5039, U+505A, U+50AD, U+50D1, U+5191, U+52D2, U+535C, U+5398, U+53A9, U+5475, U+54A4, U+54AC, U+54E8, U+54ED, U+54FA, U+557C, U+55AC, U+5614, U+5617, U+56CA, U+5821, U+5955, U+5978, U+59EA, U+5A9B, U+5B55, U+5B78, U+5BA5, U+5BD3, U+5C24, U+5C41, U+5C53, U+5C5B, U+5C79, U+5D14, U+5D69, U+5F45, U+5F6C, U+601C, U+604D, U+606B, U+6089, U+60B4, U+60B8, U+60F0, U+61AB, U+61AE, U+61BE, U+61FF, U+620A, U+621F, U+6241, U+626E, U+6289, U+634C, U+65A1, U+65A5, U+65A7, U+6727, U+6853, U+68B5, U+6912, U+6953, U+69B4, U+69D9, U+6A47, U+6A8E, U+6B3E, U+6B7F, U+6BB7, U+6BC0, U+6BD8, U+6C3E, U+6C81, U+6C8C, U+6C93, U+6CC4, U+6CEA, U+6DF9, U+6E1A, U+7015, U+703E, U+7114, U+71BE, U+7280, U+72C4, U+72E1, U+734F, U+745A, U+75B5, U+75B9, U+75D2, U+75E9, U+7624, U+779E, U+77EF, U+78DA, U+798E, U+7A62, U+7A83, U+7AE6, U+7AEA, U+7B19, U+7B75, U+7BDD, U+7C82, U+7CE0, U+7D18, U+7D43, U+7D62, U+7E0B, U+8006, U+805F, U+806F, U+8073, U+808B, U+809B, U+80B1, U+811B, U+814B, U+818F, U+81CD, U+8205, U+821C, U+8233, U+8278, U+8304, U+8309, U+8339, U+8340, U+8431, U+8471, U+84EC, U+8521, U+8543, U+857E, U+8606, U+8654, U+8695, U+86ED, U+8805, U+8823, U+88D4, U+8AA6, U+8AE7, U+8B80, U+8CC2, U+8D14, U+8DA8, U+8DBE, U+8DEA, U+8EB1, U+8EBE, U+8FC4, U+900D, U+9017, U+9075, U+90E4, U+9249, U+932B, U+947F, U+968B, U+96CD, U+9761, U+9870, U+98AF, U+9949, U+99A8, U+9BA8, U+9BAB, U+9D6C, U+9E9F, U+9F4B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold028.woff2") format("woff2");
  unicode-range: U+2F66, U+2FCD, U+4EA5, U+4F98, U+5072, U+5180, U+51DB, U+51EA, U+524C, U+52C1, U+53A0, U+5403, U+5477, U+54B8, U+55C4, U+5631, U+5634, U+56C3, U+5713, U+5718, U+57E0, U+57F4, U+582F, U+58BE, U+58EC, U+5944, U+5A62, U+5B95, U+5C60, U+5CB1, U+5DF2, U+5E37, U+5EFF, U+5F3C, U+5F4C, U+5F57, U+5F59, U+5FA8, U+6063, U+608D, U+60C7, U+60E0, U+61C9, U+6258, U+62F5, U+6369, U+637A, U+638F, U+64F2, U+652A-652B, U+6583, U+6670, U+6688, U+6714, U+6736, U+6753, U+67DA, U+67E9, U+6846, U+6854, U+68CD, U+68D8, U+690B, U+6955, U+6AC3, U+6C40, U+6D8E, U+6DB8, U+6DEE, U+6E20, U+6E25, U+6E58, U+6EF8, U+6FAA, U+6FB1, U+70AC, U+70B8, U+7164, U+7199, U+71D0, U+71E6, U+71FB, U+71FF, U+733E, U+73C2, U+74E2, U+7587, U+758B, U+75E3, U+75F0, U+7647, U+776B, U+777E, U+77E7, U+786F, U+78A9, U+795F, U+7A84, U+7AA9, U+7AC8, U+7B67, U+7B94, U+7C00, U+7C2A, U+7CFA, U+7D72, U+7D9C, U+7E20, U+7F3A, U+8000, U+8058, U+8070, U+8087, U+8129, U+8139, U+81A0, U+81B5, U+81BF, U+81FA, U+8207, U+8292, U+82EB, U+8490, U+84C9, U+84D1, U+8557, U+856A, U+85C9, U+86C6, U+86CB, U+8755, U+87A2, U+880D, U+8822, U+8877, U+88A2, U+88B1, U+890C, U+892A, U+8966, U+8ABC, U+8B10, U+8B17, U+8C79, U+8CB6, U+8CC8, U+8CFD, U+8D73, U+8E72, U+8E99, U+8EEB, U+8F2F, U+8F4D, U+8F9F, U+8FED, U+9021, U+903C, U+9081, U+9087, U+9119, U+929A, U+929C, U+92CF, U+92F8, U+9310, U+9328, U+95BB, U+9716, U+976D, U+986B, U+9957, U+9A55, U+9AEF, U+9B22, U+9B92, U+9C0A, U+9E7C, U+9EBE, U+9F0E, U+9F90, U+FA1F, U+2F877, U+2F8DC;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold029.woff2") format("woff2");
  unicode-range: U+2F87, U+4E15, U+4E56, U+4F1C, U+5080, U+5085, U+5121, U+5147, U+51CB, U+51E7, U+51ED, U+524B, U+52BE, U+5301, U+5319, U+5321, U+532A, U+543C, U+5480, U+5556, U+5664, U+5851, U+58DC, U+58FD, U+5925, U+594E, U+59E5, U+5A29, U+5A36, U+5B30, U+5B32, U+5B8D, U+5BE6, U+5C39, U+5D8C, U+5E47, U+5E5F, U+5E96, U+5EE0, U+5F17, U+5F7F, U+5F8A, U+5F98, U+5FB7, U+5FBD, U+6062, U+60A7, U+6147, U+61AC, U+61C3, U+61E3, U+620D-620E, U+622A, U+6292, U+62EE, U+63A9, U+6426, U+6478, U+6703, U+6715, U+6726, U+6883, U+689F, U+6930, U+699B-699C, U+69C7, U+6A05, U+6A84, U+6AAE, U+6B12, U+6BB2, U+6BEB-6BEC, U+6C6A, U+6C83, U+6D1F, U+6D59, U+6DD8, U+6ED4, U+6EFE, U+6F09, U+6F32, U+6F3F, U+7006, U+701F, U+7078, U+7099, U+70D9, U+70F9, U+7184, U+71D7, U+71F5, U+7252, U+73C8, U+7433, U+7455, U+748B, U+7515, U+7576, U+7586, U+75D8, U+7693, U+76E5, U+77B0, U+77DC, U+7887, U+78D4, U+7953, U+79A6, U+79AE, U+79BD, U+7A1F, U+7A46, U+7AD9, U+7AFA, U+7B08, U+7B4F, U+7B72, U+7B92, U+7C50, U+7CAE, U+7D2C, U+7D93, U+7DEC, U+7E23, U+7FF0, U+8180, U+821B, U+826B, U+827E, U+82BB, U+82E7, U+839E, U+83D6, U+840A, U+865E, U+86C9, U+86E4, U+873B, U+87E0, U+8904, U+8977, U+8AB9, U+8AFA, U+8D04, U+8D16, U+8E42, U+8E81, U+8E85, U+8E91, U+9013, U+9089-908A, U+90C3, U+916A, U+91A4, U+91C9, U+91E7, U+9266, U+927E, U+9354, U+937E, U+96EB, U+97A0, U+98C4, U+99C1, U+9B1A, U+9B28, U+9B44, U+9BD6, U+9C2F, U+9C48, U+9C52, U+9DAF, U+9E92, U+9EBA, U+9F3E, U+9F67, U+9F95;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold030.woff2") format("woff2");
  unicode-range: U+2F3D, U+2F44, U+4E19, U+4E58, U+4E99, U+4E9E, U+4EC0, U+4EC6, U+4F36, U+4F69, U+4F83, U+4F91, U+4FCE, U+4FD0, U+4FD8, U+4FDF, U+5043, U+50DE, U+50F9, U+5109, U+514C, U+5152, U+51A4, U+51C9, U+5269, U+527D, U+528D, U+52F3, U+52FA, U+5308, U+5377, U+537D, U+53DF, U+5486, U+54E9, U+54EE, U+5544, U+558A, U+55AE, U+562F, U+565B, U+56A5, U+56B4, U+56BC, U+56EE, U+5700, U+5708, U+5879, U+587C, U+5958, U+5BE8, U+5BEC, U+5C4F, U+5D0B, U+5D17, U+5DAE, U+5E1B, U+5E9A, U+5EEC, U+5F10, U+5F6A, U+5FA0, U+6015, U+6041, U+6043, U+606A, U+6084, U+6194, U+61FA, U+61FC, U+6208, U+620C, U+6248, U+629B, U+62FF, U+6357, U+63BB, U+649E, U+64BC, U+64E1, U+6572, U+6582, U+65A4, U+6635, U+66D9, U+675E, U+6777, U+6787, U+67A1, U+67B7, U+67FE, U+6867, U+68A7, U+68B1, U+68B3, U+6962, U+6977, U+69AE, U+69C3, U+69FF, U+6A59, U+6AC2, U+6CDB, U+6D85, U+6E38, U+6F11, U+6FB9, U+6FFE, U+7119, U+7155, U+722C, U+7232, U+7240, U+731C, U+7370, U+73C0, U+7422, U+7425, U+7432, U+74BD, U+74DA, U+75B1, U+7621, U+7690, U+77EE, U+788D, U+7941, U+79B9, U+79E3, U+7A63, U+7AC4, U+7ADF, U+7AE3, U+7B4D, U+7BAD, U+7C7E, U+7D63, U+7DBD, U+7DDE, U+7E0A, U+7E37, U+7E6D, U+7F8C, U+7FC5, U+80F1, U+8171, U+81C2, U+8201, U+8229, U+822B, U+82B9, U+82FA, U+83A2, U+846D, U+8655, U+86F8, U+86FE, U+87C7, U+8852, U+88C3, U+8A03, U+8A25, U+8ACD, U+8AF7, U+8B04, U+8B16, U+8B6C, U+8D6B, U+8DCB, U+8E93, U+8F61, U+8FF8, U+914B, U+9248, U+929B, U+92E4, U+932E, U+937C, U+9435, U+947D, U+958F, U+95A8, U+97EE, U+9812, U+9824, U+983D, U+9903, U+991E, U+9998, U+99AD-99AE, U+99DD, U+99F1, U+9C13, U+9C3A, U+9DFA, U+9EA9, U+9EF4, U+2F818;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold031.woff2") format("woff2");
  unicode-range: U+2F72, U+2FA3, U+2FC8, U+2FD1, U+57DC, U+5859, U+589E, U+58D8, U+58DE, U+58EF, U+5967, U+596C, U+5A03, U+5B43, U+5B5C, U+5BE2, U+5C07-5C08, U+5C2D, U+5CFD, U+5D1A, U+5DD6, U+5DE2, U+5DFD, U+5E36, U+5EF3, U+5F48, U+5F9E, U+5FB5, U+6046, U+6055, U+608C, U+60E1, U+60FA, U+613C, U+61F7, U+6230, U+6232, U+62C2, U+62D4, U+62DC, U+63ED, U+6416, U+641C, U+64CA, U+651D, U+6536, U+654D, U+660A, U+6634, U+6644, U+664F, U+665A, U+665D, U+665F, U+6668, U+6689, U+66C6, U+66C9, U+67CA, U+67D8, U+681E, U+689B, U+691B, U+6A02, U+6A1F, U+6A23, U+6A6B, U+6AA2, U+6B65, U+6B77, U+6BCF, U+6C23, U+6D35, U+6D38, U+6D6C, U+6D89, U+6DDA, U+6DE8, U+6E34, U+6EAB, U+6EC9, U+6EEF, U+6F23, U+6F81, U+6FD5, U+7028, U+7130, U+71CE, U+71D2, U+722D, U+72C0, U+72F9, U+7378, U+7473, U+7476, U+7562, U+758A, U+758F, U+76DC, U+76E1, U+7827, U+788E, U+7893, U+7955, U+7962, U+7977, U+797F, U+79AA, U+79BE, U+7A3B, U+7A57, U+7A70, U+7A79, U+7CB9, U+7DA0, U+7DD6, U+7DE3, U+7E31, U+7E96, U+7F9A, U+807D, U+81DF, U+838A, U+83EB, U+8420, U+8568, U+8597, U+85B0, U+85CF, U+85E5, U+865B, U+885E, U+88DD, U+89BD, U+8A62, U+8AC4, U+8B20, U+8B93, U+8CE3, U+8CF4, U+8F49, U+8FEA, U+90DE, U+9149, U+9187, U+9189, U+91C0, U+9291, U+9304, U+9318, U+934A, U+93AD, U+9444, U+9677, U+96AA, U+96DC, U+975C, U+980C, U+9817, U+986F, U+98DC, U+9A37, U+9A4D, U+9A57, U+9AEE, U+9DC4, U+9EC3, U+9ED1, U+9ED8, U+9EDB, U+9F4A, U+F91D, U+F928-F929, U+F936, U+F992, U+F9D0, U+FA16, U+FA19-FA1B, U+FA22, U+FA26, U+FA30-FA31, U+FA33;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold032.woff2") format("woff2");
  unicode-range: U+2F89, U+2FA4, U+2FC9, U+4F7C, U+4FA0, U+4FAD, U+5265, U+5283, U+531D, U+5366, U+540B, U+5451, U+548B, U+5516, U+5618, U+5678, U+56A2, U+5764, U+5858, U+586B, U+58F7, U+5919, U+59F6, U+5A41, U+5B2C, U+5C61, U+5CA8, U+5EFC, U+5F4A, U+6108, U+617E, U+63B4, U+63D6, U+649A, U+64B9, U+64FE, U+658C, U+67C1, U+6802, U+6822, U+6834, U+685D, U+68BC, U+68C9, U+6919, U+6934, U+6973, U+6994, U+6A17, U+6A35, U+6A61, U+6A7F, U+6AE8, U+6B1D, U+6D8C, U+6D9B-6D9C, U+6E8C, U+6F45, U+6F97, U+701E, U+7026, U+7194, U+72DB, U+7337, U+73EA, U+7511, U+751C, U+7566, U+7577, U+75D4, U+76C8, U+783A, U+783F, U+7872, U+7895, U+7A17, U+7A4E, U+7A50, U+7AC3, U+7B86, U+7BAA, U+7BED, U+7C38, U+7C81, U+7C8D, U+7CCE, U+7CDF, U+7DAC, U+7E4B, U+7E4D, U+7F6B, U+7FEB, U+807E, U+826E, U+82C5, U+82D3, U+834F, U+83B1, U+83DF, U+83F0, U+844E, U+848B, U+849C, U+8500, U+851A, U+854A, U+85AE-85AF, U+85F7, U+867B, U+86A4, U+86CE, U+8749, U+874B, U+877F, U+88B7, U+8A51, U+8ACC, U+8B2C, U+8CCE, U+8EAF, U+8FE9, U+9197, U+91C6, U+91E6, U+920E, U+9237, U+92EA, U+92F2, U+934D, U+9397, U+939A, U+9419, U+9438, U+9453, U+981A, U+982C, U+9834, U+985B, U+9A28, U+9BAA, U+9BF5, U+9C0D, U+9C10, U+9C2D, U+9D07, U+9D0E, U+9D1B, U+9D2B-9D2C, U+9D60-9D61, U+9E78, U+9EB9, U+9ECD, U+FA34-FA35, U+FA37-FA38, U+FA3A-FA3B, U+FA3D, U+FA3F-FA41, U+FA43-FA48, U+FA4A-FA57, U+FA59-FA5C, U+FA5F, U+FA61-FA65, U+FA67-FA69;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold033.woff2") format("woff2");
  unicode-range: U+2E8E, U+2F02-2F03, U+2F05, U+2F07, U+2F09, U+2F0C-2F0E, U+2F10, U+2F13, U+2F15-2F16, U+2F19-2F1B, U+4E10, U+4E17, U+4E2A, U+4E31, U+4E36, U+4E3F, U+4E42, U+4E55, U+4E62, U+4E82, U+4E85, U+4E8A, U+4E9F-4EA0, U+4EA2, U+4EB0, U+4EB3, U+4EB6, U+4EC2, U+4ECD-4ECE, U+4ED7, U+4EDE-4EDF, U+4EED, U+4EF7, U+4F09, U+4F30, U+4F57, U+4F5A, U+4F5D-4F5E, U+4F6F-4F70, U+4F76, U+4F7B, U+4F88, U+4F8F, U+4F96, U+4FAB, U+4FD1, U+4FD4, U+4FDA-4FDB, U+4FE4-4FE5, U+4FF6, U+4FFE, U+5006, U+500F, U+5011, U+5014, U+501A, U+5021, U+5025, U+5028-502A, U+502C, U+5047-5048, U+5050, U+5055-5056, U+506C, U+5078, U+509A, U+50B4, U+50C2, U+50C9-50CA, U+50D6, U+50E3, U+50E5, U+50ED-50EE, U+50F5, U+5101, U+5114-5116, U+511A, U+5137, U+513A-513C, U+513F-5140, U+5154, U+5162, U+5169-516A, U+516E, U+5182, U+5189, U+518C, U+518F-5190, U+5193, U+5195-5196, U+51A2, U+51A6, U+51A9-51AB, U+51B0-51B3, U+51B5, U+51BD, U+51C5, U+51D6, U+51E9, U+51F5, U+51FE, U+5204, U+520B, U+5214, U+5227, U+522A, U+522E, U+5233, U+5244, U+524F, U+5254, U+525E, U+526A, U+5271, U+5273-5274, U+527F, U+5288, U+5291-5292, U+5294, U+52AC-52AD, U+52B5, U+52BC, U+52CD, U+52D7, U+52DE, U+52E0, U+52E3, U+52E6, U+52F5, U+52F8-52F9, U+5306, U+530D, U+530F-5310, U+531A, U+5323, U+532F, U+5331, U+5333, U+5338, U+5340, U+5345-5346, U+5349, U+534D, U+535E, U+5369, U+536E, U+537B, U+5382, U+5396, U+53A5-53A6, U+53AE, U+53B0, U+53B6, U+53C3, U+53E8, U+53ED-53EE, U+53FA, U+5401, U+541D, U+5429, U+542C-542E, U+5436, U+543D, U+5440, U+544E, U+5470-5471, U+5476;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold034.woff2") format("woff2");
  unicode-range: U+2F1E, U+2F21-2F22, U+548F-5490, U+5492, U+54A2, U+54A8, U+54AB, U+54AF, U+54BC, U+54BE, U+54C2, U+54C4, U+54C7-54C8, U+54D8, U+54E5-54E6, U+54FD, U+550F, U+5514, U+552E, U+5533, U+5539, U+5540, U+5545, U+554C, U+5557, U+555D, U+5563, U+557B, U+557E, U+5580, U+5583, U+5587, U+5599, U+559E-559F, U+55A8, U+55C7, U+55D4, U+55F7, U+55F9, U+55FD-55FE, U+5616, U+561B, U+5636, U+5638, U+564E, U+5650, U+566A-566C, U+5680, U+5686, U+568A, U+568F, U+5694, U+56A0, U+56AE, U+56B6, U+56C0, U+56C2, U+56C8, U+56CE, U+56D1, U+56D3, U+56D7-56D8, U+56F9, U+56FF, U+5704, U+5709, U+570D, U+5716, U+571C, U+5726, U+5737-5738, U+573B, U+5740, U+574E-574F, U+5761, U+5769, U+577F, U+5788-5789, U+5793, U+57A0, U+57A4, U+57AA, U+57B0, U+57B3, U+57C0, U+57C6, U+57D3-57D4, U+57D6, U+57E3, U+580A-580B, U+5819, U+581D, U+583D, U+584B, U+5852, U+5862, U+5870, U+5872, U+5885, U+58AB, U+58AE, U+58B8-58BB, U+58C5, U+58D1, U+58D3, U+58D7, U+58D9, U+58DF, U+58E4-58E5, U+58F9, U+58FB-58FC, U+5902, U+590A, U+5910, U+5918, U+591B, U+592C-592D, U+5932, U+5938, U+593E, U+5950, U+595A, U+5960, U+5969, U+5981, U+598D, U+599B, U+599D, U+59A3, U+59B2, U+59C6, U+59D9-59DA, U+59E8, U+5A09, U+5A11, U+5A1A, U+5A1C, U+5A1F, U+5A35, U+5A40, U+5A49, U+5A6A, U+5A6C, U+5ABC-5ABE, U+5AC2, U+5ACB, U+5AD0, U+5AD6-5AD7, U+5AE3, U+5AE6, U+5AE9, U+5AFA-5AFB, U+5B0B, U+5B16, U+5B2A, U+5B36, U+5B3E, U+5B40, U+5B45, U+5B51, U+5B5A-5B5B, U+5B65, U+5B69, U+5B70-5B71, U+5B73, U+5B75;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold035.woff2") format("woff2");
  unicode-range: U+2E90, U+2E93-2E94, U+2F27, U+2F2A-2F2C, U+2F2E, U+2F33-2F37, U+2F3A-2F3B, U+5B7A, U+5B80, U+5B83, U+5BA6, U+5BB8, U+5BC3, U+5BC9, U+5BD0, U+5BD4, U+5BDE, U+5BE4-5BE5, U+5BEB, U+5BF0, U+5BF3, U+5BF6, U+5C05, U+5C0D, U+5C13, U+5C20, U+5C22, U+5C28, U+5C38, U+5C46, U+5C4E, U+5C50, U+5C6C, U+5C6E, U+5C76, U+5C8C, U+5C91, U+5C94, U+5CAB, U+5CB6-5CB7, U+5CBB-5CBC, U+5CBE, U+5CC5, U+5CC7, U+5CE9-5CEA, U+5CED, U+5CFA, U+5D11, U+5D15, U+5D18-5D19, U+5D1B, U+5D1F, U+5D22, U+5D4B, U+5D4E, U+5D52, U+5D5C, U+5D6C, U+5D73, U+5D76, U+5D82, U+5D84, U+5D87, U+5D90, U+5D9D, U+5DA2, U+5DAC, U+5DB7, U+5DBC, U+5DC9, U+5DCD, U+5DD2-5DD3, U+5DDB, U+5DF5, U+5E0B, U+5E11, U+5E19-5E1A, U+5E43-5E44, U+5E4E, U+5E54, U+5E57, U+5E62, U+5E64, U+5E75-5E76, U+5E7A, U+5E7F, U+5EA0, U+5EC1-5EC2, U+5EC8, U+5ECF-5ED0, U+5ED6, U+5EDA-5EDB, U+5EDD, U+5EE1-5EE2, U+5EE8-5EE9, U+5EF0-5EF1, U+5EF4, U+5EF8, U+5EFE, U+5F03, U+5F09, U+5F0B-5F0D, U+5F11, U+5F16, U+5F29, U+5F2D, U+5F2F, U+5F38, U+5F41, U+5F4E, U+5F51, U+5F56, U+5F5C-5F5D, U+5F61, U+5F6D, U+5F73, U+5F82-5F83, U+5F87-5F88, U+5F91, U+5F99, U+5FAD, U+5FBC, U+5FD6, U+5FDD, U+5FE4, U+5FF0-5FF1, U+5FF8, U+5FFB, U+5FFF, U+600E-6010, U+6019, U+601B, U+6021, U+6026, U+6029, U+602B, U+6031, U+603A, U+6042, U+604A, U+6059-605A, U+605F-6060, U+6064, U+606C, U+6077, U+6081, U+6083, U+608B, U+6092, U+6096-6097, U+609A-609B, U+60B3, U+60B5, U+60BD, U+60C6, U+60D3, U+60D8, U+60F1, U+60F4, U+60F6-60F7, U+60FB, U+6100, U+6103, U+6106, U+610D-610E, U+6121, U+6127-6128, U+612C, U+6134, U+613D-613F, U+6142;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold036.woff2") format("woff2");
  unicode-range: U+2E99, U+2E9B, U+2F41, U+2F46, U+614A, U+614D, U+6153, U+6158-615A, U+615D, U+615F, U+6165, U+616B, U+616F, U+6171, U+6173-6175, U+6177, U+6187, U+618A, U+6196, U+6199, U+61BA, U+61C6, U+61C8, U+61CA-61CD, U+61E6, U+61F4, U+61F6, U+61FD-61FE, U+6200, U+6209, U+6214, U+621B, U+621D-621E, U+6221, U+6233, U+624E, U+625B, U+625E, U+6260, U+6263, U+6268, U+627C, U+627E, U+6282-6283, U+6293-6294, U+6296, U+62AC, U+62BB, U+62C6-62C8, U+62CA, U+62CC, U+62CF, U+62D1, U+62EF, U+62F1, U+6302, U+6308, U+630C, U+6327, U+633E, U+634D, U+6350, U+636B, U+6376, U+6380, U+6389, U+638E, U+6396, U+63A3, U+63AB, U+63B5, U+63BE, U+63C0, U+63D2, U+63E3, U+63E9, U+6406, U+640F, U+6413, U+6417, U+6428, U+6434, U+6436, U+644E, U+6467, U+6476, U+6488, U+6493, U+6495, U+64A9, U+64BB, U+64C2, U+64C5, U+64C7, U+64D2, U+64D4, U+64D8, U+64DA, U+64E0, U+64E3, U+64E7, U+64EF, U+64F1, U+64F4, U+64F6, U+64FA, U+64FD, U+6500, U+6505, U+651C, U+6524, U+652C, U+6534-6535, U+6537-6538, U+6548, U+6555-6556, U+6558, U+655D-655E, U+6578, U+6588, U+659B, U+659F, U+65AB, U+65B7, U+65C1, U+65C3-65C4, U+65C6, U+65CC, U+65D2, U+65D9, U+65DB, U+65E0-65E1, U+65F1, U+65FB, U+6603, U+661C, U+6636, U+663F, U+6641, U+6649, U+665E, U+6662, U+6664, U+6667, U+6683-6684, U+668E, U+6698, U+669D, U+66B8-66B9, U+66BC, U+66BE, U+66C1, U+66C4, U+66DA, U+66E0, U+66E6, U+66E9, U+66F5, U+66F7, U+670F, U+6716, U+671E, U+672E, U+6737-6738, U+673F, U+6741, U+6746, U+6759, U+6760, U+6763-6764, U+676A, U+6770, U+6772-6773, U+677C, U+6785, U+6789, U+678B-678C, U+67A6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold037.woff2") format("woff2");
  unicode-range: U+2F4D-2F4F, U+2F53, U+67A9, U+67B3-67B4, U+67B8-67B9, U+67C6, U+67CE, U+67DD-67DE, U+67E2, U+67E4, U+67E7, U+67EC, U+67EE-67EF, U+6829, U+682B, U+6832, U+6840, U+684D-684E, U+6859, U+6863, U+6874, U+6877, U+687E-687F, U+688D, U+688F, U+6894, U+68A0, U+68A6, U+68AD, U+68B9-68BA, U+68C6, U+68CA, U+68D4-68D5, U+68D7, U+68E0-68E1, U+68E3, U+68E7, U+68EF, U+6901, U+6904, U+6908, U+690C, U+690F, U+691A, U+6921-6923, U+6925-6926, U+6928, U+692A, U+6936, U+6939, U+693D, U+6954, U+6959, U+695C-695E, U+6961, U+696A-696B, U+696E, U+6974, U+6978-6979, U+697E, U+6981, U+6991, U+6995, U+69A0, U+69A7, U+69B1-69B2, U+69BB, U+69BE-69BF, U+69C1, U+69CA, U+69CE, U+69D0, U+69D3, U+69DD-69DE, U+69E7-69E8, U+69EB, U+69ED, U+69F2, U+69F9, U+6A0A, U+6A0C, U+6A12-6A14, U+6A1B, U+6A1E, U+6A22, U+6A2E, U+6A36, U+6A38, U+6A44, U+6A48, U+6A62, U+6A66, U+6A72, U+6A78, U+6A8D, U+6A90, U+6A97, U+6AA0, U+6AA3, U+6AAA, U+6AAC, U+6AB3, U+6AB8, U+6AC1, U+6AD1, U+6ADA, U+6ADE-6ADF, U+6AEA, U+6AFA, U+6B05, U+6B0A, U+6B16, U+6B1F, U+6B37-6B39, U+6B43, U+6B47, U+6B49, U+6B50, U+6B54, U+6B59, U+6B5B, U+6B5F, U+6B61, U+6B78-6B79, U+6B80, U+6B83-6B84, U+6B8D, U+6B95, U+6B98, U+6B9E, U+6BA4, U+6BAA-6BAB, U+6BAF, U+6BB1, U+6BB3, U+6BBC, U+6BC6, U+6BCB, U+6BD3, U+6BDF, U+6BF3, U+6C08, U+6C13-6C14, U+6C1B, U+6C24, U+6C55, U+6C5E, U+6C62, U+6C68, U+6C73, U+6C7E, U+6C82, U+6C8D, U+6C90, U+6C92, U+6C9A-6C9B, U+6CAE, U+6CB1, U+6CBA, U+6CBD-6CBE, U+6CC5, U+6CD3, U+6CD7, U+6CD9;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold038.woff2") format("woff2");
  unicode-range: U+2F58-2F59, U+6CDD, U+6CEF, U+6CF1, U+6D0C, U+6D19, U+6D2B, U+6D33, U+6D36, U+6D3D, U+6D5A, U+6D63-6D64, U+6D79, U+6D93, U+6D95, U+6DB5, U+6DC5-6DC7, U+6DCC, U+6DD2, U+6DD5, U+6DD9, U+6DDE, U+6DE4, U+6DE6, U+6DEA, U+6DEC, U+6DFA, U+6E0A, U+6E19, U+6E1D, U+6E1F, U+6E23-6E24, U+6E2B, U+6E2D-6E2E, U+6E3A, U+6E43, U+6E4D-6E4E, U+6E5F, U+6E6B, U+6E6E, U+6E72, U+6E76, U+6E82, U+6E8F, U+6E98, U+6E9F, U+6EA5, U+6EAA, U+6EAF, U+6EB2, U+6EB7, U+6EBD, U+6EC2, U+6EC4, U+6ECC, U+6ED3, U+6ED5, U+6EEC, U+6EF7, U+6EFF, U+6F13, U+6F3E, U+6F41, U+6F58, U+6F5B, U+6F66, U+6F6D, U+6F6F, U+6F74, U+6F78, U+6F7A, U+6F7C, U+6F80, U+6F82, U+6F86, U+6F8E, U+6F91, U+6FA1, U+6FA3, U+6FB3, U+6FC2, U+6FC6, U+6FD4, U+6FD8, U+6FDB, U+6FDF, U+6FEC, U+6FEE, U+6FF3, U+6FF6, U+6FFA, U+7001, U+7009, U+700B, U+700F, U+7011, U+7018, U+701A-701B, U+701D, U+7030, U+7032, U+7051, U+7063, U+70AE-70AF, U+70B3, U+70CB, U+70DD, U+70DF, U+70F1, U+70FD, U+711C, U+7156, U+7162, U+7165-7166, U+716C, U+7188, U+718F, U+7195, U+71A8, U+71AC, U+71B9, U+71C9, U+71D4, U+71DF-71E0, U+71E7, U+71EC, U+71EE, U+71F9, U+71FC, U+720D, U+7210, U+7228, U+7230, U+723B-723C, U+723F, U+7246, U+724B, U+7258, U+7274, U+727E, U+7281-7282, U+7287, U+7292, U+7296, U+72A2, U+72A7, U+72B2, U+72B9, U+72C3, U+72C6, U+72CE, U+72D2, U+72E0, U+72E2, U+72F7, U+730A, U+7316-7317, U+731D, U+7329, U+732F, U+7334, U+734E, U+7357, U+7368, U+736A, U+7375, U+737A-737B, U+73B3, U+73BB, U+73CE, U+73DE, U+73E5, U+73EE, U+73F1, U+73F8, U+7405;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold039.woff2") format("woff2");
  unicode-range: U+2F68, U+743A, U+743F, U+7441, U+7459, U+745F, U+7463-7464, U+7469-746A, U+746F-7470, U+747E, U+749E, U+74A2, U+74CA, U+74CF, U+74D4, U+74E0, U+74E3, U+74E7, U+74E9, U+74EE, U+74F0-74F2, U+74F7-74F8, U+7503-7505, U+750C-750E, U+7513, U+751E, U+752C, U+7538, U+753C, U+7544, U+7546, U+7549-754B, U+754D, U+755A-755B, U+7564, U+7567, U+7569, U+756B, U+756D, U+7574, U+7578, U+7582, U+7589, U+7594, U+759A, U+759D, U+75A3, U+75A5, U+75B3, U+75B8, U+75BD, U+75C2-75C3, U+75CA, U+75CD, U+75DE, U+75F2-75F3, U+75FC, U+75FE-75FF, U+7601, U+7609, U+760B, U+761F-7620, U+7622, U+7627, U+7630, U+7634, U+763B, U+7646, U+7648, U+7658, U+765C, U+7661-7662, U+7667-7669, U+766C, U+7670, U+7672, U+7676, U+7678, U+767C, U+7680, U+7683, U+7688, U+768B, U+768E, U+7696, U+7699-769A, U+76B0, U+76B4, U+76B7-76B9, U+76C2, U+76CD, U+76D2, U+76D6, U+76DE, U+76EA, U+76FB, U+7704, U+7707-7708, U+771B, U+7724-7726, U+7737, U+7747, U+775A-775B, U+7765, U+7779, U+777F, U+778B, U+778E, U+77B6, U+77B9, U+77BB, U+77BD, U+77BF, U+77C7, U+77CD, U+77D7, U+77DA, U+77E3, U+77FC, U+780C, U+7812, U+7820, U+7845, U+7874, U+787C, U+7886, U+788C, U+789A, U+78A3, U+78AA, U+78AF, U+78B5, U+78BC, U+78BE, U+78C5-78C6, U+78CA-78CB, U+78D1, U+78E7, U+78EC, U+78F4, U+78FD, U+7907, U+7911-7912, U+7919, U+7926, U+792A, U+792C, U+7957, U+795A, U+797A, U+7980, U+798A, U+799D, U+79A7, U+79B3, U+79BA, U+79C9, U+79D5, U+79E1, U+79E7, U+79EC, U+7A08, U+7A0D, U+7A18-7A19, U+7A20, U+7A31, U+7A37, U+7A3E, U+7A43;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold040.woff2") format("woff2");
  unicode-range: U+7A49, U+7A61, U+7A69, U+7A7D, U+7A88, U+7A95-7A98, U+7AB0, U+7AB6, U+7ABF, U+7AC5, U+7AC7, U+7ACA, U+7ACD, U+7ACF, U+7AD2-7AD3, U+7AD5, U+7ADA, U+7ADD, U+7AE1-7AE2, U+7AED, U+7AF0, U+7AF8, U+7B02, U+7B04, U+7B06, U+7B0A-7B0B, U+7B0F, U+7B18, U+7B1E, U+7B28, U+7B33, U+7B35-7B36, U+7B45, U+7B4C, U+7B50, U+7B5D, U+7B65, U+7B6C, U+7B6E, U+7B70-7B71, U+7B74, U+7B7A, U+7B8D, U+7B8F, U+7B98-7B9A, U+7B9C-7B9D, U+7B9F, U+7BB4, U+7BC1, U+7BC6, U+7BCB-7BCC, U+7BCF, U+7BE5-7BE6, U+7BE9, U+7BF3, U+7BF6-7BF7, U+7C07, U+7C0D, U+7C11-7C14, U+7C17, U+7C1F, U+7C23, U+7C27, U+7C2B, U+7C37, U+7C3D, U+7C40, U+7C43, U+7C4C, U+7C4F, U+7C54, U+7C56, U+7C58, U+7C5F, U+7C64-7C65, U+7C6C, U+7C75, U+7C83, U+7C90, U+7CA1-7CA2, U+7CA4, U+7CA8, U+7CAB, U+7CAD, U+7CB1-7CB3, U+7CBD, U+7CC0, U+7CC2, U+7CC5, U+7CD2, U+7CD8, U+7CDC, U+7CE2, U+7CEF, U+7CF2, U+7CF4, U+7CF6, U+7D02, U+7D06, U+7D0A, U+7D15, U+7D1C, U+7D2E, U+7D32, U+7D35, U+7D3F, U+7D45, U+7D4B, U+7D4E-7D4F, U+7D56, U+7D5B, U+7D6E, U+7D73, U+7D7D, U+7D89, U+7D8F, U+7D9B, U+7D9F, U+7DA2-7DA3, U+7DAB, U+7DAE-7DB0, U+7DB5, U+7DC7, U+7DD5, U+7DD8, U+7DDC-7DDD, U+7DE1, U+7DE4, U+7DF2, U+7E05, U+7E09, U+7E12, U+7E1F, U+7E21-7E22, U+7E32, U+7E35, U+7E39-7E3B, U+7E3D, U+7E43, U+7E46, U+7E56, U+7E59-7E5A, U+7E5D-7E5E, U+7E66-7E67, U+7E69-7E6A, U+7E79, U+7E7B-7E7D, U+7E7F, U+7E83, U+7E88-7E89, U+7E8C, U+7E8E, U+7E90, U+7E92-7E94, U+7E9B-7E9C, U+7F38, U+7F45, U+7F4C-7F4E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold041.woff2") format("woff2");
  unicode-range: U+2F79, U+2F7E, U+2F80, U+7F50-7F51, U+7F54-7F55, U+7F58, U+7F5F, U+7F67-7F69, U+7F78, U+7F82-7F83, U+7F86-7F88, U+7F94, U+7F9D, U+7FA3, U+7FAE-7FAF, U+7FB2, U+7FB6, U+7FB8-7FB9, U+7FC6, U+7FCA, U+7FD5, U+7FE1, U+7FE6, U+7FE9, U+7FF9, U+8004, U+800B, U+8012, U+8018-8019, U+801C, U+8021, U+8028, U+803B, U+803F, U+8046, U+804A, U+8052, U+8062, U+8068, U+8072, U+8076, U+8079, U+807F, U+8084-8086, U+8093, U+80AC-80AD, U+80C4, U+80D6, U+80D9, U+80DB, U+80DD, U+80E5, U+80EF, U+80FC, U+8109, U+8123, U+812F, U+813E, U+8146, U+8153, U+815F, U+8165-8166, U+816E, U+8174, U+8182-8183, U+8188, U+818A, U+8193, U+8195, U+81A4, U+81A9, U+81B0, U+81B8, U+81BA, U+81BD-81BE, U+81C8-81C9, U+81D1, U+81D8-81DA, U+81E0, U+81E7, U+81FB, U+81FE, U+8202, U+8209-820A, U+820D, U+8212, U+8216, U+822E, U+8238, U+8240, U+8259-825A, U+825D, U+825F, U+8262, U+8264, U+8268, U+826A, U+8271, U+8277, U+828D, U+829F, U+82AB-82AC, U+82D2, U+82D9, U+82DC, U+82DE-82DF, U+82E1, U+82E3, U+82F3-82F4, U+82F9, U+82FB, U+8303, U+8306, U+8316-8318, U+8323, U+832F, U+8331-8332, U+8334-8335, U+8345, U+8350, U+8373, U+8375, U+8385, U+8387, U+838E, U+8393, U+8396, U+839A, U+839F-83A0, U+83A8, U+83AA, U+83B5, U+83BD, U+83C1, U+83CE, U+83D8, U+83E0, U+83F2, U+83F4, U+83F7, U+83FB, U+83FD, U+8403, U+8407, U+840B, U+840D, U+8413, U+8422, U+842A, U+8435, U+8438, U+843C, U+8446, U+8462, U+8469, U+846B;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold042.woff2") format("woff2");
  unicode-range: U+2F8C, U+2F91, U+846E-846F, U+8477, U+8479, U+8482, U+8484, U+849F, U+84A1, U+84AD, U+84B9, U+84BB, U+84BF, U+84C1, U+84C6, U+84CA, U+84CD, U+84D0, U+84D6, U+84D9-84DA, U+84F4, U+84FC, U+84FF, U+8506, U+8514-8515, U+8517-8518, U+851F, U+852C, U+8540-8541, U+8548, U+854B, U+8555, U+8558, U+855A, U+8563, U+856D, U+8577, U+8580, U+8588, U+858A, U+8590-8591, U+859B-859C, U+85A4, U+85A8, U+85B9-85BA, U+85D0, U+85D5, U+85DC, U+85F9-85FA, U+85FE, U+8602, U+860A-860B, U+8613, U+8616-8617, U+861A, U+8622, U+862F-8630, U+863F, U+864D, U+865F, U+8667, U+8671, U+868B-868C, U+8693, U+86A3, U+86A9-86AB, U+86AF-86B0, U+86B6, U+86C4, U+86D4, U+86DE-86DF, U+86E9, U+86EC, U+86EF, U+86F9, U+86FB, U+8703, U+8706, U+8708-870A, U+870D, U+8711-8712, U+871A, U+8725, U+8729, U+8734, U+8737, U+873F, U+874C, U+874E, U+8753, U+8757, U+8759, U+875F-8760, U+8763, U+8768, U+876A, U+876E, U+8774, U+8778, U+8782, U+879F, U+87AB, U+87AF, U+87B3, U+87BB, U+87BD, U+87C0, U+87C4, U+87C6, U+87CB, U+87D0, U+87D2, U+87EF, U+87F2, U+87F6-87F7, U+87FE, U+880E-880F, U+8811, U+8815-8816, U+8821, U+8827, U+8831, U+8836, U+8839, U+883B, U+8842, U+8844, U+884D, U+8859, U+8862, U+886B, U+8872, U+8875, U+887D-887E, U+888D, U+8892, U+8897, U+8899, U+889E, U+88A4, U+88AE, U+88B0, U+88B5, U+88BF, U+88C4, U+88D8-88D9, U+88E8, U+88F2, U+88F4, U+88F9, U+88FC, U+8902, U+890A, U+8913, U+891D-891E, U+8925, U+892B, U+8936, U+8938, U+893B, U+8941, U+8943, U+894C-894D, U+8960, U+8964, U+896A, U+896D, U+896F, U+8974, U+897E, U+8983, U+8988, U+898A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold043.woff2") format("woff2");
  unicode-range: U+2F97-2F98, U+8993, U+8998, U+89A1, U+89A6, U+89A9, U+89AC, U+89AF, U+89B2, U+89BA, U+89BF-89C0, U+89DA, U+89DC-89DD, U+89E7, U+89F4, U+89F8, U+8A0C, U+8A10, U+8A16, U+8A36, U+8A41, U+8A46, U+8A48, U+8A52, U+8A5B, U+8A6C-8A6D, U+8A7C, U+8A82, U+8A84, U+8A91, U+8A9A, U+8AA1, U+8AA3, U+8AA5, U+8AA8, U+8AC2, U+8ADA-8ADB, U+8ADE, U+8AE0-8AE2, U+8AE4, U+8AF1, U+8AF3, U+8B07, U+8B0C, U+8B14, U+8B1A, U+8B26, U+8B28, U+8B2B, U+8B3E, U+8B41, U+8B49, U+8B4C, U+8B4E-8B4F, U+8B56, U+8B5B, U+8B5F, U+8B6B, U+8B6F, U+8B71, U+8B74, U+8B7D, U+8B8A, U+8B8C, U+8B8E, U+8B92, U+8B96, U+8B99-8B9A, U+8C3A, U+8C3F, U+8C41, U+8C48, U+8C4C, U+8C4E, U+8C50, U+8C55, U+8C62, U+8C6B-8C6C, U+8C78, U+8C7A, U+8C7C, U+8C82, U+8C85, U+8C89-8C8A, U+8C8D-8C8E, U+8C94, U+8C98, U+8CAD-8CAE, U+8CB2-8CB3, U+8CBD, U+8CC1, U+8CCD, U+8CDA, U+8CFA-8CFB, U+8D07, U+8D0A, U+8D0D, U+8D0F-8D10, U+8D13, U+8D67, U+8D6D, U+8D71, U+8D81, U+8DBA, U+8DC2, U+8DCC, U+8DCF, U+8DD6, U+8DDA-8DDB, U+8DDF, U+8DE3, U+8DEB, U+8DFC, U+8DFF, U+8E08-8E09, U+8E10, U+8E1D-8E1F, U+8E30, U+8E34, U+8E47-8E4A, U+8E4C, U+8E50, U+8E55, U+8E59, U+8E60, U+8E63-8E64, U+8E76, U+8E7C, U+8E84, U+8E8B, U+8E94, U+8EA1, U+8EAA, U+8EAC, U+8EC5-8EC6, U+8EC8, U+8EDB, U+8EE3, U+8EFB-8EFC, U+8EFE, U+8F05, U+8F0A, U+8F0C, U+8F12-8F13, U+8F15, U+8F19, U+8F1C, U+8F1F, U+8F26, U+8F33, U+8F39, U+8F3B, U+8F3E, U+8F42, U+8F45-8F46, U+8F4C, U+8F4E, U+8F57, U+8F5C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold044.woff2") format("woff2");
  unicode-range: U+8F63-8F64, U+8F9C, U+8FA7-8FA8, U+8FAD-8FAF, U+8FB7, U+8FDA, U+8FE2, U+8FE5, U+8FEF, U+8FF4, U+8FF9-8FFA, U+9005, U+900B, U+900E, U+9011, U+9015-9016, U+9027, U+9035-9036, U+9039, U+903E, U+9049, U+904F-9052, U+9056, U+9058, U+905E, U+9068, U+906F, U+9072, U+9076, U+9080, U+9082-9083, U+908F, U+90A8, U+90AF, U+90B1, U+90B5, U+90DB, U+90E2, U+9102, U+9112, U+9130, U+9132, U+914A, U+9156, U+9158, U+9163, U+9165, U+9169, U+9172-9173, U+9182, U+918B, U+91A2, U+91AA-91AB, U+91AF, U+91B4-91B5, U+91BA, U+91C1, U+91CB, U+91D0, U+91D6, U+91DB, U+91DF, U+91E1, U+91F5-91F6, U+91FC, U+91FF, U+9211, U+9214-9215, U+921E, U+9229, U+922C, U+923F, U+9245, U+924B, U+9250, U+9257, U+925A, U+925E, U+9293, U+9295-9296, U+92B7, U+92B9, U+92E9, U+92FA, U+9319-931A, U+9322-9323, U+9335, U+933A-933B, U+9344, U+9356, U+935C, U+9360, U+936E, U+9394, U+93AC, U+93B0, U+93B9, U+93C3, U+93C8, U+93D0, U+93D6-93D8, U+93DD, U+93E4-93E5, U+93E8, U+9403, U+9407, U+9410, U+9413-9414, U+941A, U+9421, U+942B, U+9436, U+943A, U+9441, U+9452, U+945A-945B, U+945E, U+9460, U+9462, U+946A, U+9470, U+9475, U+9477, U+947C, U+947E, U+9481, U+9582, U+9587, U+958A, U+9594, U+9596, U+9598-9599, U+95A0, U+95A7, U+95AD, U+95B9, U+95BC, U+95BE, U+95C3, U+95CC-95CD, U+95D4-95D6, U+95DC, U+95E1-95E2, U+95E5, U+9621, U+9628, U+962E-962F, U+9642, U+964B-964C, U+964F, U+965C-965D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold045.woff2") format("woff2");
  unicode-range: U+2FAA-2FAB, U+2FB1-2FB2, U+2FBD-2FC0, U+965E-965F, U+9666, U+966C, U+9672, U+968D, U+9695, U+9697-9698, U+96A7-96A8, U+96B0-96B2, U+96B4, U+96B6, U+96B8-96B9, U+96C9, U+96CB, U+96CE, U+96D5-96D6, U+96D9, U+96F9, U+9704, U+9706, U+9708, U+970D-970F, U+9711, U+9713, U+9719, U+9724, U+972A, U+9730, U+9738-9739, U+973D-973E, U+9742, U+9746, U+9748-9749, U+9760, U+9764, U+9766, U+9768, U+976B, U+9771, U+9779-977A, U+977C, U+9781, U+9785-9786, U+978F-9790, U+979C, U+97A3, U+97A6, U+97A8, U+97AB, U+97B3-97B4, U+97C3, U+97C6, U+97C8, U+97CB, U+97DC, U+97ED, U+97F2, U+97F5-97F6, U+980F, U+9821, U+9846, U+984B, U+984F, U+9871, U+9873-9874, U+98AA, U+98B1, U+98B6, U+98C3, U+98C6, U+98E9, U+98EB, U+98ED-98EE, U+9912, U+9914, U+9918, U+991D, U+9920-9921, U+9924, U+992C, U+992E, U+993D-993E, U+9942, U+994B-994C, U+9950-9951, U+9955, U+9997, U+99A5, U+99BC, U+99D1, U+99D8, U+99DB, U+99DF, U+99E2, U+99ED-99EE, U+99F2, U+99F8, U+99FB, U+9A01, U+9A05, U+9A0F, U+9A2B, U+9A3E, U+9A40, U+9A42-9A43, U+9A45, U+9A5B, U+9A5F, U+9A62, U+9A64-9A65, U+9A69-9A6B, U+9AAD, U+9AB0, U+9ABC, U+9AC0, U+9ACF, U+9AD1, U+9AD3-9AD4, U+9ADE-9ADF, U+9AE2-9AE3, U+9AE6, U+9AEB, U+9AF1, U+9AF4, U+9AFB, U+9B06, U+9B18, U+9B1F, U+9B23, U+9B25, U+9B27, U+9B29-9B2A, U+9B2E-9B2F, U+9B32, U+9B3B, U+9B43, U+9B4D-9B4E, U+9B51, U+9B58, U+9B74, U+9B83, U+9B91, U+9B93, U+9B96-9B97, U+9B9F-9BA0, U+9BB4, U+9BB9, U+9BC0, U+9BC6, U+9BCA, U+9BCF, U+9BD1-9BD2, U+9BD4, U+9BE1-9BE3;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold046.woff2") format("woff2");
  unicode-range: U+2F01, U+2FC4, U+2FC6, U+2FCB-2FCC, U+2FD2, U+2FD4-2FD5, U+3402, U+34B5, U+34DB, U+378D, U+37E2, U+3B22, U+3BB6, U+3BC3, U+3C0F, U+3E3F, U+3F72, U+4264, U+4453, U+445B, U+459D, U+45EA, U+4844, U+49B0, U+4C17, U+4E28, U+4E2F-4E30, U+4E8D, U+4EE1, U+4EFD, U+4EFF, U+4F03, U+4F0B, U+4F48-4F49, U+4F56, U+4F5F-4F60, U+4F6A, U+4F6C, U+4F7E, U+4F8A, U+4F94, U+4F97, U+4FC9, U+5001-5002, U+500E, U+5018, U+5027, U+502E, U+503B, U+5040-5041, U+5094, U+50CC, U+50D0, U+50E6, U+50F2, U+5103, U+5106, U+510B, U+511E, U+9BE4, U+9BF0-9BF2, U+9C04, U+9C06, U+9C08-9C09, U+9C0C, U+9C12, U+9C14-9C15, U+9C1B, U+9C21, U+9C24-9C25, U+9C2E, U+9C30, U+9C32, U+9C3E, U+9C46-9C47, U+9C5A, U+9C60, U+9C67, U+9C76, U+9C78, U+9CE7, U+9CEB-9CEC, U+9CF0, U+9D03, U+9D06, U+9D08, U+9D12, U+9D15, U+9D1F, U+9D23, U+9D26, U+9D2A, U+9D3E-9D3F, U+9D41, U+9D44, U+9D46, U+9D48, U+9D50-9D51, U+9D59, U+9D5D-9D5E, U+9D64, U+9D6F, U+9D72, U+9D7A, U+9D87, U+9D89, U+9D9A, U+9DA4, U+9DA9, U+9DAB, U+9DB2, U+9DB8, U+9DBA-9DBB, U+9DC1-9DC2, U+9DC6, U+9DCF, U+9DD3, U+9DD9, U+9DE6, U+9DED, U+9DEF, U+9DF8, U+9DFD, U+9E1A-9E1B, U+9E75, U+9E79, U+9E7D, U+9E81, U+9E88, U+9E8B-9E8C, U+9E91, U+9E95, U+9E9D, U+9EA5, U+9EAA, U+9EAD, U+9EB8, U+9EBC, U+9ECC, U+9ECF-9ED0, U+9ED4, U+9EDC-9EDE, U+9EE0, U+9EE5, U+9EE8, U+9EEF, U+9EF6-9EF7, U+9EF9, U+9EFB-9EFD, U+9F07-9F08, U+9F15, U+9F21, U+9F2C, U+9F4E-9F4F, U+9F52, U+9F54, U+9F5F-9F61, U+9F63, U+9F66, U+9F6A, U+9F6C, U+9F72, U+9F76-9F77, U+9F9C-9F9D, U+9FA0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold047.woff2") format("woff2");
  unicode-range: U+2E8F, U+5135, U+514A, U+5155, U+5157, U+519D, U+51C3, U+51CA, U+51DE, U+51E2, U+51EE, U+5201, U+5213, U+5215, U+5249, U+5257, U+5261, U+5293, U+52C8, U+52CC, U+52D0, U+52D6, U+52DB, U+52F0, U+52FB, U+5300, U+5307, U+531C, U+5361, U+5363, U+5393, U+539D, U+53B2, U+5412, U+5427, U+544D, U+546B, U+5474, U+547F, U+5488, U+5496, U+549C, U+54A1, U+54A9, U+54C6, U+54FF, U+550E, U+552B, U+5535, U+5550, U+5581, U+5586, U+558E, U+55AD, U+55CE, U+5608, U+560E, U+563B, U+5649, U+5666, U+566F, U+5671-5672, U+5676, U+569E, U+56A9, U+56AC, U+56B3, U+56C9, U+570A, U+5721, U+572F, U+5733-5734, U+5770, U+5777, U+577C, U+579C, U+57B8, U+57C7-57C8, U+57CF, U+57E4, U+57ED, U+57F5-57F6, U+57FF, U+5809, U+5864, U+5889, U+58A9, U+58CE, U+58D2, U+58D4, U+58DA, U+58E0, U+58E9, U+590C, U+595D, U+596D, U+598B, U+5992, U+59A4, U+59C3, U+59D2, U+59DD, U+59F8, U+5A13, U+5A23, U+5A67, U+5A6D, U+5A77, U+5A7E, U+5A84, U+5A9E, U+5AA7, U+5AC4, U+5B19, U+5B25, U+5B41, U+5B56, U+5B7D, U+5B93, U+5BD8, U+5C12, U+5C1E, U+5C23, U+5C2B, U+5C62, U+5C7A, U+5C8F, U+5C9F, U+5CA3, U+5CAA, U+5CBA, U+5CCB, U+5CD0, U+5CD2, U+5CF4, U+5D0D, U+5D27, U+5D46-5D47, U+5D4A, U+5D53, U+5D6D, U+5D81, U+5DA0, U+5DA4, U+5DA7, U+5DB8, U+5DCB, U+5E14, U+5E18, U+5E58, U+5E5E, U+5E77, U+5EBE, U+5ECB, U+5EF9, U+5F00, U+5F02, U+5F07, U+5F1D, U+5F23, U+5F34, U+5F36, U+5F3D, U+5F40, U+5F54, U+5F58, U+5F64, U+5F67, U+5F7D, U+5F89, U+5F9C, U+5FA7, U+5FAF, U+5FC9, U+5FDE, U+5FE1, U+5FE9, U+600D, U+6014, U+6018;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold048.woff2") format("woff2");
  unicode-range: U+6033, U+6035, U+6047, U+609D-609E, U+60CB, U+60D4-60D5, U+60DD, U+60F8, U+611C, U+612B, U+6130, U+6137, U+618D, U+61B9, U+61BC, U+6222, U+623E, U+6243, U+6256, U+625A, U+626F, U+6285, U+62C4, U+62D6, U+62FC, U+630A, U+6318, U+6339, U+6343, U+6365, U+637C, U+63E5, U+63F5, U+6410, U+6422, U+6460, U+646D, U+6479, U+64BE-64BF, U+64C4, U+64CE, U+64D0, U+64F7, U+64FB, U+6522, U+6529, U+6567, U+659D, U+6600, U+6609, U+6615, U+661E, U+6622, U+6624, U+662B, U+6630-6631, U+6633, U+663A, U+6648, U+664C, U+6659, U+6661, U+6665, U+6673, U+6677-6678, U+668D, U+66A0, U+66B2, U+66BB, U+66C8, U+66DB, U+66E8, U+66FA-66FB, U+6713, U+6733, U+6747-6748, U+6766, U+677B, U+6781, U+6793, U+6798, U+679B, U+67BB, U+67C0, U+67D7, U+67F9, U+67FC, U+6801, U+681D, U+682C, U+6831, U+6852, U+685B, U+6872, U+6875, U+68A3, U+68A5, U+68B2, U+68C8, U+68D0, U+68E8, U+68ED, U+68F0-68F1, U+68FC, U+690A, U+6935, U+6942, U+6949, U+6957, U+6963-6964, U+6968, U+6980, U+69A5, U+69AD, U+69CF, U+69E2, U+69E9-69EA, U+69F5-69F6, U+6A0F, U+6A15, U+6A3B, U+6A3E, U+6A45, U+6A50, U+6A56, U+6A5B, U+6A73, U+6A89, U+6A94, U+6A9D-6A9E, U+6AA5, U+6AE4, U+6AE7, U+6B1B, U+6B1E, U+6B2C, U+6B35, U+6B46, U+6B56, U+6B60, U+6B67, U+6B82, U+6BA9, U+6BAD, U+6BD6-6BD7, U+6BFF, U+6C05, U+6C10, U+6C33, U+6C59, U+6C5C, U+6C74, U+6C76, U+6C85-6C86, U+6C98, U+6C9C, U+6CAA, U+6CC6, U+6CD4, U+6CE0, U+6CEB, U+6CEE, U+6CFB, U+6D04, U+6D0E, U+6D2E, U+6D31, U+6D39, U+6D3F, U+6D58, U+6D65, U+6D82, U+6D87, U+6D94, U+6DAA;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold049.woff2") format("woff2");
  unicode-range: U+2F67, U+6DAC, U+6DBF, U+6DC4, U+6DD6, U+6DDB, U+6DDD, U+6DFC, U+6E44, U+6E5C, U+6E5E, U+6EB1, U+6EC1, U+6EC7, U+6ECE, U+6F10, U+6F1A, U+6F2A, U+6F2F, U+6F33, U+6F51, U+6F59, U+6F5E, U+6F61-6F62, U+6F7E, U+6F88, U+6F8C-6F8D, U+6F94, U+6FA0, U+6FA7, U+6FB6, U+6FBC, U+6FC7, U+6FCA, U+6FF0, U+6FF5, U+6FF9, U+7005, U+704A, U+704E, U+705D-705E, U+7064, U+7075, U+7085, U+70A4, U+70AB, U+70B7, U+70D4, U+70D8, U+70E4, U+710F, U+711E, U+7120, U+712B, U+712E, U+7146-7147, U+7151-7152, U+715C, U+7160, U+7168, U+7185, U+7187, U+7192, U+71BA, U+71C1, U+71C4, U+71FE, U+7200, U+7215, U+7255-7256, U+728D, U+729B, U+72BE, U+72FB, U+7327-7328, U+7350, U+7366, U+737C, U+7395, U+739F-73A0, U+73A2, U+73A6, U+73AB, U+73C9, U+73CF, U+73D6, U+73D9, U+73E3, U+73E9, U+7407, U+740A, U+741A-741B, U+7426, U+7428, U+742A-742C, U+742E-7430, U+7444, U+7446-7447, U+744B, U+7457, U+7462, U+746B, U+746D, U+7486-7487, U+7489, U+7490, U+7498, U+749C, U+749F, U+74A3, U+74A6, U+74A8-74A9, U+74B5, U+74BF, U+74C8-74C9, U+74FF, U+7501, U+7517, U+752F, U+756F, U+7579, U+7592, U+75CE, U+75E4, U+7600, U+7602, U+7608, U+7615-7616, U+7619, U+761E, U+762D, U+7635, U+7643, U+764B, U+7664-7665, U+766D, U+766F, U+7671, U+7681, U+769B, U+769D-769E, U+76A6, U+76AA, U+76B6, U+76C5, U+76CC, U+76CE, U+76D4, U+76E6, U+76F1, U+76FC, U+770A, U+7719, U+7734, U+7736, U+7746, U+774D-774E, U+775C, U+775F, U+7762, U+777A, U+7780, U+7794, U+77AA, U+77E0, U+782D, U+7843, U+784E-784F, U+7851, U+7868;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold050.woff2") format("woff2");
  unicode-range: U+2F71, U+786E, U+78AD, U+78B0, U+78E4, U+78F2, U+78F7, U+7900, U+791C, U+792E, U+7931, U+7934, U+7945-7946, U+795C, U+7979, U+7998, U+79B8, U+79C8, U+79CA, U+79D4, U+79DE, U+79EB, U+79ED, U+7A03, U+7A39, U+7A5D, U+7A6D, U+7A85, U+7AA0, U+7AB3, U+7ABB, U+7ACE, U+7AEB, U+7AFD, U+7B12, U+7B2D, U+7B3B, U+7B47, U+7B4E, U+7B60, U+7B6D, U+7B6F, U+7B9E, U+7BD7, U+7BD9, U+7C01, U+7C20, U+7C31, U+7C33, U+7C36, U+7C59, U+7C6D, U+7C79, U+7C8F, U+7C94, U+7CA0, U+7CBC, U+7CD5, U+7CD9, U+7CDD, U+7D07-7D08, U+7D13, U+7D1D, U+7D23, U+7D31, U+7D41, U+7D48, U+7D53, U+7D5C, U+7D7A, U+7D83, U+7D8B, U+7DA6, U+7DC2, U+7DCC, U+7E08, U+7E11, U+7E15, U+7E28, U+7E47, U+7E52, U+7E8A, U+7E8D, U+7F47, U+7F91, U+7F97, U+7FBF, U+7FCE, U+7FDB, U+7FDF, U+7FEC, U+7FEE, U+7FFA, U+8014, U+8026, U+8035, U+8037, U+803C, U+80CA, U+80D7, U+80E0, U+80F3, U+8118, U+814A, U+8160, U+8167-8168, U+816D, U+81BB, U+81CA, U+81CF, U+81D7, U+8260, U+8274, U+828E, U+82A1, U+82A3-82A4, U+82A9, U+82AE, U+82B7, U+82BE-82BF, U+82C6, U+82D5, U+82FD-82FE, U+8300-8301, U+8322, U+832D, U+833A, U+8343, U+8347, U+8351, U+8355, U+8362, U+837D, U+8386, U+8392, U+8398, U+83A7, U+83A9, U+83BF-83C0, U+83C7, U+83CF, U+83D1, U+83E1, U+83EA, U+8401, U+8406, U+8448, U+845F, U+8470, U+8473, U+8485, U+849E, U+84AF, U+84B4, U+84BA, U+84C0, U+84C2, U+851E, U+852F, U+8532, U+8559, U+8564, U+857A, U+858C, U+858F, U+85A2, U+85AD, U+85CB, U+85CE, U+85ED, U+85FF, U+8604-8605, U+8610, U+8612, U+8618, U+8629, U+8638, U+8641, U+8657, U+8662;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold051.woff2") format("woff2");
  unicode-range: U+2FA1, U+866C, U+8675, U+8698, U+86B8, U+86FA, U+86FC-86FD, U+870B, U+8771, U+8787-8788, U+87AC-87AD, U+87B5, U+87D6, U+8806, U+880A, U+8810, U+8814, U+8898, U+88AA, U+88CA, U+88CE, U+88F5, U+8918-891A, U+891C, U+8927, U+8930, U+8932, U+8939, U+8940, U+8994, U+89D4, U+89E5, U+89F6, U+8A12, U+8A15, U+8A22, U+8A37, U+8A47, U+8A4E, U+8A5D, U+8A61, U+8A75, U+8A79, U+8AA7, U+8AD0, U+8ADF, U+8AF4, U+8AF6, U+8B46, U+8B54, U+8B59, U+8B69, U+8B9D, U+8C49, U+8C68, U+8CE1, U+8CF8, U+8CFE, U+8D12, U+8D1B, U+8DAF, U+8DCE, U+8DD1, U+8DD7, U+8E20, U+8E23, U+8E3D, U+8E70, U+8E7B, U+8EFA, U+8F1E, U+8F2D, U+8F36, U+8F54, U+8FA6, U+8FB5, U+8FE4, U+8FE8, U+8FEE, U+9008, U+902D, U+9088, U+9095, U+9097, U+9099, U+909B, U+90A2, U+90B3, U+90BE, U+90C4-90C5, U+90C7, U+90D7, U+90DD, U+90EF, U+90F4, U+9114-9116, U+9122-9123, U+912F, U+9131, U+9134, U+913D, U+9148, U+915B, U+9183, U+919E, U+91B1, U+91BC, U+91D7, U+91E4-91E5, U+91ED, U+91F1, U+91FB, U+9207, U+9210, U+9238-923A, U+923C, U+9240, U+9243, U+924F, U+9278, U+9288, U+92C2, U+92CB-92CC, U+92D3, U+92E0, U+92FF, U+931F, U+9321, U+9325, U+9348-9349, U+9364-9365, U+936A, U+9370, U+939B, U+93A3, U+93BA, U+93C6, U+93DE-93DF, U+93FD, U+9404, U+9433, U+944A, U+9463, U+946B, U+9471-9472, U+958E, U+959F, U+95A6, U+95A9, U+95AC, U+95B6, U+95BD, U+95CB, U+95D0, U+95D3, U+95DA, U+95DE, U+9658, U+9684, U+969D, U+96A4-96A5, U+96D2, U+96DE, U+96E9, U+96EF, U+9733, U+973B, U+974D-974F, U+975A, U+976E, U+9773, U+9795;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold052.woff2") format("woff2");
  unicode-range: U+2E97, U+3406, U+342C, U+342E, U+3468, U+346A, U+3492, U+34BC, U+34C1, U+34C7, U+351F, U+355D-355E, U+3563, U+356E, U+35A6, U+35A8, U+35C5, U+35DA, U+35F4, U+3605, U+364A, U+3691, U+3696, U+3699, U+36CF, U+3761-3762, U+376B-376C, U+3775, U+37C1, U+37E8, U+37F4, U+37FD, U+3800, U+382F, U+3836, U+3840, U+385C, U+3861, U+38FA, U+3917, U+391A, U+396F, U+3A6E, U+3A73, U+3AD6-3AD7, U+3AEA, U+3B0E, U+3B1A, U+3B1C, U+3B6D, U+3B77, U+3B87-3B88, U+3B8D, U+3BA4, U+3BCD, U+3BF0, U+97AE, U+97BA, U+97C1, U+97C9, U+97DB, U+97DE, U+97F4, U+980A, U+981E, U+982B, U+9852-9853, U+9856-9857, U+9859, U+9865, U+986C, U+98BA, U+98C8, U+98E7, U+9958, U+999E, U+9A02-9A03, U+9A24, U+9A2D-9A2E, U+9A38, U+9A4A, U+9A4E, U+9AB6, U+9AC1, U+9AC3, U+9ACE, U+9AD6, U+9AF9, U+9B02, U+9B08, U+9B20, U+9B2D, U+9B5E, U+9B66, U+9B72, U+9B75, U+9B79, U+9B84, U+9B8A, U+9B8F, U+9B9E, U+9BA7, U+9BC1, U+9BCE, U+9BE5, U+9BF8, U+9BFD, U+9C00, U+9C23, U+9C41, U+9C4F-9C50, U+9C53, U+9C63, U+9C65, U+9C77, U+9D1D-9D1E, U+9D43, U+9D47, U+9D52, U+9D63, U+9D70, U+9D7C, U+9D8A, U+9D96, U+9DAC, U+9DBC, U+9DC0, U+9DE7, U+9E07, U+9E15, U+9E9E, U+9EA4, U+9EAC, U+9EAF, U+9F10, U+9F39, U+9F57, U+9F94, U+9F97, U+9FA2, U+F970, U+F9DC, U+FA0F-FA10, U+FA14-FA15, U+FA32, U+FA36, U+FA39, U+FA3C, U+FA3E, U+FA42, U+FA60, U+FA6A, U+2000B, U+2123D, U+2131B, U+2146E, U+216B4, U+218BD, U+21E34, U+231C4, U+235C4, U+2373F, U+23763, U+23CFE, U+247F1, U+2548E, U+2550E, U+25771, U+259C4, U+25DA1, U+26AFF, U+26E40, U+270F4, U+27684, U+28277, U+283CD, U+2A190;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold053.woff2") format("woff2");
  unicode-range: U+2E83, U+2E85, U+2EA6, U+3C26, U+3CC3, U+3CD2, U+3D11, U+3D1E, U+3D64, U+3D9A, U+3DC0, U+3DD4, U+3E05, U+3E60, U+3E66, U+3E68, U+3E83, U+3E94, U+3F57, U+3F75, U+3F77, U+3FAE, U+3FC9, U+3FD7, U+4039, U+4058, U+4093, U+4105, U+4148, U+414F, U+4163, U+41B4, U+41BF, U+41E6, U+41EE, U+41F3, U+4207, U+420E, U+42C6, U+42D6, U+42DD, U+4302, U+432B, U+4343, U+43EE, U+43F0, U+4408, U+4417, U+441C, U+4422, U+4476, U+447A, U+4491, U+44B3, U+44BE, U+44D4, U+4508, U+450D, U+4525, U+4543, U+45B8, U+45E5, U+460F, U+4641, U+4665, U+46A1, U+46AF, U+470C, U+4764, U+47FD, U+4816, U+484E, U+48B5, U+49E7, U+49FA, U+4A04, U+4A29, U+4ABC, U+4B3B, U+4BC2, U+4BCA, U+4BD2, U+4BE8, U+4C20, U+4CC4, U+4CD1, U+4D07, U+4D77, U+4E02, U+4E0F, U+4E12, U+4E29, U+4E2B-4E2C, U+4E2E, U+4E40, U+4E47-4E48, U+4E51, U+4E5A, U+4E69, U+4E9D, U+4EB9, U+4EBB-4EBC, U+4EC3, U+4EC8, U+4ED0, U+4EDA, U+4EEB, U+4EF1, U+4EF5, U+4F00, U+4F16, U+4F37, U+4F3E, U+4F54, U+4F58, U+4F64, U+4F77-4F78, U+4F7A, U+4F7D, U+4F82, U+4F85, U+4F92, U+4F9A, U+4FB2, U+4FBE, U+4FC5, U+4FCB, U+4FCF, U+4FD2, U+4FE6, U+4FF2, U+5000, U+5010, U+5013, U+501C, U+501E, U+5022, U+5042, U+5046, U+504E, U+5053, U+5057, U+5063, U+5066, U+506A, U+5070, U+5088, U+5092-5093, U+5095-5096, U+509C, U+50A3, U+50AA, U+50B1, U+50BA-50BB, U+50C4, U+50C7, U+50CE, U+50D4, U+50D9, U+50E1, U+50E9, U+50F3, U+5108, U+5117, U+511B, U+5160, U+5173, U+517B, U+5183, U+518B, U+5198, U+51A3, U+51AD, U+51BC, U+51F3-51F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold054.woff2") format("woff2");
  unicode-range: U+2E89, U+5202, U+5212, U+5216, U+5255, U+525C, U+526C, U+5277, U+5282, U+5284, U+5298, U+52A4, U+52A6, U+52AF, U+52BA-52BB, U+52CA, U+52D1, U+52F7, U+530A-530B, U+5324, U+5335, U+533E, U+5342, U+5367, U+536C, U+537A, U+53A4, U+53B4, U+53B7, U+53C0, U+53D5, U+53DA, U+53F4-53F5, U+5424, U+5428, U+5443, U+5455, U+5462, U+5466, U+546C, U+548A, U+548D, U+5495, U+54A0, U+54A6, U+54AD-54AE, U+54B7, U+54BA, U+54BF, U+54C3, U+54EC, U+54EF, U+54F1, U+54F3, U+5500-5501, U+5509, U+553C, U+5541, U+5547, U+554A, U+5560-5561, U+5564, U+557D, U+5582, U+5588, U+5591, U+55BF, U+55C9, U+55CC, U+55D1-55D2, U+55DD, U+55E2, U+55E9, U+5607, U+5610, U+5628, U+5630, U+5637, U+563D, U+563F-5640, U+5647, U+565E, U+5660, U+566D, U+5688, U+568C, U+5695, U+569A, U+569D, U+56A8, U+56AD, U+56B2, U+56C5, U+56CD, U+56DF, U+56E8, U+56F6-56F7, U+5715, U+5723, U+5729, U+5745-5746, U+574C-574D, U+5768, U+576F, U+5773-5775, U+577B, U+579A, U+579D-579E, U+57A8, U+57AC, U+57CC, U+57D7, U+57DE, U+57E6, U+57F0, U+57F8, U+57FB, U+57FD, U+5804, U+581E, U+5820, U+5827, U+5832, U+5839, U+5849, U+584C, U+5867, U+588A-588B, U+588D, U+588F-5890, U+5894, U+589D, U+58AA, U+58B1, U+58C3, U+58CD, U+58E2, U+58F3-58F4, U+5905-5906, U+590B, U+590D, U+5914, U+5924, U+593D, U+5946, U+595B, U+595F, U+5975-5976, U+599F, U+59AE, U+59BC, U+59C8, U+59CD, U+59DE, U+59E3-59E4, U+59E7, U+59EE, U+5A0C-5A0D, U+5A17, U+5A27, U+5A2D, U+5A55, U+5A65, U+5A7A, U+5A8B, U+5A9C, U+5A9F-5AA0, U+5AA2, U+5AB1, U+5AB3, U+5AB5, U+5ABA, U+5ABF, U+5ADA, U+5ADC, U+5AE0, U+5AE5, U+5AEE;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold055.woff2") format("woff2");
  unicode-range: U+2E96, U+2E98, U+5AF0, U+5AF5, U+5B00, U+5B08, U+5B17, U+5B2D, U+5B34, U+5B4C, U+5B52, U+5B68, U+5B6F, U+5B7C, U+5B7F, U+5B81, U+5B84, U+5B96, U+5BAC, U+5BC0, U+5BCE, U+5BD6, U+5BF1, U+5BFD, U+5C03, U+5C29, U+5C30, U+5C5F, U+5C63, U+5C67-5C69, U+5C70, U+5C7C, U+5C88, U+5C8A, U+5CA0, U+5CA2, U+5CA6-5CA7, U+5CAD, U+5CB5, U+5CC9, U+5D06, U+5D10, U+5D1D, U+5D20, U+5D24, U+5D26, U+5D2B, U+5D31, U+5D39, U+5D42, U+5D61, U+5D6A, U+5D70, U+5D88, U+5D92, U+5D94, U+5D97, U+5D99, U+5DB0, U+5DB2, U+5DB4, U+5DB9, U+5DD1, U+5DD7-5DD8, U+5DE0, U+5DE4, U+5DE9, U+5E00, U+5E12, U+5E15, U+5E1F, U+5E2E, U+5E3E, U+5E49, U+5E56, U+5E6B-5E6E, U+5EA5, U+5EAA, U+5EAC, U+5EB9, U+5EBF, U+5EC6, U+5ED2, U+5ED9, U+5EFD, U+5F08, U+5F0E, U+5F1C, U+5F1E, U+5F47, U+5F63, U+5F72, U+5F7E, U+5F8F, U+5FA2, U+5FA4, U+5FB8, U+5FC4, U+5FC7, U+5FCB, U+5FD2-5FD4, U+5FE2, U+5FEE-5FEF, U+5FF3, U+5FFC, U+6017, U+6022, U+6024, U+604C, U+607F, U+608A, U+6095, U+60A8, U+60B0-60B1, U+60BE, U+60C8, U+60D9, U+60DB, U+60EE, U+60F2, U+60F5, U+6110, U+6112-6113, U+6119, U+611E, U+613A, U+6141, U+6146, U+6160, U+617C, U+6192-6193, U+6197-6198, U+61A5, U+61A8, U+61AD, U+61D5, U+61DD, U+61DF, U+61F5, U+6215, U+6223, U+6229, U+6246, U+624C, U+6251-6252, U+6261, U+6264, U+626D, U+6273, U+627B, U+6299, U+62A6, U+62D5, U+62FD, U+6303, U+630D, U+6310, U+6332, U+6335, U+633B-633C, U+6341, U+6344, U+634E, U+6359, U+636C, U+6384;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold056.woff2") format("woff2");
  unicode-range: U+6394, U+6399, U+63BD, U+63D4-63D5, U+63DC, U+63E0, U+63EB-63EC, U+63F2, U+63F7, U+6409, U+641E, U+6425, U+6429, U+642F, U+645A-645B, U+645D, U+6473, U+647D, U+6487, U+6491, U+649D, U+649F, U+64CB-64CC, U+64D5, U+64D7, U+64E4-64E5, U+64FF, U+6504, U+650F, U+6514, U+6516, U+651E, U+6532, U+6544, U+6554, U+656B, U+657A, U+6581, U+6584-6585, U+658A, U+65B2, U+65B5, U+65B8, U+65BF, U+65C2, U+65C9, U+65D4, U+65F2, U+65F9, U+65FC, U+6604, U+6608, U+6621, U+662A, U+6645, U+664E, U+6651, U+6657, U+665B, U+6663, U+666A-666D, U+667B, U+6680, U+6690, U+6692, U+6699, U+66AD, U+66B1, U+66B5, U+66BF, U+66EC, U+6701, U+6705, U+6712, U+6719, U+674C-674D, U+6754, U+675D, U+6774, U+6776, U+6792, U+67B0, U+67B2, U+67C3, U+67C8, U+67D2, U+67D9, U+67DB, U+67F0, U+67F7, U+6810, U+6818, U+681F, U+682D, U+6833, U+683B, U+683E, U+6844-6845, U+6849, U+684C, U+6855, U+6857, U+686B, U+686E, U+687A, U+687C, U+6882, U+6890, U+6896, U+6898-689A, U+689C, U+68AA-68AB, U+68B4, U+68BB, U+68C3, U+68C5, U+68CC, U+68CF, U+68D6, U+68D9, U+68E4-68E5, U+68EC, U+68F7, U+68FB, U+6903, U+6907, U+693B, U+6946, U+6969, U+696C, U+6972, U+697A, U+697F, U+6992, U+6996, U+6998, U+69A6, U+69B0, U+69B7, U+69BA, U+69BC, U+69C0, U+69D1, U+69D6, U+69E3, U+69EE-69EF, U+69F3-69F4, U+69FE, U+6A11, U+6A1A, U+6A1D, U+6A30, U+6A32-6A34, U+6A3F, U+6A46, U+6A49, U+6A4E, U+6A52, U+6A64, U+6A7A, U+6A7E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold057.woff2") format("woff2");
  unicode-range: U+2E9E, U+2EA1, U+2EA3, U+6A83, U+6A8B, U+6A91, U+6A9F, U+6AA1, U+6AAB, U+6ABD, U+6AC6, U+6AD0, U+6AD4, U+6ADC-6ADD, U+6AEC, U+6AF1-6AF3, U+6AFD, U+6B0B, U+6B0F-6B11, U+6B17, U+6B2F, U+6B4A, U+6B58, U+6B6C, U+6B75, U+6B7A, U+6B81, U+6B9B, U+6BAE, U+6BBD-6BBE, U+6BC7-6BC9, U+6BDA, U+6BE6-6BE7, U+6BEE, U+6BF1, U+6C02, U+6C0A, U+6C0E, U+6C35-6C36, U+6C3A, U+6C3F, U+6C4D, U+6C5B, U+6C67, U+6C6D, U+6C84, U+6C89, U+6C94-6C95, U+6C97, U+6CAD, U+6CC2, U+6CD0, U+6CD6, U+6CDA, U+6CDC, U+6CE9, U+6CEC-6CED, U+6D00, U+6D0A, U+6D24, U+6D26-6D27, U+6D2F, U+6D34, U+6D3C, U+6D5B, U+6D5E, U+6D60, U+6D70, U+6D80-6D81, U+6D8A, U+6D8D, U+6D91, U+6D98, U+6DAB, U+6DAE, U+6DB4, U+6DC2, U+6DC8, U+6DCE-6DD0, U+6DDF, U+6DE9, U+6DF6, U+6E17, U+6E1E, U+6E22, U+6E27, U+6E32, U+6E36, U+6E3C, U+6E48-6E49, U+6E4B-6E4C, U+6E4F, U+6E51, U+6E53-6E54, U+6E57, U+6E63, U+6E93, U+6EA7, U+6EB4, U+6EBF, U+6EC3, U+6ECA, U+6ED9, U+6EEB, U+6EF9, U+6EFB, U+6F0A, U+6F0C, U+6F18, U+6F25, U+6F35-6F36, U+6F3C, U+6F52, U+6F57, U+6F5A, U+6F60, U+6F68, U+6F7D, U+6F90, U+6F96, U+6F98, U+6F9F, U+6FA5, U+6FAF, U+6FB5, U+6FBE, U+6FC8-6FC9, U+6FDA, U+6FDE, U+6FE9, U+6FFC, U+7000, U+7007, U+700A, U+7023, U+7039-703A, U+703C, U+7043, U+7047, U+704B, U+7054, U+7065, U+7069, U+706C, U+706E, U+7076, U+707E, U+7081, U+7086, U+7095, U+7097, U+709F, U+70B1, U+70BB, U+70CA, U+70D1, U+70D3, U+70DC, U+70EC, U+7103-7104;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold058.woff2") format("woff2");
  unicode-range: U+2EA8, U+7106-7108, U+710C, U+712F, U+7131, U+714A, U+7150, U+7153, U+715E, U+7180, U+7196, U+719B, U+71A0, U+71A2, U+71AE-71AF, U+71B3, U+71CB, U+71D3, U+71D9, U+71DC, U+7207, U+722B, U+7234, U+7238-7239, U+7242, U+7253, U+7257, U+7263, U+726E-726F, U+7278, U+727F, U+728E, U+72AD-72AE, U+72B0-72B1, U+72C1, U+72CC, U+72F3, U+72FA, U+7307, U+7312, U+7318-7319, U+732C, U+7331, U+7333, U+7339, U+733D, U+7352, U+736B-736C, U+736E-736F, U+7371, U+7377, U+7381, U+7385, U+738A, U+7394, U+7398, U+739C, U+739E, U+73A5, U+73A8, U+73B5, U+73B7, U+73B9, U+73BC, U+73BF, U+73C5, U+73CB, U+73E1, U+73E7, U+73F9-73FA, U+7401, U+7413, U+7424, U+7431, U+7439, U+7440, U+7443, U+744D, U+7452-7453, U+745D, U+7471, U+7481, U+7485, U+7488, U+7492, U+7497, U+7499, U+74A0-74A1, U+74A5, U+74AA-74AB, U+74B9-74BB, U+74D6, U+74D8, U+74DE, U+74EB, U+74EF, U+74FA, U+7520, U+7524, U+752A, U+753D-753E, U+7540, U+7548, U+754E, U+7550, U+7552, U+756C, U+7571-7572, U+757A, U+757D-757E, U+7581, U+758C, U+75A2, U+75B0, U+75B7, U+75BF-75C0, U+75C6, U+75CF, U+75D3, U+75DD, U+75DF-75E0, U+75E7, U+75EC, U+75EE, U+75F1, U+75F9, U+7603, U+7607, U+760F, U+7613, U+7618, U+761B-761C, U+7625, U+7628, U+7633, U+763C, U+7641, U+7649, U+7655, U+766E, U+7695, U+769C, U+76A0-76A1, U+76A7-76A8, U+76AF, U+76C9, U+76E8, U+76EC, U+7717, U+771A, U+772D, U+7735, U+7758, U+7760, U+776A, U+7772, U+777C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold059.woff2") format("woff2");
  unicode-range: U+2EAD, U+777D, U+779A, U+779F, U+77A2, U+77A4, U+77A9, U+77DE-77DF, U+77E4, U+77E6, U+77EA, U+77EC, U+77F0, U+77F4, U+77FB, U+7805-7806, U+7809, U+780D, U+7819, U+7821, U+782C, U+7847, U+7864, U+786A, U+788A, U+7894, U+789D-789F, U+78A4, U+78BB, U+78C8, U+78CC, U+78CE, U+78D5, U+78E0-78E1, U+78E6, U+78F9-78FB, U+78FE, U+7910, U+791B, U+7925, U+7930, U+793B, U+794A, U+7958, U+795B, U+7967, U+7972, U+7994-7996, U+799B, U+79A1, U+79A9, U+79B4, U+79BB, U+79C2, U+79C7, U+79CC-79CD, U+79D6, U+7A0A, U+7A11, U+7A15, U+7A1B, U+7A1E, U+7A2D, U+7A38, U+7A47, U+7A4C, U+7A56, U+7A59, U+7A5C, U+7A5F-7A60, U+7A67, U+7A6A, U+7A75, U+7A78, U+7A82, U+7A8A, U+7A90, U+7AA3, U+7AAC, U+7AB9, U+7ABC, U+7ABE, U+7ACC, U+7AD1, U+7AE7-7AE8, U+7AF4, U+7B07, U+7B27, U+7B2A, U+7B2E-7B2F, U+7B31, U+7B3D, U+7B41, U+7B55, U+7B64, U+7B66, U+7B69, U+7B73, U+7B79, U+7B7F, U+7B90-7B91, U+7B9B, U+7BAF, U+7BB5, U+7BBC, U+7BC5, U+7BCA, U+7BD4, U+7BD6, U+7BDA, U+7BEA, U+7BF0, U+7C03, U+7C0B, U+7C0E-7C0F, U+7C26, U+7C45, U+7C4A, U+7C51, U+7C57, U+7C5E, U+7C61, U+7C69, U+7C6E-7C70, U+7CA6, U+7CB6-7CB7, U+7CBF, U+7CC4, U+7CC8, U+7CCD, U+7CD7, U+7CE6, U+7CEB, U+7CF5, U+7D03, U+7D09, U+7D12, U+7D1E, U+7D3D-7D3E, U+7D40, U+7D47, U+7D59-7D5A, U+7D6A, U+7D70, U+7D7F, U+7D86, U+7D88, U+7D8C, U+7D97, U+7D9D, U+7DA7, U+7DAA, U+7DB6-7DB7, U+7DC0, U+7DD7, U+7DD9, U+7DE6, U+7DF1, U+7DF9, U+7E10, U+7E17, U+7E1D, U+7E27, U+7E2C, U+7E45, U+7E73, U+7E75, U+7E7E, U+7E86-7E87, U+7E91, U+7E98, U+7E9A, U+7F3B-7F3C, U+7F3E, U+7F43-7F44, U+7F4F;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold060.woff2") format("woff2");
  unicode-range: U+2EAB, U+2EB2, U+2EB9, U+2EBE, U+7F52, U+7F61, U+7F63-7F64, U+7F6D, U+7F7D-7F7E, U+7F90, U+7F96, U+7F9C, U+7FAD, U+7FC3, U+7FCF, U+7FE3, U+7FE5, U+7FEF, U+7FF2, U+8002, U+8008, U+800A, U+800E, U+8011, U+8016, U+8024, U+802C, U+8030, U+8043, U+8066, U+8071, U+8075, U+807B, U+8099, U+809C, U+80A4, U+80A7, U+80B8, U+80C5, U+80D5, U+80D8, U+80E6, U+80F5, U+80FB, U+810D, U+8116, U+811E, U+8124, U+8127, U+812C, U+8135, U+813D, U+8169, U+8181, U+8184-8185, U+8198, U+81B2, U+81C1, U+81C3, U+81D6, U+81DB, U+81E4, U+81EC, U+81FD, U+81FF, U+8204, U+8219, U+8221-8222, U+8232, U+8234, U+823C, U+8245-8246, U+8249, U+824B, U+824F, U+8257, U+825C, U+8263, U+8279, U+827D, U+827F, U+8283, U+828A, U+8293, U+82A7-82A8, U+82B2, U+82B4, U+82BA, U+82BC, U+82E2, U+82E8, U+82F7, U+8307-8308, U+830C, U+831B, U+831D, U+8330, U+833C, U+8344, U+8354, U+8357, U+8363, U+837F, U+838D, U+8394-8395, U+839B, U+839D, U+83C9, U+83D0, U+83D4, U+83DD, U+83E5, U+83F9, U+840F, U+8411, U+8415, U+8417, U+8439, U+844A, U+844F, U+8451-8452, U+8459-845A, U+845C, U+8465, U+8476, U+8478, U+847C, U+8481, U+8497, U+84A6, U+84BE, U+84CE-84CF, U+84D3, U+84DC, U+84E7, U+84EA, U+84EF-84F1, U+84FA, U+84FD, U+850C, U+851B, U+8524-8525, U+852B, U+8534, U+853E, U+854F, U+8551, U+8553, U+855E, U+8561-8562, U+856F, U+857B, U+857D, U+857F, U+8581, U+8586, U+8593, U+859D, U+859F, U+85B7, U+85BC, U+85C7, U+85CA, U+85D8-85D9, U+85DF, U+85E1, U+85E6, U+85F6;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold061.woff2") format("woff2");
  unicode-range: U+2EC2-2EC3, U+8600, U+8611, U+861E, U+8621, U+8624, U+8627, U+8639, U+863C, U+8640, U+8653, U+8656, U+866F, U+8677, U+867A, U+8687, U+8689, U+868D, U+8691, U+869C-869D, U+86A8, U+86B1, U+86B3, U+86C1, U+86C3, U+86D1, U+86D5, U+86D7, U+86E3, U+86E6, U+8705, U+8707, U+870E, U+8710, U+8713, U+8719, U+871F, U+8721, U+8723, U+8731, U+873A, U+873E, U+8740, U+8743, U+8751, U+8758, U+8764-8765, U+8772, U+877C, U+8789, U+878B, U+8793, U+87A0, U+87A7, U+87BE, U+87C1, U+87CE, U+87DF, U+87E3, U+87E5-87E6, U+87EA-87EB, U+87ED, U+87F5, U+8801, U+8803, U+880B, U+8813, U+8828, U+882E, U+8832, U+883C, U+884A, U+8858, U+885F, U+8864, U+8869, U+886F, U+88A0, U+88BC-88BE, U+88C0, U+88D1-88D3, U+88DB, U+88F0-88F1, U+8901, U+8937, U+8942, U+8945, U+8949, U+8962, U+8980, U+8989, U+8990, U+899F, U+89B0, U+89B7, U+89D6, U+89D8, U+89EB, U+89F1, U+89F3, U+89FD, U+89FF, U+8A11, U+8A14, U+8A21, U+8A35, U+8A3E, U+8A45, U+8A4D, U+8A58, U+8A90, U+8AAE, U+8AB7, U+8ABE, U+8AD7, U+8AFC, U+8B05, U+8B0A, U+8B0D, U+8B1C, U+8B1F, U+8B2D, U+8B43, U+8B51, U+8B5E, U+8B76, U+8B7F, U+8B81, U+8B8B, U+8B94-8B95, U+8B9C, U+8B9E, U+8C39, U+8C3D, U+8C45, U+8C47, U+8C4F, U+8C54, U+8C57, U+8C69, U+8C6D, U+8C73, U+8C92-8C93, U+8C99, U+8C9B, U+8CA4, U+8CD5-8CD6, U+8CD9, U+8CF0-8CF1, U+8D09, U+8D0E, U+8D6C, U+8D84, U+8D95, U+8DA6, U+8DC6, U+8DC8, U+8DD9, U+8DEC, U+8DFD, U+8E06, U+8E0C, U+8E14, U+8E16, U+8E21-8E22, U+8E27, U+8E36;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold062.woff2") format("woff2");
  unicode-range: U+2ECD, U+2ECF, U+2ED2, U+2ED6, U+8E39, U+8E4B, U+8E54, U+8E62, U+8E6C-8E6D, U+8E6F, U+8E98, U+8E9E, U+8EAE, U+8EB3, U+8EB5-8EB6, U+8EBB, U+8ED1, U+8ED4, U+8EF9, U+8F00, U+8F08, U+8F17, U+8F2B, U+8F40, U+8F4A, U+8F58, U+8FA4, U+8FB4, U+8FB6, U+8FC1, U+8FC6, U+8FCA, U+8FCD, U+8FD3, U+8FD5, U+8FE0, U+8FF1, U+8FF5, U+8FFB, U+9002, U+900C, U+9037, U+9043-9044, U+905D, U+9085, U+908C, U+9090, U+90A1, U+90B0, U+90B6, U+90C8, U+90DC, U+90DF, U+90EB, U+90F2, U+90F6, U+90FE-9100, U+9104, U+9106, U+9118, U+911C, U+911E, U+9137, U+9139-913A, U+9146-9147, U+9157, U+9159, U+9161, U+9164, U+9174, U+9179, U+9185, U+918E, U+91A8, U+91AE, U+91B3, U+91B6, U+91C3-91C4, U+91DA, U+91EC, U+91EE, U+9201, U+920A, U+9216-9217, U+9233, U+9242, U+9247, U+924A, U+924E, U+9251, U+9256, U+9259, U+9260-9261, U+9265, U+9267-9268, U+927C-927D, U+927F, U+9289, U+928D, U+9297, U+9299, U+929F, U+92A7, U+92AB, U+92B2, U+92BF-92C0, U+92C6, U+92CE, U+92D0, U+92D7, U+92D9, U+92E5, U+92E7, U+92F7, U+92F9, U+92FB, U+9302, U+930D, U+9311, U+9315, U+931D-931E, U+9327, U+9329, U+9347, U+9351, U+9357, U+935A, U+936B, U+9371, U+9373, U+9388, U+938B, U+938F, U+939E, U+93A1, U+93C1, U+93C7, U+93DC, U+93E2, U+93E7, U+93F1, U+93F5, U+93FB, U+9409, U+940F, U+9416-9417, U+9432, U+9434, U+943B, U+9445, U+946D, U+946F, U+9578-9579, U+9586, U+958C-958D, U+95AB, U+95B4, U+95C8, U+961D, U+962C, U+9633-9634, U+963C, U+9641, U+9661, U+9682, U+969A, U+96A9, U+96AF, U+96B3, U+96BA, U+96BD, U+96D8, U+96DA, U+96DD, U+9714, U+9723, U+9736, U+9741, U+9747, U+9755, U+9757, U+975B, U+976A, U+9796, U+979A;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold063.woff2") format("woff2");
  unicode-range: U+2EDF, U+979E, U+97A2, U+97B1-97B2, U+97BE, U+97CC, U+97D1, U+97D4, U+97D8-97D9, U+97E1, U+97F1, U+9804, U+980D-980E, U+9814, U+9816, U+9823, U+9825, U+9832-9833, U+9847, U+9866, U+98AB, U+98AD, U+98B0, U+98B7-98B8, U+98BB-98BC, U+98BF, U+98C2, U+98C7, U+98CB, U+98E0-98E1, U+98E3, U+98E5, U+98EA, U+98F0-98F1, U+98F3, U+9908, U+9916-9917, U+991A-991C, U+9931-9933, U+993A-993C, U+9940-9941, U+9946, U+994D-994E, U+995C, U+995F-9960, U+99A3, U+99A6, U+99B9, U+99BD, U+99BF, U+99C3, U+99C9, U+99D4, U+99D9, U+99DE, U+99F0, U+99F9, U+99FC, U+9A0A, U+9A11, U+9A16, U+9A1A, U+9A20, U+9A31, U+9A36, U+9A44, U+9A4C, U+9A58, U+9AAF, U+9AB7, U+9AB9, U+9AC6, U+9AD0, U+9AD2, U+9AD5, U+9ADC, U+9AE0, U+9AE5, U+9AE9, U+9B03, U+9B0C, U+9B10, U+9B12, U+9B16, U+9B1C, U+9B2B, U+9B33, U+9B3D, U+9B4B, U+9B63, U+9B65, U+9B6B-9B6C, U+9B73, U+9B76-9B77, U+9BA6, U+9BAC, U+9BB1-9BB2, U+9BB8, U+9BBE, U+9BC7, U+9BD8, U+9BDD, U+9BE7, U+9BEA-9BEB, U+9BEE-9BEF, U+9BF3, U+9BF7, U+9BFA, U+9C16, U+9C18-9C1A, U+9C1D, U+9C22, U+9C27, U+9C29-9C2A, U+9C31, U+9C36-9C37, U+9C45, U+9C49-9C4A, U+9C54, U+9C58, U+9C5B-9C5D, U+9C5F, U+9C69-9C6B, U+9C6D-9C6E, U+9C70, U+9C72, U+9C75, U+9C7A, U+9CE6, U+9CF2, U+9D02, U+9D0B, U+9D11, U+9D17-9D18, U+9D32, U+9D42, U+9D4A, U+9D5F, U+9D62, U+9D69, U+9D6B, U+9D73, U+9D76-9D77, U+9D7E, U+9D84, U+9D8D;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold064.woff2") format("woff2");
  unicode-range: U+2E87, U+2EA4, U+2EBF-2EC0, U+2ECC, U+9D99, U+9DA1, U+9DB5, U+9DB9, U+9DBD, U+9DBF, U+9DC3, U+9DC7, U+9DC9, U+9DD6, U+9DDA, U+9DDF-9DE0, U+9DE3, U+9DF4, U+9E02, U+9E0A, U+9E0D, U+9E19, U+9E1C-9E1D, U+9E7B, U+9E80, U+9E85, U+9E9B, U+9EA8, U+9EBD, U+9EDF, U+9EE7, U+9EEE, U+9EFF, U+9F02-9F03, U+9F17, U+9F19, U+9F2F, U+9F37, U+9F3A, U+9F3D, U+9F41, U+9F45-9F46, U+9F53, U+9F55, U+9F58, U+9F5D, U+9F69, U+9F6D, U+9F70, U+9F75, U+FA13, U+FA20-FA21, U+FA24, U+FA49, U+FA58, U+FA5D-FA5E, U+FA66, U+20089, U+200A2, U+200A4, U+201A2, U+20213, U+2032B, U+20371, U+20381, U+203F9, U+2044A, U+20509, U+205D6, U+20628, U+2074F, U+20807, U+2083A, U+208B9, U+2097C, U+2099D, U+20AD3, U+20B1D, U+20D45, U+20DE1, U+20E64, U+20E6D, U+20E95, U+20F5F, U+21201, U+21255, U+21274, U+2127B, U+212D7, U+212E4, U+212FD, U+21336, U+21344, U+213C4, U+2146D, U+215D7, U+21647, U+21706, U+21742, U+219C3, U+21C56, U+21D2D, U+21D45, U+21D62, U+21D78, U+21D92, U+21D9C, U+21DA1, U+21DB7, U+21DE0, U+21E33, U+21F1E, U+21F76, U+21FFA, U+2217B, U+22218, U+2231E, U+223AD, U+226F3, U+2285B, U+228AB, U+2298F, U+22AB8, U+22B46, U+22B4F-22B50, U+22BA6, U+22C1D, U+22C24, U+22DE1, U+231B6, U+231C3, U+231F5, U+23372, U+233D0, U+233D2-233D3, U+233D5, U+233DA, U+233DF, U+233E4, U+2344A-2344B, U+23451, U+23465, U+234E4, U+2355A, U+23594, U+23638-2363A, U+23647, U+2370C, U+2371C, U+23764, U+237E7, U+237FF, U+23824, U+2383D, U+23A98, U+23C7F, U+23D00, U+23D0E, U+23D40, U+23DD3, U+23DF9-23DFA, U+23F7E, U+24096, U+24103, U+241C6, U+241FE, U+243BC, U+24629, U+246A5, U+24896, U+24A4D, U+24B56;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold065.woff2") format("woff2");
  unicode-range: U+2EAA, U+2EBD, U+2ECA, U+2EDE, U+4EFC, U+4F39, U+4FCD, U+4FFF, U+50D8, U+50F4, U+5164, U+51BE, U+51EC, U+529C, U+52C0, U+5372, U+53DD, U+5759, U+5765, U+58B2, U+5953, U+5963, U+59BA, U+5CF5, U+5DD0, U+5F21, U+605D, U+6085, U+60DE, U+6111, U+6120, U+6213, U+654E, U+662E, U+663B, U+670E, U+6AE2, U+6C6F, U+6D6F, U+6D96, U+6DF2, U+6DF8, U+6E39, U+24B6F, U+24C16, U+24D14, U+24E0E, U+24E37, U+24E6A, U+24E8B, U+2504A, U+25055, U+25122, U+251A9, U+251CD, U+251E5, U+2521E, U+2524C, U+2542E, U+254D9, U+255A7, U+257A9, U+257B4, U+259D4, U+25AE3-25AE4, U+25AF1, U+25BB2, U+25C4B, U+25C64, U+25E2E, U+25E56, U+25E62, U+25E65, U+25EC2, U+25ED8, U+25EE8, U+25F23, U+25F5C, U+25FD4, U+25FE0, U+25FFB, U+2600C, U+26017, U+26060, U+260ED, U+26270, U+26286, U+2634C, U+26402, U+2667E, U+266B0, U+2671D, U+268DD, U+268EA, U+26951, U+2696F, U+269DD, U+26A1E, U+26A58, U+26A8C, U+26AB7, U+26C29, U+26C73, U+26CDD, U+26E65, U+26F94, U+26FF6-26FF8, U+2710D, U+27139, U+273DA-273DB, U+273FE, U+27410, U+27449, U+27614-27615, U+27631, U+27693, U+2770E, U+27723, U+27752, U+27985, U+27A84, U+27BB3, U+27BBE, U+27BC7, U+27CB8, U+27DA0, U+27E10, U+27FB7, U+2808A, U+280BB, U+28282, U+282F3, U+2840C, U+28455, U+2856B, U+285C8-285C9, U+286D7, U+286FA, U+28946, U+28949, U+2896B, U+28987-28988, U+289BA-289BB, U+28A1E, U+28A29, U+28A43, U+28A71, U+28A99, U+28ACD, U+28ADD, U+28AE4, U+28BC1, U+28BEF, U+28D10, U+28D71, U+28DFB, U+28E1F, U+28E36, U+28E89, U+28EEB, U+28F32, U+28FF8, U+292A0, U+292B1, U+29490, U+295CF, U+2967F, U+296F0, U+29719, U+29750, U+298C6, U+29A72, U+29DDB, U+29E15, U+29E3D, U+29E49, U+29E8A, U+29EC4, U+29EDB, U+29EE9, U+29FCE, U+29FD7, U+2A01A, U+2A02F, U+2A082, U+2A0F9, U+2A38C, U+2A437, U+2A5F1, U+2A602, U+2A61A, U+2A6B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold066.woff2") format("woff2");
  unicode-range: U+2FAD, U+3EDA, U+4103, U+4E04-4E05, U+4E0C, U+4E1F, U+4E23-4E24, U+4E35, U+4E41, U+4E44, U+4E5C, U+4E63, U+4E68, U+4E74-4E75, U+4E79, U+4E7F, U+4E96-4E97, U+4EAF, U+4EDB, U+4EE0, U+4EE2, U+4EE8, U+4EEF, U+4EF3, U+4EFE, U+4F02, U+4F08, U+4F0C, U+4F12, U+4F15, U+4F17, U+4F19, U+4F2E, U+4F31, U+4F33, U+4F35, U+4F3B, U+4F40, U+4F42, U+4F4B-4F4C, U+4F52, U+4F63, U+4F6E, U+4F71, U+4F79, U+4F81, U+4F84, U+4F89, U+4F8C, U+4F8E, U+4F90, U+4F93, U+4F99, U+4F9E-4F9F, U+4FB7, U+4FB9, U+4FBB-4FBD, U+4FC0-4FC1, U+4FC6, U+4FC8, U+4FCC, U+4FDC, U+4FE2, U+4FF0, U+4FFC-4FFD, U+5004, U+5007, U+500A, U+500C, U+5017, U+501B, U+501D, U+5030, U+5032-5033, U+5035, U+5045, U+504A, U+504C, U+5051-5052, U+5059, U+505F-5060, U+5062, U+5067, U+506D, U+5071, U+5081, U+5083-5084, U+5086, U+508A, U+508E-5090, U+509B, U+509E-50A2, U+50AF-50B0, U+50B9, U+50BD, U+50C0, U+50C3, U+50D3, U+50DC-50DD, U+50DF, U+50E2, U+50E4, U+50E8, U+50EF, U+50F1, U+50F6, U+50FA, U+50FE, U+5107, U+510C, U+56E4, U+5880, U+6911, U+6DCA, U+7324, U+73BD, U+73D2, U+73F5, U+7421, U+7429, U+7575, U+7682, U+787A, U+79DA, U+7FA1, U+813A, U+83F6, U+856B, U+8807, U+8B53, U+8D76, U+8ECF, U+8FF6, U+9067, U+91B2, U+91DE, U+9206, U+9277, U+92D5, U+93A4, U+93F8, U+9431, U+9448, U+9592, U+9743, U+9751, U+9927, U+9BBB, U+9FC4, U+9FC6, U+FA0E, U+FA12, U+FA17-FA18, U+FA1C-FA1E, U+FA23, U+FA25, U+FA27-FA2D, U+FA6B-FA6D, U+20158, U+20BB7, U+233CC, U+233FE, U+242EE, U+2F8DB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold067.woff2") format("woff2");
  unicode-range: U+510D-510E, U+5110, U+5119, U+511C-511D, U+5123, U+5127-5128, U+512C-512D, U+512F, U+5131, U+5133-5134, U+5138-5139, U+5142, U+514F, U+5153, U+5158, U+515F, U+5166, U+517E, U+5184, U+518E, U+51A1, U+51B8, U+51BA, U+51BF, U+51C2, U+51C8, U+51CF, U+51D1-51D3, U+51D5, U+51D8, U+51E5, U+51F2, U+51F7, U+5205, U+5218, U+5222, U+5228, U+5231-5232, U+5235, U+523C, U+5245, U+5258, U+525A, U+525F-5260, U+5266, U+526E, U+5278-5279, U+5280, U+5285, U+528A, U+528C, U+5295-5297, U+529A, U+52A5, U+52A7, U+52B0, U+52B6-52B8, U+52BD, U+52C4, U+52C6, U+52CF, U+52D4, U+52DC, U+52E1, U+52E5, U+52E8-52EA, U+52EC, U+52F1, U+52F4, U+52F6, U+5303, U+530C, U+5311, U+5313, U+5318, U+531B, U+531E-531F, U+5325, U+5327-5329, U+532B-532D, U+5330, U+5332, U+533C-533D, U+534B-534C, U+5359, U+535B, U+5365, U+536D, U+5379, U+537E, U+5383, U+5387-5388, U+538E, U+5394, U+5399, U+53A1, U+53AA-53AB, U+53AF, U+53B5, U+53B8, U+53BA, U+53BD, U+53C5, U+53CF, U+53D2-53D3, U+53DE, U+53E0, U+53E6-53E7, U+5402, U+5413, U+541A, U+5421, U+542A, U+542F, U+5431, U+5434-5435, U+5444, U+5447, U+544F, U+545E, U+5464, U+5467, U+5469, U+546D-546E, U+5481, U+5483, U+5485, U+5489, U+5491, U+549F, U+54A7, U+54AA, U+54B1, U+54B9, U+54BB, U+54CA, U+54CD-54CE, U+54E0, U+54EA, U+54F6, U+54FC, U+54FE, U+5505, U+5508, U+550C-550D, U+5515, U+552A, U+5532, U+5536, U+553B, U+553D, U+5549, U+554D, U+5551, U+5558, U+555A-555B, U+5566, U+557F, U+558F, U+5592-5594, U+5597, U+55A3-55A4, U+55B2;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold068.woff2") format("woff2");
  unicode-range: U+55C1, U+55C3, U+55C6, U+55CB, U+55D3, U+55D7-55D8, U+55DB, U+55DE, U+55F6, U+55FF, U+5605, U+560A, U+560D, U+560F, U+5611-5612, U+5619, U+562C, U+5633, U+5635, U+5639, U+563C, U+5641, U+5643-5644, U+5646, U+564B, U+564D, U+564F, U+5654, U+5661-5663, U+5669, U+5675, U+5684-5685, U+568B, U+569F, U+56A6-56A7, U+56AB, U+56B1, U+56B7, U+56BE, U+56CB-56CC, U+56CF-56D0, U+56D9, U+56DC-56DD, U+56E1, U+56E5-56E7, U+56EB, U+56ED, U+56F1, U+5701-5702, U+5707, U+570C, U+5711, U+571A-571B, U+571D, U+5720, U+5722, U+5724-5725, U+572A, U+572C, U+572E, U+573D-573F, U+5752, U+5762, U+5767, U+576B, U+576D-576E, U+5771, U+5779-577A, U+577E, U+5781, U+5783, U+578C, U+5794-5795, U+5797, U+5799, U+579F, U+57A1, U+57A7, U+57A9, U+57BD, U+57D5, U+57DD, U+57E1, U+57E7, U+57E9, U+57FE, U+5803, U+5808, U+580C-580D, U+581B, U+581F, U+5826, U+582D, U+583F, U+584D, U+584F-5850, U+5855, U+585F, U+5868, U+5878, U+587F, U+5881, U+5887-5888, U+588C, U+5896, U+58A0-58A2, U+58A6, U+58BC, U+58C2, U+58C4, U+58C8, U+58D0, U+58D6, U+58DD, U+58E1, U+5912-5913, U+591D, U+5921, U+5923, U+5928, U+592F-5930, U+5933, U+5935-5936, U+593F, U+5943, U+5952, U+5959, U+595E, U+5961, U+596B, U+596F, U+5972, U+5979, U+597B, U+598C, U+598E, U+5995, U+5997, U+59A7, U+59AD, U+59AF-59B0, U+59B3, U+59B7, U+59C1, U+59C4, U+59CA, U+59DF, U+59EF, U+59F1-59F2, U+59F4, U+59F7, U+5A00, U+5A04, U+5A0E, U+5A12, U+5A1E, U+5A24, U+5A28, U+5A2A, U+5A30, U+5A44-5A45, U+5A47-5A48, U+5A4C, U+5A50, U+5A5E, U+5A63;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold069.woff2") format("woff2");
  unicode-range: U+2F39, U+5A7B, U+5A90, U+5A93, U+5A96, U+5A99, U+5AAC, U+5AB2, U+5AB8, U+5ABB, U+5AC6, U+5AC8, U+5ACF, U+5AEA, U+5AF6, U+5AFD, U+5B01, U+5B1B, U+5B1D, U+5B21, U+5B38, U+5B4B, U+5B5E, U+5B6E, U+5B7E, U+5B86, U+5B8A, U+5B8E, U+5B90-5B91, U+5B94, U+5BA8-5BA9, U+5BAD, U+5BAF, U+5BB1-5BB2, U+5BB7, U+5BBA, U+5BBC, U+5BC1, U+5BCD, U+5BCF, U+5BD7, U+5BD9-5BDA, U+5BE0, U+5BEF, U+5BF4, U+5C0C, U+5C17, U+5C1F, U+5C26, U+5C2C, U+5C2E, U+5C32, U+5C35-5C36, U+5C59-5C5A, U+5C5C, U+5C6D, U+5C74-5C75, U+5C7B, U+5C7D, U+5C87, U+5C92, U+5C9D, U+5CB2, U+5CB4, U+5CD7, U+5CDD, U+5CEE, U+5CF1-5CF2, U+5D01, U+5D12, U+5D23, U+5D34, U+5D3D, U+5D3F, U+5D43, U+5D48, U+5D51, U+5D55, U+5D59, U+5D5F-5D60, U+5D62, U+5D64, U+5D79-5D7A, U+5D7E-5D7F, U+5D83, U+5D8A, U+5D93, U+5D95, U+5D9B, U+5D9F, U+5DAB, U+5DC3, U+5DC7, U+5DCE, U+5DD9, U+5DF8-5DF9, U+5E07, U+5E0D, U+5E20, U+5E28, U+5E32, U+5E35, U+5E4B, U+5E50-5E51, U+5E5B-5E5C, U+5E68, U+5E6A, U+5E70, U+5E80, U+5E8B, U+5E8E, U+5EA2, U+5EA4, U+5EA8, U+5EB1, U+5EB3, U+5EBD, U+5ECC, U+5ECE, U+5ED1, U+5ED4-5ED5, U+5EDC, U+5EDE, U+5EE5, U+5EEB, U+5F06, U+5F19, U+5F22, U+5F24, U+5F28, U+5F2B-5F2C, U+5F2E, U+5F30, U+5F3B, U+5F3F, U+5F44, U+5F4D, U+5F50, U+5F5B, U+5F60, U+5F6F, U+5F74-5F75, U+5F78, U+5F7A, U+5F8D, U+5F96, U+5F9D, U+5FAB-5FAC, U+5FB0-5FB1, U+5FC8, U+5FD0-5FD1, U+5FE8, U+5FEA, U+5FEC-5FED, U+5FF2, U+5FF6, U+5FFA, U+6007, U+600A, U+6013, U+601A, U+601F, U+602D, U+6040, U+6048-6049, U+6051, U+6054, U+6056-6057, U+6061, U+6067;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold070.woff2") format("woff2");
  unicode-range: U+6071, U+607E, U+6082, U+6086, U+6088, U+608E, U+6091, U+6093, U+6098, U+60A2, U+60A4-60A5, U+60B7, U+60BB, U+60C2, U+60C4, U+60C9-60CA, U+60CE-60CF, U+60E2, U+60E5, U+60FC-60FD, U+6102, U+6107, U+610A, U+610C, U+6114, U+6116-6117, U+6122, U+612A, U+6131, U+6135-6136, U+6139, U+6145, U+6149, U+615E, U+616C, U+6172, U+6178, U+617B, U+617F-6181, U+6183-6184, U+618B, U+619C-619D, U+619F-61A0, U+61AA, U+61B8, U+61C0-61C2, U+61CE-61CF, U+61DC, U+61DE, U+61E1-61E2, U+61E5, U+61E7, U+61E9, U+61EC-61ED, U+61EF, U+6201, U+6203-6204, U+6207, U+621C, U+6220, U+6227, U+622B, U+6239, U+623D, U+6242, U+6244, U+6250, U+6254, U+625C, U+627A, U+627D, U+628D-6290, U+62A8, U+62B3, U+62B6-62B7, U+62BA, U+62BE-62BF, U+62CE, U+62DA, U+62EA, U+62F2, U+62F4, U+6304, U+630B, U+6313, U+6316, U+6329-632A, U+632D, U+6336, U+6342, U+6346, U+634A-634B, U+6352-6354, U+6358, U+635B, U+6366, U+636D, U+6371, U+6374-6375, U+6378, U+637D, U+637F, U+6382, U+6387, U+638A, U+6390, U+6395, U+639A, U+639E, U+63A4, U+63A6, U+63AD-63AF, U+63C1, U+63C5, U+63C8, U+63CE, U+63D1, U+63D3, U+63EA, U+63F3, U+63F8-63F9, U+640A, U+6412, U+6418, U+6420, U+6424, U+642A, U+6430, U+6435, U+643D, U+643F, U+644B, U+644F, U+6452-6454, U+645C, U+645F, U+6461, U+6463, U+6474, U+647B, U+6485, U+648F-6490, U+6498-6499, U+649B, U+64A1, U+64A3, U+64A6, U+64A8, U+64AC, U+64B3, U+64BD, U+64C9, U+64D1, U+64E9-64EA, U+64ED, U+64F0, U+64F5;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold071.woff2") format("woff2");
  unicode-range: U+6501, U+6508-650A, U+6513, U+6519, U+651B, U+651F, U+6526, U+652E, U+6531, U+653A, U+653C-653D, U+6543, U+6547, U+6549, U+6550, U+6552, U+655F-6560, U+657D, U+6592, U+6595, U+6598, U+65A0, U+65A3, U+65A6, U+65AE, U+65B3-65B4, U+65C8, U+65CE, U+65D0, U+65D6, U+65D8, U+65DF, U+65F0, U+65F4-65F5, U+65FE-65FF, U+660D, U+6611-6612, U+6616, U+661D, U+6623, U+6626, U+6629, U+662C, U+6637, U+6639, U+6640, U+6646, U+664A, U+6658, U+665C, U+6660, U+6675, U+6679, U+667C, U+667E-667F, U+668B-668C, U+669A-669C, U+669F, U+66A4, U+66C0, U+66C2-66C3, U+66CC, U+66CE-66CF, U+66D4, U+66DF, U+66EB, U+66EE, U+6707, U+671C, U+6720, U+6722, U+673E, U+6745, U+6755, U+676C, U+676E, U+6784, U+678E-678F, U+6791, U+6796, U+6799, U+67B1, U+67B5, U+67BC-67BD, U+67C2, U+67C5, U+67C9, U+67DC, U+67E1, U+67E6, U+67F2, U+67F6, U+6814, U+6819, U+6827-6828, U+682F-6830, U+683F, U+684A, U+6858, U+686F-6871, U+6879, U+687B, U+6884, U+6886, U+6888, U+68A1, U+68A9, U+68AE, U+68D1, U+68D3, U+68DC-68DD, U+68EA-68EB, U+68F5-68F6, U+68FD, U+6906, U+6909, U+6910, U+6913, U+6916-6917, U+6931, U+6933, U+6938, U+6945, U+694E, U+695B, U+6965-6966, U+6970-6971, U+697B, U+698D, U+69A1, U+69A8, U+69AB, U+69AF, U+69B8, U+69C5, U+69C8, U+69D7, U+69E5, U+69F1, U+6A00-6A01, U+6A03, U+6A20, U+6A24, U+6A28, U+6A37, U+6A4A, U+6A51, U+6A55, U+6A67, U+6A6A, U+6A71, U+6A81, U+6A86-6A87, U+6A9B, U+6AAF-6AB1, U+6AB4, U+6ABE-6ABF, U+6AC8-6AC9, U+6ACC, U+6AD5-6AD6, U+6AF0, U+6AFC, U+6B02;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold072.woff2") format("woff2");
  unicode-range: U+6B03, U+6B06-6B07, U+6B09, U+6B24, U+6B28, U+6B2B, U+6B36, U+6B3B, U+6B3F, U+6B4D, U+6B52, U+6B5D, U+6B6B, U+6B6E, U+6B70, U+6B7D-6B7E, U+6B85, U+6B97, U+6B9F-6BA0, U+6BA2-6BA3, U+6BA8, U+6BAC, U+6BB0, U+6BB8-6BB9, U+6BC3-6BC4, U+6BCC, U+6BE1, U+6BE3, U+6BF7, U+6BF9, U+6C04, U+6C09, U+6C0D, U+6C12, U+6C19, U+6C1F, U+6C26-6C28, U+6C2C, U+6C2E, U+6C3B, U+6C4A-6C4B, U+6C4F, U+6C52, U+6C54, U+6C6B, U+6C78-6C79, U+6C7B, U+6C87, U+6C9F, U+6CB0, U+6CB2, U+6CB4, U+6CCD, U+6CCF, U+6CD1-6CD2, U+6CE7, U+6CF2, U+6CF4, U+6D07, U+6D0F, U+6D11, U+6D13, U+6D1A, U+6D28, U+6D57, U+6D5F, U+6D61, U+6D67, U+6D7C, U+6D92, U+6D97, U+6DB7, U+6DB9, U+6DBD, U+6DE0, U+6DE2, U+6DE5, U+6DEF-6DF0, U+6DF4, U+6E00, U+6E04, U+6E3B, U+6E45, U+6E52, U+6E5D, U+6E62, U+6E68, U+6E73, U+6E7B, U+6E7D, U+6E8D, U+6E99, U+6EA0, U+6EAD-6EAE, U+6EB3, U+6EBB, U+6EC0, U+6EC8, U+6ECD, U+6ECF, U+6EED-6EEE, U+6EFD, U+6F04, U+6F08, U+6F0D, U+6F16, U+6F1B, U+6F26, U+6F29, U+6F2D, U+6F30, U+6F3B, U+6F4F, U+6F53, U+6F5D, U+6F6C, U+6F83, U+6F87, U+6F8B, U+6F92-6F93, U+6F9A, U+6FA6, U+6FA8, U+6FAE, U+6FB0, U+6FC5, U+6FE8, U+6FFD, U+700D, U+7017, U+7020, U+702F, U+7034, U+7037, U+7044, U+7048-7049, U+7055, U+7094, U+7096, U+7098, U+709B, U+70B0, U+70B4, U+70D5-70D6, U+70FA, U+7105, U+710B, U+712D, U+7138, U+7141, U+7145, U+714B, U+7157, U+715A, U+7179, U+718C, U+719A, U+71B0, U+71B2, U+71BF-71C0, U+71CC, U+71D6, U+71DA, U+71F8, U+7208-7209, U+7213, U+7217, U+721A, U+721D, U+721F, U+7224, U+722F, U+7241, U+7243, U+7245, U+724E-7250;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold073.woff2") format("woff2");
  unicode-range: U+725A, U+725C, U+725E, U+7260, U+7268, U+726B, U+7271, U+7277, U+727B-727C, U+7284, U+7289, U+7293, U+72A8, U+72B4, U+72C7, U+72C9, U+72D5-72D6, U+72D8, U+72DF, U+72E5, U+72F4, U+72FE, U+7302, U+7304-7305, U+730B, U+730D, U+7313, U+731E, U+7322, U+7332, U+7335, U+733A-733B, U+7343, U+734D, U+7356, U+7358, U+735D-7360, U+7367, U+7369, U+7379, U+7380, U+7383, U+7386, U+738E, U+7390, U+7393, U+7397, U+73AA, U+73AD, U+73C6, U+73CC, U+73D3, U+73DD, U+73E6, U+73F4, U+73F7, U+73FB, U+73FD, U+73FF-7400, U+7404, U+7411, U+742D, U+7451, U+7466-7468, U+746E, U+7472, U+7480, U+748F, U+7491, U+749A, U+74AE-74AF, U+74B1-74B2, U+74CC, U+74D0, U+74D3, U+74DB, U+74DF, U+74E4, U+74E8, U+74EA, U+74F4, U+74FB-74FC, U+7506, U+7512, U+7516, U+7521, U+7527, U+7529, U+7536, U+7539, U+753F, U+7543, U+7547, U+7557, U+755E-755F, U+7561, U+757B-757C, U+7585, U+7590, U+7593, U+7595, U+7599, U+759C, U+75A4, U+75B4, U+75BA, U+75C1, U+75C4, U+75CC, U+75D7, U+75DC, U+75E1, U+75EF, U+7604, U+760A, U+760C, U+7612, U+761D, U+7623, U+7629, U+7632, U+7638-763A, U+7640, U+7644-7645, U+764A, U+7659, U+765F, U+7674, U+7685, U+768C-768D, U+769F, U+76A2-76A5, U+76AD, U+76BD, U+76C1, U+76CB, U+76D9, U+76E0, U+76F0, U+76F6, U+76F9, U+7700, U+7706, U+770E, U+7712, U+7714-7715, U+771C, U+7722, U+7728, U+772E-772F, U+7739, U+773D-773E, U+7742, U+7745, U+774A, U+774F, U+7752, U+7756-7757, U+775E, U+7764, U+7767, U+776C, U+7770, U+7773-7774, U+7784, U+778C-778D, U+7795-7796, U+77A7;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold074.woff2") format("woff2");
  unicode-range: U+77AE-77AF, U+77B1, U+77B5, U+77BE, U+77C3, U+77C9, U+77D1-77D2, U+77D5, U+77D9, U+77F1, U+77F8, U+780E, U+7811, U+781D, U+7822-7823, U+782E, U+7830, U+7835, U+7837, U+7844, U+7848, U+784C, U+7852, U+785C, U+785E, U+7860-7861, U+7863, U+787E, U+788F, U+7898, U+78A1, U+78A8, U+78AC, U+78B1-78B3, U+78BD, U+78BF, U+78C7, U+78C9, U+78D2-78D3, U+78D6, U+78DB, U+78DF, U+78EA, U+78F3, U+78F6, U+78FF, U+7906, U+790C, U+791A, U+791E-7920, U+7927, U+7929, U+792D, U+7935, U+793D, U+793F, U+7944, U+794B, U+794F, U+7951, U+7954, U+7969, U+796B, U+797B-797C, U+797E, U+798B-798C, U+7991, U+7993, U+799C, U+79A8, U+79AB, U+79AF, U+79C4, U+79CF, U+79DD, U+79E0, U+79E2, U+79E5, U+79EA, U+79F1, U+79F8, U+79FC, U+7A02, U+7A07, U+7A09, U+7A0C, U+7A21, U+7A27, U+7A2B, U+7A2F-7A30, U+7A34-7A35, U+7A3A, U+7A44-7A45, U+7A48, U+7A55, U+7A65, U+7A7E, U+7A80, U+7A86, U+7A8B, U+7A91, U+7A94, U+7A9E, U+7AB5, U+7AC6, U+7AC9, U+7ADB, U+7AE9, U+7AEC, U+7AF1, U+7AFB, U+7AFE, U+7B14, U+7B1F, U+7B23, U+7B29, U+7B2B, U+7B30, U+7B34, U+7B3F-7B40, U+7B6A, U+7B77, U+7B84, U+7B89, U+7B8E, U+7B96, U+7BA0, U+7BA5, U+7BAC, U+7BB0, U+7BB2, U+7BB6, U+7BBA-7BBB, U+7BBD, U+7BC2, U+7BC8, U+7BDB, U+7BE8, U+7BF2, U+7BF4-7BF5, U+7BF8-7BFA, U+7BFC, U+7BFE, U+7C02, U+7C04, U+7C06, U+7C09, U+7C0C, U+7C19, U+7C1B, U+7C25, U+7C28, U+7C2C, U+7C34, U+7C39-7C3A, U+7C46, U+7C52-7C53, U+7C55, U+7C5A-7C5D, U+7C63, U+7C67, U+7C72, U+7C7C-7C7D, U+7C86-7C87, U+7C9E, U+7CB0, U+7CBA-7CBB;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold075.woff2") format("woff2");
  unicode-range: U+2EB1, U+7CC7, U+7CC9, U+7CCF, U+7CD3-7CD4, U+7CDA, U+7CE9, U+7D0F, U+7D11, U+7D16, U+7D26, U+7D2A, U+7D2D, U+7D3C, U+7D4D, U+7D51, U+7D57, U+7D5D, U+7D65, U+7D67, U+7D78, U+7D7B, U+7D81-7D82, U+7D85, U+7D8D, U+7D91, U+7D96, U+7D9E, U+7DB3, U+7DB9, U+7DC3-7DC6, U+7DCD-7DCE, U+7DE2, U+7DE5, U+7DEA-7DEB, U+7DED, U+7DF5-7DF6, U+7DFA, U+7E00, U+7E1C, U+7E2D, U+7E2F, U+7E33, U+7E36, U+7E3F, U+7E44, U+7E4E, U+7E50, U+7E58, U+7E5F, U+7E62, U+7E65, U+7E6E-7E6F, U+7E78, U+7E81, U+7E95, U+7E9D-7E9E, U+7F3D, U+7F3F, U+7F53, U+7F5B-7F5D, U+7F65-7F66, U+7F71, U+7F7F-7F80, U+7F8B, U+7F8D, U+7F8F, U+7FA2, U+7FA6, U+7FAA, U+7FB4, U+7FBC, U+7FC0, U+7FC8, U+7FE8, U+7FFD-7FFF, U+8007, U+800D, U+800F, U+8013, U+801D-8020, U+802E, U+8034, U+8039-803A, U+803E, U+8040, U+8044, U+8060, U+8064, U+806D, U+8081, U+8088, U+808E, U+809E, U+80A6, U+80AB, U+80B9, U+80C8, U+80CD, U+80CF, U+80D2, U+80D4, U+80ED-80EE, U+80F0, U+80F2, U+80F6, U+80F9-80FA, U+80FE, U+8103, U+810B, U+8117, U+811C, U+8120, U+8130, U+813C, U+8145, U+8147, U+814C, U+8152, U+8157, U+8161, U+816F, U+8177, U+8186, U+818B, U+818E, U+8190, U+8196, U+819B, U+819E, U+81A2, U+81AE, U+81B4, U+81C5, U+81CB, U+81CE, U+81D5, U+81DD-81DE, U+81E1, U+81EB, U+81F0-81F2, U+81F5-81F6, U+81F8-81F9, U+8200, U+8203, U+820F, U+8213-8214, U+821A, U+821D, U+8228, U+823A, U+8243-8244, U+824E, U+8251, U+8256, U+8267, U+826D, U+827B, U+8280-8281, U+8284, U+8287, U+8289, U+8291, U+8294, U+8296, U+8298, U+829A-829B, U+82A0;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold076.woff2") format("woff2");
  unicode-range: U+82AA, U+82B0, U+82D0, U+82DA, U+82E0, U+82E4, U+82EA, U+82ED, U+82EF, U+82F6, U+830A-830B, U+831E-831F, U+8321, U+832C, U+832E, U+8333, U+8337, U+833D, U+8342, U+834D-834E, U+8353, U+8356, U+8370, U+8378, U+8380, U+8382, U+8384, U+8399, U+839C, U+83A6, U+83AC-83AD, U+83BE, U+83E8, U+83F8, U+83FC, U+8419, U+842F, U+8445, U+8447, U+844D, U+8456, U+8458, U+8460, U+8464, U+8467, U+846A, U+8474, U+847D, U+8492-8493, U+8495, U+84A8-84AA, U+84B1, U+84BD, U+84C7-84C8, U+84CC, U+84F2, U+84F7, U+84FB, U+8502-8503, U+8507, U+850E, U+8510, U+851C, U+8522, U+8527, U+852A, U+8533, U+8536, U+853F, U+8546, U+8550, U+8552, U+8556, U+855C-855D, U+855F-8560, U+8579, U+8585, U+8589, U+858B, U+8598, U+85A0, U+85A5, U+85A7, U+85B4, U+85B6, U+85B8, U+85BD-85BF, U+85C2, U+85DA, U+85E0, U+85E8, U+85F3, U+85FC, U+860D-860E, U+8619, U+861B, U+8636, U+863A, U+863D, U+8642, U+8646, U+8652, U+8658-8659, U+865D, U+8660-8661, U+8663-8664, U+8669, U+8676, U+8688, U+8696, U+869A, U+86A1, U+86A6-86A7, U+86AD, U+86B4-86B5, U+86B7, U+86B9, U+86BF-86C0, U+86C5, U+86D2, U+86DA, U+86DC, U+86E0, U+86E5, U+86E7, U+8704, U+870F, U+8714, U+871E, U+8728, U+872E-872F, U+8732, U+8739, U+873C-873D, U+8745, U+874D, U+875D, U+8761, U+876F, U+877B, U+8783-8786, U+878C, U+8790, U+8795, U+8797-8799, U+879E, U+87A3, U+87AE, U+87B1, U+87BF, U+87C8-87CA, U+87D5, U+87D9-87DA, U+87DC, U+87E2, U+87E4, U+87F1, U+87F3, U+87F8, U+87FA, U+87FF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold077.woff2") format("woff2");
  unicode-range: U+8809, U+8812, U+8818-881C, U+881E, U+882D, U+8830, U+8835, U+883A, U+8841, U+8843, U+8845, U+8848-8849, U+884B, U+884E, U+8851, U+8855-8856, U+885A, U+885C, U+8860, U+8871, U+8879, U+887B, U+8880, U+889A-889C, U+889F, U+88A8, U+88BA, U+88CB-88CD, U+88DE, U+88E7, U+88EF, U+88F7, U+8906, U+890D-890F, U+8915-8916, U+8920, U+8926, U+8928, U+8931, U+8935, U+893A, U+893E, U+8946, U+894F, U+8952, U+8957, U+895A-895C, U+8961, U+8963, U+896B, U+896E, U+8970, U+8973, U+8975, U+897A-897D, U+898D, U+8995, U+899B-899C, U+89A0, U+89A5, U+89B4-89B6, U+89BC, U+89D5, U+89D7, U+89E9, U+89ED, U+89F9, U+8A04-8A05, U+8A07, U+8A0F, U+8A1E, U+8A20, U+8A24, U+8A26, U+8A2B-8A2C, U+8A2F, U+8A3D, U+8A40, U+8A43, U+8A49, U+8A53, U+8A56-8A57, U+8A5C, U+8A65, U+8A67, U+8A76-8A77, U+8A7A-8A7B, U+8A7E-8A80, U+8A83, U+8A86, U+8A8B, U+8A8F, U+8A92, U+8A96-8A97, U+8A99, U+8A9F, U+8AA9, U+8AAF, U+8AB3, U+8AB6, U+8ABB, U+8AC3, U+8AC6, U+8AC8-8ACA, U+8AD1, U+8AD3-8AD5, U+8ADD, U+8AEC, U+8AF0, U+8AF5, U+8AFF, U+8B06, U+8B0B, U+8B11, U+8B1E, U+8B30, U+8B37, U+8B3C, U+8B42, U+8B44-8B45, U+8B48, U+8B4D, U+8B52, U+8B63, U+8B6D, U+8B78-8B79, U+8B7C, U+8B7E, U+8B84-8B85, U+8B8D, U+8B8F, U+8B9F, U+8C38, U+8C3E, U+8C4B, U+8C51, U+8C53, U+8C58-8C59, U+8C5B, U+8C5D, U+8C63-8C64, U+8C66, U+8C75-8C76, U+8C7B, U+8C7E, U+8C86-8C87, U+8C8B, U+8C90, U+8C9C, U+8CB9-8CBA, U+8CC5-8CC6, U+8CC9, U+8CCB, U+8CCF;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold078.woff2") format("woff2");
  unicode-range: U+8CDD, U+8CE8, U+8CEC, U+8CEF, U+8CF2, U+8CF5, U+8CF7, U+8CFF, U+8D01, U+8D03, U+8D17, U+8D65, U+8D69, U+8D6E, U+8D7F, U+8D82, U+8D88, U+8D8D, U+8D90-8D91, U+8D9E-8DA0, U+8DAB-8DAC, U+8DB2, U+8DB5, U+8DB7, U+8DB9, U+8DBB-8DBC, U+8DC0, U+8DC5, U+8DC7, U+8DCA, U+8DD4-8DD5, U+8DE4-8DE5, U+8DE7, U+8DF0-8DF2, U+8DF4, U+8E01, U+8E04-8E05, U+8E0B, U+8E11, U+8E26, U+8E31, U+8E33, U+8E37-8E38, U+8E40-8E41, U+8E4D-8E4F, U+8E5B-8E5E, U+8E61, U+8E69, U+8E71, U+8E79-8E7A, U+8E82-8E83, U+8E89, U+8E90, U+8E92, U+8E95, U+8E9A-8E9B, U+8E9D, U+8EA2, U+8EA7, U+8EA9, U+8EAD, U+8EBA, U+8EC1, U+8EC3-8EC4, U+8EC7, U+8EDC, U+8EE8, U+8EED-8EEE, U+8EF0-8EF1, U+8EF7, U+8F02, U+8F07, U+8F0F-8F10, U+8F16, U+8F18, U+8F20-8F21, U+8F23, U+8F25, U+8F27-8F28, U+8F2C, U+8F2E, U+8F34-8F35, U+8F37, U+8F3A, U+8F41, U+8F43, U+8F47, U+8F4F, U+8F51-8F53, U+8F55, U+8F5D-8F5E, U+8F65, U+8F9D, U+8FA0-8FA1, U+8FA5, U+8FB8, U+8FBE, U+8FC0, U+8FCB, U+8FD0, U+8FD2, U+8FE3, U+8FFE, U+9004, U+9018, U+901B, U+9028-902A, U+902C, U+902F, U+9033-9034, U+903F, U+904C, U+905B, U+9062, U+9066, U+906C, U+9070, U+9074, U+9079, U+908B, U+908E, U+9098, U+90A0, U+90A5, U+90B2, U+90B4, U+90BD, U+90CC, U+90D2, U+90D5, U+90D8-90D9, U+90E5, U+90F0, U+9105, U+9108, U+910D, U+9110, U+9117, U+911A, U+9120, U+9125, U+9129, U+912E, U+9136, U+913C, U+9143, U+914F, U+9153, U+915A, U+9167, U+916D, U+917A-917B, U+9181, U+9186, U+918A, U+9191;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold079.woff2") format("woff2");
  unicode-range: U+9193-9195, U+9198, U+91A1, U+91A6, U+91AD, U+91B0, U+91BB, U+91BD, U+91BF, U+91C2, U+91C5, U+91D3-91D4, U+91D9, U+91E9-91EA, U+91EF-91F0, U+91F7, U+91F9, U+91FD, U+9200, U+9204-9205, U+9209, U+920C, U+9212-9213, U+9218, U+921C-921D, U+9223-9226, U+9228, U+922E-9230, U+9235-9236, U+923E, U+9246, U+924D, U+9258, U+925C-925D, U+9269, U+926E-9270, U+9275-9276, U+9279, U+927B, U+928A, U+928E, U+9292, U+92A0, U+92A4-92A5, U+92A8, U+92AF, U+92B6, U+92B8, U+92BA-92BD, U+92C1, U+92C3, U+92C5, U+92C7-92C8, U+92CD, U+92D8, U+92DC-92DD, U+92DF, U+92E1, U+92E3, U+92E8, U+92EC, U+92EE, U+92F0, U+9300, U+9308, U+9314, U+931C, U+9324, U+932A, U+9333-9334, U+9336-9337, U+9350, U+9352, U+9355, U+9358, U+935E, U+9367, U+9369, U+936D, U+936F, U+9374, U+9376, U+937A, U+937D, U+937F-9382, U+938A, U+938D, U+9392, U+9395, U+9398, U+93A6, U+93A8-93A9, U+93AB, U+93B4-93B6, U+93C4-93C5, U+93C9-93CD, U+93D3, U+93D9, U+93E6, U+93F7, U+93F9-93FA, U+9401-9402, U+9408, U+940D-940E, U+9415, U+941F, U+942E-942F, U+943D, U+943F, U+9443, U+944C, U+9455, U+9459, U+945C, U+945F, U+9461, U+9468, U+946E, U+9483-9484, U+957E, U+9584, U+9588, U+959D-959E, U+95A1, U+95BA, U+95BF, U+95C6, U+95C9, U+95D1-95D2, U+95D9, U+95DD, U+95DF-95E0, U+95E4, U+95E6, U+961E, U+9622, U+9624-9626, U+9631, U+9637-963A, U+963D, U+9652, U+9654, U+9656-9657, U+966E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold080.woff2") format("woff2");
  unicode-range: U+9674, U+967B-967C, U+967E-967F, U+9681, U+9683, U+9689, U+9691, U+9696, U+969F, U+96A6, U+96AE, U+96CA, U+96DF, U+96F1, U+96FA, U+9702-9703, U+9705, U+9709, U+971A-971B, U+971D, U+9721-9722, U+9728, U+9731, U+974A, U+9758, U+9763, U+9767, U+9776-9778, U+977B, U+977D, U+977F-9780, U+9789, U+9797, U+9799, U+979F, U+97AC, U+97B5-97B6, U+97B8-97B9, U+97BC, U+97BF, U+97C4-97C5, U+97C7, U+97CA, U+97CD-97CE, U+97D0, U+97D7, U+97DD, U+97E0, U+97E4, U+97EF, U+97F7-97F8, U+97FA, U+9807, U+9819, U+981C, U+9820, U+9826, U+982E-982F, U+9835, U+983E, U+9844, U+984A, U+9851, U+9862-9863, U+986A, U+98AE, U+98B4, U+98C5, U+98CC, U+98E6, U+98F6, U+9902, U+9907, U+9911, U+9915, U+991F, U+9922, U+9926, U+992B, U+9934-9935, U+9939, U+9947-9948, U+9954, U+9959, U+995B, U+995E, U+999B, U+999D, U+999F, U+99B0-99B2, U+99B5, U+99BA, U+99D3, U+99DA, U+99DC, U+99E7, U+99EA-99EC, U+99F4-99F5, U+99FD-99FE, U+9A04, U+9A0B-9A0C, U+9A10, U+9A1E, U+9A22-9A23, U+9A27, U+9A33, U+9A35, U+9A41, U+9A47, U+9A4B, U+9A51, U+9A54, U+9A56, U+9A5D, U+9AAA, U+9AAC, U+9AAE, U+9AB2, U+9AB4-9AB5, U+9ABB, U+9ABE-9ABF, U+9AC8, U+9AD7, U+9ADB, U+9AE4, U+9AE7, U+9AEC, U+9AF2-9AF3, U+9AF5, U+9AFA, U+9AFD, U+9AFF-9B01, U+9B04-9B05, U+9B09, U+9B0B, U+9B0D-9B0E, U+9B19, U+9B1B, U+9B26, U+9B34-9B35, U+9B37, U+9B39-9B3A, U+9B48, U+9B4C, U+9B55-9B57, U+9B5B, U+9B61, U+9B68, U+9B6A, U+9B6D-9B6E;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold081.woff2") format("woff2");
  unicode-range: U+2E8B-2E8D, U+2E95, U+2EA2, U+2EA9, U+2EAC, U+2EAE, U+2EB3, U+2EB7, U+2EBC, U+2EC6, U+2ED7, U+2EDD, U+3405, U+3427, U+3488, U+353E, U+35DE, U+3614, U+38A1, U+38AD, U+39A4, U+39B8, U+3A5C, U+3A85, U+3AC4, U+3ACB, U+3AF3, U+3BF3, U+3D31, U+3D4E, U+3DCC, U+3E40, U+3E8A, U+3FB1, U+3FDC, U+4293, U+440C, U+457A, U+45BE, U+4610, U+46AE, U+471F, U+47E6, U+481E, U+4B38, U+4B7E, U+4C38, U+4CE1, U+4E37, U+4F2B, U+4FD3, U+9B78, U+9B7F-9B80, U+9B85-9B87, U+9B89, U+9B8B, U+9B8D, U+9B90, U+9B94, U+9B9A, U+9B9D, U+9BA9, U+9BB0, U+9BB7, U+9BBC, U+9BBF, U+9BC8, U+9BD0, U+9BD7, U+9BDF, U+9BF9, U+9BFF, U+9C02, U+9C0B, U+9C0F, U+9C11, U+9C1C, U+9C1E, U+9C26, U+9C28, U+9C35, U+9C3D, U+9C43-9C44, U+9C4E, U+9C56, U+9C5E, U+9C68, U+9C7B, U+9CF7, U+9CF9, U+9D1C, U+9D2F-9D30, U+9D33-9D34, U+9D3A, U+9D3C-9D3D, U+9D45, U+9D53-9D54, U+9D65, U+9D6A, U+9D7B, U+9D83, U+9D86, U+9D8E, U+9D92-9D93, U+9D95, U+9D97-9D98, U+9DAA, U+9DAE, U+9DB1, U+9DCA, U+9DD4-9DD5, U+9DDE, U+9DE5, U+9DE9, U+9DEB, U+9DEE, U+9DF0, U+9DF3, U+9DFE, U+9E0E, U+9E10-9E12, U+9E16, U+9E7A, U+9E82-9E84, U+9E87, U+9E8E-9E8F, U+9E96, U+9E98, U+9EAE, U+9EB0, U+9EB3, U+9EC6, U+9EC8, U+9ECB, U+9ED5, U+9EE4, U+9EEC-9EED, U+9EF0-9EF2, U+9EF5, U+9EF8, U+9F09, U+9F0F, U+9F11-9F12, U+9F14, U+9F16, U+9F1A-9F1B, U+9F1F, U+9F22, U+9F26, U+9F2A-9F2B, U+9F31-9F32, U+9F34, U+9F3C, U+9F3F, U+9F43-9F44, U+9F47, U+9F56, U+9F5A, U+9F5E, U+9F68, U+9F6E-9F6F, U+9F71, U+9F73, U+9F7A, U+9F7D, U+9F8F, U+9F91-9F92, U+9F96, U+9F9E, U+9FA1, U+9FA3, U+9FA5, U+25AD7, U+2626A, U+2D544;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold082.woff2") format("woff2");
  unicode-range: U+5077, U+50F0, U+5167, U+5174, U+5179, U+51D4, U+51DF, U+5226, U+522B, U+5389, U+53C1, U+53C4, U+5433, U+543F, U+544C, U+5527, U+55EC, U+55EE, U+55F1, U+55F8, U+5620, U+575F, U+57AE, U+586D, U+5898, U+591F, U+5939, U+59EC, U+5A1B, U+5B76, U+5B82, U+5C14, U+5C19, U+5C2A, U+5CE6, U+5DFF, U+5E99, U+5F3A, U+5F65, U+6164, U+617D, U+61E8, U+6231, U+6236, U+631B, U+65BE, U+65E3, U+66B3, U+6725, U+6735, U+6743, U+6780, U+67A4, U+67BE, U+67FA, U+6805, U+688E, U+6986, U+6A54, U+6AF8, U+6B72, U+6CAC, U+6D01, U+6E42, U+6E89, U+6EBC, U+6EDA-6EDB, U+6EE6, U+6F9D, U+6FB7, U+71F4, U+73BA, U+73E4, U+7522, U+764E, U+76EB, U+77B7, U+7928, U+7942, U+7A05, U+7ABD, U+7B53, U+7C2F, U+7C42, U+7C7B, U+7D55, U+7DD0, U+7E48, U+80F7, U+812B, U+8141, U+8173, U+81EF, U+820B, U+8346, U+835A, U+85F4, U+867D, U+8722, U+878E, U+886E, U+88C6, U+88E0, U+8A01, U+8AAA, U+8B0F, U+8C9F, U+8CD2, U+8D1C, U+8E75, U+8E77, U+8FA2, U+8FC8, U+90C9, U+92B3, U+93BB, U+95B1, U+95E8, U+9839, U+99E1, U+9A08, U+9ABD, U+9B1D, U+9B2C, U+9C61, U+9FB4, U+9FBC-9FC2, U+9FCC, U+F909, U+F91F, U+F95F, U+F983, U+F999-F99A, U+F9A2, U+F9C3, U+FA03, U+2008A, U+200B0, U+200F5, U+2053F, U+205B1, U+20611, U+206EC, U+207C8, U+2090E, U+20984, U+20A64, U+20D58, U+21A1A, U+22609, U+22E42, U+22FEB, U+237F1, U+23CBE, U+2404B, U+243D0, U+24E04, U+24FF2, U+2567F, U+25874, U+259CC, U+26222, U+26999, U+26C9E, U+279B4, U+27C3C, U+27D73, U+28CDD, U+28E17, U+28EF6, U+29D4B, U+2A2B2, U+2A9E6, U+2B746, U+2B751, U+2B753, U+2B75A, U+2B75C, U+2B765, U+2B776-2B777, U+2B77C, U+2B782, U+2B789, U+2B78B, U+2B78E, U+2B794, U+2B7AC, U+2F82C;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold083.woff2") format("woff2");
  unicode-range: U+2B7AF, U+2B7BD, U+2B7C9, U+2B7CF, U+2B7D2, U+2B7D8, U+2B7F0, U+2B80D, U+2B817, U+2B81A, U+2E278, U+2E569, U+2E6EA, U+2F804, U+2F80F, U+2F815, U+2F81A, U+2F822, U+2F828, U+2F833, U+2F83F, U+2F846, U+2F852, U+2F862, U+2F86D, U+2F873, U+2F884, U+2F899-2F89A, U+2F8A6, U+2F8AC, U+2F8B2, U+2F8B6, U+2F8D3, U+2F8E1, U+2F8E5, U+2F8EA, U+2F8FC, U+2F903, U+2F90B, U+2F90F, U+2F91A, U+2F920-2F921, U+2F945, U+2F947, U+2F96C, U+2F995, U+2F9D0, U+2F9DE-2F9DF, U+2F9F4;
}

@font-face {
  font-family: OT-PUDShinMGoPr6N-Bold;
  src: url("https://tomosplus.jp/fonts/OT-PUDShinMGoPr6N-Bold084.woff2") format("woff2");
  unicode-range: U+F860-F862, U+F87A, U+F87F;
}

#app {
  background: #fff;
  justify-content: center;
  display: flex;
}

#app .password-reset-input, #app .password-reset-url, #app .password-reset-url-send, #app .password-reset, #app .password-reset-done, #app, #app .teacher-help {
  height: 100%;
  overflow: hidden;
}

::-ms-reveal {
  display: none;
}

.error {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
  position: relative;
}

.error .overlap {
  height: 1080px;
  position: relative;
}

.error .div {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
}

.error .dots {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.error .img {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.error .dots-2 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.error .dots-3 {
  width: 204px;
  height: 71px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.error .view {
  width: 1100px;
  height: 600px;
  position: absolute;
  top: 390px;
  left: 300px;
}

.error .overlap-group {
  width: 1100px;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
}

.error .main-header div {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  line-height: 82px;
  display: table-cell;
}

.error .main-header {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.2px;
  text-align: center;
  width: 100%;
  height: 170px;
  font-size: 44px;
  font-weight: 400;
  line-height: 82px;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .error .main-header {
    font-size: 2.75rem;
  }
}

.error .sub-button {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  margin: auto;
  top: 607px;
  left: 0;
  right: 0;
  box-shadow: 0 2px 6px #000000b2;
  width: 490px !important;
  height: 110px !important;
  position: absolute !important;
}

.error .sub-button .text-wrapper-back {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -2px;
  text-align: center;
  white-space: nowrap;
  width: 471px;
  font-size: 40px;
  line-height: normal;
  top: 21px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .error .sub-button .text-wrapper-back {
    font-size: 2rem;
  }
}

.error .returnmsg {
  font-family: var(--main-font-family-m);
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-size: 2rem;
  position: absolute;
  top: 607px;
  left: 0;
  right: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .error .returnmsg {
    font-size: 2rem;
  }
}

.error .close-icon-circle {
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  margin: -5px 0 0;
  padding: 0;
}

.error .close-icon {
  width: 30px;
  height: 30px;
  margin: 10px;
  position: relative;
}

.error .close-text {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -2px;
  white-space: nowrap;
  margin-left: 20px;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .error .close-text {
    font-size: 2.5rem;
  }
}

.error .button-container {
  width: 490px;
  height: 110px;
  margin: auto;
  position: absolute;
  inset: 100px 0 0;
}

.error .sub-button-back-2 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 490px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 6px #000000b2;
}

.error .sub-button-back-2:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  box-shadow: 0 2px 6px #000000b2;
}

.error .sub-button-back-2:active {
  box-shadow: none;
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
}

.error .sub-button-text {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 2.4rem;
}

.MuiButton-root.sub-button {
  color: var(--x-5r-3xf-s);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.5px;
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 250px;
  height: 70px;
  font-size: 30px;
  position: relative;
  box-shadow: 0 1px 3px #000000b2;
}

.kanji-wrapper {
  position: relative;
}

.kanji-wrapper .kanji-kana {
  letter-spacing: 0;
  width: max-content;
  font-size: .5em;
  line-height: 1;
  display: block;
  position: absolute;
  top: -1.2em;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .kanji-wrapper .kanji-kana {
    top: -1.5em;
  }
}

.logout {
  background-color: #d9d9d9;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.logout .overlap-wrapper-11 {
  background-color: #d9d9d9;
  width: 1690px;
  height: 1080px;
}

.logout .overlap-58 {
  background-color: #d9d9d9;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.logout .message-2 {
  margin: auto;
  position: absolute;
  inset: 0;
  width: 780px !important;
}

.logout .overlap-61 {
  flex-direction: column;
  align-items: center;
  width: 780px;
  display: flex;
  position: relative;
}

.logout .text-wrapper-327 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  font-size: 40px;
  line-height: 70px;
  top: 0;
  left: 0;
  position: relative !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-327 {
    font-size: 2.3em;
  }
}

.logout .text-wrapper-327-2 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: 70px;
  top: 0;
  left: 0;
  position: relative !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-327-2 {
    font-size: 2.3em;
  }
}

.logout .text-wrapper-327-3 {
  color: #333;
  font-family: var(--main-font-family-db);
  border: 2px solid #fff;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 20px;
  line-height: 1.7;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-327-3 {
    font-size: 1.4rem;
  }
}

.logout .text-wrapper-327-3 p {
  white-space: nowrap;
}

.logout .text-wrapper-327-3 ul {
  margin-left: 2em;
  list-style: disc;
}

.logout .text-wrapper-327-3 ul li span {
  padding: 0 .5rem;
  font-family: Courier New, Courier, monospace;
  font-weight: bold;
}

.logout .text-wrapper-328 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 222px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-328 {
    font-size: 1rem;
  }
}

.logout .div-23 {
  color: #333;
  font-family: var(--main-font-family-rg);
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 333px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .div-23 {
    font-size: 1rem;
  }
}

.logout .text-wrapper-329 {
  letter-spacing: -1.6px;
}

.logout .text-wrapper-330 {
  letter-spacing: -2.4px;
}

.logout .div-24 {
  color: #333;
  font-family: var(--main-font-family-rg);
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 377px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .div-24 {
    font-size: 1rem;
  }
}

.logout .text-wrapper-331 {
  letter-spacing: -4.16px;
}

.logout .text-wrapper-332 {
  letter-spacing: -2.88px;
}

.logout .button-wrapper {
  position: absolute;
  top: 588px;
  left: 755px;
}

.MuiButton-root.main-button {
  color: #fff;
  text-transform: none;
  font-family: var(--main-font-family-m);
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 290px;
  height: 110px;
  font-size: 40px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .MuiButton-root.main-button {
    font-size: 2.5rem;
  }
}

.license-apply-form {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.license-apply-form .overlap-wrapper-13 {
  background-color: #fafafa;
  width: 1690px;
  height: 1982px;
}

.license-apply-form .overlap-64 {
  height: 1982px;
  position: relative;
}

.license-apply-form .no-BG-2 {
  background-color: var(--white);
  width: 1690px;
  height: 1982px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.license-apply-form .group-203 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: -69px;
  left: 128px;
}

.license-apply-form .frame-32 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.license-apply-form .frame-33 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.license-apply-form .ellipse-22 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.license-apply-form .group-204 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1624px;
  left: 1356px;
}

.license-apply-form .group-205 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 246px;
  left: 1355px;
}

.license-apply-form .group-206 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1189px;
  left: -40px;
}

.license-apply-form .group-207 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1792px;
  left: 303px;
}

.license-apply-form .main-button-9 {
  position: relative;
  top: 0;
  left: 250px;
  width: 252px !important;
  height: 70px !important;
}

.license-apply-form .text-wrapper-3472 {
  font-size: 31px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-3472 {
    font-size: 1.9375rem;
  }
}

.license-apply-form .text-wrapper-347 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 324px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-347 {
    font-size: 1.375rem;
  }
}

.license-apply-form .main-form {
  width: 1223px;
  height: 1335px;
  position: absolute;
  top: 518px;
  left: 233px;
}

.license-apply-form .block-memo {
  width: 1154px;
  height: 200px;
  position: relative;
  top: 330px;
  left: 69px;
}

.license-apply-form .group-208 {
  width: 52px;
  height: 19px;
  position: absolute;
  top: 38px;
  left: 0;
}

.license-apply-form .text-wrapper-348 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-348 {
    font-size: 1.375rem;
  }
}

.license-apply-form .text-wrapper-3484 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 245px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-3484 {
    font-size: 1.375rem;
  }
}

.license-apply-form .group-209 {
  width: 928px;
  height: auto;
  position: absolute;
  top: -4px;
  left: 259px;
}

.license-apply-form .group-210 {
  height: 200px;
}

.license-apply-form .group-211 {
  width: 858px;
  height: 200px;
}

.license-apply-form .overlap-group-60 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 878px;
  height: 220px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-37 {
  background-color: #fff;
  border-radius: 16px;
  width: 858px;
  height: 200px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .block-textbook {
  width: 1223px;
  height: 440px;
  position: relative;
  top: 300px;
  left: 0;
}

.license-apply-form .label-textbook-extra {
  width: 125px;
  height: 99px;
  position: absolute;
  top: 258px;
  left: 69px;
}

.license-apply-form .group-212 {
  width: 125px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .group-213 {
  width: 127px;
  height: 19px;
  position: relative;
}

.license-apply-form .group-214 {
  width: 125px;
  height: 19px;
  position: absolute;
  top: 80px;
  left: 0;
}

.license-apply-form .row-textbook {
  width: 858px;
  height: 55px;
  position: absolute;
  top: 320px;
  left: 365px;
}

.license-apply-form .input-textbook-count {
  width: 304px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 554px;
}

.license-apply-form .div-28 {
  width: 146px;
  height: 28px;
  position: absolute;
  top: 14px;
  left: 0;
}

.license-apply-form .div-282 {
  width: 146px;
  height: 28px;
  position: absolute;
  top: 47px;
  left: 524px;
}

.license-apply-form .group-215 {
  width: 50px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .text-wrapper-349 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: -9px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-349 {
    font-size: 1.275rem;
  }
}

.license-apply-form .text-wrapper-3491 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: -10px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-3491 {
    font-size: 1.275rem;
  }
}

.license-apply-form .text-wrapper-3493 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 5px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-3493 {
    font-size: 1.275rem;
  }
}

.license-apply-form .group-216 {
  width: 178px;
  height: 55px;
  position: absolute;
  top: 13px;
  left: 717px;
}

.license-apply-form .group-216 .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .group-216 .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .group-217 {
  height: 55px;
}

.license-apply-form .overlap-group-61 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 160px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-38 {
  background-color: #fff;
  border-radius: 16px;
  width: 140px;
  height: 55px;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .frame-34 {
  align-items: center;
  gap: 10px;
  width: 140px;
  padding: 10px 25px;
  display: flex;
  position: absolute;
  top: 19px;
  left: 10px;
}

.license-apply-form .text-wrapper-350 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 20px;
  line-height: 26px;
  position: relative;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-350 {
    font-size: 1.25rem;
  }
}

.license-apply-form .select-textbook-4 {
  width: 479px;
  height: 55px;
  position: absolute;
  top: 13px;
  left: -3px;
}

.license-apply-form .select-textbook-4 .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .select-textbook-4 .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .overlap-group-62 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 490px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-39 {
  background-color: #fff;
  border-radius: 16px;
  width: 470px;
  height: 55px;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .vector-35 {
  width: 18px;
  height: 11px;
  position: absolute;
  top: 32px;
  left: 444px;
}

.license-apply-form .frame-35 {
  align-items: center;
  gap: 10px;
  width: 420px;
  padding: 10px 25px;
  display: flex;
  position: absolute;
  top: 19px;
  left: 10px;
}

.license-apply-form .row-textbook-2 {
  width: 858px;
  height: 55px;
  position: absolute;
  top: 240px;
  left: 365px;
}

.license-apply-form .row-textbook-3 {
  width: 858px;
  height: 55px;
  position: absolute;
  top: 160px;
  left: 365px;
}

.license-apply-form .row-textbook-4 {
  width: 858px;
  height: 55px;
  position: absolute;
  top: 80px;
  left: 365px;
}

.license-apply-form .div-29 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: -19px;
  left: 347px;
}

.license-apply-form .div-29 .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .div-29 .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .group-218 {
  background-color: var(--gold-red);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 28px;
  display: flex;
  position: absolute;
  top: 14px;
  left: 0;
}

.license-apply-form .group-218-2 {
  background-color: var(--gold-red);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 28px;
  display: flex;
  top: -1px;
  left: 0;
}

.license-apply-form .group-2183 {
  background-color: var(--gold-red);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 28px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .text-wrapper-351 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  text-align: center;
  white-space: nowrap;
  font-size: 15px;
  top: 7px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-351 {
    font-size: .9375rem;
  }
}

.license-apply-form .label-textbook-2 {
  width: 200px;
  height: 183px;
  position: absolute;
  top: 14px;
  left: 0;
}

.license-apply-form .text-wrapper-352 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 84px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-352 {
    font-size: 1.375rem;
  }
}

.license-apply-form .text-wrapper-353 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 164px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-353 {
    font-size: 1.375rem;
  }
}

.license-apply-form .text-wrapper-3533 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 405px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-3533 {
    font-size: 1.375rem;
  }
}

.license-apply-form .block-store {
  width: 1223px;
  height: 55px;
  position: relative;
  top: 250px;
  left: 0;
}

.license-apply-form .overlap-group-63 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 878px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-40 {
  background-color: #fff;
  border-radius: 16px;
  width: 858px;
  height: 55px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .div-30 {
  width: 171px;
  height: 28px;
  position: absolute;
  top: 15px;
  left: 0;
}

.license-apply-form .block-email-confirm {
  width: 1223px;
  height: 55px;
  position: relative;
  top: 200px;
  left: 0;
}

.license-apply-form .label-email-confirm {
  width: 347px;
  height: 28px;
  position: absolute;
  top: 14px;
  left: 0;
}

.license-apply-form .text-wrapper-354 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 4px;
  left: 69px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-354 {
    font-size: 1.375rem;
  }
}

.license-apply-form .block-email {
  width: 1223px;
  height: 55px;
  position: relative;
  top: 150px;
  left: 0;
}

.license-apply-form .label-email {
  width: 247px;
  height: 28px;
  position: absolute;
  top: 14px;
  left: 0;
}

.license-apply-form .block-tel {
  width: 1156px;
  height: 55px;
  position: relative;
  top: 100px;
  left: 69px;
}

.license-apply-form .block-tel .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .block-tel .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .input-tel {
  width: 897px;
  height: 55px;
  position: absolute;
  top: -19px;
  left: 276px;
}

.license-apply-form .rectangle-41 {
  background-color: #fff;
  border-radius: 16px;
  width: 858px;
  height: 55px;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .frame-36 {
  align-items: center;
  gap: 10px;
  width: 858px;
  padding: 10px 25px;
  display: flex;
  position: absolute;
  top: 19px;
  left: 10px;
}

.license-apply-form .label-tel-2 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 47px;
  position: absolute;
  top: 5px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .label-tel-2 {
    font-size: 1.375rem;
  }
}

.license-apply-form .block-person {
  width: 1223px;
  height: 55px;
  position: relative;
  top: 50px;
  left: 0;
}

.license-apply-form .group-219 {
  width: 52px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .overlap-group-64 {
  background-color: var(--gold-red);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 28px;
  display: flex;
  position: relative;
}

.license-apply-form .text-wrapper-355 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  text-align: center;
  white-space: nowrap;
  font-size: 15px;
  top: 7px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-355 {
    font-size: .9375rem;
  }
}

.license-apply-form .block-school {
  width: 1223px;
  height: 135px;
  position: relative;
  top: 0;
  left: 0;
}

.license-apply-form .input-group-school {
  width: 858px;
  height: 135px;
  position: absolute;
  top: 0;
  left: 365px;
}

.license-apply-form .input-school-alt {
  width: 901px;
  height: 55px;
  position: absolute;
  top: 61px;
  left: -21px;
}

.license-apply-form .select-school {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 431px;
  display: inline-flex;
  position: absolute;
  top: -21px;
  left: 416px;
}

.license-apply-form .select-school .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .select-school .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .group-220 {
  width: 424px;
  height: 55px;
  margin-right: -2px;
  position: relative;
}

.license-apply-form .overlap-group-65 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 442px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-42 {
  background-color: #fff;
  border-radius: 16px;
  width: 422px;
  height: 55px;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .text-wrapper-356 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  width: 344px;
  font-size: 20px;
  line-height: 26px;
  position: absolute;
  top: 30px;
  left: 35px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-356 {
    font-size: 1.25rem;
  }
}

.license-apply-form .vector-36 {
  width: 18px;
  height: 11px;
  position: absolute;
  top: 22px;
  left: 386px;
}

.license-apply-form .select-school-city {
  width: 230px;
  height: 55px;
  position: absolute;
  top: -21px;
  left: 169px;
}

.license-apply-form .select-school-city .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .select-school-city .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .select-school-prefecture {
  width: 168px;
  height: 55px;
  position: absolute;
  top: -21px;
  left: -18px;
}

.license-apply-form .select-school-prefecture .MuiTypography-root {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .select-school-prefecture .MuiTypography-root {
    font-size: 1rem;
  }
}

.license-apply-form .overlap-65 {
  height: 55px;
  position: relative;
}

.license-apply-form .group-221 {
  width: 223px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .group-222 {
  width: 223px;
  height: 55px;
}

.license-apply-form .overlap-group-66 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 243px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-43 {
  background-color: #fff;
  border-radius: 16px;
  width: 223px;
  height: 55px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .group-223 {
  width: 180px;
  height: 16px;
  position: absolute;
  top: 20px;
  left: 25px;
}

.license-apply-form .text-wrapper-357 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 20px;
  line-height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-357 {
    font-size: 1.25rem;
  }
}

.license-apply-form .vector-37 {
  width: 18px;
  height: 11px;
  position: absolute;
  top: 2px;
  left: 162px;
}

.license-apply-form .div-wrapper-3 {
  width: 163px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .overlap-66 {
  width: 165px;
  height: 55px;
  position: relative;
}

.license-apply-form .group-224 {
  width: 165px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.license-apply-form .overlap-67 {
  width: 163px;
  height: 55px;
  position: relative;
}

.license-apply-form .group-225 {
  width: 163px;
  height: 55px;
}

.license-apply-form .overlap-group-67 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 183px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.license-apply-form .rectangle-44 {
  background-color: #fff;
  border-radius: 16px;
  width: 163px;
  height: 55px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.license-apply-form .text-wrapper-358 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 20px;
  line-height: 26px;
  position: absolute;
  top: 20px;
  left: 25px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-358 {
    font-size: 1.25rem;
  }
}

.license-apply-form .vector-38 {
  width: 18px;
  height: 11px;
  position: absolute;
  top: 22px;
  left: 126px;
}

.license-apply-form .message-description-3 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  text-align: center;
  width: 827px;
  font-size: 22px;
  line-height: 35px;
  position: absolute;
  top: 180px;
  left: 431px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .message-description-3 {
    font-size: 1.125rem;
  }
}

.license-apply-form .message-description-4 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  border: 1px solid gray;
  border-radius: 1rem;
  width: 854px;
  padding: 1rem 2rem;
  font-size: 18px;
  line-height: 35px;
  position: absolute;
  top: 256px;
  left: 431px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .message-description-4 {
    font-size: 1.125rem;
  }
}

.license-apply-form .message-description-4 ul {
  margin-left: 18px;
  line-height: 1.5;
  list-style: disc;
}

.license-apply-form .title-7 {
  width: 760px;
  height: 62px;
  position: absolute;
  top: 74px;
  left: 465px;
}

.license-apply-form .view-58 {
  height: 62px;
}

.license-apply-form .group-226 {
  width: 762px;
  height: 62px;
  position: relative;
}

.license-apply-form .text-wrapper-359 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  font-size: 36px;
  line-height: 20px;
  position: absolute;
  top: 32px;
  left: 218px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-359 {
    font-size: 1.875em;
  }
}

.license-apply-form .instance-14 {
  width: 193px !important;
  height: 62px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.license-apply-form .text-wrapper-360 {
  color: #000;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  line-height: normal;
  position: absolute;
  top: -40px;
  left: auto;
  right: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .text-wrapper-360 {
    font-size: .80625em;
  }
}

.license-apply-form .div-textbook1 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: -19px;
  left: 347px;
}

.license-apply-form .div-textbook2 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: 62px;
  left: 347px;
}

.license-apply-form .div-textbook3 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: 143px;
  left: 347px;
}

.license-apply-form .div-textbook4 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: 224px;
  left: 347px;
}

.license-apply-form .div-textbook5 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: 305px;
  left: 347px;
}

.license-apply-form .div-textbook6 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: 383px;
  left: 347px;
}

.license-apply-form .sub-button-3 {
  color: #fff;
  font-family: var(--main-font-family-m);
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  order: 1;
  margin-left: 20px;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 0 2px 6px #000000b2;
  width: 252px !important;
  height: 70px !important;
}

.license-apply-form .sub-button-3:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  box-shadow: 0 2px 6px #000000b2;
}

.license-apply-form .sub-button-3:active {
  box-shadow: none;
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
}

.license-apply-form .main-button-8 {
  position: relative;
  top: 0;
  left: 0;
  width: 252px !important;
  height: 70px !important;
}

.license-apply-form .error-messages {
  font-family: var(--main-font-family-db);
  z-index: 10;
  width: 700px;
  margin-bottom: 30px;
  position: relative;
  top: 0;
  left: 360px;
}

.license-apply-form .error-message {
  color: red;
  font-size: 24px;
  line-height: 1.5;
  position: relative;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .license-apply-form .error-message {
    font-size: 1.1875rem;
  }
}

.license-apply-form .submit-button {
  padding-bottom: 2em;
  display: flex;
  position: relative;
  top: 450px;
  left: 355px;
}

.logout {
  background-color: #d9d9d9;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.logout .overlap-wrapper-11 {
  background-color: #d9d9d9;
  width: 1690px;
  height: 1080px;
}

.logout .overlap-58 {
  background-color: #d9d9d9;
  height: 1080px;
  position: relative;
}

.logout .message-2 {
  position: unset;
  justify-content: center;
  width: 594px;
  height: 213px;
  margin: auto;
  display: flex;
  inset: 0;
}

.logout .overlap-60 {
  width: 608px;
  height: 102px;
  position: relative;
}

.logout .text-wrapper-327 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  font-size: 40px;
  line-height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-327 {
    font-size: 2.3em;
  }
}

.logout .text-wrapper-328 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 222px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-328 {
    font-size: 1rem;
  }
}

.logout .div-23 {
  color: #333;
  font-family: var(--main-font-family-rg);
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 333px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .div-23 {
    font-size: 1rem;
  }
}

.logout .text-wrapper-329 {
  letter-spacing: -1.6px;
}

.logout .text-wrapper-330 {
  letter-spacing: -2.4px;
}

.logout .div-24 {
  color: #333;
  font-family: var(--main-font-family-rg);
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 377px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .div-24 {
    font-size: 1rem;
  }
}

.logout .text-wrapper-331 {
  letter-spacing: -4.16px;
}

.logout .text-wrapper-332 {
  letter-spacing: -2.88px;
}

.logout .button-wrapper {
  position: absolute;
  top: 588px;
  left: 600px;
}

.logout .sub-button-back-2 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 490px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 6px #000000b2;
}

.logout .sub-button-back-2:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  box-shadow: 0 2px 6px #000000b2;
}

.logout .sub-button-back-2:active {
  box-shadow: none;
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
}

.logout .text-wrapper-back {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -2px;
  text-align: center;
  white-space: nowrap;
  width: 471px;
  font-size: 40px;
  line-height: normal;
  top: 21px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout .text-wrapper-back {
    font-size: 2rem;
  }
}

.maintenance {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
}

.maintenance .overlap {
  height: 1080px;
  position: relative;
}

.maintenance .div {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
}

.maintenance .dots {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.maintenance .img {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.maintenance .dots-2 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.maintenance .dots-3 {
  width: 204px;
  height: 71px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.maintenance .sub-button {
  color: #fff;
  width: 498px;
  height: 110px;
  position: absolute;
  top: 755px;
  left: 600px;
}

.maintenance .overlap-group {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 490px;
  height: 110px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.maintenance .text-wrapper {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 2px;
  font-size: 40px;
}

.maintenance .group {
  width: 594px;
  height: 107px;
  position: absolute;
  top: 387px;
  left: 550px;
}

.maintenance .main-header {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 4px;
  text-align: center;
  white-space: nowrap;
  font-size: 80px;
  font-weight: 400;
  line-height: 56px;
  position: absolute;
  top: 42px;
  left: 0;
}

.maintenance .text-wrapper-5 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -3.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 34px;
  font-weight: 400;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 494px;
}

.maintenance .img-2 {
  width: 558px;
  height: 160px;
  position: absolute;
  top: 200px;
  left: 566px;
}

.maintenance .group-2 {
  width: 672px;
  height: 136px;
  position: absolute;
  top: 529px;
  left: 517px;
}

.maintenance .text-wrapper-6 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 2.3px;
  text-align: center;
  white-space: nowrap;
  font-size: 46px;
  font-weight: 400;
  line-height: 47px;
  position: absolute;
  top: 27px;
  left: 63px;
}

.maintenance .text-wrapper-7 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 316px;
}

.maintenance .text-wrapper-8 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 400;
  line-height: 47px;
  position: absolute;
  top: 110px;
  left: 0;
}

.parental-agreement-form {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.parental-agreement-form .overlap-2 {
  width: 1690px;
  position: relative;
}

.parental-agreement-form .no-BG-2 {
  background-color: var(--white);
  width: 1690px;
  height: 1625px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.parental-agreement-form .group-7 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: -55px;
  left: 128px;
}

.parental-agreement-form .frame-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.parental-agreement-form .frame-4 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.parental-agreement-form .ellipse-2 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.parental-agreement-form .group-8 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 992px;
  left: 1356px;
}

.parental-agreement-form .group-9 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 260px;
  left: 1355px;
}

.parental-agreement-form .group-10 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 702px;
  left: -40px;
}

.parental-agreement-form .group-11 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1250px;
  left: 303px;
}

.parental-agreement-form .group-12 {
  width: 790px;
  height: 40px;
  position: relative;
  top: 615px;
  left: 451px;
}

.parental-agreement-form .message-description-2 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 22px;
  line-height: 35px;
  position: absolute;
  top: 20px;
  left: 62px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .message-description-2 {
    font-size: 1.375rem;
  }
}

.parental-agreement-form .group-13 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.parental-agreement-form .overlap-group-4 {
  cursor: pointer;
  background: none;
  border-radius: 4px;
  width: 60px;
  height: 60px;
  position: relative;
  top: -10px;
  left: -10px;
}

.parental-agreement-form .rectangle-2 {
  background-color: var(--white);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.parental-agreement-form .vector {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 16px;
  left: 14px;
}

.parental-agreement-form .overlap-wrapper {
  width: 252px;
  height: 70px;
  position: relative;
  top: 600px;
  left: 720px;
}

.parental-agreement-form .overlap-3 {
  border: 1px solid;
  border-color: var(--white);
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border-radius: 16px;
  width: 250px;
  height: 70px;
  position: relative;
  box-shadow: 0 1px 3px #000000b2;
}

.parental-agreement-form .text-wrapper-7 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.5px;
  text-align: center;
  white-space: nowrap;
  font-size: 30px;
  line-height: normal;
  top: 5px;
  left: 45px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-7 {
    font-size: 1.875rem;
  }
}

.parental-agreement-form .text-wrapper-79 {
  color: var(--dark);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.5px;
  text-align: center;
  white-space: nowrap;
  font-size: 30px;
  line-height: normal;
  top: 5px;
  left: 45px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-79 {
    font-size: 1.875rem;
  }
}

.parental-agreement-form .main-form-2 {
  width: 1154px;
  height: 449px;
  position: relative;
  top: 450px;
  left: 268px;
}

.parental-agreement-form .block-store {
  width: 858px;
  height: 55px;
  position: absolute;
  top: 394px;
  left: 296px;
}

.parental-agreement-form .div-wrapper-2 {
  height: 55px;
}

.parental-agreement-form .group-14 {
  width: 858px;
  height: 55px;
}

.parental-agreement-form .group-14 .MuiStack-root {
  margin: 0;
}

.parental-agreement-form .overlap-group-5 {
  background-color: var(--gray);
  border-radius: 16px;
  width: 878px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.parental-agreement-form .rectangle-3 {
  background-color: var(--white);
  border-radius: 16px;
  width: 858px;
  height: 55px;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.parental-agreement-form .frame-5 {
  align-items: center;
  gap: 10px;
  width: 858px;
  padding: 10px 25px;
  display: flex;
  position: absolute;
  top: 19px;
  left: 10px;
}

.parental-agreement-form .text-wrapper-8 {
  color: var(--gray);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 20px;
  line-height: 26px;
  position: relative;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-8 {
    font-size: 1.25rem;
  }
}

.parental-agreement-form .block-email-confirm-2 {
  width: 1154px;
  height: 55px;
  position: absolute;
  top: 315px;
  left: 0;
}

.parental-agreement-form .group-wrapper-2 {
  width: 858px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 296px;
}

.parental-agreement-form .group-wrapper-2 .MuiStack-root {
  margin: 0;
}

.parental-agreement-form .text-wrapper-9 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 20px;
  line-height: 26px;
  position: relative;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-9 {
    font-size: 1.25rem;
  }
}

.parental-agreement-form .label-email-confirm-2 {
  width: 77px;
  height: 19px;
  position: absolute;
  top: 16px;
  left: 0;
}

.parental-agreement-form .text-wrapper-10 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-10 {
    font-size: 1.375rem;
  }
}

.parental-agreement-form .block-email-2 {
  width: 1154px;
  height: 55px;
  position: absolute;
  top: 210px;
  left: 0;
}

.parental-agreement-form .rectangle-4 {
  background-color: var(--white);
  border-radius: 16px;
  width: 858px;
  height: 55px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.parental-agreement-form .label-email-2 {
  width: 178px;
  height: 52px;
  position: absolute;
  top: 2px;
  left: 0;
}

.parental-agreement-form .text-wrapper-11 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-11 {
    font-size: 1.5rem;
  }
}

.parental-agreement-form .block-tel-2 {
  width: 1156px;
  height: 55px;
  position: absolute;
  top: 105px;
  left: 0;
}

.parental-agreement-form .label-tel-2 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  position: absolute;
  top: 16px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .label-tel-2 {
    font-size: 1.375rem;
  }
}

.parental-agreement-form .block-person-2 {
  width: 1154px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.parental-agreement-form .label-person-2 {
  width: 203px;
  height: 19px;
  position: absolute;
  top: 16px;
  left: 0;
}

.parental-agreement-form .text-wrapper-12 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-12 {
    font-size: 1.375rem;
  }
}

.parental-agreement-form .message-description-3 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  width: 848px;
  margin: auto;
  font-size: 22px;
  line-height: 35px;
  position: absolute;
  top: 245px;
  left: 0;
  right: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .message-description-3 {
    font-size: 1.1875rem;
  }
}

.parental-agreement-form .view-wrapper {
  width: 452px;
  height: 82px;
  margin: auto;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}

.parental-agreement-form .view-2 {
  height: 82px;
}

.parental-agreement-form .group-15 {
  width: 454px;
  height: 82px;
  position: relative;
}

.parental-agreement-form .text-wrapper-13 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  text-align: center;
  white-space: nowrap;
  font-size: 36px;
  line-height: 52.5px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-13 {
    font-size: 1.875rem;
  }
}

.parental-agreement-form .message-warning {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  white-space: nowrap;
  width: 759px;
  font-size: 24px;
  line-height: 56px;
  position: relative;
  top: 389px;
  left: 466px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .message-warning {
    font-size: 1.375rem;
  }
}

.parental-agreement-form .view-3 {
  width: 449px;
  height: 70px;
  position: relative;
  top: 475px;
  left: 604px;
}

.parental-agreement-form .overlap-4 {
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--);
  background: linear-gradient(#fafafa 0%, #e6e6e6 100%);
  border-radius: 16px;
  width: 483px;
  height: 70px;
  position: relative;
}

.parental-agreement-form .text-wrapper-14 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.5px;
  text-align: center;
  white-space: nowrap;
  font-size: 30px;
  position: absolute;
  top: 22px;
  left: 36px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-14 {
    font-size: 1.5rem;
  }
}

.parental-agreement-form .text-wrapper-15 {
  color: var(--black);
  font-family: var(--main-font-family-m);
  letter-spacing: -.51px;
  text-align: right;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 310px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper-15 {
    font-size: 1.0625rem;
  }
}

.parental-agreement-form .block-buttons-wrapper {
  width: 520px;
  height: 70px;
  position: absolute;
  top: 977px;
  left: 585px;
}

.parental-agreement-form .text-wrapper {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.5px;
  text-align: center;
  white-space: nowrap;
  font-size: 30px;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 62px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .parental-agreement-form .text-wrapper {
    font-size: 1.875rem;
  }
}

.parental-agreement-form .main-button {
  width: 250px;
  height: 70px;
  position: relative;
  top: 0;
  left: 0;
}

.parental-agreement-form .sub-button {
  width: 250px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 270px;
}

.mailpost-complete {
  z-index: 10;
  font-size: 30px;
  position: relative;
  top: 1000px;
  left: 600px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .mailpost-complete {
    font-size: 1.875rem;
  }
}

.pagettl {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  line-height: 34px;
}

@media screen and (width <= 767px) {
  .pagettl {
    font-size: 20px;
  }
}

.Txt_Box {
  color: #444;
  font-size: 16px;
  line-height: 1.5;
}

.Txt_Box p {
  margin-bottom: 40px;
}

.Txt_Box .bold {
  font-weight: bold;
}

.Txt_Box .right {
  text-align: right;
}

.Txt_Box p + ol {
  margin-top: -20px;
}

.Txt_Box ol {
  padding-left: 1.25em;
  list-style: auto;
}

.Txt_Box ol li {
  margin-bottom: 10px;
}

.Txt_Box ol ol {
  margin-top: 10px;
  margin-bottom: 40px;
  padding-left: 35px;
  list-style: none;
}

.Txt_Box ol ol li span.inner {
  width: 35px;
  margin-left: -35px;
  display: inline-block;
}

.form_txt {
  margin-bottom: 20px;
}

table.Confirm_list {
  color: #444;
  border-top: 1px solid #ccc;
}

table.Confirm_list th, table.Confirm_list td {
  vertical-align: top;
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  font-size: 18px;
  line-height: 32.4px;
  position: relative;
}

table.Confirm_list th {
  width: 143.66px;
}

@media screen and (width <= 767px) {
  table.Confirm_list th {
    border-bottom: none;
    padding-bottom: 0;
  }

  table.Confirm_list td {
    padding-top: 0;
  }

  table.Confirm_list th, table.Confirm_list td {
    font-size: 16px;
    line-height: 1.7;
    display: block;
  }
}

.MuiButton-root.agree-button {
  color: #000;
  text-transform: none;
  font-family: var(--main-font-family-m);
  background: linear-gradient(#fafafa 5.21%, #e5e5e5 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 483px;
  height: 70px;
  font-size: 40px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .MuiButton-root.agree-button {
    font-size: 2.5rem;
  }
}

.MuiButton-root.agree-button:hover {
  background: linear-gradient(#fffefe 5.21%, #f7f5f5 100%);
  box-shadow: 0 2px 6px #000000b2;
}

.MuiButton-root.agree-button:active {
  box-shadow: none;
  background: linear-gradient(#f6f6f6 5.21%, #dedede 100%);
}

.element-active-a-wrapper {
  width: 140px;
  height: 66px;
  display: flex;
  position: relative;
}

.element-active-a-wrapper .overlap {
  all: unset;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.element-active-a-wrapper .overlap .active-a {
  cursor: pointer;
  width: 140px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-active-a-wrapper .overlap .active-a .overlap-group {
  background-size: 100% 100%;
  align-items: center;
  width: 152px;
  height: 78px;
  display: flex;
  position: relative;
}

.element-active-a-wrapper .overlap .active-a .overlap-group .group {
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: relative;
  top: -2px;
  left: 15px;
}

.element-active-a-wrapper .overlap .active-a .overlap-group .group .img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.element-active-a-wrapper .overlap .active-a .overlap-group {
  background-image: url("rectangle-181.4825bc65.svg");
}

.element-active-a-wrapper .overlap .active-a:hover .overlap-group {
  background-image: url("rectangle-181-2.3ea0d6fd.svg");
}

.element-active-a-wrapper .overlap .active-a:hover .overlap-group .group .img:nth-of-type(2) {
  opacity: 0;
}

.element-active-a-wrapper .overlap .active-a:active {
  background-image: url("onpress1.9b3ec51b.png");
  background-size: 100% 100%;
  width: 139px;
}

.element-active-a-wrapper .overlap .active-a:active .overlap-group {
  background-image: none;
}

.element-active-a-wrapper .overlap .active-a:active .overlap-group .group .img:nth-of-type(2) {
  opacity: 1;
}

.element-active-a-wrapper .overlap .text-wrapper {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  font-size: 20px;
  position: relative;
  top: -2px;
  left: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .element-active-a-wrapper .overlap .text-wrapper {
    font-size: 1.1rem;
  }
}

.oLRtWW_container {
  justify-content: center;
  padding: 60px 60px 40px;
  display: flex;
}

.oLRtWW_main {
  background: var(--white);
  color: var(--letter-black);
  border-radius: 20px;
  flex-direction: column;
  width: 100%;
  max-width: 1450px;
  max-height: 980px;
  display: flex;
  overflow: hidden;
}

.oLRtWW_main:focus {
  outline: none;
}

.oLRtWW_header {
  background: var(--tomopla-color);
  color: var(--white);
  font-size: 40px;
  font-family: var(--main-font-family-db);
  letter-spacing: .05em;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  min-height: 96px;
  padding: 15px 15px 15px 50px;
  display: flex;
  position: relative;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .oLRtWW_header {
    font-size: 2.5rem;
  }
}

.oLRtWW_title {
  flex: 1;
}

.oLRtWW_close-button {
  cursor: pointer;
  align-items: center;
  column-gap: 5px;
  display: flex;
  position: absolute;
  right: 10px;
}

.oLRtWW_body {
  flex: 1;
  overflow-y: auto;
}

.password-reset {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.password-reset .overlap-wrapper-8 {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
}

.password-reset .overlap-44 {
  height: 1080px;
  position: relative;
}

.password-reset .div-16 {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.password-reset .group-129 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.password-reset .frame-17 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.password-reset .frame-18 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.password-reset .ellipse-17 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.password-reset .group-130 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.password-reset .group-131 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.password-reset .group-132 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.password-reset .main-button-7 {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 800px;
  height: 110px;
  position: absolute;
  top: 668px;
  left: 445px;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset .text-wrapper-250 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: -.5px;
  text-align: center;
  white-space: nowrap;
  width: 740px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 29px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-250 {
    font-size: 2rem;
  }
}

.password-reset .text-wrapper-251 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 239px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-251 {
    font-size: 1.1875rem;
  }
}

.password-reset .text-wrapper-252 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 279px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-252 {
    font-size: 1.1875rem;
  }
}

.password-reset .text-wrapper-253 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 320px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-253 {
    font-size: 1.1875rem;
  }
}

.password-reset .text-wrapper-254 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 361px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-254 {
    font-size: 1.1875rem;
  }
}

.password-reset .text-wrapper-255 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 518px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-255 {
    font-size: 1.1875rem;
  }
}

.password-reset .text-wrapper-256 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 557px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-256 {
    font-size: 1.1875rem;
  }
}

.password-reset .main-message-3 {
  width: 794px;
  height: 84px;
  position: absolute;
  top: 454px;
  left: 463px;
}

.password-reset .navbar-4 {
  width: 764px;
  height: 68px;
  position: absolute;
  top: 16px;
  left: 0;
}

.password-reset .URL-URL {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: .9px;
  text-align: center;
  width: 764px;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: -12px;
  left: 1px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .URL-URL {
    font-size: 1.5rem;
  }
}

.password-reset .text-wrapper-257 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 265px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-257 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-258 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 47px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-258 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-259 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 78px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-259 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-260 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 347px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-260 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-261 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 485px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-261 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-262 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 514px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-262 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-263 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 29px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 298px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-263 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-264 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 29px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 325px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-264 {
    font-size: .75rem;
  }
}

.password-reset .navbar-5 {
  width: 121px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 267px;
}

.password-reset .text-wrapper-265 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-265 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-266 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 82px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-266 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-267 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 29px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 32px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-267 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-268 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 29px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 59px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-268 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-269 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 403px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-269 {
    font-size: .75rem;
  }
}

.password-reset .text-wrapper-270 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 157px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-270 {
    font-size: .75rem;
  }
}

.password-reset .main-header-6 {
  width: 403px;
  height: 57px;
  position: absolute;
  top: 367px;
  left: 648px;
}

.password-reset .text-wrapper-271 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -21px;
  left: 264px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-271 {
    font-size: 1.25rem;
  }
}

.password-reset .text-wrapper-272 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -21px;
  left: 308px;
}

.password-reset .text-wrapper-273 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -21px;
  left: 353px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .text-wrapper-273 {
    font-size: 1.25rem;
  }
}

.password-reset .main-header-7 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.1px;
  text-align: center;
  white-space: nowrap;
  width: 395px;
  font-size: 42px;
  line-height: 56px;
  position: absolute;
  top: 13px;
  left: -2px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset .main-header-7 {
    font-size: 2.625rem;
  }
}

.password-reset .title-5 {
  background-image: url("20.7d5a46ab.svg");
  background-size: 100% 100%;
  width: 448px;
  height: 143px;
  position: absolute;
  top: 186px;
  left: 621px;
}

.password-reset-done {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.password-reset-done .overlap-wrapper-6 {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
}

.password-reset-done .overlap-41 {
  height: 1080px;
  position: relative;
}

.password-reset-done .div-13 {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.password-reset-done .group-119 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.password-reset-done .frame-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.password-reset-done .frame-14 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.password-reset-done .ellipse-15 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.password-reset-done .group-120 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.password-reset-done .group-121 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.password-reset-done .group-122 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.password-reset-done .sub-button-2 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 490px;
  height: 110px;
  position: absolute;
  top: 668px;
  left: 600px;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-done .text-wrapper-214 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -2px;
  text-align: center;
  white-space: nowrap;
  width: 471px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-214 {
    font-size: 2.5rem;
  }
}

.password-reset-done .navbar-3 {
  width: 930px;
  height: 83px;
  position: absolute;
  top: 455px;
  left: 388px;
}

.password-reset-done .overlap-group-37 {
  width: 914px;
  height: 68px;
  position: absolute;
  top: 15px;
  left: 0;
}

.password-reset-done .text-wrapper-215 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  text-align: center;
  width: 914px;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: -13px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-215 {
    font-size: 1.625rem;
  }
}

.password-reset-done .text-wrapper-216 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 26px;
  left: 186px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-216 {
    font-size: .6875rem;
    top: 23px;
    left: 180px;
  }
}

.password-reset-done .text-wrapper-217 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 26px;
  left: 212px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-217 {
    font-size: .6875rem;
    top: 23px;
    left: 208px;
  }
}

.password-reset-done .text-wrapper-218 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 511px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-218 {
    font-size: .6875rem;
    top: -9px;
  }
}

.password-reset-done .text-wrapper-219 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -2.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 536px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-219 {
    font-size: .6875rem;
    top: -9px;
  }
}

.password-reset-done .text-wrapper-220 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 403px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-220 {
    font-size: .6875rem;
    top: -9px;
  }
}

.password-reset-done .text-wrapper-221 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 430px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-221 {
    font-size: .6875rem;
    top: -9px;
  }
}

.password-reset-done .text-wrapper-222 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 457px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-222 {
    font-size: .6875rem;
    top: -9px;
  }
}

.password-reset-done .main-header-2 {
  width: 667px;
  height: 57px;
  position: absolute;
  top: 367px;
  left: 515px;
}

.password-reset-done .text-wrapper-223 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -20px;
  left: 330px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-223 {
    font-size: 1.25rem;
  }
}

.password-reset-done .text-wrapper-224 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -20px;
  left: 374px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .text-wrapper-224 {
    font-size: 1.25rem;
  }
}

.password-reset-done .main-header-3 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.1px;
  text-align: center;
  white-space: nowrap;
  width: 661px;
  font-size: 42px;
  line-height: 56px;
  position: absolute;
  top: 11px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-done .main-header-3 {
    font-size: 1.88125em;
  }
}

.password-reset-done .title-3 {
  background-image: url("22.39808330.svg");
  background-size: 100% 100%;
  width: 448px;
  height: 143px;
  position: absolute;
  top: 186px;
  left: 621px;
}

text-wrapper-11.password-reset-input {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.password-reset-input {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
}

.password-reset-input form {
  width: 1690px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.password-reset-input.loading {
  position: absolute;
}

.password-reset-input img.loading {
  width: 17.5%;
  height: 17.5%;
}

.password-reset-input .overlap-wrapper-7 {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
  overflow: hidden;
}

.password-reset-input .overlap-42 {
  height: 1080px;
  position: relative;
}

.password-reset-input .div-14 {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.password-reset-input .group-123 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.password-reset-input .frame-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.password-reset-input .frame-16 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.password-reset-input .ellipse-16 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.password-reset-input .group-124 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.password-reset-input .group-125 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.password-reset-input .group-126 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.password-reset-input .main-button-6 {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 290px;
  height: 110px;
  position: absolute;
  top: 853px;
  left: 700px;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-input .text-wrapper-225 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 268px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 10px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-225 {
    font-size: 2.5rem;
  }
}

.password-reset-input .text-wrapper-226 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 84px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-226 {
    font-size: 1.1875rem;
  }
}

.password-reset-input .text-wrapper-227 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 124px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-227 {
    font-size: 1.1875rem;
  }
}

.password-reset-input .text-wrapper-228 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: 56px;
  position: absolute;
  top: -9px;
  left: 165px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-228 {
    font-size: 1.1875rem;
  }
}

.password-reset-input .block-password {
  width: 912px;
  height: 107px;
  position: absolute;
  top: 704px;
  left: 391px;
}

.password-reset-input .input-password-2 {
  width: 900px;
  height: 55px;
  position: absolute;
  top: 52px;
  left: 4px;
}

.password-reset-input .group-127 {
  height: 55px;
}

.password-reset-input .group-128 {
  width: 900px;
  height: 55px;
}

.password-reset-input .overlap-group-38 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 920px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.password-reset-input .rectangle-23 {
  background-color: #fff;
  border-radius: 16px;
  width: 900px;
  height: 55px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.password-reset-input .text-wrapper-229 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 249px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-229 {
    font-size: .6875rem;
    top: -7px;
  }
}

.password-reset-input .text-wrapper-230 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 277px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-230 {
    font-size: .6875rem;
    top: -7px;
  }
}

.password-reset-input .text-wrapper-231 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-231 {
    font-size: .6875rem;
    top: -7px;
  }
}

.password-reset-input .label-password {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  white-space: nowrap;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: 3px;
  left: 3px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .label-password {
    font-size: 1.625rem;
  }
}

.password-reset-input .sub-button-3 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 160px;
  height: 55px;
  position: absolute;
  top: 623px;
  left: 1437px;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-input .text-wrapper-232 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 26px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 27px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-232 {
    font-size: 1.625rem;
  }
}

.password-reset-input .text-wrapper-233 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 34px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-233 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-234 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 53px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-234 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-235 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 79px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-235 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-236 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 106px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-236 {
    font-size: .6875rem;
  }
}

.password-reset-input .eye-icon {
  width: 46px;
  height: 40px;
  position: absolute;
  top: 67px;
  right: 19px;
}

.password-reset-input .block-password-2 {
  width: 912px;
  height: 107px;
  position: absolute;
  top: 569px;
  left: 391px;
}

.password-reset-input .input-password-3 {
  width: 900px;
  height: 55px;
  position: absolute;
  top: 53px;
  left: 4px;
}

.password-reset-input .message-warning-3 {
  width: 908px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 497px;
  left: 0;
  right: 0;
}

.password-reset-input .text-wrapper-237 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 201px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-237 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-238 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 182px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-238 {
    font-size: .6875rem;
  }
}

.password-reset-input .message-warning-RHF {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-size: 24px;
  line-height: 39px;
  position: absolute;
  top: -1px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .message-warning-RHF {
    font-size: 1.375rem;
  }
}

.password-reset-input .message-warning-4 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  white-space: nowrap;
  width: 377px;
  font-size: 24px;
  line-height: 56px;
  position: absolute;
  top: -2px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .message-warning-4 {
    font-size: 1.375rem;
  }
}

.password-reset-input .main-message-2 {
  width: 778px;
  height: 83px;
  position: absolute;
  top: 390px;
  left: 463px;
}

.password-reset-input .overlap-43 {
  width: 764px;
  height: 68px;
  position: absolute;
  top: 15px;
  left: 0;
}

.password-reset-input .text-wrapper-239 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  text-align: center;
  width: 764px;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: -13px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-239 {
    font-size: 1.625rem;
  }
}

.password-reset-input .text-wrapper-240 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: -1.92px;
  text-align: center;
  white-space: nowrap;
  width: 33px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 391px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-240 {
    font-size: .6875rem;
    top: 25px;
  }
}

.password-reset-input .div-15 {
  color: #333;
  font-family: var(--main-font-family-db);
  text-align: center;
  white-space: nowrap;
  width: 30px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 421px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .div-15 {
    font-size: .6875rem;
    top: 25px;
  }
}

.password-reset-input .text-wrapper-241 {
  letter-spacing: -3.36px;
}

.password-reset-input .text-wrapper-242 {
  letter-spacing: -2.88px;
}

.password-reset-input .text-wrapper-243 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 147px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-243 {
    font-size: .6875rem;
    top: 25px;
  }
}

.password-reset-input .text-wrapper-244 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 370px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-244 {
    font-size: .6875rem;
    top: -7px;
  }
}

.password-reset-input .text-wrapper-245 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 398px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-245 {
    font-size: .6875rem;
    top: -7px;
  }
}

.password-reset-input .text-wrapper-246 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 447px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-246 {
    font-size: .6875rem;
    top: -7px;
  }
}

.password-reset-input .main-header-4 {
  width: 403px;
  height: 59px;
  position: absolute;
  top: 300px;
  left: 648px;
}

.password-reset-input .text-wrapper-247 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -19px;
  left: 264px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-247 {
    font-size: 1.25rem;
  }
}

.password-reset-input .text-wrapper-248 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -19px;
  left: 308px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-248 {
    font-size: 1.25rem;
  }
}

.password-reset-input .text-wrapper-249 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -19px;
  left: 353px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-249 {
    font-size: 1.25rem;
  }
}

.password-reset-input .main-header-5 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.1px;
  text-align: center;
  white-space: nowrap;
  width: 395px;
  font-size: 42px;
  line-height: 56px;
  position: absolute;
  top: 16px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .main-header-5 {
    font-size: 1.88125em;
  }
}

.password-reset-input .title-4 {
  background-image: url("21.9b4ff508.svg");
  background-size: 100% 100%;
  width: 448px;
  height: 143px;
  position: absolute;
  top: 126px;
  left: 621px;
}

.password-reset-input .text-wrapper-11 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 208px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-11 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-12 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 464px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-12 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-21 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 179px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-21 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-22 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 444px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-22 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-23 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 516px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-23 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-31 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 224px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-31 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-32 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 418px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-32 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-41 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: .5px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 122px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-41 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-42 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 366px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-42 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-51 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 38px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-51 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-52 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 316px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-52 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-53 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 541px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-53 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-54 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 674px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-54 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-61 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 158px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-61 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-62 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 389px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-62 {
    font-size: .6875rem;
  }
}

.password-reset-input .text-wrapper-63 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -.5px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 506px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-input .text-wrapper-63 {
    font-size: .6875rem;
  }
}

.rubi {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
}

.text-wrapper-71 {
  letter-spacing: 0;
  top: 0;
  left: 68px;
}

.text-wrapper-72 {
  letter-spacing: -.5px;
  top: 0;
  left: 222px;
}

.text-wrapper-73 {
  letter-spacing: 1px;
  top: 0;
  left: 485px;
}

.text-wrapper-74 {
  letter-spacing: 1px;
  top: 0;
  left: 706px;
}

.text-wrapper-75 {
  letter-spacing: 1px;
  top: 40px;
  left: 178px;
}

.text-wrapper-76 {
  letter-spacing: -.5px;
  top: 40px;
  left: 370px;
}

.text-wrapper-77 {
  letter-spacing: 1px;
  top: 40px;
  left: 478px;
}

.password-reset-url {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.password-reset-url .overlap-wrapper-9 {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
}

.password-reset-url .overlap-45 {
  height: 1080px;
  position: relative;
}

.password-reset-url .div-17 {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.password-reset-url .group-133 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.password-reset-url .frame-19 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.password-reset-url .frame-20 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.password-reset-url .ellipse-18 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.password-reset-url .group-134 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.password-reset-url .group-135 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.password-reset-url .group-136 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.password-reset-url .sub-button-back {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 490px;
  height: 110px;
  position: absolute;
  top: 668px;
  left: 600px;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-url .text-wrapper-274 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -2px;
  text-align: center;
  white-space: nowrap;
  width: 471px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-274 {
    font-size: 2.5rem;
  }
}

.password-reset-url .main-message-4 {
  width: 938px;
  height: 171px;
  position: absolute;
  top: 367px;
  left: 388px;
}

.password-reset-url .overlap-group-39 {
  width: 914px;
  height: 68px;
  position: absolute;
  top: 103px;
  left: 0;
}

.password-reset-url .URL {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  text-align: center;
  white-space: nowrap;
  width: 914px;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: -13px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .URL {
    font-size: 1.625rem;
  }
}

.password-reset-url .text-wrapper-275 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 39px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 656px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-275 {
    font-size: .75rem;
    top: 20px;
  }
}

.password-reset-url .text-wrapper-276 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 51px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 690px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-276 {
    font-size: .75rem;
    top: 20px;
  }
}

.password-reset-url .text-wrapper-277 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 35px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-277 {
    font-size: .75rem;
    top: 20px;
  }
}

.password-reset-url .text-wrapper-278 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 323px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-278 {
    font-size: .75rem;
    top: 77px;
  }
}

.password-reset-url .text-wrapper-279 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 350px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-279 {
    font-size: .75rem;
    top: 77px;
  }
}

.password-reset-url .text-wrapper-280 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 377px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-280 {
    font-size: .75rem;
    top: 77px;
  }
}

.password-reset-url .text-wrapper-281 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 405px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-281 {
    font-size: .75rem;
    top: 77px;
  }
}

.password-reset-url .text-wrapper-282 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 568px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-282 {
    font-size: .75rem;
    top: 77px;
  }
}

.password-reset-url .text-wrapper-283 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -19px;
  left: 524px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-283 {
    font-size: 1.25rem;
  }
}

.password-reset-url .text-wrapper-284 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -19px;
  left: 568px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-284 {
    font-size: 1.25rem;
  }
}

.password-reset-url .text-wrapper-285 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: -19px;
  left: 613px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .text-wrapper-285 {
    font-size: 1.25rem;
  }
}

.password-reset-url .main-header-8 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.1px;
  text-align: center;
  white-space: nowrap;
  width: 395px;
  font-size: 42px;
  line-height: 56px;
  position: absolute;
  top: 381px;
  left: 648px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url .main-header-8 {
    font-size: 2.625rem;
  }
}

.password-reset-url .instance-4 {
  width: 448px !important;
  height: 143px !important;
  position: absolute !important;
  top: 186px !important;
  left: 621px !important;
}

.password-reset-url-send {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.password-reset-url-send .password-reset-url-2 {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
}

.password-reset-url-send .overlap-46 {
  height: 1080px;
  position: relative;
}

.password-reset-url-send .div-18 {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.password-reset-url-send .group-137 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.password-reset-url-send .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.password-reset-url-send .frame-22 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.password-reset-url-send .ellipse-19 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.password-reset-url-send .group-138 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.password-reset-url-send .group-139 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.password-reset-url-send .group-140 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.password-reset-url-send .button-area {
  width: 800px;
  height: 110px;
  position: absolute;
  top: 744px;
  left: 445px;
}

.password-reset-url-send .sub-button-back-2 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 490px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 310px;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-url-send .sub-button-back-2:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-url-send .sub-button-back-2:active {
  box-shadow: none;
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
}

.password-reset-url-send .text-wrapper-286 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -2px;
  text-align: center;
  white-space: nowrap;
  width: 471px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-286 {
    font-size: 2rem;
  }
}

.password-reset-url-send .main-button-send {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 290px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 6px #000000b2;
}

.password-reset-url-send .text-wrapper-287 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 268px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 10px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-287 {
    font-size: 2rem;
  }
}

.password-reset-url-send .text-wrapper-288 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 98px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-288 {
    font-size: 1.1875rem;
  }
}

.password-reset-url-send .text-wrapper-289 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 152px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-289 {
    font-size: 1.1875rem;
  }
}

.password-reset-url-send .input-email-2 {
  width: 900px;
  height: 55px;
  position: absolute;
  top: 643px;
  left: 395px;
}

.password-reset-url-send .overlap-47 {
  height: 55px;
  position: relative;
}

.password-reset-url-send .group-141 {
  width: 900px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.password-reset-url-send .group-142 {
  height: 55px;
}

.password-reset-url-send .group-143 {
  width: 900px;
  height: 55px;
}

.password-reset-url-send .overlap-group-40 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 920px;
  height: 75px;
  position: relative;
  top: -10px;
  left: -10px;
}

.password-reset-url-send .rectangle-24 {
  background-color: #fff;
  border-radius: 16px;
  width: 900px;
  height: 55px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.password-reset-url-send .text-wrapper-290 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 22px;
  line-height: 26px;
  position: absolute;
  top: 18px;
  left: 25px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-290 {
    font-size: 1.375rem;
  }
}

.password-reset-url-send .message-warning-5 {
  justify-content: center;
  width: 900px;
  height: 35px;
  margin: auto;
  display: flex;
  position: absolute;
  top: 562px;
  left: 0;
  right: 0;
}

.password-reset-url-send .text-wrapper-291 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 208px;
}

.password-reset-url-send .text-wrapper-292 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 226px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-292 {
    font-size: .75rem;
  }
}

.password-reset-url-send .message-warning-6 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  white-space: nowrap;
  width: 377px;
  font-size: 24px;
  line-height: 56px;
  top: -2px;
  left: -29px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .message-warning-6 {
    font-size: 1.5rem;
  }
}

.password-reset-url-send .message-warning-61 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 24px;
  line-height: 56px;
  top: -2px;
  left: -88px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .message-warning-61 {
    font-size: 1.5rem;
  }
}

.password-reset-url-send .navbar-6 {
  width: 790px;
  height: 83px;
  position: absolute;
  top: 455px;
  left: 463px;
}

.password-reset-url-send .navbar-7 {
  width: 764px;
  height: 68px;
  position: absolute;
  top: 15px;
  left: 0;
}

.password-reset-url-send .URL-2 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  text-align: center;
  white-space: nowrap;
  width: 766px;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: -12px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .URL-2 {
    font-size: 1.16875rem;
  }
}

.password-reset-url-send .text-wrapper-293 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: -1.92px;
  text-align: center;
  white-space: nowrap;
  width: 33px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 322px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-293 {
    font-size: .75rem;
  }
}

.password-reset-url-send .div-19 {
  color: #333;
  font-family: var(--main-font-family-rg);
  text-align: center;
  white-space: nowrap;
  width: 30px;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 352px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .div-19 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-294x {
  letter-spacing: -3.36px;
}

.password-reset-url-send .text-wrapper-295x {
  letter-spacing: -2.88px;
}

.password-reset-url-send .text-wrapper-296 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 1px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-296 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-297 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 29px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-297 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-298 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 466px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-298 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-299 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 493px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-299 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-300 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 580px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-300 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-301 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 259px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-301 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-302 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 286px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-302 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-303 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 313px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-303 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-304 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 341px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-304 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-305 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 507px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-305 {
    font-size: .75rem;
  }
}

.password-reset-url-send .main-title {
  text-align: center;
  width: 500px;
  height: 57px;
  margin: auto;
  display: flex;
  position: absolute;
  top: 367px;
  left: 0;
  right: 0;
}

.password-reset-url-send .main-header-9 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.1px;
  white-space: nowrap;
  width: 595px;
  font-size: 42px;
  line-height: 56px;
  top: 15px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .main-header-9 {
    font-size: 2.375rem;
  }
}

.password-reset-url-send .overlap-48 {
  width: 41px;
  height: 16px;
  position: absolute;
  top: -19px;
  left: 264px;
}

.password-reset-url-send .text-wrapper-306 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-306 {
    font-size: 1.25rem;
  }
}

.password-reset-url-send .overlap-49 {
  width: 41px;
  height: 16px;
  position: absolute;
  top: -19px;
  left: 308px;
}

.password-reset-url-send .overlap-50 {
  width: 41px;
  height: 16px;
  position: absolute;
  top: -19px;
  left: 353px;
}

.password-reset-url-send .instance-21 {
  width: 448px !important;
  height: 143px !important;
  position: absolute !important;
  top: 186px !important;
  left: 621px !important;
}

.password-reset-url-send .text-wrapper-11 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 219px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-11 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-21 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 163px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-21 {
    font-size: .75rem;
  }
}

.password-reset-url-send .text-wrapper-22 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 237px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .password-reset-url-send .text-wrapper-22 {
    font-size: .75rem;
  }
}

.pre-login {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.pre-login .overlap-wrapper-3 {
  background-color: #fff;
  width: 1690px;
  height: 1080px;
}

.pre-login .overlap-7 {
  height: 1080px;
  position: relative;
}

.pre-login .div-3 {
  background-color: var(--white);
  width: 1690px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pre-login .frame-wrapper {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 228px;
  left: 1341px;
}

.pre-login .frame {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.pre-login .frame-2 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.pre-login .ellipse {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.pre-login .group-12 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 53px;
  left: 226px;
}

.pre-login .group-13 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 715px;
  left: 83px;
}

.pre-login .group-14 {
  width: 204px;
  height: 204px;
  position: absolute;
  top: 1009px;
  left: 1115px;
}

.pre-login .message-description-3 {
  width: 973px;
  height: 91px;
  position: absolute;
  top: 757px;
  left: 358px;
}

.pre-login .group-15 {
  width: 977px;
  height: 82px;
  position: absolute;
  top: 745px;
  left: 359px;
}

.pre-login .overlap-group-6 {
  width: 975px;
  height: 82px;
  position: relative;
}

.pre-login .div-4 {
  color: #0000;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.6px;
  text-align: center;
  font-size: 32px;
  line-height: 56px;
  position: absolute;
  top: -1px;
  left: -1px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pre-login .div-4 {
    font-size: 2rem;
  }
}

.pre-login .text-wrapper-37 {
  color: #333;
}

.pre-login a.text-wrapper-38 {
  color: #0a93f5;
  text-underline-offset: 7px;
  text-decoration: underline;
}

.pre-login .text-wrapper-39 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: -1.28px;
  text-align: center;
  white-space: nowrap;
  font-size: 12.8px;
  line-height: normal;
  position: absolute;
  top: 49px;
  left: 355px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pre-login .text-wrapper-39 {
    font-size: .8rem;
  }
}

.pre-login .line-4 {
  width: 93px;
  height: 2px;
  position: absolute;
  top: 90px;
  left: 491px;
}

.pre-login .block-buttons-2 {
  width: 700px;
  height: 260px;
  position: absolute;
  top: 411px;
  left: 495px;
}

.pre-login .overlap-8 {
  background-color: #fff;
  border: 10px solid #fff;
  border-radius: 16px;
  width: 720px;
  height: 280px;
  position: relative;
  top: -10px;
  left: -10px;
}

.pre-login .overlap-9 {
  width: 702px;
  height: 110px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.pre-login .group-16 {
  width: 657px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
}

.pre-login .accent-button {
  width: 715px;
  height: 110px;
  position: absolute;
  top: 0;
  left: -7px;
}

.pre-login .overlap-10 {
  background: linear-gradient(#ffe499 10%, #ffc423 90%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 700px;
  height: 110px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.pre-login .text-wrapper-40 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  width: 540px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 80px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pre-login .text-wrapper-40 {
    font-size: 2.5rem;
  }
}

.pre-login .main-button {
  width: 700px;
  height: 110px;
  position: absolute;
  top: 162px;
}

.pre-login .group-17 {
  height: 110px;
}

.pre-login .group-18 {
  width: 700px;
  height: 110px;
}

.pre-login .group-19 {
  width: 702px;
  height: 110px;
}

.pre-login .overlap-group-7 {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 700px;
  height: 110px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.pre-login .text-wrapper-41 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 119px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pre-login .text-wrapper-41 {
    font-size: 2.5rem;
  }
}

.pre-login .title {
  width: 614px;
  height: 110px;
  position: absolute;
  top: 219px;
  left: 538px;
}

.pre-login .view-2 {
  width: 224px;
  height: 57px;
  position: absolute;
  top: 42px;
  left: 390px;
}

.pre-login .group-20 {
  height: 57px;
}

.pre-login .group-21 {
  width: 226px;
  height: 57px;
  position: relative;
}

.pre-login .text-wrapper-42 {
  color: #e60012;
  font-family: var(--main-font-family-db);
  letter-spacing: 7px;
  white-space: nowrap;
  font-size: 70px;
  line-height: 70px;
  position: absolute;
  top: -3px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pre-login .text-wrapper-42 {
    font-size: 4.375rem;
  }
}

.pre-login .instance-22 {
  width: 344px !important;
  height: 110px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.pre-login .block-buttons {
  position: absolute;
  top: 402px;
  left: 484px;
}

.student-help {
  background-color: #fafafa;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.student-help .div-12 {
  background-color: #fafafa;
  width: 1690px;
  height: 1080px;
  position: relative;
}

.student-help .overlap-38 {
  width: 1421px;
  height: 667px;
  position: absolute;
  top: 174px;
  left: 160px;
}

.student-help .main-container {
  width: 1259px;
  height: 667px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .help-contents {
  width: 1259px;
  height: 381px;
  position: absolute;
  top: 286px;
  left: 0;
}

.student-help .block-QA {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 128px;
  left: 0;
}

.student-help .row-QA {
  width: 466.5px;
  height: 35px;
  position: relative;
}

.student-help .question {
  width: 466px;
  height: 35px;
  position: relative;
}

.student-help .group-96 {
  width: 430px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 36px;
}

.student-help .group-97 {
  width: 434px;
  height: 18px;
  position: relative;
}

.student-help .text-wrapper-157 {
  color: var(--letter-black);
  font-family: var(--main-font-family-b);
  letter-spacing: 1.1px;
  white-space: nowrap;
  font-size: 22px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .text-wrapper-158 {
  color: var(--letter-black);
  font-family: var(--main-font-family-b);
  letter-spacing: 1.1px;
  white-space: nowrap;
  font-size: 22px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 38px;
}

.student-help .frame-10 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .row-QA-2 {
  width: 1259px;
  height: 103px;
  position: relative;
}

.student-help .answer {
  width: 1223px;
  height: 48px;
  position: absolute;
  top: 55px;
  left: 36px;
}

.student-help .group-98 {
  height: 48px;
}

.student-help .group-99 {
  width: 1227px;
  height: 48px;
  position: relative;
}

.student-help .text-wrapper-159 {
  color: var(--letter-black);
  font-family: var(--main-font-family-r);
  letter-spacing: 0;
  width: 1188px;
  font-size: 22px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 35px;
}

.student-help .question-2 {
  width: 605px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .group-100 {
  width: 570px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 36px;
}

.student-help .group-101 {
  width: 574px;
  height: 18px;
  position: relative;
}

.student-help .question-wrapper {
  width: 535.5px;
  height: 35px;
  position: relative;
}

.student-help .question-3 {
  width: 536px;
  height: 35px;
  position: relative;
}

.student-help .group-102 {
  width: 499px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 36px;
}

.student-help .group-103 {
  width: 503px;
  height: 18px;
  position: relative;
}

.student-help .genre-title {
  background-color: #ffea00;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  display: inline-flex;
  position: absolute;
  top: 68px;
  left: 0;
}

.student-help .text-wrapper-160 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  line-height: 28px;
  position: relative;
}

.student-help .section-title {
  width: 293px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .group-104 {
  width: 225px;
  height: 29px;
  position: absolute;
  top: 10px;
  left: 68px;
}

.student-help .group-105 {
  width: 227px;
  height: 29px;
  position: relative;
}

.student-help .text-wrapper-161 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  white-space: nowrap;
  font-size: 36px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .group-106 {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .link-button {
  width: 460px;
  height: 70px;
  position: absolute;
  top: 116px;
  left: 0;
}

.student-help .group-107 {
  border: 1px solid;
  border-color: var(--white);
  background: linear-gradient(#fafafa 0%, #e6e6e6 100%);
  border-radius: 16px;
  height: 70px;
  box-shadow: 0 2px 6px #000000b2;
}

.student-help .group-108 {
  width: 245px;
  height: 35px;
  position: relative;
  top: 16px;
  left: 108px;
}

.student-help .group-109 {
  width: 27px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .text-wrapper-162 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 30px;
  position: absolute;
  top: 8px;
  left: 42px;
}

.student-help .main-message {
  width: 232px;
  height: 16px;
  position: absolute;
  top: 70px;
  left: 0;
}

.student-help .text-wrapper-163 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 20px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .main-header {
  width: 248px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .text-wrapper-164 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  white-space: nowrap;
  font-size: 36px;
  line-height: 30px;
  position: absolute;
  top: 11px;
  left: 58px;
}

.student-help .group-110 {
  width: 38px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .input-search-2 {
  width: 391px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 1030px;
}

.student-help .overlap-39 {
  width: 393px;
  height: 46px;
  position: relative;
}

.student-help .input-help-keyword {
  width: 393px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .overlap-group-32 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 407px;
  height: 62px;
  position: relative;
  top: -8px;
  left: -8px;
}

.student-help .rectangle-20 {
  background-color: #fff;
  border-radius: 16px;
  width: 391px;
  height: 46px;
  position: absolute;
  top: 8px;
  left: 8px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.student-help .text-wrapper-165 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  width: 293px;
  font-size: 24px;
  line-height: 38px;
  position: absolute;
  top: 22px;
  left: 24px;
}

.student-help .main-button-search {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 60px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 331px;
  box-shadow: 0 1.1px 3.29px #000000b2;
}

.student-help .vector-28 {
  width: 32px;
  height: 34px;
  position: absolute;
  top: 5px;
  left: 13px;
}

.student-help .student-top-bar {
  width: 1630px;
  height: 67px;
  position: absolute;
  top: 16px;
  left: 30px;
}

.student-help .line-5 {
  object-fit: cover;
  width: 1630px;
  height: 1px;
  position: absolute;
  top: 66px;
  left: 0;
}

.student-help .group-111 {
  width: 1490px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 142px;
}

.student-help .text-wrapper-166 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  font-size: 36px;
  line-height: 20px;
  position: absolute;
  top: 23px;
  left: 0;
}

.student-help .group-112 {
  width: 169px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 1094px;
}

.student-help .group-113 {
  background-color: #d9d9d9;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 119px;
}

.student-help .frame-11 {
  align-items: flex-end;
  gap: 12px;
  width: 106px;
  display: flex;
  position: absolute;
  top: 18px;
  left: 0;
}

.student-help .text-wrapper-167 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: .9px;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 18px;
  line-height: 18px;
  position: relative;
}

.student-help .vector-29 {
  width: 12px;
  height: 10.2px;
  position: relative;
}

.student-help .frame-12 {
  background-color: #d9d9d9;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
  height: 50px;
  padding: 44px 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1288px;
}

.student-help .text-wrapper-168 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .8px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -36.5px;
  margin-bottom: -34.5px;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.student-help .group-114 {
  width: 129px;
  height: 50px;
  position: absolute;
  top: 7px;
  left: 0;
}

.student-help .text-wrapper-169 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: .8px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 8px;
  left: 60px;
}

.student-help .rectangle-21 {
  background-color: #d9d9d9;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-help .scroll-bar {
  background-color: var(--line-color);
  border-radius: 10px;
  width: 9px;
  height: 62px;
  position: absolute;
  top: 98px;
  left: 1651px;
}

.pMQ83W_form.pMQ83W_student {
  height: 124px;
}

.pMQ83W_form.pMQ83W_student .pMQ83W_row {
  column-gap: 36px;
}

.pMQ83W_form .pMQ83W_items {
  flex-direction: column;
  row-gap: 15px;
  display: flex;
}

.pMQ83W_form > .pMQ83W_submit {
  position: absolute;
  top: 68px;
  left: 512px;
}

.pMQ83W_form .pMQ83W_time-slot {
  font-size: 18px;
  font-family: var(--main-font-family-m);
  letter-spacing: .02em;
  width: 115px;
  height: 28px;
  padding: 4px 9px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_form .pMQ83W_time-slot {
    font-size: 1rem;
  }
}

.pMQ83W_form .pMQ83W_time-slot > img {
  width: 15px;
  right: 10px;
}

.pMQ83W_conditions {
  flex-direction: column;
  display: flex;
}

.pMQ83W_conditions > :nth-child(2) {
  margin-top: 22px;
  margin-bottom: 11px;
}

.pMQ83W_row {
  align-items: center;
  column-gap: 20px;
  display: flex;
  position: relative;
}

.pMQ83W_row .pMQ83W_school-class-select {
  width: 220px;
}

.pMQ83W_row .pMQ83W_student-select {
  width: 340px;
  margin-left: 15px;
}

.pMQ83W_row .pMQ83W_student-search {
  background-color: #fff;
  width: 340px;
  margin-left: 45px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_row .pMQ83W_student-search {
    width: 378px;
  }
}

.pMQ83W_row .pMQ83W_time-slot {
  margin-left: 28px;
}

.pMQ83W_submit {
  white-space: nowrap;
  border-radius: 8px !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: 45px !important;
  padding: 6px 9px 9px !important;
  font-size: 22px !important;
  line-height: 1 !important;
  box-shadow: 0 1.05333px 3.16px #000000b2 !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_submit {
    font-size: 1.375rem !important;
  }
}

.pMQ83W_submit.pMQ83W_ruby {
  position: absolute;
  top: 70px;
  left: 505px;
  height: 47px !important;
}

.pMQ83W_submit:disabled, .pMQ83W_download:disabled {
  cursor: not-allowed !important;
  pointer-events: unset !important;
  box-shadow: 0 0 !important;
}

.pMQ83W_download {
  white-space: nowrap;
  letter-spacing: .05em !important;
  border-radius: 8px !important;
  width: 175px !important;
  height: 45px !important;
  font-size: 22px !important;
  line-height: 1 !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_download {
    font-size: 1.375rem !important;
  }
}

.pMQ83W_toggle-container {
  background: var(--gray);
  border-radius: 40px;
  align-items: center;
  column-gap: 5px;
  padding: 3px;
  display: flex;
}

.pMQ83W_toggle-container.pMQ83W_student {
  position: absolute;
  top: 68px;
  right: 0;
}

.pMQ83W_toggle-container.pMQ83W_student > button {
  align-items: flex-end;
  padding-bottom: 12px;
}

.pMQ83W_label-container {
  align-items: center;
  column-gap: 20px;
  display: flex;
}

.pMQ83W_label-container .pMQ83W_label {
  font-size: 24px;
  font-family: var(--main-font-family-m);
  letter-spacing: .1em;
  white-space: nowrap;
  line-height: 28.5px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_label-container .pMQ83W_label {
    font-size: 1.375rem;
  }
}

.pMQ83W_date-range-picker {
  align-items: center;
  column-gap: 8px;
  display: flex;
}

.pMQ83W_label-container.pMQ83W_ruby .pMQ83W_date-range-picker {
  margin-top: 8px;
}

.pMQ83W_date-range-picker > span {
  font-family: var(--main-font-family-db);
  letter-spacing: .002em;
  font-size: 18px;
  line-height: 25.65px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_date-range-picker > span {
    font-size: 1.125rem;
  }
}

.pMQ83W_date-range-error-message {
  color: var(--gold-red);
  font-size: 18px;
  font-family: var(--main-font-family-m);
  position: absolute;
  top: 100%;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_date-range-error-message {
    font-size: 1.125rem;
  }
}

.pMQ83W_student .pMQ83W_date-range-error-message {
  top: calc(100% - 20px);
}

.pMQ83W_date-picker {
  font-family: var(--main-font-family-db);
  letter-spacing: .005em;
  width: 132px;
  font-size: 16px;
  line-height: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_date-picker {
    font-size: 1rem;
  }
}

.pMQ83W_date-picker > div {
  height: 28px;
}

.pMQ83W_date-picker input {
  font-family: var(--main-font-family-db);
  letter-spacing: .005em;
  font-size: 16px;
  line-height: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .pMQ83W_date-picker input {
    font-size: 1rem;
  }
}

.pMQ83W_student-select {
  width: 340px;
}

.MuiButton-root.accent-button {
  color: #333;
  text-transform: none;
  font-family: var(--main-font-family-m);
  background: linear-gradient(#ffe499 10%, #ffc423 90%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 680px;
  min-height: 130px;
  font-size: 40px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.MuiButton-root.accent-button:hover {
  background: linear-gradient(#ffedbd 20.3%, #ffd86b 83.33%);
  box-shadow: 0 2px 6px #000000b2;
}

.MuiButton-root.accent-button:active {
  box-shadow: none;
  background: linear-gradient(#dba000 20.3%, #cc8914 84.9%);
}

.Sq0_0G_select-label-container {
  align-items: center;
  column-gap: 20px;
  display: flex;
}

.Sq0_0G_select-label-container .Sq0_0G_label {
  font-size: 24px;
  font-family: var(--main-font-family-db);
  letter-spacing: .1em;
  color: var(--letter-black);
  line-height: 28.5px;
}

.Sq0_0G_select {
  border: 1px solid var(--line-color);
  background: var(--white);
  color: var(--letter-black);
  font-size: 24px;
  font-family: var(--main-font-family-db);
  letter-spacing: .1em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 20;
  cursor: pointer;
  border-radius: 4px;
  width: 380px;
  height: 45px;
  padding: 3px 44px 4px 15px;
  line-height: 1;
  display: block;
  position: relative;
  overflow: hidden;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .Sq0_0G_select {
    font-size: 1.375rem;
  }
}

.Sq0_0G_select div .Sq0_0G_sm {
  font-size: small;
}

.Sq0_0G_select .Sq0_0G_toggle {
  position: absolute;
  top: 50%;
  right: 11.5px;
  transform: translateY(-50%);
}

.Sq0_0G_select .Sq0_0G_toggle.Sq0_0G_open {
  transform: translateY(-50%)rotate(180deg);
}

.Sq0_0G_select.Sq0_0G_disabled {
  opacity: .5;
  background: var(--gray);
  color: var(--letter-black);
  cursor: not-allowed;
}

.Sq0_0G_no-ruby {
  align-items: center;
  height: 52px;
  padding-top: 0;
  padding-bottom: 0;
}

.Sq0_0G_popper {
  border: 1px solid var(--line-color);
  background: var(--white);
  color: var(--letter-black);
  z-index: 15;
  font-size: 18px;
  font-family: var(--main-font-family-db);
  cursor: pointer;
  border-radius: 4px;
  margin: 12px 0;
  padding-right: 10px;
  overflow: hidden;
}

.Sq0_0G_popper[data-popper-placement^="top"] {
  bottom: 6px !important;
}

.Sq0_0G_popper[data-popper-placement^="bottom"] {
  top: 6px !important;
}

.Sq0_0G_listbox {
  font-size: 1.25em;
  font-family: var(--main-font-family-db);
  background-color: #fff;
  flex-direction: column;
  row-gap: 8px;
  max-height: 327px;
  padding: 10px 14px 10px 7px;
  display: flex;
  overflow: auto;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .Sq0_0G_listbox {
    font-size: 1rem;
  }
}

.Sq0_0G_listbox::-webkit-scrollbar {
  background-color: #0000;
  width: 9px;
}

.Sq0_0G_listbox::-webkit-scrollbar-track {
  background-color: #0000;
  margin: 6px 0;
}

.Sq0_0G_listbox::-webkit-scrollbar-thumb {
  background-color: var(--line-color);
  border-radius: 5px;
  max-height: 40px;
}

.Sq0_0G_listbox li:first-child {
  padding: 0 8px 6px;
}

.Sq0_0G_listbox li {
  padding: 6px 8px;
}

:is(.Sq0_0G_listbox li:hover, .Sq0_0G_listbox li.Mui-selected) {
  background-color: var(--light-blue-2);
  border-radius: 5.2px;
}

:is(.Sq0_0G_listbox li.base--disabled, .Sq0_0G_listbox li.Mui-disabled) {
  opacity: .5;
  background: var(--gray);
  color: var(--letter-black);
  cursor: not-allowed;
}

.HGVX4a_action-bar {
  background: var(--tomopla-color);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px 10px 18px;
  display: flex;
  position: relative;
}

.HGVX4a_action-bar > * {
  color: var(--white);
  flex: 1;
  font-size: 22px;
  line-height: 1;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .HGVX4a_action-bar > * {
    font-size: 1.375rem;
  }
}

.HGVX4a_action-bar .HGVX4a_current {
  text-align: center;
}

.HGVX4a_action-bar button {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  column-gap: 8px;
  padding: 0;
  display: flex;
}

.HGVX4a_action-bar .HGVX4a_current {
  position: relative;
}

.HGVX4a_action-bar .HGVX4a_current:after {
  content: "";
  background: var(--white);
  border-radius: 2px;
  width: 66px;
  height: 2px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.HGVX4a_action-bar button:first-child {
  text-align: left;
}

.HGVX4a_action-bar button:first-child img {
  height: 9px;
  transform: rotate(90deg);
}

.HGVX4a_action-bar button:last-child {
  justify-content: flex-end;
}

.HGVX4a_action-bar button:last-child img {
  height: 9px;
  transform: rotate(-90deg);
}

.HGVX4a_action-bar button:disabled {
  pointer-events: none;
}

.sFQLVq_calendar-header {
  z-index: 1;
  color: var(--white);
  background: var(--tomopla-color);
  border-radius: 20px 20px 0 0;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 27px;
  display: flex;
}

.sFQLVq_calendar-header button {
  cursor: pointer;
  background: none;
  border: none;
}

.sFQLVq_calendar-header > .sFQLVq_current {
  align-items: center;
  width: 106px;
  height: 100%;
  display: flex;
  position: relative;
}

.sFQLVq_calendar-header > .sFQLVq_current > button {
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 22px;
  display: flex;
  color: var(--white) !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sFQLVq_calendar-header > .sFQLVq_current > button {
    font-size: 1.375rem;
  }
}

.sFQLVq_calendar-header > .sFQLVq_current > button:hover, .sFQLVq_calendar-header > .sFQLVq_current > button.sFQLVq_open {
  background-color: var(--tomopla-color-mid);
}

.sFQLVq_calendar-header .sFQLVq_current span {
  font-size: 11px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sFQLVq_calendar-header .sFQLVq_current span {
    font-size: .6875rem;
  }
}

.sFQLVq_calendar-header .sFQLVq_month-select {
  z-index: 1;
  background: var(--white);
  letter-spacing: .05em;
  filter: drop-shadow(0 2.6px 3.8px #000000b3);
  clip-path: inset(0 0 100%);
  opacity: .5;
  flex-direction: column;
  align-items: center;
  padding: 6px 0 13px;
  line-height: 36.5px;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.sFQLVq_calendar-header .sFQLVq_month-select.sFQLVq_open {
  opacity: 1;
  clip-path: inset(-100%);
}

.sFQLVq_calendar-header .sFQLVq_month-select button {
  color: var(--letter-black);
  text-align: center;
  border-radius: 5px;
  width: 65px;
  font-size: 18px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sFQLVq_calendar-header .sFQLVq_month-select button {
    font-size: 1.125rem;
  }
}

.sFQLVq_calendar-header .sFQLVq_month-select button.sFQLVq_current {
  border: 2px solid var(--beta-blue);
  background-color: var(--light-blue-2);
}

.sFQLVq_calendar-header .sFQLVq_month-select button:hover {
  background-color: var(--light-blue-2);
}

.sFQLVq_calendar-header > button:first-child {
  left: 10px;
  transform: rotate(90deg);
}

.sFQLVq_calendar-header > button:last-child {
  right: 10px;
  transform: rotate(-90deg);
}

.sFQLVq_calendar-header > button:disabled {
  visibility: hidden;
}

.k6q9xa_text-field > div {
  color: var(--letter-black);
  padding-left: 10px;
  padding-right: 0;
}

.k6q9xa_text-field input, .k6q9xa_text-field > button {
  padding: 0;
}

.k6q9xa_text-field fieldset {
  border-radius: 4px;
  border: 1px solid var(--line-color) !important;
}

.k6q9xa_disabled {
  opacity: .5;
  background: var(--gray);
}

.k6q9xa_disabled button {
  color: var(--letter-black) !important;
}

.k6q9xa_icon-button:hover {
  background-color: #0000 !important;
}

.k6q9xa_icon-button > svg {
  width: 28px;
  height: 28px;
  margin: 0 16px;
}

.k6q9xa_popper * {
  letter-spacing: .05em;
  font-family: var(--main-font-family-db) !important;
}

.k6q9xa_popper .MuiDayCalendar-root {
  letter-spacing: .05em;
  font-family: var(--main-font-family-db) !important;
}

.k6q9xa_popper .MuiPaper-root {
  box-shadow: none;
  filter: drop-shadow(0 2.86px 3.58px #000000b3);
  background: none;
  margin-top: 5px;
  overflow: visible;
}

.k6q9xa_popper .MuiPickersLayout-root {
  background: none;
  display: block;
}

.k6q9xa_popper .MuiDateCalendar-root {
  max-height: unset;
  overflow: unset;
  width: 386px;
  height: -moz-fit-content;
  height: fit-content;
}

.k6q9xa_popper .MuiDayCalendar-root {
  background: var(--white);
  border-left: 2px solid var(--tomopla-color);
  border-right: 2px solid var(--tomopla-color);
}

.k6q9xa_popper .MuiDayCalendar-root * {
  color: var(--letter-black);
  font-size: 20px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .k6q9xa_popper .MuiDayCalendar-root * {
    font-size: 1.25rem !important;
  }
}

.k6q9xa_popper .MuiDayCalendar-header > * {
  width: 44px;
  height: 44px;
}

.k6q9xa_popper .MuiDayCalendar-header > :nth-child(6) {
  color: var(--beta-blue);
}

.k6q9xa_popper .MuiDayCalendar-header > :nth-child(7) {
  color: var(--gold-red);
}

.k6q9xa_popper .MuiPickersSlideTransition-root {
  min-height: 286px;
}

.k6q9xa_popper button.MuiPickersDay-root {
  width: 44px;
  height: 44px;
}

.k6q9xa_popper .MuiPickersDay-root.Mui-selected {
  border: 2px solid var(--beta-blue);
  color: var(--letter-black);
  background-color: var(--light-blue-2) !important;
}

.k6q9xa_popper .MuiPickersDay-root:hover {
  background-color: var(--light-blue-2) !important;
}

.k6q9xa_day-outside-month {
  color: var(--gray) !important;
}

.shkb-element-default-wrapper {
  background: linear-gradient(#e6f4ff 18.75%, #9fd4fa 64.06%);
  border: 2px solid #0a93f5;
  border-radius: 40px 4px 4px 40px;
  width: 196px;
  height: 50px;
  box-shadow: 0 2px 6px #000000b2;
}

.shkb-element-default-wrapper .overlap-group {
  height: 50px;
  position: relative;
}

.shkb-element-default-wrapper .overlap-group .group {
  width: 196px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.shkb-element-default-wrapper .overlap-group .group .rectangle {
  border-radius: 40px 4px 4px 40px;
  height: 50px;
}

.shkb-element-default-wrapper .overlap-group .div {
  font-family: var(--main-font-family-m);
  color: #0071bc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  display: flex;
}

.shkb-element-default-wrapper .overlap-group .div .text-wrapper {
  letter-spacing: -1.5px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  line-height: normal;
  top: 14px;
  left: 3px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .shkb-element-default-wrapper .overlap-group .div .text-wrapper {
    font-size: 1.125rem;
  }
}

.shkb-element-default-wrapper .overlap-group .div .text-wrapper-2 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: -1px;
}

.shkb-element-default-wrapper .overlap-group .div .text-wrapper-22 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 25px;
}

.shkb-element-default-wrapper:hover {
  background: linear-gradient(#f3faff 18.75%, #cce8fc 64.06%);
  border: 2px solid #6dc1fd;
}

.shkb-element-default-wrapper:active {
  box-shadow: none;
  background: linear-gradient(#004d80 45.31%, #008bc4 92.19%);
  border: 2px solid #0000;
}

.shkb-element-default-wrapper:active .div {
  color: #fff;
}

.shkb-element-default-wrapper.active {
  box-shadow: none;
  background: linear-gradient(#004d80 45.31%, #008bc4 92.19%);
  border: 2px solid #0000;
}

.shkb-element-default-wrapper.active .div {
  color: #fff;
}

.shtb-element-default-wrapper {
  color: #0071bc;
  background: linear-gradient(#e6f4ff 18.75%, #9fd4fa 64.06%);
  border: 2px solid #0a93f5;
  border-radius: 4px 40px 40px 4px;
  width: 196px;
  height: 50px;
  box-shadow: 0 2px 6px #000000b2;
}

.shtb-element-default-wrapper .overlap-group {
  border-radius: 4px 40px 40px 4px;
  height: 50px;
  position: relative;
}

.shtb-element-default-wrapper .overlap-group .rectangle {
  border-radius: 4px 40px 40px 4px;
  width: 196px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.shtb-element-default-wrapper .overlap-group .text-wrapper {
  font-family: var(--main-font-family-m);
  letter-spacing: -1px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  font-size: 18px;
  display: flex;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .shtb-element-default-wrapper .overlap-group .text-wrapper {
    font-size: 1.125rem;
    left: 4px;
  }
}

.shtb-element-default-wrapper:hover {
  background: linear-gradient(#f3faff 18.75%, #cce8fc 64.06%);
  border: 2px solid #6cc1fd;
}

.shtb-element-default-wrapper:active, .shtb-element-default-wrapper.active {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#008bc4 6.48%, #004d80 40.7%);
  border: 2px solid #0000;
}

.XhdPaW_container {
  border: 1px solid var(--line-color);
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  row-gap: 8px;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 45px;
  padding: 0 10px 0 16px;
  display: flex;
  position: relative;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .XhdPaW_container {
    min-height: 54px;
  }
}

.XhdPaW_container input {
  font-size: 24px;
  font-family: var(--main-font-family-db);
  letter-spacing: .1em;
  text-overflow: ellipsis;
  border: none;
  line-height: 1;
  overflow: hidden;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .XhdPaW_container input {
    all: unset;
    font-family: var(--main-font-family-m);
    font-size: 1.375rem;
  }
}

.XhdPaW_container input:focus {
  outline: none;
}

.XhdPaW_container input::placeholder {
  color: #666;
}

.XhdPaW_container button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 13px;
}

.XhdPaW_container button:disabled {
  cursor: not-allowed;
  filter: grayscale();
}

.XhdPaW_container input[value=""] + button {
  filter: grayscale();
}

.PDObtW_table-container {
  z-index: 0;
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  overflow: hidden;
}

.PDObtW_table-container ::-webkit-scrollbar {
  background-color: var(--gray);
  width: 10px;
}

.PDObtW_table-container ::-webkit-scrollbar-thumb {
  background-color: #828282;
  border-radius: 5px;
  min-height: 60px;
}

.PDObtW_table-scroll-container {
  border: #0000;
  width: 100%;
  display: flex;
  overflow: auto;
}

.PDObtW_table-infinite-scroll-container {
  border: #0000;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.PDObtW_table-infinite-scroll-container .PDObtW_wrapper {
  position: relative;
}

.PDObtW_table-infinite-scroll-container .PDObtW_marker {
  z-index: -1;
  position: absolute;
  top: calc(100% - 10000px);
  bottom: 0;
}

.PDObtW_table {
  border-collapse: separate;
  table-layout: fixed;
  font-family: var(--main-font-family-db);
}

.PDObtW_th-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 11px;
  right: 13px;
}

.PDObtW_clickable {
  cursor: pointer;
}

.PDObtW_sort-icon-container {
  margin-left: 12px;
}

.PDObtW_sort-icon {
  opacity: .5;
}

.PDObtW_sort-icon.PDObtW_asc {
  opacity: 1;
  margin-bottom: 2px;
  transform: rotate(180deg);
}

.PDObtW_sort-icon.PDObtW_desc {
  opacity: 1;
}

.img-wrapper {
  width: 518px;
  height: 165px;
  position: absolute;
  top: 0;
  left: 0;
}

.menu-modal {
  z-index: 30;
  background-color: #fff;
  border-radius: 16px;
  width: 305px;
  height: 416px;
  position: absolute;
  top: 75px;
  left: 1266px;
  box-shadow: 0 2px 6px #000000b2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .menu-modal {
    width: 327px;
  }
}

.menu-modal div {
  cursor: pointer;
}

.menu-modal .modal-menu-item-1 {
  width: 219px;
  height: 40px;
  position: absolute;
  top: 27px;
  left: 27px;
}

.menu-modal .modal-menu-item-2 {
  width: 135px;
  height: 40px;
  position: absolute;
  top: 107px;
  left: 27px;
}

.menu-modal .modal-menu-item-3 {
  width: 135px;
  height: 40px;
  position: absolute;
  top: 188px;
  left: 27px;
}

.menu-modal .modal-menu-item-4 {
  width: 177px;
  height: 40px;
  position: absolute;
  top: 269px;
  left: 27px;
}

.menu-modal .modal-menu-item-5 {
  width: 156px;
  height: 40px;
  position: absolute;
  top: 350px;
  left: 27px;
}

.menu-modal .menu-item-text {
  color: #333;
  letter-spacing: 1px;
  white-space: nowrap;
  font-family: var(--main-font-family-db);
  cursor: pointer;
  font-size: 24px;
  line-height: 28.5px;
  position: absolute;
  top: 4px;
  left: 55px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .menu-modal .menu-item-text {
    font-size: 1.5rem;
  }
}

.student {
  font-size: 24px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student {
    font-size: 1.5rem !important;
  }
}

.teacher {
  font-size: 20px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher {
    font-size: 1.25rem !important;
  }
}

.menu-modal .menu-item-circle {
  background-color: #d9d9d9;
  border-radius: 20px;
  position: absolute;
  top: -3px;
  left: -3px;
}

.menu-modal .menu-item-circle img {
  position: absolute;
  top: -5px;
  left: -5px;
}

.menu-modal-bg {
  z-index: 9;
  position: fixed;
  inset: 0;
}

.MuiButton-root.neutral-button {
  color: #fff;
  text-transform: none;
  font-family: var(--main-font-family-m);
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 680px;
  min-height: 130px;
  font-size: 40px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.MuiButton-root.neutral-button:hover {
  background: linear-gradient(#66d2ff 12.03%, #019bff 89.47%);
  box-shadow: 0 2px 6px #000000b2;
}

.MuiButton-root.neutral-button:active {
  box-shadow: none;
  background: linear-gradient(#016692 12.03%, #014b79 89.47%);
}

.logout-modal-window {
  width: 1000px;
}

.logout-modal .overlap-54 {
  background: linear-gradient(#fff 0%, #f9c2b6 60%);
  border-radius: 20px;
  width: 1000px;
  height: 940px;
  margin: auto;
  position: absolute;
  inset: 0;
  position: absolute !important;
}

.logout-modal .rectangle-26 {
  background-color: var(--white);
  border: 10px solid #fff;
  border-radius: 16px;
  width: 900px;
  height: 765px;
  position: relative;
  top: 125px;
  left: 50px;
}

.logout-modal .main-button-cancel {
  width: 682px;
  height: 130px;
  position: absolute;
  top: 650px;
  left: 160px;
}

.logout-modal .overlap-group-45 {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 680px;
  height: 130px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.logout-modal .text-wrapper-323 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 2px;
  text-align: center;
  width: 271px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 48px;
  left: 204px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout-modal .text-wrapper-323 {
    font-size: 2.5rem;
  }
}

.logout-modal .accent-button-OK {
  width: 682px;
  height: 130px;
  position: absolute;
  top: 464px;
  left: 160px;
}

.logout-modal .overlap-55 {
  background: linear-gradient(#ffe499 10%, #ffc423 90%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 680px;
  height: 130px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.logout-modal .text-wrapper-324 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  width: 610px;
  font-size: 50px;
  line-height: normal;
  position: absolute;
  top: 44px;
  left: 34px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout-modal .text-wrapper-324 {
    font-size: 3.125rem;
  }
}

.logout-modal .message {
  width: 427px;
  height: 121px;
  position: absolute;
  top: 235px;
  left: 296px;
}

.logout-modal .text {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.25px;
  text-align: center;
  white-space: nowrap;
  font-size: 45px;
  line-height: normal;
  position: absolute;
  top: 85px;
  left: 18px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout-modal .text {
    font-size: 2.8125rem;
  }
}

.logout-modal .text-2 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.25px;
  text-align: center;
  white-space: nowrap;
  font-size: 45px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout-modal .text-2 {
    font-size: 2.8125rem;
  }
}

.logout-modal .modal-title-bar-2 {
  width: 1002px;
  height: 96px;
  position: absolute;
  top: 0;
  left: 0;
}

.logout-modal .overlap-56 {
  background-color: var(--tomopla-color);
  border-radius: 20px 20px 0 0;
  width: 1000px;
  height: 96px;
  position: relative;
}

.logout-modal .sub-button-close-2 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 15px;
  left: 845px;
}

.logout-modal .overlap-57 {
  height: 66px;
  position: relative;
}

.logout-modal .active-a-2 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.logout-modal .overlap-group-46 {
  background-image: url("rectangle-18-6.898caf71.svg");
  background-size: 100% 100%;
  width: 152px;
  height: 78px;
  position: relative;
  top: -4px;
  left: -6px;
}

.logout-modal .group-175 {
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: relative;
  top: 12px;
  left: 15px;
}

.logout-modal .group-176 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.logout-modal .text-wrapper-325 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 70px;
  position: absolute;
  top: -3px;
  left: 64px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout-modal .text-wrapper-325 {
    font-size: 1.25rem;
  }
}

.logout-modal .modal-title-2 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 396px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .logout-modal .modal-title-2 {
    font-size: 2.5rem;
  }
}

.teacher-password {
  background: #fff 0 0 / 100% 100%;
  border-radius: 20px;
  width: 1633px;
  height: 980px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.teacher-password .overlap-61 {
  background-size: 100% 100%;
  position: relative;
}

.teacher-password .main-button-8 {
  width: 132px;
  height: 70px;
  position: absolute;
  top: 840px;
  left: 1383px;
}

.teacher-password .overlap-group-74 {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
  width: 130px !important;
  height: 70px !important;
}

.teacher-password .text-wrapper-261 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: 2px;
  text-align: center;
  width: 100px;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 14px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-261 {
    font-size: 2.5rem;
  }
}

.teacher-password .message-note-3 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.25px;
  white-space: nowrap;
  font-size: 25px;
  line-height: normal;
  position: absolute;
  top: 855px;
  left: 314px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .message-note-3 {
    font-size: 1.12386em;
  }
}

.teacher-password .input-password {
  width: 849px;
  height: 106px;
  position: absolute;
  top: 616px;
  left: 392px;
}

.teacher-password .label-8 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.25px;
  height: 56px;
  font-size: 25px;
  line-height: 41px;
  position: absolute;
  top: -2px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .label-8 {
    font-size: 1.5625rem;
  }
}

.teacher-password .input-area-8 {
  width: 639px;
  height: 100px;
  position: absolute;
  top: -21px;
  left: 231px;
}

.teacher-password .overlap-group-75 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 620px;
  height: 90px;
  position: relative;
  top: -10px;
  left: -10px;
}

.teacher-password .rectangle-53 {
  background-color: #fff;
  border-radius: 16px;
  width: 600px;
  height: 70px;
  position: relative;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.teacher-password .warning-message-6 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 4px;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: 78px;
  left: 249px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .warning-message-6 {
    font-size: 1.25rem;
  }
}

.teacher-password .warning-message {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.teacher-password .sub-button-auto {
  width: 162px;
  height: 55px;
  position: absolute;
  top: 457px;
  left: 1281px;
}

.teacher-password .overlap-62 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 160px;
  height: 55px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.teacher-password .text-wrapper-262 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 26px;
  line-height: normal;
  position: absolute;
  top: 7px;
  left: 27px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-262 {
    font-size: 1.625rem;
  }
}

.teacher-password .input-password-2 {
  width: 849px;
  height: 106px;
  position: absolute;
  top: 450px;
  left: 392px;
}

.teacher-password .label-9 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.25px;
  white-space: nowrap;
  font-size: 25px;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .label-9 {
    font-size: 1.5625rem;
  }
}

.teacher-password .rectangle-54 {
  background-color: #fff;
  border-radius: 16px;
  width: 600px;
  height: 70px;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.teacher-password .rectangle-54 fieldset {
  border: none;
  height: 70px;
  font-size: 22px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .rectangle-54 fieldset {
    font-size: 1.375rem;
  }
}

.teacher-password .rectangle-54 .MuiOutlinedInput-root {
  height: inherit;
  font-size: 22px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .rectangle-54 .MuiOutlinedInput-root {
    font-size: 1.375rem;
  }
}

.teacher-password .icon-eye {
  width: 46px;
  height: 40px;
  position: absolute;
  top: 37px;
  left: 554px;
}

.teacher-password .input-current {
  width: 849px;
  height: 106px;
  position: absolute;
  top: 284px;
  left: 392px;
}

.teacher-password .title-8 {
  width: 368px;
  height: 28px;
  position: absolute;
  top: 208px;
  left: 392px;
}

.teacher-password .text-wrapper-263 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.75px;
  white-space: nowrap;
  font-size: 35px;
  line-height: normal;
  position: absolute;
  top: -20px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-263 {
    font-size: 2.1875rem;
  }
}

.teacher-password .modal-title-bar {
  background-color: var(--tomopla-color);
  border-radius: 20px 20px 0 0;
  width: 1633px;
  height: 96px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-password .sub-button-close {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 15px;
  left: 1478px;
}

.teacher-password .overlap-63 {
  height: 66px;
  position: relative;
}

.teacher-password .active-a {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-password .overlap-group-76 {
  background-image: url("rectangle-18-8.65e02bb6.svg");
  background-size: 100% 100%;
  width: 152px;
  height: 78px;
  position: relative;
  top: -4px;
  left: -6px;
}

.teacher-password .group-253 {
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: relative;
  top: 12px;
  left: 15px;
}

.teacher-password .group-254 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.teacher-password .text-wrapper-264 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 70px;
  position: absolute;
  top: -4px;
  left: 64px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-264 {
    font-size: 1.25rem;
  }
}

.teacher-password .modal-title {
  width: 252px;
  height: 32px;
  position: absolute;
  top: 36px;
  left: 692px;
}

.teacher-password .text-wrapper-265 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: -22px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-265 {
    font-size: 2.5rem;
  }
}

.teacher-password .text-wrapper-233 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -2px;
  left: 34px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-233 {
    font-size: .75rem;
  }
}

.teacher-password .text-wrapper-234 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -2px;
  left: 53px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-234 {
    font-size: .75rem;
  }
}

.teacher-password .text-wrapper-235 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -2px;
  left: 79px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-235 {
    font-size: .75rem;
  }
}

.teacher-password .text-wrapper-236 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -2px;
  left: 106px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-236 {
    font-size: .75rem;
  }
}

.teacher-password .text-wrapper-pr0 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: -2px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr0 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr1 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: -8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr1 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr2 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: -1.7px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 846px;
  left: 499px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr2 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr3 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 846px;
  left: 655px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr3 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr4 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: -2px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 846px;
  left: 835px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr4 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr5 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -15px;
  left: -7px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr5 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr6 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 26px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr6 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-pr7 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: 2.6px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -30px;
  left: 219px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr7 {
    font-size: 1.0625rem;
  }
}

.teacher-password .text-wrapper-pr8 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 31px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-pr8 {
    font-size: .875rem;
  }
}

.teacher-password .text-wrapper-wr1 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 23px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr1 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr2 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 237px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr2 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr21 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 354px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr21 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr3 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 5px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 11px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr3 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr3b {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 260px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr3b {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr4 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 331px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr4 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr5 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 312px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr5 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr6 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 170px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr6 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr7 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 265px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr7 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr7b {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 339px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr7b {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr8 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 475px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr8 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr8a {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 515px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr8a {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr8b {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 588px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr8b {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr9 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 15px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr9 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr10 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: .5px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr10 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr11 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: -.9px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 226px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr11 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr12 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 280px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr12 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr13 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 502px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr13 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr14 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 626px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr14 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr21 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 21px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr21 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr22 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 283px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr22 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr23 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 525px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr23 {
    font-size: .6875rem;
  }
}

.teacher-password .text-wrapper-wr24 {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 11px;
  line-height: normal;
  position: absolute;
  top: -11px;
  left: 261px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-password .text-wrapper-wr24 {
    font-size: .6875rem;
  }
}

.main-button {
  all: unset;
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  justify-content: center;
  align-items: end;
  width: 130px;
  height: 70px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.main-button .text-wrapper {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 1.6px;
  text-align: center;
  font-size: 30px;
  line-height: normal;
  top: 6px;
  left: 14px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .main-button .text-wrapper {
    font-size: 1.875rem;
  }

  .main-button .text-wrapper .kanji-kana {
    top: -1.2em;
  }
}

.main-button .div {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 63px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .main-button .div {
    font-size: 1.0625rem;
  }
}

.main-button .text-wrapper-2 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 29px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .main-button .text-wrapper-2 {
    font-size: 1.0625rem;
  }
}

.main-button:hover {
  background: linear-gradient(#66d2ff 12.03%, #019bff 89.47%);
  box-shadow: 0 2px 6px #000000b2;
}

.main-button:active {
  box-shadow: none;
  background: linear-gradient(#016692 12.03%, #014b79 89.47%);
}

.sub-button-auto {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 160px;
  height: 55px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.sub-button-auto .text-wrapper {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 26px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 27px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sub-button-auto .text-wrapper {
    font-size: 1.625rem;
    top: 5px;
  }
}

.sub-button-auto .div {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 34px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sub-button-auto .div {
    font-size: .75rem;
  }
}

.sub-button-auto .text-wrapper-2 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 53px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sub-button-auto .text-wrapper-2 {
    font-size: .75rem;
  }
}

.sub-button-auto .text-wrapper-31 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 79px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sub-button-auto .text-wrapper-31 {
    font-size: .75rem;
  }
}

.sub-button-auto .text-wrapper-4 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 106px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .sub-button-auto .text-wrapper-4 {
    font-size: .75rem;
  }
}

.sub-button-auto:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  box-shadow: 0 2px 6px #000000b2;
}

.sub-button-auto:active {
  box-shadow: none;
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
}

.view-274 {
  width: 334px;
  height: 58px;
  position: absolute;
  top: 10px;
  left: 650px;
}

.group-273 {
  width: 340px;
  height: 58px;
  position: relative;
}

.text-wrapper-290 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-290 {
    font-size: 2.5rem;
  }
}

.text-wrapper-291 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 253px;
}

.text-wrapper-292 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 294px;
}

.view-275 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 15px;
  left: 1478px;
}

.overlap-70 {
  height: 66px;
  position: relative;
}

.active-a-4 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-274 {
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: relative;
  top: 12px;
  left: 15px;
}

.group-275 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.text-wrapper-293 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 70px;
  position: absolute;
  top: -5px;
  left: 67px;
}

.text-wrapper-294 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.25px;
  white-space: nowrap;
  font-size: 25px;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-294 {
    font-size: 1.5625rem;
  }
}

.text-wrapper-295 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: .7px;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 164px;
}

.text-wrapper-296 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: -.7px;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 183px;
}

.overlap-group-81 {
  background-image: url("rectangle-18-6.898caf71.svg");
  background-size: 100% 100%;
  width: 152px;
  height: 78px;
  position: relative;
  top: -6px;
  left: -4px;
}

.icon-edit {
  z-index: 2;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 356px;
  left: 290px;
}

.input-profile-name {
  width: 643px;
  position: absolute;
  top: 43px;
  left: -16px;
}

.overlap-group-83 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 610px;
  height: 80px;
  position: relative;
  top: -5px;
  left: -5px;
}

.rectangle-55 {
  background-color: #fff;
  border-radius: 16px;
  width: 600px;
  height: 70px;
  position: absolute;
  top: 5px;
  left: 5px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.iconedit-instance {
  width: 48px !important;
  height: 48px !important;
  position: absolute !important;
  top: 31px !important;
  left: 553px !important;
}

.text-wrapper-297 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.9px;
  white-space: nowrap;
  font-size: 38px;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 25px;
}

.warning-message-7 {
  width: 320px;
  height: 34px;
  position: absolute;
  top: 147px;
  left: 1px;
}

.text-wrapper-298 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -2.4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 0;
}

.overlap-71 {
  width: 60px;
  height: 10px;
  position: absolute;
  top: -4px;
  left: 182px;
}

.div-19 {
  color: var(--gold-red);
  font-family: var(--main-font-family-rg);
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 30px;
}

.text-wrapper-299 {
  letter-spacing: -3.72px;
}

.text-wrapper-300 {
  letter-spacing: -3.24px;
}

.text-wrapper-301 {
  letter-spacing: -3px;
}

.div-20 {
  color: var(--gold-red);
  font-family: var(--main-font-family-rg);
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.text-wrapper-302 {
  letter-spacing: -1.8px;
}

.text-wrapper-303 {
  letter-spacing: -2.16px;
}

.text-wrapper-304 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -2.4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 102px;
}

.text-wrapper-305 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -2.4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 32px;
}

.text-wrapper-306 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -2.4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 170px;
}

.text-wrapper-307 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.8px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 119px;
}

.warning-message-8 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.1px;
  white-space: nowrap;
  font-size: 22px;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 4px;
}

.navbar-6 {
  width: 172px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
}

.text-wrapper-308 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.25px;
  white-space: nowrap;
  font-size: 25px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 5px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-308 {
    font-size: 1.5625rem;
  }
}

.text-wrapper-309 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: .7px;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 36px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-309 {
    font-size: .875rem;
  }
}

.div-21 {
  color: var(--letter-black);
  font-family: var(--main-font-family-rg);
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .div-21 {
    font-size: .875rem;
  }
}

.text-wrapper-310 {
  letter-spacing: -3.92px;
}

.text-wrapper-311 {
  letter-spacing: -2.94px;
}

.text-wrapper-312 {
  letter-spacing: -2.8px;
}

.text-wrapper-313 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: -3.92px;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 117px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-313 {
    font-size: .875rem;
  }
}

.text-wrapper-314 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: -2.1px;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 136px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-314 {
    font-size: .875rem;
  }
}

.main-button-11 {
  width: 136px;
  height: 70px;
  position: absolute;
  top: 228px;
  left: 475px;
}

.overlap-72 {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 130px;
  height: 70px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

.text-wrapper-315 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: 1.6px;
  text-align: center;
  width: 100px;
  font-size: 32px;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 15px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-315 {
    font-size: 2rem;
  }
}

.text-wrapper-316 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 65px;
}

.text-wrapper-317 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 30px;
}

.text-wrapper-wr1 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 5px;
}

.text-wrapper-wr2 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: .4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 98px;
}

.text-wrapper-wr3 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: -.4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 162px;
}

.text-wrapper-wr4 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 232px;
}

.teacher-avatar {
  background-size: 100% 100%;
  width: 1633px;
  height: 980px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-avatar .main-button-10 {
  position: absolute;
  top: 815px;
  left: 1282px;
}

.teacher-avatar .text-wrapper-276 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: 1.6px;
  text-align: center;
  width: 100px;
  font-size: 32px;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 14px;
}

.teacher-avatar .text-wrapper-277 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 63px;
}

.teacher-avatar .text-wrapper-278 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 29px;
}

.teacher-avatar .sub-button {
  position: absolute;
  top: 815px;
  left: 1122px;
}

.teacher-avatar .group-257 {
  height: 70px;
}

.teacher-avatar .group-258 {
  width: 134px;
  height: 70px;
}

.teacher-avatar .text-wrapper-279 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 1.4px;
  text-align: center;
  width: 100px;
  font-size: 28px;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 14px;
}

.teacher-avatar .text-wrapper-280 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: .7px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: 35px;
}

.teacher-avatar .avatar-boxshadow {
  border-radius: 16px;
  width: 360px;
  height: 360px;
  position: absolute;
  top: 333px;
  left: 1052px;
  box-shadow: 0 2px 6px #000000b2;
}

.teacher-avatar .group-259 {
  height: 346px;
}

.teacher-avatar .line-9 {
  width: 698px;
  position: absolute;
  top: 782px;
  left: 220px;
}

.teacher-avatar .select-avatar {
  grid-template-columns: repeat(6, 1fr);
  width: 555px;
  height: 460px;
  display: grid;
  position: absolute;
  top: 283px;
  left: 292px;
}

.teacher-avatar .group-262 {
  width: 555px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-avatar .group-263 {
  height: 80px;
}

.teacher-avatar .frame-24 {
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.teacher-avatar .group-264 {
  background-color: var(--white);
  border: 7px solid;
  border-color: var(--tomopla-color-mid);
  border-radius: 16px;
  width: 80px;
  height: 80px;
  position: relative;
}

.teacher-avatar .group-265 {
  background-color: var(--white);
  border: 7px solid;
  border-color: var(--gray);
  border-radius: 16px;
  width: 80px;
  height: 80px;
  position: relative;
}

.teacher-avatar .thumbnail {
  height: 71px;
}

.teacher-avatar .group-266 {
  width: 555px;
  height: 80px;
  position: absolute;
  top: 190px;
  left: 0;
}

.teacher-avatar .group-267 {
  width: 555px;
  height: 80px;
  position: absolute;
  top: 95px;
  left: 0;
}

.teacher-avatar .group-268 {
  width: 555px;
  height: 80px;
  position: absolute;
  top: 285px;
  left: 0;
}

.teacher-avatar .group-269 {
  width: 555px;
  height: 80px;
  position: absolute;
  top: 380px;
  left: 0;
}

.teacher-avatar .line-10 {
  width: 698px;
  position: absolute;
  top: 242px;
  left: 220px;
}

.teacher-avatar .navbar-5 {
  width: 355px;
  height: 44px;
  position: absolute;
  top: 179px;
  left: 222px;
}

.teacher-avatar .text-wrapper-281 {
  font-family: var(--main-font-family-db);
  letter-spacing: 1.5px;
  white-space: nowrap;
  font-size: 30px;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-281 {
    font-size: 1.875rem;
  }
}

.teacher-avatar .text-wrapper-282 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: .75px;
  white-space: nowrap;
  font-size: 15px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 197px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-282 {
    font-size: .9375rem;
  }
}

.teacher-avatar .text-wrapper-283 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 15px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 220px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-283 {
    font-size: .9375rem;
  }
}

.teacher-avatar .text-wrapper-284 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.5px;
  white-space: nowrap;
  font-size: 15px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 283px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-284 {
    font-size: .9375rem;
  }
}

.teacher-avatar .text-wrapper-285 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -1.5px;
  white-space: nowrap;
  font-size: 15px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 315px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-285 {
    font-size: .9375rem;
  }
}

.teacher-avatar .modal-title-bar-3 {
  background-color: var(--tomopla-color);
  width: 1633px;
  height: 96px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-avatar .view-272 {
  width: 334px;
  height: 58px;
  position: absolute;
  top: 10px;
  left: 650px;
}

.teacher-avatar .group-270 {
  width: 340px;
  height: 58px;
  position: relative;
}

.teacher-avatar .text-wrapper-286 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 26px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-286 {
    font-size: 2.5rem;
  }
}

.teacher-avatar .text-wrapper-287 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 253px;
}

.teacher-avatar .text-wrapper-288 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 294px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-288 {
    font-size: 1.25rem;
  }
}

.teacher-avatar .view-273 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 15px;
  left: 1478px;
}

.teacher-avatar .overlap-68 {
  height: 66px;
  position: relative;
}

.teacher-avatar .active-a-3 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-avatar .overlap-group-80 {
  background-image: url("rectangle-18-6.898caf71.svg");
  background-size: 100% 100%;
  width: 152px;
  height: 78px;
  position: relative;
  top: -4px;
  left: -6px;
}

.teacher-avatar .group-271 {
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: relative;
  top: 12px;
  left: 15px;
}

.teacher-avatar .group-272 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.teacher-avatar .text-wrapper-289 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 70px;
  position: absolute;
  top: 23px;
  left: 64px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-avatar .text-wrapper-289 {
    font-size: 1.25rem;
  }
}

.modal-sub-button {
  all: unset;
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border-radius: 16px;
  justify-content: center;
  align-items: end;
  width: 130px;
  height: 70px;
  display: flex;
  box-shadow: 0 2px 6px #000000b2;
}

.modal-sub-button .text-wrapper {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 1.6px;
  text-align: center;
  font-size: 30px;
  line-height: normal;
  top: 6px;
  left: 14px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .modal-sub-button .text-wrapper {
    font-size: 1.875rem;
  }

  .modal-sub-button .text-wrapper .kanji-kana {
    top: -1.2em;
  }
}

.modal-sub-button .div {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 63px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .modal-sub-button .div {
    font-size: 1.0625rem;
  }
}

.modal-sub-button:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  box-shadow: 0 2px 6px #000000b2;
}

.modal-sub-button:active {
  box-shadow: none;
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
}

.student-visual {
  background-color: #fff;
  background-size: 100% 100%;
  border-radius: 20px;
  width: 1633px;
  height: 980px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.student-visual.light {
  background-color: #fafafa;
}

.student-visual.beige {
  background-color: #fafaf5;
}

.student-visual.dark {
  background-color: #323232;
}

.student-visual.blue {
  background-color: #e6f0ff;
}

.student-visual.green {
  background-color: #daf2e6;
}

.student-visual.pink {
  background-color: #ffd9e3;
}

.student-visual .modal-title-bar {
  background-color: var(--tomopla-color);
  border-radius: 20px 20px 0 0;
  width: 1633px;
  height: 96px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-visual.light .modal-title-bar {
  background-color: #f97253;
}

.student-visual.beige .modal-title-bar {
  background-color: #a6976c;
}

.student-visual.dark .modal-title-bar {
  background-color: #4d4d4d;
}

.student-visual.blue .modal-title-bar {
  background-color: #0a93f5;
}

.student-visual.green .modal-title-bar {
  background-color: #00b259;
}

.student-visual.pink .modal-title-bar {
  background-color: #ff8196;
}

.student-visual .main-button-18 {
  position: absolute;
  top: 850px;
  left: 1403px;
}

.student-visual .text-wrapper-789 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: 1.6px;
  text-align: center;
  width: 100px;
  font-size: 32px;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 14px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-789 {
    font-size: 2rem;
  }
}

.student-visual .text-wrapper-790 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 63px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-790 {
    font-size: 1.0625rem;
  }
}

.student-visual .text-wrapper-791 {
  color: #fff;
  font-family: var(--main-font-family-r);
  letter-spacing: -.85px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 29px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-791 {
    font-size: 1.0625rem;
  }
}

.student-visual .select-color {
  grid-template-columns: repeat(3, 1fr);
  width: 1148px;
  height: 630px;
  display: grid;
  position: absolute;
  top: 235px;
  left: 252px;
}

.student-visual .select-color .row {
  border: 8px solid #0000;
  width: 364px;
  height: 246px;
}

.student-visual .select-color .row.selected {
  border: 8px solid #0a93f5;
  width: 364px;
  height: 246px;
}

.student-visual .row-3 {
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  width: 1096px;
  display: flex;
  position: absolute;
  top: 322px;
  left: 12px;
}

.student-visual .select-color-blue {
  width: 332px;
  margin-bottom: -.98px;
  position: relative;
}

.student-visual .select-color-green {
  width: 332px;
  margin-bottom: -1.24px;
  position: relative;
}

.student-visual .select-color-pink {
  width: 332px;
  height: 259.18px;
  margin-bottom: -1.18px;
  position: relative;
}

.student-visual .overlap-group-129 {
  width: 1108px;
  height: 270px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-visual .row-4 {
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  width: 1096px;
  display: flex;
  position: absolute;
  top: 12px;
  left: 12px;
}

.student-visual .select-color-light {
  width: 332px;
  margin-bottom: -1.44px;
  position: relative;
}

.student-visual .select-color-beize {
  width: 332px;
  margin-bottom: -.96px;
  position: relative;
}

.student-visual .select-color-dark {
  width: 332px;
  margin-bottom: -1.22px;
  position: relative;
}

.student-visual .selected-2 {
  width: 356px;
  height: 236px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-visual .subtitle {
  width: 370px;
  height: 50px;
  position: absolute;
  top: 136px;
  left: 267px;
}

.student-visual .text-wrapper-792 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.75px;
  white-space: nowrap;
  font-size: 35px;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-792 {
    font-size: 2.1875rem;
  }
}

.student-visual .text-wrapper-793 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: .85px;
  white-space: nowrap;
  font-size: 17px;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 267px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-793 {
    font-size: 1.0625rem;
  }
}

.student-visual .sub-button-close-3 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 15px;
  left: 1478px;
}

.student-visual .overlap-147 {
  height: 66px;
  position: relative;
}

.student-visual .active-a-5 {
  width: 140px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-visual .overlap-group-130 {
  background-image: url("rectangle-18.41cc0771.svg");
  background-size: 100% 100%;
  width: 152px;
  height: 78px;
  position: relative;
  top: -4px;
  left: -6px;
}

.student-visual .group-488 {
  background-color: #fff;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: relative;
  top: 12px;
  left: 15px;
}

.student-visual .group-489 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.student-visual .text-wrapper-794 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: 70px;
  position: absolute;
  top: -2px;
  left: 64px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-794 {
    font-size: 1.25rem;
  }
}

.student-visual .message {
  width: 173px;
  height: 57px;
  position: absolute;
  top: 11px;
  left: 729px;
}

.student-visual .text-wrapper-795 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  text-align: center;
  white-space: nowrap;
  font-size: 40px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 5px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-795 {
    font-size: 2.5rem;
  }
}

.student-visual .view-347 {
  width: 177px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-visual .text-wrapper-796 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: -3px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-796 {
    font-size: 1.25rem;
  }
}

.student-visual .text-wrapper-797 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: -3px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 56px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-797 {
    font-size: 1.25rem;
  }
}

.student-visual .text-wrapper-798 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 88px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-798 {
    font-size: 1.25rem;
  }
}

.student-visual .text-wrapper-799 {
  color: #fff;
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: -7px;
  left: 130px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .text-wrapper-799 {
    font-size: 1.25rem;
  }
}

.student-visual .wrap {
  flex-direction: column;
  display: flex;
  position: relative;
}

.student-visual .wrap .name {
  color: var(--, #333);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  bottom: 33px;
  left: 16px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-visual .wrap .name {
    font-size: 1.25rem;
  }
}

.student-top-bar {
  width: 1690px;
  height: 90px;
  position: relative;
}

.student-top-bar .menu-modal.menu-item-count-3 {
  height: 258px;
}

.student-top-bar .menu-modal.menu-item-count-4 {
  height: 339px;
}

.student-top-bar .user-menu {
  z-index: 2;
  flex-direction: row-reverse;
  align-items: center;
  width: 470px;
  height: 66px;
  display: flex;
  position: absolute;
  top: 12px;
  left: 1122px;
}

.student-top-bar .user-avatar-wrapper {
  border-color: #0000;
  border-radius: 6px;
  width: 70px;
  height: 70px;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
}

.student-top-bar .avatar-overlay {
  background-color: var(--white);
  position: relative;
}

.student-top-bar .avatar-bg {
  background-color: var(--white);
  border-radius: 4px;
  width: 66px;
  height: 66px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.student-top-bar .avatar-image {
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top-bar .user-details {
  cursor: pointer;
  align-items: flex-end;
  gap: 18px;
  height: 22px;
  margin-right: 20px;
  display: flex;
  left: 0;
}

.student-top-bar .dropdown-icon {
  width: 18px;
  height: 15.3px;
  position: relative;
  top: -5px;
}

.student-top-bar .bar-title {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  font-size: 40px;
  line-height: 20px;
  position: absolute;
  top: 46px;
  left: 301px;
}

.student-top-bar .tomopla-logo {
  width: 224px !important;
  height: 71px !important;
  top: 12px !important;
  left: 41px !important;
}

.student-top-bar .tomopla-logo-link {
  z-index: 10;
  cursor: pointer;
  width: 224px;
  height: 71px;
  position: absolute;
  top: 12px;
  left: 41px;
}

.student-top-bar .name-container {
  z-index: 1;
  width: 1690px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top-bar .navbar-name {
  width: 1247px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 301px;
}

.student-top-bar .name-part-1 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: .65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1112px;
}

.student-top-bar .name-part-2 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: .65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1148px;
}

.student-top-bar .name-part-3 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: .65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1178px;
}

.student-top-bar .name-part-4 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: .65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1208px;
}

.student-top-bar .name-extension {
  width: 86px;
  height: 16px;
  position: absolute;
  top: 6px;
  left: 0;
}

.student-top-bar .name-extension .name-part-5 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: .95px;
  white-space: nowrap;
  font-size: 19px;
  line-height: 20px;
  position: absolute;
  top: 1px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top-bar .name-extension .name-part-5 {
    font-size: 1.1875rem;
  }
}

.student-top-bar .name-extension .name-part-6 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: -3.8px;
  white-space: nowrap;
  font-size: 19px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 36px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top-bar .name-extension .name-part-6 {
    font-size: 1.1875rem;
  }
}

.student-frame {
  flex-direction: row;
  width: 100%;
  display: flex;
}

.student-frame .wrap {
  background-color: var(--white);
  z-index: 10;
  width: 1690px;
  height: 1080px;
  position: absolute;
}

.student-top .student-top-bar-instance {
  z-index: 9999;
}

.uKjTVq_body {
  width: 1580px;
  margin-top: 33px;
  margin-left: 35px;
}

.uKjTVq_body .uKjTVq_word-lists {
  margin-top: 52px;
}

.uKjTVq_table th {
  height: 89px;
  font-size: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .uKjTVq_table th {
    font-size: 1.25rem;
  }
}

.uKjTVq_table td {
  font-size: 18px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .uKjTVq_table td {
    font-size: 1.125rem;
  }
}

.uKjTVq_table td:first-child {
  letter-spacing: 0;
  padding: 16px 2px;
}

.uKjTVq_history-select-container {
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 28px;
}

.uKjTVq_dictionary-form {
  flex-direction: column;
  row-gap: 25px;
  display: flex;
}

.uKjTVq_dictionary-form .uKjTVq_select {
  justify-content: space-between;
}

.uKjTVq_word-list {
  flex-direction: column;
  row-gap: 24px;
  display: flex;
}

.uKjTVq_word-list .uKjTVq_label {
  font-size: 20px;
  font-family: var(--main-font-family-db);
  letter-spacing: .05em;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 25px;
  display: flex;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .uKjTVq_word-list .uKjTVq_label {
    font-size: 1.25rem;
  }
}

.xlLMgW_body {
  width: 1580px;
  margin-top: 33px;
  margin-left: 35px;
}

.xlLMgW_body .xlLMgW_table {
  z-index: 0;
  margin-top: 28px;
}

.xlLMgW_table {
  border: 1px solid var(--line-color);
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
}

.xlLMgW_table th {
  height: 89px;
  font-size: 20px;
}

.xlLMgW_table td {
  font-size: 18px;
}

.xlLMgW_table td:first-child {
  padding: 16px 2px;
}

.xlLMgW_history-select-container {
  height: -moz-fit-content;
  height: fit-content;
}

.B0qUdG_student-history-kanji.B0qUdG_body {
  width: 1580px;
  margin-top: 33px;
  margin-left: 35px;
}

.B0qUdG_student-history-kanji .B0qUdG_table {
  z-index: 0;
  margin-top: 62px;
}

.B0qUdG_student-history-kanji .B0qUdG_teaching-unit {
  padding: 10px;
}

.B0qUdG_student-history-kanji .B0qUdG_teaching-unit-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._2q-qcW_body {
  width: 1580px;
  margin-top: 33px;
  margin-left: 35px;
}

._2q-qcW_tables {
  max-width: 1580px;
  margin-top: 100px;
}

._2q-qcW_tables table {
  flex-shrink: 0;
}

._2q-qcW_tables table:not(:last-child) {
  border-right: 1px solid var(--line-color);
}

._2q-qcW_tables table tr:first-child th {
  height: 79px;
}

._2q-qcW_tables table tr:nth-child(2) th {
  height: 48px;
}

._2q-qcW_tables table td {
  font-family: var(--main-font-family-m);
  letter-spacing: .1em;
  height: 67px;
  padding-right: 9px;
  font-size: 20px;
  line-height: 28px;
}

._2q-qcW_caption-table {
  z-index: 3;
  position: sticky;
  left: 0;
}

._2q-qcW_caption-table thead {
  height: 127px;
}

._2q-qcW_test-type {
  font-family: var(--main-font-family-db);
  letter-spacing: .1em;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  font-size: 30px;
  line-height: 28px;
  display: flex;
}

.msq-_W_body {
  margin-top: 20px;
  margin-left: 40px;
}

.msq-_W_description {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: -.019em;
  margin-top: 36px;
  font-size: 20px;
  line-height: 30px;
}

.PRRVuW_body {
  margin-top: 33px;
  margin-left: 35px;
}

.PRRVuW_table {
  z-index: 0;
  margin-top: 42px;
}

.A7z1Va_icon {
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  width: 167px;
  height: 100%;
  font-size: 25px;
  display: flex;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .A7z1Va_icon {
    font-size: 1.5625rem;
  }
}

.A7z1Va_icon img {
  width: 34px;
  height: 39px;
}

.A7z1Va_icon .A7z1Va_row {
  margin-top: -5px;
}

.app-menu-wrapper .box {
  box-sizing: border-box;
  border-style: solid;
  border-width: 4px;
  border-color: var(--main-color);
  filter: drop-shadow(0 4px 2px #00000075);
  background-color: #fff;
  border-radius: 20px;
  width: 160px;
  min-width: 160px;
  height: 140px;
  position: relative;
}

.app-menu-wrapper .box.kss, .app-menu-wrapper .box.KKE1TO, .app-menu-wrapper .box.KKE2TO, .app-menu-wrapper .box.KKE3TO, .app-menu-wrapper .box.KKE4TO, .app-menu-wrapper .box.KKE5TO, .app-menu-wrapper .box.KKE6TO, .app-menu-wrapper .box.KKE1KE, .app-menu-wrapper .box.KKE2KE, .app-menu-wrapper .box.KKE3KE, .app-menu-wrapper .box.KKE4KE, .app-menu-wrapper .box.KKE5KE, .app-menu-wrapper .box.KKE6KE, .app-menu-wrapper .box.KKE1HY, .app-menu-wrapper .box.KKE2HY, .app-menu-wrapper .box.KKE3HY, .app-menu-wrapper .box.KKE4HY, .app-menu-wrapper .box.KKE5HY, .app-menu-wrapper .box.KKE6HY {
  --main-color: #8fd7ff;
  --sub-color: #c9e9fb;
}

.app-menu-wrapper .box.kkd, .app-menu-wrapper .box.kjd, .app-menu-wrapper .box.DKOKDC, .app-menu-wrapper .box.DKANDC {
  --main-color: #ade8a8;
  --sub-color: #d4edd2;
}

.app-menu-wrapper .box.kjs, .app-menu-wrapper .box.rjs, .app-menu-wrapper .box.cbt, .app-menu-wrapper .box.utt, .app-menu-wrapper .box.mv, .app-menu-wrapper .box.DMOVMK, .app-menu-wrapper .box.DCBKMK, .app-menu-wrapper .box.DPBKMK, .app-menu-wrapper .box.DROMHY, .app-menu-wrapper .box.DKJSMK, .app-menu-wrapper .box.KKJSMK, .app-menu-wrapper .box.KKJSTO, .app-menu-wrapper .box.KKJSKY, .app-menu-wrapper .box.KROMHY, .app-menu-wrapper .box.KROMHY {
  --main-color: #ffb3ac;
  --sub-color: #fbdad7;
}

.app-menu-wrapper .box.qa {
  --main-color: #ececec;
  --sub-color: #faf8f8;
}

.app-menu-wrapper .box .group {
  background-color: var(--main-color);
  border-radius: 0 0 20px 20px;
  width: 160px;
  height: 60px;
  position: absolute;
  top: 76px;
  left: -4px;
}

.app-menu-wrapper .box .group .div {
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  display: flex;
}

.app-menu-wrapper .box .img-2 {
  width: 127px;
  height: 59px;
  position: absolute;
  top: 9px;
  left: 15px;
}

.app-menu-wrapper .box:hover {
  border-color: var(--sub-color);
}

.app-menu-wrapper .box:hover .group {
  background-color: var(--sub-color);
}

.app-menu-wrapper .box:active {
  border-color: var(--main-color);
  filter: none;
}

.app-menu-wrapper .box:active .group {
  background-color: var(--main-color);
}

.app-menu-wrapper .box .furoku {
  margin: auto;
  position: absolute;
  top: 18px;
  bottom: 0;
  right: 0;
}

.fc {
  font-family: var(--main-font-family-db);
}

.fc .fc-day {
  letter-spacing: 1.24px;
  font-size: 24.8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .fc .fc-day {
    font-size: 1.55rem;
  }
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-child {
  order: 2;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  order: 3;
  width: 15%;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  order: 1;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
  position: unset;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group .fc-next-button {
  position: absolute;
  right: 12px;
}

.fc .fc-today-button {
  display: none;
}

.fc .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  letter-spacing: 1.25px;
  font-size: 25px;
  display: none;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 1.55rem;
  }
}

.fc .fc-button-primary {
  color: initial;
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.fc .fc-button-primary:focus {
  box-shadow: unset;
}

.fc .fc-button-primary:not(:disabled):active {
  color: initial;
  box-shadow: unset;
}

.fc .fc-button-primary:hover {
  color: initial;
}

.fc .fc-button-primary .fc-icon {
  font-size: 2em;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: unset;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 7px;
}

.fc .fc-scroller, .fc .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.fc .fc-scrollgrid-section-body table {
  width: 457px;
  height: 291px;
  margin-left: 8px;
}

.fc .fc-scrollgrid-section table {
  margin-top: 7px;
  margin-left: 7px;
  width: 457px !important;
}

.fc .fc-view-harness {
  height: 363px !important;
}

.fc .fc-scrollgrid {
  border-width: 0;
  font-size: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .fc .fc-scrollgrid {
    font-size: 1.25rem;
  }
}

.fc-theme-standard td, .fc-theme-standard th {
  border: none;
  height: 51px;
  padding: 0 5px;
}

.fc-theme-standard th {
  height: 41px;
}

.fc .fc-daygrid-day.fc-day-today, .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
  background: none;
}

.fc .fc-col-header-cell.fc-day.fc-day-sat {
  color: var(--light-blue);
}

.fc .fc-col-header-cell.fc-day.fc-day-sun, .fc .fc-day.is_holiday {
  color: var(--tomopla-color);
}

.fc .fc-h-event {
  background-color: unset;
  border: unset;
}

.fc .fc-day {
  position: relative;
}

.fc .fc-day.medal-silver:after {
  content: "";
  background-image: url("medal-silver.bc5e326e.svg");
  background-size: contain;
  width: 62px;
  height: 62px;
  margin: auto;
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 2px;
}

.fc .fc-day.medal-bronze:after {
  content: "";
  background-image: url("medal-bronze.dc11b268.svg");
  background-size: contain;
  width: 62px;
  height: 62px;
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 2px;
}

.fc .fc-day.medal-gold:after {
  content: "";
  background-image: url("medal-gold.7ca635ea.svg");
  background-size: contain;
  width: 62px;
  height: 62px;
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 2px;
}

.fc .fc-daygrid-day-frame {
  height: 100%;
}

.fc .fc-daygrid-day-top {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.fc .fc-bg-event {
  opacity: 1;
}

.fc .fc-bg-event .fc-event-title {
  display: none !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  display: none;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
  background: none;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: none;
}

.group-6 {
  width: 227.95px;
  height: 78.77px;
  position: absolute;
  top: -7px;
  left: -25px;
}

.group-7 {
  width: 314px;
  height: 80px;
  position: absolute;
  top: -9px;
  left: 212px;
}

.element {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  text-align: right;
  white-space: nowrap;
  font-size: 19px;
  line-height: 20px;
  position: absolute;
  top: 98px;
  left: 111px;
}

.span {
  letter-spacing: .75px;
}

.text-wrapper-5 {
  letter-spacing: -1.5px;
}

.group-62 {
  width: 136px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-63 {
  height: 32px;
}

.group-64 {
  width: 138px;
  height: 32px;
}

.overlap-group-10 {
  background-image: url("rectangle-499-2.1a5887cf.svg");
  background-size: 100% 100%;
  height: 32px;
  position: relative;
}

.text-wrapper-3 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  padding: 2px 10px;
  font-size: 20px;
  line-height: 27px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .text-wrapper-3 {
    font-size: 1.24rem;
  }
}

.group-65 {
  width: 408px;
  height: 500px;
  position: absolute;
  top: 46px;
  left: 0;
}

.group-65 .recwrap {
  position: absolute;
  top: 191px;
}

.group-66 {
  width: 408px;
  height: 88px;
  position: absolute;
  top: 103px;
  left: 0;
}

.group-73 {
  width: 408px;
  height: 88px;
  position: absolute;
  top: 206px;
  left: 0;
}

.overlap-group-12 {
  background-image: url("rectangle-477-1.22fba443.svg");
  background-size: 100% 100%;
  width: 414px;
  height: 94px;
  position: relative;
  top: -1px;
}

.rectangle-12 {
  background-color: var(--tomopla-color);
  border-radius: 0 4px 4px 0;
  width: 15px;
  height: 88px;
  position: absolute;
  top: 1px;
  left: 393px;
}

.group-74 {
  background-color: var(--white);
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 13px;
  left: 363px;
}

.group-75 {
  width: 11px;
  height: 8px;
  position: absolute;
  top: 5px;
  left: 3px;
}

.group-76 {
  width: 84px;
  height: 13px;
  position: absolute;
  top: 60px;
  left: 297px;
}

.group-77 {
  width: 86px;
  height: 13px;
  position: relative;
}

.overlap-group-13 {
  background-image: url("rectangle-477-2.f15e1f19.svg");
  background-size: 100% 100%;
  width: 414px;
  height: 94px;
  position: relative;
  top: -1px;
}

.group-10755 {
  position: absolute;
  top: 345px;
  left: 34px;
}

.M-xnSW_chart {
  font-size: 18px;
  font-family: var(--main-font-family-m);
}

.M-xnSW_chart svg {
  overflow: visible;
}

.M-xnSW_legend {
  font-size: 12px;
  font-family: var(--main-font-family-db);
  flex-wrap: wrap;
  gap: 10px 20px;
  display: flex;
}

.M-xnSW_legend .M-xnSW_item {
  align-items: center;
  column-gap: 5px;
  display: flex;
}

.M-xnSW_legend > li .M-xnSW_icon {
  width: 8px;
  height: 8px;
}

.student-top .div-2 {
  background-color: #fff;
  height: 1080px;
  position: relative;
}

.student-top .overlap-wrapper {
  width: 1690px;
  height: 226px;
  position: fixed;
  bottom: 0;
}

.student-top .overlap {
  height: 226px;
  position: relative;
}

.student-top .group-wrapper {
  width: 1690px;
  height: 210px;
  position: absolute;
  top: 16px;
  left: 0;
}

.student-top .overlap-group-wrapper {
  background-size: 100% 100%;
  height: 210px;
}

.student-top .overlap-group-2 {
  background-image: url("mask-group.cfd8bae1.png");
  background-size: 100% 100%;
  width: 1690px;
  height: 210px;
}

.student-top .app-menu-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 1630px;
  height: 148px;
  padding-bottom: 8px;
  display: flex;
  position: relative;
  top: 40px;
  left: 30px;
  overflow: auto;
}

.student-top .app-menu-wrapper::-webkit-scrollbar {
  background-color: var(--gray);
  border-radius: 20px;
  width: 12px;
}

.student-top .app-menu-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray-2);
  border-radius: 20px;
}

.student-top .app-menu-instance {
  width: unset !important;
}

.student-top .design-component-instance-node {
  width: 1630px !important;
}

.student-top .group-3 {
  width: 178px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 30px;
}

.student-top .group-4 {
  width: 180px;
  height: 32px;
}

.student-top .overlap-group-3 {
  background-image: url("rectangle-499.65bc78ee.svg");
  background-size: 100% 100%;
  width: 176px;
  height: 32px;
  position: relative;
}

.student-top .text-wrapper-300 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  background: none;
  font-size: 24px;
  line-height: 27px;
  top: 15px;
  left: 10px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-300 {
    font-size: 1.5rem;
  }
}

.student-top .rt0 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: -1px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 27px;
  position: absolute;
  top: -4px;
  left: 8px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .rt0 {
    font-size: .75rem;
  }
}

.student-top .rt1 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 3px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 27px;
  position: absolute;
  top: -4px;
  left: 116px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .rt1 {
    font-size: .75rem;
  }
}

.student-top .student-top-bar-instance {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.student-top .tomopla-logo-8 {
  width: 224px !important;
  height: 71px !important;
  position: absolute !important;
  top: 12px !important;
  left: 41px !important;
}

.student-top .group-5 {
  width: 1599px;
  height: 113px;
  position: absolute;
  top: 123px;
  left: 63px;
}

.student-top .group-8 {
  width: 1050px;
  height: 107px;
  position: absolute;
  top: 6px;
  left: 547px;
}

.student-top .group-23 {
  width: 478px;
  height: 546px;
  position: absolute;
  top: 278px;
  left: 30px;
}

.student-top .group-24 {
  box-shadow: var(--line-color);
  width: 480px;
  height: 546px;
}

.student-top .overlap-5 {
  height: 546px;
  position: relative;
}

.student-top .group-25 {
  width: 478px;
  height: 530px;
  position: absolute;
  top: 16px;
  left: 0;
}

.student-top .group-26 {
  height: 530px;
}

.student-top .overlap-group-7 {
  width: 478px;
  height: 530px;
  position: relative;
}

.student-top .group-27 {
  width: 478px;
  height: 530px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top .group-5702-wrapper {
  border: 1px solid;
  border-color: var(--tomopla-color);
  background-color: #fcf2ef;
  border-radius: 16px;
  width: 478px;
  height: 530px;
  position: relative;
}

.student-top .group-5702 {
  width: 52px !important;
  height: 53px !important;
  position: absolute !important;
  top: 247px !important;
  left: 213px !important;
}

.student-top .group-28 {
  width: 406px;
  height: 20px;
  position: absolute;
  top: 99px;
  left: 36px;
}

.student-top .group-29 {
  height: 20px;
}

.student-top .group-30 {
  width: 406px;
  height: 20px;
}

.student-top .group-31 {
  width: 408px;
  height: 20px;
  position: relative;
}

.student-top .text-wrapper-9 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.25px;
  text-align: center;
  width: 191px;
  font-size: 25px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 107px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-9 {
    font-size: 1.5625rem;
  }
}

.student-top .vector-2 {
  width: 14px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 395px;
}

.student-top .vector-3 {
  width: 14px;
  height: 22px;
  position: absolute;
  top: 0;
  left: -2px;
}

.student-top .group-32 {
  width: 442px;
  height: 19px;
  position: absolute;
  top: 151px;
  left: 25px;
}

.student-top .text-wrapper-10 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top .text-wrapper-11 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 65px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-11 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-12 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 131px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-12 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-13 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 196px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-13 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-14 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 262px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-14 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-15 {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 327px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-15 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-16 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 393px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-16 {
    font-size: 1.55rem;
  }
}

.student-top .group-33 {
  width: 428px;
  height: 294px;
  position: absolute;
  top: 208px;
  left: 25px;
}

.student-top .group-34 {
  width: 442px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top .text-wrapper-17 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-17 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-18 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 65px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-18 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-19 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 131px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-19 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-20 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 327px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-20 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-21 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 393px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-21 {
    font-size: 1.55rem;
  }
}

.student-top .group-35 {
  width: 440px;
  height: 19px;
  position: absolute;
  top: 54px;
  left: 0;
}

.student-top .text-wrapper-22 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 262px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-22 {
    font-size: 1.55rem;
  }
}

.student-top .group-36 {
  width: 442px;
  height: 19px;
  position: absolute;
  top: 110px;
  left: 0;
}

.student-top .group-37 {
  width: 442px;
  height: 19px;
  position: absolute;
  top: 164px;
  left: 0;
}

.student-top .group-38 {
  width: 442px;
  height: 19px;
  position: absolute;
  top: 219px;
  left: 0;
}

.student-top .text-wrapper-23 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 393px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-23 {
    font-size: 1.55rem;
  }
}

.student-top .group-39 {
  width: 442px;
  height: 19px;
  position: absolute;
  top: 274px;
  left: 0;
}

.student-top .text-wrapper-24 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 196px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-24 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-25 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 262px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-25 {
    font-size: 1.55rem;
  }
}

.student-top .text-wrapper-26 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 0;
  left: 327px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-26 {
    font-size: 1.55rem;
  }
}

.student-top .group-5729 {
  width: 52px !important;
  height: 53px !important;
  position: absolute !important;
  top: 264px !important;
  left: 17px !important;
}

.student-top .group-5728 {
  width: 52px !important;
  height: 52px !important;
  position: absolute !important;
  top: 209px !important;
  left: 280px !important;
}

.student-top .group-5925 {
  width: 52px !important;
  height: 53px !important;
  position: absolute !important;
  top: 209px !important;
  left: 345px !important;
}

.student-top .group-5926 {
  width: 52px !important;
  height: 52px !important;
  position: absolute !important;
  top: 209px !important;
  left: 410px !important;
}

.student-top .group-5730 {
  width: 52px !important;
  height: 52px !important;
  position: absolute !important;
  top: 264px !important;
  left: 83px !important;
}

.student-top .group-5731 {
  width: 52px !important;
  height: 52px !important;
  position: absolute !important;
  top: 264px !important;
  left: 148px !important;
}

.student-top .text-wrapper-27 {
  color: var(--black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.24px;
  text-align: center;
  white-space: nowrap;
  width: 35px;
  font-size: 24.8px;
  line-height: 35.4px;
  position: absolute;
  top: 279px;
  left: 221px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-27 {
    font-size: 1.55rem;
  }
}

.student-top .group-40 {
  width: 480px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top .overlap-6 {
  width: 478px;
  height: 90px;
  position: relative;
}

.student-top .rectangle-2 {
  border-radius: 16px 16px 0 0;
  width: 478px;
  height: 79px;
  position: absolute;
  top: 11px;
  left: 0;
}

.student-top .group-41 {
  width: 34px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 404px;
}

.student-top .overlap-group-8 {
  height: 57px;
  position: relative;
}

.student-top .ellipse {
  background-color: var(--white);
  border-radius: 17px;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 23px;
  left: 0;
}

.student-top .rectangle-3 {
  background-color: #333;
  border-radius: 20px;
  width: 10px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 12px;
}

.student-top .group-42 {
  width: 34px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 40px;
}

.student-top .text-wrapper-28 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  width: 370px;
  font-size: 24px;
  line-height: 36px;
  position: absolute;
  top: 41px;
  left: 54px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-28 {
    font-size: 1.5rem;
  }
}

.student-top .group-43 {
  width: 640px;
  height: 530px;
  position: absolute;
  top: 290px;
  left: 560px;
}

.student-top .group-44 {
  width: 640px;
  height: 546px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top .group-45 {
  width: 640px;
  height: 530px;
  position: absolute;
  top: 16px;
  left: 0;
}

.student-top .group-46 {
  border: 1px solid;
  border-color: var(--tomopla-color);
  box-shadow: var(--line-color);
  background-color: #fcf2ef;
  border-radius: 16px;
  height: 530px;
}

.student-top .group-47 {
  width: 235px;
  height: 13px;
  position: relative;
  top: 35px;
  left: 364px;
}

.student-top .group-48 {
  height: 13px;
}

.student-top .element-wrapper {
  width: 237px;
  height: 13px;
  position: relative;
}

.student-top .p {
  color: var(--letter-black);
  font-family: var(--main-font-family-rg);
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .p {
    font-size: 1rem;
  }
}

.student-top .text-wrapper-29 {
  letter-spacing: .8px;
}

.student-top .text-wrapper-30 {
  letter-spacing: 2.72px;
}

.student-top .group-49 {
  width: 157px;
  height: 32px;
  position: absolute;
  top: -28px;
  left: 21px;
}

.student-top .group-50 {
  width: 159px;
  height: 32px;
}

.student-top .overlap-group-9 {
  background-image: url("rectangle-499-1.19eb1995.svg");
  background-size: 100% 100%;
  width: 155px;
  height: 32px;
  position: relative;
}

.student-top .group-51 {
  width: 559px;
  height: 12px;
  position: absolute;
  top: 93px;
  left: 43px;
}

.student-top .vector-4 {
  object-fit: cover;
  width: 520px;
  height: 1px;
  position: absolute;
  top: 6px;
  left: 38px;
}

.student-top .text-wrapper-31 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-31 {
    font-size: 1.875rem;
  }
}

.student-top .group-52 {
  width: 559px;
  height: 15px;
  position: absolute;
  top: 155px;
  left: 43px;
}

.student-top .vector-5 {
  object-fit: cover;
  width: 520px;
  height: 1px;
  position: absolute;
  top: 8px;
  left: 38px;
}

.student-top .text-wrapper-32 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 30px;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-32 {
    font-size: 1.875rem;
  }
}

.student-top .group-53 {
  width: 559px;
  height: 16px;
  position: absolute;
  top: 220px;
  left: 43px;
}

.student-top .group-54 {
  width: 549px;
  height: 16px;
  position: absolute;
  top: 286px;
  left: 53px;
}

.student-top .vector-6 {
  object-fit: cover;
  width: 520px;
  height: 1px;
  position: absolute;
  top: 8px;
  left: 28px;
}

.student-top .text-wrapper-33 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 20px;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-33 {
    font-size: 1.875rem;
  }
}

.student-top .group-55 {
  width: 549px;
  height: 16px;
  position: absolute;
  top: 352px;
  left: 53px;
}

.student-top .group-56 {
  width: 549px;
  height: 15px;
  position: absolute;
  top: 418px;
  left: 53px;
}

.student-top .frame-2 {
  align-items: flex-end;
  gap: 28px;
  width: 509px;
  display: flex;
  position: absolute;
  top: 176px;
  left: 86px;
}

.student-top .group-57 {
  width: 48px;
  height: 339px;
  position: relative;
}

.student-top .text-wrapper-34 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 326px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-34 {
    font-size: 1.875rem;
  }
}

.student-top .frame-3 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 315px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 6px;
}

.student-top .rectangle-4 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 117px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .group-58 {
  width: 48px;
  height: 293px;
  position: relative;
}

.student-top .element-2 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 280px;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .element-2 {
    font-size: 1.875rem;
  }
}

.student-top .frame-wrapper {
  width: 36px;
  height: 269px;
  position: absolute;
  top: 0;
  left: 6px;
}

.student-top .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 220px;
}

.student-top .rectangle-5 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 49px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .frame-5 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 302px;
  display: inline-flex;
  position: absolute;
  top: 13px;
  left: 6px;
}

.student-top .rectangle-6 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 87px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 105px;
}

.student-top .rectangle-7 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 164px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .frame-7 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 366px;
  display: inline-flex;
  position: absolute;
  top: -51px;
  left: 6px;
}

.student-top .rectangle-8 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 342px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .frame-8 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 172px;
}

.student-top .rectangle-9 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 97px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .rectangle-10 {
  background: linear-gradient(#f9d1c7 0%, #f97253 100%);
  border-radius: 0 0 4px 4px;
  width: 36px;
  height: 214px;
  position: relative;
  transform: rotate(-180deg);
}

.student-top .vector-7 {
  width: 521px;
  height: 2px;
  position: absolute;
  top: 491px;
  left: 80px;
}

.student-top .group-59 {
  width: 129px;
  height: 22px;
  position: absolute;
  top: 49px;
  left: 22px;
}

.student-top .group-60 {
  width: 20px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.student-top .text-wrapper-35 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  width: 97px;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  top: 5px;
  left: 30px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-35 {
    font-size: 1.625rem;
  }
}

.student-top .group-61 {
  width: 408px;
  height: 546px;
  position: absolute;
  top: 278px;
  left: 1252px;
}

.student-top .text-wrapper-47 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  text-align: right;
  white-space: nowrap;
  font-size: 26px;
  line-height: 19px;
  position: absolute;
  top: 13px;
  left: 131px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .text-wrapper-47 {
    font-size: 1.625rem;
  }
}

.student-top .view {
  justify-content: center !important;
  gap: 5px !important;
  height: 20px !important;
  padding: 7px 10px !important;
  position: absolute !important;
  top: 244px !important;
  left: 53px !important;
}

.student-top .component-10851-instance {
  letter-spacing: 1px !important;
  margin-top: -10px !important;
  margin-bottom: -8px !important;
  font-size: 10px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .student-top .component-10851-instance {
    font-size: 1.25rem;
  }
}

.recharts-tooltip-wrapper {
  background-color: #fcf2ef !important;
}

.teacher-help {
  background-color: #fafafa;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.teacher-help .div-22 {
  background-color: #fafafa;
  width: 1690px;
  height: 1080px;
  position: relative;
}

.teacher-help .overlap-73 {
  width: 1368px;
  height: 906px;
  position: absolute;
  top: 144px;
  left: 280px;
}

.teacher-help .main-button-contact {
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--line-color);
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 25px 66px;
  display: inline-flex;
  position: absolute;
  top: 747px;
  left: 0;
}

.teacher-help .text-wrapper-318 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 1.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 24px;
  line-height: 30px;
  position: relative;
}

.teacher-help .help-contents {
  width: 1368px;
  height: 906px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .scroll-bar-6 {
  width: 1px;
  height: 906px;
  position: absolute;
  top: 0;
  left: 1368px;
}

.teacher-help .line-wrapper {
  background-image: url("line-10-3.41441df4.svg");
  background-size: 100% 100%;
  width: 12px;
  height: 906px;
  position: relative;
}

.teacher-help .line-11 {
  width: 12px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .block-QA {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 414px;
  left: 0;
}

.teacher-help .row-QA {
  width: 327.5px;
  height: 35px;
  position: relative;
}

.teacher-help .question {
  width: 328px;
  height: 35px;
  position: relative;
}

.teacher-help .group-277 {
  width: 291px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 36px;
}

.teacher-help .group-278 {
  width: 295px;
  height: 18px;
  position: relative;
}

.teacher-help .text-wrapper-319 {
  color: #333;
  font-family: var(--main-font-family-b);
  letter-spacing: 1.1px;
  white-space: nowrap;
  font-size: 22px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .text-wrapper-320 {
  color: #333;
  font-family: var(--main-font-family-b);
  letter-spacing: 1.1px;
  white-space: nowrap;
  font-size: 22px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 38px;
}

.teacher-help .frame-25 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .row-QA-2 {
  width: 1259px;
  height: 103px;
  position: relative;
}

.teacher-help .answer {
  width: 1223px;
  height: 48px;
  position: absolute;
  top: 55px;
  left: 36px;
}

.teacher-help .group-279 {
  height: 48px;
}

.teacher-help .group-280 {
  width: 1227px;
  height: 48px;
  position: relative;
}

.teacher-help .text-wrapper-321 {
  color: #333;
  font-family: var(--main-font-family-r);
  letter-spacing: 0;
  width: 1188px;
  font-size: 22px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 35px;
}

.teacher-help .question-2 {
  width: 443px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .group-281 {
  width: 408px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 36px;
}

.teacher-help .group-282 {
  width: 412px;
  height: 18px;
  position: relative;
}

.teacher-help .question-wrapper {
  width: 558.5px;
  height: 35px;
  position: relative;
}

.teacher-help .question-3 {
  width: 558px;
  height: 35px;
  position: relative;
}

.teacher-help .group-283 {
  width: 522px;
  height: 18px;
  position: absolute;
  top: 9px;
  left: 36px;
}

.teacher-help .group-284 {
  width: 526px;
  height: 18px;
  position: relative;
}

.teacher-help .genre-title {
  background-color: #ffea00;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  display: inline-flex;
  position: absolute;
  top: 354px;
  left: 0;
}

.teacher-help .text-wrapper-322 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  line-height: 28px;
  position: relative;
}

.teacher-help .section-title {
  background-image: url("iconhelp.f70c9675.png");
  background-size: 100% 100%;
  width: 293px;
  height: 48px;
  position: absolute;
  top: 286px;
  left: 0;
}

.teacher-help .block-buttons-5 {
  width: 940px;
  height: 70px;
  position: absolute;
  top: 116px;
  left: 0;
}

.teacher-help .link-teachers-manual {
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--line-color);
  background: linear-gradient(#fafafa 0%, #e6e6e6 100%);
  border-radius: 16px;
  width: 460px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 480px;
}

.teacher-help .group-285 {
  width: 371px;
  height: 35px;
  position: relative;
  top: 16px;
  left: 45px;
}

.teacher-help .group-286 {
  width: 27px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .text-wrapper-323 {
  color: #333;
  font-family: var(--main-font-family-m);
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 30px;
  position: absolute;
  top: 8px;
  left: 42px;
}

.teacher-help .link-students-manual {
  border: 1px solid;
  border-color: var(--white);
  box-shadow: var(--line-color);
  background: linear-gradient(#fafafa 0%, #e6e6e6 100%);
  border-radius: 16px;
  width: 460px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .group-287 {
  width: 245px;
  height: 35px;
  position: relative;
  top: 16px;
  left: 108px;
}

.teacher-help .main-message {
  width: 232px;
  height: 16px;
  position: absolute;
  top: 70px;
  left: 0;
}

.teacher-help .text-wrapper-324 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 20px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .title-9 {
  width: 248px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .text-wrapper-325 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  white-space: nowrap;
  font-size: 36px;
  line-height: 30px;
  position: absolute;
  top: 11px;
  left: 58px;
}

.teacher-help .group-288 {
  width: 38px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .overlap-74 {
  width: 1452px;
  height: 67px;
  position: absolute;
  top: 16px;
  left: 210px;
}

.teacher-help .input-search-8 {
  width: 391px;
  height: 46px;
  position: absolute;
  top: 2px;
  left: 850px;
}

.teacher-help .overlap-75 {
  height: 46px;
  position: relative;
}

.teacher-help .input-search-keyword {
  width: 391px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .overlap-group-84 {
  background-color: #d9d9d9;
  border-radius: 16px;
  width: 407px;
  height: 62px;
  position: relative;
  top: -8px;
  left: -8px;
}

.teacher-help .rectangle-56 {
  background-color: #fff;
  border-radius: 16px;
  width: 391px;
  height: 46px;
  position: absolute;
  top: 8px;
  left: 8px;
  box-shadow: inset 0 2px 6px #000000b2;
}

.teacher-help .text-wrapper-326 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  width: 183px;
  font-size: 24px;
  line-height: 38px;
  position: absolute;
  top: 21px;
  left: 24px;
}

.teacher-help .main-button-search {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 60px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 331px;
  box-shadow: 0 1.1px 3.29px #000000b2;
}

.teacher-help .vector-58 {
  width: 32px;
  height: 34px;
  position: absolute;
  top: 5px;
  left: 13px;
}

.teacher-help .teacher-top-bar-9 {
  width: 1452px;
  height: 67px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .text-wrapper-327 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.8px;
  font-size: 36px;
  line-height: 20px;
  position: absolute;
  top: 23px;
  left: 0;
}

.teacher-help .group-289 {
  width: 169px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 1281px;
}

.teacher-help .group-290 {
  background-color: #d9d9d9;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 119px;
}

.teacher-help .frame-26 {
  align-items: flex-end;
  gap: 12px;
  width: 106px;
  display: flex;
  position: absolute;
  top: 18px;
  left: 0;
}

.teacher-help .text-wrapper-328 {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: .9px;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 18px;
  line-height: 18px;
  position: relative;
}

.teacher-help .vector-59 {
  width: 12px;
  height: 10.2px;
  position: relative;
}

.teacher-help .line-12 {
  object-fit: cover;
  width: 1450px;
  height: 1px;
  position: absolute;
  top: 66px;
  left: 0;
}

.teacher-help .teacher-side-bar {
  width: 182px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-help .overlap-76 {
  background-color: var(--tomopla-color);
  width: 180px;
  height: 1080px;
  position: relative;
}

.teacher-help .text-wrapper-329 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 25px;
  line-height: 20px;
  position: absolute;
  top: 19px;
  left: 15px;
}

.teacher-help .group-291 {
  width: 118px;
  height: 314px;
  position: absolute;
  top: 77px;
  left: 31px;
}

.teacher-help .group-292 {
  width: 119px;
  height: 78px;
  position: absolute;
  top: 0;
  left: 1px;
}

.teacher-help .text-wrapper-330 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .8px;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 65px;
  left: 0;
}

.teacher-help .group-293 {
  background-color: #d9d9d9;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 33px;
}

.teacher-help .group-294 {
  width: 100px;
  height: 78px;
  position: absolute;
  top: 118px;
  left: 9px;
}

.teacher-help .frame-27 {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.teacher-help .rectangle-57 {
  background-color: #d9d9d9;
  width: 50px;
  height: 50px;
  position: relative;
}

.teacher-help .text-wrapper-331 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .8px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.teacher-help .group-295 {
  width: 119px;
  height: 78px;
  position: absolute;
  top: 236px;
  left: 0;
}

.teacher-help .rectangle-58 {
  background-color: #d9d9d9;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 34px;
}

.teacher-help .group-296 {
  width: 86px;
  height: 98px;
  position: absolute;
  top: 791px;
  left: 48px;
}

.teacher-help .text-wrapper-332 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: .8px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 65px;
  left: 0;
}

.teacher-help .rectangle-59 {
  background-color: #d9d9d9;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 17px;
}

.BI0TLa_container {
  z-index: 10;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  padding: 16px 4px 20px;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.BI0TLa_items {
  flex-direction: column;
  align-items: center;
  row-gap: 34px;
  display: flex;
}

.BI0TLa_item {
  color: var(--white);
  font-size: 16px;
  font-family: var(--main-font-family-m);
  letter-spacing: .05em;
  text-align: center;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;
  line-height: 17px;
  display: flex;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .BI0TLa_item {
    font-size: 1rem;
  }
}

.teacher-top-bar {
  z-index: 11;
  justify-content: space-between;
  width: calc(100% - 140px);
  height: 90px;
  display: flex;
  position: absolute;
  top: 0;
  left: 140px;
}

.teacher-top-bar .topbar-title {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 2px;
  font-size: 40px;
  line-height: 20px;
  position: absolute;
  top: 50px;
  left: 301px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-top-bar .topbar-title {
    font-size: 2.5rem;
  }
}

.teacher-top-bar .wrapper {
  flex-direction: row-reverse;
  align-items: center;
  gap: 21px;
  width: 500px;
  height: 66px;
  display: flex;
  position: absolute;
  top: 12px;
  right: 163px;
}

.teacher-top-bar .wrapper .avatar-wrapper {
  border-color: #0000;
  border-radius: 6px;
  width: 70px;
  height: 70px;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
}

.teacher-top-bar .wrapper .avatar-bg-wrapper {
  background-color: var(--white);
  position: relative;
}

.teacher-top-bar .wrapper .avatar-bg {
  background-color: var(--white);
  border-radius: 4px;
  width: 66px;
  height: 66px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.teacher-top-bar .wrapper .avatar-img {
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-top-bar .wrapper .name-wrapper {
  cursor: pointer;
  align-items: flex-end;
  gap: 18px;
  height: 22px;
  display: flex;
  left: 1279px;
}

.teacher-top-bar .wrapper .user-name {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: 4px;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 272px;
  margin-top: -1.5px;
  font-size: 27px;
  line-height: 30px;
  position: relative;
  top: 6px;
  overflow: hidden;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-top-bar .wrapper .user-name {
    font-size: 1.4875rem;
  }
}

.teacher-top-bar .wrapper .arrow-icon {
  width: 18px;
  height: 15.3px;
  position: relative;
}

.teacher-top-bar .custom-instance {
  width: 224px !important;
  height: 71px !important;
  position: absolute !important;
  top: 12px !important;
  left: 41px !important;
}

.teacher-top-bar .menu-modal {
  box-shadow: var(--);
  cursor: pointer;
  background-color: #fff;
  border-radius: 16px;
  width: 271px;
  height: 339px;
  position: relative;
  left: calc(100% - 302px);
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .teacher-top-bar .menu-modal {
    width: 283px;
  }
}

.teacher-top-bar .menu-modal.menu-item-count-3 {
  height: 258px;
}

.teacher-top-bar .menu-modal.menu-item-count-4 {
  height: 339px;
}

.teacher-top-bar .menu-modal .menu-item-text {
  top: 7px;
}

.teacher-top-bar .tomopla-logo-link {
  z-index: 10;
  cursor: pointer;
  width: 224px;
  height: 71px;
  position: absolute;
  top: 12px;
  left: 41px;
}

.teacher-frame {
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 90px;
  padding-left: 140px;
  display: flex;
}

.fLb1ha_body {
  margin-top: 20px;
  margin-left: 40px;
}

.fLb1ha_item {
  margin-left: 20px;
}

.fLb1ha_button {
  display: block;
  margin-left: 40px !important;
}

.fLb1ha_description {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: -.019em;
  margin-top: 36px;
  font-size: 20px;
  line-height: 30px;
}

.fLb1ha_warning {
  color: red;
  font-family: var(--main-font-family-db);
  letter-spacing: -.019em;
  font-size: 20px;
  line-height: 30px;
}

.zm6G_G_body {
  margin-top: 20px;
  margin-left: 40px;
}

.zm6G_G_body .zm6G_G_table-container {
  margin-top: 22px;
}

.zm6G_G_body .zm6G_G_ranking {
  margin-top: 36px;
}

.zm6G_G_table-container {
  height: 419px;
}

.zm6G_G_table th {
  font-size: 18px;
  height: 89px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zm6G_G_table th {
    font-size: 1.125rem;
  }
}

.zm6G_G_table td {
  font-size: 14px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zm6G_G_table td {
    font-size: .875rem;
  }
}

.zm6G_G_table td:first-child, .zm6G_G_table td:nth-child(2), .zm6G_G_table td:nth-child(4) {
  padding: 18px 0;
}

.zm6G_G_buttons {
  column-gap: 15px;
  margin-left: 15px;
  display: flex;
}

.zm6G_G_ranking {
  border: 5px solid var(--tomopla-color);
  background-color: var(--tomopla-color-light);
  border-radius: 8px;
  align-items: flex-end;
  column-gap: 70px;
  width: 1440px;
  min-height: 264px;
  padding: 16px 40px;
  display: flex;
  position: relative;
}

.zm6G_G_ranking .zm6G_G_title {
  position: absolute;
  top: -5px;
  left: 40px;
}

.zm6G_G_title {
  background-color: var(--tomopla-color);
  color: var(--white);
  font-size: 24px;
  font-family: var(--main-font-family-db);
  letter-spacing: .01em;
  white-space: pre-wrap;
  text-align: center;
  border-radius: 0 0 8px 8px;
  place-content: center;
  width: 440px;
  height: 80px;
  line-height: 32px;
  display: grid;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zm6G_G_title {
    font-size: 1.5rem;
  }
}

.zm6G_G_ranking .zm6G_G_form {
  width: 430px;
  margin-left: 10px;
}

.zm6G_G_form {
  flex-direction: column;
  row-gap: 28px;
  display: flex;
  position: relative;
}

.zm6G_G_form .zm6G_G_select {
  justify-content: space-between;
  width: 298px;
}

.zm6G_G_form .zm6G_G_select * {
  white-space: nowrap;
  font-size: 16px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zm6G_G_form .zm6G_G_select * {
    font-size: 1rem;
  }
}

.zm6G_G_form .zm6G_G_select > :not(:first-child) {
  width: 195px;
  height: 42px;
}

.zm6G_G_form .zm6G_G_select img {
  height: 9px;
}

.zm6G_G_form .zm6G_G_submit {
  position: absolute;
  bottom: 5px;
  right: -58px;
  width: 100px !important;
}

.zm6G_G_word-list {
  border: 1px solid var(--line-color);
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
}

.zm6G_G_word-list li {
  height: 60px;
  display: flex;
}

.zm6G_G_word-list li > * {
  align-items: center;
  height: 100%;
  display: flex;
}

.zm6G_G_word-list li:not(:last-child) {
  border-bottom: 1px solid var(--line-color);
}

.zm6G_G_word-list .zm6G_G_caption {
  border-right: 1px solid var(--line-color);
  background-color: var(--tomopla-color);
  color: var(--white);
  font-size: 20px;
  font-family: var(--main-font-family-db);
  letter-spacing: .05em;
  justify-content: center;
  width: 79px;
  height: 100%;
  line-height: 1;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zm6G_G_word-list .zm6G_G_caption {
    font-size: 1.25rem !important;
  }
}

.zm6G_G_word-list .zm6G_G_content {
  background-color: var(--white);
  font-size: 22.5px;
  font-family: var(--main-font-family-db);
  letter-spacing: .1em;
  flex: 1;
  padding: 0 20px;
  line-height: 1.2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zm6G_G_word-list .zm6G_G_content {
    font-size: 1.5625rem;
  }
}

.CkU0cq_body {
  margin-top: 20px;
  margin-left: 40px;
}

.CkU0cq_body .CkU0cq_table {
  z-index: 0;
  margin-top: 22px;
}

.CkU0cq_table th {
  font-size: 20px;
  height: 89px !important;
}

.CkU0cq_table td {
  font-size: 18px;
}

.CkU0cq_table td:first-child, .CkU0cq_table td:nth-child(2), .CkU0cq_table td:nth-child(4) {
  padding: 18px 0;
}

.CkU0cq_buttons {
  column-gap: 15px;
  margin-left: 15px;
  display: flex;
}

.iKJyaW_body {
  width: 1440px;
  margin-top: 20px;
  margin-left: 40px;
}

form {
  position: relative;
}

.iKJyaW_body .iKJyaW_toggle-row {
  margin-top: 21px;
}

.iKJyaW_body .iKJyaW_table {
  z-index: 0;
  margin-top: 17px;
}

.iKJyaW_table tr:nth-last-child(2) td {
  border-bottom: none;
}

.iKJyaW_table tr:last-child td {
  border-top: 1px solid var(--line-color);
}

.iKJyaW_table tbody tr:last-child {
  z-index: 1;
  position: sticky;
  bottom: -1px;
}

.iKJyaW_table tbody tr:last-child td {
  border-bottom: 2px solid var(--line-color);
}

.iKJyaW_table td:nth-child(n+6) {
  padding: 16px 4px;
}

.iKJyaW_selects-row {
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;
}

.iKJyaW_selects {
  align-items: center;
  column-gap: 36px;
  display: flex;
}

.iKJyaW_buttons {
  column-gap: 15px;
  margin-left: 15px;
  display: flex;
}

.iKJyaW_toggle-row {
  justify-content: flex-end;
  width: 100%;
}

.zp5MsG_body {
  width: 1440px;
  margin-top: 20px;
  margin-left: 40px;
}

.zp5MsG_body .zp5MsG_toggle-row {
  margin-top: 21px;
}

.zp5MsG_table {
  z-index: 0;
  margin-top: 21px;
}

.zp5MsG_table td {
  height: 35px;
  padding: 0;
}

.zp5MsG_table td .zp5MsG_total {
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 11px;
  display: flex;
}

.zp5MsG_table td .zp5MsG_type {
  white-space: pre-wrap;
  line-height: 28px;
}

.zp5MsG_table td .zp5MsG_date-column {
  display: flex;
}

.zp5MsG_table td .zp5MsG_frequncy-type, .zp5MsG_table td .zp5MsG_date {
  text-align: center;
  font-size: 13px;
}

.zp5MsG_table td .zp5MsG_frequncy-type {
  width: 75px;
}

.zp5MsG_table td .zp5MsG_date {
  flex: 1;
}

.zp5MsG_selects-row {
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;
}

.zp5MsG_selects {
  align-items: center;
  gap: 25px;
  display: flex;
}

.zp5MsG_selects .zp5MsG_category-select {
  margin-left: 36px;
}

.zp5MsG_selects .zp5MsG_category-select > :first-child {
  white-space: wrap;
}

.zp5MsG_selects .zp5MsG_teaching-unit-select {
  margin-left: 30px;
}

.zp5MsG_small-label {
  column-gap: 15px;
}

.zp5MsG_small-label > :first-child {
  letter-spacing: .01em;
  width: 111px;
  line-height: 22px;
  font-size: 17px !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .zp5MsG_small-label > :first-child {
    white-space: initial;
    font-size: 1.1em !important;
  }
}

.zp5MsG_small-label > :not(:first-child) {
  flex: 1;
}

.zp5MsG_category-select {
  width: 316px;
}

.zp5MsG_teaching-unit-select {
  width: 380px;
}

.zp5MsG_buttons {
  column-gap: 15px;
  margin-left: 15px;
  display: flex;
}

.zp5MsG_toggle-row {
  justify-content: flex-end;
  width: 100%;
}

.XqKHJW_body {
  margin-top: 20px;
  margin-left: 40px;
}

.XqKHJW_description {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  letter-spacing: -.019em;
  margin-top: 36px;
  font-size: 20px;
  line-height: 30px;
}

.bcDAZG_body {
  margin-top: 20px;
  margin-left: 40px;
}

.bcDAZG_table {
  z-index: 0;
  border-radius: 4px;
  width: 1480px;
  margin-top: 22px;
}

.bcDAZG_buttons {
  column-gap: 15px;
  margin-left: 15px;
  display: flex;
}

.fa2wkG_body {
  margin-top: 20px;
  margin-left: 40px;
}

.fa2wkG_button {
  display: block;
  margin-left: 40px !important;
}

.fa2wkG_description {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  letter-spacing: -.019em;
  margin-top: 36px;
  font-size: 20px;
  line-height: 30px;
}

.knWgxa_body {
  margin-top: 20px;
  margin-left: 40px;
}

.knWgxa_conditions {
  column-gap: 26px;
  display: flex;
  position: absolute;
}

.knWgxa_table {
  z-index: 0;
  margin-top: 23px;
  position: absolute;
  top: 164px;
}

.knWgxa_table td {
  padding: 14px;
}

.knWgxa_table td button {
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin: 0 10px !important;
  padding: 12px 19px !important;
  font-size: 16px !important;
  line-height: 1 !important;
}

.knWgxa_table td button:first-child {
  margin-left: 0 !important;
}

.knWgxa_table td button:last-child {
  margin-right: 0 !important;
}

.knWgxa_modal-header {
  justify-content: flex-start;
}

.knWgxa_modal-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.knWgxa_modal-content {
  flex-direction: column;
  justify-content: center;
  padding: 62px 140px;
  display: flex;
  position: relative;
}

.knWgxa_modal-content:after {
  content: "";
  border-left: 1px solid var(--line-color);
  position: absolute;
  top: 108px;
  bottom: 108px;
  left: 460px;
}

.knWgxa_modal-content li {
  font-size: 40px;
  font-family: var(--main-font-family-m);
  letter-spacing: .05em;
  align-items: center;
  width: 100%;
  line-height: 1.25;
  display: flex;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .knWgxa_modal-content li {
    font-size: 2.5rem;
  }
}

.knWgxa_modal-content li > div:first-child {
  font-family: var(--main-font-family-b);
  white-space: nowrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  width: 320px;
  padding: 22px 60px 23px 0;
  display: flex;
}

.knWgxa_modal-content li > div:last-child {
  line-break: anywhere;
  flex: 1;
  padding: 22px 0 23px 60px;
}

.knWgxa_modal-content li:last-child > div:last-child {
  padding: 6px 0 6px 60px;
}

.knWgxa_modal-content button {
  font-size: 34px !important;
  font-family: var(--main-font-family-m) !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin: 0 10px !important;
  padding: 21px 14px 21px 16px !important;
  line-height: 1 !important;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .knWgxa_modal-content button {
    font-size: 2.125rem !important;
  }
}

#app {
  height: 100%;
}

._7damzq_body {
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  display: flex;
}

._7damzq_body ._7damzq_statistics {
  margin-top: 27px;
}

._7damzq_content {
  flex: 1;
  padding: 0 50px 28px;
}

._7damzq_nav {
  background: var(--tomopla-yellow);
  height: 210px;
  position: relative;
}

._7damzq_nav ._7damzq_menu {
  position: relative;
}

._7damzq_label {
  color: var(--white);
  font-size: 20px;
  font-family: var(--main-font-family-db);
  letter-spacing: .05em;
  line-height: 1;
  position: absolute;
  top: -16px;
  left: 50px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  ._7damzq_label {
    font-size: 1.25rem;
  }
}

._7damzq_label > div {
  border-radius: 4px 0 0 4px;
  height: 32px;
  padding: 6px 0 6px 10px;
  position: relative;
}

._7damzq_label > div > span {
  position: relative;
}

._7damzq_label > div:before {
  content: "";
  background-repeat: no-repeat;
  width: 178px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -32px;
}

._7damzq_logo {
  position: absolute;
  top: 0;
  right: 0;
}

._7damzq_statistics ._7damzq_header {
  border-bottom: 2px solid var(--gray);
  font-family: var(--main-font-family-m);
  letter-spacing: .05em;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  display: flex;
}

._7damzq_statistics ._7damzq_header:last-child {
  font-size: 17px;
}

._7damzq_statistics ._7damzq_body {
  flex-direction: row;
  column-gap: 42px;
  width: 100%;
  margin-top: 36px;
  display: flex;
}

._7damzq_statistics ._7damzq_chart {
  border: 1px solid var(--tomopla-color);
  background: var(--bg-student-chart);
  border-radius: 16px;
  flex-shrink: 0;
  width: 640px;
  height: 519px;
  padding: 57px 40px 20px 43px;
  position: relative;
  box-shadow: 0 0 3px #0006;
}

._7damzq_statistics ._7damzq_chart ._7damzq_label {
  left: 22px;
}

._7damzq_statistics ._7damzq_chart ._7damzq_caption {
  font-family: var(--main-font-family-db);
  align-items: center;
  column-gap: 10px;
  font-size: 13px;
  display: flex;
  position: absolute;
  top: 23px;
  left: 22px;
}

._7damzq_statistics ._7damzq_chart ._7damzq_period {
  font-family: var(--main-font-family-db);
  letter-spacing: .05em;
  font-size: 16px;
  position: absolute;
  top: 26px;
  right: 62px;
}

._7damzq_statistics ._7damzq_chart ._7damzq_period > span {
  margin: 0 4.5px;
}

._7damzq_statistics ._7damzq_chart ._7damzq_scaling {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  top: 11px;
  right: 13px;
}

._7damzq_statistics ._7damzq_chart ._7damzq_main {
  width: 100%;
  height: 100%;
}

._7damzq_statistics ._7damzq_records {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  row-gap: 14px;
  display: flex;
  position: relative;
}

._7damzq_statistics ._7damzq_records ._7damzq_blank {
  position: absolute;
  top: 345px;
  right: 0;
}

@keyframes _7damzq_fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

._7damzq_statistics ._7damzq_records ._7damzq_item {
  background: var(--tomopla-color-light);
  border-radius: 4px;
  padding: 14px 31px 9px 30px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 3px 2px 3px #0000001a;
}

._7damzq_statistics ._7damzq_records ._7damzq_item._7damzq_linked {
  background: linear-gradient(90deg, #f9d0c7 -23.77%, #f9866b 100%);
}

._7damzq_statistics ._7damzq_records ._7damzq_item._7damzq_linked ._7damzq_value span {
  color: var(--white);
}

._7damzq_statistics ._7damzq_records ._7damzq_item:after {
  content: "";
  background-color: var(--top-record-edge);
  width: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

._7damzq_statistics ._7damzq_records ._7damzq_item ._7damzq_icon {
  width: 18px;
  position: absolute;
  top: 10px;
  right: 25px;
}

._7damzq_statistics ._7damzq_records ._7damzq_item ._7damzq_caption {
  font-family: var(--main-font-family-db);
  color: var(--letter-black);
  letter-spacing: .05em;
  font-size: 26px;
  line-height: 1;
}

._7damzq_statistics ._7damzq_records ._7damzq_item ._7damzq_caption > :last-child {
  font-family: var(--main-font-family-m);
  letter-spacing: .05em;
  margin-top: 9px;
  font-size: 15px;
}

._7damzq_statistics ._7damzq_records ._7damzq_item ._7damzq_value {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 38px 0 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

._7damzq_statistics ._7damzq_records ._7damzq_item ._7damzq_value > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--tomopla-color);
  font-family: var(--main-font-family-b);
  letter-spacing: .03em;
  font-size: 50px;
  overflow: hidden;
}

._7damzq_statistics ._7damzq_records ._7damzq_item ._7damzq_value ._7damzq_unit {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 22px;
  position: absolute;
  bottom: 5px;
  right: 0;
}

._7damzq_modal {
  place-content: center;
  height: 100%;
  padding: 8px 0 24px;
  display: grid;
}

._7damzq_appMenuWrapper {
  justify-content: space-between;
  width: 1630px;
  height: 140px;
  display: flex;
  position: relative;
  top: 40px;
  left: 30px;
}

.teacher-top .app-menu-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: calc(100% - 98px);
  height: 148px;
  padding-bottom: 8px;
  display: flex;
  position: relative;
  top: 40px;
  left: 50px;
  overflow: auto;
}

.tempdelete-form {
  background-color: #fff;
  background-image: url("tempdelete-bk.fd4ce214.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1690px;
  height: 1080px;
  margin: auto;
  display: flex;
  position: relative;
}

.tempdelete-form .title {
  margin-top: 24px;
  margin-bottom: 48px;
}

.tempdelete-form .description {
  margin-bottom: 44px;
}

.tempdelete-form .attention1, .tempdelete-form .attention2 {
  box-sizing: border-box;
  border: 1px solid #333;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.tempdelete-form .attention1 {
  width: 847px;
  height: 181px;
}

.tempdelete-form .attention2 {
  width: 847px;
  height: 206px;
}

.tempdelete-form .attention3 {
  background: #d9d9d9;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 847px;
  height: 130px;
  margin-bottom: 55px;
  display: flex;
}

.tempdelete-form .button {
  box-sizing: border-box;
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 498px;
  height: 70px;
  transition: all .3s ease-in-out;
  box-shadow: 0 1px 3px #000000b3;
}

.tempdelete-form .button:hover {
  background: linear-gradient(#66d2ff 12.03%, #019bff 89.47%);
  border: 1px solid #fff;
  border-radius: 16px;
  box-shadow: 0 2px 6px #000000b3;
}

.tempdelete-form .button:active {
  background: linear-gradient(#016692 12.03%, #014b79 89.47%);
  border: 1px solid #fff;
  border-radius: 16px;
}

.tempdelete-form .modal-overlay {
  z-index: 1000;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.tempdelete-form .modal {
  text-align: center;
  background: linear-gradient(#fff 0%, #ffdcdc 60%);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  width: 857px;
  display: flex;
}

.tempdelete-form .modal h2 {
  background-color: #ffbebe;
  border-radius: 20px 20px 0 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 114px;
  display: flex;
}

.tempdelete-form .modal .msg {
  min-height: 256px;
  display: flex;
}

.tempdelete-form .modal .btnbg {
  background-color: #fff;
  border-radius: 24px;
  flex-direction: column;
  width: 618px;
  margin-bottom: 72px;
  display: flex;
}

.tempdelete-form .delete-button {
  color: #fff;
  cursor: pointer;
  background: linear-gradient(#ffb3b3 10.42%, #ff1a1a 91.67%);
  border: 1px solid #fff;
  border-radius: 15px;
  height: 130px;
  margin: 10px;
  padding: 10px 20px;
  box-shadow: 0 2px 6px #000000b3;
}

.tempdelete-form .delete-button:hover {
  background: linear-gradient(#ffc9c9 10.42%, #ff4d4d 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  box-shadow: 0 1px 3px #000000b3;
}

.tempdelete-form .delete-button:active {
  background: linear-gradient(#cc8080 10.42%, #b30000 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
}

.tempdelete-form .cancel-button, .tempdelete-form .return-button {
  color: #fff;
  letter-spacing: .05em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 15px;
  height: 130px;
  margin: 10px;
  padding: 10px 20px;
  font-family: A-OTF UD Shin Maru Go Pr6N;
  font-size: 40px;
  font-weight: 505;
  line-height: 80px;
  box-shadow: 0 2px 6px #000000b3;
}

.tempdelete-form .cancel-button:hover, .tempdelete-form .return-button:hover {
  background: linear-gradient(#cecedc 10.42%, #9f9fb4 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  box-shadow: 0 1px 3px #000000b3;
}

.tempdelete-form .cancel-button:active, .tempdelete-form .return-button:active {
  background: linear-gradient(#797980 10.42%, #393940 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
}

.page-login {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

.page-login img {
  width: 50%;
}

.icon-eye > img {
  width: 100%;
}

.panel-left {
  background-image: url("group-10783.d2295139.png"), url("group-10783.d2295139.png");
  background-position: right 120px top 73px, left 90px bottom 140px;
  background-repeat: no-repeat, no-repeat;
  background-size: 204px, 204px;
  justify-content: center;
  align-items: center;
  width: 710px;
  padding: 414px 115px 510px 105px;
  display: flex;
}

.page-login .twenty-two {
  width: 100%;
  height: 100%;
}

.btn-back {
  width: 192px;
  height: 65px;
  position: absolute;
  top: 40px;
  left: 40px;
}

.btn-back .view {
  width: 192px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-back .overlap-group-3 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 190px;
  height: 65px;
  position: relative;
  box-shadow: 0 1px 3px #000000b2;
}

.btn-back .text-wrapper-7 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 161px;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 14px;
}

.btn-back .text-wrapper-8 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 14px;
}

.btn-back .text-wrapper-9 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 59px;
}

.btn-back .text-wrapper-10 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 74px;
}

.panel-right {
  background-image: url("group-10783.d2295139.png"), url("group-10783.d2295139.png");
  background-position: right 55px top 88px, left 50% bottom -133px;
  background-repeat: no-repeat, no-repeat;
  background-size: 204px, 204px;
  flex-direction: column;
  flex: 1;
  padding-top: 100px;
  padding-right: 80px;
  display: flex;
}

.sso-login-wrap {
  height: 510px;
}

.page-login .button-sso-login {
  margin-top: 30px;
}

.page-login .button-sso-login:not(.apple):hover {
  background-color: #fff;
}

.sso-login-wrap .area-message {
  margin-top: 25px;
}

.email-login-wrap {
  flex: 1;
  margin-top: 63px;
}

.page-login .separator {
  align-items: center;
  display: flex;
}

.page-login .separator .line {
  background-color: #000;
  width: 405px;
  height: 3px;
}

.page-login .separator .label {
  text-align: center;
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  flex: 1;
  font-size: 20px;
}

.page-login .message {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: .07em;
  white-space: nowrap;
  font-size: 20px;
  line-height: 2;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .page-login .message {
    font-size: 1.25em;
  }
}

.page-login .warning.message {
  color: var(--gold-red);
}

.page-login .label-email-login {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.5px;
  white-space: nowrap;
  font-size: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .page-login .label-email-login {
    font-size: 1.25em;
  }
}

.page-login .email-logo {
  vertical-align: middle;
  background-color: #bedfff;
  border-radius: 2.4px;
  width: 38px;
  height: 26px;
  margin-right: 20px;
  display: inline-block;
  position: relative;
}

.page-login .email-logo-img {
  width: 31px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 4px;
}

.page-login .area-input {
  justify-content: space-between;
  margin-top: 17px;
  display: flex;
}

.page-login .area-input-left {
  width: 565px;
  position: relative;
}

.page-login .area-input-left .icon-eye {
  position: absolute;
  bottom: 23px;
  right: 10px;
}

.page-login .area-input-right {
  flex: 1;
  margin-left: 35px;
}

.page-login .area-input-message {
  width: 100%;
}

.page-login .area-input .inputbox {
  margin-bottom: 20px;
}

.page-login .area-input .inputbox input {
  height: 60px;
}

.page-login .text-wrapper-12 {
  letter-spacing: 1.1px;
  white-space: nowrap;
  line-height: 40px;
  position: absolute;
  top: 919px;
  left: 710px;
}

.page-login .area-input .tps-link {
  line-height: 86px;
}

.page-login .area-input .secondary-button {
  height: 75px;
}

ruby.narrow-ruby rt {
  transform: scale(.66, 1);
}

.MuiButton-root.button-sso-login {
  color: #333;
  font-family: var(--main-font-family-db);
  text-transform: none;
  background-color: #fefefe;
  border: 1px solid #fff;
  border-radius: 16px;
  justify-content: center;
  width: 100%;
  height: 85px;
  padding: 22px 10.4px 22px 32px;
  font-size: 26px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .MuiButton-root.button-sso-login {
    font-size: 1.625em;
  }
}

.MuiButton-root.button-sso-login .MuiButton-startIcon img {
  object-fit: cover;
  width: 45px;
  height: 45px;
  margin-right: 40px;
}

.MuiButton-root.button-sso-login.apple {
  color: #fff;
  background: #000;
  border-color: #000;
}

.MuiButton-root.button-sso-login.apple .MuiButton-startIcon img {
  width: 34px;
  height: 34px;
  margin-right: 4px;
}





.inputbox .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
}

.inputbox .MuiInputBase-input.MuiOutlinedInput-input {
  font-family: var(--main-font-family-db);
  letter-spacing: .07em;
  background-color: #fff;
  border: 5px solid #d9d9d9;
  border-radius: 20px;
  padding: 0 27px;
  font-size: 20px;
  box-shadow: inset 0 2px 6px #000000b2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .inputbox .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 1.25em;
  }
}

.inputbox .MuiOutlinedInput-notchedOutline {
  border: none;
}

.tps-link {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  white-space: nowrap;
  letter-spacing: 2.8px;
  text-underline-offset: 12px;
  font-size: 24px;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px), (any-hover: none) {
  .tps-link {
    font-size: 1.5rem;
  }
}

.link-forgotten {
  width: 299px;
  height: 54px;
  position: relative;
}

.link-forgotten .line {
  width: 299px;
  height: 3px;
  position: absolute;
  top: 51px;
  left: 0;
}

.link-forgotten .group-5 {
  width: 303px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 0;
}

.link-forgotten .text-wrapper-5 {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.3px;
  white-space: nowrap;
  font-size: 26px;
  line-height: 47px;
  position: absolute;
  top: 16px;
  left: 0;
}

.link-forgotten .text-wrapper-6 {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 13px;
  line-height: 47px;
  position: absolute;
  top: 0;
  left: 164px;
}

.MuiButton-root.secondary-button {
  color: #333;
  text-transform: none;
  font-family: var(--main-font-family-db);
  background-color: #f0f0f0;
  border: 1px solid #fff;
  border-radius: 16px;
  width: 100%;
  font-size: 30px;
  position: relative;
  box-shadow: 0 2px 6px #000000b2;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .MuiButton-root.secondary-button {
    font-size: 1.875em;
  }
}

.page-login {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

.page-login img {
  width: 50%;
}

.icon-eye > img {
  width: 100%;
}

.panel-left {
  background-image: url("group-10783.d2295139.png"), url("group-10783.d2295139.png");
  background-position: right 120px top 73px, left 90px bottom 140px;
  background-repeat: no-repeat, no-repeat;
  background-size: 204px, 204px;
  justify-content: center;
  align-items: center;
  width: 710px;
  padding: 414px 115px 510px 105px;
  display: flex;
}

.page-login .twenty-two {
  width: 100%;
  height: 100%;
}

.btn-back {
  width: 192px;
  height: 65px;
  position: absolute;
  top: 40px;
  left: 40px;
}

.btn-back .view {
  width: 192px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-back .overlap-group-3 {
  background: linear-gradient(#b5b5c1 10.42%, #5a5a63 91.67%);
  border: 1px solid #fff;
  border-radius: 16px;
  width: 190px;
  height: 65px;
  position: relative;
  box-shadow: 0 1px 3px #000000b2;
}

.btn-back .text-wrapper-7 {
  color: #fff;
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 161px;
  font-size: 20px;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 14px;
}

.btn-back .text-wrapper-8 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 14px;
}

.btn-back .text-wrapper-9 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 59px;
}

.btn-back .text-wrapper-10 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 74px;
}

.panel-right {
  background-image: url("group-10783.d2295139.png"), url("group-10783.d2295139.png");
  background-position: right 55px top 88px, left 50% bottom -133px;
  background-repeat: no-repeat, no-repeat;
  background-size: 204px, 204px;
  flex-direction: column;
  flex: 1;
  padding-top: 100px;
  padding-right: 80px;
  display: flex;
}

.sso-login-wrap {
  height: 510px;
}

.page-login .button-sso-login {
  margin-top: 30px;
}

.page-login .button-sso-login:not(.apple):hover {
  background-color: #fff;
}

.sso-login-wrap .area-message {
  margin-top: 25px;
}

.email-login-wrap {
  flex: 1;
  margin-top: 63px;
}

.page-login .separator {
  align-items: center;
  display: flex;
}

.page-login .separator .line {
  background-color: #000;
  width: 405px;
  height: 3px;
}

.page-login .separator .label {
  text-align: center;
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  flex: 1;
  font-size: 20px;
}

.page-login .message {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: .07em;
  white-space: nowrap;
  font-size: 20px;
  line-height: 2;
  left: 0;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .page-login .message {
    font-size: 1.25em;
  }
}

.page-login .warning.message {
  color: var(--gold-red);
}

.page-login .label-email-login {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.5px;
  white-space: nowrap;
  font-size: 20px;
}

@media only screen and (device-width >= 810px) and (device-width <= 1080px) {
  .page-login .label-email-login {
    font-size: 1.25em;
  }
}

.page-login .email-logo {
  vertical-align: middle;
  background-color: #bedfff;
  border-radius: 2.4px;
  width: 38px;
  height: 26px;
  margin-right: 20px;
  display: inline-block;
  position: relative;
}

.page-login .email-logo-img {
  width: 31px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 4px;
}

.page-login .area-input {
  justify-content: space-between;
  margin-top: 17px;
  display: flex;
}

.page-login .area-input-left {
  width: 565px;
  position: relative;
}

.page-login .area-input-left .icon-eye {
  position: absolute;
  bottom: 23px;
  right: 10px;
}

.page-login .area-input-right {
  flex: 1;
  margin-left: 35px;
}

.page-login .area-input-message {
  width: 100%;
}

.page-login .area-input .inputbox {
  margin-bottom: 20px;
}

.page-login .area-input .inputbox input {
  height: 60px;
}

.page-login .text-wrapper-12 {
  letter-spacing: 1.1px;
  white-space: nowrap;
  line-height: 40px;
  position: absolute;
  top: 919px;
  left: 710px;
}

.page-login .area-input .tps-link {
  line-height: 86px;
}

.page-login .area-input .secondary-button {
  height: 75px;
}

ruby.narrow-ruby rt {
  transform: scale(.66, 1);
}

.temp-data-delete {
  letter-spacing: -.03em;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: A-OTF UD Shin Maru Go Pr6N;
  font-size: 23px;
  font-weight: 505;
  line-height: 30px;
  position: absolute;
  bottom: 77px;
  right: 81px;
}

.temp-data-delete a {
  color: #2592f0;
  text-decoration: underline;
}

.contact-form {
  background-color: #eee;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-family: sans-serif;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.contact-form .Wrapper {
  position: relative;
}

.contact-form .Wrapper .Container {
  flex-direction: column;
  align-items: center;
  width: 940px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container {
    width: 100vw;
  }
}

.contact-form .Wrapper .Container header {
  width: 100%;
  padding: 100px 0 50px;
}

.contact-form .Wrapper .Container header h1 {
  text-align: center;
  color: #444;
  font-size: 38px;
  font-weight: bold;
  line-height: 72px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container header {
    padding: 50px 0 25px;
  }

  .contact-form .Wrapper .Container header h1 {
    font-size: 24px;
  }
}

.contact-form .Wrapper .Container .Contents {
  background: #fff;
  width: 100%;
  margin-bottom: 40px;
  padding: 60px 100px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents {
    padding: 20px;
  }
}

.contact-form .Wrapper .Container .Contents table.FormList {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 25px;
}

.contact-form .Wrapper .Container .Contents table.FormList th {
  white-space: nowrap;
  width: 264px;
  padding-right: 20px;
  font-weight: bold;
  line-height: 32.4px;
}

.contact-form .Wrapper .Container .Contents table.FormList th, .contact-form .Wrapper .Container .Contents table.FormList td {
  vertical-align: top;
  text-align: left;
  padding: 15px 0;
  font-size: 18px;
  position: relative;
}

.contact-form .Wrapper .Container .Contents table.FormList th .required, .contact-form .Wrapper .Container .Contents table.FormList td .required {
  color: #fff;
  vertical-align: middle;
  background: #ed5d5d;
  border-radius: 20px;
  margin-left: 10px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
}

.contact-form .Wrapper .Container .Contents table.FormList input[type="text"], .contact-form .Wrapper .Container .Contents table.FormList input[type="email"], .contact-form .Wrapper .Container .Contents table.FormList input[type="tel"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  padding: 10px;
  font-size: 18px;
}

.contact-form .Wrapper .Container .Contents table.FormList textarea {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 300px;
  padding: 10px;
  font-size: 18px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents table.FormList th, .contact-form .Wrapper .Container .Contents table.FormList td {
    font-size: 16px;
    display: block;
  }

  .contact-form .Wrapper .Container .Contents table.FormList input[type="text"], .contact-form .Wrapper .Container .Contents table.FormList input[type="email"], .contact-form .Wrapper .Container .Contents table.FormList textarea {
    font-size: 16px;
  }
}

.contact-form .Wrapper .Container .Contents table.FormList .select-wrap select {
  appearance: none;
  box-sizing: border-box;
  color: #000;
  background: #fff url("select.237b2c0c.png") 96% / 15px 9px no-repeat;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 267px;
  height: 45px;
  padding: 0 10px;
  font-size: 18px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents table.FormList .select-wrap select {
    width: 100%;
  }
}

.contact-form .Wrapper .Container .Contents table.FormList select::-ms-expand {
  display: none;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom {
  margin-bottom: 12px;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom input {
  display: none;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom input + span.radio_custom_field-text {
  margin-right: 40px;
  padding-left: 25px;
  display: inline-block;
  position: relative;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom input + span.radio_custom_field-text:before {
  content: "";
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom input:checked + span.radio_custom_field-text:before {
  background: #fff;
  border: 3px solid #355cbe;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom input:checked + span.radio_custom_field-text:after {
  content: "";
  background: #355cbe;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
}

.contact-form .Wrapper .Container .Contents table.FormList span.radio_custom_field-text {
  display: inline-block;
}

.contact-form .Wrapper .Container .Contents table.FormList .radio_custom span.radio_custom_field label.active {
  background: #1f215d;
}

.contact-form .Wrapper .Container .Contents .btn_wrap {
  flex-direction: row-reverse;
  justify-content: center;
  display: flex;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents .btn_wrap {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
}

.contact-form .Wrapper .Container .Contents .btn_wrap .btn {
  margin: 0 20px;
}

.contact-form .Wrapper .Container .Contents .btn_wrap .btn button {
  color: #fff;
  cursor: pointer;
  background: #355cbe;
  border: none;
  border-radius: 10px;
  min-width: 300px;
  padding: 15px;
  font-size: 30px;
  font-weight: bold;
  transition: all .3s;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents .btn_wrap .btn button {
    font-size: 20px;
  }
}

.contact-form .Wrapper .Container .Contents .btn_wrap .btn button:hover {
  color: #fff;
  background: #1f215d;
}

.contact-form .Wrapper .Container .Contents .btn_wrap .btn.btn_back button {
  color: #fff;
  background: #999;
  border-color: #999;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents .btn_wrap .btn.btn_back button {
    font-size: 20px;
  }
}

.contact-form .Wrapper .Container .Contents .btn_wrap .btn.btn_back button:hover {
  background: #333;
  border-color: #333;
}

.contact-form .Wrapper .Container .Contents .pagettl {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  line-height: 34px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents .pagettl {
    font-size: 20px;
  }
}

.contact-form .Wrapper .Container .Contents .form_txt {
  margin-bottom: 20px;
}

.contact-form .Wrapper .Container .Contents table.Confirm_list {
  color: #444;
  border-top: 1px solid #ccc;
}

.contact-form .Wrapper .Container .Contents table.Confirm_list th, .contact-form .Wrapper .Container .Contents table.Confirm_list td {
  vertical-align: top;
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  font-size: 18px;
  line-height: 32.4px;
  position: relative;
}

.contact-form .Wrapper .Container .Contents table.Confirm_list th {
  width: 143.66px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents table.Confirm_list th {
    border-bottom: none;
    padding-bottom: 0;
  }

  .contact-form .Wrapper .Container .Contents table.Confirm_list td {
    padding-top: 0;
  }

  .contact-form .Wrapper .Container .Contents table.Confirm_list th, .contact-form .Wrapper .Container .Contents table.Confirm_list td {
    font-size: 16px;
    line-height: 1.7;
    display: block;
  }
}

.contact-form .Wrapper .Container .Contents .caution_box {
  margin-bottom: 60px;
}

.contact-form .Wrapper .Container .Contents .caution_box .caution {
  margin-bottom: 10px;
  padding-left: 1em;
  font-size: 14px;
  line-height: 25.2px;
}

.contact-form .Wrapper .Container .Contents .caution_box .caution span {
  margin-left: -1em;
}

.contact-form .Wrapper .Container .Contents .caution_box .caution a {
  text-decoration: underline;
}

.contact-form .Wrapper .Container .Contents .message {
  text-align: center;
  margin: 90px 0 40px;
  font-size: 18px;
  line-height: 32.4px;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container .Contents .message {
    margin: 40px 0 20px;
  }
}

.contact-form .Wrapper .Container .Contents .btn_return a {
  background: #fff;
  border: 4px solid #355cbe;
  border-radius: 30px;
  padding: 12px 30px;
  font-size: 16px;
  line-height: 1;
  display: inline-block;
}

.contact-form .Wrapper .Container .Contents .Txt_Box {
  color: #444;
  font-size: 16px;
  line-height: 1.5;
}

.contact-form .Wrapper .Container .Contents .Txt_Box p {
  margin-bottom: 40px;
}

.contact-form .Wrapper .Container .Contents .Txt_Box .bold {
  font-weight: bold;
}

.contact-form .Wrapper .Container .Contents .Txt_Box .right {
  text-align: right;
}

.contact-form .Wrapper .Container .Contents .Txt_Box p + ol {
  margin-top: -20px;
}

.contact-form .Wrapper .Container .Contents .Txt_Box ol {
  padding-left: 1.25em;
  list-style: auto;
}

.contact-form .Wrapper .Container .Contents .Txt_Box ol li {
  margin-bottom: 10px;
}

.contact-form .Wrapper .Container .Contents .Txt_Box ol ol {
  margin-top: 10px;
  margin-bottom: 40px;
  padding-left: 35px;
  list-style: none;
}

.contact-form .Wrapper .Container .Contents .Txt_Box ol ol li span.inner {
  width: 35px;
  margin-left: -35px;
  display: inline-block;
}

.contact-form .Wrapper .Container footer {
  width: 100%;
  margin-top: 48px;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.contact-form .Wrapper .Container footer .foot_nav {
  text-align: center;
  color: #444;
}

.contact-form .Wrapper .Container footer .foot_nav a {
  color: #444;
}

.contact-form .Wrapper .Container footer .foot_nav li {
  display: inline-block;
}

.contact-form .Wrapper .Container footer .foot_nav li + li:before {
  content: "｜";
  margin: 0 20px;
}

.contact-form .Wrapper .Container footer .btn_wrap {
  flex-direction: row-reverse;
  justify-content: center;
  display: flex;
  position: absolute;
  top: -48px;
  left: 0;
}

.contact-form .Wrapper .Container footer .btn_wrap.thanks {
  margin: auto;
  top: -163px;
  left: 0;
  right: 0;
}

@media screen and (width <= 767px) {
  .contact-form .Wrapper .Container footer .btn_wrap.thanks {
    top: -47px;
  }
}

.contact-form .Wrapper .Container footer .btn_wrap p.btn_return a {
  background: #fff;
  border: 4px solid #355cbe;
  border-radius: 30px;
  padding: 12px 30px;
  font-size: 16px;
  line-height: 1;
  display: inline-block;
}

.contact-form .Wrapper a {
  color: #444;
  text-decoration: none;
}

.contact-form .Wrapper a:hover {
  opacity: .8;
  transition: all .3s;
}

.contact-form .Wrapper .err {
  color: #ed5d5d;
}

/*# sourceMappingURL=index.9ff75f93.css.map */
