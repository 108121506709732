.main-button {
  all: unset;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  height: 70px;
  position: relative;
  width: 130px;

  background: linear-gradient(180deg, rgb(41, 171, 226) 5.21%, rgb(1, 113, 189) 100%);
  box-shadow: 0px 2px 6px #000000b2;

  display: flex;
  justify-content: center;
  align-items: end;

  .text-wrapper {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 30px;

    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover:none) {
      font-size: 1.875rem;

      .kanji-kana {
        top: -1.2em;
      }
    }

    ;

    left: 14px;
    letter-spacing: 1.6px;
    line-height: normal;
    // position: absolute;
    text-align: center;
    top: 6px;
    // width: 100px;
  }

  .div {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 17px;

    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover:none) {
      font-size: 1.0625rem;
    }

    ;

    left: 63px;
    letter-spacing: -0.85px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -4px;
    white-space: nowrap;
  }

  .text-wrapper-2 {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 17px;

    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover:none) {
      font-size: 1.0625rem;
    }

    ;

    left: 29px;
    letter-spacing: -0.85px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -4px;
    white-space: nowrap;
  }

  &:hover {
    background: linear-gradient(180deg, rgb(102, 210, 255) 12.03%, rgb(1, 155, 255) 89.47%);
    box-shadow: 0px 2px 6px #000000b2;
  }

  &:active {
    background: linear-gradient(180deg, rgb(1, 102, 146) 12.03%, rgb(1, 75, 121) 89.47%);
    box-shadow: none;
  }
}