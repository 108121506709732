.modal-sub-button {
  all: unset;
  height: 70px;
  width: 130px;
  border-radius: 16px;

  background: linear-gradient(180deg, rgb(181, 181, 193) 10.42%, rgb(90, 90, 99) 91.67%);
  box-shadow: 0px 2px 6px #000000b2;

  display: flex;
  justify-content: center;
  align-items: end;

  .text-wrapper {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 30px;

    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover:none) {
      font-size: 1.875rem;

      .kanji-kana {
        top: -1.2em;
      }
    }

    ;

    left: 14px;
    letter-spacing: 1.6px;
    line-height: normal;
    // position: absolute;
    text-align: center;
    top: 6px;
    // width: 100px;
  }

  .div {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 17px;

    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover:none) {
      font-size: 1.0625rem;
    }

    ;

    left: 63px;
    letter-spacing: -0.85px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -4px;
    white-space: nowrap;
  }

  &:hover {
    background: linear-gradient(180deg, rgb(206, 206, 220) 10.42%, rgb(159, 159, 180) 91.67%);
    box-shadow: 0px 2px 6px #000000b2;
  }

  &:active {
    background: linear-gradient(180deg, rgb(121, 121, 128) 10.42%, rgb(57, 57, 64) 91.67%);
    box-shadow: none;
  }

}