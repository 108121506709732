.kanji-wrapper {
  position: relative;
}

.kanji-wrapper .kanji-kana {
  display: block;
  font-size: 0.5em;
  position: absolute;
  top: -1.2em;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    top: -1.5em;
  }

  ;
  left: 50%;
  letter-spacing: 0;
  line-height: 1;
  transform: translateX(-50%);
  width: max-content;
}