.tps-link {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem;};
  white-space: nowrap;
  /*  */
  letter-spacing: 2.8px;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 12px;

  
}

.link-forgotten {
  height: 54px;
  position: relative;
  width: 299px;
}

.link-forgotten .line {
  height: 3px;
  left: 0;
  position: absolute;
  top: 51px;
  width: 299px;
}

.link-forgotten .group-5 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 303px;
}

.link-forgotten .text-wrapper-5 {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  font-size: 26px;
  /*  */
  left: 0;
  letter-spacing: 1.3px;
  line-height: 47px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.link-forgotten .text-wrapper-6 {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  font-size: 13px;
  /*  */
  left: 164px;
  letter-spacing: 0;
  line-height: 47px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}
