.password-reset-done {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.password-reset-done .overlap-wrapper-6 {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
}

.password-reset-done .overlap-41 {
  height: 1080px;
  position: relative;
}

.password-reset-done .div-13 {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.password-reset-done .group-119 {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.password-reset-done .frame-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.password-reset-done .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.password-reset-done .ellipse-15 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.password-reset-done .group-120 {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.password-reset-done .group-121 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.password-reset-done .group-122 {
  height: 204px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.password-reset-done .sub-button-2 {
  background: linear-gradient(
    180deg,
    rgb(181, 181, 193) 10.42%,
    rgb(90, 90, 99) 91.67%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  left: 600px;
  position: absolute;
  top: 668px;
  width: 490px;
}

.password-reset-done .text-wrapper-214 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  
  left: 8px;
  letter-spacing: -2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
  width: 471px;
}

.password-reset-done .navbar-3 {
  height: 83px;
  left: 388px;
  position: absolute;
  top: 455px;
  width: 930px;
}

.password-reset-done .overlap-group-37 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 914px;
}

.password-reset-done .text-wrapper-215 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};
  left: 0;
  letter-spacing: 1.3px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: -13px;
  width: 914px;
}

.password-reset-done .text-wrapper-216 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:23px;left:180px;};
  left: 186px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 26px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-217 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:23px;left:208px;};
  left: 212px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 26px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-218 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:-9px;};
  left: 511px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-219 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:-9px;};
  left: 536px;
  letter-spacing: -2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-220 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:-9px;};
  left: 403px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-221 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:-9px;};
  left: 430px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-222 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;top:-9px;};
  left: 457px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-done .main-header-2 {
  height: 57px;
  left: 515px;
  position: absolute;
  top: 367px;
  width: 667px;
}

.password-reset-done .text-wrapper-223 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 330px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -20px;
  white-space: nowrap;
}

.password-reset-done .text-wrapper-224 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 374px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -20px;
  white-space: nowrap;
}

.password-reset-done .main-header-3 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 42px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.88125em;};
  left: 0;
  letter-spacing: 2.1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 11px;
  white-space: nowrap;
  width: 661px;
}

.password-reset-done .title-3 {
  background-image: url(../../../static/img/22.svg);
  background-size: 100% 100%;
  height: 143px;
  left: 621px;
  position: absolute;
  top: 186px;
  width: 448px;
}
