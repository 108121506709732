.student-visual {
  background-color: #fff;
  background-size: 100% 100%;
  border-radius: 20px;
  height: 980px;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1633px;
}

/*TBD: テーマカラー背景　テストです*/
.student-visual.light {
  background-color: #fafafa;
}

.student-visual.beige {
  background-color: #fafaf5;
}

.student-visual.dark {
  background-color: #323232;
}

.student-visual.blue {
  background-color: #E6F0FF;
}

.student-visual.green {
  background-color: #DAF2E6;
}

.student-visual.pink {
  background-color: #FFD9E3;
}

.student-visual .modal-title-bar {
  background-color: var(--tomopla-color);
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1633px;
  border-radius: 20px 20px 0 0;
}

/* TBD:テーマカラータイトルバー テストです*/
.student-visual.light .modal-title-bar {
  background-color: #F97253;
}

.student-visual.beige .modal-title-bar {
  background-color: #A6976C;
}

.student-visual.dark .modal-title-bar {
  background-color: #4D4D4D;
}

.student-visual.blue .modal-title-bar {
  background-color: #0A93F5;
}

.student-visual.green .modal-title-bar {
  background-color: #00B259;
}

.student-visual.pink .modal-title-bar {
  background-color: #FF8196;
}

.student-visual .main-button-18 {
  left: 1403px;
  position: absolute;
  top: 850px;
}

.student-visual .text-wrapper-789 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 32px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2rem;};
  /*  */
  left: 14px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 100px;
}

.student-visual .text-wrapper-790 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 17px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.0625rem;};
  /*  */
  left: 63px;
  letter-spacing: -0.85px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.student-visual .text-wrapper-791 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 17px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.0625rem;};
  /*  */
  left: 29px;
  letter-spacing: -0.85px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.student-visual .select-color {
  height: 630px;
  left: 252px;
  position: absolute;
  top: 235px;
  width: 1148px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.student-visual .select-color .row {
  border: 8px solid transparent;
  height: 246px;
  width: 364px;
}

.student-visual .select-color .row.selected {
  border: 8px solid #0A93F5;
  height: 246px;
  width: 364px;
}

.student-visual .row-3 {
  align-items: flex-start;
  display: flex;
  gap: 50px;
  left: 12px;
  /* padding: 0px 1007px 0px 0px; */
  position: absolute;
  top: 322px;
  width: 1096px;
  justify-content: space-between;
}

.student-visual .select-color-blue {
  /* height: 258.98px; */
  margin-bottom: -0.98px;
  /* margin-right: -243px; */
  position: relative;
  width: 332px;
}

.student-visual .select-color-green {
  /* height: 259.24px; */
  margin-bottom: -1.24px;
  /* margin-right: -625px; */
  position: relative;
  width: 332px;
}

.student-visual .select-color-pink {
  height: 259.18px;
  margin-bottom: -1.18px;
  /* margin-right: -1007px; */
  position: relative;
  width: 332px;
}

.student-visual .overlap-group-129 {
  height: 270px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1108px;
}

.student-visual .row-4 {
  align-items: flex-start;
  display: flex;
  gap: 50px;
  left: 12px;
  /* padding: 0px 1007px 0px 0px; */
  position: absolute;
  top: 12px;
  width: 1096px;
  justify-content: space-between;
}

.student-visual .select-color-light {
  /* height: 259.44px; */
  margin-bottom: -1.44px;
  /* margin-right: -243px; */
  position: relative;
  width: 332px;
}

.student-visual .select-color-beize {
  /* height: 258.96px; */
  margin-bottom: -0.96px;
  /* margin-right: -625px; */
  position: relative;
  width: 332px;
}

.student-visual .select-color-dark {
  /* height: 259.22px; */
  margin-bottom: -1.22px;
  /* margin-right: -1007px; */
  position: relative;
  width: 332px;
}

.student-visual .selected-2 {
  height: 236px;
  left: 0;
  position: absolute;
  top: 0;
  width: 356px;
}

.student-visual .subtitle {
  height: 50px;
  left: 267px;
  position: absolute;
  top: 136px;
  width: 370px;
}

.student-visual .text-wrapper-792 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 35px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.1875rem;};
  /*  */
  left: 0;
  letter-spacing: 1.75px;
  line-height: normal;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.student-visual .text-wrapper-793 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 17px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.0625rem;};
  /*  */
  left: 267px;
  letter-spacing: 0.85px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.student-visual .sub-button-close-3 {
  height: 66px;
  left: 1478px;
  position: absolute;
  top: 15px;
  width: 140px;
}

.student-visual .overlap-147 {
  height: 66px;
  position: relative;
}

.student-visual .active-a-5 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.student-visual .overlap-group-130 {
  background-image: url(../../../../static/img/rectangle-18.svg);
  background-size: 100% 100%;
  height: 78px;
  left: -6px;
  position: relative;
  top: -4px;
  width: 152px;
}

.student-visual .group-488 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  left: 15px;
  position: relative;
  top: 12px;
  width: 50px;
}

.student-visual .group-489 {
  height: 30px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 30px;
}

.student-visual .text-wrapper-794 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 64px;
  letter-spacing: 0;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
}

.student-visual .message {
  height: 57px;
  left: 729px;
  position: absolute;
  top: 11px;
  width: 173px;
}

.student-visual .text-wrapper-795 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  /*  */
  left: 5px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.student-visual .view-347 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 177px;
}

.student-visual .text-wrapper-796 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 0;
  letter-spacing: -3px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.student-visual .text-wrapper-797 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 56px;
  letter-spacing: -3px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.student-visual .text-wrapper-798 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 88px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.student-visual .text-wrapper-799 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 130px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.student-visual .wrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.student-visual .wrap .name {
  color: var(--, #333);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  font-style: normal;
  /*  */
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: 33px;
  left: 16px;
}