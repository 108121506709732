text-wrapper-11.password-reset-input {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.password-reset-input {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}

.password-reset-input form{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1690px;
}

.password-reset-input.loading {
  position: absolute;
}

.password-reset-input img.loading {
  width: 17.5%;
  height: 17.5%;
}

.password-reset-input .overlap-wrapper-7 {
  background-color: #ffffff;
  height: 1080px;
  overflow: hidden;
  width: 1690px;
}

.password-reset-input .overlap-42 {
  height: 1080px;
  position: relative;
}

.password-reset-input .div-14 {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.password-reset-input .group-123 {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.password-reset-input .frame-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.password-reset-input .frame-16 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.password-reset-input .ellipse-16 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.password-reset-input .group-124 {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.password-reset-input .group-125 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.password-reset-input .group-126 {
  height: 204px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.password-reset-input .main-button-6 {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  left: 700px;
  position: absolute;
  top: 853px;
  width: 290px;
}

.password-reset-input .text-wrapper-225 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 2.5rem;
  }

  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
  width: 268px;
}

.password-reset-input .text-wrapper-226 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.1875rem;
  }

  left: 84px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-227 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.1875rem;
  }

  left: 124px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-228 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.1875rem;
  }

  left: 165px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset-input .block-password {
  height: 107px;
  left: 391px;
  position: absolute;
  top: 704px;
  width: 912px;
}

.password-reset-input .input-password-2 {
  height: 55px;
  left: 4px;
  position: absolute;
  top: 52px;
  width: 900px;
}

.password-reset-input .group-127 {
  height: 55px;
}

.password-reset-input .group-128 {
  height: 55px;
  width: 900px;
}

.password-reset-input .overlap-group-38 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 920px;
}

.password-reset-input .rectangle-23 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 900px;
}

.password-reset-input .text-wrapper-229 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: -7px;
  }

  left: 249px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-230 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: -7px;
  }

  left: 277px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-231 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: -7px;
  }

  left: 0;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .label-password {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.625rem;
  }

  left: 3px;
  letter-spacing: 1.3px;
  line-height: 47px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.password-reset-input .sub-button-3 {
  background: linear-gradient(
    180deg,
    rgb(181, 181, 193) 10.42%,
    rgb(90, 90, 99) 91.67%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 55px;
  left: 1437px;
  position: absolute;
  top: 623px;
  width: 160px;
}

.password-reset-input .text-wrapper-232 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 26px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.625rem;
  }

  left: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-233 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 34px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-234 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-235 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 79px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-236 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 106px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}

.password-reset-input .eye-icon {
  height: 40px;
  right: 19px;
  position: absolute;
  top: 67px;
  width: 46px;
}

.password-reset-input .block-password-2 {
  height: 107px;
  left: 391px;
  position: absolute;
  top: 569px;
  width: 912px;
}

.password-reset-input .input-password-3 {
  height: 55px;
  left: 4px;
  position: absolute;
  top: 53px;
  width: 900px;
}

.password-reset-input .message-warning-3 {
  height: 35px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  top: 497px;
  width: 908px;
}

.password-reset-input .text-wrapper-237 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 201px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-238 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 182px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .message-warning-RHF {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.375rem;
  }

  left: 0;
  letter-spacing: 1.2px;
  line-height: 39px;
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
  width: 100%;
}

.password-reset-input .message-warning-4 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.375rem;
  }

  left: 0;
  letter-spacing: 1.2px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
  width: 377px;
}

.password-reset-input .main-message-2 {
  height: 83px;
  left: 463px;
  position: absolute;
  top: 390px;
  width: 778px;
}

.password-reset-input .overlap-43 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 764px;
}

.password-reset-input .text-wrapper-239 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.625rem;
  }

  left: 0;
  letter-spacing: 1.3px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: -13px;
  width: 764px;
}

.password-reset-input .text-wrapper-240 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: 25px;
  }

  left: 391px;
  letter-spacing: -1.92px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
  width: 33px;
}

.password-reset-input .div-15 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: 25px;
  }

  left: 421px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
  width: 30px;
}

.password-reset-input .text-wrapper-241 {
  letter-spacing: -3.36px;
}

.password-reset-input .text-wrapper-242 {
  letter-spacing: -2.88px;
}

.password-reset-input .text-wrapper-243 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: 25px;
  }

  left: 147px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-244 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: -7px;
  }

  left: 370px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-245 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: -7px;
  }

  left: 398px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-246 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
    top: -7px;
  }

  left: 447px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .main-header-4 {
  height: 59px;
  left: 648px;
  position: absolute;
  top: 300px;
  width: 403px;
}

.password-reset-input .text-wrapper-247 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.25rem;
  }

  left: 264px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -19px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-248 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.25rem;
  }

  left: 308px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -19px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-249 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.25rem;
  }

  left: 353px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -19px;
  white-space: nowrap;
}

.password-reset-input .main-header-5 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 42px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.88125em;
  }

  left: 0;
  letter-spacing: 2.1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
  width: 395px;
}

.password-reset-input .title-4 {
  background-image: url(../../../static/img/21.svg);
  background-size: 100% 100%;
  height: 143px;
  left: 621px;
  position: absolute;
  top: 126px;
  width: 448px;
}

.password-reset-input .text-wrapper-11 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 208px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-12 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 464px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-21 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 179px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-22 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 444px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-23 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 516px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-31 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 224px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-32 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 418px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-41 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 122px;
  letter-spacing: 0.5px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-42 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 366px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-51 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 38px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-52 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 316px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-53 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 541px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-54 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 674px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-61 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 158px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-62 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 389px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-input .text-wrapper-63 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 0.6875rem;
  }

  left: 506px;
  letter-spacing: -0.5px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.rubi {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  position: absolute;
}

.text-wrapper-71 {
  top: 0px;
  left: 68px;
  letter-spacing: 0px;
}

.text-wrapper-72 {
  top: 0px;
  left: 222px;
  letter-spacing: -0.5px;
}

.text-wrapper-73 {
  top: 0px;
  left: 485px;
  letter-spacing: 1px;
}

.text-wrapper-74 {
  top: 0px;
  left: 706px;
  letter-spacing: 1px;
}

.text-wrapper-75 {
  top: 40px;
  left: 178px;
  letter-spacing: 1px;
}

.text-wrapper-76 {
  top: 40px;
  left: 370px;
  letter-spacing: -0.5px;
}

.text-wrapper-77 {
  top: 40px;
  left: 478px;
  letter-spacing: 1px;
}
