.teacher-avatar {
  /* background-image: url(../../../../static/img/vector-51.svg); */
  background-size: 100% 100%;
  height: 980px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1633px;
}

.teacher-avatar .main-button-10 {
  left: 1282px;
  position: absolute;
  top: 815px;
}

.teacher-avatar .text-wrapper-276 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 32px;
  
  left: 14px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 100px;
}

.teacher-avatar .text-wrapper-277 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 17px;
  
  left: 63px;
  letter-spacing: -0.85px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -3px;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-278 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 17px;
  
  left: 29px;
  letter-spacing: -0.85px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -3px;
  white-space: nowrap;
}

.teacher-avatar .sub-button {
  /* height: 70px; */
  left: 1122px;
  position: absolute;
  top: 815px;
  /* width: 130px; */
}

.teacher-avatar .group-257 {
  height: 70px;
}

.teacher-avatar .group-258 {
  height: 70px;
  width: 134px;
}

.teacher-avatar .overlap-group-79 {
  /* background: linear-gradient(180deg,
      rgb(181, 181, 193) 10.42%,
      rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 70px;
  position: relative;
  width: 130px; */
}

.teacher-avatar .text-wrapper-279 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 28px;
  
  left: 14px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 100px;
}

.teacher-avatar .text-wrapper-280 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 14px;
  
  left: 35px;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2px;
  white-space: nowrap;
}

/* .teacher-avatar .current-avatar {
  border: 7px solid transparent;
  background: linear-gradient(180deg, #F9C2B6 -15.69%, #F97354 75.07%, #F97253 75.09%) border-box border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
  mask-composite: exclude;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
  border-radius: 16px;
  height: 360px;
  left: 1052px;
  position: absolute;
  top: 333px;
  width: 360px;
} */

.teacher-avatar .avatar-boxshadow {
  top: 333px;
  left: 1052px;
  width: 360px;
  height: 360px;
  position: absolute;
  top: 333px;
  left: 1052px;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
}

.teacher-avatar .group-259 {
  height: 346px;
}

.teacher-avatar .line-9 {
  /* height: 3px; */
  left: 220px;
  position: absolute;
  top: 782px;
  width: 698px;
}

.teacher-avatar .select-avatar {
  height: 460px;
  left: 292px;
  position: absolute;
  top: 283px;
  width: 555px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.teacher-avatar .group-262 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 0;
  width: 555px;
}

.teacher-avatar .group-263 {
  height: 80px;
}

.teacher-avatar .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  gap: 15px;
  position: relative;
}

.teacher-avatar .group-264 {
  background-color: var(--white);
  border: 7px solid;
  border-color: var(--tomopla-color-mid);
  border-radius: 16px;
  height: 80px;
  position: relative;
  width: 80px;
}

.teacher-avatar .group-265 {
  background-color: var(--white);
  border: 7px solid;
  border-color: var(--gray);
  border-radius: 16px;
  height: 80px;
  position: relative;
  width: 80px;
}

.teacher-avatar .thumbnail {
  height: 71px;
}

.teacher-avatar .group-266 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 190px;
  width: 555px;
}

.teacher-avatar .group-267 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 95px;
  width: 555px;
}

.teacher-avatar .group-268 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 285px;
  width: 555px;
}

.teacher-avatar .group-269 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 380px;
  width: 555px;
}

.teacher-avatar .line-10 {
  /* height: 3px; */
  left: 220px;
  position: absolute;
  top: 242px;
  width: 698px;
}

.teacher-avatar .navbar-5 {
  height: 44px;
  left: 222px;
  position: absolute;
  top: 179px;
  width: 355px;
}

.teacher-avatar .text-wrapper-281 {
  /* color: var(--letter-black); */
  font-family: var(--main-font-family-db);
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  
  left: 0;
  letter-spacing: 1.5px;
  line-height: normal;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-282 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.9375rem;};
  
  left: 197px;
  letter-spacing: 0.75px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-283 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.9375rem;};
  
  left: 220px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-284 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.9375rem;};
  
  left: 283px;
  letter-spacing: -1.5px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-285 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.9375rem;};
  
  left: 315px;
  letter-spacing: -1.5px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.teacher-avatar .modal-title-bar-3 {
  background-color: var(--tomopla-color);
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1633px;
}

.teacher-avatar .view-272 {
  height: 58px;
  left: 650px;
  position: absolute;
  top: 10px;
  width: 334px;
}

.teacher-avatar .group-270 {
  height: 58px;
  position: relative;
  width: 340px;
}

.teacher-avatar .text-wrapper-286 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 26px;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-287 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 253px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.teacher-avatar .text-wrapper-288 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  
  left: 294px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.teacher-avatar .view-273 {
  height: 66px;
  left: 1478px;
  position: absolute;
  top: 15px;
  width: 140px;
}

.teacher-avatar .overlap-68 {
  height: 66px;
  position: relative;
}

.teacher-avatar .active-a-3 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.teacher-avatar .overlap-group-80 {
  background-image: url(../../../../static/img/rectangle-18-6.svg);
  background-size: 100% 100%;
  height: 78px;
  left: -6px;
  position: relative;
  top: -4px;
  width: 152px;
}

.teacher-avatar .group-271 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  left: 15px;
  position: relative;
  top: 12px;
  width: 50px;
}

.teacher-avatar .group-272 {
  height: 30px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 30px;
}

.teacher-avatar .text-wrapper-289 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  
  left: 64px;
  letter-spacing: 0;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 23px;
  white-space: nowrap;
}