.tempdelete-form {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; // 必要なら中央揃え
    width: 1690px;
    height: 1080px;
    margin: auto;
    position: relative;
    background-image: url(../../../static/img/tempdelete-bk.png);
    background-size: cover; // 背景画像を幅と高さに合わせて拡大/縮小
    background-position: center; // 背景画像を中央揃え
    background-repeat: no-repeat; // 背景画像の繰り返しを無効化

    .title {
        margin-top: 24px;
        margin-bottom: 48px;
    }

    .description {
        margin-bottom: 44px;
    }

    .attention1,
    .attention2 {
        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        border: 1px solid #333333;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .attention1 {
        width: 847px;
        height: 181px;
    }

    .attention2 {
        width: 847px;
        height: 206px;
    }


    .attention3 {
        width: 847px;
        height: 130px;

        display: flex;
        justify-content: center;
        align-items: center;
        /* Caution */
        background: #D9D9D9;
        border-radius: 10px;
        margin-bottom: 55px;
    }

    .button {
        /* Default */
        width: 498px;
        height: 70px;
        box-sizing: border-box;
        background: linear-gradient(180deg, #29ABE2 5.21%, #0171BD 100%);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
        border-radius: 16px;
        transition: all 0.3s ease-in-out; // ディレイとアニメーション効果

        &:hover {
            /* キーブルー/キーブルーMouseOver */
            background: linear-gradient(180deg, #66D2FF 12.03%, #019BFF 89.47%);
            border: 1px solid #FFFFFF;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.7);
            border-radius: 16px;
        }

        &:active {
            /* キーブルー/キーブルーOnPress */
            background: linear-gradient(180deg, #016692 12.03%, #014B79 89.47%);
            border: 1px solid #FFFFFF;
            border-radius: 16px;
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .modal {
        // padding: 20px;
        border-radius: 20px;
        text-align: center;
        width: 857px;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFDCDC 60%);
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            height: 114px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 190, 190, 1);
            border-radius: 20px 20px 0 0;
            width: 100%;
        }

        .msg {
            display: flex;
            min-height: 256px;
        }

        .btnbg {
            background-color: #fff;
            width: 618px;
            border-radius: 24px;
            display: flex;
            flex-direction: column;
            margin-bottom: 72px;
        }
    }

    .delete-button {
        background: red;
        height: 130px;
        color: #fff;
        border: none;
        padding: 10px 20px;
        margin: 10px;
        border-radius: 15px;
        cursor: pointer;
        background: linear-gradient(180deg, #FFB3B3 10.42%, #FF1A1A 91.67%);
        border: 1px solid rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.7);

        &:hover {
            background: linear-gradient(180deg, #FFC9C9 10.42%, #FF4D4D 91.67%);
            border: 1px solid #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
            border-radius: 16px;
        }

        &:active {
            background: linear-gradient(180deg, #CC8080 10.42%, #B30000 91.67%);
            border: 1px solid #FFFFFF;
            border-radius: 16px;
        }
    }

    .cancel-button,
    .return-button {
        background: gray;
        height: 130px;
        color: #fff;
        font-family: A-OTF UD Shin Maru Go Pr6N;
        font-size: 40px;
        font-weight: 505;
        line-height: 80px;
        letter-spacing: 0.05em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        border: none;
        padding: 10px 20px;
        margin: 10px;
        border-radius: 15px;
        cursor: pointer;
        background: linear-gradient(180deg, #B5B5C1 10.42%, #5A5A63 91.67%);
        border: 1px solid rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.7);

        &:hover {
            /* グレーボタン/グレーボタンMouseOver */
            background: linear-gradient(180deg, #CECEDC 10.42%, #9F9FB4 91.67%);
            border: 1px solid #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
            border-radius: 16px;
        }

        &:active {
            /* プロパティ1=OnPress */
            /* グレーボタン/ボタングレーOnPress */
            background: linear-gradient(180deg, #797980 10.42%, #393940 91.67%);
            border: 1px solid #FFFFFF;
            border-radius: 16px;
        }
    }
}
