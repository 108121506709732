.student-top {
  /* height: 1080px;
  width: 1690px; */
}

.student-top .div-2 {
  background-color: #ffffff;
  height: 1080px;
  position: relative;
}

.student-top .overlap-wrapper {
  height: 226px;
  /* left: 0;
  position: absolute;
  top: 854px; */
  width: 1690px;
  position: fixed;
  bottom: 0;
}

.student-top .overlap {
  height: 226px;
  position: relative;
}

.student-top .group-wrapper {
  height: 210px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 1690px;
}

.student-top .overlap-group-wrapper {
  /* background-image: url(../../../static/img/image-2b.svg); */
  background-size: 100% 100%;
  height: 210px;
}

.student-top .overlap-group-2 {
  background-image: url(../../../static/img/mask-group.png);
  background-size: 100% 100%;
  height: 210px;
  width: 1690px;
}

.student-top .app-menu-wrapper {
  height: 148px;
  left: 30px;
  position: relative;
  top: 40px;
  width: 1630px;
  display: flex;
  justify-content: center;
  gap:20px;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 8px;
}

.student-top .app-menu-wrapper::-webkit-scrollbar {
  background-color: var(--gray);
  width: 12px;
  border-radius: 20px;

}

.student-top .app-menu-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray-2);
  border-radius: 20px;
}


.student-top .app-menu-instance {
  width: unset !important;
}

.student-top .design-component-instance-node {
  width: 1630px !important;
}

.student-top .group-3 {
  height: 32px;
  left: 30px;
  position: absolute;
  top: 0;
  width: 178px;
}

.student-top .group-4 {
  height: 32px;
  width: 180px;
}

.student-top .overlap-group-3 {
  background-image: url(../../../static/img/rectangle-499.svg);
  background-size: 100% 100%;
  height: 32px;
  position: relative;
  width: 176px;
}

.student-top .text-wrapper-300 {
  background: transparent;
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem};
  
  left: 10px;
  letter-spacing: 1px;
  line-height: 27px;
  /* position: absolute; */
  top: 15px;
  white-space: nowrap;

  
}

.student-top .rt0 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  
  left: 8px;
  letter-spacing: -1px;
  line-height: 27px;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.student-top .rt1 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  
  left: 116px;
  letter-spacing: 3px;
  line-height: 27px;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}



.student-top .student-top-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.student-top .tomopla-logo-8 {
  height: 71px !important;
  left: 41px !important;
  position: absolute !important;
  top: 12px !important;
  width: 224px !important;
}

.student-top .group-5 {
  height: 113px;
  left: 63px;
  position: absolute;
  top: 123px;
  width: 1599px;
}

/*おしらせ*/
.student-top .group-8 {
  height: 107px;
  left: 547px;
  position: absolute;
  top: 6px;
  width: 1050px;
}

.student-top .group-23 {
  height: 546px;
  left: 30px;
  position: absolute;
  top: 278px;
  width: 478px;
}

.student-top .group-24 {
  box-shadow: var(--line-color);
  height: 546px;
  width: 480px;
}

.student-top .overlap-5 {
  height: 546px;
  position: relative;
}

.student-top .group-25 {
  height: 530px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 478px;
}

.student-top .group-26 {
  height: 530px;
}

.student-top .overlap-group-7 {
  height: 530px;
  position: relative;
  width: 478px;
}

.student-top .group-27 {
  height: 530px;
  left: 0;
  position: absolute;
  top: 0;
  width: 478px;
}

.student-top .group-5702-wrapper {
  background-color: #fcf2ef;
  border: 1px solid;
  border-color: var(--tomopla-color);
  border-radius: 16px;
  height: 530px;
  position: relative;
  width: 478px;
}

.student-top .group-5702 {
  height: 53px !important;
  left: 213px !important;
  position: absolute !important;
  top: 247px !important;
  width: 52px !important;
}

.student-top .group-28 {
  height: 20px;
  left: 36px;
  position: absolute;
  top: 99px;
  width: 406px;
}

.student-top .group-29 {
  height: 20px;
}

.student-top .group-30 {
  height: 20px;
  width: 406px;
}

.student-top .group-31 {
  height: 20px;
  position: relative;
  width: 408px;
}

.student-top .text-wrapper-9 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5625rem;};
  
  left: 107px;
  letter-spacing: 1.25px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 191px;
}

.student-top .vector-2 {
  height: 22px;
  left: 395px;
  position: absolute;
  top: 0;
  width: 14px;
}

.student-top .vector-3 {
  height: 22px;
  left: -2px;
  position: absolute;
  top: 0;
  width: 14px;
}

.student-top .group-32 {
  height: 19px;
  left: 25px;
  position: absolute;
  top: 151px;
  width: 442px;
}

.student-top .text-wrapper-10 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  
  left: 0;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-11 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 65px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-12 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 131px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-13 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 196px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-14 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 262px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-15 {
  color: var(--beta-blue);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 327px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-16 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 393px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .group-33 {
  height: 294px;
  left: 25px;
  position: absolute;
  top: 208px;
  width: 428px;
}

.student-top .group-34 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 0;
  width: 442px;
}

.student-top .text-wrapper-17 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 0;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-18 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 65px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-19 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 131px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-20 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 327px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-21 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 393px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .group-35 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 54px;
  width: 440px;
}

.student-top .text-wrapper-22 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 262px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .group-36 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 110px;
  width: 442px;
}

.student-top .group-37 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 164px;
  width: 442px;
}

.student-top .group-38 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 219px;
  width: 442px;
}

.student-top .text-wrapper-23 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 393px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .group-39 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 274px;
  width: 442px;
}

.student-top .text-wrapper-24 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 196px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-25 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 262px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .text-wrapper-26 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 327px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
}

.student-top .group-5729 {
  height: 53px !important;
  left: 17px !important;
  position: absolute !important;
  top: 264px !important;
  width: 52px !important;
}

.student-top .group-5728 {
  height: 52px !important;
  left: 280px !important;
  position: absolute !important;
  top: 209px !important;
  width: 52px !important;
}

.student-top .group-5925 {
  height: 53px !important;
  left: 345px !important;
  position: absolute !important;
  top: 209px !important;
  width: 52px !important;
}

.student-top .group-5926 {
  height: 52px !important;
  left: 410px !important;
  position: absolute !important;
  top: 209px !important;
  width: 52px !important;
}

.student-top .group-5730 {
  height: 52px !important;
  left: 83px !important;
  position: absolute !important;
  top: 264px !important;
  width: 52px !important;
}

.student-top .group-5731 {
  height: 52px !important;
  left: 148px !important;
  position: absolute !important;
  top: 264px !important;
  width: 52px !important;
}

.student-top .text-wrapper-27 {
  color: var(--black);
  font-family: var(--main-font-family-db);
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  left: 221px;
  letter-spacing: 1.24px;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 279px;
  white-space: nowrap;
  width: 35px;
}

.student-top .group-40 {
  height: 90px;
  left: 0;
  position: absolute;
  top: 0;
  width: 480px;
}

.student-top .overlap-6 {
  height: 90px;
  position: relative;
  width: 478px;
}

.student-top .rectangle-2 {
  /* background-color: var(--tomopla-color); */
  border-radius: 16px 16px 0px 0px;
  height: 79px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 478px;
}

.student-top .group-41 {
  height: 57px;
  left: 404px;
  position: absolute;
  top: 0;
  width: 34px;
}

.student-top .overlap-group-8 {
  height: 57px;
  position: relative;
}

.student-top .ellipse {
  background-color: var(--white);
  border-radius: 17px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 34px;
}

.student-top .rectangle-3 {
  background-color: #333333;
  border-radius: 20px;
  height: 46px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 10px;
}

.student-top .group-42 {
  height: 57px;
  left: 40px;
  position: absolute;
  top: 0;
  width: 34px;
}

.student-top .text-wrapper-28 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem;};
  
  left: 54px;
  letter-spacing: 1.2px;
  line-height: 36px;
  position: absolute;
  text-align: center;
  top: 41px;
  width: 370px;
}

.student-top .group-43 {
  height: 530px;
  left: 560px;
  position: absolute;
  top: 290px;
  width: 640px;
}

.student-top .group-44 {
  height: 546px;
  left: 0;
  position: absolute;
  top: 0;
  width: 640px;
}

.student-top .group-45 {
  height: 530px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 640px;
}

.student-top .group-46 {
  background-color: #fcf2ef;
  border: 1px solid;
  border-color: var(--tomopla-color);
  border-radius: 16px;
  box-shadow: var(--line-color);
  height: 530px;
}

.student-top .group-47 {
  height: 13px;
  left: 364px;
  position: relative;
  top: 35px;
  width: 235px;
}

.student-top .group-48 {
  height: 13px;
}

.student-top .element-wrapper {
  height: 13px;
  position: relative;
  width: 237px;
}

.student-top .p {
  color: var(--letter-black);
  font-family: var(--main-font-family-rg);
  font-size: 16px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};
  
  left: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.student-top .text-wrapper-29 {
  letter-spacing: 0.8px;
}

.student-top .text-wrapper-30 {
  letter-spacing: 2.72px;
}

.student-top .group-49 {
  height: 32px;
  left: 21px;
  position: absolute;
  top: -28px;
  width: 157px;
}

.student-top .group-50 {
  height: 32px;
  width: 159px;
}

.student-top .overlap-group-9 {
  background-image: url(../../../static/img/rectangle-499-1.svg);
  background-size: 100% 100%;
  height: 32px;
  position: relative;
  width: 155px;
}

.student-top .group-51 {
  height: 12px;
  left: 43px;
  position: absolute;
  top: 93px;
  width: 559px;
}

.student-top .vector-4 {
  height: 1px;
  left: 38px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 520px;
}

.student-top .text-wrapper-31 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 0;
  white-space: nowrap;
}

.student-top .group-52 {
  height: 15px;
  left: 43px;
  position: absolute;
  top: 155px;
  width: 559px;
}

.student-top .vector-5 {
  height: 1px;
  left: 38px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 520px;
}

.student-top .text-wrapper-32 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 0;
  white-space: nowrap;
  width: 30px;
}

.student-top .group-53 {
  height: 16px;
  left: 43px;
  position: absolute;
  top: 220px;
  width: 559px;
}

.student-top .group-54 {
  height: 16px;
  left: 53px;
  position: absolute;
  top: 286px;
  width: 549px;
}

.student-top .vector-6 {
  height: 1px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 520px;
}

.student-top .text-wrapper-33 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 0;
  white-space: nowrap;
  width: 20px;
}

.student-top .group-55 {
  height: 16px;
  left: 53px;
  position: absolute;
  top: 352px;
  width: 549px;
}

.student-top .group-56 {
  height: 15px;
  left: 53px;
  position: absolute;
  top: 418px;
  width: 549px;
}

.student-top .frame-2 {
  align-items: flex-end;
  display: flex;
  gap: 28px;
  left: 86px;
  position: absolute;
  top: 176px;
  width: 509px;
}

.student-top .group-57 {
  height: 339px;
  position: relative;
  width: 48px;
}

.student-top .text-wrapper-34 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 326px;
  white-space: nowrap;
}

.student-top .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 315px;
  justify-content: flex-end;
  left: 6px;
  position: absolute;
  top: 0;
}

.student-top .rectangle-4 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 117px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .group-58 {
  height: 293px;
  position: relative;
  width: 48px;
}

.student-top .element-2 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 15px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 280px;
  white-space: nowrap;
}

.student-top .frame-wrapper {
  height: 269px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 36px;
}

.student-top .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: 220px;
}

.student-top .rectangle-5 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 49px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 302px;
  justify-content: flex-end;
  left: 6px;
  position: absolute;
  top: 13px;
}

.student-top .rectangle-6 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 87px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: 105px;
}

.student-top .rectangle-7 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 164px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 366px;
  justify-content: flex-end;
  left: 6px;
  position: absolute;
  top: -51px;
}

.student-top .rectangle-8 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 342px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: 172px;
}

.student-top .rectangle-9 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 97px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .rectangle-10 {
  background: linear-gradient(
    180deg,
    rgb(249, 208.5, 199.2) 0%,
    rgb(249, 114, 83) 100%
  );
  border-radius: 0px 0px 4px 4px;
  height: 214px;
  position: relative;
  transform: rotate(-180deg);
  width: 36px;
}

.student-top .vector-7 {
  height: 2px;
  left: 80px;
  position: absolute;
  top: 491px;
  width: 521px;
}

.student-top .group-59 {
  height: 22px;
  left: 22px;
  position: absolute;
  top: 49px;
  width: 129px;
}

.student-top .group-60 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.student-top .text-wrapper-35 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 13px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};
  
  left: 30px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: 5px;
  width: 97px;
}

.student-top .group-61 {
  height: 546px;
  left: 1252px;
  position: absolute;
  top: 278px;
  width: 408px;
}
.student-top .text-wrapper-47 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 26px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};
  
  left: 131px;
  letter-spacing: 1.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 13px;
  white-space: nowrap;
}

.student-top .view {
  gap: 5px !important;
  height: 20px !important;
  justify-content: center !important;
  left: 53px !important;
  padding: 7px 10px !important;
  position: absolute !important;
  top: 244px !important;
}

.student-top .component-10851-instance {
  font-size: 10px !important;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  letter-spacing: 1px !important;
  margin-bottom: -8px !important;
  margin-top: -10px !important;
}

.recharts-tooltip-wrapper{
  background-color: #fcf2ef!important;
}
