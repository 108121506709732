/* メインフォント適用 */
.fc {
  font-family: var(--main-font-family-db);
}

/* .fc-group-wrapper {
  border: 1px solid;
  border-color: var(--tomopla-color);
  background-color: var(--tomopla-color-light);
  border-radius: 16px;
  width: 478px;
  height: 534px;
  position: relative;
  margin-top: 11px;
} 

.fc-group-wrapper .fc-group40 {
  height: 90px;
  left: 0;
  position: relative;
  top: -11px;
  width: 480px;
}

.fc-group-wrapper .fc-group40 .overlap-6 {
  height: 90px;
  position: relative;
  width: 478px;
}

.fc-group-wrapper .fc-group40 .overlap-6 .text-wrapper-28 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  letter-spacing: 1.2px;
  text-align: center;
  width: 370px;
  font-size: 24px;
  
  line-height: 36px;
  position: absolute;
  top: 30px;
  left: 54px;
}

.fc-group-wrapper .fc-group40 .rectangle-2 {
  background-color: var(--tomopla-color);
  border-radius: 16px 16px 0px 0px;
  height: 79px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 478px;
}
*/

/* 日付サイズ */
.fc .fc-day {
  font-size: 24.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  letter-spacing: 1.24px;
}

/* ヘッダー配置調整（年月を中央に） */
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  order: 2;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  order: 3;
  width: 15%;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  order: 1;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
  position: unset;
}

.fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(3)
  .fc-button-group
  .fc-next-button {
  position: absolute;
  right: 12px;
}

/* 今日のボタンを非表示 */
.fc .fc-today-button {
  display: none;
}

/* ヘッダ年月文字スタイル */
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.55rem;};
  
  letter-spacing: 1.25px;
  display: none;
}

/* 左右ナビゲーションボタン基本背景、ボーダーをリセット */
.fc .fc-button-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  color: initial;
}

.fc .fc-button-primary:focus {
  box-shadow: unset;
}

.fc .fc-button-primary:not(:disabled):active {
  color: initial;
  box-shadow: unset;
}

/* 左右ナビゲーションボタン　ホバーカラーイニシャル */
.fc .fc-button-primary:hover {
  color: initial;
}

/* 左右ナビゲーションボタン　fc-iconスタイル */
.fc .fc-button-primary .fc-icon {
  font-size: 2em;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: unset;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 7px;
}

.fc .fc-scroller,
.fc .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.fc .fc-scrollgrid-section-body table {
  width: 457px;
  height: 291px;
  margin-left: 8px;
}

.fc .fc-scrollgrid-section table {
  width: 457px !important;
  margin-left: 7px;
  margin-top: 7px;
}

.fc .fc-view-harness {
  height: 363px !important;
}

.fc .fc-scrollgrid {
  border-width: 0 0 0 0;
  /* 外側の枠線消す */
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};  
}

.fc-theme-standard td,
.fc-theme-standard th {
  height: 51px;
  /* 枠の高さ指定 */
  border: none;
  /* 枠のボーダー消す */
  padding: 0 5px;
}

.fc-theme-standard th {
  height: 41px;
}

/*　今日の背景 */
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
  background: none;
}

.fc .fc-col-header-cell.fc-day.fc-day-sat {
  color: var(--light-blue);
}

.fc .fc-col-header-cell.fc-day.fc-day-sun {
  color: var(--tomopla-color);
}

.fc .fc-day.is_holiday {
  color: var(--tomopla-color);
}

.fc .fc-h-event {
  background-color: unset;
  border: unset;
}
.fc .fc-day {
  position: relative;
}
/* メダル銀 */
.fc .fc-day.medal-silver::after {
  content: '';
  display: inline-block;
  background-image: url(../../../../static/img/medal-silver.svg);
  width: 62px;
  height: 62px;
  display: inline-block;
  background-size: contain;
  margin: auto;
  position: absolute;
  top: -5px;
  left: 2px;
}
/* メダル銅 */
.fc .fc-day.medal-bronze::after {
  content: '';
  display: inline-block;
  background-image: url(../../../../static/img/medal-bronze.svg);
  width: 62px;
  height: 62px;
  display: inline-block;
  position: absolute;
  background-size: contain;
  top: -5px;
  left: 2px;
}
/* メダル金 */
.fc .fc-day.medal-gold::after {
  content: '';
  display: inline-block;
  background-image: url(../../../../static/img/medal-gold.svg);
  width: 62px;
  height: 62px;
  display: inline-block;
  position: absolute;
  background-size: contain;
  top: -5px;
  left: 2px;
}

/* 日付を枠の高さいっぱいに */
.fc .fc-daygrid-day-frame {
  height: 100%;
}

/* 日付を枠の中心に */
.fc .fc-daygrid-day-top {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100%;
}

.fc .fc-bg-event {
  opacity: 1;
}

/* イベントタイトル非表示 */
.fc .fc-bg-event .fc-event-title {
  display: none !important;
}

/* 時間指定のあるイベントの表示スペースをそもそも非表示にしてしまう暴挙 */
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  display: none;
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: none;
}
/* イベント表示ブロックを非表示 */
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: none;
}