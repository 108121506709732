/* {
  background: #fff;
  background-size: 100% 100%;
  height: 980px;
  left: 29px;
  position: absolute;
  top: 50px;
  width: 1633px;
  border-radius: 20px;
}

.modal-title-bar-4 {
  background-color: var(--tomopla-color);
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1633px;
  border-radius: 20px 20px 0 0;
  z-index: 10;
} */

.view-274 {
  height: 58px;
  left: 650px;
  position: absolute;
  top: 10px;
  width: 334px;
}

.group-273 {
  height: 58px;
  position: relative;
  width: 340px;
}

.text-wrapper-290 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  white-space: nowrap;
}

.text-wrapper-291 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 253px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.text-wrapper-292 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 294px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.view-275 {
  height: 66px;
  left: 1478px;
  position: absolute;
  top: 15px;
  width: 140px;
}

.overlap-70 {
  height: 66px;
  position: relative;
}

.active-a-4 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.group-274 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  left: 15px;
  position: relative;
  top: 12px;
  width: 50px;
  cursor: pointer;
}

.group-275 {
  height: 30px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 30px;
}

.text-wrapper-293 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 20px;
  
  left: 67px;
  letter-spacing: 0;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

/* .area-avatar {
  height: 423px;
  left: 272px;
  position: absolute;
  top: 269px;
  width: 360px;
}

.label-avatar {
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 217px;
} */

.text-wrapper-294 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5625rem;};
  left: 0;
  letter-spacing: 1.25px;
  line-height: normal;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}

.text-wrapper-295 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 14px;
  
  left: 164px;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.text-wrapper-296 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 14px;
  
  left: 183px;
  letter-spacing: -0.7px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

/* .avatar {
  border: 7px solid transparent;
  background: linear-gradient(180deg, #F9D1C7 0%, #F97253 100%) border-box border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
  mask-composite: exclude;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
  border-radius: 16px;
  height: 360px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 360px;
} */

/* .avatar-boxshadow {
  width: 360px;
  height: 360px;
  position: absolute;
  top: 63px;
  left: 0;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
} */

.overlap-group-81 {
  background-image: url(../../../../static/img/rectangle-18-6.svg);
  background-size: 100% 100%;
  width: 152px;
  height: 78px;
  position: relative;
  top: -6px;
  left: -4px;
}


/* .overlap-group-82 {
  height: 346px;
  position: relative;
  width: 346px;
  background-color: white;
  border-radius: 11px;
}

.group-276 {
  height: 227px;
  left: 37px;
  position: absolute;
  top: 59px;
  width: 272px;
} */

.icon-edit {
  height: 48px;
  left: 290px;
  position: absolute;
  top: 356px;
  width: 48px;
  z-index: 2;
}

/* .area-input-profile {
  height: 298px;
  left: 757px;
  position: absolute;
  top: 394px;
  width: 605px;
} */

.input-profile-name {
  /* height: 70px; */
  left: -16px;
  position: absolute;
  top: 43px;
  width: 643px;
}

.overlap-group-83 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 80px;
  left: -5px;
  position: relative;
  top: -5px;
  width: 610px;
}

.rectangle-55 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 70px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 600px;
}

.iconedit-instance {
  height: 48px !important;
  left: 553px !important;
  position: absolute !important;
  top: 31px !important;
  width: 48px !important;
}

.text-wrapper-297 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 38px;
  
  left: 25px;
  letter-spacing: 1.9px;
  line-height: normal;
  position: absolute;
  top: 23px;
  white-space: nowrap;
}

.warning-message-7 {
  height: 34px;
  left: 1px;
  position: absolute;
  top: 147px;
  width: 320px;
}

.text-wrapper-298 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 0;
  letter-spacing: -2.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.overlap-71 {
  height: 10px;
  left: 182px;
  position: absolute;
  top: -4px;
  width: 60px;
}

.div-19 {
  color: var(--gold-red);
  font-family: var(--main-font-family-rg);
  font-size: 12px;
  
  left: 30px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.text-wrapper-299 {
  letter-spacing: -3.72px;
}

.text-wrapper-300 {
  letter-spacing: -3.24px;
}

.text-wrapper-301 {
  letter-spacing: -3px;
}

.div-20 {
  color: var(--gold-red);
  font-family: var(--main-font-family-rg);
  font-size: 12px;
  
  left: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.text-wrapper-302 {
  letter-spacing: -1.8px;
}

.text-wrapper-303 {
  letter-spacing: -2.16px;
}

.text-wrapper-304 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 102px;
  letter-spacing: -2.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-305 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 32px;
  letter-spacing: -2.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-306 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 170px;
  letter-spacing: -2.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-307 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 119px;
  letter-spacing: -1.8px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.warning-message-8 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 22px;
  
  left: 4px;
  letter-spacing: 1.1px;
  line-height: normal;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.navbar-6 {
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 172px;
}

.text-wrapper-308 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5625rem;};
  
  left: 5px;
  letter-spacing: 1.25px;
  line-height: normal;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.text-wrapper-309 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  
  left: 36px;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.div-21 {
  color: var(--letter-black);
  font-family: var(--main-font-family-rg);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  
  left: 0;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.text-wrapper-310 {
  letter-spacing: -3.92px;
}

.text-wrapper-311 {
  letter-spacing: -2.94px;
}

.text-wrapper-312 {
  letter-spacing: -2.8px;
}

.text-wrapper-313 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  
  left: 117px;
  letter-spacing: -3.92px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.text-wrapper-314 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  
  left: 136px;
  letter-spacing: -2.1px;
  line-height: normal;
  position: absolute;
  top: -5px;
  white-space: nowrap;
}

.main-button-11 {
  height: 70px;
  left: 475px;
  position: absolute;
  top: 228px;
  width: 136px;
}

.overlap-72 {
  background: linear-gradient(180deg,
      rgb(41, 171, 226) 5.21%,
      rgb(1, 113, 189) 100%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 70px;
  position: relative;
  width: 130px;
}

.text-wrapper-315 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 32px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2rem;};
  
  left: 15px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 100px;
}

.text-wrapper-316 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 17px;
  
  left: 65px;
  letter-spacing: -0.85px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-317 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 17px;
  
  left: 30px;
  letter-spacing: -0.85px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-wr1 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 5px;
  letter-spacing: -1.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-wr2 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 98px;
  letter-spacing: 0.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}
.text-wrapper-wr3 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 162px;
  letter-spacing: -0.4px;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.text-wrapper-wr4 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  
  left: 232px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}
