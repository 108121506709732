.student-help {
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.student-help .div-12 {
  background-color: #fafafa;
  height: 1080px;
  position: relative;
  width: 1690px;
}

.student-help .overlap-38 {
  height: 667px;
  left: 160px;
  position: absolute;
  top: 174px;
  width: 1421px;
}

.student-help .main-container {
  height: 667px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1259px;
}

.student-help .help-contents {
  height: 381px;
  left: 0;
  position: absolute;
  top: 286px;
  width: 1259px;
}

.student-help .block-QA {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 128px;
}

.student-help .row-QA {
  height: 35px;
  position: relative;
  width: 466.5px;
}

.student-help .question {
  height: 35px;
  position: relative;
  width: 466px;
}

.student-help .group-96 {
  height: 18px;
  left: 36px;
  position: absolute;
  top: 9px;
  width: 430px;
}

.student-help .group-97 {
  height: 18px;
  position: relative;
  width: 434px;
}

.student-help .text-wrapper-157 {
  color: var(--letter-black);
  font-family: var(--main-font-family-b);
  font-size: 22px;
  
  left: 0;
  letter-spacing: 1.1px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.student-help .text-wrapper-158 {
  color: var(--letter-black);
  font-family: var(--main-font-family-b);
  font-size: 22px;
  
  left: 38px;
  letter-spacing: 1.1px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.student-help .frame-10 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 35px;
}

.student-help .row-QA-2 {
  height: 103px;
  position: relative;
  width: 1259px;
}

.student-help .answer {
  height: 48px;
  left: 36px;
  position: absolute;
  top: 55px;
  width: 1223px;
}

.student-help .group-98 {
  height: 48px;
}

.student-help .group-99 {
  height: 48px;
  position: relative;
  width: 1227px;
}

.student-help .text-wrapper-159 {
  color: var(--letter-black);
  font-family: var(--main-font-family-r);
  font-size: 22px;
  
  left: 35px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 1188px;
}

.student-help .question-2 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 605px;
}

.student-help .group-100 {
  height: 18px;
  left: 36px;
  position: absolute;
  top: 9px;
  width: 570px;
}

.student-help .group-101 {
  height: 18px;
  position: relative;
  width: 574px;
}

.student-help .question-wrapper {
  height: 35px;
  position: relative;
  width: 535.5px;
}

.student-help .question-3 {
  height: 35px;
  position: relative;
  width: 536px;
}

.student-help .group-102 {
  height: 18px;
  left: 36px;
  position: absolute;
  top: 9px;
  width: 499px;
}

.student-help .group-103 {
  height: 18px;
  position: relative;
  width: 503px;
}

.student-help .genre-title {
  align-items: center;
  background-color: #ffea00;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 12px 20px;
  position: absolute;
  top: 68px;
}

.student-help .text-wrapper-160 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  letter-spacing: 1px;
  line-height: 28px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.student-help .section-title {
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.student-help .group-104 {
  height: 29px;
  left: 68px;
  position: absolute;
  top: 10px;
  width: 225px;
}

.student-help .group-105 {
  height: 29px;
  position: relative;
  width: 227px;
}

.student-help .text-wrapper-161 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 36px;
  
  left: 0;
  letter-spacing: 1.8px;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.student-help .group-106 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 48px;
}

.student-help .link-button {
  height: 70px;
  left: 0;
  position: absolute;
  top: 116px;
  width: 460px;
}

.student-help .group-107 {
  background: linear-gradient(
    180deg,
    rgb(249.9, 249.9, 249.9) 0%,
    rgb(229.5, 229.5, 229.5) 100%
  );
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 70px;
}

.student-help .group-108 {
  height: 35px;
  left: 108px;
  position: relative;
  top: 16px;
  width: 245px;
}

.student-help .group-109 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 27px;
}

.student-help .text-wrapper-162 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 24px;
  
  left: 42px;
  letter-spacing: 1.2px;
  line-height: 30px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.student-help .main-message {
  height: 16px;
  left: 0;
  position: absolute;
  top: 70px;
  width: 232px;
}

.student-help .text-wrapper-163 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 0;
  letter-spacing: 1px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.student-help .main-header {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 248px;
}

.student-help .text-wrapper-164 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 36px;
  
  left: 58px;
  letter-spacing: 1.8px;
  line-height: 30px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.student-help .group-110 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 38px;
}

.student-help .input-search-2 {
  height: 46px;
  left: 1030px;
  position: absolute;
  top: 0;
  width: 391px;
}

.student-help .overlap-39 {
  height: 46px;
  position: relative;
  width: 393px;
}

.student-help .input-help-keyword {
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 393px;
}

.student-help .overlap-group-32 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 62px;
  left: -8px;
  position: relative;
  top: -8px;
  width: 407px;
}

.student-help .rectangle-20 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 46px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 391px;
}

.student-help .text-wrapper-165 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  
  left: 24px;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  top: 22px;
  width: 293px;
}

.student-help .main-button-search {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 1.1px 3.29px #000000b2;
  height: 46px;
  left: 331px;
  position: absolute;
  top: 0;
  width: 60px;
}

.student-help .vector-28 {
  height: 34px;
  left: 13px;
  position: absolute;
  top: 5px;
  width: 32px;
}

.student-help .student-top-bar {
  height: 67px;
  left: 30px;
  position: absolute;
  top: 16px;
  width: 1630px;
}

.student-help .line-5 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 1630px;
}

.student-help .group-111 {
  height: 52px;
  left: 142px;
  position: absolute;
  top: 0;
  width: 1490px;
}

.student-help .text-wrapper-166 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 36px;
  
  left: 0;
  letter-spacing: 1.8px;
  line-height: 20px;
  position: absolute;
  top: 23px;
}

.student-help .group-112 {
  height: 50px;
  left: 1094px;
  position: absolute;
  top: 0;
  width: 169px;
}

.student-help .group-113 {
  background-color: #d9d9d9;
  border-radius: 25px;
  height: 50px;
  left: 119px;
  position: absolute;
  top: 0;
  width: 50px;
}

.student-help .frame-11 {
  align-items: flex-end;
  display: flex;
  gap: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 106px;
}

.student-help .text-wrapper-167 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 18px;
  
  letter-spacing: 0.9px;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.student-help .vector-29 {
  height: 10.2px;
  position: relative;
  width: 12px;
}

.student-help .frame-12 {
  align-items: center;
  background-color: #d9d9d9;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  left: 1288px;
  padding: 44px 24px;
  position: absolute;
  top: 0;
  width: 200px;
}

.student-help .text-wrapper-168 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  letter-spacing: 0.8px;
  line-height: 20px;
  margin-bottom: -34.5px;
  margin-top: -36.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.student-help .group-114 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 129px;
}

.student-help .text-wrapper-169 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  left: 60px;
  letter-spacing: 0.8px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 8px;
}

.student-help .rectangle-21 {
  background-color: #d9d9d9;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.student-help .scroll-bar {
  background-color: var(--line-color);
  border-radius: 10px;
  height: 62px;
  left: 1651px;
  position: absolute;
  top: 98px;
  width: 9px;
}
