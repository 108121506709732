.teacher-top-bar {
  height: 90px;
  left: 140px;
  position: absolute;
  top: 0;
  width: calc(100% - 140px);
  z-index: 11;
  display: flex;
  justify-content: space-between;
}

.teacher-top-bar .topbar-title {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 2.5rem;
  }

  ;

  left: 301px;
  letter-spacing: 2px;
  line-height: 20px;
  position: absolute;
  top: 50px;
}

/* ---- */
.teacher-top-bar .wrapper {
  height: 66px;
  /* left:1052px; */
  right: 163px;
  position: absolute;
  top: 12px;
  /* width: 66px; */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 500px;
  gap: 21px;
}

.teacher-top-bar .wrapper .avatar-wrapper {
  height: 70px;
  width: 70px;
  /* background: linear-gradient(180deg, #F9D1C7 0%, #F97253 100%) border-box border-box; */
  border-color: transparent;
  border-radius: 6px;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);

}

.teacher-top-bar .wrapper .avatar-bg-wrapper {
  background-color: var(--white);
  position: relative;
}

.teacher-top-bar .wrapper .avatar-bg {
  background-color: var(--white);
  height: 66px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 66px;
  border-radius: 4px;
}

/* .teacher-top-bar .wrapper .avatar-border {
  background-color: var(--white);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(249, 194.32, 181.77) 20.83%,
      rgb(249, 114.83, 84.02) 71.34%,
      rgb(249, 114, 83) 71.35%
    )
    1;
  border-radius: 4px;
  height: 70px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 70px;
} */

.teacher-top-bar .wrapper .avatar-img {
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
}

.teacher-top-bar .wrapper .name-wrapper {
  align-items: flex-end;
  display: flex;
  gap: 18px;
  height: 22px;
  left: 1279px;
  cursor: pointer;
}

.teacher-top-bar .wrapper .user-name {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 27px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.4875rem;
  }

  ;

  letter-spacing: 4px;
  line-height: 30px;
  margin-top: -1.5px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: 272px;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
}

.teacher-top-bar .wrapper .arrow-icon {
  height: 15.3px;
  position: relative;
  width: 18px;
}

/* ---- */
.teacher-top-bar .custom-instance {
  height: 71px !important;
  left: 41px !important;
  position: absolute !important;
  top: 12px !important;
  width: 224px !important;
}

/* 教師モーダル */
.teacher-top-bar .menu-modal {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: var(--);
  height: 339px;
  position: relative;
  width: 271px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    width: 283px;
  }

  left: calc(100% - 302px);
  cursor: pointer;
}

.teacher-top-bar .menu-modal.menu-item-count-3 {
  height: 258px;
}

.teacher-top-bar .menu-modal.menu-item-count-4 {
  height: 339px;
}

.teacher-top-bar .menu-modal .menu-item-text {
  top: 7px;
}

.teacher-top-bar .tomopla-logo-link {
  position: absolute;
  width: 224px;
  height: 71px;
  top: 12px;
  left: 41px;
  z-index: 10;
  cursor: pointer;
}