.sub-button-auto {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  height: 55px;
  position: relative;
  width: 160px;

  background: linear-gradient(180deg, rgb(181, 181, 193) 10.42%, rgb(90, 90, 99) 91.67%);
  box-shadow: 0px 2px 6px #000000b2;

  .text-wrapper {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 26px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
      font-size: 1.625rem;
      top:5px;
    };
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 3px;
    white-space: nowrap;
  }

  .div {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 12px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
    left: 34px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -3px;
    white-space: nowrap;
  }
  
  .text-wrapper-2 {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 12px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
    left: 53px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -3px;
    white-space: nowrap;
  }
  
  .text-wrapper-31 {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 12px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
    left: 79px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -3px;
    white-space: nowrap;
  }
  
  .text-wrapper-4 {
    color: #ffffff;
    font-family: var(--main-font-family-m);
    font-size: 12px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
    left: 106px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -3px;
    white-space: nowrap;
  }

  &:hover {
    background: linear-gradient(180deg, rgb(206, 206, 220) 10.42%, rgb(159, 159, 180) 91.67%);
    box-shadow: 0px 2px 6px #000000b2;
  }

  &:active {
    background: linear-gradient(180deg, rgb(121, 121, 128) 10.42%, rgb(57, 57, 64) 91.67%);
    box-shadow: none;
  }
}