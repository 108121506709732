.parental-agreement-form {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.parental-agreement-form .overlap-2 {
  /* height: 1470px; */
  width: 1690px;
  position: relative;
}

.parental-agreement-form .no-BG-2 {
  background-color: var(--white);
  height: 1625px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.parental-agreement-form .group-7 {
  height: 204px;
  left: 128px;
  position: absolute;
  top: -55px;
  width: 204px;
}

.parental-agreement-form .frame-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.parental-agreement-form .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.parental-agreement-form .ellipse-2 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.parental-agreement-form .group-8 {
  height: 204px;
  left: 1356px;
  position: absolute;
  top: 992px;
  width: 204px;
}

.parental-agreement-form .group-9 {
  height: 204px;
  left: 1355px;
  position: absolute;
  top: 260px;
  width: 204px;
}

.parental-agreement-form .group-10 {
  height: 204px;
  left: -40px;
  position: absolute;
  top: 702px;
  width: 204px;
}

.parental-agreement-form .group-11 {
  height: 204px;
  left: 303px;
  position: absolute;
  top: 1250px;
  width: 204px;
}

.parental-agreement-form .group-12 {
  height: 40px;
  left: 451px;
  position: relative;
  top: 615px;
  width: 790px;
}

.parental-agreement-form .message-description-2 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 22px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
  left: 62px;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.parental-agreement-form .group-13 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.parental-agreement-form .overlap-group-4 {
  background: none;
  border-radius: 4px;
  height: 60px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 60px;
  cursor: pointer;
}

.parental-agreement-form .rectangle-2 {
  background-color: var(--white);
  border-radius: 4px;
  height: 40px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 40px;
}

.parental-agreement-form .vector {
  height: 40px;
  left: 14px;
  position: absolute;
  top: 16px;
  width: 40px;
}

.parental-agreement-form .overlap-wrapper {
  height: 70px;
  left: 720px;
  position: relative;
  top: 600px;
  width: 252px;
  
}

.parental-agreement-form .overlap-3 {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 1px 3px #000000b2;
  height: 70px;
  position: relative;
  width: 250px;
}

.parental-agreement-form .text-wrapper-7 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  left: 45px;
  letter-spacing: 1.5px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  top: 5px;
  white-space: nowrap;
}

.parental-agreement-form .text-wrapper-79 {
  color: var(--dark);
  font-family: var(--main-font-family-m);
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  left: 45px;
  letter-spacing: 1.5px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  top: 5px;
  white-space: nowrap;
}

.parental-agreement-form .main-form-2 {
  height: 449px;
  left: 268px;
  position: relative;
  top: 450px;
  width: 1154px;
}

.parental-agreement-form .block-store {
  height: 55px;
  left: 296px;
  position: absolute;
  top: 394px;
  width: 858px;
}

.parental-agreement-form .div-wrapper-2 {
  height: 55px;
}

.parental-agreement-form .group-14 {
  height: 55px;
  width: 858px;
}
.parental-agreement-form .group-14 .MuiStack-root {
  margin: 0;
}

.parental-agreement-form .overlap-group-5 {
  background-color: var(--gray);
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 878px;
}

.parental-agreement-form .rectangle-3 {
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 858px;
}

.parental-agreement-form .frame-5 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 10px;
  padding: 10px 25px;
  position: absolute;
  top: 19px;
  width: 858px;
}

.parental-agreement-form .text-wrapper-8 {
  color: var(--gray);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.parental-agreement-form .block-email-confirm-2 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 315px;
  width: 1154px;
}

.parental-agreement-form .group-wrapper-2 {
  height: 55px;
  left: 296px;
  position: absolute;
  top: 0;
  width: 858px;
}
.parental-agreement-form .group-wrapper-2 .MuiStack-root {
  margin: 0;
}

.parental-agreement-form .text-wrapper-9 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.parental-agreement-form .label-email-confirm-2 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 77px;
}

.parental-agreement-form .text-wrapper-10 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
  left: 0;
  letter-spacing: 1.2px;
  /* line-height: 47px; */
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.parental-agreement-form .block-email-2 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 1154px;
}

.parental-agreement-form .rectangle-4 {
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 858px;
}

.parental-agreement-form .label-email-2 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 178px;
}

.parental-agreement-form .text-wrapper-11 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem;};
  left: 0;
  letter-spacing: 1.2px;
  line-height: 32px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.parental-agreement-form .block-tel-2 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 105px;
  width: 1156px;
}

.parental-agreement-form .label-tel-2 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
  left: 0;
  letter-spacing: 1.2px;
  /* line-height: 47px; */
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.parental-agreement-form .block-person-2 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1154px;
}

.parental-agreement-form .label-person-2 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 203px;
}

.parental-agreement-form .text-wrapper-12 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
  left: 0;
  letter-spacing: 1.2px;
  /* line-height: 47px; */
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.parental-agreement-form .message-description-3 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 22px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 0;
  right: 0;
  margin: auto;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  text-align: center;
  top: 245px;
  width: 848px;
}

.parental-agreement-form .view-wrapper {
  height: 82px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  top: 100px;
  width: 452px;
}

.parental-agreement-form .view-2 {
  height: 82px;
}

.parental-agreement-form .group-15 {
  height: 82px;
  position: relative;
  width: 454px;
}

.parental-agreement-form .text-wrapper-13 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 36px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  left: 0;
  letter-spacing: 1.8px;
  line-height: 52.5px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.parental-agreement-form .message-warning {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
  left: 466px;
  letter-spacing: 1.2px;
  line-height: 56px;
  position: relative;
  text-align: center;
  top: 389px;
  white-space: nowrap;
  width: 759px;
}

.parental-agreement-form .view-3 {
  height: 70px;
  left: 604px;
  position: relative;
  top: 475px;
  width: 449px;
}

.parental-agreement-form .overlap-4 {
  background: linear-gradient(
    180deg,
    rgb(249.9, 249.9, 249.9) 0%,
    rgb(229.5, 229.5, 229.5) 100%
  );
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--);
  height: 70px;
  position: relative;
  width: 483px;
}

.parental-agreement-form .text-wrapper-14 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem;};
  left: 36px;
  letter-spacing: 1.5px;
  /* line-height: normal; */
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}

.parental-agreement-form .text-wrapper-15 {
  color: var(--black);
  font-family: var(--main-font-family-m);
  font-size: 17px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.0625rem;};
  left: 310px;
  letter-spacing: -0.51px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 65px;
  white-space: nowrap;
}

.parental-agreement-form .block-buttons-wrapper {
  height: 70px;
  left: 585px;
  position: absolute;
  top: 977px;
  width: 520px;
}
.parental-agreement-form .text-wrapper {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
  left: 62px !important;
  letter-spacing: 1.5px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 5px;
  white-space: nowrap;
}
.parental-agreement-form .main-button {
  width: 250px;
  height: 70px;
  position: relative;
  top: 0;
  left: 0;
}
.parental-agreement-form .sub-button {
  width: 250px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 270px;
}
.mailpost-complete {
  position: relative;
  top: 1000px;
  left: 600px;
  z-index: 10;
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.875rem;};
}

.pagettl {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 34px;

  @media screen and (max-width:767px) {
    font-size: 20px;

  }
}

// 個人情報保護方針
.Txt_Box {
  line-height: 1.5;
  font-size: 16px;
  color: rgb(68, 68, 68);

  p {
    margin-bottom: 40px;
  }

  .bold {
    font-weight: bold;
  }

  .right{
    text-align: right;
  }

  p+ol {
    margin-top: -20px;
  }

  ol {
    list-style: auto;
    padding-left: 1.25em;

    li {
      margin-bottom: 10px;
    }

    ol {
      margin-top: 10px;
      list-style: none;
      padding-left: 35px;
      margin-bottom: 40px;

      li {
        span.inner {
          display: inline-block;
          width: 35px;
          margin-left: -35px;
        }
      }
    }
  }
}

.form_txt {
  margin-bottom: 20px;
}

table.Confirm_list {
  border-top: 1px solid #CCC;
  color: rgb(68, 68, 68);

  th,
  td {
    line-height: 32.4px;
    border-bottom: 1px solid #CCC;
    font-size: 18px;
    padding: 15px 0;
    vertical-align: top;
    position: relative;
    text-align: left;
  }

  th {
    width: 143.66px;
  }

  @media screen and (max-width:767px) {

    th {
      border-bottom: none;
      padding-bottom: 0;
    }

    td {
      padding-top: 0;
    }

    th,
    td {
      font-size: 16px;
      display: block;
      line-height: 1.7;
    }
  }
}
