.student-top-bar {
  height: 90px;
  position: relative;
  width: 1690px;
}

.student-top-bar .menu-modal.menu-item-count-3 {
  height: 258px;
}
.student-top-bar .menu-modal.menu-item-count-4 {
  height: 339px;
}


/* -----> */
.student-top-bar .user-menu {
  height: 66px;
  left: 1122px;
  position: absolute;
  top: 12px;
  width: 470px;
  z-index: 2;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* justify-content: space-around; */
  
}

.student-top-bar .user-avatar-wrapper {
  height: 70px;
  width: 70px;
  /* background: linear-gradient(180deg, #F9D1C7 0%, #F97253 100%) border-box border-box; */
  border-color: transparent;
  border-radius: 6px;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
}

.student-top-bar .avatar-overlay {
  background-color: var(--white);
  position: relative;
}

.student-top-bar .avatar-bg {
  background-color: var(--white);
  height: 66px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 66px;
  border-radius: 4px;
}

/* .student-top-bar .avatar-border {
  background-color: var(--l-CX-wu-6);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(249, 194.32, 181.77) 20.83%,
      rgb(249, 114.83, 84.02) 71.34%,
      rgb(249, 114, 83) 71.35%
    )
    1;
  border-radius: 4px;
  height: 70px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 70px;
} */

.student-top-bar .avatar-image {
  height: 70px;
  left: 0px;
  position: absolute;
  top: 0px;
  /* width: 59px; */
}

.student-top-bar .user-details {
  align-items: flex-end;
  display: flex;
  gap: 18px;
  height: 22px;
  left: 0;
  /* position: absolute;
  top: 27px;
  width: 159px; */
  cursor: pointer;
  margin-right: 20px;
}

/* .student-top-bar .user-name {
  color: var(--r-i587v);
  font-family: var(--main-font-family-db);
  font-size: 27px;
  
  letter-spacing: 1.35px;
  line-height: 27px;
  margin-top: -1.5px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
} */

.student-top-bar .dropdown-icon {
  height: 15.3px;
  position: relative;
  width: 18px;
  top: -5px;
}
/* <------ */

.student-top-bar .bar-title {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  font-size: 40px;
  
  left: 301px;
  letter-spacing: 2px;
  line-height: 20px;
  position: absolute;
  top: 46px;
}

.student-top-bar .tomopla-logo {
  height: 71px !important;
  left: 41px !important;
  top: 12px !important;
  width: 224px !important;
}

.student-top-bar .tomopla-logo-link {
  position: absolute;
  width: 224px;
  height: 71px;
  top: 12px;
  left: 41px;
  z-index: 10;
  cursor: pointer;
}


.student-top-bar .name-container {
  width: 1690px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.student-top-bar .navbar-name {
  width: 1247px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 301px;
}

.student-top-bar .name-part-1 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: 0.65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1112px;
}
.student-top-bar .name-part-2 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: 0.65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1148px;
}
.student-top-bar .name-part-3 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: 0.65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1178px;
}
.student-top-bar .name-part-4 {
  color: #000;
  font-family: var(--main-font-family-db);
  letter-spacing: 0.65px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  
  line-height: 28.5px;
  position: absolute;
  top: 0;
  left: 1208px;
}

.student-top-bar .name-extension {
  width: 86px;
  height: 16px;
  position: absolute;
  top: 6px;
  left: 0;
}
.student-top-bar .name-extension .name-part-5 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: 0.95px;
  white-space: nowrap;
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  line-height: 20px;
  position: absolute;
  top: 1px;
  left: 0;
}
.student-top-bar .name-extension .name-part-6 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-db);
  letter-spacing: -3.8px;
  white-space: nowrap;
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 36px;
}
