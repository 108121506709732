.error {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
  position:relative;
}

.error .overlap {
  height: 1080px;
  position: relative;
}

.error .div {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1690px;
}

.error .dots {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.error .img {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.error .dots-2 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.error .dots-3 {
  height: 71px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.error .view {
  height: 600px;
  left: 300px;
  position: absolute;
  top: 390px;
  width: 1100px;
}

.error .overlap-group {
  height: 600px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1100px;
}

.error .main-header div {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 82px;
  display: table-cell;
}

.error .main-header {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 44px;
  font-weight: 400;
  left: 0;
  letter-spacing: 2.2px;
  line-height: 82px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  height: 170px;
  display: table;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover: none) {
    font-size: 2.75rem;
  }
}

.error .sub-button {
  background: linear-gradient(180deg, rgb(181, 181, 193) 10.42%, rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px !important;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute !important;
  top: 607px;
  width: 490px !important;
}

.error .sub-button .text-wrapper-back {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 2rem;
  }

  ;
  left: 8px;
  letter-spacing: -2px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 471px;
}

.error .returnmsg {
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 607px;
  width: fit-content;
  font-family: var(--main-font-family-m);
  font-size: 2rem;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 2rem;
  }
}

.error .close-icon-circle {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  width: 50px;
  padding: 0;
  margin: 0;
  margin-top: -5px;
}

.error .close-icon {
  height: 30px;
  width: 30px;
  margin: 10px;
  position: relative;
}

.error .close-text {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -2px;
  margin-left: 20px;
  line-height: 40px;
  white-space: nowrap;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover: none) {
    font-size: 2.5rem;
  }
}

.error .button-container {
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  top: 100px;
  bottom: 0;
  width: 490px;
  height: 110px;
}

.error .sub-button-back-2 {
  background: linear-gradient(180deg,
      rgb(181, 181, 193) 10.42%,
      rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  width: 490px;
}

.error .sub-button-back-2:hover {
  background: linear-gradient(180deg, rgb(206, 206, 220) 10.42%, rgb(159, 159, 180) 91.67%);
  box-shadow: 0px 2px 6px #000000b2;
}

.error .sub-button-back-2:active {
  background: linear-gradient(180deg, rgb(121, 121, 128) 10.42%, rgb(57, 57, 64) 91.67%);
  box-shadow: none;
}

.error .sub-button-text {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 2.4rem;
}