.shkb-element-default-wrapper {
  height: 50px;
  width: 196px;
  border-radius: 40px 4px 4px 40px;

  background: linear-gradient(180deg, rgb(229.5, 243.67, 255) 18.75%, rgb(159, 212, 250) 64.06%);
  border: 2px solid;
  border-color: #0a93f5;
  box-shadow: 0px 2px 6px 0px #000000b2;

  .overlap-group {
    height: 50px;
    position: relative;

    .group {
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 196px;

      .rectangle {
        border-radius: 40px 4px 4px 40px;
        height: 50px;
      }
    }

    .div {
      height: 47px;
      width: 100%;
      font-family: var(--main-font-family-m);
      color: rgba(0, 113, 188, 1);
      display: flex;
      justify-content: center;
      height: 47px;
      width: 100%;
      align-items: center;

      .text-wrapper {
        font-size: 18px;

        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
        (any-hover:none) {
          font-size: 1.125rem;
        }

        left: 3px;
        letter-spacing: -1.5px;
        line-height: normal;
        // position: absolute;
        text-align: center;
        top: 14px;
        white-space: nowrap;
      }

      .text-wrapper-2 {
        font-size: 12px;

        left: -1px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 3px;
        white-space: nowrap;
      }

      .text-wrapper-22 {
        font-size: 12px;

        left: 25px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 3px;
        white-space: nowrap;
      }
    }
  }

  &:hover {
    background: linear-gradient(180deg, rgb(243, 250, 255) 18.75%, rgb(204, 232, 252) 64.06%);
    border: 2px solid;
    border-color: #6dc1fd;
  }

  &:active {
    background: linear-gradient(180deg, rgb(0, 77, 128) 45.31%, rgb(0, 139, 196) 92.19%);
    border: 2px solid;
    border-color: transparent;

    .div {
      color: white;
    }

    box-shadow: none;
  }

  &.active {
    background: linear-gradient(180deg, rgb(0, 77, 128) 45.31%, rgb(0, 139, 196) 92.19%);
    border: 2px solid;
    border-color: transparent;

    .div {
      color: white;
    }

    box-shadow: none;
  }

}