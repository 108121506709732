.MuiButton-root.neutral-button {
  background: linear-gradient(#29abe2 5.21%, #0171bd 100%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  min-height: 130px;
  width: 680px;
  position: relative;
  color: #fff;
  text-transform: none;
  font-family: var(--main-font-family-m);
  font-size: 40px;

  

  &:hover{
    background: linear-gradient(180deg, rgb(102, 210, 255) 12.03%, rgb(1, 155, 255) 89.47%);
    box-shadow: 0px 2px 6px #000000b2;
  }

  &:active{
    background: linear-gradient(180deg, rgb(1, 102, 146) 12.03%, rgb(1, 75, 121) 89.47%);
    box-shadow: none;
  }
}
