.element-active-a-wrapper {
  height: 66px;
  width: 140px;
  position: relative;
  display: flex;
  // align-items: center;

  .overlap {
    // height: 66px;
    // position: relative;
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .active-a {
      height: 66px;
      left: 0;
      position: absolute;
      top: 0;
      width: 140px;
      cursor: pointer;

      .overlap-group {
        background-size: 100% 100%;
        height: 78px;
        // left: -6px;
        position: relative;
        // top: -4px;
        width: 152px;
        display: flex;
        align-items: center;

        .group {
          background-color: #ffffff;
          border-radius: 25px;
          height: 50px;
          left: 15px;
          position: relative;
          top: -2px;
          width: 50px;

          .img {
            height: 30px;
            left: 11px;
            position: absolute;
            top: 11px;
            width: 30px;
          }
        }
      }

      .overlap-group {
        background-image: url(../../../../static/img/rectangle-181.svg);
      }

      &:hover {
        .overlap-group {
          background-image: url(../../../../static/img/rectangle-181-2.svg);

          .group {
            .img:nth-of-type(2) {
              opacity: 0;
            }
          }
        }

      }

      // &:active {
      //   .overlap-group {}
      // }
      &:active {
        .overlap-group {
          background-image: none;

          .group {
            .img:nth-of-type(2) {
              opacity: 1;
            }
          }
        }

        background-image: url(../../../../static/img/onpress1.png);
        background-size: 100% 100%;
        width: 139px;
      }

    }

    .text-wrapper {
      color: #ffffff;
      font-family: var(--main-font-family-db);
      font-size: 20px;      
      // left: 64px;
      left: 20px;
      letter-spacing: 0;
      // line-height: 70px;
      position: relative;
      // text-align: center;
      top: -2px;
      white-space: nowrap;
      user-select: none;
      pointer-events: none;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1rem;};
    }
  }
}

/* .element-active-a-wrapper .overlap {
  height: 66px;
  position: relative;
  
}

.element-active-a-wrapper .active-a {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.element-active-a-wrapper .overlap-group {
  background-size: 100% 100%;
  height: 78px;
  left: -6px;
  position: relative;
  top: -4px;
  width: 152px;
}

.element-active-a-wrapper .group {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  left: 15px;
  position: relative;
  top: 12px;
  width: 50px;
}

.element-active-a-wrapper .img {
  height: 30px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 30px;
}

.element-active-a-wrapper .text-wrapper {
  color: #ffffff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 64px;
  letter-spacing: 0;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
}

.element-active-a-wrapper .overlap .active-a .overlap-group{
  background-image: url(../../../../static/img/rectangle-181.svg);
} */

/* .element-active-a-wrapper .on-press {
  background-image: url(../../../../static/img/onpress1.png);
  background-size: 100% 100%;
  width: 139px;
}

.element-active-a-wrapper .overlap.active-a .overlap-group {
  background-image: url(../../../../static/img/rectangle-181.svg);
}

.element-active-a-wrapper .overlap.mouse-over .overlap-group {
  background-image: url(../../../../static/img/rectangle-181-2.svg);
}

.element-active-a-wrapper .overlap.on-press .overlap-group {
  background-image: url(../../../../static/img/rectangle-181.svg);
} */