.MuiButton-root.secondary-button {
  background-color: #f0f0f0;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  position: relative;
  width: 100%;
  color: #333333;
  text-transform: none;
  font-family: var(--main-font-family-db);
  font-size: 30px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {font-size: 1.875em;};
}
