.inputbox .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
}

.inputbox .MuiInputBase-input.MuiOutlinedInput-input {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: inset 0 2px 6px #000000b2;
  border: 5px solid #d9d9d9;
  font-family: var(--main-font-family-db);
  letter-spacing: .07em;
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {font-size: 1.25em;};
  padding: 0px 27px;
}

.inputbox .MuiOutlinedInput-notchedOutline {
  border: none;
}
