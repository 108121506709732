.app-menu-wrapper {
  .box {
    height: 140px;
    position: relative;
    width: 160px;
    min-width: 160px;
    background-color: #fff;
    border-radius: 20px;
    border-width: 4px;
    border-style: solid;
    box-sizing: border-box;

    // 計算スキル
    &.kss,
    &.KKE1TO,
    &.KKE2TO,
    &.KKE3TO,
    &.KKE4TO,
    &.KKE5TO,
    &.KKE6TO,
    &.KKE1KE,
    &.KKE2KE,
    &.KKE3KE,
    &.KKE4KE,
    &.KKE5KE,
    &.KKE6KE,
    &.KKE1HY,
    &.KKE2HY,
    &.KKE3HY,
    &.KKE4HY,
    &.KKE5HY,
    &.KKE6HY {
      --main-color: #8FD7FF;
      --sub-color: #C9E9FB;
    }

    // 計算スキルは１年から６年まで
    // kss-1 ~ kss-6

    // 国語辞典、漢字辞典
    &.kkd,
    &.kjd,
    &.DKOKDC,
    &.DKANDC {
      --main-color: #ADE8A8;
      --sub-color: #D4EDD2;
    }

    //漢字スキル、ローマ字スキル、きそのたしかめ、単元テスト、映像資料
    &.kjs,
    &.rjs,
    &.cbt,
    &.utt,
    &.mv,
    &.DMOVMK,
    &.DCBKMK,
    &.DPBKMK,
    &.DROMHY,
    &.DKJSMK,
    &.KKJSMK,
    &.KKJSTO,
    &.KKJSKY,
    &.KROMHY,
    &.KROMHY{
      --main-color: #FFB3AC;
      --sub-color: #FBDAD7;
    }

    /*
    CBT:DCBKMK
    ムービー：DMOVMK
    */
    //漢字スキル、ローマ字スキルには「ふろく」付きがある
    //kjs-f
    //rjs-f

    // トモプラヘルプ
    &.qa {
      --main-color: #ECECEC;
      --sub-color: #FAF8F8;
    }

    border-color: var(--main-color);
    filter: drop-shadow(0 4px 2px #00000075);

    .group {
      background-color: var(--main-color);
      height: 60px;
      left: -4px;
      position: absolute;
      top: 76px;
      width: 160px;
      border-radius: 0 0 20px 20px;

      .div {
        height: 60px;
        width: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .img-2 {
      height: 59px;
      left: 15px;
      position: absolute;
      top: 9px;
      width: 127px;
    }

    &:hover {
      border-color: var(--sub-color);

      .group {
        background-color: var(--sub-color);
      }
    }

    &:active {
      border-color: var(--main-color);
      filter: none;

      .group {
        background-color: var(--main-color);
      }
    }

    .furoku{
      position: absolute;
      right: 0;
      top:18px;
      bottom: 0;
      margin: auto;
    }
  }
}