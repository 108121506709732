.teacher-help {
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.teacher-help .div-22 {
  background-color: #fafafa;
  height: 1080px;
  position: relative;
  width: 1690px;
}

.teacher-help .overlap-73 {
  height: 906px;
  left: 280px;
  position: absolute;
  top: 144px;
  width: 1368px;
}

.teacher-help .main-button-contact {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--line-color);
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 25px 66px;
  position: absolute;
  top: 747px;
}

.teacher-help .text-wrapper-318 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 24px;
  
  letter-spacing: 1.2px;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.teacher-help .help-contents {
  height: 906px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1368px;
}

.teacher-help .scroll-bar-6 {
  height: 906px;
  left: 1368px;
  position: absolute;
  top: 0;
  width: 1px;
}

.teacher-help .line-wrapper {
  background-image: url(../../../static/img/line-10-3.svg);
  background-size: 100% 100%;
  height: 906px;
  position: relative;
  width: 12px;
}

.teacher-help .line-11 {
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.teacher-help .block-QA {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 414px;
}

.teacher-help .row-QA {
  height: 35px;
  position: relative;
  width: 327.5px;
}

.teacher-help .question {
  height: 35px;
  position: relative;
  width: 328px;
}

.teacher-help .group-277 {
  height: 18px;
  left: 36px;
  position: absolute;
  top: 9px;
  width: 291px;
}

.teacher-help .group-278 {
  height: 18px;
  position: relative;
  width: 295px;
}

.teacher-help .text-wrapper-319 {
  color: #333333;
  font-family: var(--main-font-family-b);
  font-size: 22px;
  
  left: 0;
  letter-spacing: 1.1px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.teacher-help .text-wrapper-320 {
  color: #333333;
  font-family: var(--main-font-family-b);
  font-size: 22px;
  
  left: 38px;
  letter-spacing: 1.1px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.teacher-help .frame-25 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 35px;
}

.teacher-help .row-QA-2 {
  height: 103px;
  position: relative;
  width: 1259px;
}

.teacher-help .answer {
  height: 48px;
  left: 36px;
  position: absolute;
  top: 55px;
  width: 1223px;
}

.teacher-help .group-279 {
  height: 48px;
}

.teacher-help .group-280 {
  height: 48px;
  position: relative;
  width: 1227px;
}

.teacher-help .text-wrapper-321 {
  color: #333333;
  font-family: var(--main-font-family-r);
  font-size: 22px;
  
  left: 35px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 1188px;
}

.teacher-help .question-2 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 443px;
}

.teacher-help .group-281 {
  height: 18px;
  left: 36px;
  position: absolute;
  top: 9px;
  width: 408px;
}

.teacher-help .group-282 {
  height: 18px;
  position: relative;
  width: 412px;
}

.teacher-help .question-wrapper {
  height: 35px;
  position: relative;
  width: 558.5px;
}

.teacher-help .question-3 {
  height: 35px;
  position: relative;
  width: 558px;
}

.teacher-help .group-283 {
  height: 18px;
  left: 36px;
  position: absolute;
  top: 9px;
  width: 522px;
}

.teacher-help .group-284 {
  height: 18px;
  position: relative;
  width: 526px;
}

.teacher-help .genre-title {
  align-items: center;
  background-color: #ffea00;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 12px 20px;
  position: absolute;
  top: 354px;
}

.teacher-help .text-wrapper-322 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  letter-spacing: 1px;
  line-height: 28px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.teacher-help .section-title {
  background-image: url(../../../static/img/iconhelp.png);
  background-size: 100% 100%;
  height: 48px;
  left: 0;
  position: absolute;
  top: 286px;
  width: 293px;
}

.teacher-help .block-buttons-5 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 116px;
  width: 940px;
}

.teacher-help .link-teachers-manual {
  background: linear-gradient(
    180deg,
    rgb(249.9, 249.9, 249.9) 0%,
    rgb(229.5, 229.5, 229.5) 100%
  );
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--line-color);
  height: 70px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 460px;
}

.teacher-help .group-285 {
  height: 35px;
  left: 45px;
  position: relative;
  top: 16px;
  width: 371px;
}

.teacher-help .group-286 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 27px;
}

.teacher-help .text-wrapper-323 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 24px;
  
  left: 42px;
  letter-spacing: 1.2px;
  line-height: 30px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.teacher-help .link-students-manual {
  background: linear-gradient(
    180deg,
    rgb(249.9, 249.9, 249.9) 0%,
    rgb(229.5, 229.5, 229.5) 100%
  );
  border: 1px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--line-color);
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 460px;
}

.teacher-help .group-287 {
  height: 35px;
  left: 108px;
  position: relative;
  top: 16px;
  width: 245px;
}

.teacher-help .main-message {
  height: 16px;
  left: 0;
  position: absolute;
  top: 70px;
  width: 232px;
}

.teacher-help .text-wrapper-324 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 0;
  letter-spacing: 1px;
  line-height: 28.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.teacher-help .title-9 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 248px;
}

.teacher-help .text-wrapper-325 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 36px;
  
  left: 58px;
  letter-spacing: 1.8px;
  line-height: 30px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.teacher-help .group-288 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 38px;
}

.teacher-help .overlap-74 {
  height: 67px;
  left: 210px;
  position: absolute;
  top: 16px;
  width: 1452px;
}

.teacher-help .input-search-8 {
  height: 46px;
  left: 850px;
  position: absolute;
  top: 2px;
  width: 391px;
}

.teacher-help .overlap-75 {
  height: 46px;
  position: relative;
}

.teacher-help .input-search-keyword {
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 391px;
}

.teacher-help .overlap-group-84 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 62px;
  left: -8px;
  position: relative;
  top: -8px;
  width: 407px;
}

.teacher-help .rectangle-56 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 46px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 391px;
}

.teacher-help .text-wrapper-326 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 24px;
  
  left: 24px;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  top: 21px;
  width: 183px;
}

.teacher-help .main-button-search {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 1.1px 3.29px #000000b2;
  height: 46px;
  left: 331px;
  position: absolute;
  top: 0;
  width: 60px;
}

.teacher-help .vector-58 {
  height: 34px;
  left: 13px;
  position: absolute;
  top: 5px;
  width: 32px;
}

.teacher-help .teacher-top-bar-9 {
  height: 67px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1452px;
}

.teacher-help .text-wrapper-327 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 36px;
  
  left: 0;
  letter-spacing: 1.8px;
  line-height: 20px;
  position: absolute;
  top: 23px;
}

.teacher-help .group-289 {
  height: 50px;
  left: 1281px;
  position: absolute;
  top: 0;
  width: 169px;
}

.teacher-help .group-290 {
  background-color: #d9d9d9;
  border-radius: 25px;
  height: 50px;
  left: 119px;
  position: absolute;
  top: 0;
  width: 50px;
}

.teacher-help .frame-26 {
  align-items: flex-end;
  display: flex;
  gap: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 106px;
}

.teacher-help .text-wrapper-328 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 18px;
  
  letter-spacing: 0.9px;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.teacher-help .vector-59 {
  height: 10.2px;
  position: relative;
  width: 12px;
}

.teacher-help .line-12 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 1450px;
}

.teacher-help .teacher-side-bar {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 182px;
}

.teacher-help .overlap-76 {
  background-color: var(--tomopla-color);
  height: 1080px;
  position: relative;
  width: 180px;
}

.teacher-help .text-wrapper-329 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 25px;
  
  left: 15px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
}

.teacher-help .group-291 {
  height: 314px;
  left: 31px;
  position: absolute;
  top: 77px;
  width: 118px;
}

.teacher-help .group-292 {
  height: 78px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 119px;
}

.teacher-help .text-wrapper-330 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  left: 0;
  letter-spacing: 0.8px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 65px;
  white-space: nowrap;
}

.teacher-help .group-293 {
  background-color: #d9d9d9;
  height: 50px;
  left: 33px;
  position: absolute;
  top: 0;
  width: 50px;
}

.teacher-help .group-294 {
  height: 78px;
  left: 9px;
  position: absolute;
  top: 118px;
  width: 100px;
}

.teacher-help .frame-27 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.teacher-help .rectangle-57 {
  background-color: #d9d9d9;
  height: 50px;
  position: relative;
  width: 50px;
}

.teacher-help .text-wrapper-331 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  letter-spacing: 0.8px;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.teacher-help .group-295 {
  height: 78px;
  left: 0;
  position: absolute;
  top: 236px;
  width: 119px;
}

.teacher-help .rectangle-58 {
  background-color: #d9d9d9;
  height: 50px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 50px;
}

.teacher-help .group-296 {
  height: 98px;
  left: 48px;
  position: absolute;
  top: 791px;
  width: 86px;
}

.teacher-help .text-wrapper-332 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  left: 0;
  letter-spacing: 0.8px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 65px;
}

.teacher-help .rectangle-59 {
  background-color: #d9d9d9;
  height: 50px;
  left: 17px;
  position: absolute;
  top: 0;
  width: 50px;
}
