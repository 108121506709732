.MuiButton-root.button-sso-login.apple {
  background: #000;
  color: #fff;
  border-color: #000;
}

.MuiButton-root.button-sso-login.apple .MuiButton-startIcon img {
  margin-right: 4px;
  width: 34px;
  height: 34px;
}