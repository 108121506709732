.logout {
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.logout .overlap-wrapper-11 {
  background-color: #d9d9d9;
  height: 1080px;
  width: 1690px;
}

.logout .overlap-58 {
  background-color: #d9d9d9;
  height: 1080px;
  position: relative;
}

.logout .message-2 {
  height: 213px;
  position: unset;
  margin: auto;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 594px;
}

.logout .overlap-60 {
  height: 102px;
  position: relative;
  width: 608px;
}

.logout .text-wrapper-327 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 2.3em;
  }

  ;
  left: 0;
  letter-spacing: 2px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 0;
}

.logout .text-wrapper-328 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  left: 222px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  white-space: nowrap;
}

.logout .div-23 {
  color: #333333;
  font-family: var(--main-font-family-rg);
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  left: 333px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  white-space: nowrap;
}

.logout .text-wrapper-329 {
  letter-spacing: -1.6px;
}

.logout .text-wrapper-330 {
  letter-spacing: -2.4px;
}

.logout .div-24 {
  color: #333333;
  font-family: var(--main-font-family-rg);
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  left: 377px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  white-space: nowrap;
}

.logout .text-wrapper-331 {
  letter-spacing: -4.16px;
}

.logout .text-wrapper-332 {
  letter-spacing: -2.88px;
}

.logout .button-wrapper {
  position: absolute;
  top: 588px;
  left: 600px;
}

.logout .sub-button-back-2 {
  background: linear-gradient(180deg,
      rgb(181, 181, 193) 10.42%,
      rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  width: 490px;
}

.logout .sub-button-back-2:hover{
  background: linear-gradient(180deg, rgb(206, 206, 220) 10.42%, rgb(159, 159, 180) 91.67%);
  box-shadow: 0px 2px 6px #000000b2;
}

.logout .sub-button-back-2:active{
  background: linear-gradient(180deg, rgb(121, 121, 128) 10.42%, rgb(57, 57, 64) 91.67%);
  box-shadow: none;
}

.logout .text-wrapper-back {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 2rem;
  }

  ;
  left: 8px;
  letter-spacing: -2px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 471px;
}