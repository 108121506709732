.MuiButton-root.agree-button {
  background: linear-gradient(
    180deg,
    rgb(250, 250, 250) 5.21%,
    rgb(229, 229, 229) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 70px;
  position: relative;
  width: 483px;
  color: #000;
  text-transform: none;
  font-family: var(--main-font-family-m);
  font-size: 40px;  
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
}

.MuiButton-root.agree-button:hover{
  background: linear-gradient(
    180deg,
    rgb(255, 254, 254) 5.21%,
    rgb(247, 245, 245) 100%
  );
  box-shadow: 0px 2px 6px #000000b2;
}

.MuiButton-root.agree-button:active{
  background: linear-gradient(
    180deg,
    rgb(246, 246, 246) 5.21%,
    rgb(222, 222, 222) 100%
  );
  box-shadow: none;
}