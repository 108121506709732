.teacher-top .app-menu-wrapper {
  height: 148px;
  left: 50px;
  position: relative;
  top: 40px;
  width: calc(100% - 98px);
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 8px;
}
