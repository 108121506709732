.MuiButton-root.button-sso-login {
  background-color: #fefefe;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 85px;
  position: relative;
  width: 100%;
  padding: 22px 10.4px;
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {font-size: 1.625em;};
  text-transform: none;
  justify-content: center;
  padding-left: 32px;
}
.MuiButton-root.button-sso-login .MuiButton-startIcon img {
  height: 45px;
  object-fit: cover;
  width: 45px;
  margin-right: 40px;
}
