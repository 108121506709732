.menu-modal {
  background-color: #fff;
  border-radius: 16px;
  width: 305px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    width: 327px;
  }

  height: 416px;
  position: absolute;
  top: 75px;
  left: 1266px;
  box-shadow: 0 2px 6px #000000b2;
  z-index: 30;
}

.menu-modal div {
  cursor: pointer;
}

.menu-modal .modal-menu-item-1 {
  width: 219px;
  height: 40px;
  position: absolute;
  top: 27px;
  left: 27px;
}

.menu-modal .modal-menu-item-2 {
  width: 135px;
  height: 40px;
  position: absolute;
  top: 107px;
  left: 27px;
}

.menu-modal .modal-menu-item-3 {
  width: 135px;
  height: 40px;
  position: absolute;
  top: 188px;
  left: 27px;
}

.menu-modal .modal-menu-item-4 {
  width: 177px;
  height: 40px;
  position: absolute;
  top: 269px;
  left: 27px;
}

.menu-modal .modal-menu-item-5 {
  width: 156px;
  height: 40px;
  position: absolute;
  top: 350px;
  left: 27px;
}

.menu-modal .menu-item-text {
  color: #333;
  letter-spacing: 1px;
  white-space: nowrap;
  font-family: var(--main-font-family-db);
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem;}
  line-height: 28.5px;
  position: absolute;
  top: 4px;
  left: 55px;
  cursor: pointer;
}

.student {
  font-size: 24px !important;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.5rem !important;
  }

  ;
}

.teacher {
  font-size: 20px !important;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.25rem !important;
  }

  ;
}

.menu-modal .menu-item-circle {
  background-color: #d9d9d9;
  border-radius: 20px;
  /* width: 40px;
  height: 40px; */
  position: absolute;
  top: -3px;
  left: -3px;
}

.menu-modal .menu-item-circle img {
  position: absolute;
  top: -5px;
  left: -5px;
}

.menu-modal-bg {
  position: fixed;
  inset: 0;
  z-index: 9;
}