.shtb-element-default-wrapper {
  height: 50px;
  width: 196px;
  border-radius: 4px 40px 40px 4px;

  background: linear-gradient(180deg, rgb(229.5, 243.67, 255) 18.75%, rgb(159, 212, 250) 64.06%);
  border: 2px solid;
  border-color: #0a93f5;
  box-shadow: 0px 2px 6px 0px #000000b2;

  color: rgba(0, 113, 188, 1);

  .overlap-group {
    border-radius: 4px 40px 40px 4px;
    height: 50px;
    position: relative;
    

    .rectangle {
      border-radius: 4px 40px 40px 4px;
      //box-shadow: 0px 4px 4px #00000040;
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 196px;
    }

    .text-wrapper {
      font-family: var(--main-font-family-m);
      font-size: 18px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.125rem; left:4px;}
      letter-spacing: -1px;
      white-space: nowrap;
      width: 100%;
      height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
  }
  &:hover {
    background: linear-gradient(180deg, rgb(242.64, 249.51, 255) 18.75%, rgb(203.7, 231.81, 251.97) 64.06%);
    border: 2px solid;
    border-color: #6cc1fd;
  }

  &:active {
    background: linear-gradient(180deg, rgb(0, 139, 196) 6.48%, rgb(0, 77, 128) 40.7%);
    border: 2px solid;
    border-color: transparent;
    color: white;
    box-shadow: none;
  }

  &.active{
    background: linear-gradient(180deg, rgb(0, 139, 196) 6.48%, rgb(0, 77, 128) 40.7%);
    border: 2px solid;
    border-color: transparent;
    color: white;
    box-shadow: none;
  }
}