.logout {
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.logout .overlap-wrapper-11 {
  background-color: #d9d9d9;
  height: 1080px;
  width: 1690px;
}

.logout .overlap-58 {
  background-color: #d9d9d9;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.logout .message-2 {
  /* height: 102px;
  left: 552px; */
  width: 780px!important;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.logout .overlap-61 {
  position: relative;
  width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout .text-wrapper-327 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 2.3em;
  }
  left: 0;
  letter-spacing: 2px;
  line-height: 70px;
  position: relative !important;
  text-align: center;
  top: 0;
}

.logout .text-wrapper-327-2 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 2.3em;
  }
  left: 0;
  letter-spacing: 2px;
  line-height: 70px;
  position: relative !important;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.logout .text-wrapper-327-3 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1.4rem;
  }
  line-height: 1.7;
  padding: 1rem 2rem;
  border: 2px solid white;
  border-radius: 1rem;
  margin-top: 1rem;
}
.logout .text-wrapper-327-3 p{
  white-space: nowrap;
}

.logout .text-wrapper-327-3 ul {
  list-style:disc;
  margin-left: 2em;
}
.logout .text-wrapper-327-3 ul li span{
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 0 0.5rem;
}

.logout .text-wrapper-328 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 16px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1rem;
  }
  left: 222px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  white-space: nowrap;
}

.logout .div-23 {
  color: #333333;
  font-family: var(--main-font-family-rg);
  font-size: 16px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1rem;
  }
  left: 333px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  white-space: nowrap;
}

.logout .text-wrapper-329 {
  letter-spacing: -1.6px;
}

.logout .text-wrapper-330 {
  letter-spacing: -2.4px;
}

.logout .div-24 {
  color: #333333;
  font-family: var(--main-font-family-rg);
  font-size: 16px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
    (any-hover: none) {
    font-size: 1rem;
  }
  left: 377px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  white-space: nowrap;
}

.logout .text-wrapper-331 {
  letter-spacing: -4.16px;
}

.logout .text-wrapper-332 {
  letter-spacing: -2.88px;
}

.logout .button-wrapper {
  position: absolute;
  top: 588px;
  left: 755px;
}
