.maintenance {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
}

.maintenance .overlap {
  height: 1080px;
  position: relative;
}

.maintenance .div {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1690px;
}

.maintenance .dots {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.maintenance .img {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.maintenance .dots-2 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.maintenance .dots-3 {
  height: 71px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.maintenance .sub-button {
  height: 110px;
  left: 600px;
  position: absolute;
  top: 755px;
  width: 498px;
  color: white;
}

.maintenance .overlap-group {
  background: linear-gradient(180deg, rgb(181, 181, 193) 10.42%, rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  position: relative;
  width: 490px;
}

.maintenance .text-wrapper {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;
  letter-spacing: 2px;
}

.maintenance .group {
  height: 107px;
  left: 550px;
  position: absolute;
  top: 387px;
  width: 594px;
}

.maintenance .main-header {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 80px;
  font-weight: 400;
  left: 0;
  letter-spacing: 4px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 42px;
  white-space: nowrap;
}

.maintenance .text-wrapper-5 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 34px;
  font-weight: 400;
  left: 494px;
  letter-spacing: -3.4px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.maintenance .img-2 {
  height: 160px;
  left: 566px;
  position: absolute;
  top: 200px;
  width: 558px;
}

.maintenance .group-2 {
  height: 136px;
  left: 517px;
  position: absolute;
  top: 529px;
  width: 672px;
}

.maintenance .text-wrapper-6 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 46px;
  font-weight: 400;
  left: 63px;
  letter-spacing: 2.3px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
}

.maintenance .text-wrapper-7 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 24px;
  font-weight: 400;
  left: 316px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.maintenance .text-wrapper-8 {
  color: #333333;
  font-family: var(--main-font-family-m);
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.6px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: 110px;
  white-space: nowrap;
}
