.teacher-password {
  background: #fff;
  background-size: 100% 100%;
  height: 980px;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1633px;
  border-radius: 20px;
}

.teacher-password .overlap-61 {
  background-size: 100% 100%;
  /* height: 980px; */
  position: relative;
  /* width: 1633px; */
}

.teacher-password .main-button-8 {
  height: 70px;
  left: 1383px;
  position: absolute;
  top: 840px;
  width: 132px;
}

.teacher-password .overlap-group-74 {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 70px !important;
  position: relative;
  width: 130px !important;
}

.teacher-password .text-wrapper-261 {
  color: #ffffff;
  font-family: var(--main-font-family-r);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  /*  */
  left: 14px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 100px;
}

.teacher-password .message-note-3 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.12386363636364em;};
  /*  */
  left: 314px;
  letter-spacing: 1.25px;
  line-height: normal;
  position: absolute;
  top: 855px;
  white-space: nowrap;
}

.teacher-password .input-password {
  height: 106px;
  left: 392px;
  position: absolute;
  top: 616px;
  width: 849px;
}

.teacher-password .label-8 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5625rem;};
  /*  */
  height: 56px;
  left: 0;
  letter-spacing: 1.25px;
  line-height: 41px;
  position: absolute;
  top: -2px;
}

.teacher-password .input-area-8 {
  height: 100px;
  left: 231px;
  position: absolute;
  top: -21px;
  width: 639px;
}

.teacher-password .overlap-group-75 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 90px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 620px;
}

.teacher-password .rectangle-53 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 70px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 600px;
}

.teacher-password .warning-message-6 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 249px;
  letter-spacing: 4px;
  line-height: normal;
  position: absolute;
  top: 78px;
  white-space: nowrap;
}

.teacher-password .warning-message{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1;
}

.teacher-password .sub-button-auto {
  height: 55px;
  left: 1281px;
  position: absolute;
  top: 457px;
  width: 162px;
}

.teacher-password .overlap-62 {
  background: linear-gradient(
    180deg,
    rgb(181, 181, 193) 10.42%,
    rgb(90, 90, 99) 91.67%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 55px;
  position: relative;
  width: 160px;
}

.teacher-password .text-wrapper-262 {
  color: #ffffff;
  font-family: var(--main-font-family-db);
  font-size: 26px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};
  /*  */
  left: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.teacher-password .input-password-2 {
  height: 106px;
  left: 392px;
  position: absolute;
  top: 450px;
  width: 849px;
}

.teacher-password .label-9 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5625rem;};
  /*  */
  left: 0;
  letter-spacing: 1.25px;
  line-height: normal;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.teacher-password .rectangle-54 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 70px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 600px;
}
.teacher-password .rectangle-54 fieldset {
  border: none;
  height: 70px;
  font-size: 22px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
}
.teacher-password .rectangle-54 .MuiOutlinedInput-root {
  height: inherit;
  font-size: 22px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
}

.teacher-password .icon-eye {
  height: 40px;
  left: 554px;
  position: absolute;
  top: 37px;
  width: 46px;
}

.teacher-password .input-current {
  height: 106px;
  left: 392px;
  position: absolute;
  top: 284px;
  width: 849px;
}

.teacher-password .title-8 {
  height: 28px;
  left: 392px;
  position: absolute;
  top: 208px;
  width: 368px;
}

.teacher-password .text-wrapper-263 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 35px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.1875rem;};
  /*  */
  left: 0;
  letter-spacing: 1.75px;
  line-height: normal;
  position: absolute;
  top: -20px;
  white-space: nowrap;
}

.teacher-password .modal-title-bar {
  background-color: var(--tomopla-color);
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1633px;
  border-radius: 20px 20px 0 0;
}

.teacher-password .sub-button-close {
  height: 66px;
  left: 1478px;
  position: absolute;
  top: 15px;
  width: 140px;
}

.teacher-password .overlap-63 {
  height: 66px;
  position: relative;
}

.teacher-password .active-a {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.teacher-password .overlap-group-76 {
  background-image: url(../../../../static/img/rectangle-18-8.svg);
  background-size: 100% 100%;
  height: 78px;
  left: -6px;
  position: relative;
  top: -4px;
  width: 152px;
}

.teacher-password .group-253 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  left: 15px;
  position: relative;
  top: 12px;
  width: 50px;
}

.teacher-password .group-254 {
  height: 30px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 30px;
}

.teacher-password .text-wrapper-264 {
  color: #ffffff;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  /*  */
  left: 64px;
  letter-spacing: 0;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.teacher-password .modal-title {
  height: 32px;
  left: 692px;
  position: absolute;
  top: 36px;
  width: 252px;
}

.teacher-password .text-wrapper-265 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  /*  */
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -22px;
  white-space: nowrap;
}
.teacher-password .text-wrapper-233 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  /*  */
  left: 34px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-234 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  /*  */
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-235 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  /*  */
  left: 79px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-236 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  /*  */
  left: 106px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -2px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr0 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: -2px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr1 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: -8px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr2 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: 499px;
  letter-spacing: -1.7px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 846px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr3 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: 655px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 846px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr4 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: 835px;
  letter-spacing: -2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 846px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr5 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: -7px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -15px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr6 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: 26px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 23px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr7 {
  color: #000;
  font-family: var(--main-font-family-db);
  font-size: 17px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.0625rem;};
  /*  */
  left: 219px;
  letter-spacing: 2.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -30px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-pr8 {
  color: #fff;
  font-family: var(--main-font-family-db);
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
  /*  */
  left: 31px;
  letter-spacing: 3px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -3px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr1 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 23px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr2 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 237px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr21 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 354px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr3 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 11px;
  letter-spacing: 5px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr3b {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 260px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr4 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 331px;
  letter-spacing: 3px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr5 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 312px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr6 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 170px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr7 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 265px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr7b {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 339px;
  letter-spacing: 3px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr8 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 475px;
  letter-spacing: 3px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr8a {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 515px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr8b {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 588px;
  letter-spacing: -1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr9 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr10 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 0px;
  letter-spacing: 0.5px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr11 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 226px;
  letter-spacing: -0.9px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr12 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 280px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr13 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 502px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr14 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 626px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr21 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 21px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr22 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 283px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr23 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 525px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}

.teacher-password .text-wrapper-wr24 {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.6875rem;};
  /*  */
  left: 261px;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  top: -11px;
  white-space: nowrap;
}