.page-login {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  /* width: 1690px;
  height: 1080px; */
  margin: auto;
  display: flex;
  position: relative;
}

.page-login img{
  width: 50%;
}

.icon-eye > img{
  width: 100%;
}

.panel-left {
  background-image: url(../../../../static/img/auth/group-10783.png), url(../../../../static/img/auth/group-10783.png);
  background-position: right 120px top 73px, left 90px bottom 140px;
  background-repeat: no-repeat, no-repeat;
  background-size: 204px, 204px;
  width: 710px;
  padding: 414px 115px 510px 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-login .twenty-two {
  width: 100%;
  height: 100%;
}

.btn-back {
  height: 65px;
  left: 40px;
  position: absolute;
  top: 40px;
  width: 192px;
}

.btn-back .view {
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 192px;
}

.btn-back .overlap-group-3 {
  background: linear-gradient(
    180deg,
    rgb(181, 181, 193) 10.42%,
    rgb(90, 90, 99) 91.67%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 1px 3px #000000b2;
  height: 65px;
  position: relative;
  width: 190px;
}

.btn-back .text-wrapper-7 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 20px;

  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
  white-space: nowrap;
  width: 161px;
}

.btn-back .text-wrapper-8 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.btn-back .text-wrapper-9 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.btn-back .text-wrapper-10 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 12px;

  left: 74px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.panel-right {
  flex: 1;
  background-image: url(../../../../static/img/auth/group-10783.png), url(../../../../static/img/auth/group-10783.png);
  background-position: right 55px top 88px, left 50% bottom -133px;
  background-repeat: no-repeat, no-repeat;
  background-size: 204px, 204px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-right: 80px;
}

.sso-login-wrap {
  height: 510px;
}

.page-login .button-sso-login {
  margin-top: 30px;
}

.page-login .button-sso-login:not(.apple):hover{
  background-color: rgba(255, 255, 255, 1);
}


.sso-login-wrap .area-message {
  margin-top: 25px;
}

.email-login-wrap {
  flex: 1;
  margin-top: 63px;
}

.page-login .separator {
  display: flex;
  align-items: center;
}

.page-login .separator .line {
  width: 405px;
  height: 3px;
  background-color: #000;
}

.page-login .separator .label {
  flex: 1;
  text-align: center;
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: 20px;

}

.page-login .message {
  color: #333;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {font-size: 1.25em;};
  left: 0;
  letter-spacing: 0.07em;
  line-height: 2;
  white-space: nowrap;
}

.page-login .warning.message {
  color: var(--gold-red);
}

.page-login .label-email-login {
  color: #333;
  font-family: var(--main-font-family-db);
  letter-spacing: 1.5px;
  white-space: nowrap;
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {font-size: 1.25em;};
}

.page-login .email-logo {
  background-color: #bedfff;
  border-radius: 2.4px;
  width: 38px;
  height: 26px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 20px;
}

.page-login .email-logo-img {
  height: 15px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 31px;
}

.page-login .area-input {
  display: flex;
  margin-top: 17px;
  justify-content: space-between;
}

.page-login .area-input-left {
  width: 565px;
  position: relative;
}

.page-login .area-input-left .icon-eye{
  position: absolute;
  bottom: 23px;
  right: 10px;
}
.page-login .area-input-right {
  flex: 1;
  margin-left: 35px;
}
.page-login .area-input-message {
  width: 100%;
}

.page-login .area-input .inputbox {
  margin-bottom: 20px;
}

.page-login .area-input .inputbox input {
  height: 60px;
}

.page-login .text-wrapper-12 {
  left: 710px;
  letter-spacing: 1.1px;
  line-height: 40px;
  position: absolute;
  top: 919px;
  white-space: nowrap;
}

.page-login .area-input .tps-link {
  line-height: 86px;
}

.page-login .area-input .secondary-button {
  height: 75px;
}

ruby.narrow-ruby rt {
  transform: scale( 0.66 , 1 );
}

.temp-data-delete{
  position: absolute;
  font-family: A-OTF UD Shin Maru Go Pr6N;
  font-size: 23px;
  font-weight: 505;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: absolute;
  right: 81px;
  bottom: 77px;
}

.temp-data-delete a{
  color: rgba(37, 146, 240, 1);
  text-decoration: underline;
}
