.logout-modal-window {
  width: 1000px;
}

.logout-modal .overlap-54 {
  height: 940px;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.logout-modal .overlap-54 {
  height: 940px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1000px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9C2B6 60%);
  border-radius: 20px;
}

.logout-modal .rectangle-26 {
  background-color: var(--white);
  border: 10px solid;
  border-color: #ffffff;
  border-radius: 16px;
  height: 765px;
  left: 50px;
  position: relative;
  top: 125px;
  width: 900px;
}

.logout-modal .main-button-cancel {
  height: 130px;
  left: 160px;
  position: absolute;
  top: 650px;
  width: 682px;
}

.logout-modal .overlap-group-45 {
  background: linear-gradient(180deg,
      rgb(41, 171, 226) 5.21%,
      rgb(1, 113, 189) 100%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 130px;
  position: relative;
  width: 680px;
}

.logout-modal .text-wrapper-323 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  left: 204px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 48px;
  width: 271px;
}

.logout-modal .accent-button-OK {
  height: 130px;
  left: 160px;
  position: absolute;
  top: 464px;
  width: 682px;
}

.logout-modal .overlap-55 {
  background: linear-gradient(180deg,
      rgb(255, 228, 153) 10%,
      rgb(255, 196.28, 35.06) 90%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 130px;
  position: relative;
  width: 680px;
}

.logout-modal .text-wrapper-324 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 50px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 3.125rem;};
  left: 34px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 44px;
  width: 610px;
}

.logout-modal .message {
  height: 121px;
  left: 296px;
  position: absolute;
  top: 235px;
  width: 427px;
}

.logout-modal .text {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 45px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.8125rem};
  left: 18px;
  letter-spacing: 2.25px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 85px;
  white-space: nowrap;
}

.logout-modal .text-2 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 45px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.8125rem};
  left: 0;
  letter-spacing: 2.25px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.logout-modal .modal-title-bar-2 {
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1002px;
}

.logout-modal .overlap-56 {
  background-color: var(--tomopla-color);
  border-radius: 20px 20px 0px 0px;
  height: 96px;
  position: relative;
  width: 1000px;
}

.logout-modal .sub-button-close-2 {
  height: 66px;
  left: 845px;
  position: absolute;
  top: 15px;
  width: 140px;
}

.logout-modal .overlap-57 {
  height: 66px;
  position: relative;
}

.logout-modal .active-a-2 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.logout-modal .overlap-group-46 {
  background-image: url(../../../../static/img/rectangle-18-6.svg);
  background-size: 100% 100%;
  height: 78px;
  left: -6px;
  position: relative;
  top: -4px;
  width: 152px;
}

.logout-modal .group-175 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 50px;
  left: 15px;
  position: relative;
  top: 12px;
  width: 50px;
}

.logout-modal .group-176 {
  height: 30px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 30px;
}

.logout-modal .text-wrapper-325 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 64px;
  letter-spacing: 0;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: -3px;
  white-space: nowrap;
}

.logout-modal .modal-title-2 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  left: 396px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}
