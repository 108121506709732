.password-reset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.password-reset .overlap-wrapper-8 {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
}

.password-reset .overlap-44 {
  height: 1080px;
  position: relative;
}

.password-reset .div-16 {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.password-reset .group-129 {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.password-reset .frame-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.password-reset .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.password-reset .ellipse-17 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.password-reset .group-130 {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.password-reset .group-131 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.password-reset .group-132 {
  height: 204px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.password-reset .main-button-7 {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  left: 445px;
  position: absolute;
  top: 668px;
  width: 800px;
}

.password-reset .text-wrapper-250 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2rem;};
  left: 29px;
  letter-spacing: -0.5px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 740px;
}

.password-reset .text-wrapper-251 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 239px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset .text-wrapper-252 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 279px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset .text-wrapper-253 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 320px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset .text-wrapper-254 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 361px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset .text-wrapper-255 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 518px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset .text-wrapper-256 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 19px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.1875rem;};
  left: 557px;
  letter-spacing: 0.95px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -9px;
  white-space: nowrap;
}

.password-reset .main-message-3 {
  height: 84px;
  left: 463px;
  position: absolute;
  top: 454px;
  width: 794px;
}

.password-reset .navbar-4 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 764px;
}

.password-reset .URL-URL {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem};
  left: 1px;
  letter-spacing: 0.9px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 764px;
}

.password-reset .text-wrapper-257 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 265px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-258 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 47px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-259 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-260 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 347px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-261 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 485px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-262 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 514px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-263 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 298px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 29px;
}

.password-reset .text-wrapper-264 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 325px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
  white-space: nowrap;
  width: 29px;
}

.password-reset .navbar-5 {
  height: 10px;
  left: 267px;
  position: absolute;
  top: 0;
  width: 121px;
}

.password-reset .text-wrapper-265 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-266 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-267 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
  width: 29px;
}

.password-reset .text-wrapper-268 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
  width: 29px;
}

.password-reset .text-wrapper-269 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 403px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .text-wrapper-270 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};
  left: 157px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
  width: 39px;
}

.password-reset .main-header-6 {
  height: 57px;
  left: 648px;
  position: absolute;
  top: 367px;
  width: 403px;
}

.password-reset .text-wrapper-271 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 264px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -21px;
  white-space: nowrap;
}

.password-reset .text-wrapper-272 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  
  left: 308px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -21px;
  white-space: nowrap;
}

.password-reset .text-wrapper-273 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 353px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -21px;
  white-space: nowrap;
}

.password-reset .main-header-7 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 42px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.625rem;};
  left: -2;
  letter-spacing: 2.1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 13px;
  white-space: nowrap;
  width: 395px;
}

.password-reset .title-5 {
  background-image: url(../../../static/img/20.svg);
  background-size: 100% 100%;
  height: 143px;
  left: 621px;
  position: absolute;
  top: 186px;
  width: 448px;
}
