.group-62 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 136px;
}

.group-63 {
  height: 32px;
}

.group-64 {
  height: 32px;
  width: 138px;
}

.overlap-group-10 {
  background-image: url(../../../../static/img/rectangle-499-2.svg);
  background-size: 100% 100%;
  height: 32px;
  position: relative;
  /* width: 134px; */
}

.text-wrapper-3 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.24rem;};
  /* left: 10px; */
  letter-spacing: 1px;
  line-height: 27px;
  /* position: absolute;
  top: 2px !important; */
  white-space: nowrap;
  padding: 2px 10px;
}

/* 学習の記録　枠 */
.group-65 {
  height: 500px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 408px;
}

.group-65 .recwrap{
  position: absolute;
  top: 191px;
}

.group-66 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 103px;
  width: 408px;
}

/* .overlap-7 {
  height: 88px;
  position: relative;
} */

/* .group-67 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 0;
  width: 408px;
} */

/* .overlap-group-11 {
  background-image: url(../../../../static/img/rectangle-477.svg);
  background-size: 100% 100%;
  height: 94px;
  position: relative;
  top: -1px;
  width: 414px;
} */

/* .rectangle-11 {
  background-color: var(--tomopla-color);
  border-radius: 0px 4px 4px 0px;
  height: 88px;
  left: 393px;
  position: relative;
  top: 1px;
  width: 15px;
} */

/* .group-68 {
  height: 37px;
  left: 10px;
  position: absolute;
  top: 26px;
  width: 371px;
} */

/* .group-69 {
  height: 37px;
  position: relative;
} */

/* .group-70 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 258px;
} */

/* .group-71 {
  height: 37px;
  position: relative;
  width: 266px;
} */

/* .text-wrapper-36 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-b);
  font-size: 46px;
  
  left: 164px;
  letter-spacing: 1.38px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 10px;
} */

/* .text-wrapper-37 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 26px;
  
  left: 232px;
  letter-spacing: 1.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 14px;
  white-space: nowrap;
} */

/* .text-wrapper-38 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 26px;
  
  left: 99px;
  letter-spacing: 1.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 14px;
  white-space: nowrap;
}

.text-wrapper-39 {
  color: var(--tomopla-color);
  font-family: var(--main-font-family-b);
  font-size: 46px;
  
  right: 170px;
  letter-spacing: 1.38px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 11px;
} */

/* .text-wrapper-39.a1 {
  right: 33px;
} */

/* .group-72 {
  height: 13px;
  left: 271px;
  position: absolute;
  top: 23px;
  width: 102px;
} */

/* .text-wrapper-40 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  left: 0;
  letter-spacing: 0.8px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: -4px;
  white-space: nowrap;
} */

.group-73 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 206px;
  width: 408px;
}

.overlap-group-12 {
  background-image: url(../../../../static/img/rectangle-477-1.svg);
  background-size: 100% 100%;
  height: 94px;
  position: relative;
  top: -1px;
  width: 414px;
}

.rectangle-12 {
  background-color: var(--tomopla-color);
  border-radius: 0px 4px 4px 0px;
  height: 88px;
  left: 393px;
  position: absolute;
  top: 1px;
  width: 15px;
}

.group-74 {
  background-color: var(--white);
  border-radius: 9px;
  height: 18px;
  left: 363px;
  position: absolute;
  top: 13px;
  width: 18px;
}

.group-75 {
  height: 8px;
  left: 3px;
  position: absolute;
  top: 5px;
  width: 11px;
}

.group-76 {
  height: 13px;
  left: 297px;
  position: absolute;
  top: 60px;
  width: 84px;
}

.group-77 {
  height: 13px;
  position: relative;
  width: 86px;
}

/* .group-78 {
  height: 63px;
  left: 54px;
  position: absolute;
  top: 12px;
  width: 191px;
} */

/* .group-79 {
  height: 37px;
  left: 32px;
  position: absolute;
  top: 26px;
  width: 161px;
} */

/* .text-wrapper-41 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;
  
  left: 131px;
  letter-spacing: 1.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 13px;
  white-space: nowrap;
}

.text-wrapper-42 {
  color: var(--white);
  font-family: var(--main-font-family-b);
  font-size: 46px;
  
  letter-spacing: 1.38px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 10px;
  right: 34px;
} */

.overlap-group-13 {
  background-image: url(../../../../static/img/rectangle-477-2.svg);
  background-size: 100% 100%;
  height: 94px;
  position: relative;
  top: -1px;
  width: 414px;
}

/* .group-80 {
  height: 37px;
  left: 61px;
  position: absolute;
  top: 26px;
  width: 322px;
}

.text-wrapper-43 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  left: 186px;
  letter-spacing: 0.8px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 21px;
  white-space: nowrap;
}

.group-81 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 125px;
}

.group-82 {
  height: 37px;
}

.group-83 {
  height: 37px;
  position: relative;
  width: 129px;
} */

/* .group-84 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 309px;
  width: 408px;
}

.overlap-group-14 {
  background-image: url(../../../../static/img/rectangle-477-3.svg);
  background-size: 100% 100%;
  height: 94px;
  position: relative;
  top: -1px;
  width: 414px;
}

.group-85 {
  height: 13px;
  left: 54px;
  position: absolute;
  top: 12px;
  width: 86px;
}

.text-wrapper-44 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 16px;
  
  left: 0;
  letter-spacing: 0.8px;
  line-height: 19px;
  position: absolute;
  top: -4px;
  white-space: nowrap;
}

.group-86 {
  height: 37px;
  left: 54px;
  position: absolute;
  top: 38px;
  width: 189px;
}

.group-87 {
  height: 37px;
  position: relative;
  width: 193px;
}

.text-wrapper-45 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 26px;
  
  left: 163px;
  letter-spacing: 1.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 13px;
  white-space: nowrap;
}

.text-wrapper-46 {
  color: #ffffff;
  font-family: var(--main-font-family-b);
  font-size: 46px;
  
  letter-spacing: 1.38px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 10px;
  right: 34px;
}

.group-88 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 412px;
  width: 408px;
}

.overlap-group-15 {
  background-image: url(../../../../static/img/rectangle-477-4.svg);
  background-size: 100% 100%;
  height: 94px;
  position: relative;
  top: -1px;
  width: 414px;
}

.group-89 {
  height: 37px;
  left: 86px;
  position: absolute;
  top: 38px;
  width: 157px;
}

.group-90 {
  height: 37px;
  position: relative;
  width: 161px;
}
*/
/* アプリ3つの画像　暫定 */
.group-10755 {
  position: absolute;
  top: 345px;
  left: 34px;
}
