.pre-login {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.pre-login .overlap-wrapper-3 {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
}

.pre-login .overlap-7 {
  height: 1080px;
  position: relative;
}

.pre-login .div-3 {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.pre-login .frame-wrapper {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.pre-login .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.pre-login .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.pre-login .ellipse {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.pre-login .group-12 {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.pre-login .group-13 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.pre-login .group-14 {
  height: 204px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.pre-login .message-description-3 {
  height: 91px;
  left: 358px;
  position: absolute;
  top: 757px;
  width: 973px;
}

.pre-login .group-15 {
  height: 82px;
  left: 359px;
  position: absolute;
  top: 745px;
  width: 977px;
}

.pre-login .overlap-group-6 {
  height: 82px;
  position: relative;
  width: 975px;
}

.pre-login .div-4 {
  color: transparent;
  font-family: var(--main-font-family-db);
  font-size: 32px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2rem;};
  left: -1px;
  letter-spacing: 1.6px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -1px;
}

.pre-login .text-wrapper-37 {
  color: #333333;
}

.pre-login a.text-wrapper-38 {
  color: #0a93f5;
  text-decoration: underline;
  text-underline-offset: 7px;
}

.pre-login .text-wrapper-39 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12.8px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.8rem;};
  left: 355px;
  letter-spacing: -1.28px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 49px;
  white-space: nowrap;
}

.pre-login .line-4 {
  height: 2px;
  left: 491px;
  position: absolute;
  top: 90px;
  width: 93px;
}

.pre-login .block-buttons-2 {
  height: 260px;
  left: 495px;
  position: absolute;
  top: 411px;
  width: 700px;
}

.pre-login .overlap-8 {
  background-color: #ffffff;
  border: 10px solid;
  border-color: #ffffff;
  border-radius: 16px;
  height: 280px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 720px;
}

.pre-login .overlap-9 {
  height: 110px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 702px;
}

.pre-login .group-16 {
  height: 110px;
  left: 0;
  position: absolute;
  top: 0;
  width: 657px;
}

.pre-login .accent-button {
  height: 110px;
  left: -7px;
  position: absolute;
  top: 0;
  width: 715px;
}

.pre-login .overlap-10 {
  background: linear-gradient(
    180deg,
    rgb(255, 228, 153) 10%,
    rgb(255, 196.28, 35.06) 90%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  position: relative;
  width: 700px;
}

.pre-login .text-wrapper-40 {
  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  left: 80px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 540px;
}

.pre-login .main-button {
  height: 110px;
  /* left: 10px; */
  position: absolute;
  top: 162px;
  width: 700px;
}

.pre-login .group-17 {
  height: 110px;
}

.pre-login .group-18 {
  height: 110px;
  width: 700px;
}

.pre-login .group-19 {
  height: 110px;
  width: 702px;
}

.pre-login .overlap-group-7 {
  background: linear-gradient(
    180deg,
    rgb(41, 171, 226) 5.21%,
    rgb(1, 113, 189) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  position: relative;
  width: 700px;
}

.pre-login .text-wrapper-41 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  left: 119px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}

.pre-login .title {
  height: 110px;
  left: 538px;
  position: absolute;
  top: 219px;
  width: 614px;
}

.pre-login .view-2 {
  height: 57px;
  left: 390px;
  position: absolute;
  top: 42px;
  width: 224px;
}

.pre-login .group-20 {
  height: 57px;
}

.pre-login .group-21 {
  height: 57px;
  position: relative;
  width: 226px;
}

.pre-login .text-wrapper-42 {
  color: #e60012;
  font-family: var(--main-font-family-db);
  font-size: 70px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 4.375rem;};
  left: 0;
  letter-spacing: 7px;
  line-height: 70px;
  position: absolute;
  top: -3px;
  white-space: nowrap;
}

.pre-login .instance-22 {
  height: 110px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 344px !important;
}

.pre-login .block-buttons {
  position: absolute;
  top: 402px;
  left: 484px;
}
