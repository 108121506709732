.MuiButton-root.sub-button {
  background: linear-gradient(
    180deg,
    rgb(181, 181, 193) 10.42%,
    rgb(90, 90, 99) 91.67%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 1px 3px #000000b2;
  height: 70px;
  position: relative;
  width: 250px;
  color: var(--x-5r-3xf-s);
  font-family: var(--main-font-family-m);
  font-size: 30px;
  
  letter-spacing: 1.5px;
}
