.license-apply-form {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.license-apply-form .overlap-wrapper-13 {
  background-color: #fafafa;
  height: 1982px;
  width: 1690px;
}

.license-apply-form .overlap-64 {
  height: 1982px;
  position: relative;
}

.license-apply-form .no-BG-2 {
  background-color: var(--white);
  height: 1982px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.license-apply-form .group-203 {
  height: 204px;
  left: 128px;
  position: absolute;
  top: -69px;
  width: 204px;
}

.license-apply-form .frame-32 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.license-apply-form .frame-33 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.license-apply-form .ellipse-22 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.license-apply-form .group-204 {
  height: 204px;
  left: 1356px;
  position: absolute;
  top: 1624px;
  width: 204px;
}

.license-apply-form .group-205 {
  height: 204px;
  left: 1355px;
  position: absolute;
  top: 246px;
  width: 204px;
}

.license-apply-form .group-206 {
  height: 204px;
  left: -40px;
  position: absolute;
  top: 1189px;
  width: 204px;
}

.license-apply-form .group-207 {
  height: 204px;
  left: 303px;
  position: absolute;
  top: 1792px;
  width: 204px;
}

.license-apply-form .main-button-9 {
  height: 70px !important;
  left: 250px;
  position: relative;
  top: 0;
  width: 252px !important;
}

.license-apply-form .text-wrapper-3472 {
  font-size: 31px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.9375rem;
  }

  ;
}

.license-apply-form .text-wrapper-347 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 324px;
  white-space: nowrap;
}

.license-apply-form .main-form {
  height: 1335px;
  left: 233px;
  position: absolute;
  top: 518px;
  width: 1223px;
}

.license-apply-form .block-memo {
  height: 200px;
  left: 69px;
  position: relative;
  top: 330px;
  width: 1154px;
}

.license-apply-form .group-208 {
  height: 19px;
  left: 0px;
  position: absolute;
  top: 38px;
  width: 52px;
}

.license-apply-form .text-wrapper-348 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 0;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.license-apply-form .text-wrapper-3484 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 245px;
  white-space: nowrap;
}

.license-apply-form .group-209 {
  height: auto;
  left: 259px;
  position: absolute;
  top: -4px;
  width: 928px;
}

.license-apply-form .group-210 {
  height: 200px;
}

.license-apply-form .group-211 {
  height: 200px;
  width: 858px;
}

.license-apply-form .overlap-group-60 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 220px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 878px;
}

.license-apply-form .rectangle-37 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 200px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 858px;
}

.license-apply-form .block-textbook {
  height: 440px;
  left: 0;
  position: relative;
  top: 300px;
  width: 1223px;
}

.license-apply-form .label-textbook-extra {
  height: 99px;
  left: 69px;
  position: absolute;
  top: 258px;
  width: 125px;
}

.license-apply-form .group-212 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 0;
  width: 125px;
}

.license-apply-form .group-213 {
  height: 19px;
  position: relative;
  width: 127px;
}

.license-apply-form .group-214 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 125px;
}

.license-apply-form .row-textbook {
  height: 55px;
  left: 365px;
  position: absolute;
  top: 320px;
  width: 858px;
}

.license-apply-form .input-textbook-count {
  height: 55px;
  left: 554px;
  position: absolute;
  top: 0;
  width: 304px;
}

.license-apply-form .div-28 {
  height: 28px;
  left: 0px;
  position: absolute;
  top: 14px;
  width: 146px;
}

.license-apply-form .div-282 {
  height: 28px;
  left: 524px;
  position: absolute;
  top: 47px;
  width: 146px;
}

.license-apply-form .group-215 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.license-apply-form .text-wrapper-349 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.275rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: -9px;
  white-space: nowrap;
}

.license-apply-form .text-wrapper-3491 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.275rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: -10px;
  white-space: nowrap;
}

.license-apply-form .text-wrapper-3493 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.275rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.license-apply-form .group-216 {
  height: 55px;
  left: 717px;
  position: absolute;
  top: 13px;
  width: 178px;
}

.license-apply-form .group-216 .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .group-217 {
  height: 55px;
}

.license-apply-form .overlap-group-61 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 160px;
}

.license-apply-form .rectangle-38 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 140px;
}

.license-apply-form .frame-34 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 10px;
  padding: 10px 25px;
  position: absolute;
  top: 19px;
  width: 140px;
}

.license-apply-form .text-wrapper-350 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.25rem;
  }

  ;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.license-apply-form .select-textbook-4 {
  height: 55px;
  left: -3px;
  position: absolute;
  top: 13px;
  width: 479px;
}

.license-apply-form .select-textbook-4 .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .overlap-group-62 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 490px;
}

.license-apply-form .rectangle-39 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 470px;
}

.license-apply-form .vector-35 {
  height: 11px;
  left: 444px;
  position: absolute;
  top: 32px;
  width: 18px;
}

.license-apply-form .frame-35 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 10px;
  padding: 10px 25px;
  position: absolute;
  top: 19px;
  width: 420px;
}

.license-apply-form .row-textbook-2 {
  height: 55px;
  left: 365px;
  position: absolute;
  top: 240px;
  width: 858px;
}

.license-apply-form .row-textbook-3 {
  height: 55px;
  left: 365px;
  position: absolute;
  top: 160px;
  width: 858px;
}

.license-apply-form .row-textbook-4 {
  height: 55px;
  left: 365px;
  position: absolute;
  top: 80px;
  width: 858px;
}

.license-apply-form .div-29 {
  height: 55px;
  left: 347px;
  position: absolute;
  top: -19px;
  width: 896px;
}

.license-apply-form .div-29 .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .group-218 {
  background-color: var(--gold-red);
  border-radius: 4px;
  height: 28px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.license-apply-form .group-218-2 {
  background-color: var(--gold-red);
  border-radius: 4px;
  height: 28px;
  left: 0;
  /* position: absolute; */
  top: -1px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.license-apply-form .group-2183 {
  background-color: var(--gold-red);
  border-radius: 4px;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.license-apply-form .text-wrapper-351 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 15px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 0.9375rem;
  }

  ;
  left: 8px;
  letter-spacing: 3px;
  /* line-height: 47px; */
  /* position: absolute; */
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.license-apply-form .label-textbook-2 {
  height: 183px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 200px;
}

.license-apply-form .text-wrapper-352 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 84px;
  white-space: nowrap;
}

.license-apply-form .text-wrapper-353 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.license-apply-form .text-wrapper-3533 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 405px;
  white-space: nowrap;
}

.license-apply-form .block-store {
  height: 55px;
  left: 0;
  position: relative;
  top: 250px;
  width: 1223px;
}

.license-apply-form .overlap-group-63 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 878px;
}

.license-apply-form .rectangle-40 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 858px;
}

.license-apply-form .div-30 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 171px;
}

.license-apply-form .block-email-confirm {
  height: 55px;
  left: 0;
  position: relative;
  top: 200px;
  width: 1223px;
}

.license-apply-form .label-email-confirm {
  height: 28px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 347px;
}

.license-apply-form .text-wrapper-354 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 69px;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.license-apply-form .block-email {
  height: 55px;
  left: 0;
  position: relative;
  top: 150px;
  width: 1223px;
}

.license-apply-form .label-email {
  height: 28px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 247px;
}

.license-apply-form .block-tel {
  height: 55px;
  left: 69px;
  position: relative;
  top: 100px;
  width: 1156px;
}

.license-apply-form .block-tel .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .input-tel {
  height: 55px;
  left: 276px;
  position: absolute;
  top: -19px;
  width: 897px;
}

.license-apply-form .rectangle-41 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 858px;
}

.license-apply-form .frame-36 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 10px;
  padding: 10px 25px;
  position: absolute;
  top: 19px;
  width: 858px;
}

.license-apply-form .label-tel-2 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 0;
  letter-spacing: 1.2px;
  line-height: 47px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.license-apply-form .block-person {
  height: 55px;
  left: 0;
  position: relative;
  top: 50px;
  width: 1223px;
}

.license-apply-form .group-219 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 52px;
}

.license-apply-form .overlap-group-64 {
  background-color: var(--gold-red);
  border-radius: 4px;
  height: 28px;
  position: relative;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.license-apply-form .text-wrapper-355 {
  color: var(--white);
  font-family: var(--main-font-family-db);
  font-size: 15px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 0.9375rem;
  }

  ;
  left: 8px;
  letter-spacing: 3px;
  /* line-height: 47px; */
  /* position: absolute; */
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.license-apply-form .block-school {
  height: 135px;
  left: 0;
  position: relative;
  top: 0;
  width: 1223px;
}

.license-apply-form .input-group-school {
  height: 135px;
  left: 365px;
  position: absolute;
  top: 0;
  width: 858px;
}

.license-apply-form .input-school-alt {
  height: 55px;
  left: -21px;
  position: absolute;
  top: 61px;
  width: 901px;
}

.license-apply-form .select-school {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 416px;
  position: absolute;
  top: -21px;
  width: 431px;
}

.license-apply-form .select-school .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .group-220 {
  height: 55px;
  margin-right: -2px;
  position: relative;
  width: 424px;
}

.license-apply-form .overlap-group-65 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 442px;
}

.license-apply-form .rectangle-42 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 422px;
}

.license-apply-form .text-wrapper-356 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.25rem;
  }

  ;
  left: 35px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 30px;
  width: 344px;
}

.license-apply-form .vector-36 {
  height: 11px;
  left: 386px;
  position: absolute;
  top: 22px;
  width: 18px;
}

.license-apply-form .select-school-city {
  height: 55px;
  left: 169px;
  position: absolute;
  top: -21px;
  width: 230px;
}

.license-apply-form .select-school-city .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .select-school-prefecture {
  height: 55px;
  left: -18px;
  position: absolute;
  top: -21px;
  width: 168px;
}

.license-apply-form .select-school-prefecture .MuiTypography-root {
  position: absolute;
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1rem;
  }

  ;
  width: 100%;
  display: flex;
  top: 76px;
  left: 0;
}

.license-apply-form .overlap-65 {
  height: 55px;
  position: relative;
}

.license-apply-form .group-221 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 223px;
}

.license-apply-form .group-222 {
  height: 55px;
  width: 223px;
}

.license-apply-form .overlap-group-66 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 243px;
}

.license-apply-form .rectangle-43 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 223px;
}

.license-apply-form .group-223 {
  height: 16px;
  left: 25px;
  position: absolute;
  top: 20px;
  width: 180px;
}

.license-apply-form .text-wrapper-357 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.25rem;
  }

  ;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.license-apply-form .vector-37 {
  height: 11px;
  left: 162px;
  position: absolute;
  top: 2px;
  width: 18px;
}

.license-apply-form .div-wrapper-3 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 163px;
}

.license-apply-form .overlap-66 {
  height: 55px;
  position: relative;
  width: 165px;
}

.license-apply-form .group-224 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 165px;
}

.license-apply-form .overlap-67 {
  height: 55px;
  position: relative;
  width: 163px;
}

.license-apply-form .group-225 {
  height: 55px;
  width: 163px;
}

.license-apply-form .overlap-group-67 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 183px;
}

.license-apply-form .rectangle-44 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 163px;
}

.license-apply-form .text-wrapper-358 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.25rem;
  }

  ;
  left: 25px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.license-apply-form .vector-38 {
  height: 11px;
  left: 126px;
  position: absolute;
  top: 22px;
  width: 18px;
}

.license-apply-form .message-description-3 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 22px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.125rem;
  }

  ;
  left: 431px;
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 35px;
  position: absolute;
  text-align: center;
  top: 180px;
  width: 827px;
}

.license-apply-form .message-description-4 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 18px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.125rem;
  }

  ;
  left: 431px;
  letter-spacing: 0;
  /* white-space: nowrap; */
  line-height: 35px;
  position: absolute;
  /* text-align: center; */
  top: 256px;
  width: 854px;
  padding: 1rem 2rem;
  border: 1px solid gray;
  border-radius: 1rem;
}

.license-apply-form .message-description-4 ul {
  list-style: disc;
  margin-left: 18px;
  line-height: 1.5;

}

.license-apply-form .title-7 {
  height: 62px;
  left: 465px;
  position: absolute;
  top: 74px;
  width: 760px;
}

.license-apply-form .view-58 {
  height: 62px;
}

.license-apply-form .group-226 {
  height: 62px;
  position: relative;
  width: 762px;
}

.license-apply-form .text-wrapper-359 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 36px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.875em;
  }

  ;
  left: 218px;
  letter-spacing: 1.8px;
  line-height: 20px;
  position: absolute;
  top: 32px;
}

.license-apply-form .instance-14 {
  height: 62px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 193px !important;
}

.license-apply-form .text-wrapper-360 {
  color: #000000;
  font-family: var(--main-font-family-m);
  font-size: 18px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 0.80625em;
  }

  ;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -40px;
  left: auto;
  right: 0;
  white-space: nowrap;
}

.license-apply-form .div-textbook1 {
  height: 55px;
  left: 347px;
  position: absolute;
  top: -19px;
  width: 896px;
}

.license-apply-form .div-textbook2 {
  height: 55px;
  left: 347px;
  position: absolute;
  top: 62px;
  width: 896px;
}

.license-apply-form .div-textbook3 {
  height: 55px;
  left: 347px;
  position: absolute;
  top: 143px;
  width: 896px;
}

.license-apply-form .div-textbook4 {
  height: 55px;
  left: 347px;
  position: absolute;
  top: 224px;
  width: 896px;
}

.license-apply-form .div-textbook5 {
  height: 55px;
  left: 347px;
  position: absolute;
  top: 305px;
  width: 896px;
}

.license-apply-form .div-textbook6 {
  width: 896px;
  height: 55px;
  position: absolute;
  top: 383px;
  left: 347px;
}

.license-apply-form .sub-button-3 {
  background: linear-gradient(180deg,
      rgb(181, 181, 193) 10.42%,
      rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 70px !important;
  left: 0;
  position: relative;
  top: 0;
  width: 252px !important;
  color: #fff;
  font-family: var(--main-font-family-m);
  order: 1;
  margin-left: 20px;
}

.license-apply-form .sub-button-3:hover {
  background: linear-gradient(180deg, rgb(206, 206, 220) 10.42%, rgb(159, 159, 180) 91.67%);
  box-shadow: 0px 2px 6px #000000b2;
}

.license-apply-form .sub-button-3:active {
  background: linear-gradient(180deg, rgb(121, 121, 128) 10.42%, rgb(57, 57, 64) 91.67%);
  box-shadow: none;
}

.license-apply-form .main-button-8 {
  height: 70px !important;
  left: 0;
  position: relative;
  top: 0;
  width: 252px !important;
}

.license-apply-form .error-messages {
  font-family: var(--main-font-family-db);
  z-index: 10;
  position: relative;
  top: 0;
  left: 360px;
  margin-bottom: 30px;
  width: 700px;
}

.license-apply-form .error-message {
  color: red;
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.1875rem;
  }

  ;
  line-height: 1.5;
  position: relative;
}

.license-apply-form .submit-button {
  position: relative;
  top: 450px;
  left: 355px;
  display: flex;
  padding-bottom: 2em;
}