.password-reset-url {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.password-reset-url .overlap-wrapper-9 {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
}

.password-reset-url .overlap-45 {
  height: 1080px;
  position: relative;
}

.password-reset-url .div-17 {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.password-reset-url .group-133 {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.password-reset-url .frame-19 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.password-reset-url .frame-20 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.password-reset-url .ellipse-18 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.password-reset-url .group-134 {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.password-reset-url .group-135 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.password-reset-url .group-136 {
  height: 204px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.password-reset-url .sub-button-back {
  background: linear-gradient(
    180deg,
    rgb(181, 181, 193) 10.42%,
    rgb(90, 90, 99) 91.67%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  left: 600px;
  position: absolute;
  top: 668px;
  width: 490px;
}

.password-reset-url .text-wrapper-274 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  left: 8px;
  letter-spacing: -2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
  width: 471px;
}

.password-reset-url .main-message-4 {
  height: 171px;
  left: 388px;
  position: absolute;
  top: 367px;
  width: 938px;
}

.password-reset-url .overlap-group-39 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 103px;
  width: 914px;
}

.password-reset-url .URL {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};
  left: 0;
  letter-spacing: 1.3px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: -13px;
  width: 914px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-275 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:20px;};
  left: 656px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
  width: 39px;
}

.password-reset-url .text-wrapper-276 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:20px;};
  left: 690px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
  width: 51px;
}

.password-reset-url .text-wrapper-277 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:20px;};
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-278 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:77px;} ;
  left: 323px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-279 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:77px;};
  left: 350px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-280 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:77px;};
  left: 377px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-281 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:77px;};
  left: 405px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-282 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;top:77px;};
  left: 568px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-283 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 524px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -19px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-284 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 568px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -19px;
  white-space: nowrap;
}

.password-reset-url .text-wrapper-285 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
  left: 613px;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: -19px;
  white-space: nowrap;
}

.password-reset-url .main-header-8 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 42px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.625rem;};
  left: 648px;
  letter-spacing: 2.1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 381px;
  white-space: nowrap;
  width: 395px;
}

.password-reset-url .instance-4 {
  height: 143px !important;
  left: 621px !important;
  position: absolute !important;
  top: 186px !important;
  width: 448px !important;
}
