.password-reset-url-send {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.password-reset-url-send .password-reset-url-2 {
  background-color: #ffffff;
  height: 1080px;
  width: 1690px;
}

.password-reset-url-send .overlap-46 {
  height: 1080px;
  position: relative;
}

.password-reset-url-send .div-18 {
  background-color: var(--white);
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1690px;
}

.password-reset-url-send .group-137 {
  height: 204px;
  left: 1341px;
  position: absolute;
  top: 228px;
  width: 204px;
}

.password-reset-url-send .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 204px;
  position: relative;
  width: 204px;
}

.password-reset-url-send .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.password-reset-url-send .ellipse-19 {
  background-color: var(--tomopla-color-dot);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.password-reset-url-send .group-138 {
  height: 204px;
  left: 226px;
  position: absolute;
  top: 53px;
  width: 204px;
}

.password-reset-url-send .group-139 {
  height: 204px;
  left: 83px;
  position: absolute;
  top: 715px;
  width: 204px;
}

.password-reset-url-send .group-140 {
  height: 204px;
  left: 1115px;
  position: absolute;
  top: 1009px;
  width: 204px;
}

.password-reset-url-send .button-area {
  height: 110px;
  left: 445px;
  position: absolute;
  top: 744px;
  width: 800px;
}

.password-reset-url-send .sub-button-back-2 {
  background: linear-gradient(180deg,
      rgb(181, 181, 193) 10.42%,
      rgb(90, 90, 99) 91.67%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  left: 310px;
  position: absolute;
  top: 0;
  width: 490px;
}

.password-reset-url-send .sub-button-back-2:hover{
  background: linear-gradient(180deg, rgb(206, 206, 220) 10.42%, rgb(159, 159, 180) 91.67%);
  box-shadow: 0px 2px 6px #000000b2;
}

.password-reset-url-send .sub-button-back-2:active{
  background: linear-gradient(180deg, rgb(121, 121, 128) 10.42%, rgb(57, 57, 64) 91.67%);
  box-shadow: none;
}

.password-reset-url-send .text-wrapper-286 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 2rem;
  }

  ;
  left: 8px;
  letter-spacing: -2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 471px;
}

.password-reset-url-send .main-button-send {
  background: linear-gradient(180deg,
      rgb(41, 171, 226) 5.21%,
      rgb(1, 113, 189) 100%);
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px #000000b2;
  height: 110px;
  left: 0;
  position: absolute;
  top: 0;
  width: 290px;
}

.password-reset-url-send .text-wrapper-287 {
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 40px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 2rem;
  }

  ;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 268px;
}

.password-reset-url-send .text-wrapper-288 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 19px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.1875rem;
  }

  ;
  left: 98px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-289 {
  color: #ffffff;
  font-family: var(--main-font-family-m);
  font-size: 19px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.1875rem;
  }

  ;
  left: 152px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
}

.password-reset-url-send .input-email-2 {
  height: 55px;
  left: 395px;
  position: absolute;
  top: 643px;
  width: 900px;
}

.password-reset-url-send .overlap-47 {
  height: 55px;
  position: relative;
}

.password-reset-url-send .group-141 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 900px;
}

.password-reset-url-send .group-142 {
  height: 55px;
}

.password-reset-url-send .group-143 {
  height: 55px;
  width: 900px;
}

.password-reset-url-send .overlap-group-40 {
  background-color: #d9d9d9;
  border-radius: 16px;
  height: 75px;
  left: -10px;
  position: relative;
  top: -10px;
  width: 920px;
}

.password-reset-url-send .rectangle-24 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: inset 0px 2px 6px #000000b2;
  height: 55px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 900px;
}

.password-reset-url-send .text-wrapper-290 {
  color: var(--line-color);
  font-family: var(--main-font-family-db);
  font-size: 22px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.375rem;
  }

  ;
  left: 25px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.password-reset-url-send .message-warning-5 {
  height: 35px;
  left: 0;
  right:0;
  margin: auto;
  position: absolute;
  top: 562px;
  width: 900px;
  display: flex;
  justify-content: center;
}

.password-reset-url-send .text-wrapper-291 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  left: 208px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-292 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 226px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -4px;
  white-space: nowrap;
}

.password-reset-url-send .message-warning-6 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.5rem
  }

  ;
  left: -29px;
  letter-spacing: 1.2px;
  line-height: 56px;
  /* position: absolute; */
  text-align: center;
  top: -2px;
  white-space: nowrap;
  width: 377px;
}

.password-reset-url-send .message-warning-61 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 24px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.5rem
  }

  ;
  left: -88px;
  letter-spacing: 1.2px;
  line-height: 56px;
  /* position: absolute; */
  text-align: center;
  top: -2px;
  white-space: nowrap;
  /* width: 377px; */
}

.password-reset-url-send .navbar-6 {
  height: 83px;
  left: 463px;
  position: absolute;
  top: 455px;
  width: 790px;
}

.password-reset-url-send .navbar-7 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 764px;
}

.password-reset-url-send .URL-2 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 26px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.16875rem;
  }

  ;
  left: 0;
  letter-spacing: 1.3px;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 766px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-293 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 322px;
  letter-spacing: -1.92px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
  width: 33px;
}

.password-reset-url-send .div-19 {
  color: #333333;
  font-family: var(--main-font-family-rg);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 352px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
  width: 30px;
}

.password-reset-url-send .text-wrapper-294x {
  letter-spacing: -3.36px;
}

.password-reset-url-send .text-wrapper-295x {
  letter-spacing: -2.88px;
}

.password-reset-url-send .text-wrapper-296 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-297 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 29px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-298 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 466px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-299 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 493px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-300 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 580px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-301 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 259px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-302 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 286px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-303 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 313px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-304 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 341px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-305 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 507px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -7px;
  white-space: nowrap;
}

.password-reset-url-send .main-title {
  height: 57px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 367px;
  width: 500px;
  text-align: center;
  display: flex;
}

.password-reset-url-send .main-header-9 {
  color: #333333;
  font-family: var(--main-font-family-db);
  font-size: 42px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 2.375rem;
  }

  ;
  left: 0;
  letter-spacing: 2.1px;
  line-height: 56px;
  /* position: absolute; */
  /* text-align: center; */
  top: 15px;
  white-space: nowrap;
  width: 595px;
}

.password-reset-url-send .overlap-48 {
  height: 16px;
  left: 264px;
  position: absolute;
  top: -19px;
  width: 41px;
}

.password-reset-url-send .text-wrapper-306 {
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.25rem;
  }

  ;
  left: 0;
  letter-spacing: 1px;
  line-height: 56px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.password-reset-url-send .overlap-49 {
  height: 16px;
  left: 308px;
  position: absolute;
  top: -19px;
  width: 41px;
}

.password-reset-url-send .overlap-50 {
  height: 16px;
  left: 353px;
  position: absolute;
  top: -19px;
  width: 41px;
}

.password-reset-url-send .instance-21 {
  height: 143px !important;
  left: 621px !important;
  position: absolute !important;
  top: 186px !important;
  width: 448px !important;
}

.password-reset-url-send .text-wrapper-11 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 219px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-21 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 163px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}

.password-reset-url-send .text-wrapper-22 {
  color: var(--gold-red);
  font-family: var(--main-font-family-db);
  font-size: 12px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 0.75rem;
  }

  ;
  left: 237px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
  white-space: nowrap;
}