#app {
  background: #fff;
  display: flex;
  justify-content: center;
}
/*
・パスワード再設定
・パスワード変更URL送信完了
・パスワード再設定用URL送信へ戻る
・パスワード有効期限切れ
・パスワード設定完了
・
 */
#app .password-reset-input,
#app .password-reset-url,
#app .password-reset-url-send,
#app .password-reset,
#app .password-reset-done,
#app {
  overflow: hidden;
  height: 100%;
}

#app .teacher-help {
  overflow: hidden;
  height: 100%;
}

::-ms-reveal {
  display: none;
}
